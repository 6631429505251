import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Button,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Radio,
  RadioGroup,
  Stack,
  Switch,
  Select,
  Avatar,
  useToast,
  Checkbox,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";
import axios from "axios";

export default function LeaveQuota() {
  const userData = JSON.parse(localStorage.getItem("user"));

  const toast = useToast();
  const id = "toast";

  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridRef = useRef();
  
  const [LeaveQuotaId, setLeaveQuotaId] = useState(0);
  const [LeaveType, setLeaveType] = useState();
  const [LeaveDescription, setLeaveDescription] = useState();
  const [AnnualLeave, setAnnualLeave] = useState();
  const [MaxLeave, setMaxLeave] = useState();
  const [PaidLeave, setPaidLeave] = useState("1");
  const [CalculateMonthly, setCalculateMonthly] = useState("1");
  const [LeaveColor, setLeaveColor] = useState("#f44336");

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));
  
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Leave Type",
      field: "leaveType",
    },
    {
      headerName: "Assign Annual Leave",
      field: "assignAnnualLeaves",
    },
    {
      headerName: "Max Leave Quota",
      field: "maxLeaveQuota",
    },
    {
      headerName: "Paid Leave",
      field: "paidLeave",
    },
    {
      headerName: "Monthly Calculation",
      field: "calculateMonthly",
    },
    { headerName: "For Compensation Offs", field: "compoffs" },
    {
      headerName: "Action",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              // setLeaveQuotaId(params.data.leaveQuotaId);
              setLeaveType(params.data.leaveType);
              setLeaveDescription(params.data.leaveDescription);
              setAnnualLeave(params.data.assignAnnualLeaves);
              setMaxLeave(params.data.maxLeaveQuota);
              setPaidLeave(params.data.paidLeave === true ? "1" : "2");
              setCalculateMonthly(
                params.data.calculateMonthly === true ? "1" : "2"
              );
              setLeaveColor(params.data.colorCodeHash);
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
          >
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  useEffect(() => {
    getLeaveQuotaList();
  }, []);

  const getLeaveQuotaList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveQuotaList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Get Leave Quota List Response", response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const SaveLeaveQuota = async (e) => {
    e.preventDefault();

    let body = {
      // leaveQuotaId: LeaveQuotaId,
      leaveType: LeaveType,
      leaveDescription: LeaveDescription,
      colorCodeHash: LeaveColor,
      assignAnnualLeaves: AnnualLeave,
      maxLeaveQuota: MaxLeave,
      calculateMonthly: CalculateMonthly === "1" ? true : false,
      paidLeave: PaidLeave === "1" ? true : false,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log("Save Leave Quota", body);

    await axios
      .post(`${process.env.REACT_APP_ACQ_URL}/api/Leave/SAVELeaveQuota`, body)
      .then((response) => {
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getLeaveQuotaList();
        setLeaveQuotaId(0);
        setLeaveType("");
        setLeaveDescription("");
        setAnnualLeave("");
        setMaxLeave("");
        setPaidLeave("1");
        setCalculateMonthly("1");
        setLeaveColor("#f44336");
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Leave Quota Not Created",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };
  return (
    <div>
      <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Leave Quota</h1>
      </div>

      <Accordion
        defaultIndex={[0]}
        shadow="lg"
        border="transparent"
        rounded="xl"
        allowMultiple
        bg="white"
      >
        <AccordionItem>
          <h2>
            <AccordionButton>
              <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                Add Leave Quota
              </h6>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <form onSubmit={SaveLeaveQuota}>
              <div className="grid grid-cols-3 gap-6">
                <div className="space-y-2">
                  <label>Leave Type</label>
                  <Input
                    value={LeaveType}
                    onChange={({ target }) => {
                      setLeaveType(target.value);
                    }}
                    borderColor="gray"
                    placeholder="Leave Type"
                  />
                </div>

                <div className="space-y-2">
                  <label>Leave Description</label>
                  <Input
                    value={LeaveDescription}
                    onChange={({ target }) => {
                      setLeaveDescription(target.value);
                    }}
                    borderColor="gray"
                    placeholder="Leave Description"
                  />
                </div>

                <div className="space-y-2">
                  <label>Annual Leave</label>
                  <Input
                    type={"number"}
                    value={AnnualLeave}
                    onChange={({ target }) => {
                      setAnnualLeave(target.value);
                    }}
                    borderColor="gray"
                    placeholder="Annual Leave"
                  />
                </div>

                <div className="space-y-2">
                  <label>Max Leave Quota</label>
                  <Input
                    type={"number"}
                    value={MaxLeave}
                    onChange={({ target }) => {
                      setMaxLeave(target.value);
                    }}
                    borderColor="gray"
                    placeholder="Max Leave"
                  />
                </div>

                <div className="grid grid-cols-2 gap-4">
                  <div className="space-y-2">
                    <label>Paid Leave</label>
                    <RadioGroup value={PaidLeave} onChange={setPaidLeave}>
                      <Stack direction="row" gap={6}>
                        <Radio size="lg" borderColor={"gray.400"} value="1">
                          Yes
                        </Radio>
                        <Radio size="lg" borderColor={"gray.400"} value="2">
                          No
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </div>

                  <div className="space-y-2 ">
                    <label>Calculate Monthly</label>
                    <RadioGroup
                      value={CalculateMonthly}
                      onChange={setCalculateMonthly}
                    >
                      <Stack direction="row" gap={6}>
                        <Radio size="lg" borderColor={"gray.400"} value="1">
                          Yes
                        </Radio>
                        <Radio size="lg" borderColor={"gray.400"} value="2">
                          No
                        </Radio>
                      </Stack>
                    </RadioGroup>
                  </div>
                </div>

                <div className="space-y-2 w-1/3">
                  <label>Color</label>
                  <Input
                    value={LeaveColor}
                    onChange={({ target }) => {
                      setLeaveColor(target.value);
                    }}
                    borderColor="gray"
                    type="color"
                  />
                </div>

              </div>

              <div className="space-x-4 my-2 text-right">
                <Button
                  colorScheme={"purple"}
                  variant={"outline"}
                  onClick={() => {
                    setLeaveQuotaId(0);
                    setLeaveType("");
                    setLeaveDescription("");
                    setAnnualLeave("");
                    setMaxLeave("");
                    setPaidLeave("1");
                    setCalculateMonthly("1");
                    setLeaveColor("#f44336");
                  }}
                >
                  Reset
                </Button>
                <Button type="submit" colorScheme="purple">
                  Submit
                </Button>
              </div>
            </form>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      <div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}
