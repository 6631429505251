import {   Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Select,
    Checkbox,
    Input,
    Tooltip,
    InputGroup,
    InputRightAddon,
    useToast,
    InputLeftAddon,
    Textarea,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,Box} from '@chakra-ui/react'
import axios from 'axios';
import React, {
    useRef,
    useMemo,
    useState,
    useCallback,
    useEffect,
  } from "react";
  import { AgGridReact } from "ag-grid-react";
  import { faPlus, faDownload, faPaperclip, faEdit, faTrash, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { CheckCircle, XCircle } from "react-feather";
import ReactSelect from 'react-select';
import _ from 'lodash';
import moment from 'moment';
import DatePicker from "react-datepicker";

function EmpExpenseClaim() {

    const userData = JSON.parse(localStorage.getItem("user"));
    const toast = useToast();
    const id = "toast";

    const EmpBillRef = useRef();
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

    const FileRef = useRef();

    const reset = () => {
      FileRef.current.value = "";
    };


    const [ReturnDate, setReturnDate] = useState(new Date());
    const [ReturnAmount, setReturnAmount] = useState();
    const [ReturnInfo, setReturnInfo] = useState();
    const [ReturnMethod, setReturnMethod] = useState();

    const [ReturnRowData, setReturnRowData] = useState([]);


const [EligibilityDetails, setEligibilityDetails] = useState([]);

    

    const [RemainingLimit, setRemainingLimit] = useState(0)
   const [LimitUsed, setLimitUsed] = useState();
    const [BillID, setBillID] = useState(1);

  const [ViewClaimAttachment, setViewClaimAttachment] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const [ExpenseLimitDetails, setExpenseLimitDetails] = useState([]);


  const {
    isOpen: isViewTicketBillsOpen,
    onOpen: onViewTicketBillsOpen,
    onClose: onViewTicketBillsClose,
  } = useDisclosure();

  const {
    isOpen: isReturnRequestOpen,
    onOpen: onReturnRequestOpen,
    onClose: onReturnRequestClose,
  } = useDisclosure();


    const {
        isOpen: isAdvanceRequestOpen,
        onOpen: onAdvanceRequestOpen,
        onClose: onAdvanceRequestClose,
      } = useDisclosure();

      const {
        isOpen: isAddReimbursementOpen,
        onOpen: onAddReimbursementOpen,
        onClose: onAddReimbursementClose,
      } = useDisclosure();

      const [PettyCashPurpose, setPettyCashPurpose] = useState();
      const [RequestDate, setRequestDate] = useState(moment().format('YYYY-MM-DD'));
      const [RequestAmount, setRequestAmount] = useState();

      const [ClaimAmountDetails, setClaimAmountDetails] = useState([]);


    //   Expese Claim Variables
      const [EmployeeReimburseRowData, setEmployeeReimburseRowData] = useState([]);
      const [TicketId, setTicketId] = useState();
      const [ClaimDate, setClaimDate] = useState(moment().format('YYYY-MM-DD'));
      const [BillDate, setBillDate] = useState(moment().format('YYYY-MM-DD'));
      const [ClaimAmount, setClaimAmount] = useState();
      const [BillNumber, setBillNumber] = useState();
      const [Reason, setReason] = useState();
      const [Attachment, setAttachment] = useState(null);
      const [ReimbursementType, setReimbursementType] = useState();
      const [ClientList, setClientList] = useState([]);
      const [ClientName, setClientName] = useState();


      const [ReimbursementTypeList, setReimbursementTypeList] = useState([]);


      const [TicketRowData, setTicketRowData] = useState([]);

      const [TicketsColumnDefs, setTicketsColumnDefs] = useState([

        { headerName: "Ticket Id", field: "reimbursementTicketGenerate" },
        { headerName: "Total Amount", field: "claimedAmount" },
        { headerName: "Payment Status", field: "paymentStatus",
        cellRenderer: (params) => (
          params.data.paymentStatus === 'Pending' ||  params.data.paymentStatus === 'pending'?
          <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Pending
        </span>
         : params.data.paymentStatus === 'Done'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
         Done
        </span> : ''
        ),
       },
        { headerName: "Payment Date", field: "paymentDate",
        cellRenderer: (params) => <span>{moment(params.data.paymentDate).format('DD/MM/YYYY')}</span>

       },
        { headerName: "Approval Level", field: "approvalId" },
        {
          headerName: "Action",
          cellRenderer: (params) => <Button 
          onClick={async()=>{
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetReimbursementByTicketId?Ticket=${params.data.reimbursementTicketGenerate}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
              )

              console.log("Ticket Bills Response",response);
              setExpenseClaimRowData(response.data);
              onViewTicketBillsOpen();
            } catch (error) {
              console.error(error);
            }
          }}
          leftIcon={<FontAwesomeIcon icon={faEye} />} size={'xs'} colorScheme={'blue'}>View Bills</Button>
        },
      ]);

      const [PettyCashAdvanceRowData, setPettyCashAdvanceRowData] = useState([]);
      const [ExpenseClaimRowData, setExpenseClaimRowData] = useState([])

      const [ExpensesColumnDefs, setExpensesColumnDefs] = useState([

        { headerName: "Bill Date", field: "billDate",valueGetter: (params) => {
          return moment(params.data.billDate).format('DD/MM/YYYY')
        }},
        { headerName: "Bill Number", field: "billNumber" },
        { headerName: "Expense Name", field: "expenseName" },
        { headerName: "Claim Amount", field: "claimedAmount" },
        {
          headerName: "Description",
          field: "reason",
        },
        {
          headerName: "Attachment",
          field: "attachmentPath",
          cellRenderer:(params) =>            
         <Button
          size={'xs'}
         leftIcon={<FontAwesomeIcon icon={faPaperclip} />}
         onClick={()=>{
          // handleDownloadFile(params.data.attachmentPath);

          const objectUrl = URL.createObjectURL(params.data.attachmentPath)
          setViewClaimAttachment(objectUrl);
          onOpen();
        }}
         colorScheme="purple"
         variant='solid'
       >
         View
       </Button> 
        },
        {
          headerName: "Action",
          cellRenderer: (params) => <div className='space-x-4'>
            {/* <Button
            size={'xs'}
           leftIcon={<FontAwesomeIcon icon={faDownload} />}
           onClick={()=>{handleDownloadFile(params.data.attachmentPath)}}
           colorScheme="purple"
           variant='solid'
         >
           Download
         </Button> */}
         <Button
         size={'xs'}
           leftIcon={<FontAwesomeIcon icon={faEdit} />}
           onClick={()=>{
            let RowData = params.data;
            console.log("Row Data",RowData);
            console.log("Remainig Limit",RemainingLimit);
            setBillID(RowData.billId)
            setReimbursementType(RowData.reimbursementName);
            setBillDate(moment(RowData.billDate).format('YYYY-MM-DD'));
            setBillNumber(RowData.billNumber);
            setClaimAmount(RowData.claimedAmount);
            setReason(RowData.reason);
            setClientName(RowData.clientId);
            setAttachment(RowData.attachmentPath)
            // setRemainingLimit(Number(RemainingLimit || 0)+Number(RowData.claimedAmount || 0))
            // HandleEdit();
           }}
           colorScheme="blue"
           variant='solid'
         >
           Edit
         </Button>
         <Button
         size={'xs'}
           leftIcon={<FontAwesomeIcon icon={faTrash} />}
           onClick={()=>{onRemoveSelected()}}
           colorScheme="red"
           variant='solid'
         >
           Delete
         </Button>

          </div>
        },
      ]);



      const getRowId = useCallback((params) => {
        return params.data.billId;
      }, []);

      const onRemoveSelected = useCallback(() => {
        const selectedData = EmpBillRef.current.api.getSelectedRows();
        const res = EmpBillRef.current.api.applyTransaction({ remove: selectedData });
        // printResult(res);

         console.log("Selected Data",selectedData);

         const allRows = EmpBillRef.current.api.getModel().rowsToDisplay.map(row => row.data);
         console.log("Left Rows",allRows);

         setEmployeeReimburseRowData(allRows);


      }, []);




      const [PettyCashAdvColDefs, setPettyCashAdvColDefs] = useState([
        // { headerName: "Emp Code", field: "empCode" ,floatingFilter : false},
        // { headerName: "Employee Name", field: "empName" ,floatingFilter : false},
        { headerName: "Request Date", field: "pT_ClaimDate" ,cellRenderer: (params) => <span>{moment(params.data.pT_ClaimDate).format('DD/MM/YYYY')}</span>},
        { headerName: "Purpose", field: "pT_Purpose" },
          { headerName: "Total Request Amount", field: "pT_ClaimAmount" },
        {
          headerName: "Claim Status",
          field: "pT_ClaimStatus",
          cellRenderer: (params) => (
            params.data.pT_ClaimStatus === 'Pending' ||  params.data.pT_ClaimStatus === 'pending'?
            <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Pending
          </span>
           : params.data.pT_ClaimStatus === 'Approved'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Approved
          </span> : params.data.pT_ClaimStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Rejected
          </span>: ''
          ),
        },

        { headerName: "Payment Status", field: "paymentStatus",
        cellRenderer: (params) => (
          params.data.paymentStatus === 'Pending' ||  params.data.paymentStatus === 'pending'?
          <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Pending
        </span>
         : params.data.paymentStatus === 'Done'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
         Done
        </span> : ''
        ),
       },
      ]);


      const [ExpenseDataColDefs, setExpenseDataColDefs] = useState([
        // { headerName: "Employee Code", field: "empCode",floatingFilter : false },
        // {
        //     headerName: "Employee Name",
        //     field: "employeeName"
        //     ,floatingFilter : false
        //   },
          { headerName: "Expense Name", field: "reimbursementName" },
          { headerName: "Ticket ID", field: "reimbursementTicketGenerate" },
          { headerName: "Requested Amount", field: "claimedAmount" },
          { headerName: "Request Date", field: "claimDate",cellRenderer:(params)=><span>{moment(params.data.claimDate).format('DD/MM/YYYY')}</span> },
          { headerName: "Description", field: "reason" },
          {
            headerName: "Claim Status",
            field: "ClaimStatus",
            cellRenderer: (params) => (
              params.data.claimStatus === 'Pending' ?
              <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
              Pending
            </span>
             : params.data.claimStatus === 'Approved'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
             Approved
            </span> : params.data.claimStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
             Rejected
            </span>: ''
            ),
          },
          {
            headerName: "Attachment",
            field: "attachment",floatingFilter : false,
            cellRenderer:(params) => params.data.attachment != null ? 
          <Button colorScheme={'blue'} size={'xs'} onClick={()=>{handleViewFile(params.data.attachment)}}>
            <FontAwesomeIcon className="cursor-pointer mr-1"  icon={faPaperclip} /> View
         </Button> : ''
          },
      ]);


      const handleViewFile = async (DocPath) =>{
        console.log(DocPath)
       await fetch(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
      ).then(response => {
    
        console.log("file Response",response);
    
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            setViewClaimAttachment(alink);
            onOpen();
            // alink.download = fileURL;
            // alink.click();
        })
    
        }).catch((error)=>{
          console.log("File Api Error",error);
        })
      }


      const [ReturnsColumnDefs, setReturnsColumnDefs] = useState([
        { headerName: "Return Date", field: "returnDate", cellRenderer: (params) => <span>{moment(params.data.returnDate).format('DD/MM/YYYY')}</span> },
        { headerName: "Return Amount", field: "returnAmount" },
        { headerName: "Return Method", field: "returnMethod" },
        { headerName: "Return Remarks", field: "returnInfo" },
        { headerName: "Receiving Status", field: "returnStatus",
        cellRenderer: (params) => (
          params.data.returnStatus === "Receiving Pending"?
          <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Receiving Pending
        </span>
         : params.data.returnStatus === 'Received'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
         Received
        </span> : ''
        ),
       },
      ]);



        //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);

      const handleDownloadFile = async (DocPath) =>{
        console.log(DocPath)
       await fetch(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
      ).then(response => {
    
        console.log("file Response",response);
    
        response.blob().then(blob => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
    
            // Setting various property values
            let alink = document.createElement('a');
            alink.href = fileURL;
            alink.download = fileURL;
            alink.click();
        })
    
      }).catch((error)=>{
        console.log("File Api Error",error);
      })
      }


      
    useEffect(() => {

      let TotalKharch = EmployeeReimburseRowData.reduce((sum, Bill) => {
        return Number(sum) + Number(Bill.claimedAmount ||0);
      }, 0)

      setLimitUsed(TotalKharch)

      console.log("Total Kharch",LimitUsed);

    }, [EmployeeReimburseRowData,ClaimAmount])

      useEffect(() => {
        getClientList();
        GetTicketsRowData()
        GetReturnRequestsData();
        GetPettyCashAdvanceData();
        GetExpenseRowData();
        GetEligibilityDetails();
         GetLimitDetails();
      },[])




      const GetEligibilityDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Client/GetPettyCashEligibilitySettingByEmpGuId?EmpGuId=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )

          console.log("Eligibility Response",response);

          setEligibilityDetails(response.data || []);
        } catch (error) {
          console.error(error);
        }
      }



      const GetPettyCashAdvanceData = async () => {
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetAdvanceDetailByEmployee?EmpGuid=${userData.guId}&month=${new Date().getMonth()+1}&year=${new Date().getFullYear()}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("Petty Advance Data Response",response);
            setPettyCashAdvanceRowData(response.data);
          } catch (error) {
            console.error(error);
          }
      }


      const GetTicketsRowData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetReimbursementTotalByEmpGuid?EmpGuid=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )

          console.log("Eligibility Response",response);

          setTicketRowData(response.data)
        } catch (error) {
          console.error(error);
        }
      }


      const GetExpenseRowData = async () => {
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetBillBasedOnMonth?EmpGuid=${userData.guId}&month=${new Date().getMonth()+1}&year=${new Date().getFullYear()}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("Expense Claim Data Response",response);
            setExpenseClaimRowData(response.data);
          } catch (error) {
            console.error(error);
          }

      }



      const GetReturnRequestsData = async () => {
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashReturnDetailsList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
             console.log("Return Data Response",response);
             const res = response.data;

            setReturnRowData(res.filter(data => data.empGuId === userData.guId));
          } catch (error) {
            console.error(error);
          }

      }


      const getClientList = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Client/GetClientInfo?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          // console.log(response);
          setClientList(response.data);
        } catch (error) {
          console.error(error);
        }
      };

      const getEmployeeReimbursementData = async (EmpTicketID) => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetReimbursementByTicketId?Ticket=${EmpTicketID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log("Employee Reimbursement data", response);
          let ReimbursementData = response.data;
          // console.log("TicketID",TicketID);
          setEmployeeReimburseRowData(response.data);
        } catch (error) {
          console.error(error);
        }
      };


      const HandleAddExpense = async () => {

        let body = {
          empGuId: userData.guId,
          empCode: userData.empCode,
          // ticketValid:false,
          ticketGenerateDate: new Date(),
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
        };
        console.log(body);
    
        await axios
          .post(
            `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveReimbursementTicket`,
            body,
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          )
          .then((response) => {
            const res = response.data;
            // console.log(response);
            // console.log("Ticket ",response.data);
            setTicketId(res);
            GetBalanceAmountDetails(userData.guId);
            GetEmpPettyType(userData.guId);
            getEmployeeReimbursementData(res);
           
            onAddReimbursementOpen();
          })
          .catch((error) => {
            console.log(error.response);
            console.log(error.response.data);
          });
    
      }


      const GetLimitDetails = async() => {

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetSummaryReportbyEmp?EmpGuid=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
           )
           console.log("Expense Limit Response" , response);
           const res = response.data
           setExpenseLimitDetails(res);
           setRemainingLimit(res.remanningLimit)


        } catch (error) {
          console.error(error);
        }
      }

      const GetBalanceAmountDetails = async (EmpGuid) => {

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetExpenseBalanceByGuid?EmpGuid=${EmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log("Employee Claim Amount data", response);
          let ClaimAmount = response.data;
          // console.log("TicketID",TicketID);
          setClaimAmountDetails(ClaimAmount);
        } catch (error) {
          console.error(error);
        }
    
      }
    
      const GetEmpPettyType = async (EmpGuid) => {
        console.log(EmpGuid);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetExpenseList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log("Emp Petty Type List", response);
          setReimbursementTypeList(response.data);
        } catch (error) {
          console.log("Emp Petty Type Error", error);
        }
      };


      const SaveAdvanceRequest = async (e) =>{
        e.preventDefault();
      
        let body ={
          empGuId: userData.guId,
          empName: userData.name,
          empCode:userData.empCode,
          pT_ClaimAmount: RequestAmount,
          pT_ClaimDate: RequestDate,
          pT_Purpose: PettyCashPurpose,
          pT_ClaimStatus: "Pending",
          claimMonth: new Date(RequestDate).getMonth()+1,
          claimYear: new Date(RequestDate).getFullYear(),
          companyId:userData.companyId,
          branchId:userData.isActiveBranch
        }
      
        console.log(body);
        
      
        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashMaster`,body
      )
      .then((response) => {
         //  const res = response.data;
          console.log( "Save Petty Cash Response",response);
          // console.log("Add Reimbursement List Response ",response.data);
      
          const res = response.data;
          // console.log("res",res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title:'Done',
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
      
          GetPettyCashAdvanceData();
          GetExpenseRowData();

        onAdvanceRequestClose();
        setPettyCashPurpose('')
        setRequestDate(moment().format('YYYY-MM-DD'))
        setRequestAmount('')
        })
        .catch((error) => {
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Petty Cash Not Added.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });
      }

console.table("Emp Claim Row Data",EmployeeReimburseRowData);
console.log("Attachment", Attachment);

      const AddReimbursementList = async (e) => {
        e.preventDefault();

        // let FinalRemainingAmount = Number(RemainingLimit || 0) - Number(LimitUsed || 0)

        // console.log("New Remaining",FinalRemainingAmount,"Old Remaining",RemainingLimit,"Amount Used",LimitUsed);
        // if(Number(ClaimAmount) <= Number(FinalRemainingAmount) ){
    
  
          const UploadData = new FormData();
      
          UploadData.append("attachmentPath", Attachment);
          UploadData.append("claimDate", ClaimDate);
          UploadData.append("empCode", userData.empCode);
          UploadData.append("empGuId", userData.guId);
          UploadData.append("reimbursementName", ReimbursementType);
          UploadData.append("expenseName",ReimbursementTypeList.filter(data => Number(data.expenseID) === Number(ReimbursementType)).map(filterData => filterData.expenseType)[0])
          UploadData.append("billDate", BillDate);
          UploadData.append("billNumber", BillNumber);
          UploadData.append("claimedAmount", ClaimAmount);
          UploadData.append("claimStatus", "Pending");
          UploadData.append("claimAction", "Pending");
          UploadData.append("reason", Reason);
          UploadData.append("clientId", ClientName);
          UploadData.append("clientName", ClientList.filter(data => Number(data.clientId) === Number(ClientName)).map(filData=> filData.clientName)[0]);
          UploadData.append("reimbursementTicketGenerate", TicketId);
          UploadData.append("companyId", userData.companyId);
          UploadData.append("branchId", userData.isActiveBranch);

          const newBillItem = {
              billId: BillID,
              attachmentPath : UploadData.get('attachmentPath'),
              claimDate : UploadData.get('claimDate'),
              empCode : UploadData.get('empCode'),
              empGuId : UploadData.get('empGuId'),
              reimbursementName : UploadData.get('reimbursementName'),
              expenseName :UploadData.get('expenseName'),
              billDate : UploadData.get('billDate'),
              billNumber : UploadData.get('billNumber'),
              claimedAmount : UploadData.get('claimedAmount'),
              claimStatus : UploadData.get('claimStatus'),
              claimAction : UploadData.get('claimAction'),
              reason : UploadData.get('reason'),
              clientId : UploadData.get('clientId'),
              clientName : UploadData.get('clientName'),
              reimbursementTicketGenerate : UploadData.get('reimbursementTicketGenerate'),
              companyId : UploadData.get('companyId'),
              branchId : UploadData.get('branchId'),
          };
       console.log("New Bill",newBillItem);


       const isFound = EmployeeReimburseRowData.some(data => data.billId === newBillItem.billId)
           
       console.log("Is Found",isFound);

       if(EmployeeReimburseRowData.some(data => data.billNumber === newBillItem.billNumber)){
        alert('Bill Number Already Exists. Please Enter Unique Bill Number.')
       }else{

        if(isFound){
          setEmployeeReimburseRowData(prev => {
            const newState = prev.map((obj)=>{
              if(obj.billId === newBillItem.billId){
                 return {...obj,
                  attachmentPath : UploadData.get('attachmentPath'),
                  claimDate : UploadData.get('claimDate'),
                  empCode : UploadData.get('empCode'),
                  empGuId : UploadData.get('empGuId'),
                  reimbursementName : UploadData.get('reimbursementName'),
                  billDate : UploadData.get('billDate'),
                  billNumber : UploadData.get('billNumber'),
                  expenseName :UploadData.get('expenseName'),
                  claimedAmount : UploadData.get('claimedAmount'),
                  claimStatus : UploadData.get('claimStatus'),
                  claimAction : UploadData.get('claimAction'),
                  reason : UploadData.get('reason'),
                  clientId : UploadData.get('clientId'),
                  clientName : UploadData.get('clientName'),
                  reimbursementTicketGenerate : UploadData.get('reimbursementTicketGenerate'),
                  companyId : UploadData.get('companyId'),
                  branchId : UploadData.get('branchId'),
                }
              }
              return obj
            })
            return newState
          })
         }else{
          setEmployeeReimburseRowData([...EmployeeReimburseRowData,newBillItem])
  
  
         }


         reset();
         setClaimDate(moment().format('YYYY-MM-DD'));
         setBillDate(moment().format('YYYY-MM-DD'));
         setClaimAmount("");
         setBillNumber("");
         setReason("");
        //  setClientName("");
         setReimbursementType("");
        setBillID(BillID+1)

       }

    
      };





      const HandleBillSave = () => {


        if(Number(LimitUsed) <= Number(RemainingLimit) ){



        EmployeeReimburseRowData.forEach(async(item) => {



          const ItemData = new FormData();
        
          ItemData.append("attachmentPath", item.attachmentPath);
          ItemData.append("claimDate", item.claimDate);
          ItemData.append("empCode", item.empCode);
          ItemData.append("empGuId", item.empGuId);
          ItemData.append("reimbursementName", item.expenseName);
          ItemData.append("billDate", item.billDate);
          ItemData.append("billNumber", item.billNumber);
          ItemData.append("claimedAmount", item.claimedAmount);
          ItemData.append("claimStatus", item.claimStatus);
          ItemData.append("claimAction", item.claimAction);
          ItemData.append("totalAmount", LimitUsed);
          ItemData.append("reason", item.reason);
          ItemData.append("clientId", item.clientId);
          ItemData.append("clientName", item.clientName);
          ItemData.append("reimbursementTicketGenerate", item.reimbursementTicketGenerate);
          ItemData.append("companyId", item.companyId);
          ItemData.append("branchId", item.branchId);


          await axios
          .post(
            `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveReimbursement`,
            ItemData
          )
          .then((response) => {
            console.log("Responce IUpload Data", response);
            const res = response.data;
            console.log("res", res);
            if (!toast.isActive(id)) {
            toast({
              id,
              title: res.status,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }

            setBillID(1);
            GetExpenseRowData();
            GetTicketsRowData();
            onAddReimbursementClose();

    
          })
          .catch((error) => {
            console.log(error.response);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: "Bills.",
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });

        })

      }else{
        toast({
          id,
          title: "Expense Limit Exceeded",
          description: "Please Enter the Amount Below Your Expense Limit.",
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }


      }



      const SaveReturnRequest = async (e) => {
        e.preventDefault();

        let body={
            empGuId : userData.guId,
            empCode : userData.empCode,
            empName : userData.name,
            returnDate : moment(ReturnDate).format('YYYY-MM-DD'),
            returnMonth : new Date(ReturnDate).getMonth()+1,
            returnYear : new Date(ReturnDate).getFullYear(),
            returnMethod : ReturnMethod,
            returnAmount : ReturnAmount,
            returnInfo : ReturnInfo,
            companyId : userData.companyId,
            branchId : userData.isActiveBranch,
            returnStatus : "Receiving Pending",
          }

          console.log("save Return request body",body);
          
          await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashReturnDetails`,body
          )
          .then((response) => {
            console.log(response);
            const res = response.data;
            //console.log("res",res);
            if (!toast.isActive(id)) {
              toast({
                id,
                title:res,
                position: 'top',
                status: 'info',
                duration: 2000,
                isClosable: true,
              })
            }


            GetReturnRequestsData();
            onReturnRequestClose();
            setReturnDate(new Date())
            setReturnAmount('')
            setReturnInfo('')
            setReturnMethod('')


          })
          .catch((error) => {
            console.log(error.response);

            if (!toast.isActive(id)) {
              toast({
                id,
                title: 'ERROR',
                description: "Return Request not Added",
                position: 'top',
                status: 'error',
                duration: 2000,
                isClosable: true,
              })
            }
          });

      }

  return (
    <div>
      <div className="flex justify-between items-center border-b border-gray-400 mb-3 pb-3 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">Add Expense</h1>
        <div className='space-x-4'>
          <Button isDisabled={!EligibilityDetails.eligiblityActive} size={'sm'} onClick={()=>{onReturnRequestOpen();}}colorScheme={'purple'} variant={'outline'}>Return Request</Button>
          <Button isDisabled={!EligibilityDetails.eligiblityActive} size={'sm'} onClick={()=>{onAdvanceRequestOpen();}} colorScheme={'purple'} >Request Advance</Button>
          <Button isDisabled={!EligibilityDetails.eligiblityActive} size={'sm'} onClick={()=>{HandleAddExpense();}}colorScheme={'purple'} >Claim Expense</Button>
        </div>
      </div>


      <Tabs size={'sm'} variant={'solid-rounded'} colorScheme={'purple'}>
        <TabList>
          <Tab>Expenses</Tab>  
          <Tab>Petty Cash Advance</Tab>
          <Tab>Return Status</Tab>
       </TabList> 
  <TabPanels>

    <TabPanel>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={TicketRowData} // Row Data for Rows
          columnDefs={TicketsColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>
    <TabPanel>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={PettyCashAdvanceRowData} // Row Data for Rows
          columnDefs={PettyCashAdvColDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>

    <TabPanel>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={ReturnRowData} // Row Data for Rows
          columnDefs={ReturnsColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>

  </TabPanels>
</Tabs>




      <Modal size='2xl' isOpen={isAdvanceRequestOpen} onClose={()=>{
          onAdvanceRequestClose();
          setPettyCashPurpose('')
          setRequestDate(moment().format('YYYY-MM-DD'))
          setRequestAmount('')
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Advance Request</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveAdvanceRequest}>
          <ModalBody>
            <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Employee Name</p>
                <h6 className="font-medium">{userData.name}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Employee Code</p>
                <h6 className="font-medium">{userData.empCode}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Advance Limit</p>
                <h6 className="font-medium">{ExpenseLimitDetails.advanceLimit || 0}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Expense Limit</p>
                <h6 className="font-medium">{ExpenseLimitDetails.expenseLimit || 0}</h6>
              </div>

            </div>

            <hr />


            <div className="grid grid-cols-2 gap-8 mt-4">
            <div className="space-y-2">
                <label>Petty Cash Purpose</label>
                <Input value={PettyCashPurpose || ''} onChange={(e)=>{setPettyCashPurpose(e.target.value)}} borderColor='gray.500' placeholder="Petty Cash Purpose" />
              </div> 

              <div className="space-y-2">
                <label>Request Date</label>
                <Input readOnly value={RequestDate} onChange={(e)=>{setRequestDate(e.target.value)}} type='date' borderColor='gray.500' />
              </div> 

            </div>


            <div className="grid grid-cols-2 gap-8 mt-4">

              <div className="space-y-2">
                <label>Request Amount</label>
                <Input value={RequestAmount} onChange={({target})=>{
                  let ReqAmount = target.value;

                  if (Number(ReqAmount) <= Number(ExpenseLimitDetails.advanceLimit)) {
                    setRequestAmount(ReqAmount);
                  }else{
                    setRequestAmount(ExpenseLimitDetails.advanceLimit)
                  }

                  }} type='number' placeholder="Request Amount" borderColor='gray.500' />
              </div> 
            </div>



          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={()=>{
              onAdvanceRequestClose();
              setPettyCashPurpose('')
              setRequestDate(moment().format('YYYY-MM-DD'))
              setRequestAmount('')
            }} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>


      <Modal
          size="full"
          isOpen={isAddReimbursementOpen}
          onClose={onAddReimbursementClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader bg="gray.400">Add Expense</ModalHeader>
            <ModalCloseButton />
            <hr />

            <ModalBody>
              <div className="flex py-2 gap-8">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Employee Name</p>
                  <h6 className="font-medium">{userData.name}</h6>
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Employee Code</p>
                  <h6 className="font-medium">{userData.empCode}</h6>
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Ticket Id</p>
                  <h6 className="font-medium">{TicketId}</h6>
                </div>

                <div>
                <p className="text-sm text-gray-500">Request Date</p>
                <h6 className="font-semibold">
                  {moment(ClaimDate).format('DD/MM/YYYY')}
                </h6>
              </div>

              <div>
                <p className="text-sm text-gray-500">Balance Amount</p>
                <h6 className="font-semibold">
                  {ExpenseLimitDetails.remainingAmount}
                </h6>
              </div>

                <div>
                <p className="text-sm text-gray-500">Expense Limit</p>
                <h6 className="font-semibold">
                  {Number(RemainingLimit || 0) - Number(LimitUsed || 0)}
                </h6>
              </div>


              {/* <div>
                <p className="text-sm text-gray-500">Total Amount</p>
                <h6 className="font-semibold">
                  {LimitUsed || 0}
                </h6>
              </div> */}



              </div>
              <hr />

              <form onSubmit={AddReimbursementList}>
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-4 mb-4">



                    <div className="space-y-2">
                      <label>Expense Type</label>

                      <InputGroup>
                        <Select
                          isRequired
                          value={ReimbursementType}
                          onChange={(e) => {
                            setReimbursementType(e.target.value);
                          }}
                          borderColor="gray.400"
                          placeholder="Select Expense Type"
                        >
                          {ReimbursementTypeList.map((data, i) => {
                            return (
                              <option key={i} value={data.expenseID}>
                                {data.expenseType}
                              </option>
                            );
                          })}
                        </Select>

                      </InputGroup>
                    </div>

                    <div className="space-y-2">
                      <label>Bill Date</label>
                      <Input
                        isRequired
                        value={BillDate || ''}
                        onChange={(e) => {
                          setBillDate(e.target.value);
                        }}
                        type="date"
                        borderColor="gray.400"
                        placeholder="Employee Code"
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Bill Number</label>
                      <Input
                        isRequired
                        value={BillNumber || ''}
                        onChange={(e) => {
                          setBillNumber(e.target.value);
                        }}
                        borderColor="gray.400"
                        placeholder="Bill Number"
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Expense Amount</label>
                      <Input
                        isRequired
                        value={ClaimAmount || ''}
                        onChange={({target}) => {
                          setClaimAmount(target.value);
                        }}
                        type="number"
                        borderColor="gray.400"
                        placeholder="Expense Amount"
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Description</label>
                      <Textarea
                        value={Reason || ''}
                        onChange={(e) => {
                          setReason(e.target.value);
                        }}
                        borderColor="gray.400"
                        rows={1}
                        placeholder="Enter Description"
                      />
                    </div>




                    <div className="space-y-2">
                      <label>Client Info</label>
                        <Select
                          isRequired
                          value={ClientName}
                          onChange={({target}) => {
                            setClientName(target.value);
                          }}
                          borderColor="gray.400"
                          placeholder="Select Client"
                        >
                          {ClientList.map((data, i) => {
                            return (
                              <option key={i} value={data.clientId}>
                                {data.clientName}
                              </option>
                            );
                          })}
                        </Select>
                    </div>

                    <div className="space-y-2">
                      <label>Attachment</label>
                      <Input
                       isRequired={Attachment === null ? true : false}
                      ref={FileRef}
                        
                        onChange={({target}) => {
                          setAttachment(target.files[0]);
                          console.log(target.files[0]);
                        }}
                        type="file"
                        borderColor="gray.400"
                        placeholder="Attachment"
                      />
                    </div>

                  </div>

                  <div className="mb-6 text-right">
                    <Button leftIcon={<FontAwesomeIcon icon={faPlus} />} type="submit" colorScheme="purple" mr={3}>
                      Add Bill
                    </Button>
                  </div>
                </div>
              </form>

              <div className="ag-theme-alpine-dark">
                <AgGridReact
                  getRowId={getRowId}
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  ref={EmpBillRef}
                  rowData={EmployeeReimburseRowData} // Row Data for Rows
                  columnDefs={ExpensesColumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  rowSelection="multiple" // Options - allows click selection of rows
                  pagination={true}
                  paginationPageSize={10}
                  suppressModelUpdateAfterUpdateTransaction={true}
                  paginationNumberFormatter={paginationNumberFormatter}
                  cacheQuickFilter={true}
                  suppressAggAtRootLevel={true}
                />
              </div>




              <div className='my-6 flex justify-end'>
                <div className='space-y-2'>
                <label>Total Amount</label>
                   <Input isReadOnly borderColor={'gray.400'} value={LimitUsed || 0} />

                   <div className='text-right space-x-4'>
                   <Button colorScheme={'purple'} onClick={HandleBillSave}>Save</Button>
                   <Button
                      onClick={onAddReimbursementClose}
                      colorScheme="purple"
                      variant="outline"
                    >
                      Cancel
                    </Button>
                   </div>
 

                </div>
              </div>

            </ModalBody>
          </ModalContent>
        </Modal>



        <Modal
          size="full"
          isOpen={isViewTicketBillsOpen}
          onClose={onViewTicketBillsClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader bg="gray.400">View Ticket Bills</ModalHeader>
            <ModalCloseButton />
            <hr />

            <ModalBody>

            <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={ExpenseClaimRowData} // Row Data for Rows
          columnDefs={ExpenseDataColDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

            </ModalBody>

            <ModalFooter>

            <Button variant='outline' onClick={()=>{
              onViewTicketBillsClose();
            }} colorScheme='purple' >
              Close
            </Button>

          </ModalFooter>
          </ModalContent>
        </Modal>


        <Modal size='2xl' isOpen={isReturnRequestOpen} onClose={()=>{
                     onReturnRequestClose();
                     setReturnDate(new Date())
                     setReturnAmount('')
                     setReturnInfo('')
                     setReturnMethod('')
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Return Request</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveReturnRequest}>
          <ModalBody>
            <div className='grid grid-cols-2 gap-4 mb-2'>

              <div className='space-y-2'>
                <label>Return Date</label>
                <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={ReturnDate}
                  onChange={(date) => setReturnDate(date)}
                  minDate={new Date()}
                  placeholderText="Return Date"
                  dateFormat={'dd-MM-yyyy'}
                />
              </div>

              <div className="space-y-2">
                <label>Return Amount</label>
                <Input
                  isRequired
                  value={ReturnAmount || ''}
                  onChange={({target}) => {
                    setReturnAmount(target.value);
                  }}
                  type={'number'}
                  borderColor="gray.400"
                  placeholder="Return Amount"
                />
              </div>

              <div className="space-y-2">
                <label>Return Method</label>
                <Select value={ReturnMethod} onChange={({target})=>{setReturnMethod(target.value)}} borderColor={'gray.400'} placeholder='Select Return Method'>
                  <option value={'Cash'}>Cash</option>
                  <option value={'Online'}>Online</option>
                  <option value={'Cheque'}>Cheque</option>
                </Select>
              </div>

              <div className="space-y-2">
                <label>Return Remarks</label>
                <Input
                  isRequired
                  value={ReturnInfo || ''}
                  onChange={({target}) => {
                    setReturnInfo(target.value);
                  }}
                  borderColor="gray.400"
                  placeholder="Return Remarks"
                />
              </div>
            </div>

          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={()=>{
                          onReturnRequestClose();
                          setReturnDate(new Date())
                          setReturnAmount('')
                          setReturnInfo('')
                          setReturnMethod('')
            }} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

        
      <Modal scrollBehavior='inside' size='full' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attachment</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div id="pdf-div"  >
            <embed
              src={ViewClaimAttachment + "#toolbar=0"}
              type="application/pdf"
              className="h-screen w-full !select-none"
              height={800}
              width={500}
            />
            </div>
          </ModalBody>
          <hr />

          <ModalFooter>
            <Button colorScheme='purple' onClick={onClose}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>







    </div>
  )
}

export default EmpExpenseClaim