import React from 'react';
import { Button, Input, useToast, InputGroup,InputRightElement} from '@chakra-ui/react'
import axios from 'axios';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Eye, EyeOff } from "react-feather";
function IsPasswordReset() {

    const [show, setShow] = React.useState(false);
    const handleClick = () => setShow(!show);

    const userData = JSON.parse(localStorage.getItem("user"));
    const toast = useToast();
    const id = "toast";
    const navigate = useNavigate();

    const LoggedInEmail = localStorage.getItem('loggedInEmail')
  
    const [UserName, setUserName] = useState(LoggedInEmail);
    const [OldPassword, setOldPassword] = useState();
    const [NewPassword, setNewPassword] = useState();
    const [ConfirmPassword, setConfirmPassword] = useState();

    const HandleResetPassword = async (e) => {

        e.preventDefault();
    
        let body = {
            empGuId: userData.guId,
            userName: UserName,
            oldPassword: OldPassword,
            password: NewPassword,
            confirmPassword: ConfirmPassword,
            companyId: userData.companyId,
            branchId: userData.branchId,
            isActive: true,
            IsPasswordReset:true
          }
    
          console.log("Reset Password Body",body);
    
          await axios.post(
            `${process.env.REACT_APP_ACQ_URL}/api/Account/ResetPassword`,body
          )
          .then((response) => {
            console.log(response);
            const res = response.data;
            if (!toast.isActive(id)) {
              toast({
                id,
                title:res,
                position: "top",
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            }

            setUserName('');
            setOldPassword('');
            setNewPassword('');
            setConfirmPassword('');
            navigate('/')
              
          })
          .catch((error) => {
            console.error(error);
      
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: `Password not Updated`,
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });
    
    
    
      }

      

  return (
    <div>

<div className="grid md:grid-cols-2 sm:grid-cols-1">

<div className=" bg-slate-800 hidden  md:my-38 md:flex  items-center place-content-center ">
  <img className=" h-auto p-10 lg:p-28 mx-auto" src="/images/ForgotPassword.png" alt=""  />

</div>
<div className="basis-1/2   h-auto md:h-screen flex items-center justify-start py-12 px-4 sm:px-6 lg:px-8 sm:basis-1">
  <div className="max-w-md w-full space-y-8 sm:max-w-md">
    <div>

      <h2 className="mt-6 text-4xl font-bold text-gray-800">Reset Password</h2>

    </div>
          <form onSubmit={HandleResetPassword}>



        <div className=" space-y-5 mx-auto ">

        <div className="space-y-2">
            <label>Username</label>
            <Input value={UserName || ''} isReadOnly onChange={({target})=>{setUserName(target.value)}} isRequired borderColor={"gray.400"} placeholder="Username" />
          </div>


          <div className="space-y-2">
            <label>Old Password</label>
            <InputGroup size="md">
                      <Input
                        value={OldPassword}
                        onChange={(e)=>{setOldPassword(e.target.value)}}
                        // pr="2rem"
                        borderColor='gray.400'
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                      />
                      <InputRightElement width="2.5rem">
                        <Button h="1.75rem" size="xs" onClick={handleClick}>
                          {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                        </Button>
                      </InputRightElement>
                    </InputGroup>          </div>

          <div className="space-y-2">
            <label>New Password</label>
            <InputGroup size="md">
                      <Input
                        value={NewPassword}
                        onChange={(e)=>{setNewPassword(e.target.value)}}
                        // pr="2rem"
                        borderColor='gray.400'
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                      />
                      <InputRightElement width="2.5rem">
                        <Button h="1.75rem" size="xs" onClick={handleClick}>
                          {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                        </Button>
                      </InputRightElement>
                    </InputGroup>          </div>

          <div className="space-y-2">
            <label>Confirm Password</label>
            <InputGroup size="md">
                      <Input
                        value={ConfirmPassword}
                        onChange={(e)=>{setConfirmPassword(e.target.value)}}
                        // pr="2rem"
                        borderColor='gray.400'
                        type={show ? "text" : "password"}
                        placeholder="Enter password"
                      />
                      <InputRightElement width="2.5rem">
                        <Button h="1.75rem" size="xs" onClick={handleClick}>
                          {show ? <EyeOff size='14px'/> : <Eye size='14px'/> }
                        </Button>
                      </InputRightElement>
                    </InputGroup>          </div>

          <div>
            <Button type="submit" className="w-full" colorScheme={"purple"}>
              Submit
            </Button>
          </div>
        </div>

        </form>
  </div>
</div>
</div> 

    </div>
  )
}

export default IsPasswordReset