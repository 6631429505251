import axios from 'axios';

const authApi = axios.create({ 
    baseURL: `${process.env.REACT_APP_ACQ_URL}`,
    headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`
      }
})

export default authApi;
