import {
  Radio,
  RadioGroup,
  Stack,
  Input,
  Button,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  ModalCloseButton,
  Switch,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  useDisclosure,
  Spinner,
  Center,
} from "@chakra-ui/react";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import ReactSelect from "react-select";

function ClientMaster() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [isLoading, setisLoading] = useState(false);
  const AddEmpClientGridRef = useRef();

  const [InActiveClientList, setInActiveClientList] = useState([]);

  const {
    isOpen: isAddEmpClientsOpen,
    onOpen: onAddEmpClientsOpen,
    onClose: onAddEmpClientsClose,
  } = useDisclosure();

  const [ClientId, setClientId] = useState(0);
  const [ClientName, setClientName] = useState();
  const [ClientAddress, setClientAddress] = useState();
  const [ClientPhone, setClientPhone] = useState();

  const [ShowClients, setShowClients] = useState("1");

  const [AllEmplyeeList, setAllEmplyeeList] = useState([]);
  const [SelctdEmp, setSelctdEmp] = useState([]);

  const [SelectedClients, setSelectedClients] = useState([]);

  const [EmpClientsRowData, setEmpClientsRowData] = useState([]);

  const [EmpClientColumnDefs, setEmpClientColumnDefs] = useState([
    {
      headerName: "Client Name",
      field: "clientName",
    },

    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleEmpClientSwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
  ]);

  const [AddEmpClientRowData, setAddEmpClientRowData] = useState([]);

  const [AddEmpClientColumnDefs, setAddEmpClientColumnDefs] = useState([
    {
      headerName: "Client Name",
      field: "clientName",
      headerCheckboxSelection: true,
      checkboxSelection: (params) => {
        return params.data.clientName;
      },
    },

    {
      headerName: "Client Address",
      field: "clientAddress",
    },

    {
      headerName: "Client Phone",
      field: "clientPhoneNo",
    },
  ]);

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Client Name",
      field: "clientName",
    },

    {
      headerName: "Client Address",
      field: "clientAddress",
    },

    {
      headerName: "Client Phone",
      field: "clientPhoneNo",
    },

    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleClientSwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },

    {
      headerName: "Action",
      field: "clientName",
      cellRenderer: (params) => (
        <div>
          <Button
            onClick={() => {
              let ClientData = params.data;
              setClientId(ClientData.clientId);
              setClientName(ClientData.clientName);
              setClientAddress(ClientData.clientAddress);
              setClientPhone(ClientData.clientPhoneNo);
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
          >
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    enableCellTextSelection: true,
  }));

  useEffect(() => {
    GetClientList();
    GetEmpList();
  }, []);

  const GetClientList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Client/GetClientInfo?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Client List Response", response);
      const res = response.data;
      setInActiveClientList(res.filter((data) => data.isActive === false));
      setRowData(res.filter((data) => data.isActive === true));
    } catch (error) {
      console.error(error);
    }
  };

  const GetEmpList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log(response);
      const res = response.data;
      let ActiveEmpList = res.filter((data) => data.isActive === true);
      setAllEmplyeeList(ActiveEmpList);
    } catch (error) {
      console.error(error);
    }
  };

  const GetEmpClientList = async (EMPGUID) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Client/GetEmpClientRelationlistByEmp?EmpGuId=${EMPGUID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Emp Client List", response);
      setEmpClientsRowData(response.data);
      HandleAllocateClientList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleAllocateClientList = async (EmpClients) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Client/GetClientInfo?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Allocate Client List Response", response);

      const res = response.data;

      console.log(
        "Filetered Clients",
        res.filter(
          (obj1) => !EmpClients.find((obj2) => obj1.clientId === obj2.clientId)
        )
      );

      const filteredData = res.filter(
        (obj1) => !EmpClients.find((obj2) => obj1.clientId === obj2.clientId)
      );
      setAddEmpClientRowData(
        filteredData.filter((data) => data.isActive === true)
      );

      setisLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onSelectionChanged = useCallback(() => {
    var ClientList = AddEmpClientGridRef.current.api.getSelectedRows();
    console.log(ClientList);

    setSelectedClients(ClientList);
  }, []);

  const HandleEmpClientSwitch = async (checked, ClientData) => {
    setRowData((prev) => {
      const newState = prev.map((obj) => {
        if (obj.clientId === ClientData.clientId) {
          return { ...obj, isActive: checked };
        }
        return obj;
      });
      return newState;
    });

    let body = {
      clientId: ClientData.clientId,
      clientName: ClientData.clientName,
      clientAddress: ClientData.clientAddress,
      clientPhoneNo: ClientData.clientPhoneNo,
      isActive: checked,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log(body);
    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Client/SaveClientMaster`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        GetClientList();
      })
      .catch((error) => {
        console.error(error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: ` Designation not Updated`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const HandleClientSwitch = async (checked, ClientData) => {
    setRowData((prev) => {
      const newState = prev.map((obj) => {
        if (obj.clientId === ClientData.clientId) {
          return { ...obj, isActive: checked };
        }
        return obj;
      });
      return newState;
    });

    let body = {
      clientId: ClientData.clientId,
      clientName: ClientData.clientName,
      clientAddress: ClientData.clientAddress,
      clientPhoneNo: ClientData.clientPhoneNo,
      isActive: checked,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log(body);
    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Client/SaveClientMaster`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        GetClientList();
      })
      .catch((error) => {
        console.error(error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: ` Designation not Updated`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const SaveClientDetails = async (e) => {
    e.preventDefault();

    let body = {
      clientId: ClientId,
      clientName: ClientName,
      clientAddress: ClientAddress,
      clientPhoneNo: ClientPhone,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log("Save Client Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Client/SaveClientMaster`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetClientList();
        setClientId(0);
        setClientName("");
        setClientAddress("");
        setClientPhone("");
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Designation not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const SaveAllocatedClients = async () => {
    let body = SelectedClients.map((data) => {
      return {
        empGuId: SelctdEmp.guId,
        empName: SelctdEmp.fullName,
        empCode: SelctdEmp.empCode,
        clientId: data.clientId,
        clientName: data.clientName,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
        isActive: true,
      };
    });

    console.log("Allocated Clients Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Client/SaveEmpClientRelation`,
        body
      )
      .then((response) => {
        const res = response.data;
        //console.log("res",res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        GetEmpClientList(SelctdEmp.guId);
        onAddEmpClientsClose();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Clients not Saved.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Client Master</h1>
      </div>

      <Tabs colorScheme={"purple"} variant={"solid-rounded"} size={"sm"}>
        <TabList>
          <Tab>Client Details</Tab>
          <Tab>Emp Client Relation</Tab>
        </TabList>

        <TabPanels>
          <TabPanel px={0}>
            <Box className="bg-white rounded-2xl p-4  items-end mb-4 ">
              <form onSubmit={SaveClientDetails}>
                <div className="flex gap-6 items-end">
                  <div className="space-y-2">
                    <label>Client Name</label>
                    <Input
                      isRequired
                      value={ClientName || ""}
                      onChange={({ target }) => {
                        setClientName(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Client Name"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Client Address</label>
                    <Input
                      value={ClientAddress || ""}
                      onChange={({ target }) => {
                        setClientAddress(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Client Address"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Client Phone</label>
                    <Input
                      type={"number"}
                      value={ClientPhone || ""}
                      onChange={({ target }) => {
                        setClientPhone(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Client Phone"
                    />
                  </div>

                  <Button type="submit" colorScheme={"purple"}>
                    Save
                  </Button>
                  <Button
                    onClick={() => {
                      setClientId(0);
                      setClientName("");
                      setClientAddress("");
                      setClientPhone("");
                    }}
                    variant={"outline"}
                    colorScheme={"purple"}
                  >
                    Reset
                  </Button>
                </div>

                <div className="text-right space-x-4 mt-4"></div>
              </form>
            </Box>

            <div className="flex justify-end mb-4">
              <div className="bg-white drop-shadow-sm flex gap-2 items-center rounded-lg py-1.5 px-3">
                <label className="font-medium">Show Clients :</label>
                <RadioGroup onChange={setShowClients} value={ShowClients}>
                  <Stack direction="row" gap={2}>
                    <Radio borderColor={"gray.400"} value="1">
                      Active
                    </Radio>
                    <Radio borderColor={"gray.400"} value="2">
                      InActive
                    </Radio>
                  </Stack>
                </RadioGroup>
              </div>
            </div>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={ShowClients === "1" ? rowData : InActiveClientList} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
            </div>
          </TabPanel>
          <TabPanel px={0}>
            <Box className="bg-white rounded-2xl p-4 flex gap-8 items-end mb-4 ">
              <div className="space-y-2 w-1/4">
                <label>Select Employee</label>
                <ReactSelect
                  options={AllEmplyeeList}
                  value={SelctdEmp}
                  onChange={(value) => {
                    setSelctdEmp(value);
                    setisLoading(true);
                    GetEmpClientList(value.guId);
                  }}
                  getOptionLabel={(option) =>
                    `${option.fullName} - ${option.empCode}`
                  }
                  getOptionValue={(option) => option.guId}
                  placeholder="Select Employee"
                />
              </div>
              <Button
                isDisabled={SelctdEmp.length === 0 ? true : false}
                onClick={() => {
                  onAddEmpClientsOpen();
                }}
                leftIcon={<FontAwesomeIcon icon={faPlus} />}
                colorScheme={"purple"}
              >
                Allocate Clients
              </Button>
            </Box>

            {isLoading ? (
              <Center>
                <Spinner thickness="4px" color={"purple.500 "} size="lg" />
              </Center>
            ) : (
              <div className="ag-theme-alpine">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={EmpClientsRowData} // Row Data for Rows
                  columnDefs={EmpClientColumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                  enableCellTextSelection={true}
                />
              </div>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal
        size="4xl"
        isOpen={isAddEmpClientsOpen}
        onClose={onAddEmpClientsClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Allocate Clients</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div className="grid grid-cols-2 gap-4">
              <div className="mb-4">
                <h6 className="text-lg font-semibold">
                  Employee Name:-{" "}
                  <span className="font-normal">{SelctdEmp.fullName}</span>
                </h6>
                <h6 className="text-lg font-semibold">
                  Employee Code:-{" "}
                  <span className="font-normal">{SelctdEmp.empCode}</span>
                </h6>
              </div>
              <div className="mb-4">
                <h6 className="text-lg font-semibold">
                  Designation:-{" "}
                  <span className="font-normal">
                    {SelctdEmp.designationName}
                  </span>
                </h6>
                <h6 className="text-lg font-semibold">
                  Department:-{" "}
                  <span className="font-normal">
                    {SelctdEmp.departmentName}
                  </span>
                </h6>
              </div>
            </div>
            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={AddEmpClientGridRef} // Ref for accessing Grid's API
                rowData={AddEmpClientRowData} // Row Data for Rows
                columnDefs={AddEmpClientColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                rowSelection={"multiple"}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
                onSelectionChanged={onSelectionChanged}
              />
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button onClick={SaveAllocatedClients} colorScheme="purple" mr={3}>
              Save
            </Button>
            <Button
              onClick={onAddEmpClientsClose}
              colorScheme="purple"
              variant="outline"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ClientMaster;
