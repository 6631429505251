import axios from 'axios';
import React, { useState, useEffect } from 'react'
import HelpGuideMenu from './HelpGuideMenu';
import { NavLink, useLocation } from 'react-router-dom';

const HelpSidebar = () => {

  const location = useLocation();
  const { pathname } = location;

  const [HelpMenuList, setHelpMenuList] = useState([]);

  useEffect(()=>{
    GetHelpGuideMenu();
  },[])

  const GetHelpGuideMenu = async () => {
    try{
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/HelpGuide/GetHelpGuideMenuList`
      )
      // console.log("All Menu List",response);
      setHelpMenuList(response.data)
    } catch (err) {
      console.error(err);
    }
  }


  return (
    <div>

      <div
        id="sidebar"
        className={`flex flex-col m-2 mb-8  top-0 rounded-2xl absolute z-40 left-0  lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-60 md:sidebar-expanded:!w-60 lg:w-60 lg:sidebar-expanded:!w-60 2xl:!w-60 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out`}
      >
                <div className="flex justify-between mb-10 pr-3 sm:px-2 bg-slate-100 p-1 drop-shadow-md rounded-lg">
          {/* Close button */}
          <button
            className="lg:hidden text-slate-500 hover:text-slate-400"
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}

            <div className="flex justify-center gap-2 items-center">
              <img className="h-7 ml-2" src="/HRMusterLogo.png" alt='HR Muster Logo' />
              <h1 className="text-gray-800 font-bold text-lg">
                HR Muster Help
              </h1>
            </div>
        </div>

        <div>
        <ul>
              {HelpMenuList.map((data,i) => {
                return <HelpGuideMenu
                 key={i}
                activecondition={
                  pathname === "/viewclients"
                }
              >
                {(handleClick, open) => {
                  return (
                    <React.Fragment>
                      <a
                        href="#"
                        className={`block text-slate-300 hover:text-slate-50 truncate transition duration-150 ${
                          (pathname === "/sdf" ||
                            pathname.includes("dashboard")) &&
                          "hover:text-slate-200"
                        }`}
                        onClick={(e) => {
                           e.preventDefault();
                           handleClick();
                        }}
                      >
                        <div className="flex items-center justify-between">
                          <div className="flex items-center">
                            <span className="text-sm font-medium hover:ml-2 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              {data.categoryName}
                            </span>
                          </div>
                          <div className="flex shrink-0 ml-2">
                            <svg
                              className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                open && "rotate-180"
                              }`}
                              viewBox="0 0 12 12"
                            >
                              <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                            </svg>
                          </div>
                        </div>
                      </a>
                      <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                        <ul className={`pl-4 mt-1 ${!open && "hidden"}`}>
                          {data?._helpGuideSubCategoryList?.map((SubData,j)=>{
                            return <li key={j} className="mb-1 last:mb-0">
                            <NavLink
                              to={`/help/${data.categoryId}/${SubData.subCategoryId || 0}`}
                              className={({ isActive }) =>
                                "block text-slate-300 hover:text-slate-50 hover:translate-x-2 transition duration-150 truncate " +
                                (isActive ? "!text-indigo-500" : "")
                              } >
                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                               {SubData.subCategoryName}
                              </span>
                            </NavLink>
                          </li>
                          })}
                        </ul>
                      </div>
                    </React.Fragment>
                  );
                }}
              </HelpGuideMenu>
              })}

            </ul>
        </div>
      </div>
    </div>
  )
}

export default HelpSidebar