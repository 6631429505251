import React,{useRef,useMemo,useState, useCallback, useEffect} from 'react'
import { AgGridReact } from "ag-grid-react";
import _ from 'lodash';
import axios from "axios";
import moment from 'moment';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { Button, Center, Spinner, useToast} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { LuDownload,LuRefreshCw } from 'react-icons/lu';
import useValidation from '../Utils/useValidation';

function AllAttendanceReport(){

    const Validation = useValidation();

    const userData = JSON.parse(localStorage.getItem("user"));

    const toast = useToast();
    const id = "toast";

    const [IsLoading, setIsLoading] = useState(false);
    const [SyncLoading, setSyncLoading] = useState(false)

    const [SelectedMonth, setSelectedMonth] = useState(new Date());
    const [AllEmpData, setAllEmpData] = useState([]);
    const [FinalRowData, setFinalRowData] = useState([]);

    const MonthNames = [ "JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]

    const gridRef = useRef()
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling


    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
      const defaultColDef = useMemo(() => ({
        //  flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        resizable : true
      }));

      const [columnDefs, setColumnDefs] = useState([

      ]);


     console.log("Column Defs",columnDefs);

      useEffect(() => {
        console.log("RowData",rowData);
        setFinalRowData(_.flatten(rowData))
      }, [rowData])
      


      const getAllDaysInMonth = (year, month) => {
        const date = new Date(year, month, 1);
    
        const dates = [];
    
        while (date.getMonth() === month) {
          dates.push({
            headerName: `${new Date(date).getDate()}-${MonthNames[new Date(date).getMonth()]}-${new Date(date).getFullYear()}`,
            // field: new Date(date).toLocaleDateString("en-CA"),
            // width:140
            children: [
              {
                headerName:'Status',
                field: moment(date).format('YYYY-MM-DD'),
                width:100
              },
              {
                headerName:'In Time',
                field: new Date(date).getDate()+'InTime',
                width:120
              },
              {
                headerName:'Out Time',
                field: new Date(date).getDate()+'OutTime',
                width:120
              }
            ],
          });
          date.setDate(date.getDate() + 1);
        }
    
        return dates;
      };

      const HandleGridHeader = (year, month) => {

        let StartingHead = [
          {
            headerName: "Emp Code",
            field: "empCode",
            width:140
          },
          {
            headerName: "Emp Name",
            field: "empName",
          },
          ]

         let DatesHead = getAllDaysInMonth(year,month)

         setColumnDefs(_.union(StartingHead,DatesHead))

      }

      const getAllEmpData = async () => {
        setIsLoading(true);
        setRowData([])       
        setFinalRowData([]);
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log( " All Employees",response);
          const res = response.data;
          let ISActiveEmp = res.filter((data)=>data.isActive === true)
          setAllEmpData(ISActiveEmp);
          console.log("IS Active EMp",ISActiveEmp);
          
          HandleRowData(ISActiveEmp);
 
          HandleGridHeader(new Date(SelectedMonth).getFullYear(),new Date(SelectedMonth).getMonth());
        } catch (error) {
          console.error(error);
        }
      };


      const HandleRowData = (AllEmployeeData) =>{
        // console.log("Start date",moment(SelectedMonth).startOf("month").format("YYYY-MM-DD"),"End Date",moment(SelectedMonth).endOf("month").format("YYYY-MM-DD"));
        Promise.all(
            AllEmployeeData.map(
              async (data) => {
                const response = await axios.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetEmpFinalAttendanceByDate?EmpGuid=${data.guId}&fromdate=${moment(SelectedMonth).startOf("month").format("YYYY-MM-DD")}&Todate=${moment(SelectedMonth).endOf("month").format("YYYY-MM-DD")}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                );
                const EmpAttData = response.data;
  
                console.log("Emp Att Data",response);
                let FinalRowData = []
  
                console.log("Final Row Data",FinalRowData)
  
                let TempData = _.uniqBy(EmpAttData, "empGuid").map((attData)=>{
                  let EmpRowData = [];
  
                   EmpRowData.push({
                      empName : attData.empName,
                      empCode : attData.empCode
                  });
  
                  EmpAttData.map((AllAttData) => {
                      return EmpRowData.push({
                       [moment(AllAttData.attendanceDate).format('YYYY-MM-DD')]:AllAttData.attendanceStatus,
                       [new Date(AllAttData.attendanceDate).getDate()+'InTime']:AllAttData.inTime,
                       [new Date(AllAttData.attendanceDate).getDate()+'OutTime']:AllAttData.outTime,
                      });
                    })
                    let AssignedObj = Object.assign({},...EmpRowData)                
                  return Object.assign({},...EmpRowData)
                })
  
                return setRowData((prev)=>{
                  return [...prev,TempData]
                })
              }
            )
          );

          setIsLoading(false)
      }

console.log("Final variable row data",FinalRowData)

const exportData = () => {

  // let SelMonthName = MonthNames[new Date(SelectedDateMonth).getMonth()]
  const params = {
    fileName: `All Attendance Report of ${userData.activeBranchName}.csv`,
  };

  gridRef.current.api.exportDataAsCsv(params);
};


const AttendanceHelper = async () => {
   setSyncLoading(true)
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/CloneAttendance?AttendanceDate=${new Date(SelectedMonth).getFullYear()}-${new Date(SelectedMonth).getMonth()+1}-01&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log("Run Process Attendance Reponse",response);

    if(response.data === 'True') {
      toast({
        id,
        title: response.data,
        description: `Attendance Processed for Month ${MonthNames[new Date(SelectedMonth).getMonth()]}`,
        position: "top",
        status: "success",
        duration: 5000,
        isClosable: true
      });
    }else{
      toast({
        id,
        title: response.data,
        position: "top",
        status: "info",
        duration: 5000,
        isClosable: true
      });
    }
  } catch (err) {
    console.error(err);
   } finally {
    setSyncLoading(false);
  }

  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/ProcessDailyAttendance?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Attendancedate=${moment(SelectedMonth).format('YYYY-MM-DD')}`
    );
    console.log("Process Attendance Response", response);
  } catch (error) {
    console.error(error);
  }
}

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">All Attendance Report</h1>
        <div className='space-x-4'>
         <Button isLoading={SyncLoading} loadingText='Syncing...' onClick={AttendanceHelper} leftIcon={<LuRefreshCw className="text-lg" />} colorScheme="purple" >Sync Attendance</Button>
         <Button isDisabled={FinalRowData?.length === 0 ? true : false} onClick={()=>{exportData()}} leftIcon={<LuDownload />} colorScheme={'purple'} >Export</Button>
        </div>
      </div>

     {SyncLoading === false ?

      <div className="bg-white rounded-2xl p-4 flex gap-4 items-end  mb-4">
          
          <div className="space-y-2">
            <label>Select Month/Year</label>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
              selected={SelectedMonth}
              onChange={(date) => {
                setSelectedMonth(date);
              }}
              showMonthYearPicker
              dateFormat="MM/yyyy"
              placeholderText="Select Month/Year"
              minDate={new Date(Validation?.payrollYear,Validation?.payrollMonth,1)}
              maxDate={new Date()}
            />
          </div>
        
          <Button onClick={()=>{
            getAllEmpData();
          }} leftIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />} colorScheme={"purple"}>Search</Button>
        </div> : <Center>
         <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
        </Center> }

        {IsLoading === false?

      <div className="ag-theme-alpine mb-4">
        {FinalRowData.length !== 0 ? 
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={FinalRowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            rowSelection="multiple" // Options - allows click selection of rows
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
            suppressExcelExport={true}
          /> : ''}
        </div> 
     :  <Center>
         <Spinner   thickness='4px' color={'purple.500 '}  size='lg'  />
        </Center>}


    </div>
  )
}

export default AllAttendanceReport