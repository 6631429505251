import React,{useState} from 'react';
import { Input, Textarea, useToast } from '@chakra-ui/react'
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

function CreateCompany() {

  const userData = JSON.parse(localStorage.getItem('user'));
  const toast = useToast();
  const id = "toast";

  const [CompanyName, setCompanyName] = useState();
  const [CompanyCode, setCompanyCode] = useState();
  const [CompanyType, setCompanyType] = useState();
  const [CompanyAddress, setCompanyAddress] = useState();
  const [CompanyPhone, setCompanyPhone] = useState();
  
  const [CompanyLogo, setCompanyLogo] = useState();


  const navigate = useNavigate();



  const HandleCreateCompany = async (e) => {
    e.preventDefault();


    const CompanyData = new FormData();

    CompanyData.append('userId',userData.userId)
    CompanyData.append('companyName',CompanyName)
    CompanyData.append('companyCode',CompanyCode)
    CompanyData.append('isActive',true)
    CompanyData.append('companyType',CompanyType)
    CompanyData.append('companyAddress',CompanyAddress)
    CompanyData.append('companyPhone',CompanyPhone)
    CompanyData.append('logo',CompanyLogo)

  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/Company/SaveCompany`,CompanyData
  )
    .then((response) => {
      console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res.errorMessage,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }

      if(userData.isActiveBranch === 0){
        navigate('/createbranch');
      }

      
    })
    .catch((error) => {
      console.error(error);

      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Company not Added`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });

    let body1 = {
      userName : userData.userName,
      password : userData.password
    }
    
    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Account/VerifyUserLogin`,body1
    )
    .then((response) => {
      console.log(response);
      const json =  response.data;
    
      console.log("json",json);
      if (json === 'Failed') {
        // redirect
        alert("Login Again");
      }else{
        //console.log("Success");
        // console.log(json);
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(json));
        //  window.location.reload(true);
        //navigate('/dashboard');
      }
    
    
    })
    .catch((error) => {
      console.error(error);
    
      // if (!toast.isActive(id)) {
      //   toast({
      //     id,
      //     title: "ERROR",
      //     description: `Network Error`,
      //     position: "top",
      //     status: "error",
      //     duration: 2000,
      //     isClosable: true,
      //   });
      // }
    });


  }
  return (
    <div>
      <div className="grid md:grid-cols-2 sm:grid-cols-1">

<div className=" bg-slate-800 hidden  md:my-38 md:flex  items-center place-content-center ">
  <img className=" h-auto p-10 lg:p-28 mx-auto" src="/images/CreateCompany.png" alt=""  />

</div>
<div className="basis-1/2   h-auto md:h-screen flex items-center justify-start py-12 px-4 sm:px-6 lg:px-8 sm:basis-1">
  <div className="max-w-md w-full space-y-8 sm:max-w-md">
    <div>
      <h2 className="mt-6 text-4xl font-bold text-gray-800">Company Details</h2>
    </div>
    <form className="mt-6 space-y-6" onSubmit={HandleCreateCompany} >
    <div className="space-y-2 ">
                    <label>Company Name</label>
                    <Input
                      value={CompanyName || ""}
                      onChange={({target})=>{setCompanyName(target.value)}}
                      borderColor="gray.500"
                      placeholder="Enter Company Name"
                    />
                  </div>
                  <div className="space-y-2 ">
                    <label>Company Code</label>
                    <Input
                      value={CompanyCode || ""}
                      onChange={({target})=>{setCompanyCode(target.value)}}
                      borderColor="gray.500"
                      placeholder="Enter Company Code"
                    />
                  </div>


                  <div className="space-y-2 ">
                    <label>Company Type</label>
                    <Input
                      value={CompanyType || ""}
                      onChange={({target})=>{setCompanyType(target.value)}}
                      borderColor="gray.500"
                      placeholder="Enter Company Type"
                    />
                  </div>

                  <div className="space-y-2 ">
                    <label>Company Logo</label>
                    <Input 
                      type={'file'}
                      onChange={({target})=>{setCompanyLogo(target.files[0])}}
                      borderColor="gray.500"
                      placeholder="Enter Company Type"
                    />
                  </div>


                  <div className="space-y-2">
                    <label>Company Phone</label>
                    <Input
                      value={CompanyPhone || ""}
                      onChange={({target})=>{setCompanyPhone(target.value)}}
                      borderColor="gray.500"
                      placeholder="Enter Company Phone Number"
                    />
                  </div>

                  <div className="space-y-2">
                  <label>Company Address</label>
                  <Textarea
                    value={CompanyAddress || ""}
                    rows={1}
                    onChange={({target})=>{setCompanyAddress(target.value)}}
                    borderColor="gray.500"
                    placeholder="Enter Company Address"
                  />
                </div>

      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 hover:drop-shadow-lg focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-purple-400"
        >
         Create Company
        </button>

      </div>
    </form>
  </div>
</div>
</div> 
    </div>
  )
}

export default CreateCompany