import React, { useEffect } from "react";
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers, faBuildingUser, faUserTie, faTriangleExclamation, } from "@fortawesome/free-solid-svg-icons";
import axios from "axios";
import StatCard from "./UI Components/StatCard";
import { useAuth } from "./Utils/AuthContext";
import useFetch from "./Utils/useFetch";

function Dashboard() {

  const {userData} = useAuth();

  const { FetchedData: SeparationData } = useFetch('/api/EmployeeResignation/GetEmpResignationList',{
    CompanyId: userData?.companyId,
    BranchId: userData?.isActiveBranch
  })

  const { FetchedData: DashboardData } = useFetch('/api/EmployeeSalary/GetEmpSalaryDashboardData',{
    CompanyId: userData?.companyId,
    BranchId: userData?.isActiveBranch
  })

  const { FetchedData: DepartmentDataList } = useFetch('/api/Master/GetDepartmentTotalEmpData',{
    CompanyId: userData?.companyId,
    BranchId: userData?.isActiveBranch
  })

  const { FetchedData: PayrollCostData } = useFetch('/api/PayrollEstimate/GetPayrollGraphDataForYear',{
    CompanyId: userData?.companyId,
    BranchId: userData?.isActiveBranch,
    PayrollYear: new Date().getFullYear()
  })



  const monthNames = [
    "JANUARY",
    "FEBRUARY",
    "MARCH",
    "APRIL",
    "MAY",
    "JUNE",
    "JULY",
    "AUGUST",
    "SEPTEMBER",
    "OCTOBER",
    "NOVEMBER",
    "DECEMBER",
  ];

  let monthIndex = new Date().getMonth();
  let monthName = monthNames[monthIndex];

  const formatYAxis = (tickItem) => {
    return userData.currencySymbol + " " + tickItem;
  };

  useEffect(()=>{
    if(SeparationData){
      SeparationData?.filter((filData) => filData.resignationStatus === "Approved" || filData.resignationStatus === "Finalized")?.map(async(data)=>{
        if(new Date(data.lastWorkingDate) < new Date() ){
          try {
            const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ActiveEmployeeByGuid?EmpGuid=${data.guId}&IsActive=false`);
            // console.log("Out Response",response);
          } catch (err) {
            console.error("Out Error",err);
          }
        } else {
          try {
            const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ActiveEmployeeByGuid?EmpGuid=${data.guId}&IsActive=true`);
            // console.log("In Response",response);
          } catch (err) {
            console.error("In Error",err);
          }
        }
      })
    }
  },[SeparationData])



  return (
    <div>
      <div className="relative bg-indigo-300 p-4 sm:p-6 rounded-2xl overflow-hidden mb-6">
        <div
          className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden md:block"
          aria-hidden="true"
        >
          <svg
            width="319"
            height="198"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
              <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
              <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
              <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="welcome-b"
              >
                <stop stopColor="#A5B4FC" offset="0%" />
                <stop stopColor="#818CF8" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="50%"
                y1="24.537%"
                x2="50%"
                y2="100%"
                id="welcome-c"
              >
                <stop stopColor="#4338CA" offset="0%" />
                <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <g transform="rotate(64 36.592 105.604)">
                <mask id="welcome-d" fill="#fff">
                  <use xlinkHref="#welcome-a" />
                </mask>
                <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
                <path
                  fill="url(#welcome-c)"
                  mask="url(#welcome-d)"
                  d="M64-24h80v152H64z"
                />
              </g>
              <g transform="rotate(-51 91.324 -105.372)">
                <mask id="welcome-f" fill="#fff">
                  <use xlinkHref="#welcome-e" />
                </mask>
                <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
                <path
                  fill="url(#welcome-c)"
                  mask="url(#welcome-f)"
                  d="M40.333-15.147h50v95h-50z"
                />
              </g>
              <g transform="rotate(44 61.546 392.623)">
                <mask id="welcome-h" fill="#fff">
                  <use xlinkHref="#welcome-g" />
                </mask>
                <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
                <path
                  fill="url(#welcome-c)"
                  mask="url(#welcome-h)"
                  d="M40.333-15.147h50v95h-50z"
                />
              </g>
            </g>
          </svg>
        </div>

        <div className="relative">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1">
            Hello, {userData.name}! 👋
          </h1>
          <p>Welcome Back</p>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-6 gap-6">
        <StatCard
          StatName="Total Employees"
          StatNumber={DashboardData?.totalEmployee}
          Description={"-"}
          IconBg={"green.100"}
          StatIcon={<FontAwesomeIcon
            className=" text-green-600 text-xl"
            icon={faUsers}
          />}
        />

        <StatCard
          StatName="Total Departments"
          StatNumber={DashboardData?.department}
          Description={"-"}
          IconBg={"blue.100"}
          StatIcon={<FontAwesomeIcon
            className=" text-blue-600 text-2xl"
            icon={faBuildingUser}
          />}
        />

        <StatCard
          StatName="New Joinee"
          StatNumber={DashboardData?.newJoinee}
          Description={monthName}
          IconBg={"purple.100"}
          StatIcon={<FontAwesomeIcon
            className=" text-purple-600 text-2xl"
            icon={faUserTie}
          />}
        />

        <StatCard
          StatName="Pending Salary Structure"
          StatNumber={DashboardData?.pendingSalaryStructure}
          Description={"No. of Employees"}
          IconBg={"red.100"}
          StatIcon={<FontAwesomeIcon
            className=" text-red-600 text-xl"
            icon={faTriangleExclamation}
          />}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8 mb-6">
        <div className="bg-white rounded-2xl drop-shadow-md p-4 ">
          <div className="border-b border-slate-400 pb-2 mb-4">
            <h1 className="text-lg font-semibold text-gray-800">
              No. of Employees w.r.t Department
            </h1>
          </div>

          <ResponsiveContainer width="100%" height={400}>
            <BarChart
              width={500}
              height={300}
              data={DepartmentDataList}
              margin={{
                top: 5,
                // right: 30,
                // left: 20,
                bottom: 5,
              }}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="departmentName" />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="count"
                barSize={35}
                name="No. of Employees"
                fill="#8884d8"
              />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white rounded-2xl drop-shadow-md p-4 ">
          <div className="border-b flex justify-between border-slate-400 pb-2 mb-4">
            <h1 className="text-lg font-semibold text-gray-800">
              Payroll Cost
            </h1>

            <h6 className="text-lg font-semibold text-gray-800">
              {new Date().getFullYear()}
            </h6>
          </div>

          {/* <div>
                <ul className='max-h-80 h-auto overflow-auto'>
                  {_.orderBy(BirthdayList,['dateOfBirth'], ['desc']).map((data,i)=>{
                    return <li key={i} className="border-b border-slate-200 last:border-0 ">
                    <Link
                      className="flex justify-between items-center py-2  hover:bg-slate-100 px-2"
                      to="#0"
                    >
                      <div className="flex items-center">
                      <span className="text-2xl mr-2">🥳</span>
                      <span className="block "> Wish <span className="font-semibold">{data.employeeFullName}</span> Happy Birthday!</span>
                      </div>
                      <span className="block text-xs font-medium text-slate-400">{new Date(data.dateOfBirth).toLocaleDateString('en',BirthDateOptions)}</span>
                    </Link>
                  </li>
                  }) }

                  
          </ul>
                </div> */}

          <div>
            <ResponsiveContainer height={400}>
              <LineChart width={500} height={300} data={PayrollCostData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="payrollMonth" />
                <YAxis tickFormatter={formatYAxis} />
                <Tooltip />
                <Legend verticalAlign="top" height={36} />
                <Line
                  type="monotone"
                  dataKey="totalCost"
                  name="Payroll Cost"
                  stroke="#8884d8"
                  activeDot={{ r: 8 }}
                />
              </LineChart>
            </ResponsiveContainer>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
