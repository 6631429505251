import { Box, Button, Checkbox, Input, Select, Switch } from "@chakra-ui/react";
import { faBattleNet } from "@fortawesome/free-brands-svg-icons";
import { faHandPointRight } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import usePost from "../Utils/usePost";
import useValidation from "../Utils/useValidation";

const PayrollConfiguration = () => {
  
  const userData = JSON.parse(localStorage.getItem("user"));
  const { IsPostLoading: IsConfigurationLoading, PostData: PostConfigurationData } = usePost('/api/PayrollConfiguration/SavePayrollConfiguration');
  const { IsPostLoading: IsFinYearLoading, PostData: PostFinYearData } = usePost('/api/Master/SaveFinancialYearMaster');
  
  const Validation = useValidation();

  const [AsPerAttendance, setAsPerAttendance] = useState(false);
  const [PayrollCutOffDate, setPayrollCutOffDate] = useState();
  const [PayrollPaymentDate, setPayrollPaymentDate] = useState();
  const [AttendanceType, setAttendanceType] = useState();
  const [IsCalculateByLastDay, setIsCalculateByLastDay] = useState(false)
  const [PayrollStartFrom, setPayrollStartFrom] = useState();
  const [CalculationTypeList, setCalculationTypeList] = useState([]);
  const [FinancialYear, setFinancialYear] = useState();
  const [MonthDays, setMonthDays] = useState(30);

  useEffect(()=>{
    const [selectedMonth, selectedYear] = [new Date(PayrollStartFrom).getMonth()+1, new Date(PayrollStartFrom).getFullYear()]
    if (selectedMonth < 4) {
      setFinancialYear(`${selectedYear - 1}-${selectedYear}`);
    } else {
      setFinancialYear(`${selectedYear}-${selectedYear + 1}`);
    }
  },[PayrollStartFrom]);

  useEffect(() => {
    GetPayrollConfigurationData();
    getAttendanceTypeMasterList();
  }, [])
  

  const getAttendanceTypeMasterList = async()=>{
    try {
      const response= await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetAttendanceTypeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Get Attendance Type List Response",response);
       setCalculationTypeList(response.data);
    } catch (error) {
      console.error(error);
    }
  }



  const GetPayrollConfigurationData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollConfiguration/GetPayrollConfiguration?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )

      console.log("Get Configuration Data",response);

      const res = response.data;
       let month = new Date().getMonth();
       let year = new Date().getFullYear();

      console.log("Cutt off Date",year,month,res.payrollCutOffDate)

      setPayrollCutOffDate(new Date(year,month,res.payrollCutOffDate||1));
      setPayrollPaymentDate(new Date(year,month,res.payrollPaymentDate||1));
      setAttendanceType(res.attendanceType);
      setAsPerAttendance(res.isAsPerAttendance)
      setMonthDays(res.flatWorkingDays);
      setPayrollStartFrom(new Date(res.payrollStartFrom || new Date()));
      setIsCalculateByLastDay(res.isLastworkingDay);
    } catch (error) {
      console.error(error);
    }
  }

//  console.log("Payroll Start From", PayrollStartFrom);

  const SavePayrollConfigurations = async (e) => {
    e.preventDefault();

    let body = {
      payrollCutOffDate : new Date(PayrollCutOffDate).getDate(),
      attendanceType : Number(AttendanceType),
      payrollPaymentDate : new Date(PayrollPaymentDate).getDate(),
      companyId : userData.companyId,
      branchId : userData.isActiveBranch,
      flatWorkingDays:MonthDays,
      isAsPerLeave: !AsPerAttendance ? true : false,
      isAsPerAttendance : AsPerAttendance,
      isLastworkingDay : IsCalculateByLastDay,
      payrollStartFrom : moment(PayrollStartFrom).format('YYYY-MM-DD')
    }

    console.log("Save Payroll Configuration Body",body);
   
    const response = await PostConfigurationData(body);

    if(response){
      GetPayrollConfigurationData();
    }

    let body2 = {
      financialYearName: FinancialYear,
      isActive: true,
      isCurrent: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    }

    console.log("Finan Year Body",body2 );

    await PostFinYearData(body2);

    

  }

// console.log("Attendance Type",AttendanceType);



  return (
    <div className="pb-56">
      <div className=" border-b border-gray-400 mb-4 pb-2 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">
          Payroll Configuration
        </h1>
      </div>


      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
      <Box className="bg-white rounded-2xl p-4 drop-shadow-md">
        <form className="space-y-4" onSubmit={SavePayrollConfigurations}>
        <div className="space-y-2">
          <label>Payroll Cut-Off Date</label>
          <DatePicker
            readOnly={Validation?.payrollMonth > 0 ? true : false}
            required
            className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
            selected={PayrollCutOffDate}
            onChange={(date) =>{setPayrollCutOffDate(date)}}
            // minDate={minDate}
            // maxDate={maxDate}
             renderCustomHeader={() => <div></div>}
            // renderDayContents={renderDayContents}
            dateFormat="dd"
            placeholderText="Select Payroll Cut-Off Date"
          />
        </div>

        <div className="space-y-2">
            <label>Payroll Payment Date</label>
            <DatePicker
              required
              readOnly={Validation?.payrollMonth > 0 ? true : false}
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
              selected={PayrollPaymentDate}
              onChange={(date) => setPayrollPaymentDate(date)}
               minDate={PayrollCutOffDate}
              // maxDate={maxDate}
               renderCustomHeader={() => <div></div>}
              // renderDayContents={renderDayContents}
              dateFormat="dd"
              placeholderText="Select Payroll Payment Date"
            />
          </div>

          <div className="space-y-2">
            <label>Calculate monthly salary based on</label>
            <Select isReadOnly={Validation?.payrollMonth > 0 ? true : false} required value={AttendanceType} isRequired onChange={({target})=>{setAttendanceType(target.value)}} borderColor={"gray.400"} placeholder="Select Attendance Type">
              {CalculationTypeList.map((data,i)=>{
                return <option key={i} value={data.attendanceTypeId}>{data.attendanceTypeName}</option>
              })}
            </Select>
          </div>

          <div className="space-y-2">
            <label>Processing Type</label>
            <div className="flex gap-6">
              <label>As Per Attendance</label>
              <Switch isChecked={AsPerAttendance} onChange={({target})=>{
                setAsPerAttendance(target.checked);
              }} colorScheme="green" />
            </div>
          </div>


            <div className="space-y-2">
              <label>Start your first payroll from</label>
              <DatePicker
                required
                readOnly={Validation?.payrollMonth > 0 ? true : false}
                className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
                selected={PayrollStartFrom}
                onChange={(date) => setPayrollStartFrom(date)}
                // minDate={new Date(MinPayRollDate)}
                dateFormat="MM/yyyy"
                showMonthYearPicker
              />
            </div>

            <div className="space-y-2 ">
              <label>Financial Year Started From</label>
              <Input isReadOnly={Validation?.payrollMonth > 0 ? true : false} value={FinancialYear || ''} readOnly placeholder="Financial Year" />
            </div>

          {Number(AttendanceType) === 1?
          <>
            <div className="space-y-2">
              <div className="flex justify-between">
               <label>Month Days</label>
               <Checkbox isReadOnly={Validation?.payrollMonth > 0 ? true : false} isChecked={IsCalculateByLastDay} onChange={({target})=>{
                setIsCalculateByLastDay(target.checked);
                if(target.checked){
                  setMonthDays(0);
                }
               }} borderColor={'gray.400'}>Last Day</Checkbox>
              </div>
              {/* <Input isRequired={Number(AttendanceType) === 1 ? true : false} value={MonthDays || ''} onChange={({target})=>{setMonthDays(target.value)}} type={'number'} borderColor={'gray.400'} placeholder="Month Days" /> */}
              <Select isReadOnly={Validation?.payrollMonth > 0 ? true : false} isDisabled={IsCalculateByLastDay} isRequired value={MonthDays} onChange={({target})=>{setMonthDays(target.value)}} borderColor={'gray.400'} placeholder="Select Month Days">
                <option value={'25'}>25 Days</option>
                <option value={'26'}>26 Days</option>
                <option value={'27'}>27 Days</option>
                <option value={'28'}>28 Days</option>
                <option value={'29'}>29 Days</option>
                <option value={'30'}>30 Days</option>
                <option value={'31'}>31 Days</option>
              </Select>  
            </div>

          </>
           : ''}

          {Validation?.payrollMonth > 0 || <div className="text-right">
            <Button isLoading={IsConfigurationLoading || IsFinYearLoading} loadingText="Saving..." type="submit" colorScheme={"purple"}>Save</Button>
          </div>}
        </form>
      </Box>

      <Box className="bg-white rounded-2xl p-4 drop-shadow-md">
        <div className="space-y-4">
          <div className="space-y-1">
            <h2 className="font-semibold text-gray-700"><FontAwesomeIcon icon={faBattleNet} className="text-blue-600 text-lg mr-2" />Payroll Payment Date</h2>
            <p className="text-sm text-gray-400">Payroll payment date refers to the date on which an employer pays its employees for their work, as per the company's payroll schedule and compliance with relevant laws and regulations.</p>
          </div>
          <div className="space-y-1">
            <h2 className="font-semibold text-gray-700"><FontAwesomeIcon icon={faBattleNet} className="text-blue-600 text-lg mr-2" />Payroll Cut-off Date</h2>
            <p className="text-sm text-gray-400">Payroll cut-off date is the deadline for an employer to receive and process employee data, such as hours worked and leave taken, in order to calculate the payroll and issue paychecks on the scheduled payment date. This date can vary depending on the company's payroll schedule and policies.</p>
          </div>
          <div className="space-y-1">
            <h2 className="font-semibold text-gray-700"><FontAwesomeIcon icon={faBattleNet} className="text-blue-600 text-lg mr-2" />Attendance Type</h2>
            <p className="text-sm text-gray-400">Attendance Type refers to the type on which attendance will be counted on the month. For Example:</p>
            <p className="text-sm text-gray-400"><span className="font-semibold text-gray-700"><FontAwesomeIcon icon={faHandPointRight} className="mr-1.5 text-rose-400" />Calendar Days : </span>Calendar days means the days are counted as 30 days of all the month fixed</p>
            <p className="text-sm text-gray-400"><span className="font-semibold text-gray-700"><FontAwesomeIcon icon={faHandPointRight} className="mr-1.5 text-rose-400" />Working Days : </span>Wroking days means the actual working days of a month</p>
          </div>
          {/* <div className="space-y-1">
            <h2 className="font-semibold text-gray-700"><FontAwesomeIcon icon={faBattleNet} className="text-blue-600 text-lg mr-2" />Processing Type</h2>
            <p className="text-sm text-gray-400">Processing Type refers to </p>
            <p className="text-sm text-gray-400"><span className="font-semibold text-gray-700"><FontAwesomeIcon icon={faHandPointRight} className="mr-1.5 text-rose-400" />As Per Leave : </span>As per Leave refers to</p>
            <p className="text-sm text-gray-400"><span className="font-semibold text-gray-700"><FontAwesomeIcon icon={faHandPointRight} className="mr-1.5 text-rose-400" />As Per Attendance : </span>As per Attendance refers to</p>
          </div> */}
          {Number(AttendanceType) === 1?
          <div className="space-y-1">
            <h2 className="font-semibold text-gray-700"><FontAwesomeIcon icon={faBattleNet} className="text-blue-600 text-lg mr-2" />Month Days</h2>
            <p className="text-sm text-gray-400">Month Days is the days on which Salary will be calculated</p>
          </div> : ''}
        </div>
      </Box>

      </div>
    </div>
  );
}

export default PayrollConfiguration;
