import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { Box, Button, useDisclosure, useToast,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, 
    Input,
    InputGroup,
    Select,
    InputRightAddon,Tooltip} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileImport, faPlus } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import _ from "lodash";
import useValidation from "../Utils/useValidation";

function FinalAdhocDeductions() {


    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = "toast";
    const Validation = useValidation();
    const monthNames = ["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER",];
    const [AdhocDeductionMasterList, setAdhocDeductionMasterList] = useState([]);
    const [ImportedDeductionData, setImportedDeductionData] = useState([]);
    const [AddDeductionRowData, setAddDeductionRowData] = useState([]);
    const [AdhocDeductionName, setAdhocDeductionName] = useState();
    const [DeductionMasterName, setDeductionMasterName] = useState();
    const [DeductionMasterType, setDeductionMasterType] = useState();
    const [EmpList, setEmpList] = useState([]);
    const [ImportDataMonth, setImportDataMonth] = useState(new Date());
    const [ImportDeductionExcelHeads, setImportDeductionExcelHeads] = useState([
          { label: "empGuId", key: `empGuId`},
          { label: "employeeName", key: `employeeName`},
          { label: "empCode", key: `empCode`},
          { label: "deductionAmount", key: `deductionAmount`},
          { label: "month", key: `month`},
          { label: "year", key: `year`}
        ])

    const {
        isOpen: isAddAdhocDeductionOpen,
        onOpen: onAddAdhocDeductionOpen,
        onClose: onAddAdhocDeductionClose,
      } = useDisclosure();

      const {
        isOpen: isImportAdhocDeductionOpen,
        onOpen: onImportAdhocDeductionOpen,
        onClose: onImportAdhocDeductionClose,
      } = useDisclosure();

    const {
        isOpen: isAdhocDeductionMasterAddOpen,
        onOpen: onAdhocDeductionMasterAddOpen,
        onClose: onAdhocDeductionMasterAddClose,
      } = useDisclosure();

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Employee Code",
        field: "empCode",
      },
      {
            headerName: "Employee Name",
            field: "employeeName",
          },
  

                          
          {
            headerName: "Deduction Name",
            field: "deductionName",
          },
            {
              headerName: "Amount",
              field: "deductionAmount",
              editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'

            },
  
            {
              headerName: "Month",
              field: "month",
            },
  
            // {
            //   headerName: "Pay Action",
            //   field: "payAction",
            //   editable:true, singleClickEdit: true,
            //   cellEditor: 'agSelectCellEditor',
            //   cellEditorParams: {
            //      values: ['Pay', 'Hold'],
            //   } ,cellClass:'editable-grid-cell'
            // },
  
            {
              headerName: "Remarks",
              field: "remark",
              editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'
            },


            {
                headerName: "Action",
                cellRenderer : (params) => <Button 
                onClick={async()=>{
                    let AdhocData = params.data;
    
    
                    let body={
                        payrollAdhocDeductionId : AdhocData.payrollAdhocDeductionId,
                        employeeName : AdhocData.employeeName,
                        empCode : AdhocData.empCode,
                        empGuId : AdhocData.empGuId,
                        deductionId : AdhocData.deductionId,
                        deductionAmount : AdhocData.deductionAmount,
                        remark : AdhocData.remark,
                        payAction : AdhocData.payAction,
                        month : AdhocData.month,
                        year : AdhocData.year,
                        companyId : userData.companyId,
                        branchId : userData.isActiveBranch,
                        isLock : true,
                      }
    
    
                    console.log("Row Save Body",body)
    
    
                    await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/AdhocDeduction/SaveAdhocDeduction`,body
                    )
                    .then((response) => {
                      const res = response.data;
                      console.log("res",res);
                  
                      if (!toast.isActive(id)) {
                        toast({
                          id,
                          title:res,
                          position: 'top',
                          status: 'info',
                          duration: 2000,
                          isClosable: true,
                        })
                      }
                  
                    GetAdhocDeductionList(AdhocData.month,AdhocData.year);
    
                  
                    })
                    .catch((error) => {
                      console.log(error.response);
                      console.log(error.response.data);
                  
                      if (!toast.isActive(id)) {
                        toast({
                          id,
                          title: 'ERROR',
                          description: "Deduction not Updates.",
                          position: 'top',
                          status: 'error',
                          duration: 2000,
                          isClosable: true,
                        })
                      }
                    });
    
    
                }}
                 size={'xs'} colorScheme='blue'>Save</Button>
              },

      ]);


      const [AdhocDeductionColumnDefs, setAdhocDeductionColumnDefs] = useState([
        {
            headerName: "Employee Name",
            field: "employeeName",
          },
  
          {
              headerName: "Employee Code",
              field: "empCode",
            },
            {
              headerName: "Amount",
              field: "deductionAmount",
              singleClickEdit: true ,editable:true, cellClass:'editable-grid-cell'
            },
  
            {
              headerName: "Month",
              field: "month",
            },

            {
                headerName: "Month",
                field: "year",
              }

      ]);


            // Table Pagination
            const paginationNumberFormatter = useCallback((params) => {
              return "[" + params.value.toLocaleString() + "]";
            }, []);
          
            //Table columns properties applies to all columns
            const defaultColDef = useMemo(() => ({
              flex: 1,
              sortable: true,
              filter: "agTextColumnFilter",
              floatingFilter: true,
              cacheQuickFilter: true,
              cellClass: 'no-border',
              enableCellTextSelection:true
  
            }));



            useEffect(()=>{
                getEmployeeList();
                GetAdhocDeductionList(new Date(ImportDataMonth).getMonth()+1,new Date(ImportDataMonth).getFullYear());
                GetAdhocDeductionMasterList();
              },[])


              const getEmployeeList = async() =>{
                try {
            
                  const response = await axios.get(
                    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                  )
                  console.log("Employee List",response.data);
            
                  const res = response.data;

                  let ActiveEmpList = res.filter((data)=>data.isActive === true);
            
                  setEmpList(ActiveEmpList);
                  
                } catch (error) {
                  console.error(error)
                }
            
              }

              const GetAdhocDeductionList = async(MONTH,YEAR) => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_ACQ_URL}/api/AdhocDeduction/GetAdhocDeductionlist?month=${MONTH}&year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                        )

                        console.log("Adhoc Deduction List Response",response);

                        setRowData(response.data);

                } catch (error) {
                    console.error(error)
                }
              }


              const GetAdhocDeductionMasterList = async() => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_ACQ_URL}/api/AdhocDeduction/GetAdhocDeductionMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                        )

                        console.log("Adhoc Deduction Master List Response",response);
                        const res = response.data;

                        setAdhocDeductionMasterList(res);



                } catch (error) {
                    console.error(error)
                }
              }





              
              const SaveDeductionMaster = async (e) => {

                e.preventDefault();

                let body = {
                    deductionName : DeductionMasterName,
                    deductionType : DeductionMasterType,
                    companyId : userData.companyId,
                    branchId : userData.isActiveBranch
                  }

                  console.log("Save Deduction Master Body",body);



                  await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/AdhocDeduction/SaveAdhocDeductionMaster`,body
                  )
                  .then((response) => {
                    const res = response.data;
                    console.log("res",res);
                
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title:res,
                        position: 'top',
                        status: 'info',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
                
                  GetAdhocDeductionMasterList();
                  onAdhocDeductionMasterAddClose();
                  setDeductionMasterName('');
                  setDeductionMasterType('');
                
                  })
                  .catch((error) => {
                    console.error(error);
                    console.log(error.response.data);
                
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: 'ERROR',
                        description: "Deduction Master not Added.",
                        position: 'top',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
                  });
              }


   console.log("Deduction Name", _.compact(AdhocDeductionMasterList.map((data)=>{
    if(data.deductionId === 3){
       return  data.deductionName
    }
   }))[0]);





   const SaveTableAdhocDeductions = async () => {
         let body = AddDeductionRowData;

         console.log("Table Save Adhoc Deduction Body",body);


         
         await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/AdhocDeduction/ImportAdhocDeduction`,body
         )
         .then((response) => {

            console.log("Save Response",response);

           const res = response.data;

       
           if (!toast.isActive(id)) {
             toast({
               id,
               title:res,
               position: 'top',
               status: 'info',
               duration: 2000,
               isClosable: true,
             })
           }
       
         GetAdhocDeductionList(new Date(ImportDataMonth).getMonth()+1,new Date(ImportDataMonth).getFullYear());
         onAddAdhocDeductionClose();
         setAdhocDeductionName('')
       
         })
         .catch((error) => {
           console.log(error.response);
           console.log(error.response.data);
       
           if (!toast.isActive(id)) {
             toast({
               id,
               title: 'ERROR',
               description: "Deduction not Added.",
               position: 'top',
               status: 'error',
               duration: 2000,
               isClosable: true,
             })
           }
         });


   }


   const ImportedDeductionReadExcel = (file)=>{

    const promise = new Promise((resolve,reject) => {
      
     const fileReader = new FileReader();
     fileReader.readAsArrayBuffer(file);
 
     fileReader.onload=(e)=>{
       const bufferArray = e.target.result;
 
       const wb = XLSX.read(bufferArray,{type:'buffer'});
       const wsname = wb.SheetNames[0];
 
       const ws = wb.Sheets[wsname];
 
       const data = XLSX.utils.sheet_to_json(ws, {raw: false});
 
       resolve(data)
     } 
 
     fileReader.onerror = (error) => {
       reject(error)
     }
 
    });
 
    promise.then((data)=>{
      console.log("Deduction Imported Data",data);

      let FinalData = data.map((obj)=>{
        return {...obj,
          companyId:userData.companyId,
          branchId:userData.isActiveBranch
        }
       })

       console.log("Deduction Final Data",FinalData);
       setImportedDeductionData(FinalData);

       HandleImportedDeductionData(FinalData);


      });
 }



 const HandleImportedDeductionData = async (FinalData) => {


    let body = FinalData.map((data)=>{
        return { ...data,
            deductionId:AdhocDeductionName
        }
    })

    console.log("Import Adhoc Deduction Body",body)
         
    await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/AdhocDeduction/ImportAdhocDeduction`,body
    )
    .then((response) => {

       console.log("Save Response",response);

      const res = response.data;

  
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: 'top',
          status: 'info',
          duration: 2000,
          isClosable: true,
        })
      }
  
    GetAdhocDeductionList(new Date(ImportDataMonth).getMonth()+1,new Date(ImportDataMonth).getFullYear());
    onImportAdhocDeductionClose();
    setAdhocDeductionName('')
  
    })
    .catch((error) => {
      console.log(error.response);
      console.log(error.response.data);
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title: 'ERROR',
          description: "Deduction not Added.",
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    });



 }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Adhoc Deductions</h1>
        <div className="flex items-center gap-4">
            <label>Data Month :</label>
            <div>
            <DatePicker
            className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
            selected={ImportDataMonth}
            onChange={(date) => {
              setImportDataMonth(date);
              GetAdhocDeductionList(new Date(date).getMonth()+1,new Date(date).getFullYear());
            }}
            dateFormat="MM/yyyy"
            minDate={new Date(Validation?.payrollStartFrom)}
            maxDate={new Date()}
            showMonthYearPicker
            placeholderText="Select Month"
          />
            </div>
          </div>
      </div>



      <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end mb-4 '>

      <div className="space-y-2">
    <label>Deduction Name</label>
    <InputGroup>
            <Select
              isRequired
              value={AdhocDeductionName}
              onChange={(e) => {
                setAdhocDeductionName(e.target.value);
              }}
              borderColor="gray.400"
              placeholder="Select Deduction Name"
            >
              {AdhocDeductionMasterList.map((data, i) => {
                return (
                  <option key={i} value={data.deductionId}>
                    {data.deductionName}
                  </option>
                );
              })}
            </Select>
            <Tooltip
              rounded="5px"
              hasArrow
              label="Add Deduction Name"
              bg={'black'}
              color="white"
            >
              <InputRightAddon
                onClick={onAdhocDeductionMasterAddOpen}
                className="cursor-pointer"
                bg="purple.400"
                children={
                  <FontAwesomeIcon
                    className="text-white"
                    icon={faPlus}
                  />
                }
              />
            </Tooltip>
          </InputGroup>

</div>


<div className="space-y-2">
    <label>Import Data for Month</label>
    <DatePicker
      required
        className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2   outline-none"
        selected={ImportDataMonth}
        onChange={(date) => {
          setImportDataMonth(date);
          GetAdhocDeductionList(new Date(date).getMonth()+1,new Date(date).getFullYear());

        }}
        dateFormat="MM/yyyy"
        minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
        maxDate={new Date()}
        showMonthYearPicker
        placeholderText="Select Month"
      />
  </div>

        <Button onClick={()=>{
            let DeductionRowData = EmpList.map((data)=>{
                return {
                    employeeName : data.fullName,
                    empCode : data.empCode,
                    empGuId : data.guId,
                    deductionId : AdhocDeductionName,
                    deductionAmount : 0,
                    month : new Date(ImportDataMonth).getMonth()+1,
                    year : new Date(ImportDataMonth).getFullYear(),
                    companyId : userData.companyId,
                    branchId : userData.isActiveBranch,

                  }
            })
            setAddDeductionRowData(DeductionRowData);
            onAddAdhocDeductionOpen();
        }} isDisabled={AdhocDeductionName === undefined ? true : false} colorScheme='purple' ><FontAwesomeIcon icon={faPlus} className='mr-2'/>Add Deduction</Button>
        <Button  onClick={()=>{
            let DeductionRowData = EmpList.map((data)=>{
                return {
                    employeeName : data.fullName,
                    empCode : data.empCode,
                    empGuId : data.guId,
                    deductionId : AdhocDeductionName,
                    deductionAmount : 0,
                    month : new Date(ImportDataMonth).getMonth()+1,
                    year : new Date(ImportDataMonth).getFullYear(),
                    companyId : userData.companyId,
                    branchId : userData.isActiveBranch,

                  }
            })
            setAddDeductionRowData(DeductionRowData);
            onImportAdhocDeductionOpen();
        }} isDisabled={AdhocDeductionName === undefined? true : false} colorScheme='purple' ><FontAwesomeIcon icon={faFileImport} className='mr-2'/>Import Deductions</Button>

      </Box>

     


      
      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>


      <Modal isOpen={isAdhocDeductionMasterAddOpen} onClose={onAdhocDeductionMasterAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Deduction</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveDeductionMaster}>
          <ModalBody className="space-y-4">

          <div className="space-y-2">
                <label>Deduction Name</label>
                <Input value={DeductionMasterName} onChange={({target})=>{setDeductionMasterName(target.value)}} borderColor='gray.500' placeholder="Deduction Name" />
            </div>

            <div className="space-y-2">
                <label>Deduction Type</label>
                <Select
                  isRequired
                  value={DeductionMasterType}
                  onChange={(e) => {
                    setDeductionMasterType(e.target.value);
                  }}
                  borderColor="gray.500"
                  placeholder="Select Deduction Type"
                >
                      <option  value='1'>
                      Re-Accuring
                      </option>
                      <option  value='2'>
                      Non Re-Accuring
                      </option>
                      
                </Select>
                        
                </div>

          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={onAdhocDeductionMasterAddClose} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>





      <Modal scrollBehavior="inside" size='full' isOpen={isAddAdhocDeductionOpen} onClose={onAddAdhocDeductionClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Adhoc Deduction</ModalHeader>
          <ModalCloseButton />
          <hr />

          <ModalBody>
            <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Deduction Name</p>
                <h6 className="font-medium">{ _.compact(AdhocDeductionMasterList.map((data)=>{
    if(data.deductionId === Number(AdhocDeductionName)){
       return  data.deductionName
    }
   }))[0]}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Month</p>
                <h6 className="font-medium">{monthNames[new Date(ImportDataMonth).getMonth()]}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Year</p>
                <h6 className="font-medium">{new Date(ImportDataMonth).getFullYear()}</h6>
              </div>
            </div>

            <hr />

            <div className="ag-theme-alpine mt-2">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={AddDeductionRowData} // Row Data for Rows
                columnDefs={AdhocDeductionColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
            </div>



          </ModalBody>
          <hr />
          <ModalFooter>
            <Button onClick={()=>{SaveTableAdhocDeductions()}} variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={onAddAdhocDeductionClose} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>

        </ModalContent>
      </Modal>



      <Modal size='full' isOpen={isImportAdhocDeductionOpen} onClose={onImportAdhocDeductionClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Adhoc Deduction Data</ModalHeader>
          <ModalCloseButton onClick={()=>{setImportedDeductionData([])}} />
          <hr />
            <ModalBody>
              <div className='mt-4 flex items-end gap-5'>

              <div className="space-y-1">
                <p className="text-sm text-gray-500">Deduction Name</p>
                <h6 className="font-medium">{ _.compact(AdhocDeductionMasterList.map((data)=>{
    if(data.deductionId === Number(AdhocDeductionName)){
       return  data.deductionName
    }
   }))[0]}</h6>
              </div>

               <CSVLink data={AddDeductionRowData} headers={ImportDeductionExcelHeads} filename={"Import Deduction.csv"}>
                 <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Deduction Import</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                ImportedDeductionReadExcel(file);
              }}
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(ImportedDeductionData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setImportedDeductionData([]);
                    onImportAdhocDeductionClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default FinalAdhocDeductions