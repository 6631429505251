import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Input,
  Button,
  Switch,
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Textarea,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";

export default function Announcements() {

  const userData = JSON.parse(localStorage.getItem('user'));
  const toast = useToast();
  const id = "toast";


  const [ViewAnnouncementDescription, setViewAnnouncementDescription] = useState();

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable

  const Today = new Date();

  const StartDateCurrent = moment(Today).startOf("month").format('YYYY-MM-DD');
  const EndDateCurrent = moment(Today).endOf("month").format('YYYY-MM-DD');

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  const [AnnouncementDateRange, setAnnouncementDateRange] = useState([new Date(StartDateCurrent),new Date(EndDateCurrent)]);

  const [AnnouncementStartDate, AnnouncementEndDate] = AnnouncementDateRange;

  const {
    isOpen: ismodalopen,
    onOpen: onmodalopen,
    onClose: onmodalclose,
  } = useDisclosure();

  const [AnnouncementType, setAnnouncementType] = useState();
  const [AnnouncementDescription, setAnnouncementDescription] = useState();
  const [StartDate, setStartDate] = useState();
  const [EndDate, setEndDate] = useState();

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Announcement Type",
      field: "announcementType",
    },
    {
      headerName: "Announcement Description",
      field: "description",
      cellRenderer:(params) => (
        <div className="cursor-pointer" onClick={()=>{
          setViewAnnouncementDescription(params.data.description);
          onmodalopen();
        }}>
          {params.data.description}
        </div>
      )
    },

    {
      headerName: "Start Date",
      field: "startDate",
      valueGetter:(params) => new Date(params.data.startDate).toLocaleDateString('en-GB')
    },
    {
      headerName: "End Date",
      field: "endDate",
      valueGetter:(params) => new Date(params.data.endDate).toLocaleDateString('en-GB')
    },
    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          size="md"
        />
      ),
    },
  ]);
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  const HandleAttendanceHistoryChange = (dates) => {
    const [start, end] = dates;
    console.log("Dates",dates)
    setAnnouncementDateRange(dates);

    getAnnouncementList(start,end);

  };


useEffect(()=>{
  getAnnouncementList(AnnouncementStartDate,AnnouncementEndDate);
},[])

  const getAnnouncementList = async (FromDate,ToDate) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Announcement/GetAnnouncmentDataByDate?Startdate=${new Date(FromDate).toLocaleDateString('en-CA')}&EndDate=${new Date(ToDate).toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Announcement List",response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  }


  const SaveAnnouncement = async (e) => {
     
    e.preventDefault();
    let body ={
      announcementType: AnnouncementType,
      description: AnnouncementDescription,
      startDate: StartDate,
      endDate: EndDate,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
  }

  console.log("announcement Body" , body);

  await axios
  .post(`${process.env.REACT_APP_ACQ_URL}/api/Announcement/SaveAnnouncmentData`, body)
  .then((response) => {
    console.log(response);
    const res = response.data;
    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }


    getAnnouncementList(AnnouncementStartDate,AnnouncementEndDate);
    setAnnouncementType('');
    setAnnouncementDescription('');
    setStartDate('');
    setEndDate('');

  })
  .catch((error) => {
    console.error(error);

    if (!toast.isActive(id)) {
      toast({
        id,
        title: "ERROR",
        description: `Leave not Added`,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  });

  }
  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Announcements</h1>

        <div className='flex items-center justify-end'>
        <label className="font-semibold text-lg mr-3 ">Announcement Data Range :</label>
        <div>
        <DatePicker
          className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2   outline-none"
          selectsRange={true}
          startDate={AnnouncementStartDate}
          endDate={AnnouncementEndDate}
          onChange={(update) => {
            HandleAttendanceHistoryChange(update);
          }}
          dateFormat="dd/MM/yyyy"
          // isClearable={true}
          placeholderText="Select Attendance Range"
        />
        </div>



        </div>

      </div>
      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                Add Announcements
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
            
        <form onSubmit={SaveAnnouncement}>

          <div className="grid grid-cols-2 gap-8">

            <div className="space-y-4">

            <div className='space-y-2'>
              <label>Announcement Type</label>
              <Input value={AnnouncementType || ''} onChange={({target})=>{setAnnouncementType(target.value)}} borderColor='gray.400' placeholder='Announcement Type' />
            </div>

            <div className="grid grid-cols-2 gap-6">

            <div className='space-y-2'>
              <label>Start Date</label>
              <Input value={StartDate || ''} onChange={({target})=>{setStartDate(target.value)}} type={'date'} borderColor='gray.400' placeholder='Start Date' />
            </div>

            <div className='space-y-2'>
              <label>End Date</label>
              <Input value={EndDate || ''} onChange={({target})=>{setEndDate(target.value)}} type={'date'} borderColor='gray.400' placeholder='End Date' />
            </div>

            </div>
            </div>


            <div>

            <div className='space-y-2'>
              <label>Announcement Description</label>
              <Textarea  value={AnnouncementDescription || ''} onChange={({target})=>{setAnnouncementDescription(target.value)}} rows={5} borderColor='gray.400' placeholder='Announcement Description' />
            </div>
            </div>

          </div>


          <div className="text-right my-6">
            <Button type="submit" colorScheme={'purple'}>Save</Button>

          </div>
        </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Modal isOpen={ismodalopen} onClose={onmodalclose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Announcement Description</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
           <div>
            {ViewAnnouncementDescription}
           </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme='purple' mr={3} onClick={onmodalclose}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>


    </div>
  );
}
