import ReactSelect from 'react-select';
import React, {
    useRef,
    useMemo,
    useState,
    useCallback,
    useEffect,
  } from "react";
  import {   Modal,
      ModalOverlay,
      ModalContent,
      ModalHeader,
      ModalFooter,
      ModalBody,
      ModalCloseButton,Tabs,Checkbox, Box ,TabList,InputRightAddon, TabPanels,Select,Tooltip, Tab, Input,Textarea,InputGroup,InputLeftAddon, TabPanel,useToast, Button, useDisclosure } from "@chakra-ui/react";
  import { AgGridReact } from "ag-grid-react";
  import axios from "axios";
  import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
  import { faDownload } from "@fortawesome/free-solid-svg-icons";
  import * as XLSX from 'xlsx';
  import { CSVLink, CSVDownload } from "react-csv";
  import { CheckCircle, Plus, XCircle } from "react-feather";
import moment from 'moment';


function PettyAdvanceRequest() {


    const userData = JSON.parse(localStorage.getItem("user"));
    const toast = useToast();
    const id = "toast";

    let CurrentYear = new Date().getFullYear();
    // console.log("Current Year", CurrentYear);
  
    let CurrentMonth = new Date().getMonth() + 1;

    const [TicketId, setTicketId] = useState();


    const {
        isOpen: isAdvanceRequestOpen,
        onOpen: onAdvanceRequestOpen,
        onClose: onAdvanceRequestClose,
      } = useDisclosure();

      const {
        isOpen: isImportPettyCashOpen,
        onOpen: onImportPettyCashOpen,
        onClose: onImportPettyCashClose,
      } = useDisclosure();

      const [SampleExcelHeads, setSampleExcelHeads] = useState([
        { label: "empName", key: `empName`},
        { label: "empCode", key: `empCode`},
        { label: "pT_ClaimAmount", key: `pT_ClaimAmount`},
        { label: "pT_Type", key: `pT_Type`},
        { label: "pT_ClaimDate", key: `pT_ClaimDate`},
        { label: "paymentBasedOn", key: `paymentBasedOn`},
        { label: "claimMonth", key: `claimMonth`},
        { label: "claimYear", key: `claimYear`},
      ])

    const [EmpList, setEmpList] = useState([]);

    const [SelectedEmp, setSelectedEmp] = useState([]);

    const [PettyCashPurpose, setPettyCashPurpose] = useState();
    const [RequestDate, setRequestDate] = useState(moment().format('YYYY-MM-DD'));
    const [RequestAmount, setRequestAmount] = useState();


    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [AllPettyCashRowData, setAllPettyCashRowData] = useState([]);

    const [AllPettyCashColumnDefs, setAllPettyCashColumnDefs] = useState([
      { headerName: "Emp Code", field: "empCode" },
        { headerName: "Employee Name", field: "empName" },
        { headerName: "Purpose", field: "pT_Purpose" },
        { headerName: "Request Date", field: "pT_ClaimDate" ,valueGetter: (params) => {
            return new Date(params.data.pT_ClaimDate).toLocaleDateString('en-GB')
          }},
        {
          headerName: "Claim Status",
          field: "pT_ClaimStatus",
          cellRenderer: (params) => (
            params.data.pT_ClaimStatus === 'Pending' ||  params.data.pT_ClaimStatus === 'pending'?
            <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
            Pending
          </span>
           : params.data.pT_ClaimStatus === 'Approved'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Approved
          </span> : params.data.pT_ClaimStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
           Rejected
          </span>: ''
          ),
        },
        { headerName: "Total Request Amount", field: "pT_ClaimAmount" },
        {
          headerName: "Action",
          field: "pT_ClaimStatus",
          cellRenderer: (params) => (
            params.data.pT_ClaimStatus === 'Pending' ||  params.data.pT_ClaimStatus === 'pending'?
            <div className="space-x-4">
              <Button size='xs' onClick={()=>{SaveApprovePettyRequest(params.data)}} colorScheme='green'><CheckCircle size='14px' className="mr-2" />Approve</Button>
              <Button size='xs' onClick={()=>{SaveRejectPettyRequest(params.data)}} colorScheme='red'><XCircle size='14px' className="mr-2" />Reject</Button>
            </div> : ''
          ),
        },
      ]);


      console.log("Selected Emp",SelectedEmp);
      // Table Pagination
      const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
      //Table columns properties applies to all columns
      const defaultColDef = useMemo(() => ({
         flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
      }));

      useEffect(() => {
        GetExpenseTypeMasterList();
        getAllPettyCashList();
      }, [])
      



      const GetExpenseTypeMasterList = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashTypeList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Expense Type List Response",response.data);
          setEmpList(response.data);
        } catch (error) {
          console.error(error);
        }

      }




      const getAllPettyCashList = async()=>{
        try {
          const response = await axios.get(
           `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashMasterList?month=${CurrentMonth}&year=${CurrentYear}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Advance Petty Cash List" , response);
          setAllPettyCashRowData(response.data);
        } catch (error) {
          console.error(error);
        }
       }







      const SaveApprovePettyRequest = async (data) =>{

        console.log("Params Data",data)
      
        let body ={
          ptAdvMId:data.ptAdvMId,
          empGuId: data.empGuId ,
          empName: data.empName ,
          empCode: data.empCode,
          pT_ClaimAmount: data.pT_ClaimAmount ,
          pT_Type: data.pT_Type ,
          pT_ClaimDate: data.pT_ClaimDate ,
          pT_Purpose: data.pT_Purpose ,
          paymentBasedOn: data.paymentBasedOn ,
          pT_ClaimStatus: "Approved",
          claimMonth: data.claimMonth ,
          claimYear: data.claimYear ,
          companyId:userData.companyId,
          branchId:userData.isActiveBranch
        }
      
        console.log("Approve Body",body);
        
      
        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashMaster`,body
      )
      .then((response) => {
         //  const res = response.data;
          console.log( "Save Petty Cash Response",response);
          // console.log("Add Reimbursement List Response ",response.data);
      
          const res = response.data;
          // console.log("res",res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title:'Done',
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
      
          getAllPettyCashList();
      
        })
        .catch((error) => {
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Petty Cash Not Updated.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });
      }
      
      const SaveRejectPettyRequest = async (data) =>{
      
        console.log("Params Data",data)
      
        let body ={
          ptAdvMId:data.ptAdvMId,
          empGuId: data.empGuId ,
          empName: data.empName ,
          empCode: data.empCode,
          pT_ClaimAmount: data.pT_ClaimAmount ,
          pT_Type: data.pT_Type ,
          pT_ClaimDate: data.pT_ClaimDate ,
          pT_Purpose: data.pT_Purpose ,
          paymentBasedOn: data.paymentBasedOn ,
          pT_ClaimStatus: "Rejected",
          claimMonth: data.claimMonth ,
          claimYear: data.claimYear ,
          companyId:userData.companyId,
          branchId:userData.isActiveBranch
        }
      
        console.log("Rejected Body",body);
        
      
        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashMaster`,body
      )
      .then((response) => {
         // const res = response.data;
          console.log( "Save Petty Cash Response",response);
          // console.log("Add Reimbursement List Response ",response.data);
      
          const res = response.data;
          // console.log("res",res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title:'Done',
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
      
          getAllPettyCashList();
      
        })
        .catch((error) => {
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Petty Cash Not Updated.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });
      }


      const SaveAdvanceRequest = async (e) =>{
        e.preventDefault();
      
        let body ={
          empGuId: SelectedEmp.empGuId,
          empName: SelectedEmp.empName,
          empCode:SelectedEmp.empCode,
          pT_ClaimAmount: RequestAmount,
          pT_ClaimDate: RequestDate,
          pT_Purpose: PettyCashPurpose,
          pT_ClaimStatus: "Pending",
          claimMonth: new Date(RequestDate).getMonth()+1,
          claimYear: new Date(RequestDate).getFullYear(),
          companyId:userData.companyId,
          branchId:userData.isActiveBranch
        }
      
        console.log(body);
        
      
        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashMaster`,body
      )
      .then((response) => {
         //  const res = response.data;
          console.log( "Save Petty Cash Response",response);
          // console.log("Add Reimbursement List Response ",response.data);
      
          const res = response.data;
          // console.log("res",res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title:'Done',
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
      
        onAdvanceRequestClose();
        getAllPettyCashList();
        setPettyCashPurpose('')
        setRequestDate(moment().format('YYYY-MM-DD'))
        setRequestAmount('')
       setSelectedEmp([]);
        })
        .catch((error) => {
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Petty Cash Not Added.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });
      }


      const readExcel = (file)=>{

        const promise = new Promise((resolve,reject) => {
          
         const fileReader = new FileReader();
         fileReader.readAsArrayBuffer(file);
     
         fileReader.onload=(e)=>{
           const bufferArray = e.target.result;
     
           const wb = XLSX.read(bufferArray,{type:'buffer'});
           const wsname = wb.SheetNames[0];
     
           const ws = wb.Sheets[wsname];
     
           const data = XLSX.utils.sheet_to_json(ws);
     
           resolve(data)
         } 
     
         fileReader.onerror = (error) => {
           reject(error)
         }
     
        });
     
        promise.then((data)=>{
         console.log(data);
    
         let FinalData = data.map((obj)=>{
            return {...obj,
                pT_ClaimStatus:"Pending",
              companyId:userData.companyId,
              branchId:userData.isActiveBranch
            }
           })
    
           HandleImportPettyCash(FinalData);
    
        });
     
     }
    
     const HandleImportPettyCash = async(FinalData) => {
        console.log(FinalData);
    
        let body = FinalData.map(obj => {
            return{
                ...obj,
                empName:String(obj.empName),
                empCode:String(obj.empCode),
                pT_Type: String(obj.pT_Type),
                pT_ClaimDate: String(obj.pT_ClaimDate),
                paymentBasedOn: String(obj.paymentBasedOn),
            }
        })
    
        await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/PettyCash/ImportPettycashData`,body
        )
        .then((response) => {
          const res = response.data;
          console.log("res",res);
      
          if (!toast.isActive(id)) {
            toast({
              id,
              title:'Done',
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
      
      
      
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);
      
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Petty Cash Data not Imported.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });
    
        
     }
    

      
  return (
    <div>
      <div className="border-b flex justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Petty Cash</h1>
        {/* <Button onClick={onImportPettyCashOpen} size={'sm'} colorScheme='purple' >Import Petty Cash List</Button> */}
      </div>


      <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end mb-4 '>

        <div className='space-y-2 w-1/4'>
            <label>Select Employee</label>
            <ReactSelect
               options={EmpList}
               value={SelectedEmp}
               onChange={(value) => {setSelectedEmp(value); }}
               getOptionLabel={(option) => `${option.empName} ${option.empCode}`}
               getOptionValue={(option) => option.guId}
               placeholder="Select Employee"
              />
        </div>

        
        <Button isDisabled={SelectedEmp.length === 0 ? true : false} onClick={onAdvanceRequestOpen} colorScheme={'purple'}>Add Advance Request</Button> 

      </Box>


      <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                rowData={AllPettyCashRowData}
                columnDefs={AllPettyCashColumnDefs}
                defaultColDef={defaultColDef}
                animateRows={true}
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>

      

            <Modal size='2xl' isOpen={isAdvanceRequestOpen} onClose={onAdvanceRequestClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Advance Request</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveAdvanceRequest}>
          <ModalBody>
            <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Employee Name</p>
                <h6 className="font-medium">{SelectedEmp.empName}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Employee Code</p>
                <h6 className="font-medium">{SelectedEmp.empCode}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Advance Max Limit</p>
                <h6 className="font-medium">{SelectedEmp.advanceLimit}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Expense Max Limit</p>
                <h6 className="font-medium">{SelectedEmp.expenseLimit}</h6>
              </div>

            </div>

            <hr />


            <div className="grid grid-cols-2 gap-8 mt-4">
            <div className="space-y-2">
                <label>Petty Cash Purpose</label>
                <Input value={PettyCashPurpose || ''} onChange={(e)=>{setPettyCashPurpose(e.target.value)}} borderColor='gray.500' placeholder="Petty Cash Purpose" />
              </div> 

              <div className="space-y-2">
                <label>Request Date</label>
                <Input readOnly value={RequestDate} onChange={(e)=>{setRequestDate(e.target.value)}} type='date' borderColor='gray.500' />
                {/* <DatePicker
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   outline-none"
                  selected={RequestDate}
                  onChange={(date) => setRequestDate(date)}
                  placeholderText="Request Date"
                /> */}
              </div> 

            </div>


            <div className="grid grid-cols-2 gap-8 mt-4">


              <div className="space-y-2">
                <label>Request Amount</label>
                <Input value={RequestAmount || ''} onChange={(e)=>{setRequestAmount(e.target.value)}} type='number' placeholder="Request Amount" borderColor='gray.500' />
              </div> 
            </div>



          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={onAdvanceRequestClose} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>


      <Modal size='4xl' isOpen={isImportPettyCashOpen} onClose={onImportPettyCashClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Petty Cash List</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div className="mt-2">
                <CSVLink data={SampleExcelHeads} headers={SampleExcelHeads} filename={"Petty Cash Sample.csv"}>
                <Button colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel</Button>
              </CSVLink>
            </div>

            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }} id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

          </ModalBody>
          <hr />
          <ModalFooter>
            <Button variant='outline' colorScheme='purple' mr={3} onClick={onImportPettyCashClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


    </div>
  )
}

export default PettyAdvanceRequest