import React, {
  useState,
  useRef,
  useEffect,
} from "react";
import {
  Input,
  Button,
  Select,
  Switch,
  useToast,
  Textarea,
  Image,
  Avatar,
  Tooltip,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import ReactSelect from 'react-select';
import { Edit } from "react-feather";
import axios from "axios";
import { LuMapPin } from "react-icons/lu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faMoneyBill1 } from "@fortawesome/free-regular-svg-icons";
import { BsPlusCircleDotted } from 'react-icons/bs';


function BranchMaster() {

  
  const gridRef = useRef();
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const { isOpen, onOpen, onClose } = useDisclosure()


  const FileRef = useRef();

  const reset = () => {
    FileRef.current.value = "";
  };

  const [BranchLogo, setBranchLogo] = useState();
  

  const [BranchLogoPath, setBranchLogoPath] = useState();

  const [ViewLogoPath, setViewLogoPath] = useState();

  const [BranchID, setBranchID] = useState(0);
  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);

  const [BranchName, setBranchName] = useState();
  const [BranchCode, setBranchCode] = useState();
  const [CountryName, setCountryName] = useState();
  const [StateName, setStateName] = useState();
  const [Address1, setAddress1] = useState();
  const [Address2, setAddress2] = useState();
  const [PinCode, setPinCode] = useState();
  const [CurrencyName, setCurrencyName] = useState();
  const [TimeZoneName, setTimeZoneName] = useState();
  const [CompanyName, setCompanyName] = useState(userData.companyName);

  const [rowData, setRowData] = useState([]); 

  const HandleEditBranch = async (BranchData) => {

    handleDownloadFile(BranchData.logoPath)
    setBranchLogoPath(BranchData.logoPath);
    setBranchID(BranchData.branchId);
    setBranchCode(BranchData.branchCode);
    setBranchName(BranchData.branchName)
    setCompanyName(BranchData.companyName);
    setAddress1(BranchData.address1);
    setAddress2(BranchData.address2);
    setPinCode(BranchData.pincode);
    setCurrencyName(BranchData.currencyId);
    setTimeZoneName(BranchData.timeZoneName)
    
    
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCountryMasterList`
      )
      console.log("Country List",response.data);

      const res = response.data;

      setCountryName(res[res.findIndex(data => data.countryId === Number(BranchData.countryId))]);
      
    } catch (error) {
      console.error(error)
    }


    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetStateListByCountryId?CountryId=${BranchData.countryId}`
      )
      console.log("State List",response.data);
      const res= response.data;
       setStateName(res[res.findIndex(data => data.stateId === Number(BranchData.stateId))])
       setStateList(response.data);

       onOpen();
    } catch (error) {
      console.error(error)
    }

  }

  console.log("View Logo PAth",ViewLogoPath);

  const handleDownloadFile = async (DocPath) =>{
    console.log("Document path",DocPath)
   await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
  ).then(response => {

    console.log("file Response",response);

    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values

        let alink = document.createElement('a');
        alink.href = fileURL;
        setViewLogoPath(alink)
        // alink.download = fileURL;
        // alink.click();
    })

  }).catch((error)=>{
    console.log("File Api Error",error);
  })
  }

  console.log('Find index ',CountryList.findIndex(data => data.countryId === 101))

  useEffect(() => {
    getCountryList();
    getCurrencyList();
    getBranchList();
  }, []);

  const getBranchList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/BranchMaster/GetBranchMasterList?CompanyId=${userData.companyId}`
      );
      console.log("Branch List", response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getCountryList = async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCountryMasterList`
      )
      console.log("Country List",response.data);
       setCountryList(response.data);
    } catch (error) {
      console.error(error)
    }
  }


  const getStateList = async(CountryID)=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetStateListByCountryId?CountryId=${CountryID}`
      )
      console.log("State List",response.data);
       setStateList(response.data);
    } catch (error) {
      console.error(error)
    }
  }
  

  const getCurrencyList = async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCurrencyMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Currency List",response.data);
      setCurrencyList(response.data);
    } catch (error) {
      console.error(error)
    }
  }





  const HandleBranchSwitch = async (checked,BranchData) => {
    if (window.confirm('Are you sure you want to perform this Action?')) {
    
      setRowData(prev => {
        const newState = prev.map((obj)=>{
          if (obj.branchId === BranchData.branchId) {
            return {...obj, isActive: checked};
          }
          return obj;
        })
        return newState
      })


  const BranchFormData = new FormData();

  BranchFormData.append('branchId',BranchData.branchId)
  BranchFormData.append('branchName',BranchData.branchName)
  BranchFormData.append('branchCode',BranchData.branchCode)
  BranchFormData.append('countryId',String(BranchData.countryId))
  BranchFormData.append('stateId',String(BranchData.stateId))
  BranchFormData.append('address1',BranchData.address1)
  BranchFormData.append('address',BranchData.address)
  BranchFormData.append('address2',BranchData.address2)
  BranchFormData.append('pincode',BranchData.pincode,)
  BranchFormData.append('currencyId',BranchData.currencyId)
  BranchFormData.append('timeZoneName',BranchData.timeZoneName)
  BranchFormData.append('companyName',BranchData.companyName)
  BranchFormData.append('isActive',checked)
  BranchFormData.append('companyId',userData.companyId)
  BranchFormData.append('_userId',String(userData.userId))
  BranchFormData.append('_userName',userData.userName)
  BranchFormData.append('logo',BranchData.logo)
  BranchFormData.append('logoPath',BranchData.logoPath)


  await axios
  .post(
    `${process.env.REACT_APP_ACQ_URL}/api/BranchMaster/SaveBranchMaster`,BranchFormData)
  .then((response) => {
    console.log(response);
    const res = response.data;
    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }

    getBranchList();

  })
  .catch((error) => {
    console.error(error);

    if (!toast.isActive(id)) {
      toast({
        id,
        title: "ERROR",
        description: `Branch not Updated`,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  });

    }

  }


  const SaveBranchName = async (e) => {
    e.preventDefault();
    
    const BranchData = new FormData();

    BranchData.append('branchName',BranchName)
    BranchData.append('branchCode',BranchCode)
    BranchData.append('countryId',String(CountryName.countryId))
    BranchData.append('stateId',String(StateName.stateId))
    BranchData.append('address1',Address1)
    BranchData.append('address2',Address2)
    BranchData.append('pincode',PinCode,)
    BranchData.append('currencyId',CurrencyName)
    BranchData.append('timeZoneName',TimeZoneName)
    BranchData.append('companyName',CompanyName)
    BranchData.append('isActive',true)
    BranchData.append('companyId',userData.companyId)
    BranchData.append('branchId',BranchID)
    BranchData.append('_userId',String(userData.userId))
    BranchData.append('_userName',userData.userName)
    BranchData.append('logo',BranchLogo)
    BranchData.append('logoPath',BranchLogoPath)



    console.log(BranchData);
    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/BranchMaster/SaveBranchMaster`,BranchData)
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
         onClose();
         getBranchList();
         setBranchName();
         setBranchCode();
         setCountryName();
         setStateName();
         setAddress1();
         setAddress2();
         setPinCode();
         setCurrencyName();
         setTimeZoneName();
         setBranchID(0);
         setBranchLogoPath();
         setViewLogoPath(undefined)
         reset();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Branch not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };


  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Branch Master</h1>
      </div>

        <div className="flex flex-wrap gap-6">

          {rowData.map((data,i) => {
            return <div key={i} className="bg-white rounded-2xl w-96 drop-shadow-md p-3 ">
                <div className="flex gap-3 items-center mb-4">
                  <Avatar size={'md'} bgColor={'gray.200'} icon={<FontAwesomeIcon icon={faBuilding} className="text-purple-500 text-2xl"/> } />

                  <div className="flex justify-between w-full">

                  <div>
                     <h6 className="text-lg font-bold text-gray-800">{data.branchName}</h6>
                     <p className="text-sm font-light text-gray-600">{data.branchCode}</p>
                    </div>

                    <div className="flex gap-4 ">
                      <div>
                      <Switch
                       colorScheme="green"
                       isChecked={data.isActive}
                       size="md"
                       onChange={({target})=>{HandleBranchSwitch(target.checked,data)}}
                     />
                        </div>

                        <div>
                     <Tooltip 
                       borderRadius={"7px"}
                       hasArrow
                       label="Edit"
                       bgColor={"gray.600"}
                     >
                      <button onClick={()=>{
                        HandleEditBranch(data);
                      }}><Edit size="16px" className="text-blue-500 hover:text-blue-800 mt-1.5" /></button>
                     </Tooltip>
                        </div>
                    </div>
                  </div>

                </div>
                <div className="space-y-1.5">
                  <div className="flex items-center gap-2"><FontAwesomeIcon icon={faMoneyBill1} className="text-orange-400"/> {data.currencyName}</div>
                  <div className="flex items-center gap-2"><LuMapPin className="text-orange-400"/> {data.address1} {data.address2}, {data.pincode}</div>
                  <div className="flex items-center gap-2"><LuMapPin className="text-orange-400"/> {data.stateName}, {data.countryName}</div>
                </div>
              </div>
          })}

            <div onClick={onOpen} className="border-gray-200 text-gray-500 hover:text-gray-700 cursor-pointer border-2 bg-white bg-opacity-20 hover:bg-opacity-40 rounded-2xl w-96 flex items-center drop-shadow-md p-3 ">
              <div className="mx-auto ">
               <div className="space-y-2 text-center">
                <BsPlusCircleDotted size={'4rem'} className="mx-auto" />
                <p className="text-sm ">Add New Branch</p>
               </div>
              </div>
            </div>

        </div>
          

        <Modal size={'4xl'} isOpen={isOpen} onClose={()=>{
          onClose();
          setBranchName();
          setBranchCode();
          setCountryName();
          setStateName();
          setAddress1();
          setAddress2();
          setPinCode();
          setCurrencyName();
          setTimeZoneName();
          setBranchID(0);
          setViewLogoPath(undefined);
          reset();
        }}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
           textColor={"gray.700"}
           fontSize="lg"
           backgroundColor={"purple.200"}
           roundedTop={"3xl"}
           roundedBottom={"xl"}
           fontWeight="bold"
          >Branch Details</ModalHeader>
          <ModalCloseButton className="mt-1.5" />

          <form onSubmit={SaveBranchName}>
          <ModalBody>

          <div>
                {ViewLogoPath !== undefined ?
                  <Image h={40} src={ViewLogoPath} />
                  : ''}
                </div> 
                <div className="grid grid-cols-2 gap-6">

                  <div className="space-y-2">
                    <label>Branch Name</label>
                    <Input
                      isRequired
                      value={BranchName || ""}
                      onChange={({target})=>{setBranchName(target.value)}}
                      borderColor="gray"
                      placeholder="Enter Branch Name"
                    />
                  </div>

                  <div className="grid grid-cols-2 gap-4">

                  <div className="space-y-2">
                    <label>Branch Code</label>
                    <Input
                      isRequired
                      value={BranchCode || ""}
                      onChange={({target})=>{setBranchCode(target.value)}}
                      borderColor="gray"
                      placeholder="Enter Branch Code"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Branch Logo</label>
                    <Input
                      ref={FileRef}
                      onChange={({target})=>{
                        setBranchLogo(target.files[0]);
                        console.log("Logo",target.files[0]);
                      }}
                      borderColor="gray"
                      type={'file'}
                      placeholder="Enter Branch Code"
                    />
                  </div>
                    
                  </div>

                    <div className="space-y-2">
                      <label>Country Name</label>

                      <ReactSelect
                        required
                        options={CountryList}
                        value={CountryName}
                        className="w-full my-auto"
                        onChange={(value) => {
                          setCountryName(value);
                          console.log("Country ID",value.countryId);
                          getStateList(value.countryId)
                        }}
                        getOptionLabel={(option) => option.countryName}
                        getOptionValue={(option) => option.countryId}
                        placeholder="Select Country"
                      />

                    </div>

                    <div className="space-y-2">
                      <label>State Name</label>
                      <ReactSelect
                        required
                        options={StateList}
                        value={StateName}
                        className="w-full my-auto"
                        onChange={(value) => {
                          setStateName(value);
                          console.log("State ID",value.stateId);
                        }}
                        getOptionLabel={(option) => option.stateName}
                        getOptionValue={(option) => option.stateId}
                        placeholder="Select State"
                      />

                    </div>

                    <div className="space-y-2">
                      <label>Address 1</label>
                      <Textarea 
                      isRequired
                      value={Address1 || ""}
                      onChange={({target})=>{setAddress1(target.value)}}
                      borderColor="gray" placeholder="Enter Address" />
                    </div>

                    <div className="space-y-2">
                      <label>Address 2</label>
                      <Textarea 
                      isRequired
                        value={Address2 || ""}
                        onChange={({target})=>{setAddress2(target.value)}}
                        borderColor="gray" placeholder="Enter Address" />
                    </div>

                    <div className="space-y-2">
                      <label>Pincode</label>
                      <Input  
                      isRequired
                      value={PinCode || ""}
                      type="number"
                      onChange={({target})=>{setPinCode(target.value)}}
                      borderColor="gray" placeholder="Pincode" />
                    </div>

                    <div className="space-y-2">
                      <label>Currency Name</label>
                      <Select
                      isRequired
                        value={CurrencyName} 
                        onChange={({target})=>{setCurrencyName(target.value)}}
                        borderColor="gray"
                        placeholder="Please Select Currency"
                      >
                        {CurrencyList.map((data,i)=>{
                          return <option key={i} value={data.currencyId} >{data.currencyName}</option>
                        })}
                      </Select>
                    </div>

                    <div className="space-y-2">
                      <label>Time Zone</label>
                      <Select
                      isRequired
                        value={TimeZoneName} 
                        onChange={({target})=>{setTimeZoneName(target.value)}}
                        borderColor="gray"
                        placeholder="Please Select Time Zone"
                      >
                        <option value="UTC +04:30">UTC +04:30</option>
                        <option value="UTC +05:30">UTC +05:30</option>
                        <option value="GMT +05:30">GMT +05:30</option>
                      </Select>
                    </div>

                    <div className="space-y-2">
                      <label>Company Name</label>
                      <Input
                        isDisabled
                        value={CompanyName || ""}
                        onChange={({target})=>{setCompanyName(target.value)}}
                        borderColor="gray"
                        placeholder="Enter Company Name"
                      />
                    </div>

                  </div>

            
          </ModalBody>
          <hr />
          <ModalFooter>
          <Button onClick={()=>{
                               onClose();
                               setBranchName();
                               setBranchCode();
                               setCountryName();
                               setStateName();
                               setAddress1();
                               setAddress2();
                               setPinCode();
                               setCurrencyName();
                               setTimeZoneName();
                               setBranchID(0);
                               setViewLogoPath(undefined)
                               reset();
                    }} variant="outline" colorScheme="purple" mr={4}>
                      Cancel
                    </Button>
          <Button type="submit" colorScheme="purple">
                      Save
                    </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      </div>
    );
  }
  
  export default BranchMaster;
  