import {
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  Input,
  Checkbox,
  useToast,
  InputGroup,
  InputRightAddon,
  Select,
  Tooltip,
} from "@chakra-ui/react";
import {
  faDownload,
  faFileImport,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useRef,
  useState,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { CSVLink } from "react-csv";
import * as XLSX from "xlsx";
import moment from "moment";

function AddAsset() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";
  const {
    isOpen: isAddAssetOpen,
    onOpen: onAddAssetOpen,
    onClose: onAddAssetClose,
  } = useDisclosure();

  const {
    isOpen: isImportAssetOpen,
    onOpen: onImportAssetOpen,
    onClose: onImportAssetClose,
  } = useDisclosure();

  const {
    isOpen: isAddAssetTypeOpen,
    onOpen: onAddAssetTypeOpen,
    onClose: onAddAssetTypeClose,
  } = useDisclosure();

  const [assetName, setAssetName] = useState();
  const [assetSRNO, setAssetSRNO] = useState();
  const [AssetType, setAssetType] = useState();
  const [purchaseDate, setPurchaseDate] = useState();

  const [ExpiryDate, setExpiryDate] = useState();
  const [Active, setActive] = useState(true);
  const [AssetModelNumber, setAssetModelNumber] = useState();

  const [AssetTypeName, setAssetTypeName] = useState();
  const [AssetTypeList, setAssetTypeList] = useState([]);

  useEffect(() => {
    getInventoryList();
    getAssetTypeList();
  }, []);

  const getInventoryList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Assets/GetAssetsMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log(response);
      console.log("Inventory List", response.data);
      setInventoryRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Getting Asset Type List
  const getAssetTypeList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Assets/AssetsTypeList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Asset Type List", response.data);
      setAssetTypeList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Table Data Variable
  const gridRef = useRef();
  const [InventoryrowData, setInventoryRowData] = useState([]);

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  //Defining Columns Defiinitions Ex-fields data,headername,etc
  const [columnDefs, setColumnDefs] = useState([
    { headerName: "Asset Name", field: "assetsName" },
    { headerName: "Asset Serial No.", field: "assetsSerialNo" },
    { headerName: "Asset Type Name", field: "assetsTypeName" },
    {
      headerName: "Purchase Date",
      field: "assetsPurchaseDate",
      valueGetter: (params) =>
        new Date(params.data.assetsPurchaseDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "Warrenty Expiry Date",
      field: "assetsWarrentyExpiryDate",
      valueGetter: (params) =>
        new Date(params.data.assetsWarrentyExpiryDate).toLocaleDateString(
          "en-GB"
        ),
    },
    {
      headerName: "Asset Status",
      field: "isAllocate",
      cellRenderer: (params) =>
        params.data.isAllocate === false ? (
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            Available
          </span>
        ) : params.data.isAllocate === true ? (
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            Not Available
          </span>
        ) : (
          ""
        ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
  }));

  const [AssetsExcelHeads, setEmpExcelHeads] = useState([
    { label: "assetsName", key: `assetsName` },
    { label: "assetsTypeName", key: `assetsTypeName` },
    { label: "assetsSerialNo", key: `assetsSerialNo` },
    { label: "assetsModelNo", key: `assetsModelNo` },
    { label: "assetsPurchaseDate", key: `assetsPurchaseDate` },
    { label: "assetsWarrentyExpiryDate", key: `assetsWarrentyExpiryDate` },
    { label: "assetsRemark", key: `assetsRemark` },
  ]);

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws, { raw: false });

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      console.log("Imported Data", data);

      let FinalData = data.map((obj) => {
        return {
          ...obj,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
        };
      });

      console.log("Final Data", FinalData);
      handleImportAsset(FinalData);
    });
  };

  const ExcelDateToJSDate = (date) => {
    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    converted_date = String(converted_date).slice(4, 15);
    date = converted_date.split(" ");
    let day = date[1];
    let month = date[0];
    month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1;
    if (month.toString().length <= 1) month = "0" + month;
    let year = date[2];
    return String(year.slice(0, 4) + "-" + month + "-" + day);
  };

  const handleImportAsset = async (FinalData) => {
    let body = FinalData.map((obj) => {
      return {
        ...obj,
        assetsPurchaseDate: moment(obj.assetsPurchaseDate).format("YYYY-MM-DD"),
        assetsWarrentyExpiryDate: moment(obj.assetsWarrentyExpiryDate).format(
          "YYYY-MM-DD"
        ),
      };
    });

    console.log("Import Asset Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Assets/ImportAssetsMaster`,
        body
      )
      .then((response) => {
        console.log("Import Response", response);
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "Asset Imported",
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.log("Import Error", error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Asset Data not Imported.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const HandleAddAsset = async (e) => {
    e.preventDefault();
    let body = {
      assetsName: assetName,
      assetsSerialNo: assetSRNO,
      assetsTypeId: AssetType,
      assetsPurchaseDate: purchaseDate,
      assetsWarrentyExpiryDate: ExpiryDate,
      assetsModelNo: AssetModelNumber,
      isActive: Active,
      isAllocate: false,
      assetsStatus: "Available",
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    console.log("Add Asset Body", body);
    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Assets/SaveAssetsMaster`,
        body
      )
      .then((response) => {
        const res = response.data;
        // console.log("res",res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
        onAddAssetClose();
        getInventoryList();
        setAssetName();
        setAssetSRNO();
        setAssetType();
        setPurchaseDate();
        setExpiryDate();
        setActive();
        setAssetModelNumber();
      })
      .catch((error) => {
        console.error(error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Asset not Added ",
            isClosable: true,
          });
        }
      });
  };

  const handleAddAssetType = async (e) => {
    e.preventDefault();

    let body = {
      assetsTypeName: AssetTypeName,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    // console.log("Add Asset Type",body);

    await axios
      .post(`${process.env.REACT_APP_ACQ_URL}/api/Assets/SaveAssetsType`, body)
      .then((response) => {
        const res = response.data;
        //  console.log("res",res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        getAssetTypeList();
        onAddAssetTypeClose();
        getAssetTypeList();
        setAssetTypeName();
      })
      .catch((error) => {
        console.error(error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Asset Type Name not added.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className="flex items-center justify-between  border-b border-gray-400 mb-4 pb-3 text-left">
        <h1 className="text-gray-700 text-xl font-bold ">Assets</h1>

        <div className="space-x-4">
          <Button size={"sm"} onClick={onImportAssetOpen} colorScheme="purple">
            <FontAwesomeIcon icon={faFileImport} className="mr-2" />
            Import Assets
          </Button>
          <Button size={"sm"} onClick={onAddAssetOpen} colorScheme="purple">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Asset
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={InventoryrowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Modal
        size={"2xl"}
        isOpen={isImportAssetOpen}
        onClose={onImportAssetClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Assets</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={handleAddAssetType}>
            <ModalBody>
              <div className="mt-4">
                <CSVLink
                  data={AssetsExcelHeads}
                  headers={AssetsExcelHeads}
                  filename={"Assets Data.csv"}
                >
                  <Button size="sm" colorScheme="purple">
                    <FontAwesomeIcon icon={faDownload} className="mr-2" />
                    Download Sample Excel
                  </Button>
                </CSVLink>
              </div>
              <div className="flex justify-center items-center w-full my-10">
                <label
                  htmlFor="dropzone-file"
                  className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600"
                >
                  <div className="flex flex-col justify-center items-center pt-5 pb-6">
                    <svg
                      aria-hidden="true"
                      className="mb-3 w-10 h-10 text-gray-400"
                      fill="none"
                      stroke="currentColor"
                      viewBox="0 0 24 24"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                      ></path>
                    </svg>
                    <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                      <span className="font-semibold">Click to upload</span> or
                      drag and drop
                    </p>
                    <p className="text-xs text-gray-500 dark:text-gray-400">
                      XLSV, CSV
                    </p>
                  </div>
                  <input
                    onChange={(e) => {
                      const file = e.target.files[0];
                      readExcel(file);
                    }}
                    id="dropzone-file"
                    type="file"
                    className="hidden"
                  />
                </label>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button
                onClick={onImportAssetClose}
                colorScheme="purple"
                variant={"outline"}
              >
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal size="2xl" isOpen={isAddAssetOpen} onClose={onAddAssetClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Asset</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={HandleAddAsset}>
            <ModalBody>
              <div className="grid grid-cols-2 gap-4">
                <div className="space-y-2">
                  <label>Asset Name</label>
                  <Input
                    value={assetName}
                    onChange={(e) => {
                      setAssetName(e.target.value);
                    }}
                    type="text"
                    borderColor="gray.400"
                    placeholder="Asset Name"
                  />
                </div>

                <div className="space-y-2">
                  <label>Asset Serial No.</label>
                  <Input
                    value={assetSRNO}
                    onChange={(e) => {
                      setAssetSRNO(e.target.value);
                    }}
                    type="text"
                    borderColor="gray.400"
                    placeholder="Asset Serial No."
                  />
                </div>

                <div className="space-y-2">
                  <label>Asset Model No.</label>
                  <Input
                    value={AssetModelNumber}
                    onChange={(e) => {
                      setAssetModelNumber(e.target.value);
                    }}
                    borderColor="gray.400"
                    placeholder="Asset Model No."
                  />
                </div>

                <div className="space-y-2">
                  <label>Asset Type</label>
                  <InputGroup>
                    <Select
                      value={AssetType}
                      onChange={(e) => {
                        setAssetType(e.target.value);
                      }}
                      borderColor="gray.400"
                      placeholder="Select Asset Type"
                    >
                      {AssetTypeList.map((data, i) => (
                        <option key={i} value={data.assetsTypeId}>
                          {data.assetsTypeName}
                        </option>
                      ))}
                    </Select>
                    <Tooltip
                      rounded="5px"
                      hasArrow
                      label="Add Asset Type"
                      bg={"black"}
                    >
                      <InputRightAddon
                        onClick={onAddAssetTypeOpen}
                        className="cursor-pointer"
                        bg="purple.400"
                        children={
                          <FontAwesomeIcon
                            className="text-white"
                            icon={faPlus}
                          />
                        }
                      />
                    </Tooltip>
                  </InputGroup>
                </div>

                <div className="space-y-2">
                  <label>Purchased Date</label>
                  <Input
                    value={purchaseDate}
                    onChange={(e) => {
                      setPurchaseDate(e.target.value);
                    }}
                    type="date"
                    borderColor="gray.400"
                    placeholder="Asset Type"
                  />
                </div>

                <div className="space-y-2">
                  <label>Warranty Expiry Date</label>
                  <Input
                    value={ExpiryDate}
                    onChange={(e) => {
                      setExpiryDate(e.target.value);
                    }}
                    type="date"
                    borderColor="gray.400"
                    placeholder="Asset Type"
                  />
                </div>
              </div>

              <div className="my-4 align-middle">
                <Checkbox
                  isChecked={Active}
                  onChange={(e) => {
                    setActive(e.target.checked);
                  }}
                  borderColor="gray.400"
                >
                  Make this as Active
                </Checkbox>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button
                onClick={onAddAssetClose}
                colorScheme="purple"
                variant="outline"
              >
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAddAssetTypeOpen} onClose={onAddAssetTypeClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Asset Type</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={handleAddAssetType}>
            <ModalBody>
              <div className="space-y-2">
                <label>Asset Type Name</label>
                <Input
                  isRequired
                  value={AssetTypeName}
                  onChange={(e) => {
                    setAssetTypeName(e.target.value);
                  }}
                  type="text"
                  borderColor="gray.400"
                  placeholder="Asset Type Name"
                />
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default AddAsset;
