import { Select, Checkbox, useToast, Button } from "@chakra-ui/react";
import axios from "axios";
import moment from "moment";
import React, { useState } from "react";
import { useEffect } from "react";
import DatePicker from "react-datepicker";

function AttendanceSettings() {

  const userData = JSON.parse(localStorage.getItem('user'));
  const toast = useToast();
  const id = "toast";


  const [InTime, setInTime] = useState();
  const [OutTime, setOutTime] = useState();
  const [PresentHours, setPresentHours] = useState();
  const [LunchBreak, setLunchBreak] = useState();
  const [AttendanceType, setAttendanceType] = useState();
  const [HalfDayHours, setHalfDayHours] = useState();
  const [GraceTiming, setGraceTiming] = useState();
  const [IsGraceForWFHEmployee, setIsGraceForWFHEmployee] = useState();
  const [Break1, setBreak1] = useState();
  const [Break2, setBreak2] = useState();


  const [CalculationTypeList, setCalculationTypeList] = useState([]);

  useEffect(()=>{
    getAttendancesettingData();
    getAttendanceTypeMasterList();
  },[])


  
  const getAttendanceTypeMasterList = async()=>{
    try {
      const response= await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetAttendanceTypeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Get Attendance Type List Response",response);
       setCalculationTypeList(response.data);
    } catch (error) {
      console.error(error);
    }
  }


  function getDateFromTime(time) {
    const [hours, minutes, seconds] = time.split(':');
    const date = new Date();
    date.setHours(hours);
    date.setMinutes(minutes);
    date.setSeconds(seconds);
    return date;
  }


  const getAttendancesettingData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetAttendenceSettingsByBranch?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Setting Data",response);
      const res = response.data;

      setInTime(getDateFromTime(res.officeInTime || "00:00:00"))
setOutTime(getDateFromTime(res.officeOutTime || "00:00:00"))
setPresentHours(getDateFromTime(res.presentHour || "00:00:00"))
setLunchBreak(getDateFromTime(res.lunchBreak || "00:00:00"))
setAttendanceType(res.attendanceTypeVal)
setHalfDayHours(getDateFromTime(res.halfDayhour || "00:00:00"))
setGraceTiming(getDateFromTime(res.graceTime || "00:00:00"))
setIsGraceForWFHEmployee(res.graceTimeForWFHApplicable)
setBreak1(getDateFromTime(res.break1 || "00:00:00"))
setBreak2(getDateFromTime(res.break2 || "00:00:00"))
    } catch (error) {
      console.error(error);
    }
  }


  function calculateHoursWorked(intime, outime) {
    const intimeDate = new Date(intime);
    const outimeDate = new Date(outime);
  
    const timeDifference = outimeDate - intimeDate;
    const hoursDifference = timeDifference / (1000 * 60 * 60);
  
    const wholeHours = Math.floor(hoursDifference);
    const fractionalHours = (hoursDifference - wholeHours) * 60;
  
    const formattedHours = String(wholeHours).padStart(2, '0');
    const formattedMinutes = String(Math.round(fractionalHours)).padStart(2, '0');
    const formattedSeconds = '00';

    const date = new Date();
    date.setHours(formattedHours);
    date.setMinutes(formattedMinutes);
    date.setSeconds(formattedSeconds);
  
    return date;
  }

  useEffect(()=>{
    if(InTime&& OutTime){
      setPresentHours(calculateHoursWorked(InTime || "00:00:00",OutTime || "00:00:00") || new Date());
    }
  },[InTime,OutTime])

  function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  }

  function convertToSeconds(hours, minutes) {
    return Number(hours) * 60 * 60 + Number(minutes) * 60;
  }


  // console.log("Get Date Time",getDateFromTime("14:00:00"))
  

  // console.log("In Timing",new Date("14:00:00").toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' , hour12: false}))

  const SaveAttendanceSetting = async (e) => {
    e.preventDefault();

    let body = {
      officeInTime: moment(InTime).format('HH:mm:ss') || "00:00:00",
      officeOutTime: moment(OutTime).format('HH:mm:ss') || "00:00:00",
      presentHour: moment(PresentHours).format('HH:mm:ss') || "00:00:00",
      halfDayhour: moment(HalfDayHours).format('HH:mm:ss') || "00:00:00",
      graceTime: moment(GraceTiming).format('HH:mm:ss') || "00:00:00",
      graceTimeForWFHApplicable: IsGraceForWFHEmployee,
      lunchBreak: moment(LunchBreak).format('HH:mm:ss') || "00:00:00",
      break1: moment(Break1).format('HH:mm:ss') || "00:00:00",
      break2: moment(Break2).format('HH:mm:ss') || "00:00:00",
      attendanceTypeVal: Number(AttendanceType),
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
  }

    console.log("Save Att Setting Body",body);


    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveAttendanceSetting`,body
    )
    .then((response) => {
      console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      getAttendancesettingData();
  
    })
    .catch((error) => {
      console.error(error);
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Attendance Setting not Updated`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });

  }

  console.log("Validate",new Date(InTime).getTime() < new Date(LunchBreak).getTime() , new Date(LunchBreak).getTime() < new Date(OutTime).getTime());
   

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Attendance Settings</h1>
      </div>

      <div className="bg-white rounded-2xl drop-shadow-md p-4">

        <form onSubmit={SaveAttendanceSetting}>

        <div className="border-b flex justify-between items-center border-slate-400 ">
        <h6 className="text-xl text-left mb-2  flex-1 font-bold  text-gray-800">
          Attendance Settings
        </h6>
        <span className='text-red-500 font-normal'>*Note : Enter Time in 24 Hours Format</span>
        </div>

        <div className="border-b border-slate-400  my-2">
        <div className="flex gap-8 my-4">
          <div className="space-y-2 w-1/3">
            <label>Office Timing<span className='text-red-500 font-semibold'>*</span></label>
            <div className="flex gap-3 items-center">
              <p>In:</p>
              <div className="flex-1">
                <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={InTime}
                  onChange={(date) => setInTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="In Time"
                />
                </div>
              </div>
              <p>Out:</p>
              <div className="flex-1">
                <div>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={OutTime}
                  onChange={(date) => setOutTime(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Out Time"
                />
                </div>
              </div>
            </div>
          </div>
          <div className="space-y-2 w-1/3">
            <label>Present Hours<span className='text-red-500 font-semibold'>*</span></label>
            <div>
                <DatePicker
                 required
                 readOnly
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={PresentHours}
                  onChange={(date) => setPresentHours(date)}
                  showTimeSelect
                  showTimeSelectOnly
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Present Hours"
                />
                </div>
          </div>
          <div className="space-y-2 w-1/3">
            <label>Lunch Break Starts at<span className='text-red-500 font-semibold'>*</span></label>
            <div>
              <DatePicker
               required
                className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 outline-none"
                selected={LunchBreak}
                onChange={(date) => {
                  setLunchBreak(date);

                  // if(new Date(InTime).getTime() < new Date(date).getTime() && new Date(date).getTime() < new Date(OutTime).getTime()){
                  //   setLunchBreak(date);
                  // }else{
                  //   setLunchBreak(InTime)
                  // }
                }}
                showTimeSelect
                showTimeSelectOnly
                // minTime={new Date(InTime)}
                // maxTime={new Date(OutTime)}
                timeFormat="HH:mm"
                timeIntervals={5}
                timeCaption="Time"
                dateFormat="HH:mm"
                placeholderText="Lunch Break"
              />
            </div>
          </div>
        </div>
        </div>
        <div className="border-b border-slate-400  my-2">
        <div className="flex gap-8 my-4">
          <div className="space-y-2 mb-2 w-1/3">
            <label>Attendance Type<span className='text-red-500 font-semibold'>*</span></label>
            <Select isRequired value={AttendanceType} onChange={({target})=>{setAttendanceType(target.value)}}  borderColor="gray" placeholder="Select Days">
            {CalculationTypeList.map((data,i)=>{
      return <option key={i} value={data.attendanceTypeId}>{data.attendanceTypeName}</option>
    })}
            </Select>
          </div>
        </div>
        </div>
        
        
        <div className="border-b border-slate-400  my-2">
        <div className="flex gap-8 my-4">
          <div className="space-y-2 mb-4 w-1/4">
            <label>Half Day Starts at<span className='text-red-500 font-semibold'>*</span></label>
            <div>
                <DatePicker
                 required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={HalfDayHours}
                  onChange={(date) => {
                    if(new Date(InTime).getTime() < new Date(date).getTime() && new Date(date).getTime() < new Date(OutTime).getTime()){
                      setHalfDayHours(date);
                    }else{
                      setHalfDayHours(InTime)
                    }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  minTime={new Date(InTime)}
                  maxTime={new Date(OutTime)}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Half Day Hours"
                />
                </div>
          </div>


<div className="space-y-2 w-1/4">
            <label>Grace Time Ends at<span className='text-red-500 font-semibold'>*</span></label>
            <div>
                <DatePicker
                 required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={GraceTiming}
                  onChange={(date) => {
                    setGraceTiming(date);

                    // if(new Date(InTime).getTime() < new Date(date).getTime() && new Date(date).getTime() < new Date(OutTime).getTime()){
                    //   setGraceTiming(date);
                    // }else{
                    //   setGraceTiming(InTime)
                    // }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  // minTime={new Date(InTime)}
                  // maxTime={new Date(OutTime)}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Grace Timing"
                />
                </div>
          </div>
          <div className="space-y-2 w-1/4">
            <label className="block">Grace Timing Applicable for</label>
            <Checkbox isChecked={IsGraceForWFHEmployee} onChange={({target})=>{setIsGraceForWFHEmployee(target.checked)}}  borderColor="gray">WFH Employee</Checkbox>
          </div>

        </div>
        </div>
        
      <div className="flex gap-8 my-4">

          <div className="space-y-2 w-1/4">
            <label>Break 1<span className='text-red-500 font-semibold'>*</span></label>
            <div>
                <DatePicker
                 required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={Break1}
                  onChange={(date) => {
                    setBreak1(date);
                    // if(new Date(InTime).getTime() < new Date(date).getTime() && new Date(date).getTime() < new Date(OutTime).getTime()){
                    //   setBreak1(date);
                    // }else{
                    //   setBreak1(InTime);
                    // }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  // minTime={new Date(InTime)}
                  // maxTime={new Date(OutTime)}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Break 1"
                />
                </div>
          </div>
          <div className="space-y-2 w-1/4">
            <label className="block">Break 2<span className='text-red-500 font-semibold'>*</span></label>
            <div>
                <DatePicker
                 required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={Break2}
                  onChange={(date) => {
                    setBreak2(date);
                    // if(new Date(InTime).getTime() < new Date(date).getTime() && new Date(date).getTime() < new Date(OutTime).getTime()){
                    //   setBreak2(date);
                    // }else{
                    //   setBreak2(InTime)
                    // }
                  }}
                  showTimeSelect
                  showTimeSelectOnly
                  // minTime={new Date(InTime)}
                  // maxTime={new Date(OutTime)}
                  timeFormat="HH:mm"
                  timeIntervals={5}
                  timeCaption="Time"
                  dateFormat="HH:mm"
                  placeholderText="Break 2"
                />
                </div>
          </div>
        </div>

        <div className="space-x-4 text-right mt-6">
          <Button type="submit" variant={'solid'} colorScheme={'purple'}>Save</Button>
        </div>

        </form>
      </div>

    </div>
  );
}

export default AttendanceSettings;
