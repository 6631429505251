import React from 'react';
import { Input} from '@chakra-ui/react'

function ForgotPassword() {
  return (
    <div>

<div className="grid md:grid-cols-2 sm:grid-cols-1">

<div className=" bg-slate-800 hidden  md:my-38 md:flex  items-center place-content-center ">
  <img className=" h-auto p-10 lg:p-28 mx-auto" src="/images/ForgotPassword.png" alt=""  />

</div>
<div className="basis-1/2   h-auto md:h-screen flex items-center justify-start py-12 px-4 sm:px-6 lg:px-8 sm:basis-1">
  <div className="max-w-md w-full space-y-8 sm:max-w-md">
    <div>

      <h2 className="mt-6 text-4xl font-bold text-gray-800">Reset Password</h2>

    </div>
    <form className="mt-6 space-y-6" >

    <div>
                    <label className="block mb-2 text-sm font-medium  text-gray-800">New Password</label>
                    <Input borderColor='gray' type='password'  placeholder='New Password' />
                                    </div>
                <div>
                    <label className="block mb-2 text-sm font-medium  text-gray-800">Confirm Password</label>
                    <Input borderColor='gray' type='password'  placeholder='Confirm Password' />
                                    </div>

      <div>
        <button
          type="submit"
          className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-purple-600 hover:bg-purple-700 hover:drop-shadow-lg focus:outline-none focus:ring-1 focus:ring-offset-1 focus:ring-purple-400"
        >
          Reset Password
        </button>

      </div>
    </form>
  </div>
</div>
</div> 

    </div>
  )
}

export default ForgotPassword