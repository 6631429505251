import { Button, Center, IconButton, Select, Spinner, useToast } from '@chakra-ui/react'
import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import axios from 'axios';
import ReactSelect from "react-select";
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const AssignProject = () => {

  const userData = JSON.parse(localStorage.getItem('user'));

  const toast = useToast();
  const id = "toast";

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const gridRef = useRef();
  const SelectedGridRef = useRef();

  const [IsLoading, setIsLoading] = useState(false);

  const [ProjectList, setProjectList] = useState([]);
  const [SelectedProject, setSelectedProject] = useState([]);

  const [SelectedData, setSelectedData] = useState([])
  const [SelectedColumnDefs, setSelectedColumnDefs] = useState([
    { headerName: "Emp Code", field: "empCode"},
    { headerName: "Emp Name", field: "fullName"},
    { headerName: "Designation", field: "designationName"},
    { headerName: "Department", field: "departmentName"},
    // { headerName: "Action", 
    //   cellRenderer : (params) => <Button
    //     size={'xs'}
    //     leftIcon={<FontAwesomeIcon icon={faTrash} />}
    //     onClick={()=>{onRemoveSelected()}}
    //     colorScheme="red"
    //     variant='solid'
    //   >
    //     Delete
    //   </Button>
    // },
  ]);

  const [EmployeeList, setEmployeeList] = useState([]);
  const [EmpColumnDefs, setEmpColumnDefs] = useState([
    { headerName: "Emp Code", field: "empCode", headerCheckboxSelection: true, checkboxSelection:true},
    { headerName: "Emp Name", field: "fullName"},
    { headerName: "Designation", field: "designationName"},
    { headerName: "Department", field: "departmentName"},
  ]);

  const onRemoveSelected = useCallback(() => {
    const DeletedData = SelectedGridRef.current.api.getSelectedRows();
    const res = SelectedGridRef.current.api.applyTransaction({ remove: DeletedData });
    // printResult(res);
    console.log("Selected Data",DeletedData);
    const allRows = SelectedGridRef.current.api.getModel().rowsToDisplay.map(row => row.data);
    console.log("Left Rows",allRows);
    setSelectedData(allRows);
  }, []);
  

  const onSelectionChanged = useCallback(() => {
    var SelData = gridRef.current.api.getSelectedRows();
    console.log("Selected Emp",SelData);
    console.log("Data Size",SelData.length,"Team Size",SelectedProject.teamSize);
        setSelectedData(SelData);
  }, []);

    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    

      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
         floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: "no-border",
        resizable: true
      }));

      const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.forEachNode((node) =>
          node.setSelected(node.data.isAssigned === true)
        );
      }, []);

  useEffect(()=>{
    GetProjectMasterList();
  },[])

   const GetProjectMasterList = async () => {
     try {
       const response =  await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftProjectMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
       console.log("Project List Response",response);
       const res = response.data;
       setProjectList(res.filter(data => data.isActive === true));
     } catch (error) {
       console.error(error);
     }
   }

   const HandleAssignEmp = async () => {
    setIsLoading(true);
    try{
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        // console.log("Employee List Response",response.data);
        const res = response.data;

        GetAssignedEmployees(res.filter(data => data.isActive === true));
    }catch(error){
       console.error(error);
    }
   }


   const GetAssignedEmployees = async (AllEmpList) => {
     try {
        const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftProjectEmpLinkByProj?ShiftPojectID=${SelectedProject.shiftPojectID}`
          )
          console.log("Project Emp List Response",response);
          const res = response.data
          console.log("All Employee List",AllEmpList);

          let ModifiedList =AllEmpList.map((data)=>{
            return {...data,
                isAssigned : res.some(PrjEmp => PrjEmp.empGuid === data.guId)
            }
          })

          console.log("Modified Row Data" ,ModifiedList );

          setEmployeeList(ModifiedList);

     } catch (error) {
        console.error(error);
     }finally{
        setIsLoading(false)
     }

   }


   const SaveAssignedEmployees = async () => {
     let body = SelectedData.map((data)=>{
        return {
            empGuid : data.guId,
            shiftPojectID : SelectedProject.shiftPojectID,
            isLock : false
         }
     })

     console.log("Assign Save Body",body);
     await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Shift/SaveShiftProjectEmpLinkData`,body
        ).then((response)=>{
            console.log(response);
            const res = response.data;

            if (!toast.isActive(id)) {
                toast({
                  id,
                  title: res,
                  position: "top",
                  status: "info",
                  duration: 2000,
                  isClosable: true,
                });
              }

              setSelectedProject([]);
              setSelectedData([]);
              setEmployeeList([]);

        }).catch((error)=>{
            console.error(error);

            if (!toast.isActive(id)) {
                toast({
                  id,
                  title: "Error",
                  description: "Project Not Assigned to the Employees",
                  position: "top",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                });
              }
        })
   }
  


  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Assign Project</h1>
      </div>

      <div className={`bg-white rounded-2xl p-4 drop-shadow-md ${EmployeeList.length === 0 ? "mb-36" : "mb-4"}`}>
        <div className='flex gap-4 items-end'>

        <div className='space-y-2'>
          <label>Select Project</label>
          <ReactSelect
            options={ProjectList}
            value={SelectedProject}
            onChange={(value) => {
              setSelectedProject(value);
            }}
            getOptionLabel={(option) => option.shiftProjectName}
            getOptionValue={(option) => option.shiftPojectID}
            placeholder="Select Project"
          />
        </div>
        <Button isDisabled={SelectedProject.length === 0 ? true : false} onClick={HandleAssignEmp} colorScheme='purple'>Assign Employees</Button>
        </div>
      </div>

      {IsLoading ?
        <Center>
          <Spinner  thickness='4px' color={'purple.500 '}  size='lg'  />
        </Center> 
      :  <>

{ EmployeeList.length !== 0 ? <>

<div className="bg-white rounded-2xl p-4 mb-4">

  
<div className="flex  py-2 gap-8">

 <div className="space-y-1">
  <p className="text-sm text-gray-500">Project Name</p>
  <h6 className="font-medium">{SelectedProject.shiftProjectName}</h6>
 </div>

 <div className="space-y-1">
  <p className="text-sm text-gray-500">Location</p>
  <h6 className="font-medium">{SelectedProject.projectLocation}</h6>
 </div>


 <div className="space-y-1">
  <p className="text-sm text-gray-500">Start Date</p>
  <h6 className="font-medium">{moment(SelectedProject.projectStartDate).format('DD/MM/YYYY')}</h6>
 </div>

 <div className="space-y-1">
  <p className="text-sm text-gray-500">End Date</p>
  <h6 className="font-medium">{moment(SelectedProject.projectEndDate).format('DD/MM/YYYY')}</h6>
 </div>


 <div className="space-y-1">
  <p className="text-sm text-gray-500">Team Size</p>
  <h6 className="font-medium">{SelectedProject.teamSize}</h6>
 </div>       
 
</div>

</div>


<div className='grid grid-cols-2 gap-6 '>
<div>
<div className=" border-b border-gray-400 mb-4 pb-2 text-left">
<h1 className="text-gray-700 text-lg font-bold ">
All Employees
</h1>
</div>

<div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={EmployeeList} // Row Data for Rows
          columnDefs={EmpColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={12}
          rowSelection='multiple'
          paginationNumberFormatter={paginationNumberFormatter}
          onSelectionChanged={onSelectionChanged}
          onFirstDataRendered={onFirstDataRendered}
        />
      </div>
</div>

<div>
<div className=" flex items-center justify-between border-b border-gray-400 mb-4 pb-2 text-left">
<h1 className="text-gray-700 text-lg font-bold ">
Assigned Employees
</h1>
{Number(SelectedData.length) > Number(SelectedProject.teamSize) && <h1 className="text-white bg-red-500  text-sm py-1 px-1.5 rounded-lg font-medium">Team Size Exceeded</h1>}
</div>

<div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          ref={SelectedGridRef}
          domLayout={"autoHeight"}
          rowData={SelectedData} // Row Data for Rows
          columnDefs={SelectedColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={12}
          paginationNumberFormatter={paginationNumberFormatter}
          suppressModelUpdateAfterUpdateTransaction={true}
          cacheQuickFilter={true}
          suppressAggAtRootLevel={true}

        />
      </div>
</div>

</div>


<div className='text-right my-4'>
    <div className='space-x-4'>
        <Button 
        onClick={()=>{
          setSelectedProject([]);
          setSelectedData([]);
          setEmployeeList([]);
        }} variant={'outline'} colorScheme='purple'>Cancel</Button>
        <Button isDisabled={(SelectedData.length === 0 ? true : false) || (Number(SelectedData.length) > Number(SelectedProject.teamSize) ? true : false ) ? true : false} onClick={SaveAssignedEmployees} colorScheme='purple'>Save</Button>
    </div>
</div>
</> : ''}


</> }

    </div>
  )
}

export default AssignProject