import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { Button, useToast, useDisclosure,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton } from '@chakra-ui/react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileImport } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import axios from 'axios';
import { AgGridReact } from "ag-grid-react";
import * as XLSX from 'xlsx';
import { CSVLink } from "react-csv";
import useValidation from "../Utils/useValidation";
import moment from 'moment';
import usePost from "../Utils/usePost";
import { Save } from "react-feather";

const NumericCellEditor = (props) => {
  const [value, setValue] = useState(props.value);

  const onValueChange = (event) => {
    const newValue = event.target.value;
    if (!isNaN(newValue)) {
      setValue(newValue);
      props.api.stopEditing();
    }
  };

  return <input type="number" value={value} onChange={onValueChange} />;
};


function ImportTds() {

    const userData = JSON.parse(localStorage.getItem("user"));
    const toast = useToast();
    const id = "toast";

    const Validation = useValidation();

    const { IsPostLoading: IsSaveTdsLoading , PostData: PostSaveTdsData } = usePost('/api/PayrollTDSMonthData/SavePayrollTDSMonthData');
    const { IsPostLoading: IsBulkTdsLoading , PostData: PostBulkTdsData } = usePost('/api/PayrollTDSMonthData/ImportPayrollTDSMonthData')
    
    //  console.log("validation",Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,parseInt(Validation?.payrollMonth)+1,1) : new Date(moment(Validation?.payrollStartFrom).add('1','month')));

    const {
        isOpen:  isImportTDSDetailsOpen,
        onOpen:  onImportTDSDetailsOpen,
        onClose: onImportTDSDetailsClose,
      } = useDisclosure();
  

    const [DataMonth, setDataMonth] = useState();
    const [EmpList, setEmpList] = useState([]);

    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    
    const [ImportedTDSData, setImportedTDSData] = useState([]);
    const [TdsImportMonth, setTdsImportMonth] = useState(new Date())
    const [TDSRowData, setTDSRowData] = useState([]);
    const [TDSColumnDefs] = useState([
      {
        headerName: "Emp Code",
        field: "empCode",
      },
        {
          headerName: "Emp Name",
          field: "empName",
        },
        {
          headerName: "Payroll Month",
          field: "payrollMonth",
          valueGetter:(params)=> `${params.data.payrollMonth}/${params.data.payrollYear}`
        },
        {
          headerName: "TDS Amount",
          field: "empTDSAmount",
          editable:true, 
          singleClickEdit: true,
          cellClass:'editable-grid-cell',

        },
        // {
        //     headerName: "Action",
        //     cellRenderer:(params) => <Button isLoading={IsSaveTdsLoading} loadingText="Updating..."
        //      onClick={async()=>{
        //         let EmpRowData = params.data;
        //         let body = {
        //               payrollTDSMonthDataId : EmpRowData.payrollTDSMonthDataId,
        //               empGuId : EmpRowData.empGuId,
        //               empTDSAmount : EmpRowData.empTDSAmount,
        //               payrollMonth : EmpRowData.payrollMonth,
        //               payrollYear : EmpRowData.payrollYear,
        //               companyId : EmpRowData.companyId,
        //               branchId : EmpRowData.branchId,
        //               isLock: false,
        //               empCode : EmpRowData.empCode,
        //               empName : EmpRowData.empName
        //             }
        //         console.log("TDS Update Body",body);
        //         const response = await PostSaveTdsData(body);
        //         if(response){
        //           GetTDSDetailsOfMonth(EmpRowData.payrollMonth,EmpRowData.payrollYear)
        //         }
        //      }}
        //      colorScheme={'blue'} size={'xs'}>Update</Button>
        //   }
    ])


    const [TDSImportExcelHeads, setTDSImportExcelHeads] = useState([
        { label: "empGuId", key: `empGuId`},
        { label: "empName", key: `empName`},
        { label: "empCode", key: `empCode`},
        { label: "empTDSAmount", key: `empTDSAmount`},
        { label: "payrollMonth", key: `payrollMonth`},
        { label: "payrollYear", key: `payrollYear`}
       ])

    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: 'no-border',
      enableCellTextSelection: true,
      resizable: true
    }));


    useEffect(()=>{
      if(Validation){
        setDataMonth(new Date(Validation?.currentPayrollDate));
        setTdsImportMonth(new Date(Validation?.currentPayrollDate));
        getEmployeeList(Validation?.currentPayrollMonth,Validation?.currentPayrollYear);
        GetTDSDetailsOfMonth(Validation?.currentPayrollMonth,Validation?.currentPayrollYear)
      }
    },[Validation]);


      const getEmployeeList = async(MONTH,YEAR) =>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Employee List",response.data);
          const res = response.data;
          let ActiveEmpList = res.filter((data)=>data.isActive === true);
          setEmpList(ActiveEmpList.map((data)=>{
              return {
                  empGuId : data.guId,
                  empName: data.fullName,
                  empCode: data.empCode,
                  empTDSAmount : 0,
                  payrollMonth : MONTH,
                  payrollYear : YEAR
              }
          }))
        } catch (error) {
          console.error(error)
        }
      }

      const GetTDSDetailsOfMonth = async (MONTH,YEAR) => {
        try {
            const response = await axios.get(
             `${process.env.REACT_APP_ACQ_URL}/api/PayrollTDSMonthData/GetPayrollTDSMonthDataList?Month=${MONTH}&year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("TDS Details of Month",response);
            setTDSRowData(response.data);
        } catch (error) {
            console.error(error)
        }
      }


      const ImportedTDSDetailsReadExcel = (file)=>{

        const promise = new Promise((resolve,reject) => {
          
         const fileReader = new FileReader();
         fileReader.readAsArrayBuffer(file);
     
         fileReader.onload=(e)=>{
           const bufferArray = e.target.result;
     
           const wb = XLSX.read(bufferArray,{type:'buffer'});
           const wsname = wb.SheetNames[0];
     
           const ws = wb.Sheets[wsname];
     
           const data = XLSX.utils.sheet_to_json(ws, {raw: false});
     
           resolve(data)
         } 
     
         fileReader.onerror = (error) => {
           reject(error)
         }
     
        });
     
        promise.then((data)=>{
          // console.log("TDS Imported Data",data);
    
          let FinalData = data.map((obj)=>{
            return {...obj,
              companyId:userData.companyId,
              branchId:userData.isActiveBranch
            }
           })
    
           console.log("TDS Final Data",FinalData);
           setImportedTDSData(FinalData);
           SaveTDSImportedData(FinalData);
          });
     }

     const SaveTDSImportedData = async (FinalData) => {

        let body = FinalData
    
        console.log("TDS Imported Save Bodt",body);
        const response = await PostBulkTdsData(body);
        if(response){
          GetTDSDetailsOfMonth(new Date(DataMonth).getMonth()+1,new Date(DataMonth).getFullYear())
        }
     }

     console.log("Validation",Validation);
  return (
    <div>
      <div className="flex items-center justify-between border-b border-gray-400 mb-4 pb-2 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">
         Tax Deduction at Source (TDS)
        </h1>
        <div className='flex gap-4 items-center'>
            {Validation && <h6 className="font-bold text-gray-700">{Validation?.currentPayrollDesc}</h6>}
            <Button onClick={onImportTDSDetailsOpen} size={'sm'} leftIcon={<FontAwesomeIcon icon={faFileImport} />} colorScheme={'purple'}>Import TDS</Button>
            {/* <div>
             <DatePicker
               className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2 outline-none"
               selected={DataMonth}
               onChange={(date) => {
                 setDataMonth(date);
                 GetTDSDetailsOfMonth(new Date(date).getMonth()+1,new Date(DataMonth).getFullYear())
               }}
               showMonthYearPicker
               minDate={Validation?.payrollMonth === 0 ? new Date(Validation?.payrollStartFrom) : new Date(Validation?.payrollYear,Validation?.payrollMonth,1)}
               maxDate={Validation?.payrollMonth === 0 ? new Date(moment(Validation?.payrollStartFrom)) : new Date(Validation?.payrollYear,Validation?.payrollMonth + 1,1)}
               dateFormat="MM/yyyy"
               placeholderText="Select Month/Year"
             />
            </div> */}
        </div>
      </div>

      <div className=" mb-4 text-right">
        <Button isLoading={IsBulkTdsLoading} loadingText="Saving..." onClick={async()=>{
           let body = TDSRowData;
           const response = await PostBulkTdsData(body);
           if(response){
             GetTDSDetailsOfMonth(new Date(DataMonth).getMonth()+1,new Date(DataMonth).getFullYear());
           }
        }} leftIcon={<Save size={'20px'} />} colorScheme="purple">Save TDS</Button>
      </div>

      <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={TDSRowData} // Row Data for Rows
                columnDefs={TDSColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
           </div>


           
      <Modal size='full' isOpen={isImportTDSDetailsOpen} onClose={onImportTDSDetailsClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import TDS Details</ModalHeader>
          <ModalCloseButton onClick={()=>{setImportedTDSData([])}} />
          <hr />
            <ModalBody>
              <div className='mt-4 flex items-center gap-5'>
              {/* <div className="space-y-2">
                <label>Select Data Month</label>
                <div>
                 <DatePicker
                   className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
                   selected={TdsImportMonth}
                   onChange={(date) => {
                     setTdsImportMonth(date);

                     let SelMonthEmpList = EmpList.map((data)=>{
                        return {...data,
                            payrollMonth : new Date(date).getMonth()+1,
                            payrollYear : new Date(date).getFullYear(),
                        }
                    })
                    setEmpList(SelMonthEmpList);
                   }}
                   showMonthYearPicker
                   minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
                   maxDate={new Date()}
                   dateFormat="MM/yyyy"
                   placeholderText="Select Month/Year"
                 />
                </div>
              </div> */}
            {Validation && <h6 className="font-bold text-gray-700">{Validation?.currentPayrollDesc}</h6>}

               <CSVLink data={EmpList} headers={TDSImportExcelHeads} filename={"Import TDS Details.csv"}>
                 <Button leftIcon={<FontAwesomeIcon icon={faDownload} />} size='sm' colorScheme='purple'>Download Sample Excel for TDS Details Import</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                ImportedTDSDetailsReadExcel(file);
              }}
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(ImportedTDSData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setImportedTDSData([]);
                    onImportTDSDetailsClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default ImportTds

