import React, {useState,useRef, useMemo,useCallback} from "react";
import DatePicker from "react-datepicker";
import { Button, Center, Spinner, useToast } from '@chakra-ui/react';
import { RefreshCw } from 'react-feather';
import axios from 'axios';
import { AgGridReact } from "ag-grid-react";

function AttendanceHelper() {

    const userData = JSON.parse(localStorage.getItem('user'));

    const toast = useToast();
    const id = "toast";

    const [AttendanceMonth, setAttendanceMonth] = useState(new Date());
    const [IsLoading, setIsLoading] = useState(false);
    const monthNames = ["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER"];

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [columnDefs] = useState([
        {
          headerName: "Attendance Month",
          field: "AttendanceDate",
          valueGetter:(params) => monthNames[params.data.AttendanceDate]
        },
        {
          headerName: "Result",
          field: "AttResult",
          cellRenderer:(params)=> params.data.AttResult === 'True' ?
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            True
          </span> : params.data.AttResult === 'False' ?
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            False
          </span> : ''
        }
      ]);
  
      const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: 'no-border'
      }));


    const RunProcessAttendance = async () => {
      setIsLoading(true);
      try {
        const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/Attendance/CloneAttendance?AttendanceDate=${new Date(`${new Date(AttendanceMonth).getMonth()+1}/01/${new Date(AttendanceMonth).getFullYear()}`).toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`)
        console.log("Run Process Attendance Reponse",response);

        toast({
          id,
          title: response.data,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true
        });

        setRowData([{
          AttendanceDate : new Date(AttendanceMonth).getMonth(),
          AttResult : response.data
        }])
     
      } catch (error) {
        console.error(error);
      }finally{
        setIsLoading(false);
      }

      try {
        const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/Attendance/ProcessDailyAttendance?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Attendancedate=${new Date(`${new Date(AttendanceMonth).getMonth()+1}/01/${new Date(AttendanceMonth).getFullYear()}`).toLocaleDateString('en-CA')}`)
          console.log("Process Attendance Response",response);
      } catch (error) {
          console.error(error);
      }
    }
    

  return (
    <div>
      <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Process Attendance</h1>
        <div className="flex items-center gap-4">
            <label className="font-semibold text-lg ">Attendance Month :</label>
            <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={AttendanceMonth}
              onChange={(date) => {setAttendanceMonth(date);}}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Select Month"
            />
            </div>
            <Button isDisabled={IsLoading} onClick={RunProcessAttendance} colorScheme='purple' size='sm' variant={"solid"} ><RefreshCw size='17px' className="mr-2" />Process Attendance</Button>
        </div>
      </div>


      {IsLoading === false ? 

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div> : <Center>
               <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
             </Center> }

    </div>
  )
}

export default AttendanceHelper