import React,{useRef, useState, useMemo,useEffect, useCallback} from 'react'
import { AgGridReact } from "ag-grid-react";
import { useDisclosure, Button,useToast, Modal,ModalBody,ModalContent,ModalFooter,ModalHeader,ModalOverlay,ModalCloseButton } from '@chakra-ui/react';
import axios from 'axios';
import _ from 'lodash';

function AllocateAsset()  {

    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = 'toast';
      const {
          isOpen: isAllocateAssetOpen,
          onOpen: onAllocateAssetOpen,
          onClose: onAllocateAssetClose,
        } = useDisclosure();
  
        const {
          isOpen: isViewEmpAssetsOpen,
          onOpen: onViewEmpAssetsOpen,
          onClose: onViewEmpAssetsClose,
        } = useDisclosure();
  
        const {
          isOpen: isModifyEmpAssetsOpen,
          onOpen: onModifyEmpAssetsOpen,
          onClose: onModifyEmpAssetsClose,
        } = useDisclosure();
  

        const ModifyGridRef = useRef();
  
        // Table Data Variable
    const gridRef = useRef();
    const AssetListGridRef = useRef();
    const [EmpListRowData, setEmpListRowData] = useState([]); 
  
    const [SelectedEmp, setSelectedEmp] = useState([]);
  
    const [ViewEmpAssetsRowData, setViewEmpAssetsRowData] = useState([]);
    const [ModifyEmpAssetsRowData, setModifyEmpAssetsRowData] = useState([]);
  
    const [EmpAssetsList, setEmpAssetsList] = useState([]);
    const [AssetsListRowData, setAssetsListRowData] = useState([]); 
  
  
    const [AssetsListcolumnDefs, setAssetsListColumnDefs] = useState([
      // { headerName: "Asset Id", field: "id" },
      { headerName: "Asset Name", field: "assetsName",headerCheckboxSelection: true, 
       checkboxSelection: (params) => {
          return params.data.assetsName;
        }, },
      { headerName: "Serial No.", field: "assetsSerialNo" },
      { headerName: "Model No.", field: "assetsModelNo" },
      { headerName: "Asset Type", field: "assetsTypeName" },
      { headerName: "Asset Status", 
        field: "isAllocate", 
        cellRenderer : (params) => 
        params.data.isAllocate === false ? 
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            Available
          </span> : 
        params.data.isAllocate === true ? 
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
           Not Available
          </span> : ''
      },   
    ]);
  
    const [ViewEmpAssetsColumnDefs, setViewEmpAssetsColumnDefs] = useState([
      // { headerName: "Asset Id", field: "id" },
      { headerName: "Asset Name", field: "assetsName" },
      { headerName: "Serial No.", field: "assetsSerialNo" },
      { headerName: "Model No.", field: "assetsModelNo" },
      { headerName: "Asset Type", field: "assetsTypeName" },
    ]);
  
  
    const [ModifyAssetsListcolumnDefs, setModifyAssetsListColumnDefs] = useState([
      // { headerName: "Asset Id", field: "id" },
      { headerName: "Asset Name", field: "assetsName",headerCheckboxSelection: true, 
        checkboxSelection: (params) => {
          return params.data.assetsName;
        },
      },
      { headerName: "Serial No.", field: "assetsSerialNo" },
      { headerName: "Model No.", field: "assetsModelNo" },
      { headerName: "Asset Type", field: "assetsTypeName" },
      { headerName: "Asset Status", 
        field: "isAllocate", 
        cellRenderer : (params) => 
        params.data.isAllocate === false ? 
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            Available
          </span> : 
        params.data.isAllocate === true ? 
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
           Not Available
          </span> : ''
      }
    ]);


    useEffect(() => {
      getAssetsList();
      getAllocateAssetEmpList();
    }, [])
    
  
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  
    //Defining Columns Defiinitions Ex-fields data,headername,etc
    const [columnDefs, setColumnDefs] = useState([
      { headerName: "Emp Code", field: "empCode" },
      { headerName: "Emp Name", field: "empName" },
      { headerName: "Designation", field: "designationName" },
      { headerName: "Department", field: "departmentName" },
      {
          headerName: "Allocate Status",
          field: "assetsAllocateStatus",
          cellRenderer: (params) =>
            params.data.assetsAllocateStatus ===  "Allocated" ? (
              <span className="bg-green-500  text-white rounded-md px-1 py-0.5 text-sm font-medium">
                Allocated
              </span>
             ) : (
            <span className="bg-red-500 text-white  rounded-md px-1 py-0.5 text-sm font-medium">
              Not Allocated
            </span>
          ),
        },    {
          headerName: "Action",
          field: "assetsAllocateStatus",
          cellRenderer: (params) =>
            params.data.assetsAllocateStatus ===  "Not Allocated" ? (
              <Button onClick={()=>{
                setSelectedEmp(params.data);
                onAllocateAssetOpen();
              }} size='xs' colorScheme='blue'>Allocate Assets</Button>
            ) : (
              <div className='space-x-4'>
              <Button variant='solid' onClick={()=>{setSelectedEmp(params.data); HandleViewEmpAssets(params.data.empGuId);}} size='xs' colorScheme='purple'>View</Button>
              <Button variant='solid' onClick={()=>{setSelectedEmp(params.data); HandleModifyEmpAssets(params.data.empGuId);}} size='xs' colorScheme='blue' >Modify</Button>
              </div>
            ),
        },
    ]);
  


    const HandleModifyEmpAssets = async(EmpGuid) => {
      try {
        const response = await axios.get(
         `${process.env.REACT_APP_ACQ_URL}/api/Assets/GetEmpAssetsModify?IsAllocate=false&EmpGuId=${EmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
         console.log("Employee Assets",response);

         const res = response.data;

         console.log("Sorted Emp Assets",_.sortBy(res, [(obj) => !Boolean(obj.isAllocate)]));

         setModifyEmpAssetsRowData(_.sortBy(res, [(obj) => !Boolean(obj.isAllocate)]));
         onModifyEmpAssetsOpen();
        } catch (error) {
            console.error(error);
        }
    }
  
    const onFirstDataRendered = useCallback((params) => {
      ModifyGridRef.current.api.forEachNode((node) =>
        node.setSelected(node.data.isAllocate === true)
      );
    }, []);
  

  
    const HandleViewEmpAssets = async(EmpGuid) => {
      console.log(EmpGuid);
        try {
          const response = await axios.get(
           `${process.env.REACT_APP_ACQ_URL}/api/Assets/ViewAssetsAllocatelist?EmpGuId=${EmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
           console.log("Employee Assets",response);
  
           const res = response.data;
  
           setViewEmpAssetsRowData(res);
           onViewEmpAssetsOpen();
          } catch (error) {
              console.error(error);
          }
    }

    const onSelectionChanged = useCallback(() => {
      var AssetsList = AssetListGridRef.current.api.getSelectedRows();
     console.log(AssetsList);  
     setEmpAssetsList(AssetsList);
    }, []);
  

    const onModifySelectionChanged = useCallback(() => {
      var AssetsList = ModifyGridRef.current.api.getSelectedRows();
     console.log(AssetsList);  
     setEmpAssetsList(AssetsList);
    }, []);
  

    console.log("Emp Assets List",EmpAssetsList);
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
    }));
  
    const AssetdefaultColDef = useMemo(() => ({
      // flex: 1,
       sortable: true,
       filter: "agTextColumnFilter",
       floatingFilter: true,
       cacheQuickFilter: true,
     }));
  
  
  
     const getAssetsList = async()=>{
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Assets/GetAssetsMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Assets List Respone",response.data);
        const res = response.data;

        setModifyEmpAssetsRowData(res)
        const AvailableAssets = res.filter((data)=> data.isAllocate === false)
  
        setAssetsListRowData(AvailableAssets);
      } catch (error) {
        console.error(error)
      }
     }
  
  
     const getAllocateAssetEmpList = async() => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Assets/EmpGenralInfoAssetsList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log(response)
        console.log("Allocate EMP List ",response.data);
        setEmpListRowData(response.data)
      } catch (error) {
        console.error(error)
      }
     }
  
  
  
     const SaveEmployeeAllocatedAsset = async () => {
      let body=EmpAssetsList.map((obj)=>{
        return {...obj,
          isAllocate:true,
          empGuId:SelectedEmp.empGuId,
          empCode:SelectedEmp.empCode,
          empName: SelectedEmp.empName,
        }
       })
  
       console.log("Save Allocated Asset Body",body);
  
       await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Assets/SaveEmployeeAllocateAssetslist`,body
       )
       .then((response) => {
         const res = response.data;
         //console.log("res",res);
         if (!toast.isActive(id)) {
           toast({
             id,
             title:res,
             position: 'top',
             status: 'info',
             duration: 2000,
             isClosable: true,
           })
         }
         getAllocateAssetEmpList();
         getAssetsList();
         onAllocateAssetClose();
       })
       .catch((error) => {
         console.log(error.response);
         console.log(error.response.data);
         if (!toast.isActive(id)) {
           toast({
             id,
             title: 'ERROR',
             description: "Assets not Saved.",
             position: 'top',
             status: 'error',
             duration: 2000,
             isClosable: true,
           })
         }
       });
  
     }




     const HandleModifySave = async () =>{
         
      let body=EmpAssetsList.map((obj)=>{
        return {...obj,
          isAllocate:true,
          empGuId:SelectedEmp.empGuId,
          empCode:SelectedEmp.empCode,
          empName: SelectedEmp.empName,
        }
       })
  
       console.log("Modify Allocated Asset Body",body);
  
       await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Assets/SaveEmployeeAllocateAssetslist`,body
       )
       .then((response) => {
        console.log("Modified Saved Response",response);
         const res = response.data;
         //console.log("res",res);
         if (!toast.isActive(id)) {
           toast({
             id,
             title:res,
             position: 'top',
             status: 'info',
             duration: 2000,
             isClosable: true,
           })
         }
         getAllocateAssetEmpList();
         getAssetsList();
         onModifyEmpAssetsClose();
       })
       .catch((error) => {
         console.error(error);
         console.log(error.response.data);
         if (!toast.isActive(id)) {
           toast({
             id,
             title: 'ERROR',
             description: "Assets not Modified.",
             position: 'top',
             status: 'error',
             duration: 2000,
             isClosable: true,
           })
         }
       });


       const filteredArray = ModifyEmpAssetsRowData.filter(item1 => !EmpAssetsList.find(item2 => item2.empAllocateAssetsId === item1.empAllocateAssetsId));



         await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Assets/DeleteDataAllocateAssetslist`,filteredArray
         )
         .then((response) => {
          console.log("Modified Unselected Saved Response",response);
           const res = response.data;
           //console.log("res",res);
           if (!toast.isActive(id)) {
             toast({
               id,
               title:res,
               position: 'top',
               status: 'info',
               duration: 2000,
               isClosable: true,
             })
           }

         })
         .catch((error) => {
           console.error(error);
           console.log(error.response.data);
           if (!toast.isActive(id)) {
             toast({
               id,
               title: 'ERROR',
               description: "Assets not Modified.",
               position: 'top',
               status: 'error',
               duration: 2000,
               isClosable: true,
             })
           }
         });

     }
  
  
     let unSelectedList = ModifyEmpAssetsRowData.map((AllData)=>{
      if(EmpAssetsList.some((SelData)=>{
        if(SelData.empAllocateAssetsId !== AllData.empAllocateAssetsId){
          return true
        }else{
          return false
        }
      })){
        return   {
          empAllocateAssetsId : AllData.empAllocateAssetsId,
          assetsMasterId : AllData.assetsMasterId
        }
      }
 
      })

       console.log("Unselected List",_.compact(unSelectedList));


      const filteredArray = ModifyEmpAssetsRowData.filter(item1 => !EmpAssetsList.find(item2 => item2.empAllocateAssetsId === item1.empAllocateAssetsId));
      console.log("Filtered Array",filteredArray);



    return (
      <div>
        <div className="flex items-center justify-between  border-b border-gray-400 mb-4 pb-2 text-left">
          <h1 className="text-gray-700 text-xl font-bold ">Allocate Assets</h1>
        </div>
  
        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={EmpListRowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>
  
        <Modal size="6xl" isOpen={isAllocateAssetOpen} onClose={onAllocateAssetClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Allocate Asset</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>
            <div className="grid grid-cols-2 gap-4">
                <div className="mb-4">
                  <h6 className="text-lg font-semibold">
                    Employee Name:- <span className="font-normal">{SelectedEmp.empName}</span>
                  </h6>
                  <h6 className="text-lg font-semibold">
                    Employee Code:- <span className="font-normal">{SelectedEmp.empCode}</span>
                  </h6>
                </div>
  
                <div className="mb-4">
                  <h6 className="text-lg font-semibold">
                    Designation:- <span className="font-normal">{SelectedEmp.designationName}</span>
                  </h6>
                  <h6 className="text-lg font-semibold">
                    Department:- <span className="font-normal">{SelectedEmp.departmentName}</span>
                  </h6>
                </div>
  

              </div>
              <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={AssetListGridRef} // Ref for accessing Grid's API
            rowData={AssetsListRowData} // Row Data for Rows
            columnDefs={AssetsListcolumnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            rowSelection={"multiple"}
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
            onSelectionChanged={onSelectionChanged}
  
          />
        </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button onClick={SaveEmployeeAllocatedAsset} colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button
                onClick={onAllocateAssetClose}
                colorScheme="purple"
                variant="outline"
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
  
  
        <Modal size="6xl" isOpen={isViewEmpAssetsOpen} onClose={onViewEmpAssetsClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>View Asset</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>
            <div className="grid grid-cols-2 gap-4">
                <div className="mb-4">
                  <h6 className="text-lg font-semibold">
                    Employee Name:- <span className="font-normal">{SelectedEmp.empName}</span>
                  </h6>
                  <h6 className="text-lg font-semibold">
                    Employee Code:- <span className="font-normal">{SelectedEmp.empCode}</span>
                  </h6>
                </div>
  
                <div className="mb-4">
                  <h6 className="text-lg font-semibold">
                    Designation:- <span className="font-normal">{SelectedEmp.designationName}</span>
                  </h6>
                  <h6 className="text-lg font-semibold">
                    Department:- <span className="font-normal">{SelectedEmp.departmentName}</span>
                  </h6>
                </div>
  
  
              </div>
              <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            // ref={AssetListGridRef} // Ref for accessing Grid's API
            rowData={ViewEmpAssetsRowData} // Row Data for Rows
            columnDefs={ViewEmpAssetsColumnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            rowSelection={"multiple"}
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                onClick={onViewEmpAssetsClose}
                colorScheme="purple"
                variant="outline"
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
  
  
        <Modal size="6xl" isOpen={isModifyEmpAssetsOpen} onClose={onModifyEmpAssetsClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Modify Assets</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>
            <div className="grid grid-cols-2 gap-4">
                <div className="mb-4">
                  <h6 className="text-lg font-semibold">
                    Employee Name:- <span className="font-normal">{SelectedEmp.empName} {SelectedEmp.lastName}</span>
                  </h6>
                  <h6 className="text-lg font-semibold">
                    Employee Code:- <span className="font-normal">{SelectedEmp.empCode}</span>
                  </h6>
                </div>
  
                <div className="mb-4">
                  <h6 className="text-lg font-semibold">
                    Designation:- <span className="font-normal">{SelectedEmp.designationName}</span>
                  </h6>
                  <h6 className="text-lg font-semibold">
                    Department:- <span className="font-normal">{SelectedEmp.departmentName}</span>
                  </h6>
                </div>
  
  
              </div>
              <div className="ag-theme-alpine">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  ref={ModifyGridRef} // Ref for accessing Grid's API
                  rowData={ModifyEmpAssetsRowData} // Row Data for Rows
                  columnDefs={ModifyAssetsListcolumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  pagination={true}
                  rowSelection={"multiple"}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                  onSelectionChanged={onModifySelectionChanged}
                  onFirstDataRendered={onFirstDataRendered}
                />
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button onClick={HandleModifySave} colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button
                onClick={onModifyEmpAssetsClose}
                colorScheme="purple"
                variant="outline"
              >
                Cancel
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
  
  
      </div>
    )
  }

export default AllocateAsset