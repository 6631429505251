import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";

function SalaryArrearsList() {
    const userData = JSON.parse(localStorage.getItem("user"));
    const [ArrearDateRange, setArrearDateRange] = useState([
        new Date(moment().startOf("month")),
        new Date(moment().endOf("month")),
      ]);
      const [ArrearStartDate, ArrearEndDate] = ArrearDateRange;
    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  
    
  const HandleArrearChange = (dates) => {
    const [start,end] = dates;
    console.log("Dates", dates);
    setArrearDateRange(dates);
    GetArrearsList(start,end);
  };
  
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Employee Name",
        field: "empName",
      },
      {
        headerName: "Arrear Month",
        field: "arrearMonth",
      },
      {
        headerName: "Arrear Year",
        field: "arrearYear",
      },
      {
        headerName: "Month Days",
        field: "monthDays",
      },
      {
        headerName: "Present Days",
        field: "presentDays",
      },
      {
        headerName: "Arrear Amount",
        field: "arrearAmount",
      },
      {
        headerName: "Old Gross",
        field: "oldGrossSalary",
      },
      {
        headerName: "New Gross",
        field: "newGrossSalary",
      },
    ]);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable: true
    }));
  
  
    useEffect(()=>{
      GetArrearsList(ArrearStartDate,ArrearEndDate);
    },[]);
  
    const GetArrearsList = async (FromDate,ToDate) => {
      try{
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Arrear/GenerateArrear?ArrearFromDate=${moment(FromDate).format('YYYY-MM-DD')}&ArrearToDate=${moment(ToDate).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Arrears List",response);
        setRowData(response.data);
      } catch (err) {
        console.error(err);
      }
    }
    return (
      <div>
        <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
          <h1 className="text-xl font-bold text-gray-800">Arrears List</h1>
          <div>
          <DatePicker
            className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full px-2.5 py-2 outline-none"
            selectsRange={true}
            startDate={ArrearStartDate}
            endDate={ArrearEndDate}
            onChange={(update) => {
              HandleArrearChange(update);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText="Select Arrears Range"
          />
          </div>
        </div>
  
        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            rowSelection="multiple"
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>
      </div>
    )
  }

export default SalaryArrearsList