import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { useToast, Button, Input, Checkbox, useDisclosure, Modal, ModalBody, ModalCloseButton, ModalOverlay, ModalContent, ModalFooter, ModalHeader, Flex, InputGroup, InputLeftAddon, InputRightAddon, Select, } from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { CheckCircle } from "react-feather";
import {
  faArrowTurnUp,
  faDownload,
  faSliders,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import _ from "lodash";
import {
  View,
  Text,
  Document,
  Page,
  StyleSheet,
  Image,
  Line,
  Font,
  PDFDownloadLink,
  PDFViewer,
} from "@react-pdf/renderer";
import numeral from "numeral";

const ExitProcessSteps = [
  { label: "Step 1", description: "NOC Checklist" },
  { label: "Step 2", description: "Payables" },
  { label: "Step 3", description: "Dues & Overrides" },
  { label: "Step 4", description: "FNF Calculation" },
  { label: "Step 5", description: "Finalize" },
];

const styles = StyleSheet.create({
  page: {
    fontFamily: "Helvetica",
    paddingTop: 35,
    paddingLeft: 25,
    paddingRight: 25,
    lineHeight: 1.5,
    flexDirection: "column",
  },

  imgLogo: {
    height: 70,
    width: 110,
  },
});

function FNFSettlements() {
  const userData = JSON.parse(localStorage.getItem("user"));

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const [HoldSalaryData, setHoldSalaryData] = useState([]);
  const [HoldSalaryComponents, setHoldSalaryComponents] = useState([]);
  const [CheckListAttachment, setCheckListAttachment] = useState();

  const [MonthsAndYears, setMonthsAndYears] = useState([]);

  const [IsLeavePending, setIsLeavePending] = useState(false);

  const [EmpSalaryDetails, setEmpSalaryDetails] = useState([]);

  const [UnservedAmount, setUnservedAmount] = useState(0);
  const [TotalEncashDays, setTotalEncashDays] = useState(0);
  const [TotalEncashedAmount, setTotalEncashedAmount] = useState(0);

  const toast = useToast();
  const id = "toast";
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  let CurrentYear = new Date().getFullYear();
  // console.log("Current Year", CurrentYear);

  let CurrentMonth = new Date().getMonth() + 1;
  // console.log("Current Month",CurrentMonth);

  const CheckListRef = useRef();

  const FileRef = useRef();

  const reset = () => {
    FileRef.current.value = "";
  };

  const [UnservedDetails, setUnservedDetails] = useState([]);

  const [IsFinalized, setIsFinalized] = useState(false);

  const [TotalPayableAmount, setTotalPayableAmount] = useState(0);
  const [DescTotalPayable, setDescTotalPayable] = useState();
  const [OtherPayableAmount, setOtherPayableAmount] = useState(0);
  const [DescOtherPayable, setDescOtherPayable] = useState();
  const [OtherDeductionAmount, setOtherDeductionAmount] = useState(0);
  const [DescOtherDeduction, setDescOtherDeduction] = useState();
  const [TotalDueAmount, setTotalDueAmount] = useState(0);
  const [DescTotalDue, setDescTotalDue] = useState();
  const [SalaryAmount, setSalaryAmount] = useState(0);
  const [DescSalary, setDescSalary] = useState();
  const [TotalFNFCalculationAmount, setTotalFNFCalculationAmount] = useState(0);

  const [ApprovedResignRowData, setApprovedResignRowData] = useState([]);
  const [FNFSelectedEmp, setFNFSelectedEmp] = useState([]);
  const [ExitDetailsSelectedEMP, setExitDetailsSelectedEMP] = useState([]);
  const [LeaveEncashmentRowData, setLeaveEncashmentRowData] = useState([]);
  const [FinalLop, setFinalLop] = useState(0);
  const [LOPAdjust, setLOPAdjust] = useState(0);
  const [LOPOverrideComment, setLOPOverrideComment] = useState();
  const [EmpBasicAmount, setEmpBasicAmount] = useState();

  const [SelectedRecovery, setSelectedRecovery] = useState();
  const [IsGratuity, setIsGratuity] = useState(false);
  const {
    isOpen: isExitProcessOpen,
    onOpen: onExitProcessOpen,
    onClose: onExitProcessClose,
  } = useDisclosure();

  const {
    isOpen: isViewFNFDocumentOpen,
    onOpen: onViewFNFDocumentOpen,
    onClose: onViewFNFDocumentClose,
  } = useDisclosure();

  const {
    nextStep: ExitProcessNext,
    prevStep: ExitProcessPrev,
    reset: ExitProcessReset,
    activeStep: ExitProcessActiveStep,
  } = useSteps({ initialStep: 0 });

  // Payables Due Variables
  const [PayableLOP, setPayableLOP] = useState();
  const [PayableBonus, setPayableBonus] = useState(0);
  const [PayableOvertime, setPayableOvertime] = useState(0);
  const [PayableSalaryRevision, setPayableSalaryRevision] = useState();
  const [PayableShift, setPayableShift] = useState();
  const [PayableExpenseClaim, setPayableExpenseClaim] = useState(0);
  const [PayableAdjustmentRequests, setPayableAdjustmentRequests] = useState();
  const [PayableSalaryArrears, setPayableSalaryArrears] = useState();
  const [PayablePendingReceipts, setPayablePendingReceipts] = useState();
  const [PayableAdvanceSettlements, setPayableAdvanceSettlements] = useState(0);

  const [DuesOverrideData, setDuesOverrideData] = useState([]);
  const [TotalDeductionAmount, setTotalDeductionAmount] = useState(0);
  const [TotalFNFAmount, setTotalFNFAmount] = useState(0);

  const [EmpAssetsRowData, setEmpAssetsRowData] = useState([]);

  const [AssetAmount, setAssetAmount] = useState(0);
  const [ExpenseAmount, setExpenseAmount] = useState(0);
  const [TotalLOPAmount, setTotalLOPAmount] = useState(0);
  const [GratuityAmount, setGratuityAmount] = useState(0);
  const [CheckListRowData, setCheckListRowData] = useState([]);

  const [DueAssetAmount, setDueAssetAmount] = useState(0);
  const [DueExpenseAmount, setDueExpenseAmount] = useState(0);

  const [ChecklistColumnDefs, setChecklistColumnDefs] = useState([
    {
      headerName: "CheckList Name",
      field: "checkListName",
      headerCheckboxSelection: true,
      checkboxSelection: (params) => {
        return params.data.checkListName;
      },
    },
    {
      headerName: "Recovery Status",
      field: "isStatus",
      cellRenderer: (params) =>
        params.data.isStatus === false ? (
          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Not Recovered
          </span>
        ) : params.data.isStatus === true ? (
          <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Recovered
          </span>
        ) : (
          ""
        ),
    },
  ]);

  useEffect(() => {
    setUnservedAmount(
      UnservedDetails.settelmentAction === "Waive Off"
        ? 0
        : UnservedDetails.unservedDays * UnservedDetails.oneDayGrossSalary
    );
  }, [UnservedDetails]);

  useEffect(() => {
    setTotalEncashDays(
      LeaveEncashmentRowData?.reduce((sum, daysData) => {
        return sum + Number(daysData.leveEncashmentEncashLeaveDays || 0);
      }, 0)
    );
    setTotalEncashedAmount(
      LeaveEncashmentRowData?.reduce((sum, daysData) => {
        return sum + Number(daysData.leveEncashmentLeaveAmount || 0);
      }, 0)
    );
  }, [LeaveEncashmentRowData]);

  useEffect(() => {
    getResignationListofMonth();
    getLeaveTypeList();
    getSeparationCheckList();
  }, []);

  const [FNFColumnDefs, setFNFColumnDefs] = useState([
    { headerName: "Employee Name", field: "empName" },
    {
      headerName: "Resignation Date",
      field: "resignationDate",
      valueGetter: (params) => {
        return new Date(params.data.resignationDate).toLocaleDateString(
          "en-GB"
        );
      },
    },
    { headerName: "Separation Reason", field: "resignationReason" },
    {
      headerName: "Last Working Date",
      field: "lastWorkingDate",
      valueGetter: (params) => {
        return new Date(params.data.lastWorkingDate).toLocaleDateString(
          "en-GB"
        );
      },
    },
    {
      headerName: "Separation Status",
      field: "resignationStatus",
      cellRenderer: (params) =>
        params.data.resignationStatus === "Pending" ? (
          <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Pending
          </span>
        ) : params.data.resignationStatus === "Approved" ? (
          <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Approved
          </span>
        ) : params.data.resignationStatus === "Rejected" ? (
          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Rejected
          </span>
        ) : params.data.resignationStatus === "Cancelled" ? (
          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Cancelled
          </span>
        ) : params.data.resignationStatus === "Finalized" ? (
          <span className="bg-blue-500 font-medium px-1.5 py-0.5 rounded-md text-white">
            Finalized
          </span>
        ) : (
          ""
        ),
    },
    // { headerName: "Settled Amount", field: "settledAmount", valueGetter : ()=> 0 },
    { headerName: "HR Comment", field: "hrComment" },
    {
      headerName: "Action",
      field: "finalLop",
      floatingFilter: false,
      cellRenderer: (params) =>
        params.data.resignationStatus === "Finalized" ? (
          <div>
            <Button
              onClick={() => {
                setIsFinalized(true);
                HandleFNFSettlement(params.data.empGuId);
              }}
              variant="link"
              colorScheme="blue"
            >
              View
            </Button>{" "}
            Finalized Settlement
          </div>
        ) : (
          <div>
            <Button
              onClick={() => {
                setIsFinalized(false);
                HandleFNFSettlement(params.data.empGuId);
              }}
              variant="link"
              colorScheme="blue"
            >
              Click Here
            </Button>{" "}
            for F&F settlement
          </div>
        ),
    },
  ]);

  const [AssetRecoveryColumnDefs, setAssetRecoveryColumnDefs] = useState([
    { headerName: "Asset Name", field: "assetsName" },
    {
      headerName: "Assigned On",
      field: "assetsIssueDate",
      valueGetter: (params) => {
        return new Date(params.data.assetsIssueDate).toLocaleDateString(
          "en-GB"
        );
      },
    },
    {
      headerName: "Comment",
      field: "recoveryComment",
      editable: (params) => !params.data.isRecovery,
      singleClickEdit: (params) => !params.data.isRecovery,
      cellClass: (params) =>
        params.data.isRecovery === false ? "editable-grid-cell" : "",
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "Recovered in Good Condition",
          "Recovered in Damaged",
          "Retained",
        ],
      },
    },
    {
      headerName: "Amount",
      field: "recoveryAmount",
      editable: (params) => !params.data.isRecovery,
      singleClickEdit: (params) => !params.data.isRecovery,
      cellClass: (params) =>
        params.data.isRecovery === false ? "editable-grid-cell" : "",
    },
    {
      headerName: "Remarks",
      field: "assetsAllocateRemark",
      editable: (params) => !params.data.isRecovery,
      singleClickEdit: (params) => !params.data.isRecovery,
      cellClass: (params) =>
        params.data.isRecovery === false ? "editable-grid-cell" : "",
    },
    {
      headerName: "Action",
      cellRenderer: (params) =>
        params.data.isRecovery === false ? (
          <Button
            size="xs"
            onClick={async () => {
              let AssetData = params.data;

              let body = {
                assetsTypeId: AssetData.assetsTypeId,
                assetsTypeName: AssetData.assetsTypeName,
                assetsMasterId: AssetData.assetsMasterId,
                assetsName: AssetData.assetsName,
                assetsSerialNo: AssetData.assetsSerialNo,
                assetsModelNo: AssetData.assetsModelNo,
                assetsPurchaseDate: AssetData.assetsPurchaseDate,
                assetsWarrentyPeriod: AssetData.assetsWarrentyPeriod,
                assetsWarrentyExpiryDate: AssetData.assetsWarrentyExpiryDate,
                assetsRemark: AssetData.assetsRemark,
                isActive: AssetData.isActive,
                createdBy: AssetData.createdBy,
                createdOn: AssetData.createdOn,
                modifiedBy: AssetData.modifiedBy,
                modifiedOn: AssetData.modifiedOn,
                companyId: AssetData.companyId,
                branchId: AssetData.branchId,
                empAllocateAssetsId: AssetData.empAllocateAssetsId,
                empName: AssetData.empName,
                empCode: AssetData.empCode,
                empGuId: AssetData.empGuId,
                assetsDescription: AssetData.assetsDescription,
                assetsIssueDate: AssetData.assetsIssueDate,
                assetsAllocateRemark: AssetData.assetsAllocateRemark,
                isDamage: AssetData.isDamage,
                damageDate: AssetData.damageDate,
                isAllocate: false,
                isRecovery: true,
                recoveryAmount: AssetData.recoveryAmount,
                recoveryComment: AssetData.recoveryComment,
                recoveryDate: moment().format("YYYY-MM-DD"),
                isReturn: AssetData.isReturn,
                returnDate: AssetData.returnDate,
                returnComment: AssetData.returnComment,
              };

              console.log("Asset Recovery Body", body);

              await axios
                .post(
                  `${process.env.REACT_APP_ACQ_URL}/api/Assets/SaveEmpAllocateAssetswithoutlist`,
                  body
                )
                .then((response) => {
                  console.log("Save Asset Recovery Response ", response);
                  const res = response.data;

                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: res,
                      position: "top",
                      status: "info",
                      duration: 2000,
                      isClosable: true,
                    });
                  }

                  HandleFNFSettlement(AssetData.empGuId);
                })
                .catch((error) => {
                  console.log(error.response);

                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: "ERROR",
                      description: ` Asset not Recovered`,
                      position: "top",
                      status: "error",
                      duration: 2000,
                      isClosable: true,
                    });
                  }
                });
            }}
            colorScheme="blue"
          >
            Save
          </Button>
        ) : (
          ""
        ),
    },
  ]);

  useEffect(() => {
    let TotalAmount =
      Number(TotalPayableAmount || 0) +
      Number(OtherPayableAmount || 0) +
      Number(SalaryAmount || 0) -
      Number(TotalDueAmount || 0) -
      Number(OtherDeductionAmount || 0);

    console.log(
      "Total Payable",
      TotalPayableAmount,
      "Other Payable",
      OtherPayableAmount,
      "Total Due",
      TotalDueAmount,
      "Other Due",
      OtherDeductionAmount,
      "Salary Amount",
      SalaryAmount,
      "Total Amount",
      Number(numeral(TotalAmount).format("0.00"))
    );
    setTotalFNFCalculationAmount(
      Number(
        numeral(
          Number(TotalPayableAmount || 0) +
            Number(OtherPayableAmount || 0) +
            Number(SalaryAmount || 0) -
            (Number(TotalDueAmount || 0) - Number(OtherDeductionAmount || 0))
        ).format("0.00")
      )
    );
  }, [
    TotalPayableAmount,
    OtherPayableAmount,
    SalaryAmount,
    TotalDueAmount,
    OtherDeductionAmount,
  ]);
  console.log("Total FNF Calculation Amount", TotalFNFCalculationAmount);

  useEffect(() => {
    let FinalLopAdjusted = DuesOverrideData.totalLOP - LOPAdjust;
    // console.log("Final Lop Adjusted",Number(FinalLopAdjusted));
    setFinalLop(Number(FinalLopAdjusted || 0));
    setTotalLOPAmount(
      Number(DuesOverrideData.perdaySalary) * Number(LOPAdjust) || 0
    );
  }, [LOPAdjust]);

  useEffect(() => {
    moment().diff(FNFSelectedEmp.dateofjoin, "years") >= 5
      ? setGratuityAmount(
          numeral(
            (moment().diff(FNFSelectedEmp.dateofjoin, "years") *
              EmpBasicAmount *
              15) /
              26 || 0
          ).format("0.00")
        )
      : setGratuityAmount(0);
    console.log(
      "Gratuity Amount",
      (moment().diff(FNFSelectedEmp.dateofjoin, "years") *
        EmpBasicAmount *
        15) /
        26 || 0
    );
  }, [EmpBasicAmount]);

  const getSeparationCheckList = async (EMPGUID) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SeparationsCheckList/SeparationsCheckList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Checklist Response", response);
      HandleEmployeeChecklistData(response.data, EMPGUID);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleEmployeeChecklistData = async (ChecklistMasterList, EMPGUID) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SeparationsCheckList/SeparationsEmpCheckListByGUId?EmpGUID=${EMPGUID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Emp Checklist Response", response);
      const res = response.data;

      console.log(
        "Checklist",
        res.map((EmpCheckListData) => {
          if (EmpCheckListData.separationsCheckListId === 1) {
            return EmpCheckListData.isStatus;
          }
        })[0]
      );
      console.log(
        "Joined Row Data",
        ChecklistMasterList.map((MasterData) => {
          return {
            ...MasterData,
            isStatus:
              _.compact(
                res.map((EmpCheckListData) => {
                  if (
                    EmpCheckListData.separationsCheckListId ===
                    MasterData.separationsCheckListId
                  ) {
                    return EmpCheckListData.isStatus;
                  }
                })
              )[0] || false,
            empGuId:
              _.compact(
                res.map((EmpCheckListData) => {
                  if (
                    EmpCheckListData.separationsCheckListId ===
                    MasterData.separationsCheckListId
                  ) {
                    return EmpCheckListData.empGuId;
                  }
                })
              )[0] || "",
          };
        })
      );

      setCheckListRowData(
        ChecklistMasterList.map((MasterData) => {
          return {
            ...MasterData,
            isStatus:
              _.compact(
                res.map((EmpCheckListData) => {
                  if (
                    EmpCheckListData.separationsCheckListId ===
                    MasterData.separationsCheckListId
                  ) {
                    return EmpCheckListData.isStatus;
                  }
                })
              )[0] || false,
            resigAttachpath:
              _.compact(
                res.map((EmpCheckListData) => {
                  if (
                    EmpCheckListData.separationsCheckListId ===
                    MasterData.separationsCheckListId
                  ) {
                    return EmpCheckListData.resigAttachpath;
                  }
                })
              )[0] || null,
          };
        })
      );
    } catch (error) {
      console.error(error);
    }
  };

  console.log("Leave Encashment Row Data", LeaveEncashmentRowData);

  const getLeaveTypeList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/GetLeaveTYpe?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Type List", response.data);
      //  setLeaveEncashmentRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const onSelectionChanged = useCallback(() => {
    var CheckList = CheckListRef.current.api.getSelectedRows();
    console.log(CheckList);
    setSelectedRecovery(CheckList);
  }, []);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    resizable: true,
  }));

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const getResignationListofMonth = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetEmpResignationList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      //  console.log(response);
      console.log("Resignation List of Month", response);
      const AllResignation = response.data;
      let ApprovedResign = AllResignation.filter((data) => {
        return (
          data.resignationStatus === "Approved" ||
          data.resignationStatus === "Finalized"
        );
      });
      console.log("Approved Resignations", ApprovedResign);

      setApprovedResignRowData(
        _.orderBy(ApprovedResign, ["resignationDate"], ["desc"])
      );
    } catch (error) {
      console.error(error);
    }
  };

  const HandleFNFSettlement = async (EMPGUID) => {
    console.log(EMPGUID);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeGeneralInfoByGuid?EmpGuid=${EMPGUID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Handle Fnf Settlement EMP Response", response);
      const res = response.data;
      setFNFSelectedEmp(res);
      console.log(FNFSelectedEmp);
    } catch (error) {
      console.error(error);
    }

    try {
      const ExitDetailsResponse = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetEmpResignationByGUId?EmpGUID=${EMPGUID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("EMP Resgination Details FNF Response", ExitDetailsResponse);
      const exitres = ExitDetailsResponse.data;
      setExitDetailsSelectedEMP(exitres);

      handleCalculate(exitres.resignationDate, exitres.lastWorkingDate);
      console.log(
        "Differnce",
        (exitres.resignationDate, exitres.lastWorkingDate)
      );
    } catch (error) {
      console.error(error);
    }

    try {
      const EmpAssetsResponse = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Assets/ViewAssetsAllocatelist?EmpGuId=${EMPGUID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Emp Assets Response", EmpAssetsResponse);
      setEmpAssetsRowData(EmpAssetsResponse.data);
    } catch (error) {
      console.error(error);
    }

    onExitProcessOpen();
    ExitProcessReset();

    getSeparationCheckList(EMPGUID);
  };

  console.log("Months and Years", MonthsAndYears);

  const handleCalculate = (startDate, endDate) => {
    console.log("M&Y StartDate", startDate, "M&Y EndDate", endDate);
    const start = new Date(startDate);
    const end = new Date(endDate);
    let currentDate = new Date(start);

    const monthsAndYears = [];

    while (currentDate <= end) {
      const month = currentDate.getMonth() + 1;
      const year = currentDate.getFullYear();
      monthsAndYears.push({
        salaryMonth: month,
        salaryYear: year,
      });

      currentDate.setMonth(currentDate.getMonth() + 1);
    }

    setMonthsAndYears(monthsAndYears);
  };

  console.log(
    "Gratuity Years",
    moment().diff(FNFSelectedEmp.dateofjoin, "years"),
    "Date of Joining",
    FNFSelectedEmp.dateofjoin
  );

  const SavePayablesDues = async (e) => {
    e.preventDefault();

    let body = {
      empGuId: FNFSelectedEmp.guId,
      empName: FNFSelectedEmp.firstName,
      lopReversals: Number(PayableLOP || 0),
      bonusesDue: Number(PayableBonus || 0),
      overtime: Number(PayableOvertime || 0),
      salaryRevisions: Number(PayableSalaryRevision || 0),
      shiftAllowance: Number(PayableShift || 0),
      expenseClaims: Number(PayableExpenseClaim || 0),
      adjustmentRequests: Number(PayableAdjustmentRequests || 0),
      previousSalaryArrears: Number(PayableSalaryArrears || 0),
      pendingReceiptSubmissions: Number(PayablePendingReceipts || 0),
      advanceSettlements: Number(PayableAdvanceSettlements || 0),
      actualLOP: DuesOverrideData.actualLOP,
      lopAdjust: Number(LOPAdjust || 0),
      finalLOP: Number(FinalLop || 0),
      lopComment: LOPOverrideComment,
      salaryAmount: Number(SalaryAmount || 0),
      taxAmount: Number(TotalDeductionAmount || 0),
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log(body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/ExitProcessPayables/SaveEmpResigExitProcessPayables`,
        body
      )
      .then((response) => {
        console.log("Save Payables Response ", response);
        const res = response.data;

        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Payables not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const SaveNocChecklist = async (CheckListId) => {
    const RecoveryData = new FormData();

    RecoveryData.append("attachment", CheckListAttachment);
    RecoveryData.append("empGuId", FNFSelectedEmp.guId);
    RecoveryData.append("separationsCheckListId", CheckListId);
    RecoveryData.append("isStatus", true);
    RecoveryData.append("companyId", userData.companyId);
    RecoveryData.append("branchId", userData.isActiveBranch);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/SeparationsCheckList/SeparationsEmpCheckListwithFile`,
        RecoveryData
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        reset();
        getSeparationCheckList(FNFSelectedEmp.guId);
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Recovery not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const handleViewChecklistAttachment = async (DocPath) => {
    console.log(DocPath);
    await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`
    )
      .then((response) => {
        console.log("file Response", response);

        const fileURL = response.url;

        console.log("File URL", fileURL);

        let alink = document.createElement("a");
        alink.href = fileURL;
        // setViewChecklistAttachment(alink);
        // onViewAttachmentOpen();

        console.log("alink", alink);
        alink.download = fileURL;
        alink.click();
      })
      .catch((error) => {
        console.log("File Api Error", error);
      });
  };

  console.log("CheckLists Attachment", CheckListRowData);

  const NumberFormat = (value) => {
    return numeral(value).format("0,0.00");
  };

  const HandleViewFNFDocument = async (EMPGUID) => {
    onViewFNFDocumentOpen();
  };

  const FNFDocument = () => {
    return (
      <Document>
        <Page size="A4" style={styles.page}>
          {/* Company Details Header */}
          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingBottom: 10,
              borderBottom: 1,
              borderBottomColor: "#333333",
              alignItems: "center",
            }}
          >
            <Image style={styles.imgLogo} src="/images/acquaraLogo.png" />
            <View>
              <Text
                style={{
                  marginBottom: 2,
                  fontSize: 13,
                  fontWeight: "extrabold",
                  fontFamily: "Helvetica-Bold",
                }}
              >
                {userData.companyName}
              </Text>
              <Text style={{ fontSize: 9, fontWeight: "thin" }}>
                {userData.address1} {userData.address2}
              </Text>
              <Text style={{ fontSize: 9, fontWeight: "thin" }}>
                {userData.stateName} {userData.pincode}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              paddingTop: 10,
              paddingBottom: 8,
              borderBottom: 1,
              borderBottomColor: "#333333",
            }}
          >
            <View style={{ width: "60%", flexWrap: "wrap" }}>
              <Text style={{ marginBottom: "5px", fontSize: 10 }}>
                Employee Name : {FNFSelectedEmp.fullName}
              </Text>
              <Text style={{ marginBottom: "5px", fontSize: 10 }}>
                Department : {FNFSelectedEmp.departmentName}
              </Text>
              <Text style={{ marginBottom: "5px", fontSize: 10 }}>
                Date of Joining :{" "}
                {moment(FNFSelectedEmp.dateofjoin).format("DD/MM/YYYY")}
              </Text>
              <Text style={{ marginBottom: "5px", fontSize: 10 }}>
                Last Working Date :{" "}
                {moment(ExitDetailsSelectedEMP.lastWorkingDate).format(
                  "DD/MM/YYYY"
                )}
              </Text>
              <Text style={{ fontSize: 10 }}>
                FNF Date : {moment().format("DD/MM/YYYY")}
              </Text>
            </View>

            <View style={{ width: "40%", flexWrap: "wrap" }}>
              <Text style={{ marginBottom: "5px", fontSize: 10 }}>
                Employee Code : {FNFSelectedEmp.empCode}
              </Text>
              <Text style={{ marginBottom: "5px", fontSize: 10 }}>
                Designation : {FNFSelectedEmp.designationName}
              </Text>
              <Text style={{ marginBottom: "5px", fontSize: 10 }}>
                Resignation Date :{" "}
                {moment(ExitDetailsSelectedEMP.resignationDate).format(
                  "DD/MM/YYYY"
                )}
              </Text>
              <Text style={{ fontSize: 10 }}>
                No. of Days Worked :{" "}
                {moment(ExitDetailsSelectedEMP.lastWorkingDate).diff(
                  new Date(FNFSelectedEmp.dateofjoin),
                  "days"
                )}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "column",
              justifyContent: "space-between",
              borderBottom: "1px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                paddingTop: "4px",
                paddingBottom: "2px",
                borderBottom: 1,
                backgroundColor: "#CCEAFF",
              }}
            >
              <Text style={{ fontSize: 10, paddingRight: 7, paddingLeft: 7 }}>
                Components
              </Text>
              <Text style={{ fontSize: 10, paddingRight: 7, paddingLeft: 7 }}>
                Salary Month: {monthNames[HoldSalaryData.salaryMonth - 1]}/
                {HoldSalaryData.salaryYear}
              </Text>
              <Text style={{ fontSize: 10, paddingRight: 7, paddingLeft: 7 }}>
                Total Present: {HoldSalaryData.workingdays}
              </Text>

              <Text style={{ fontSize: 10, paddingRight: 7, paddingLeft: 7 }}>
                Amount
              </Text>
            </View>

            <View
              style={{ paddingRight: 7, paddingLeft: 7, paddingTop: "5px" }}
            >
              {HoldSalaryComponents.map((data, i) => {
                return (
                  <View
                    key={i}
                    style={{
                      marginBottom: "5px",
                      flexDirection: "row",
                      justifyContent: "space-between",
                    }}
                  >
                    <Text style={{ fontSize: 9 }}>{data.displayLabel}</Text>
                    <Text style={{ fontSize: 9 }}>
                      {NumberFormat(data.salaryValue)}
                    </Text>
                  </View>
                );
              })}
            </View>

            <View
              style={{
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                borderTop: 1,
                paddingTop: "5px",
              }}
            >
              <Text
                style={{
                  marginBottom: "3px",
                  fontSize: 10,
                  paddingRight: 7,
                  paddingLeft: 7,
                }}
              >
                Total
              </Text>
              <Text
                style={{
                  marginBottom: "3px",
                  fontSize: 10,
                  paddingRight: 7,
                  paddingLeft: 7,
                }}
              >
                {NumberFormat(HoldSalaryData.grossSalary)}
              </Text>
            </View>
          </View>

          <View
            style={{
              flexDirection: "column",
              justifyContent: "space-between",
              borderBottom: "1px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                paddingTop: "4px",
                paddingBottom: "2px",
                borderBottom: 1,
                backgroundColor: "#CCEAFF",
              }}
            >
              <Text style={{ fontSize: 10, paddingRight: 7, paddingLeft: 7 }}>
                Payables Details
              </Text>
              <Text style={{ fontSize: 10, paddingRight: 7, paddingLeft: 7 }}>
                Amount
              </Text>
            </View>

            <View
              style={{ paddingRight: 7, paddingLeft: 7, paddingTop: "5px" }}
            >
              <View
                style={{
                  marginBottom: "5px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 9 }}>Bonuses Due</Text>
                <Text style={{ fontSize: 9 }}>
                  {NumberFormat(PayableBonus)}
                </Text>
              </View>

              <View
                style={{
                  marginBottom: "5px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 9 }}>Expense Claims</Text>
                <Text style={{ fontSize: 9 }}>
                  {NumberFormat(PayableExpenseClaim)}
                </Text>
              </View>

              <View
                style={{
                  marginBottom: "5px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 9 }}>Adhoc Settlements</Text>
                <Text style={{ fontSize: 9 }}>
                  {NumberFormat(PayableAdvanceSettlements)}
                </Text>
              </View>

              <View
                style={{
                  marginBottom: "5px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 9 }}>Overtime</Text>
                <Text style={{ fontSize: 9 }}>
                  {NumberFormat(PayableOvertime)}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "column",
              justifyContent: "space-between",
              borderBottom: "1px",
            }}
          >
            <View
              style={{
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                paddingTop: "4px",
                paddingBottom: "2px",
                borderBottom: 1,
                backgroundColor: "#CCEAFF",
              }}
            >
              <Text style={{ fontSize: 10, paddingRight: 7, paddingLeft: 7 }}>
                Dues Details
              </Text>
              <Text style={{ fontSize: 10, paddingRight: 7, paddingLeft: 7 }}>
                Amount
              </Text>
            </View>

            <View
              style={{ paddingRight: 7, paddingLeft: 7, paddingTop: "5px" }}
            >
              <View
                style={{
                  marginBottom: "5px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 9 }}>Asset Amount</Text>
                <Text style={{ fontSize: 9 }}>{NumberFormat(AssetAmount)}</Text>
              </View>

              <View
                style={{
                  marginBottom: "5px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 9 }}>Expense Amount</Text>
                <Text style={{ fontSize: 9 }}>
                  {NumberFormat(ExpenseAmount)}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{ flexDirection: "column", justifyContent: "space-between" }}
          >
            <View
              style={{
                flexDirection: "row",
                alignContent: "center",
                justifyContent: "space-between",
                paddingTop: "4px",
                paddingBottom: "2px",
                borderBottom: 1,
                backgroundColor: "#CCEAFF",
              }}
            >
              <Text
                style={{
                  fontSize: 10,
                  paddingRight: 7,
                  paddingLeft: 7,
                  width: "33%",
                }}
              >
                Other Settlements
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  paddingRight: 7,
                  paddingLeft: 7,
                  width: "33%",
                }}
              >
                Eligibility Period
              </Text>
              <Text
                style={{
                  fontSize: 10,
                  paddingRight: 7,
                  paddingLeft: 7,
                  width: "33%",
                  textAlign: "right",
                }}
              >
                Amount
              </Text>
            </View>

            <View
              style={{ paddingRight: 7, paddingLeft: 7, paddingTop: "5px" }}
            >
              <View
                style={{
                  marginBottom: "5px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 9, width: "33%" }}>
                  Leave Encashment
                </Text>
                <Text style={{ fontSize: 9, width: "33%" }}>
                  {TotalEncashDays} (days)
                </Text>
                <Text style={{ fontSize: 9, width: "33%", textAlign: "right" }}>
                  {NumberFormat(TotalEncashedAmount)}
                </Text>
              </View>

              <View
                style={{
                  marginBottom: "5px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 9, width: "33%" }}>
                  {UnservedDetails.unservedDays <= 0
                    ? "Unserved Days"
                    : "Extra Days"}
                </Text>
                <Text style={{ fontSize: 9, width: "33%" }}>
                  {UnservedDetails.unservedDays}
                </Text>
                <Text style={{ fontSize: 9, width: "33%", textAlign: "right" }}>
                  {NumberFormat(UnservedAmount)}
                </Text>
              </View>

              <View
                style={{
                  marginBottom: "5px",
                  flexDirection: "row",
                  justifyContent: "space-between",
                }}
              >
                <Text style={{ fontSize: 9, width: "33%" }}>
                  Amount after Adjustments
                </Text>
                <Text style={{ fontSize: 9, width: "33%" }}></Text>
                <Text style={{ fontSize: 9, width: "33%", textAlign: "right" }}>
                  {UnservedDetails.settelmentAction === "Waive Off"
                    ? 0
                    : numeral(
                        Number(TotalEncashedAmount + UnservedAmount) || 0
                      ).format("0.00")}
                </Text>
              </View>
            </View>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              borderTop: 1,
              paddingTop: "5px",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                fontWeight: "extrabold",
                fontFamily: "Helvetica-Bold",
                width: "33%",
              }}
            >
              Total Payable Amount
            </Text>
            <Text style={{ fontSize: 10, width: "33%" }}>
              {DescTotalPayable}
            </Text>
            <Text style={{ fontSize: 10, width: "33%", textAlign: "right" }}>
              {NumberFormat(TotalPayableAmount)}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              borderTop: 1,
              paddingTop: "5px",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                width: "33%",
                fontWeight: "extrabold",
                fontFamily: "Helvetica-Bold",
              }}
            >
              Other Payable Amount
            </Text>
            <Text style={{ fontSize: 10, width: "33%" }}>
              {DescOtherPayable}
            </Text>
            <Text style={{ fontSize: 10, width: "33%", textAlign: "right" }}>
              {NumberFormat(OtherPayableAmount)}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              borderTop: 1,
              paddingTop: "5px",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                fontWeight: "extrabold",
                fontFamily: "Helvetica-Bold",
                width: "33%",
              }}
            >
              Total Due Amount
            </Text>
            <Text style={{ fontSize: 10, width: "33%" }}>{DescTotalDue}</Text>
            <Text style={{ fontSize: 10, width: "33%", textAlign: "right" }}>
              {NumberFormat(TotalDueAmount)}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              borderTop: 1,
              paddingTop: "5px",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                fontWeight: "extrabold",
                fontFamily: "Helvetica-Bold",
                width: "33%",
              }}
            >
              Other Due Amount
            </Text>
            <Text style={{ fontSize: 10, width: "33%" }}>
              {DescOtherDeduction}
            </Text>
            <Text style={{ fontSize: 10, width: "33%", textAlign: "right" }}>
              {NumberFormat(OtherDeductionAmount)}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              borderTop: 1,
              paddingTop: "5px",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                fontWeight: "extrabold",
                fontFamily: "Helvetica-Bold",
                width: "33%",
              }}
            >
              Salary Amount
            </Text>
            <Text style={{ fontSize: 10, width: "33%" }}>{DescSalary}</Text>
            <Text style={{ fontSize: 10, width: "33%", textAlign: "right" }}>
              {NumberFormat(SalaryAmount)}
            </Text>
          </View>

          <View
            style={{
              flexDirection: "row",
              alignContent: "center",
              justifyContent: "space-between",
              borderTop: 1,
              paddingTop: "5px",
            }}
          >
            <Text
              style={{
                fontSize: 10,
                fontWeight: "extrabold",
                fontFamily: "Helvetica-Bold",
                width: "33%",
              }}
            >
              Final Amount
            </Text>
            <Text style={{ fontSize: 10, width: "33%" }}>{""}</Text>
            <Text style={{ fontSize: 10, width: "33%", textAlign: "right" }}>
              {NumberFormat(TotalFNFCalculationAmount)}
            </Text>
          </View>
        </Page>
      </Document>
    );
  };

  console.log("Dues & Override Amount", DuesOverrideData);

  const HandleSaveNContinue = async () => {
    console.log("Active Step", ExitProcessActiveStep);

    if (ExitProcessActiveStep === 0) {
      try {
        const PendingResponse = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetPendingLeaveListDetails?EmpGuid=${FNFSelectedEmp.guId}&leavestatus=pending`
        );

        console.log("Leave Pending Response", PendingResponse);

        const res = PendingResponse.data;
        console.log(
          "Is Pending",
          res.some(
            (data) =>
              data.leaveStatus === "Pending" || data.leaveStatus === "pending"
          )
        );
        setIsLeavePending(
          res.some(
            (data) =>
              data.leaveStatus === "Pending" || data.leaveStatus === "pending"
          )
        );
      } catch (error) {
        console.log("Leave Pending Status", error);
      }

      try {
        const PayablesDataResponse = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetPayableDataByEmpGuid?PayrollMonth=${CurrentMonth}&PayrollYear=${CurrentYear}&EmpGuid=${FNFSelectedEmp.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("Payables Data Response", PayablesDataResponse);

        const res = PayablesDataResponse.data;

        setGratuityAmount(res.gratuityPayable);
        setPayableBonus(res.bonusPayable);
        setPayableOvertime(res.overtimePayable);
        setPayableAdvanceSettlements(res.adhocPayable);
        setPayableExpenseClaim(res.expensePayable);
        setLeaveEncashmentRowData(res._leaveEncashmentViewList || []);
        setUnservedDetails(res._empUnservedDayViewModel);
      } catch (error) {
        console.log("Payables Data Error", error);
      }

      ExitProcessNext();
    }

    if (ExitProcessActiveStep === 1) {
      let Payablesbody = {
        empGuId: FNFSelectedEmp.guId,
        empName: FNFSelectedEmp.fullName,
        gratuityPayable: GratuityAmount,
        overtimePayable: Number(PayableOvertime || 0),
        bonusPayable: Number(PayableBonus || 0),
        expensePayable: Number(PayableExpenseClaim || 0),
        adhocPayable: Number(PayableAdvanceSettlements || 0),
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
        _leaveEncashmentViewList: LeaveEncashmentRowData,
        _empUnservedDayViewModel: {
          empGuId: FNFSelectedEmp.guId,
          unservedDays: UnservedDetails.unservedDays,
          settelmentAmt: UnservedAmount,
          oneDayGrossSalary: UnservedDetails.oneDayGrossSalary,
          settelmentAction: UnservedDetails.settelmentAction,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
        },
      };

      console.log("Payables Body", Payablesbody);

      await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SavePayableData`,
          Payablesbody
        )
        .then((response) => {
          console.log("Save Payables Response ", response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: ` Payables not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

      try {
        const LOPDueResponse = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetEmpDuesDetailByGuid?PayrollMonth=${CurrentMonth}&PayrollYear=${CurrentYear}&EmpGuid=${FNFSelectedEmp.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("LOP Due Response", LOPDueResponse);
        const res = LOPDueResponse.data;

        setDueExpenseAmount(res.expenseDues);
        setDueAssetAmount(res.assetsRecoveryDues);

        setDuesOverrideData(res);
      } catch (error) {
        console.error(error);
      }

      ExitProcessNext();
    }

    if (ExitProcessActiveStep === 2) {
      let Duesbody = {
        empGuId: FNFSelectedEmp.guId,
        expenseDues: Number(DueExpenseAmount || 0),
        assetsRecoveryDues: Number(DueAssetAmount || 0),
        actualLOP: DuesOverrideData.totalLOP,
        lopAdjust: LOPAdjust,
        finalLOP: FinalLop,
        lopDues: DuesOverrideData.totalLOP,
        lopComment: LOPOverrideComment,
        totalLOP: DuesOverrideData.totalLOP,
        perdaySalary: DuesOverrideData.perdaySalary,
        lopReversals: TotalLOPAmount,
      };

      console.log("Due Save Body", Duesbody);

      await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveDuesData`,
          Duesbody
        )
        .then((response) => {
          console.log("Save Dues Response ", response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Dues not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

      try {
        const HoldSalaryResponse = await axios.get(
          `${
            process.env.REACT_APP_ACQ_URL
          }/api/PayrollSalaryReview/GeneratePayslipByGuid?EmpGuid=${
            FNFSelectedEmp.guId
          }&PayrollMonth=${
            new Date(ExitDetailsSelectedEMP.resignationDate).getMonth() + 1
          }&PayrollYear=${new Date(
            ExitDetailsSelectedEMP.resignationDate
          ).getFullYear()}&CompanyId=${userData.companyId}&BranchId=${
            userData.isActiveBranch
          }`
        );
        console.log("Hold Salary Response", HoldSalaryResponse);

        const res = HoldSalaryResponse.data;
        setHoldSalaryData(res);

        setHoldSalaryComponents(res.salarySlipComponents);
      } catch (error) {
        console.error(error);
      }

      try {
        const FNFCalcResponse = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetFNFCalculationByGuid?PayrollMonth=${CurrentMonth}&PayrollYear=${CurrentYear}&EmpGuid=${FNFSelectedEmp.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("FNF Response", FNFCalcResponse);
        const FnfRes = FNFCalcResponse.data;

        setTotalPayableAmount(FnfRes.totalPayableAmt);
        setDescTotalPayable(FnfRes.totalPayableAmtDescription);
        setOtherPayableAmount(FnfRes.otherPayable);
        setDescOtherPayable(FnfRes.otherPayableDescription);
        setOtherDeductionAmount(FnfRes.otherDeductionAmt);
        setDescOtherDeduction(FnfRes.otherDeductionDescription);
        setTotalDueAmount(FnfRes.totalDues);
        setDescTotalDue(FnfRes.totalDuesDescription);
        setSalaryAmount(FnfRes.holdSalaryAmount);
        setDescSalary(FnfRes.holdSalaryDescription);
        //  setTotalFNFCalculationAmount(FnfRes.finalAmount)
      } catch (error) {
        console.error(error);
      }

      ExitProcessNext();
    }

    if (ExitProcessActiveStep === 3) {
      let FnfCalcBody = {
        empGuId: FNFSelectedEmp.guId,
        empName: FNFSelectedEmp.fullName,
        totalPayableAmt: Number(TotalPayableAmount || 0),
        totalPayableAmtDescription: DescTotalPayable,
        otherPayable: Number(OtherPayableAmount || 0),
        otherPayableDescription: DescOtherPayable,
        totalDues: Number(TotalDueAmount || 0),
        totalDuesDescription: DescTotalDue,
        otherDeductionAmt: Number(OtherDeductionAmount || 0),
        otherDeductionDescription: DescOtherDeduction,
        holdSalaryAmount: Number(SalaryAmount || 0),
        holdSalaryDescription: DescSalary,
        finalAmount: Number(TotalFNFCalculationAmount || 0),
      };

      console.log("Save FNF Calc Body", FnfCalcBody);

      await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveFNFCalculationData`,
          FnfCalcBody
        )
        .then((response) => {
          console.log("Save FNF Calc Response ", response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }
        })
        .catch((error) => {
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `FNF Calculation not Saved`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

      ExitProcessNext();
    }
  };

  const HandleNextStep = async () => {
    if (ExitProcessActiveStep === 0) {
      try {
        const PendingResponse = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetPendingLeaveListDetails?EmpGuid=${FNFSelectedEmp.guId}&leavestatus=pending`
        );

        console.log("Leave Pending Response", PendingResponse);

        const res = PendingResponse.data;
        console.log(
          "Is Pending",
          res.some(
            (data) =>
              data.leaveStatus === "Pending" || data.leaveStatus === "pending"
          )
        );
        setIsLeavePending(
          res.some(
            (data) =>
              data.leaveStatus === "Pending" || data.leaveStatus === "pending"
          )
        );
      } catch (error) {
        console.log("Leave Pending Status", error);
      }

      try {
        const PayablesDataResponse = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetPayableDataByEmpGuid?PayrollMonth=${CurrentMonth}&PayrollYear=${CurrentYear}&EmpGuid=${FNFSelectedEmp.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("Payables Data Response", PayablesDataResponse);

        const res = PayablesDataResponse.data;

        setGratuityAmount(res.gratuityPayable);
        setPayableBonus(res.bonusPayable);
        setPayableOvertime(res.overtimePayable);
        setPayableAdvanceSettlements(res.adhocPayable);
        setPayableExpenseClaim(res.expensePayable);
        setLeaveEncashmentRowData(res._leaveEncashmentViewList || []);
        setUnservedDetails(res._empUnservedDayViewModel);
      } catch (error) {
        console.log("Payables Data Error", error);
      }

      ExitProcessNext();
    }

    if (ExitProcessActiveStep === 1) {
      try {
        const LOPDueResponse = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetEmpDuesDetailByGuid?PayrollMonth=${CurrentMonth}&PayrollYear=${CurrentYear}&EmpGuid=${FNFSelectedEmp.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("LOP Due Response", LOPDueResponse);
        const res = LOPDueResponse.data;

        setDueExpenseAmount(res.expenseDues);
        setDueAssetAmount(res.assetsRecoveryDues);

        setDuesOverrideData(res);
      } catch (error) {
        console.error(error);
      }
      ExitProcessNext();
    }

    if (ExitProcessActiveStep === 2) {
      try {
        const HoldSalaryResponse = await axios.get(
          `${
            process.env.REACT_APP_ACQ_URL
          }/api/PayrollSalaryReview/GeneratePayslipByGuid?EmpGuid=${
            FNFSelectedEmp.guId
          }&PayrollMonth=${
            new Date(ExitDetailsSelectedEMP.resignationDate).getMonth() + 1
          }&PayrollYear=${new Date(
            ExitDetailsSelectedEMP.resignationDate
          ).getFullYear()}&CompanyId=${userData.companyId}&BranchId=${
            userData.isActiveBranch
          }`
        );
        console.log("Hold Salary Response", HoldSalaryResponse);

        const res = HoldSalaryResponse.data;
        setHoldSalaryData(res);

        setHoldSalaryComponents(res.salarySlipComponents);
      } catch (error) {
        console.error(error);
      }

      try {
        const FNFCalcResponse = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetFNFCalculationByGuid?PayrollMonth=${CurrentMonth}&PayrollYear=${CurrentYear}&EmpGuid=${FNFSelectedEmp.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log("FNF Response", FNFCalcResponse);
        const FnfRes = FNFCalcResponse.data;

        setTotalPayableAmount(FnfRes.totalPayableAmt);
        setDescTotalPayable(FnfRes.totalPayableAmtDescription);
        setOtherPayableAmount(FnfRes.otherPayable);
        setDescOtherPayable(FnfRes.otherPayableDescription);
        setOtherDeductionAmount(FnfRes.otherDeductionAmt);
        setDescOtherDeduction(FnfRes.otherDeductionDescription);
        setTotalDueAmount(FnfRes.totalDues);
        setDescTotalDue(FnfRes.totalDuesDescription);
        setSalaryAmount(FnfRes.holdSalaryAmount);
        setDescSalary(FnfRes.holdSalaryDescription);
        //  setTotalFNFCalculationAmount(FnfRes.finalAmount)
      } catch (error) {
        console.error(error);
      }

      ExitProcessNext();
    }

    if (ExitProcessActiveStep === 3) {
      ExitProcessNext();
    }
  };

  const HandleFinalizeSettlement = async () => {
    if (
      window.confirm(
        "Are you sure you want to Finalize the Settlement? After Finalization no edition can be done!"
      )
    ) {
      let body = {
        resignationId: ExitDetailsSelectedEMP.resignationId,
        empGuId: ExitDetailsSelectedEMP.empGuId,
        empName: ExitDetailsSelectedEMP.empName,
        resignationDate: ExitDetailsSelectedEMP.resignationDate,
        isNoticeStatus: ExitDetailsSelectedEMP.isNoticeStatus,
        noticeStartDate: ExitDetailsSelectedEMP.noticeStartDate,
        noticeEndDate: ExitDetailsSelectedEMP.noticeEndDate,
        resignationReason: ExitDetailsSelectedEMP.resignationReason,
        empSalaryStatus: ExitDetailsSelectedEMP.empSalaryStatus,
        lastWorkingDate: ExitDetailsSelectedEMP.lastWorkingDate,
        resignationStatus: "Finalized",
        salaryHoldMonth: ExitDetailsSelectedEMP.salaryHoldMonth,
        separationType: ExitDetailsSelectedEMP.separationType,
        resignRecoveryDays: ExitDetailsSelectedEMP.resignRecoveryDays,
        resigSalaryStatus: ExitDetailsSelectedEMP.resigSalaryStatus,
        resigSalaryHold: ExitDetailsSelectedEMP.resigSalaryHold,
        hrComment: ExitDetailsSelectedEMP.hrComment,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
        resignMonth: new Date(
          ExitDetailsSelectedEMP.resignationDate
        ).getMonth(),
        resignYear: new Date(
          ExitDetailsSelectedEMP.resignationDate
        ).getFullYear(),
      };

      console.log("Edit Resignation Body", body);

      await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveEmpResignation`,
          body,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const res = response.data;
          console.log("res", res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }
          getResignationListofMonth();
          onExitProcessClose();
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: "FNF Not Finalized.",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });
    }
  };

  return (
    <div>
      <div className=" w-full border-b border-gray-400 mb-3 pb-3 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">FNF Settlement</h1>
      </div>

      <div className="ag-theme-alpine mt-2">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={ApprovedResignRowData} // Row Data for Rows
          columnDefs={FNFColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Modal
        size="full"
        scrollBehavior="inside"
        isOpen={isExitProcessOpen}
        onClose={() => {
          setIsFinalized(false);
          onExitProcessClose();
        }}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="gray.400">Exit Process</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div className=" mx-auto my-6">
              <Steps
                checkIcon={CheckCircle}
                colorScheme="purple"
                activeStep={ExitProcessActiveStep}
              >
                <Step label={"NOC Checklist"}>
                  <div className="pt-4">
                    <div className="flex pb-1 gap-8 mb-4">
                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Name</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.firstName} {FNFSelectedEmp.lastName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Code</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.empCode}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Department</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.departmentName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Designation</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.designationName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Date of Joining</p>
                        <h6 className="font-medium">
                          {new Date(
                            FNFSelectedEmp.dateofjoin
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">
                          Last Working Date
                        </p>
                        <h6 className="font-medium">
                          {new Date(
                            ExitDetailsSelectedEMP.lastWorkingDate
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>
                    </div>

                    <div>
                      <div className="flex gap-8">
                        <div className="w-2/3 space-y-2">
                          <div className=" flex justify-between items-center border-b border-gray-400 mb-3 pb-3 text-left">
                            <h1 className="text-gray-700 text-lg font-bold ">
                              Assets Submissions
                            </h1>
                            {EmpAssetsRowData.length === 0 ? (
                              <h1 className="text-white bg-red-500  text-sm py-1 px-1.5 rounded-lg font-medium">
                                No Assets Assigned to this Employee!!
                              </h1>
                            ) : (
                              ""
                            )}
                            {EmpAssetsRowData.some(
                              (data) => data.recoveryComment === null
                            ) ? (
                              <h1 className="text-white bg-yellow-500  text-sm py-1 px-1.5 rounded-lg font-medium">
                                Assets Submission Pending
                              </h1>
                            ) : (
                              ""
                            )}
                          </div>

                          <div className="ag-theme-alpine">
                            <AgGridReact
                              style={gridStyle}
                              domLayout={"autoHeight"}
                              rowData={EmpAssetsRowData} // Row Data for Rows
                              columnDefs={AssetRecoveryColumnDefs} // Column Defs for Columns
                              defaultColDef={defaultColDef} // Default Column Properties
                              animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                              pagination={true}
                              rowSelection={"multiple"}
                              paginationPageSize={10}
                              paginationNumberFormatter={
                                paginationNumberFormatter
                              }
                            />
                          </div>

                          <div className=" w-full border-b border-gray-400 mb-3 pb-3 text-left">
                            <h1 className="text-gray-700 text-lg font-bold ">
                              NOC Checklist
                            </h1>
                          </div>

                          <div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                            <table className="w-full text-sm text-left text-gray-500 ">
                              <thead className="text-xs text-white uppercase bg-slate-700 ">
                                <tr>
                                  <th scope="col" className="py-3 px-6">
                                    Checklist Name
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Recovery Status
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Attachment
                                  </th>
                                  <th scope="col" className="py-3 px-6">
                                    Action
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {CheckListRowData.map((data, i) => {
                                  return (
                                    <tr
                                      key={i}
                                      className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300"
                                    >
                                      <th
                                        scope="row"
                                        className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                                      >
                                        {data.checkListName}
                                      </th>
                                      <td className="py-4 px-6">
                                        {data.isStatus === false ? (
                                          <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                                            Not Recovered
                                          </span>
                                        ) : data.isStatus === true ? (
                                          <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                                            Recovered
                                          </span>
                                        ) : (
                                          ""
                                        )}
                                      </td>

                                      <td className="py-4 px-6">
                                        {data.resigAttachpath === null ? (
                                          <div className="w-1/2">
                                            <Input
                                              ref={FileRef}
                                              onChange={({ target }) => {
                                                setCheckListAttachment(
                                                  target.files[0]
                                                );
                                              }}
                                              type={"file"}
                                              borderColor={"gray.400"}
                                              bg={"white"}
                                            />
                                          </div>
                                        ) : (
                                          <Button
                                            variant={"outline"}
                                            leftIcon={
                                              <FontAwesomeIcon
                                                icon={faDownload}
                                              />
                                            }
                                            onClick={() => {
                                              handleViewChecklistAttachment(
                                                data.resigAttachpath
                                              );
                                            }}
                                            colorScheme={"purple"}
                                            size={"sm"}
                                          >
                                            Download
                                          </Button>
                                        )}
                                      </td>
                                      <td>
                                        <div>
                                          {data.resigAttachpath === null ? (
                                            <Button
                                              onClick={() => {
                                                SaveNocChecklist(
                                                  data.separationsCheckListId
                                                );
                                              }}
                                              colorScheme={"purple"}
                                              size={"sm"}
                                            >
                                              Save
                                            </Button>
                                          ) : (
                                            ""
                                          )}
                                        </div>
                                      </td>
                                    </tr>
                                  );
                                })}
                              </tbody>
                            </table>
                          </div>
                        </div>

                        <div className="w-1/4">
                          <div className="bg-gray-200 drop-shadow-md rounded-xl p-4">
                            <h6 className="text-lg font-medium">
                              Exit Details
                            </h6>
                            <div className="grid grid-cols-2 mt-5">
                              <div className="space-y-6">
                                <div className="space-y-1">
                                  <p className="text-sm text-gray-500">
                                    Separation Date
                                  </p>
                                  <h6 className="font-medium">
                                    {new Date(
                                      ExitDetailsSelectedEMP.resignationDate
                                    ).toLocaleDateString("en-GB")}
                                  </h6>
                                </div>

                                <div className="space-y-1">
                                  <p className="text-sm text-gray-500">
                                    Notice Period Start Date
                                  </p>
                                  <h6 className="font-medium">
                                    {new Date(
                                      ExitDetailsSelectedEMP.noticeStartDate
                                    ).toLocaleDateString("en-GB")}
                                  </h6>
                                </div>

                                <div className="space-y-1">
                                  <p className="text-sm text-gray-500">
                                    Last Working Date
                                  </p>
                                  <h6 className="font-medium">
                                    {new Date(
                                      ExitDetailsSelectedEMP.lastWorkingDate
                                    ).toLocaleDateString("en-GB")}
                                  </h6>
                                </div>
                              </div>

                              <div className="space-y-6">
                                <div className="space-y-1">
                                  <p className="text-sm text-gray-500">
                                    Separation Reason
                                  </p>
                                  <h6 className="font-medium">
                                    {ExitDetailsSelectedEMP.resignationReason}
                                  </h6>
                                </div>

                                <div className="space-y-1">
                                  <p className="text-sm text-gray-500">
                                    Notice Period End Date
                                  </p>
                                  <h6 className="font-medium">
                                    {new Date(
                                      ExitDetailsSelectedEMP.noticeEndDate
                                    ).toLocaleDateString("en-GB")}
                                  </h6>
                                </div>

                                <div className="space-y-1">
                                  <p className="text-sm text-gray-500">
                                    HR Comment
                                  </p>
                                  <h6 className="font-medium">
                                    {ExitDetailsSelectedEMP.hrComment}
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Step>

                <Step label={"Payables"}>
                  <div className="pt-4">
                    <div className="flex pb-1 gap-8 mb-8">
                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Name</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.firstName} {FNFSelectedEmp.lastName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Code</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.empCode}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Department</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.departmentName}
                        </h6>
                      </div>
                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Designation</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.designationName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Date of Joining</p>
                        <h6 className="font-medium">
                          {new Date(
                            FNFSelectedEmp.dateofjoin
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">
                          Last Working Date
                        </p>
                        <h6 className="font-medium">
                          {new Date(
                            ExitDetailsSelectedEMP.lastWorkingDate
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>
                    </div>

                    <div className="space-y-2 mb-6">
                      <h6 className="font-medium text-xl">Gratuity</h6>
                      {moment().diff(FNFSelectedEmp.dateofjoin, "years") >=
                      5 ? (
                        <Checkbox
                          isChecked={IsGratuity}
                          onChange={({ target }) => {
                            setIsGratuity(target.checked);
                          }}
                          borderColor="gray.400"
                        >
                          Employee is eligible for gratuity payment (minimum 5
                          years of service in company)
                        </Checkbox>
                      ) : (
                        <span className="text-red-400">
                          Employee is Not Eligibile for Gratuity
                        </span>
                      )}
                    </div>

                    <div className="flex gap-6">
                      {moment().diff(FNFSelectedEmp.dateofjoin, "years") >=
                      5 ? (
                        <div className=" mb-4 flex flex-1 justify-between">
                          <h6 className="font-medium text-xl">
                            Gratuity Amount
                          </h6>
                          <div className="w-1/2">
                            <Input
                              type="number"
                              value={GratuityAmount}
                              onChange={({ target }) => {
                                setGratuityAmount(target.value);
                              }}
                              bgColor="white"
                              borderColor="gray.400"
                              placeholder="Gratuity Amount"
                            />
                          </div>
                        </div>
                      ) : (
                        ""
                      )}

                      <div className=" mb-4 flex flex-1 justify-between">
                        <h6 className="font-medium text-xl">Bonus Due</h6>
                        <div className="w-1/2">
                          <Input
                            type="number"
                            bgColor="white"
                            value={PayableBonus}
                            onChange={(e) => {
                              setPayableBonus(e.target.value);
                            }}
                            borderColor="gray.400"
                            placeholder="Bonuses Due"
                          />
                        </div>
                      </div>

                      <div className=" mb-4 flex flex-1 justify-between">
                        <h6 className="font-medium text-xl">Expense Claims</h6>
                        <div className="w-1/2">
                          <Input
                            type="number"
                            bgColor="white"
                            value={PayableExpenseClaim}
                            onChange={(e) => {
                              setPayableExpenseClaim(e.target.value);
                            }}
                            borderColor="gray.400"
                            placeholder="Expense Claims"
                          />
                        </div>
                      </div>

                      <div className=" mb-4 flex flex-1 justify-between">
                        <h6 className="font-medium text-xl">
                          Adhoc Settlements
                        </h6>
                        <div className="w-1/2">
                          <Input
                            type="number"
                            bgColor="white"
                            value={PayableAdvanceSettlements}
                            onChange={(e) => {
                              setPayableAdvanceSettlements(e.target.value);
                            }}
                            borderColor="gray.400"
                            placeholder="Advance Settlements"
                          />
                        </div>
                      </div>

                      <div className=" mb-4 flex flex-1 justify-between">
                        <h6 className="font-medium text-xl">Overtime</h6>
                        <div className="w-1/2">
                          <Input
                            type="number"
                            bgColor="white"
                            value={PayableOvertime}
                            onChange={(e) => {
                              setPayableOvertime(e.target.value);
                            }}
                            borderColor="gray.400"
                            placeholder="Overtime"
                          />
                        </div>
                      </div>
                    </div>

                    <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
                      <h1 className="text-lg font-bold text-gray-800">
                        Leave Encashment{" "}
                        {IsLeavePending ? (
                          <span className="ml-2 text-white bg-red-500  text-sm py-1 px-1.5 rounded-lg font-medium">
                            Pending Leaves are there for this Employee!
                          </span>
                        ) : (
                          ""
                        )}
                      </h1>
                      <h6>Calculated on Basic Salary</h6>
                    </div>

                    <div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-xs text-white uppercase bg-slate-700 ">
                          <tr>
                            <th scope="col" className="py-3 px-6">
                              Leave Type
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Leave Balance
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Encash Leave for day(s)
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Amount
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Pay Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {LeaveEncashmentRowData?.map((data, i) => {
                            return (
                              <tr
                                key={i}
                                className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300"
                              >
                                <th
                                  scope="row"
                                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                                >
                                  {data.leaveType}
                                </th>
                                <td className="py-4 px-6">
                                  {data.leveEncashmentLeaveBalance}
                                </td>

                                <td className="py-4 px-6">
                                  <Input
                                    name={data.leaveType}
                                    value={data.leveEncashmentEncashLeaveDays || ''}
                                    onChange={({ target }) => {
                                      console.log("Target Name", target.name);

                                      let EncashDays =
                                        target.value <=
                                        data.leveEncashmentLeaveBalance
                                          ? target.value
                                          : data.leveEncashmentLeaveBalance;
                                      setLeaveEncashmentRowData((prev) => {
                                        const newState = prev.map((obj) => {
                                          if (obj.leaveType === target.name) {
                                            return {
                                              ...obj,
                                              leveEncashmentEncashLeaveDays:
                                                EncashDays,
                                              leveEncashmentLeaveAmount:
                                                numeral(
                                                  EncashDays *
                                                    obj.oneDayBasicSalary
                                                ).format("0.00"),
                                            };
                                          }
                                          return obj;
                                        });
                                        return newState;
                                      });
                                    }}
                                    bgColor="white"
                                    borderColor="gray.400"
                                    type="number"
                                    placeholder="Enter No. of Days"
                                  />
                                </td>

                                <td className="py-4 px-6">
                                  {data.leveEncashmentLeaveAmount}
                                </td>

                                <td className="py-4 px-6">
                                  <Select
                                    bg
                                    name={data.leaveType}
                                    value={data.payStatus || ''}
                                    onChange={({ target }) => {
                                      console.log("Target Name", target.name);
                                      setLeaveEncashmentRowData((prev) => {
                                        const newState = prev.map((obj) => {
                                          if (obj.leaveType === target.name) {
                                            return {
                                              ...obj,
                                              payStatus: target.value,
                                              leveEncashmentEncashLeaveDays:
                                                target.value === "Waive Off"
                                                  ? 0
                                                  : obj.leveEncashmentEncashLeaveDays,
                                              leveEncashmentLeaveAmount:
                                                target.value === "Waive Off"
                                                  ? 0
                                                  : obj.leveEncashmentLeaveAmount,
                                            };
                                          }
                                          return obj;
                                        });
                                        return newState;
                                      });
                                    }}
                                    borderColor="gray.400"
                                    placeholder="Select Pay Action"
                                  >
                                    <option value={"Pay"}>Pay</option>
                                    <option value={"Hold"}>Hold</option>
                                    <option value={"Buy Out"}>Buy Out</option>
                                    <option value={"Waive Off"}>
                                      Waive Off
                                    </option>
                                  </Select>
                                </td>
                              </tr>
                            );
                          })}

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            ></th>
                            <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                              Total
                            </td>
                            <td className="py-4 px-6">{TotalEncashDays}</td>
                            <td className="py-4 px-6">{TotalEncashedAmount}</td>
                            <td className="py-4 px-6"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
                      <h1 className="text-lg font-bold text-gray-800">
                        Unserved Days Settlement
                      </h1>
                      <h6>Calculated on Gross Salary</h6>
                    </div>

                    <div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-xs text-white uppercase bg-slate-700 ">
                          <tr>
                            <th scope="col" className="py-3 px-6"></th>
                            <th scope="col" className="py-3 px-6"></th>
                            <th scope="col" className="py-3 px-6">
                              Days
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Amount
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Pay Action
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              {UnservedDetails.unservedDays <= 0
                                ? "Unserved Days"
                                : "Extra Days"}
                            </th>
                            <td className="py-4 px-6"></td>
                            <td className="py-4 px-6">
                              {UnservedDetails.unservedDays || 0}
                            </td>

                            <td className="py-4 px-6">{UnservedAmount}</td>

                            <td className="py-4 px-6">
                              <Select
                                bg
                                value={UnservedDetails.settelmentAction}
                                onChange={async ({ target }) => {
                                  console.log(
                                    "Unserved Pay Action",
                                    target.value
                                  );
                                  setUnservedDetails({
                                    ...UnservedDetails,
                                    settelmentAction: target.value,
                                  });
                                }}
                                borderColor="gray.400"
                                placeholder="Select Pay Action"
                              >
                                <option value={"Buy Out"}>Buy Out</option>
                                <option value={"Waive Off"}>Waive Off</option>
                              </Select>
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Amount after Adjustment
                            </th>
                            <td className="py-4 px-6"></td>
                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">
                              {UnservedDetails.settelmentAction === "Waive Off"
                                ? 0
                                : numeral(
                                    Number(
                                      TotalEncashedAmount + UnservedAmount
                                    ) || 0
                                  ).format("0.00")}
                            </td>

                            <td className="py-4 px-6"></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Step>

                <Step label={"Dues & Overrides"}>
                  <div className="pt-4">
                    <div className="flex pb-1 gap-8 mb-8">
                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Name</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.firstName} {FNFSelectedEmp.lastName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Code</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.empCode}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Department</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.departmentName}
                        </h6>
                      </div>
                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Designation</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.designationName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Date of Joining</p>
                        <h6 className="font-medium">
                          {new Date(
                            FNFSelectedEmp.dateofjoin
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">
                          Last Working Date
                        </p>
                        <h6 className="font-medium">
                          {new Date(
                            ExitDetailsSelectedEMP.lastWorkingDate
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>
                    </div>

                    <div className="flex gap-8 mb-6">
                      <div className="space-y-2">
                        <label>Asset Amount</label>
                        <Input
                          type="number"
                          value={DueAssetAmount}
                          onChange={({ target }) => {
                            setDueAssetAmount(target.value);
                          }}
                          placeholder="Asset Amount"
                          borderColor="gray.500"
                        />
                      </div>
                      <div className="space-y-2">
                        <label>Expense Amount</label>
                        <Input
                          type="number"
                          value={DueExpenseAmount}
                          onChange={({ target }) => {
                            setDueExpenseAmount(target.value);
                          }}
                          placeholder="Expense Amount"
                          borderColor="gray.500"
                        />
                      </div>
                    </div>

                    <div>
                      <h6 className="text-2xl font-semibold mb-4">
                        LOP Overrides
                      </h6>

                      <div className="mb-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                        <table className="w-full text-sm text-left text-gray-500 ">
                          <thead className="text-xs text-white uppercase bg-slate-700 ">
                            <tr>
                              <th scope="col" className="py-3 px-6">
                                Actual LOP
                              </th>
                              <th scope="col" className="py-3 px-6">
                                LOP Adjust
                              </th>
                              <th scope="col" className="py-3 px-6">
                                Final LOP
                              </th>
                              <th scope="col" className="py-3 px-6">
                                LOP Amount
                              </th>
                              <th scope="col" className="py-3 px-6">
                                Comment
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                              <th
                                scope="row"
                                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                              >
                                {DuesOverrideData.totalLOP} day(s)
                              </th>
                              <td className="py-4 px-6">
                                <Input
                                  type="number"
                                  value={LOPAdjust}
                                  onChange={({ target }) => {
                                    setLOPAdjust(target.value);
                                  }}
                                  bgColor="white"
                                  borderColor="gray.400"
                                />
                              </td>

                              <td className="py-4 px-6">{FinalLop}</td>

                              <td className="py-4 px-6">
                                <Input
                                  type="number"
                                  value={TotalLOPAmount}
                                  onChange={({ target }) => {
                                    setTotalLOPAmount(target.value);
                                  }}
                                  bgColor="white"
                                  borderColor="gray.400"
                                  placeholder="LOP Amount"
                                />
                              </td>

                              <td className="py-4 px-6">
                                <Input
                                  value={LOPOverrideComment || ""}
                                  onChange={(e) => {
                                    setLOPOverrideComment(e.target.value);
                                  }}
                                  bgColor="white"
                                  borderColor="gray.400"
                                  placeholder="Comment"
                                />
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </Step>

                <Step label={"FNF Calculation"}>
                  <div className="pt-4">
                    <div className="flex pb-1 gap-8 mb-8">
                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Name</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.firstName} {FNFSelectedEmp.lastName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Code</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.empCode}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Department</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.departmentName}
                        </h6>
                      </div>
                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Designation</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.designationName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Date of Joining</p>
                        <h6 className="font-medium">
                          {new Date(
                            FNFSelectedEmp.dateofjoin
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">
                          Last Working Date
                        </p>
                        <h6 className="font-medium">
                          {new Date(
                            ExitDetailsSelectedEMP.lastWorkingDate
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>
                    </div>

                    <div className="flex flex-wrap  gap-5">
                      <div className="bg-blue-100 rounded-xl drop-shadow-md  px-2 py-1  hover:-translate-y-2 ease-in-out duration-200">
                        <h6 className="font-semibold ">
                          Salary Hold Month :{" "}
                          <span className="font-semibold">
                            {monthNames[HoldSalaryData.salaryMonth - 1]}/
                            {HoldSalaryData.salaryYear}
                          </span>
                        </h6>
                      </div>
                      <div className="bg-blue-100 rounded-xl drop-shadow-md  px-2 py-1  hover:-translate-y-2 ease-in-out duration-200">
                        <h6 className="font-semibold ">
                          Working Days :{" "}
                          <span className="font-semibold">
                            {HoldSalaryData.totalPresent}
                          </span>
                        </h6>
                      </div>
                    </div>

                    <div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-xs text-white uppercase bg-slate-700 ">
                          <tr>
                            <th scope="col" className="py-3 px-6">
                              Earning Component
                            </th>
                            <th scope="col" className="py-3 px-6"></th>
                            <th scope="col" className="py-3 px-6">
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {HoldSalaryComponents.filter(
                            (data) => data.componentType === 1
                          ).map((data, i) => {
                            return (
                              <tr
                                key={i}
                                className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300"
                              >
                                <th
                                  scope="row"
                                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                                >
                                  {data.salarySlipLabel}
                                </th>
                                <td className="py-4 px-6"></td>

                                <td className="py-4 px-6">
                                  {data.salaryValue}
                                </td>
                              </tr>
                            );
                          })}

                          <tr className="text-xs text-white uppercase bg-slate-700 ">
                            <th scope="col" className="py-2 px-6">
                              Deduction Component
                            </th>
                            <th scope="col" className="py-2 px-6"></th>
                            <th scope="col" className="py-2 px-6">
                              Amount
                            </th>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Employee PF
                            </th>
                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">
                              {HoldSalaryData.pF_Employee}
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Employee ESI
                            </th>
                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">
                              {HoldSalaryData.esI_Employee}
                            </td>
                          </tr>

                          {HoldSalaryComponents.filter(
                            (data) => data.componentType === 2
                          ).map((data, i) => {
                            return (
                              <tr
                                key={i}
                                className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300"
                              >
                                <th
                                  scope="row"
                                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                                >
                                  {data.salarySlipLabel}
                                </th>
                                <td className="py-4 px-6"></td>

                                <td className="py-4 px-6">
                                  {data.salaryValue}
                                </td>
                              </tr>
                            );
                          })}

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th scope="row"></th>
                            <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap ">
                              Net Salary
                            </td>

                            <td className="py-4 px-6">
                              {HoldSalaryData.netPayableSalary}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-xs text-white uppercase bg-slate-700 ">
                          <tr>
                            <th scope="col" className="py-3 px-6">
                              Component
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Description
                            </th>
                            <th scope="col" className="py-3 px-6">
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Total Payable Amount
                            </th>
                            <td className="py-4 px-6">
                              <Input
                                value={DescTotalPayable}
                                onChange={({ target }) => {
                                  setDescTotalPayable(target.value);
                                }}
                                type="text"
                                bg="white"
                                borderColor="gray"
                                placeholder="Desciption"
                              />
                            </td>

                            <td className="py-4 px-6">
                              <div className="w-full lg:w-1/4">
                                <InputGroup>
                                  <Input
                                    isReadOnly
                                    value={TotalPayableAmount}
                                    onChange={({ target }) => {
                                      setTotalPayableAmount(target.value);
                                    }}
                                    type="number"
                                    bg="white"
                                    borderColor="gray"
                                    placeholder="Amount"
                                  />
                                  <InputRightAddon
                                    borderColor="gray.400"
                                    bg="white"
                                    children={userData.currencyCode}
                                  />
                                </InputGroup>
                              </div>
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Other Payable Amount
                            </th>
                            <td className="py-4 px-6">
                              <Input
                                value={DescOtherPayable}
                                onChange={({ target }) => {
                                  setDescOtherPayable(target.value);
                                }}
                                type="text"
                                bg="white"
                                borderColor="gray"
                                placeholder="Desciption"
                              />
                            </td>

                            <td className="py-4 px-6">
                              <div className="w-full lg:w-1/4">
                                <InputGroup>
                                  <Input
                                    value={OtherPayableAmount}
                                    onChange={({ target }) => {
                                      setOtherPayableAmount(target.value);
                                    }}
                                    type="number"
                                    bg="white"
                                    borderColor="gray"
                                    placeholder="Amount"
                                  />
                                  <InputRightAddon
                                    borderColor="gray.400"
                                    bg="white"
                                    children={userData.currencyCode}
                                  />
                                </InputGroup>
                              </div>
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Total Due Amount
                            </th>
                            <td className="py-4 px-6">
                              <Input
                                value={DescTotalDue}
                                onChange={({ target }) => {
                                  setDescTotalDue(target.value);
                                }}
                                type="text"
                                bg="white"
                                borderColor="gray"
                                placeholder="Desciption"
                              />
                            </td>

                            <td className="py-4 px-6">
                              <div className="w-full lg:w-1/4">
                                <InputGroup>
                                  <Input
                                    isReadOnly
                                    value={TotalDueAmount}
                                    onChange={(e) => {
                                      setTotalDueAmount(e.target.value);
                                    }}
                                    type="number"
                                    bg="white"
                                    borderColor="gray"
                                    placeholder="Amount"
                                  />
                                  <InputRightAddon
                                    borderColor="gray.400"
                                    bg="white"
                                    children={userData.currencyCode}
                                  />
                                </InputGroup>
                              </div>
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Other Due Amount
                            </th>
                            <td className="py-4 px-6">
                              <Input
                                value={DescOtherDeduction}
                                onChange={({ target }) => {
                                  setDescOtherDeduction(target.value);
                                }}
                                type="text"
                                bg="white"
                                borderColor="gray"
                                placeholder="Desciption"
                              />
                            </td>

                            <td className="py-4 px-6">
                              <div className="w-full lg:w-1/4">
                                <InputGroup>
                                  <Input
                                    value={OtherDeductionAmount}
                                    onChange={({ target }) => {
                                      setOtherDeductionAmount(target.value);
                                    }}
                                    type="number"
                                    bg="white"
                                    borderColor="gray"
                                    placeholder="Amount"
                                  />
                                  <InputRightAddon
                                    borderColor="gray.400"
                                    bg="white"
                                    children={userData.currencyCode}
                                  />
                                </InputGroup>
                              </div>
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Salary
                            </th>
                            <td className="py-4 px-6">
                              <Input
                                value={DescSalary || ""}
                                onChange={({ target }) =>
                                  setDescSalary(target.value)
                                }
                                type="text"
                                bg="white"
                                borderColor="gray"
                                placeholder="Desciption"
                              />
                            </td>

                            <td className="py-4 px-6">
                              <div className="w-full lg:w-1/4">
                                <InputGroup>
                                  <Input
                                    isReadOnly
                                    value={SalaryAmount}
                                    onChange={({ target }) => {
                                      setSalaryAmount(target.value);
                                    }}
                                    type="number"
                                    bg="white"
                                    borderColor="gray"
                                    placeholder="Amount"
                                  />
                                  <InputRightAddon
                                    borderColor="gray.400"
                                    bg="white"
                                    children={userData.currencyCode}
                                  />
                                </InputGroup>
                              </div>
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            ></th>
                            <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap ">
                              Final Amount
                            </td>

                            <td className="py-4 px-6">
                              <div className="w-full lg:w-1/4">
                                <InputGroup>
                                  <Input
                                    value={TotalFNFCalculationAmount}
                                    onChange={(e) => {
                                      setTotalFNFCalculationAmount(
                                        e.target.value
                                      );
                                    }}
                                    type="number"
                                    bg="white"
                                    borderColor="gray"
                                    placeholder="Amount"
                                  />
                                  <InputRightAddon
                                    borderColor="gray.400"
                                    bg="white"
                                    children={userData.currencyCode}
                                  />
                                </InputGroup>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </Step>

                <Step label={"Finalize"}>
                  <div className="pt-4">
                    <div className="flex pb-1 gap-8 mb-8">
                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Name</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.firstName} {FNFSelectedEmp.lastName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Employee Code</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.empCode}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Department</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.departmentName}
                        </h6>
                      </div>
                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Designation</p>
                        <h6 className="font-medium">
                          {FNFSelectedEmp.designationName}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">Date of Joining</p>
                        <h6 className="font-medium">
                          {new Date(
                            FNFSelectedEmp.dateofjoin
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>

                      <div className="space-y-1">
                        <p className="text-sm text-gray-500">
                          Last Working Date
                        </p>
                        <h6 className="font-medium">
                          {new Date(
                            ExitDetailsSelectedEMP.lastWorkingDate
                          ).toLocaleDateString("en-GB")}
                        </h6>
                      </div>
                    </div>

                    <div className="border-b flex justify-between border-slate-400 pb-2 mb-4">
                      <h1 className="text-lg font-bold text-gray-800">
                        FNF Summary
                      </h1>
                    </div>

                    <div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                      <table className="w-full text-sm text-left text-gray-500 ">
                        <thead className="text-xs text-white uppercase bg-slate-700 ">
                          <tr>
                            <th scope="col" className="py-2 px-6">
                              Checklist Details
                            </th>
                            <th scope="col" className="py-2 px-6">
                              Checklist
                            </th>
                            <th scope="col" className="py-2 px-6">
                              Status
                            </th>
                            <th scope="col" className="py-2 px-6"></th>
                          </tr>
                        </thead>
                        <tbody>
                          {CheckListRowData.map((data, i) => {
                            return (
                              <tr
                                key={i}
                                className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300"
                              >
                                <th
                                  scope="row"
                                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                                >
                                  NOC Checklist
                                </th>
                                <td className="py-4 px-6">
                                  {data.checkListName}
                                </td>
                                <td className="py-4 px-6">
                                  {data.isStatus === false ? (
                                    <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                                      Not Recovered
                                    </span>
                                  ) : data.isStatus === true ? (
                                    <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                                      Recovered
                                    </span>
                                  ) : (
                                    ""
                                  )}
                                </td>

                                {/* <td className="py-4 px-6">
                                {data.resigAttachpath === null ?
                                  <div className="w-1/2">
                                    <Input  ref={FileRef} onChange={({target})=>{setCheckListAttachment(target.files[0])}} type={'file'} borderColor={'gray.400'} bg={'white'}/>
                                  </div>:<Button variant={'outline'} leftIcon={<FontAwesomeIcon icon={faDownload} />} onClick={()=>{handleViewChecklistAttachment(data.resigAttachpath);}} colorScheme={'purple'} size={'sm'}>Download</Button> }
                                </td> */}
                                <td className="py-4 px-6"></td>
                              </tr>
                            );
                          })}

                          <tr className="text-xs text-white uppercase bg-slate-700 ">
                            <th scope="col" className="py-2 px-6">
                              Payables Details
                            </th>
                            <th scope="col" className="py-2 px-6">
                              Payables
                            </th>
                            <th scope="col" className="py-2 px-6"></th>
                            <th scope="col" className="py-2 px-6">
                              Amount
                            </th>

                            {/* <th scope="col" className="py-3 px-6">
                                  Pay Action
                                </th> */}
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Payables
                            </th>
                            <td className="py-4 px-6">Bonuses Due</td>
                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">{PayableBonus || 0}</td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            ></th>
                            <td className="py-4 px-6">Expense Claims</td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">
                              {PayableExpenseClaim || 0}
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            ></th>
                            <td className="py-4 px-6">Adhoc Settlements</td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">
                              {PayableAdvanceSettlements || 0}
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            ></th>
                            <td className="py-4 px-6">Overtime</td>
                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">
                              {PayableOvertime || 0}
                            </td>
                          </tr>

                          <tr className="text-xs text-white uppercase bg-slate-700 ">
                            <th scope="col" className="py-2 px-6">
                              Leave Type
                            </th>
                            <th scope="col" className="py-2 px-6">
                              Leave Balance
                            </th>
                            <th scope="col" className="py-2 px-6">
                              Encash Leave for day(s)
                            </th>
                            <th scope="col" className="py-2 px-6">
                              Amount
                            </th>
                            {/* <th scope="col" className="py-3 px-6">
                                  Pay Action
                                </th> */}
                          </tr>

                          {LeaveEncashmentRowData?.map((data, i) => {
                            return (
                              <tr
                                key={i}
                                className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300"
                              >
                                <th
                                  scope="row"
                                  className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                                >
                                  {data.leaveType}
                                </th>
                                <td className="py-4 px-6">
                                  {data.leveEncashmentLeaveBalance}
                                </td>

                                <td className="py-4 px-6">
                                  {data.leveEncashmentEncashLeaveDays}
                                </td>

                                <td className="py-4 px-6">
                                  {data.leveEncashmentLeaveAmount}
                                </td>

                                {/* <td className="py-4 px-6">
                                {data.payStatus} 
                                </td> */}
                              </tr>
                            );
                          })}

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            ></th>
                            <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap">
                              Total
                            </td>
                            <td className="py-4 px-6">{TotalEncashDays}</td>
                            <td className="py-4 px-6">{TotalEncashedAmount}</td>
                          </tr>

                          <tr className="text-xs text-white uppercase bg-slate-700 ">
                            <th scope="col" className="py-2 px-6">
                              Dues Details
                            </th>
                            <th scope="col" className="py-2 px-6">
                              Dues
                            </th>
                            <th scope="col" className="py-2 px-6"></th>
                            <th scope="col" className="py-2 px-6">
                              Amount
                            </th>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Dues
                            </th>
                            <td className="py-4 px-6">Asset Amount</td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">{AssetAmount || 0}</td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            ></th>
                            <td className="py-4 px-6">Expense Amount</td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">{ExpenseAmount || 0}</td>
                          </tr>

                          <tr className="text-xs text-white uppercase bg-slate-700 ">
                            <th scope="col" className="py-2 px-6">
                              Unserved Settlement Details
                            </th>
                            <th scope="col" className="py-2 px-6">
                              Days
                            </th>
                            <th scope="col" className="py-2 px-6"></th>
                            <th scope="col" className="py-2 px-6">
                              Amount
                            </th>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              {UnservedDetails.unservedDays <= 0
                                ? "Unserved Days"
                                : "Extra Days"}
                            </th>
                            <td className="py-4 px-6">
                              {UnservedDetails.unservedDays}
                            </td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">{UnservedAmount}</td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Amount after Adjustment
                            </th>
                            <td className="py-4 px-6"></td>
                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">
                              {UnservedDetails.settelmentAction === "Waive Off"
                                ? 0
                                : numeral(
                                    Number(
                                      TotalEncashedAmount + UnservedAmount
                                    ) || 0
                                  ).format("0.00")}
                            </td>
                          </tr>

                          <tr className="text-xs text-white uppercase bg-slate-700 ">
                            <th scope="col" className="py-2 px-6">
                              Final Calculation
                            </th>
                            <th scope="col" className="py-2 px-6">
                              Description
                            </th>
                            <th scope="col" className="py-2 px-6"></th>
                            <th scope="col" className="py-2 px-6">
                              Amount
                            </th>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Total Payable Amount
                            </th>
                            <td className="py-4 px-6">{DescTotalPayable}</td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">{TotalPayableAmount}</td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Other Payable Amount
                            </th>
                            <td className="py-4 px-6">{DescOtherPayable}</td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">{OtherPayableAmount}</td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Total Due Amount
                            </th>
                            <td className="py-4 px-6">{DescTotalDue}</td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">{TotalDueAmount}</td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Other Due Amount
                            </th>
                            <td className="py-4 px-6">{DescOtherDeduction}</td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">
                              {OtherDeductionAmount}
                            </td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            >
                              Salary Amount
                            </th>
                            <td className="py-4 px-6">{DescSalary}</td>

                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6">{SalaryAmount}</td>
                          </tr>

                          <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
                            <th
                              scope="row"
                              className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
                            ></th>
                            <td className="py-4 px-6"></td>

                            <td className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap ">
                              Final Amount
                            </td>

                            <td className="py-4 px-6">
                              {numeral(TotalFNFCalculationAmount).format(
                                "0.00"
                              )}
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>

                    <div className="text-right">
                      <Button
                        onClick={() => {
                          HandleViewFNFDocument();
                        }}
                        colorScheme={"purple"}
                      >
                        View FNF Document
                      </Button>
                    </div>
                  </div>
                </Step>
              </Steps>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            {ExitProcessActiveStep === ExitProcessSteps.length ? (
              <Flex px={4} width="100%" className="justify-end">
                <Button mr={4} onClick={ExitProcessReset}>
                  Reset
                </Button>
              </Flex>
            ) : (
              <Flex width="100%" justify="flex-end">
                <Button
                  isDisabled={ExitProcessActiveStep === 0}
                  mr={4}
                  onClick={ExitProcessPrev}
                  colorScheme="purple"
                  variant="ghost"
                >
                  Prev
                </Button>
                {ExitProcessActiveStep === ExitProcessSteps.length - 1 ? (
                  <Button
                    onClick={() => {
                      HandleFinalizeSettlement();
                    }}
                    colorScheme="purple"
                  >
                    Finish
                  </Button>
                ) : (
                  <div className="space-x-4">
                    <Button
                      colorScheme="purple"
                      variant={"outline"}
                      onClick={() => {
                        HandleNextStep();
                      }}
                    >
                      Next
                    </Button>
                    {!IsFinalized && (
                      <>
                        <Button
                          isDisabled={
                            EmpAssetsRowData.every(
                              (data) => data.isRecovery === true
                            )
                              ? false
                              : true
                          }
                          colorScheme="purple"
                          onClick={() => {
                            HandleSaveNContinue();
                          }}
                        >
                          Save & Continue
                        </Button>
                      </>
                    )}
                  </div>
                )}
              </Flex>
            )}
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal
        size="full"
        isOpen={isViewFNFDocumentOpen}
        onClose={onViewFNFDocumentClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>FNF Document</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <PDFViewer width="100%" className="h-screen my-10">
              <FNFDocument />
            </PDFViewer>
          </ModalBody>
          <hr />

          <ModalFooter>
            <Button colorScheme="purple" onClick={onViewFNFDocumentClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default FNFSettlements;
