import React from "react";
import PayslipReports from "./PayslipReports";
import { Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import PayRegisterReports from "./PayRegisterReports";

const PayrollReports = () => {
  return (
    <div>
      <Tabs variant={'line'} size={'sm'} colorScheme="purple">
        <TabList>
          <Tab>Payslip Report</Tab>
          <Tab>Pay Register Report</Tab>
        </TabList>
        <TabPanels>
          <TabPanel  px={0}>
            <PayslipReports />
          </TabPanel>
          <TabPanel  px={0}>
            <PayRegisterReports />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
};

export default PayrollReports;
