import { Input,Select,InputGroup,ModalBody,ModalCloseButton,useToast,ModalContent,ModalFooter,ModalHeader,Modal,ModalOverlay,Button, InputRightAddon, useDisclosure, Tooltip, Checkbox } from '@chakra-ui/react'
import axios from 'axios';
import React,{useState , useEffect} from 'react'
import { Plus } from 'react-feather';
import ReactSelect from 'react-select';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDownload, faFileImport, faUpRightFromSquare } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from "react-csv";
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';

function CreateEmployee() {

  const userData = JSON.parse(localStorage.getItem('user'));
  const toast = useToast();
  const id = "toast";

  const navigate = useNavigate();


  const [IsManualEmpCode, setIsManualEmpCode] = useState(false);

  const [ManualEmpCode, setManualEmpCode] = useState();

  const [ActivePlanDetails,setActivePlanDetails] = useState([]);

  const [SelfReporting, setSelfReporting] = useState(false);

  const [MinPayrollDate, setMinPayrollDate] = useState();


  const [EmployeeList, setEmployeeList] = useState([]);
  const [EmployeesImportData, setEmployeesImportData] = useState([]);

  const [EmpTitle, setEmpTitle] = useState();
  // Form Variables
  const [FirstName, setFirstName] = useState();
  const [MiddleName, setMiddleName] = useState();
  const [LastName, setLastName] = useState();
  const [DateofJoining, setDateofJoining] = useState(new Date());
  const [Designation, setDesignation] = useState();
  const [Department, setDepartment] = useState();
  const [ReportingOfficer, setReportingOfficer] = useState();
  const [OfficeMobileNo, setOfficeMobileNo] = useState();
  const [RegulationDate, setRegulationDate] = useState(new Date());
  const [OfficeEmail, setOfficeEmail] = useState();
  const [EmployeeCategory, setEmployeeCategory] = useState();
  const [ProbationStartDate, setProbationStartDate] = useState(new Date());
  const [ProbationEndDate, setProbationEndDate] = useState();
  const [PersonalEmail, setPersonalEmail] = useState();
  const [RoleName, setRoleName] = useState();

  const [WorkingLocation, setWorkingLocation] = useState();
  const [RefId, setRefId] = useState();


  const [RoleList, setRoleList] = useState([]);
  // Add Designation Variables
  const [AddDesignationName, setAddDesignationName] = useState();
  const [DesignationList, setDesignationList] = useState([]);
  const { isOpen: isAddDesignationOpen, onOpen: onAddDesignationOpen, onClose: onAddDesignationClose } = useDisclosure();
  const { isOpen: isImportEmployeesOpen, onOpen: onImportEmployeesOpen, onClose: onImportEmployeesClose } = useDisclosure();

  // Add Department Varaibles
  const [AddDepartmentName, setAddDepartmentName] = useState();
  const [DepartmentList, setDepartmentList] = useState([]);
  const { isOpen: isAddDepartmentOpen, onOpen: onAddDepartmentOpen, onClose: onAddDepartmentClose } = useDisclosure();

  const [PhoneError, setPhoneError] = useState(null);

  // Add Employee Category
const [AddEmpCategoryName, setAddEmpCategoryName] = useState();
const [ProbationMonth, setProbationMonth] = useState();
const [IsProbationApply, setIsProbationApply] = useState(false);

const [EmployeeCategoryList, setEmployeeCategoryList] = useState([]);
const { isOpen: isAddEmpCategoryOpen, onOpen: onAddEmpCategoryOpen, onClose: onAddEmpCategoryClose } = useDisclosure();


  const [EmpExcelHeads, setEmpExcelHeads] = useState([
    // { label: "title", key: `title`},
    { label: "firstName", key: `firstName`},
    { label: "middleName", key: `middleName`},
    { label: "lastName", key: `lastName`},
    { label: "gender", key: `gender`},
    { label: "empCode", key: `empCode`},
    { label: "dateofjoin", key: `dateofjoin`},
    { label: "designation", key: `designation`},
    { label: "department", key: `department`},
    { label: "division", key: `division`},
    { label: "grade", key: `grade`},
    // { label: "reportingOfficerName", key: `reportingOfficerName`},
    { label: "mobile", key: `mobile`},
    { label: "officeEmail", key: `officeEmail`},
    { label: "refId", key: `refId`},
    { label: "workingLocation", key: `workingLocation`},
    // { label: "regulationDate", key: `regulationDate`},
    // { label: "officeLocation", key: `officeLocation`},
    // { label: "probationType", key: `probationType`},
    // { label: "empTypeName", key: `empTypeName`}
  ])


// Profile Photo Preview Code
//   useEffect(() => {
//     if (!selectedFile) {
//         setPreview(undefined)
//         return
//     }

//     const objectUrl = URL.createObjectURL(selectedFile)
//     setPreview(objectUrl)

//     return () => URL.revokeObjectURL(objectUrl)
// }, [selectedFile])

// const onSelectFile = e => {
//     if (!e.target.files || e.target.files.length === 0) {
//         setSelectedFile(undefined)
//         return
//     }
//     setSelectedFile(e.target.files[0])
// }

useEffect(() => {
  UpdatePlanDetails();
  GetUserPlanDetails();
  GetPayrollFinalizedList();
  getCodeMasterList();
  getEmpList();
  getDesignationList();
  getDepartmentList();
  getEmployeeCategoryList();
  GetRoleList();
}, [])

const UpdatePlanDetails = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Plan/UpdateSubscritpionData?CompanyId=${userData.companyId}`
    )
    console.log("Plan Update Res",response);
  }catch (err){
    console.error(err);
  }
}

const GetUserPlanDetails = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/SuperAdmin/GetUserSubscriptionByEmail?EmailId=${userData.userName}`
    )
    console.log("Active Plan Details",response);
    setActivePlanDetails(response.data);
  } catch (err) {
    console.error(err);
  }
}

const GetPayrollFinalizedList = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/PayrollFinalize/GetPayrollStatusList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    );

    console.log("Payroll Finalized List",response);

    const res = response.data;

    const sortedData = res.sort((a, b) => {
      if (a.payrollYear !== b.payrollYear) {
        return b.payrollYear - a.payrollYear;
      } else {
        return b.payrollMonth - a.payrollMonth;
      }
    });

    const lastMonthYear = sortedData[0];
    console.log("Last Month Payroll",lastMonthYear);
    console.log("Last Payroll Date",new Date(lastMonthYear.payrollYear,lastMonthYear.payrollMonth,1) || new Date());

   setMinPayrollDate(new Date(lastMonthYear.payrollYear,lastMonthYear.payrollMonth,1));
    
  } catch (error) {
    console.error(error);
  }
}

console.log("Is Manual Code",IsManualEmpCode);

const getCodeMasterList = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCodeMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    );
    console.log("Code Master List", response);

    const res = response.data;

    const isCodeMasterCreated = res.length ? false : true;


    if(isCodeMasterCreated){

      navigate('/code');

      toast({
        id,
        title:"Error Code Not Created",
        description : "Please Create Code Master Before Creating Employee!",
        position: 'top',
        status: 'error',
        duration: 4000,
        isClosable: true,
      })
    }

    console.log("Code Master Created",isCodeMasterCreated);
    setIsManualEmpCode(res[0].isManual)
  } catch (error) {
    console.error(error);
  }
};


const GetRoleList = async() => {
  try {
      const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetRoleMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Role List ",response)

        setRoleList(response.data);

  } catch (error) {
   console.error(error);   
  }
}


const getEmpList = async () => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log(response);

    const res = response.data;

    setEmployeeList(res.filter((data)=> data.isActive === true));
  } catch (error) {
    console.error(error);
  }
}


const getDesignationList = async()=>{
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Master/GetDesignationMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log("Designation List",response.data);
    setDesignationList(response.data);
  } catch (error) {
    console.error(error)
  }
}

const getDepartmentList = async()=>{
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Master/DepartmentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log("Department List",response.data);
    setDepartmentList(response.data);
  } catch (error) {
    console.error(error)
  }
}

const getEmployeeCategoryList = async()=>{
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Master/GetEmployeeTypeMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log("Employee Category List",response);
    setEmployeeCategoryList(response.data);
  } catch (error) {
    console.error(error)
  }
}


const readExcel = (file)=>{

  const promise = new Promise((resolve,reject) => {
    
   const fileReader = new FileReader();
   fileReader.readAsArrayBuffer(file);

   fileReader.onload=(e)=>{
     const bufferArray = e.target.result;

     const wb = XLSX.read(bufferArray,{type:'buffer'});
     const wsname = wb.SheetNames[0];

     const ws = wb.Sheets[wsname];

     const data = XLSX.utils.sheet_to_json(ws, {raw: false});

     resolve(data)
   } 

   fileReader.onerror = (error) => {
     reject(error)
   }

  });

  promise.then((data)=>{
    console.log("Imported Data",data);

    let FinalData = data.map((obj)=>{
      return {...obj,
        companyId:userData.companyId,
        branchId:userData.isActiveBranch
      }
     })

   console.log("Final Data",FinalData);
   handleImportEmployeesData(FinalData);
  });

}


  const ExcelDateToJSDate = (date) => {
    let converted_date = new Date(Math.round((date - 25569) * 864e5));
    converted_date = String(converted_date).slice(4, 15)
    date = converted_date.split(" ")
    let day = date[1];
    let month = date[0];
    month = "JanFebMarAprMayJunJulAugSepOctNovDec".indexOf(month) / 3 + 1
    if (month.toString().length <= 1)
        month = '0' + month
    let year = date[2];
    return String(year.slice(0, 4) + '-' + day + '-' + month )
}

// function ExcelDateToJSDate(date) {
//   return new Date(Math.round((date - 25569)*86400*1000));
// }

// const HandleImportValidation = (ExcelData) => {

//   console.log("Excel Data", ExcelData);

//   const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
//   const validGenders = ["male", "female", "other"];

//   if(!ExcelData.every(data => Number(data?.firstName?.length || 0) > 1)){
//     return "Please enter the correct First Name and Import the excel again!"
//   }else if (!ExcelData.every(data => Number(data?.lastName?.length || 0) > 1)){
//     return "Please enter the correct Last Name and Import the excel again!"
//   }else if (!ExcelData.every(data => Number(data?.empCode?.length || 0) > 1)){
//     return "Please enter the correct Employee Code and Import the excel again!"
//   }else if (!ExcelData.every(data => moment(data?.dateofjoin).format('YYYY-MM-DD') !== "Invalid date")){
//     return "Please enter the correct Date of Joining Format other than 'DD-MM-YYYY' and Import the excel again!"
//   }else if (!ExcelData.every(data => Number(data?.designation?.length || 0) > 1)){
//     return "Please enter the correct Designation and Import the excel again!"
//   }else if (!ExcelData.every(data => Number(data?.department?.length || 0) > 1)){
//     return "Please enter the correct Department and Import the excel again!"
//   }else if (!ExcelData.every(data => Number(data?.mobile?.length || 0) > 1)){
//     return "Please enter the correct Mobile and Import the excel again!"
//   }else if (!ExcelData.every(data => isValidEmail(data?.officeEmail))){
//     return "Please enter the correct Email and Import the excel again!"
//   }else if (!ExcelData.every(data => validGenders.includes(data?.gender?.toLowerCase()))){
//     return "Please enter the correct Gender and Import the excel again!"
//   }else{
//     return "All Correct"
//   }

// }

const HandleImportValidation = (ExcelData) => {
  const isValidEmail = (email) => /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  const validGenders = ["male", "female", "other"];

  const validationChecks = [
    { prop: 'firstName', message: 'First Name' },
    { prop: 'lastName', message: 'Last Name' },
    { prop: 'empCode', message: 'Employee Code' },
    { prop: 'dateofjoin', message: 'Date of Joining Format other than "YYYY-MM-DD"', validator: date => moment(date, 'YYYY-MM-DD').isValid() },
    { prop: 'designation', message: 'Designation' },
    { prop: 'department', message: 'Department' },
    { prop: 'mobile', message: 'Mobile' },
    { prop: 'officeEmail', message: 'Email', validator: isValidEmail },
    { prop: 'gender', message: 'Gender', validator: value => validGenders.includes(value?.toLowerCase()) },
    { prop: 'refId', message: 'RefId' },
    { prop: 'workingLocation', message: 'WorkingLocation' }, 
  
  ];

  for (let i = 0; i < ExcelData.length; i++) {
    const data = ExcelData[i];
    for (const check of validationChecks) {
      const value = data?.[check.prop] || [check.prop] ;
      if (check.prop === 'dateofjoin') {
        if (!check.validator(value)) {
          return `Please enter the correct ${check.message} at Row ${i + 2} and import the excel again!`;
        }
      } else if (typeof value !== 'string' || value.trim().length < 1 || value === "undefined" || (check.validator && !check.validator(value))) {
        return `Please enter the correct ${check.message + value} at Row ${i + 2} and import the excel again!`;
      }
    }
  }

  return "All Correct";
};






// const DataExample = [
//     {
//       "firstName": "Arpit",
//       "lastName": "mf",
//       "gender": "female",
//       "empCode": "HRMS14",
//       "dateofjoin": "21-jul-2022",
//       "designation": "Frontend Developer",
//       "department": "IT",
//       "mobile": "9911019990",
//       "officeEmail": "arpitsharma@hrmuster.com",
//       "companyId": 1,
//       "branchId": 19,
//       "middleName": "",
//       "division": "undefined",
//       "grade": "undefined"
//     },
//     {
//       "firstName": "Rohit",
//       "lastName": "Sharma",
//       "gender": "Male",
//       "empCode": "HRMS14",
//       "dateofjoin": "2022-07-21",
//       "designation": "Frontend Developer",
//       "department": "IT",
//       "mobile": "9911019990",
//       "officeEmail": "arpitsharma@hrmuster.com",
//       "companyId": 1,
//       "branchId": 19,
//       "middleName": "",
//       "division": "undefined",
//       "grade": "undefined"
//     }
//   ];
// console.log("Validation Check", HandleImportValidation(DataExample));

const handleImportEmployeesData = async (FinalData) =>{
  
console.log(FinalData);


let body = FinalData.map(obj =>{
             return {...obj,
                      empCode : String(obj.empCode),
                      firstName : String(obj.firstName),
                      middleName : String(obj.middleName || ''),
                      lastName : String(obj.lastName),
                      gender : String(obj.gender),
                      dateofjoin : moment(obj.dateofjoin).format('YYYY-MM-DD'),
                      designation : String(obj.designation),
                      department : String(obj.department),
                      division: String(obj.division),
                      grade: String(obj.grade),
                      // reportingOfficerName : String(obj.reportingOfficerName),
                      mobile : String(obj.mobile),
                      officeEmail : String(obj.officeEmail),
                      refId : String(obj.refId),
                      workingLocation : String(obj.workingLocation),
                      // regulationDate : moment(obj.regulationDate).format('YYYY-MM-DD'),
                      // officeLocation : String(obj.officeLocation),
                      // probationType : String(obj.probationType),
                      // empTypeName : String(obj.empTypeName)
                    }
             })


console.log("Import Body",body)
let DataStatus = HandleImportValidation(body);

if(DataStatus === "All Correct"){
  setEmployeesImportData(FinalData);

  await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ImportEmployeeGeneralInfo`,body
  )
  .then((response) => {
    const res = response.data;
    console.log("res",res);

    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: 'top',
        status: 'info',
        duration: 2000,
        isClosable: true
      })
    }

    GetUserPlanDetails();

  })
  .catch((error) => {
    console.error(error);
    console.log(error.response);
    // console.log(error.response.data);

    if (!toast.isActive(id)) {
      toast({
        id,
        title: 'ERROR',
        description: "Employees Data not Imported.",
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
    
  });
}else{
  alert(DataStatus);
  setEmployeesImportData([]);
  onImportEmployeesClose();
}


}

const SaveCreateEmployee = async(e)=>{
  e.preventDefault();

  let body={
    title:EmpTitle,
    firstName: FirstName,
    middleName:MiddleName || '',
    lastName: LastName,
    empCode: ManualEmpCode || '',
    dateofjoin:  moment(DateofJoining).format('YYYY-MM-DD'),
    designation: String(Designation.designationId),
    department: String(Department.departmentId),
    // reportingOfficersId: ReportingOfficer.id,
    reportingOfficersId: SelfReporting === true ? 0 : ReportingOfficer.id,
    // reportingOfficerName: `${ReportingOfficer.firstName} ${ReportingOfficer.lastName}`,
    mobile: OfficeMobileNo,
    officeEmail: OfficeEmail,
    personalEmail:PersonalEmail,
    workingLocation:WorkingLocation,
    refId:RefId,
    regulationDate:  moment(RegulationDate).format('YYYY-MM-DD'),
    probationStartDate: moment(ProbationStartDate).format('YYYY-MM-DD'),
    probationEndDate: moment(ProbationEndDate).format('YYYY-MM-DD'),
    empType: String(EmployeeCategory.empTypeId || 0),
    roleId:Number(RoleName),
    // empTypeName: EmployeeCategory.empTypeName,
    status: true,
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch,
  }

  console.log("Create Emp Save Body",body);

  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeGeneralInfo`,body
  )
  .then((response) => {
    console.log(response);
    const res = response.data;
    if (!toast.isActive(id)) {
      toast({
        id,
        title:res,
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }

   UpdatePlanDetails();
   GetUserPlanDetails();
    
   setEmpTitle('');
   setFirstName('');
   setMiddleName('');
   setLastName('');
   setDateofJoining(new Date());
   setDesignation('');
   setDepartment('');
   setReportingOfficer('');
   setOfficeMobileNo('');
   setRegulationDate('');
   setRoleName('');
   setOfficeEmail('');
   setEmployeeCategory('');
   setProbationStartDate('');
   setProbationEndDate('');
   setPersonalEmail('');

   setRefId('');
   setWorkingLocation('');

  })
  .catch((error) => {
    console.error(error);

    if (!toast.isActive(id)) {
      toast({
        id,
        title: "ERROR",
        description: `Employee not Created`,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  });
  
}


const SaveDesignationMaster = async (e) =>{
  e.preventDefault();
  let body = {
    designationName: AddDesignationName,
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  }

  console.log(body);
  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveDesignationMaster`,body
  )
  .then((response) => {
    console.log(response);
    const res = response.data;
    if (!toast.isActive(id)) {
      toast({
        id,
        title:res,
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }

    getDesignationList();
    onAddDesignationClose();
    setAddDesignationName('');

  })
  .catch((error) => {
    console.error(error);

    if (!toast.isActive(id)) {
      toast({
        id,
        title: "ERROR",
        description: `Designation not Added`,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  });

}


const SaveDepartmentMaster = async (e) =>{
  e.preventDefault();
  let body = {
    departmentName: AddDepartmentName,
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  }
  console.log(body);
  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveDepartmentMaster`,body
  )
  .then((response) => {
    console.log(response);
    const res = response.data;
    if (!toast.isActive(id)) {
      toast({
        id,
        title:res,
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }

     getDepartmentList();
     onAddDepartmentClose();
     setAddDepartmentName('');

  })
  .catch((error) => {
    console.error(error);

    if (!toast.isActive(id)) {
      toast({
        id,
        title: "ERROR",
        description: `Department not Added`,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  });


}


const SaveEmpTypeName = async (e) => {
  e.preventDefault();
  let body = {
    empTypeName: AddEmpCategoryName,
    isProbationApply: IsProbationApply,
    probationMonth: String(ProbationMonth || 0),
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch,
  };
  console.log(body);
  await axios
    .post(
      `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveEmployeeTypeMaster`,
      body
    )
    .then((response) => {
      console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title: res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }

      getEmployeeCategoryList();
      onAddEmpCategoryClose();
      setAddEmpCategoryName("");
      setProbationMonth("");
      setIsProbationApply(false);
    }).catch((err) => {
      console.error(err);

      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Employee Category not Added`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });
};


  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Create Employee</h1>
        {Number(ActivePlanDetails.currentUsers) <= Number(ActivePlanDetails.uptoUsers) ? 
                <Button onClick={onImportEmployeesOpen} size={'sm'} colorScheme={'purple'}><FontAwesomeIcon icon={faFileImport} className='mr-2' />Import Employees</Button>:  ''
        }
      </div>
   {Number(ActivePlanDetails.currentUsers) <= Number(ActivePlanDetails.uptoUsers) ?
      <form onSubmit={SaveCreateEmployee}>

      <div className='bg-white rounded-2xl drop-shadow-md p-4'>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

          <div className='flex  gap-6'>
            <div className='space-y-2'>
              <label>Title<span className='text-red-500 font-semibold'>*</span></label>
              <Select value={EmpTitle} onChange={({target})=>{setEmpTitle(target.value)}} isRequired borderColor='gray.400' placeholder='Select Title'>
                <option value={'Mr.'}>Mr.</option>
                <option value={'Ms.'}>Ms.</option>
              </Select>
            </div>

           <div className='space-y-2'>
              <label>First Name<span className='text-red-500 font-semibold'>*</span></label>
              <Input isRequired value={FirstName || ''} 
              onChange={({target})=>{
                let InputTarget = target.value;
                setFirstName(InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1));
              }} 
              borderColor='gray.400' placeholder='First Name' />
            </div>

            <div className='space-y-2'>
              <label>Middle Name</label>
              <Input value={MiddleName || ''} 
              onChange={({target})=>{
                let InputTarget = target.value;
                setMiddleName(InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1));
              }} 
              borderColor='gray.400' placeholder='Middle Name' />
            </div>

          </div>


          <div className={`${IsManualEmpCode ? 'grid grid-cols-2 gap-6':''}`}>

          <div className='space-y-2'>
              <label>Last Name<span className='text-red-500 font-semibold'>*</span></label>
              <Input isRequired value={LastName || ''} 
              onChange={({target})=>{
                let InputTarget = target.value;
                setLastName(InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1));
              }} 
              borderColor='gray.400' placeholder='Last Name' />
            </div>


            {IsManualEmpCode && 
            <div className='space-y-2'>
              <label>Employee Code<span className='text-red-500 font-semibold'>*</span></label>
              <Input value={ManualEmpCode || ''} 
              onChange={({target})=>{
                let InputTarget = target.value;
                setManualEmpCode(InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1));
              }} 
              borderColor='gray.400' placeholder='Employee Code' />
            </div>
            }



          </div>


        </div>

        <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

          <div className='space-y-2'>
  <label>Date of Joining<span className='text-red-500 font-semibold'>*</span></label>
  <DatePicker
    required
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={DateofJoining}
    onChange={(date) =>{
      setDateofJoining(date); 
      setRegulationDate(date); 
      setProbationStartDate(date);
      setProbationEndDate(date);

    }}
    minDate={MinPayrollDate !== undefined ? new Date(moment(MinPayrollDate)): new Date(moment().startOf('month'))}
    placeholderText="Date of Joining"
    dateFormat={"dd-MM-yyyy"}
  />
</div>




<div className='grid grid-cols-2 gap-6'>

<div className='space-y-2'>
  <label>Designation<span className='text-red-500 font-semibold'>*</span></label>
  <InputGroup size='md'>
      <ReactSelect
        required
        options={DesignationList}
        value={Designation}
        className="w-full my-auto "
        onChange={setDesignation}
        getOptionLabel={(option) => option.designationName}
        getOptionValue={(option) => option.designationName}
        key={(option)=> option.designationId}
        placeholder="Select Designation"
      />
      <Tooltip placement='top' rounded='5px' hasArrow label='Add Designation' bg='gray.700' color='white'>
       <InputRightAddon onClick={onAddDesignationOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
      </Tooltip>
    </InputGroup>
</div>


<div className='space-y-2'>
              <label>Assign Role<span className='text-red-500 font-semibold'>*</span></label>
              <Select isRequired value={RoleName} onChange={({target})=>{setRoleName(target.value)}} borderColor={'gray.400'} placeholder='Select Role'>
                {RoleList.filter((data)=> data.isActive ===  true).map((data,i)=>{
                    return <option key={i} value={data.id}>{data.roleName}</option>
                })}
             </Select>
            </div>

</div>


</div>


<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Department<span className='text-red-500 font-semibold'>*</span></label>
  <InputGroup size='md'>
      <ReactSelect
        required
        options={DepartmentList}
        value={Department}
        className="w-full my-auto"
        onChange={setDepartment}
        getOptionLabel={(option) => option.departmentName}
        getOptionValue={(option) => option.departmentName}
        key={(option)=> option.departmentId}
        placeholder="Select Department"
      />
      <Tooltip placement='top' rounded='5px' hasArrow label='Add Department' bg='gray.700' color='white'>
       <InputRightAddon onClick={onAddDepartmentOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
      </Tooltip>
    </InputGroup>
</div>

<div className='space-y-2'>

  <div className='flex justify-between'>
   <label>Reporting Officer<span className='text-red-500 font-semibold'>*</span></label>
   <Checkbox borderColor={'gray.400'} isChecked={SelfReporting} onChange={({target})=>{setSelfReporting(target.checked)}}>Self Reporting</Checkbox>
  </div>
      <ReactSelect
        required
        isDisabled={SelfReporting === true ? true: false}
        options={EmployeeList}
        value={ReportingOfficer}
        className="w-full my-auto"
        onChange={(value) => {
          setReportingOfficer(value);
          console.log(value.id);
        }}
        getOptionLabel={(option) => `${option.firstName} ${option.lastName}`}
        getOptionValue={(option) => option.id}
        key={(option)=> option.id}
        placeholder="Select Reporting Officer"
        />
</div>



</div>


<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Office Mobile No.<span className='text-red-500 font-semibold'>*</span></label>
  <Input isRequired value={OfficeMobileNo || ''} onChange={({target})=>{setOfficeMobileNo(target.value)}} type='number' borderColor='gray.400' placeholder='Office Mobile No.' onKeyDown={(event)=>{
      if (event.target.value.length < 9) {
        setPhoneError('Phone number should not be less than 10 digits!');
      } else {
        setPhoneError(null);
      }
  }} />
  {PhoneError && <span className='text-red-500 text-sm'>{PhoneError}</span>}
</div>

<div className='space-y-2'>
  <label>Employee Category<span className='text-red-500 font-semibold'>*</span></label>
  <InputGroup size='md'>
      <ReactSelect
        required
        options={EmployeeCategoryList}
        value={EmployeeCategory}
        className="w-full my-auto"
        onChange={(value) => {
          setEmployeeCategory(value);
          console.log(value);
          let ProbationMonth = value?.probationMonth
          //  console.log("Probation Month",ProbationMonth);
           let dt = RegulationDate;
             
           let EndDate = moment(dt).add(ProbationMonth,'M');

          //  console.log('dt',dt);
          //  console.log("Probation Date",EndDate);
          //  console.log("Probation Date",moment(dt).add(ProbationMonth,'M'));

          setProbationEndDate(new Date(EndDate))
        }}
        getOptionLabel={(option) => option.empTypeName}
        getOptionValue={(option) => option.empTypeId}
        key={(option)=> option.empTypeId}
        placeholder="Select Employee Category"
      />

      <Tooltip placement='top' rounded='5px' hasArrow label='Add Employee Category' bg='gray.700' color='white'>
      <InputRightAddon onClick={onAddEmpCategoryOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
      </Tooltip>
    </InputGroup>
</div>


</div>


<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Regulation Date<span className='text-red-500 font-semibold'>*</span></label>
  <DatePicker
    disabled
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={RegulationDate}
    onChange={(date) => setRegulationDate(date)}
    placeholderText="Regulation Date"
    dateFormat={'dd-MM-yyyy'}
  />
</div>


<div className='grid grid-cols-2 gap-6'>
<div className='space-y-2'>
  <label>Probation Start Date<span className='text-red-500 font-semibold'>*</span></label>
  <DatePicker
    disabled
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={ProbationStartDate}
    onChange={(date) => setProbationStartDate(date)}
    placeholderText="Probation Start Date"
    dateFormat={'dd-MM-yyyy'}
  />
</div>

<div className='space-y-2'>
  <label>Probation End Date<span className='text-red-500 font-semibold'>*</span></label>
  <DatePicker
    disabled
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={ProbationEndDate}
    onChange={(date) => setProbationEndDate(date)}
    placeholderText="Probation End Date"
    dateFormat={'dd-MM-yyyy'}
  />
</div>

</div>


</div>

<div className='grid grid-cols-1 md:grid-cols-3 gap-4 mb-4 md:gap-8'>


<div className='space-y-2'>
  <label>Office Email <span className='text-red-500 font-semibold'>*</span></label>
  <Input isRequired value={OfficeEmail || ''} onChange={({target})=>{setOfficeEmail(target.value)}} type='email' borderColor='gray.400' placeholder='Office Email' />
</div>
<div className='space-y-2'>
  <label>Working Loction <span className='text-red-500 font-semibold'>*</span></label>
  <Input isRequired value={WorkingLocation || ''} onChange={({target})=>{setWorkingLocation(target.value)}} type='text' borderColor='gray.400' placeholder='Working Location' />
</div>


<div className='space-y-2'>
  <label>RefID <span className='text-red-500 font-semibold'>*</span></label>
  <Input isRequired value={RefId || ''} onChange={({target})=>{setRefId(target.value)}} type='text' borderColor='gray.400' placeholder='RefID' />
</div>


</div>


<div className='flex gap-6 justify-end mt-6'>
  {Number(ActivePlanDetails.currentUsers || 0) <= Number(ActivePlanDetails.uptoUsers || 0) ? 
  <Button type='submit' variant='solid' colorScheme='purple'>Submit</Button>
  : <h6 onClick={()=>{navigate('/plantransactions')}} className="text-white cursor-pointer bg-red-500  text-sm py-1 px-1.5 rounded-lg font-medium">Employee Limit Exceeded. Please Upgrade your Plan!! <FontAwesomeIcon icon={faUpRightFromSquare} className=' ml-1' /></h6>
   }
</div>


      </div>

      </form> : 

      <div className='p-4 flex justify-center items-center'>
        <div className='text-center space-y-6'>
          <img src="./images/oops.png" className='mx-auto' alt="oops"/>
          <h2 className='text-2xl font-semibold text-gray-800'>Employee Limit Exceeded. Please Upgrade your Plan!!</h2>
          <Button onClick={()=>{navigate('/plantransactions')}} variant={'solid'} colorScheme='purple'>Upgrade Plan</Button>
        </div>
      </div> }


      <Modal isOpen={isAddDesignationOpen} onClose={onAddDesignationClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader >Add Designation</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveDesignationMaster}>
            <ModalBody>
            <div className="space-y-2">
                  <label>Designation Name</label>
                  <Input
                    value={AddDesignationName}
                    onChange={({target})=>{setAddDesignationName(target.value)}}
                    isRequired
                    borderColor="gray"
                    placeholder="Enter Designation Name"
                  />
                </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button type="submit" colorScheme="purple" variant="solid">
                  Save
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={onAddDesignationClose}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAddDepartmentOpen} onClose={onAddDepartmentClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader >Add Department</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveDepartmentMaster}>
            <ModalBody>
            <div className="space-y-2">
                  <label>Department Name</label>
                  <Input
                    value={AddDepartmentName}
                    onChange={({target})=>{setAddDepartmentName(target.value)}}
                    isRequired
                    borderColor="gray"
                    placeholder="Enter Department Name"
                  />
                </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button type="submit" colorScheme="purple" variant="solid">
                  Save
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={onAddDepartmentClose}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>


      <Modal isOpen={isAddEmpCategoryOpen} onClose={onAddEmpCategoryClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader >Add Employee Category</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SaveEmpTypeName}>
            <ModalBody>
            <div className="space-y-2 mb-2">
                  <label>Employee Catgeory Name</label>
                  <Input
                    value={AddEmpCategoryName}
                    onChange={({target})=>{setAddEmpCategoryName(target.value)}}
                    isRequired
                    borderColor="gray"
                    placeholder="Enter Employee Category Name"
                  />
                </div>

                <div className="space-y-2  mb-4">
                    <div className="flex justify-between">
                    <label>Probation Month</label>
                    <Checkbox
                    isChecked={IsProbationApply}
                    onChange={({ target }) => {
                      setIsProbationApply(target.checked);
                      if (target.checked === false) {
                        setProbationMonth("");
                      }
                    }}
                    borderColor="gray"
                  >
                    is Probation Apply?
                  </Checkbox>
                    </div>
                    <Select
                      isDisabled={IsProbationApply === false ? true : false}
                      value={ProbationMonth || ""}
                      onChange={({ target }) => {
                        setProbationMonth(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Select Month"
                    >
                      <option value="1">1 Month</option>
                      <option value="2">2 Month</option>
                      <option value="3">3 Month</option>
                    </Select>
                  </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button type="submit" colorScheme="purple" variant="solid">
                  Save
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={onAddEmpCategoryClose}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>


      <Modal size='5xl' scrollBehavior='inside' isOpen={isImportEmployeesOpen} onClose={onImportEmployeesClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Employees</ModalHeader>
          <ModalCloseButton onClick={()=>{setEmployeesImportData([])}} />
          <hr />
            <ModalBody>

              <div className='mt-4'>
               <CSVLink data={EmpExcelHeads} headers={EmpExcelHeads} filename={"Employees Data.csv"}>
                 <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Employees</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(EmployeesImportData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setEmployeesImportData([]);
                    onImportEmployeesClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default CreateEmployee