import React, { useState, useRef } from "react";
import { Tooltip, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure, Button, Textarea, Input } from "@chakra-ui/react";
import { MessageCircle } from "react-feather";
import { useLocation } from "react-router-dom";
import { useAuth } from "./AuthContext";
import moment from "moment";
import usePost from './usePost'

const RaiseIssue = () => {


    const FileRef = useRef();

    const reset = () => {
      FileRef.current.value = "";
    };

  const { IsPostLoading, PostData} = usePost('/api/Issues/SaveIssues');
  const { userData } = useAuth();
  const location = useLocation();
  const { isOpen: isRaiseIssueOpen, onOpen: onRaiseIssueOpen, onClose: onRaiseIssueClose } = useDisclosure();

  const [RaisedIssueData, setRaisedIssueData] = useState({
    menuName: "",
    issueTitle: "",
    issueDescription: "",
    fileAttachment: "",
  });

  return (
    <div>
      <div
        onClick={() => {
            onRaiseIssueOpen();
        }}
        className="absolute right-5 animate-bounce hover:animate-none bottom-5 rounded-full"
      >
        <Tooltip
          placement="left"
          rounded="5px"
          hasArrow
          label="Facing Any Issue?"
          bg="gray.700"
          color="white"
        >
          <button className="transition ease-in-out delay-75 bg-blue-500 hover:-translate-y-1 hover:scale-125 hover:bg-indigo-500 duration-300 p-2 rounded-full text-white">
            <MessageCircle />
          </button>
        </Tooltip>
      </div>


      <Modal isCentered isOpen={isRaiseIssueOpen} onClose={onRaiseIssueClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.800"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >
            Raise An Issue
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <form onSubmit={() => {}}>
            <ModalBody className="space-y-2">
              <div className="space-y-2">
                <label>
                  Menu Name<span className="text-red-600">*</span>
                </label>
                <Input
                  isRequired
                  value={RaisedIssueData?.menuName}
                  onChange={({ target }) => {
                    setRaisedIssueData({
                      ...RaisedIssueData,
                      menuName: target.value,
                    });
                  }}
                  borderColor={"gray"}
                  placeholder="Menu Name"
                />
              </div>
              <div className="space-y-2">
                <label>
                  Issue Title<span className="text-red-600">*</span>
                </label>
                <Input
                  isRequired
                  value={RaisedIssueData?.issueTitle}
                  onChange={({ target }) => {
                    setRaisedIssueData({
                      ...RaisedIssueData,
                      issueTitle: target.value,
                    });
                  }}
                  borderColor={"gray"}
                  placeholder="Issue Title"
                />
              </div>
              <div className="space-y-2">
                <label>
                  Description<span className="text-red-600">*</span>
                </label>
                <Textarea
                  isRequired
                  value={RaisedIssueData?.issueDescription}
                  onChange={({ target }) => {
                    setRaisedIssueData({
                      ...RaisedIssueData,
                      issueDescription: target.value,
                    });
                  }}
                  placeholder="Issue Description"
                  rows={2}
                  borderColor={"gray"}
                />
              </div>
              <div className="space-y-2">
                <label>Attachment</label>
                <Input 
                  ref={FileRef}
                  borderColor={"gray"}
                  onChange={({ target }) => {
                    setRaisedIssueData({
                      ...RaisedIssueData,
                      fileAttachment: target.files[0],
                    });
                  }}
                  type="file"
                  accept="image/*"
                />
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button isLoading={IsPostLoading} loadingText="Saving..."
                onClick={ async () => {
                  const IssueData = new FormData();

                  const { menuName, issueTitle, issueDescription, fileAttachment } = RaisedIssueData;

                  IssueData.append("menuName", menuName);
                  IssueData.append("issueUrl", location?.pathname);
                  IssueData.append("issueTitle", issueTitle);
                  IssueData.append("issueDescription", issueDescription);
                  IssueData.append("FileAttachmentPath", fileAttachment);
                  IssueData.append("raisedBy", userData?.userId);
                  IssueData.append("emailId", userData?.userName);
                  IssueData.append("raisedDate",moment().format('YYYY-MM-DD'));
                  IssueData.append("resolvedDate",moment().format('YYYY-MM-DD'));
                  IssueData.append("status",'Open');
                  IssueData.append("priority","Medium");
                  IssueData.append("companyId",userData?.companyId);
                  IssueData.append("branchId", userData?.isActiveBranch);
                  IssueData.append("createdOn",moment().format('YYYY-MM-DD'));
                  IssueData.append("createdBy",userData?.userId);

                  const response = await PostData(IssueData);
                  if(response){
                    onRaiseIssueClose();
                    reset();
                    setRaisedIssueData({
                        menuName: "",
                        issueTitle: "",
                        issueDescription: "",
                        fileAttachment: "",
                      });

                  }
                }}
                colorScheme="purple"
                mr={3}
              >
                Save
              </Button>
              <Button colorScheme="purple" onClick={onRaiseIssueClose} variant="outline">
                Cancel
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default RaiseIssue;
