import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import ReactSelect from 'react-select';
import { Box, Button, Avatar, Image, Modal, ModalOverlay, ModalContent, useDisclosure, useToast } from "@chakra-ui/react";
import { Search } from "react-feather";
import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';
import 'react-datepicker/dist/react-datepicker.css';

function ActivityReport() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ProfilePicPath, setProfilePicPath] = useState({
    fullName: '',
    profilePath: ''
  });
  const [imageBlobUrl, setImageBlobUrl] = useState(null);
  const toast = useToast();
  const gridRef = useRef();
  const [EmpList, setEmpList] = useState([]);
  const [SelectedEmp, setSelectedEmp] = useState([]);
  const [DateRange, setDateRange] = useState([null, null]);
  const [StartDate, EndDate] = DateRange;

  const gridStyle = useMemo(() => ({ height: "100%", width: "100%", overflow: "auto" }), []);
  const [rowData, setRowData] = useState([]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true,
    tooltipField: 'tooltip'
  }), []);

  const [columnDefs] = useState([
    {
      headerName: "Employee Code",
      field: "empCode",
      tooltipField: "empCode"
    },
    {
      headerName: "Image",
      field: "imagePath",
      cellRenderer: (params) => (
        <div onClick={() => {
          if (params.data.imagePath) {
            fetchImage(params.data.imagePath, params.data.empName);
          }
        }} className={params.data.imagePath ? "pt-1 cursor-pointer" : "pt-1"}>
          <Avatar size={'sm'} src={`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFileAttendance?FilePath=${params.data.imagePath}`} />
        </div>
      ),
      tooltipField: "imagePath"
    },
    {
      headerName: "Employee Name",
      field: "empName",
      tooltipField: "empName"
    },
    {
      headerName: "Att. Date",
      field: "attendanceDate",
      valueGetter: (params) => new Date(params.data.attendanceDate).toLocaleDateString('en-GB'),
      tooltipField: "attendanceDate"
    },
    {
      headerName: "Att. Time",
      field: "attendanceTime",
      tooltipField: "attendanceTime"
    },
    {
      headerName: "Latitude",
      field: "latitude",
      tooltipField: "latitude"
    },
    {
      headerName: "Longitude",
      field: "longitude",
      tooltipField: "longitude"
    },
    {
      headerName: "Att. Status",
      field: "attendanceStatus",
      tooltipField: "attendanceStatus"
    },
    {
      headerName: "Location",
      field: "location",
      tooltipField: "location"
    },
  ]);

  useEffect(() => {
    getEmployeeList();
  }, []);

  const getEmployeeList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      const res = response.data;
      let ActiveEmpList = res.filter((data) => data.isActive === true);
      setEmpList(ActiveEmpList);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleSearch = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetActivityReport?Empguid=${SelectedEmp.guId}&Fromdate=${new Date(StartDate).toLocaleDateString('en-CA')}&Todate=${new Date(EndDate).toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchImage = async (imagePath, empName) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFileAttendance?FilePath=${imagePath}`, { responseType: 'blob' });
      const blob = new Blob([response.data], { type: response.data.type });
      const url = URL.createObjectURL(blob);
      setImageBlobUrl(url);
      setProfilePicPath({
        fullName: empName,
        profilePath: imagePath
      });
      onOpen();
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Activity Report</h1>
      </div>

      <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end'>
        <div className='space-y-2 w-1/4'>
          <label>Select Employee</label>
          <ReactSelect
            options={EmpList}
            value={SelectedEmp}
            onChange={(value) => { setSelectedEmp(value); }}
            getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
            getOptionValue={(option) => option.guId}
            placeholder="Select Employee"
          />
        </div>

        <div className="space-y-2">
          <label>Select Date Range</label>
          <DatePicker
            className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 outline-none"
            selectsRange={true}
            startDate={StartDate}
            endDate={EndDate}
            onChange={(update) => {
              setDateRange(update);
            }}
            dateFormat="dd/MM/yyyy"
            placeholderText='Select Date Range'
          />
        </div>

        <Button colorScheme={'purple'} onClick={HandleSearch}>
          <Search size={'16px'} className="mr-2" />Search
        </Button>
      </Box>

      <div className="ag-theme-alpine mt-4" style={gridStyle}>
        <AgGridReact
          domLayout={"autoHeight"}
          ref={gridRef}
          rowData={rowData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          pagination={true}
          paginationPageSize={12}
          paginationNumberFormatter={paginationNumberFormatter}
          tooltipShowDelay={0}
        />
      </div>

      <Modal size={'xs'} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg={'transparent'} shadow={'none'}>
          <div>
            {imageBlobUrl && (
              <Image
                borderRadius='full'
                className="object-cover"
                boxSize='300px'
                src={imageBlobUrl}
                alt={ProfilePicPath.fullName}
              />
            )}
          </div>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ActivityReport;
