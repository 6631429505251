import React, { useState, useEffect, useMemo, useRef } from "react";
import { AgGridReact } from "ag-grid-react";
import { Box, Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalCloseButton, ModalBody, ModalFooter, Input, FormLabel, Switch, Radio, RadioGroup, Stack, useDisclosure } from "@chakra-ui/react";
import axios from "axios";

function ESIRuleReport() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "400px", width: "100%" }), []);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [rowData, setRowData] = useState([]);
  const [filterValue, setFilterValue] = useState("all");
  const [formData, setFormData] = useState({
    esiRuleId: 0,
    empGuId: "",
    empName: "",
    esiStatus: true,
    startDate: "",
    endDate: "",
    companyId: 0,
    branchId: 0,
    workingLocation: "",
  });

  const columnDefs = [
    { headerName: "Emp Code", field: "empCode" },
    { headerName: "Emp Name", field: "empName", width: 120 },
    {
      headerName: "ESI Status",
      field: "esiStatus",
      cellRenderer: (params) =>
        params.value ? (
          <span style={{ color: "red", fontWeight: "bold" }}>Pending</span>
        ) : (
          <span style={{ color: "green", fontWeight: "bold" }}>Cleared</span>
        ),
    },
    {
      headerName: "Start Date",
      field: "startDate",
      valueFormatter: (params) => formatDate(params.value),
    },
    {
      headerName: "End Date",
      field: "endDate",
      valueFormatter: (params) => formatDate(params.value),
    },
    { headerName: "Working Location", field: "workingLocation" },
    {
      headerName: "Action",
      field: "action",
      cellRenderer: (params) =>
        params.data.esiStatus && (
          <Button
            colorScheme="blue"
            size="sm"
            onClick={() => handleEdit(params.data)}
          >
            Edit
          </Button>
        ),
    },
  ];

  const defaultColDef = useMemo(
    () => ({
      flex: 1,
      sortable: true,
      filter: true,
      floatingFilter: true,
      resizable: true,
    }),
    []
  );

  const filteredData = useMemo(() => {
    if (filterValue === "pending") {
      return rowData.filter((row) => row.esiStatus === true);
    } else if (filterValue === "cleared") {
      return rowData.filter((row) => row.esiStatus === false);
    }
    return rowData;
  }, [rowData, filterValue]);

  const handleEdit = (rowData) => {
    setFormData({ ...rowData });
    onOpen();
  };

  const handleSubmit = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/ESI_PF/ClearESIRuleReport`,
        formData
      );
      if (response.status === 200) {
        alert("ESI Rule Updated Successfully");
        onClose();
        fetchData(); // Refresh the grid
      }
    } catch (error) {
      console.error("Error updating ESI Rule:", error);
    }
  };

  const formatDate = (date) => {
    if (!date) return "";
    const options = { day: "2-digit", month: "short", year: "numeric" };
    return new Intl.DateTimeFormat("en-GB", options)
      .format(new Date(date))
      .replace(",", "-");
  };

  const fetchData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/ESI_PF/GetEmployeeESIRuleReport?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      setRowData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <Box p={4}>
      <h1 style={{ fontSize: "1.5rem", fontWeight: "bold", marginBottom: "1rem" }}>ESI Rule Report</h1>

      {/* Radio Button Filter */}
      <RadioGroup onChange={setFilterValue} value={filterValue} mb={4}>
        <Box
          border="1px solid gray"
          borderRadius="md"
          bg="white"
          p={4}
          display="inline-block"
        >
          <Stack direction="row" spacing={6}>
            <Radio value="all">All</Radio>
            <Radio value="cleared">ESI Rule Cleared</Radio>
            <Radio value="pending">ESI Rule Pending</Radio>
          </Stack>
        </Box>
      </RadioGroup>

      {/* AgGrid Table */}
      <div className="ag-theme-alpine" style={gridStyle}>
        <AgGridReact
          ref={gridRef}
          rowData={filteredData}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          pagination={true}
          paginationPageSize={10}
        />
      </div>

      {/* Modal */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit ESI Rule</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormLabel>Employee Name</FormLabel>
            <Input name="empName" value={formData.empName} isDisabled />

            <FormLabel>Working Location</FormLabel>
            <Input name="workingLocation" value={formData.workingLocation} isDisabled />

            <FormLabel>Start Date</FormLabel>
            <Input
              type="date"
              name="startDate"
              value={formData.startDate.split("T")[0]}
              onChange={(e) => setFormData({ ...formData, startDate: e.target.value })}
            />

            <FormLabel>End Date</FormLabel>
            <Input
              type="date"
              name="endDate"
              value={formData.endDate.split("T")[0]}
              onChange={(e) => setFormData({ ...formData, endDate: e.target.value })}
            />

            <FormLabel>ESI Status</FormLabel>
            <Switch
              isChecked={formData.esiStatus}
              onChange={() => setFormData((prev) => ({ ...prev, esiStatus: !prev.esiStatus }))}
              colorScheme="blue"
            >
              {formData.esiStatus ? "Pending" : "Cleared"}
            </Switch>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={handleSubmit}>
              Submit
            </Button>
            <Button variant="ghost" onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
}

export default ESIRuleReport;
