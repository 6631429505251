import React, { useState } from "react";
import { Button, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, useDisclosure } from "@chakra-ui/react";
import axios from "axios";
import { useEffect } from "react";
import { Download, Eye } from "react-feather";
import DatePicker from "react-datepicker";
import NoData from "../Utils/NoData";
import useFetch from "../Utils/useFetch";
import useValidation from "../Utils/useValidation";

function EmpPayslip() {
  const userData = JSON.parse(localStorage.getItem("user"));

  const Validation = useValidation();

  const [PaySlipDataMonth, setPaySlipDataMonth] = useState(new Date());

  const { FetchedData, Refetch } = useFetch(`/api/EmpMonthlySalarySlip/GetMonthlySalarySlipbyGuid?EmpGuid=${userData.guId}&Year=${new Date(PaySlipDataMonth).getFullYear()}`)

  console.log("Fetched Dat",FetchedData);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [PdfPath, setPdfPath] = useState();

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const [EmpPaySlipList, setEmpPaySlipList] = useState([]);

  useEffect(() => {
    GetPayslipList(new Date(PaySlipDataMonth).getFullYear());
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleViewFile = async (DocPath) => {
    console.log("Document path", DocPath);
    await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`
    )
      .then((response) => {
        console.log("file Response", response);

        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values

          let alink = document.createElement("a");
          alink.href = fileURL;
          //  alink.download = fileURL;
          setPdfPath(alink.href);
          onOpen();
          // alink.click();
        });
      })
      .catch((err) => {
        console.error("File Api Error", err);
      });
  };

  const handleDownloadFile = async (DocPath, MONTH, YEAR) => {
    console.log("Document path", DocPath, MONTH, YEAR);
    await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`
    )
      .then((response) => {
        console.log("file Response", response);

        response.blob().then((blob) => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values
          let alink = document.createElement("a");
          alink.href = fileURL;
          alink.download = `${userData.name} ${
            monthNames[MONTH - 1]
          } ${YEAR} Payslip.pdf`;
          alink.click();
        });
      })
      .catch((error) => {
        console.log("File Api Error", error);
      });
  };

  const GetPayslipList = async (YEAR) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmpMonthlySalarySlip/GetMonthlySalarySlipbyGuid?EmpGuid=${userData.guId}&Year=${YEAR}`
      );
      console.log("Paylsip List Response", response);
      setEmpPaySlipList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div>
      <div className="flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Payslip</h1>
        <div>
          <DatePicker
            className="bg-white border border-gray-400 z-60 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
            selected={PaySlipDataMonth}
            onChange={(date) => {
              setPaySlipDataMonth(date);
              Refetch();
              GetPayslipList(new Date(date).getFullYear());
            }}
            dateFormat="yyyy"
            minDate={new Date(Validation?.payrollStartFrom)}
            maxDate={new Date()}
            showYearPicker
            placeholderText="Select Year"
          />
        </div>
      </div>

      {EmpPaySlipList.length !== 0 ? (
        <div className="flex gap-6 flex-wrap">
          {EmpPaySlipList?.map((PaySlipData) => {
            return (
              <div
                key={PaySlipData.empMonthlySalarySlipId}
                className="bg-white max-w-xs w-full rounded-xl p-4"
              >
                <div className="bg-gray-200 drop-shadow-md mx-auto rounded-full h-16 w-16 p-2">
                  <img src="/images/PayslipIcon.png" alt="Payslip Icon" />
                </div>
                <div className="text-center">
                  <h3 className="font-bold text-2xl text-gray-700">
                    {monthNames[PaySlipData.month - 1]}
                  </h3>
                  <h6 className="font-ligt text-gray-400">
                    {PaySlipData.year}
                  </h6>
                </div>
                <div className="text-right space-x-4 mt-3">
                  <Button
                    onClick={() => {
                      handleViewFile(PaySlipData.filePath);
                    }}
                    size={"sm"}
                    leftIcon={<Eye size={"14px"} />}
                    colorScheme="purple"
                    variant={"link"}
                  >
                    View
                  </Button>
                  <Button
                    onClick={() => {
                      handleDownloadFile(
                        PaySlipData.filePath,
                        PaySlipData.month,
                        PaySlipData.year
                      );
                    }}
                    size={"sm"}
                    leftIcon={<Download size={"14px"} />}
                    colorScheme="purple"
                    variant={"link"}
                  >
                    Download
                  </Button>
                </div>
              </div>
            );
          })}
        </div>
      ) : (
        <NoData />
      )}

      <Modal size="full" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Salary Slip</ModalHeader>
          <ModalCloseButton mt={1} />
          <ModalBody>
            <div id="pdf-div">
              <embed
                src={PdfPath + "#toolbar=0"}
                type="application/pdf"
                className="h-screen w-full !select-none"
                height={800}
                width={500}
                oncontextmenu="return false;"
              />
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme="purple" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default EmpPayslip;
