import React, {useCallback, useState, useRef, useMemo } from 'react'
import useFetch from '../Utils/useFetch';
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { Button } from '@chakra-ui/react';
import { Eye, Plus } from 'react-feather';

const EmpInvestDeclaration = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
    const { IsFetchLoading, FetchedData, FetchError } = useFetch(`/api/Investment/GetEmpTaxFYList?CompanyId=${userData.companyId}&BranchId=${userData.branchId}&EmpGuid=${userData.guId}`)

    const navigate = useNavigate()
    const gridRef = useRef();
    const [ columnDefs ] = useState([
      {
        headerName: "Financial Year",
        field: "financialYear"
      },
      {
        headerName: "Declaration Status",
        valueGetter:(params)=> params.data.isComplete === true && params.data.isLock === true  ? "Confirmed" : params.data.isComplete === true && params.data.isLock === false ? "Completed" : "Pending",
        field: "isComplete",
        cellRenderer:(params) => {
          return params.data.isComplete === true && params.data.isLock === true ? 
          <span className="bg-green-600 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap"><FontAwesomeIcon icon={faLock} size="sm" className="mr-1"/>Confirmed</span>: params.data.isComplete === true && params.data.isLock === false ? 
          <span className="bg-green-500 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap">Completed</span>:
          <span className="bg-red-500   px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap">Pending</span>
        }
      },
      {
        headerName: "Action",
        floatingFilter: false,
        cellRenderer: (params) => params.data.isComplete === false ? <Button onClick={()=>{
            navigate(`/addinvestment/${params.data.empGuid}/employee/${params.data.financialYear}`)
          }} leftIcon={<Plus size={'14px'}/>} colorScheme='blue' size={'xs'} >Add Declaration</Button> : params.data.isComplete === true ? <Button onClick={()=>{
            navigate(`/addinvestment/${params.data.empGuid}/employee/${params.data.financialYear}`)
          }} leftIcon={<Eye size={'14px'}/>} colorScheme='blue' size={'xs'} >View Declaration</Button> : '' 
      }
    ]);

    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    const defaultColDef = useMemo(() => ({
      flex:1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      enableCellTextSelection: true,
      resizable: true
    }));

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">My Investments</h1>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={FetchedData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>
    </div>
  )
}

export default EmpInvestDeclaration