import React,{useRef,useMemo,useState, useCallback, useEffect} from 'react'
import axios from "axios";
import {  Avatar, Button , useToast,  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,useDisclosure} from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
    faAngleLeft,
    faAngleRight,
    faCircleCheck,
    faDownload,
    faXmarkCircle,
} from "@fortawesome/free-solid-svg-icons";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper";
import "swiper/css";
import "swiper/css/navigation";
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-datepicker";
import numberToWords from 'number-to-words';
import numeral from 'numeral';
import _ from "lodash";


function EstimatedSalary() {

  const toast = useToast();
  const id = 'toast';

  const { isOpen, onOpen, onClose } = useDisclosure()
  const cancelRef = useRef()

  const [SelectedYear, setSelectedYear] = useState(new Date());

  const [PayrollMonth, setPayrollMonth] = useState(new Date().getMonth()+1);
  const [PayrollYear, setPayrollYear] = useState(new Date().getFullYear())

  const [IsPayrollMonthFinalized, setIsPayrollMonthFinalized] = useState(false);

  const [PayrollSettingStatus, setPayrollSettingStatus] = useState(
    {
      PayrollConfiguration : false,
      SalaryStatus : false,
      // AttendanceOfMonth : false
    }
  )

  console.log("Payroll Setting Status",PayrollSettingStatus,Object.values(PayrollSettingStatus).every((value) => value));

  const [PayrollMonthList, setPayrollMonthList] = useState([]);
  const [EstimatedData, setEstimatedData] = useState([]);
  const [MonthClicked, setMonthClicked] = useState();
  const [YearClicked, setYearClicked] = useState();
  const userData = JSON.parse(localStorage.getItem("user"));
  const [isCalculateDisabled, setisCalculateDisabled] = useState(false);
  // Slider Variables Start
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  // Slider Variables End



  const NumberFormat =  (value) =>{
    return numeral(value).format('0,0.00')
  }


  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  const [columnDefs, setColumnDefs] = useState(
    userData.countryName === "India"? [
      { headerName: "Employee Code", field: "empCode",pinned: 'left' },

      { headerName: "Employee Name", field: "employeeName",pinned: 'left' },
      { headerName: "Salary Month", field: "salaryMonth" },
      { headerName: "Salary Year", field: "salaryYear" },
      { headerName: "Working Days", field: "workingdays" },
      { headerName: "Loss of Pay", field: "lossOfPay" },
      { headerName: "EPF_EE", field: "employeeEPFContribution" },
      { headerName: "EPF_ER", field: "employerEPFContribution" },
      // { headerName: "Employee Additional PF Contribution", field: "empAdditionalEPFContribution" },
      { headerName: "ESI_EE", field: "employeeESIContribution" },
      { headerName: "ESI_ER", field: "employerESIContribution" },
      { headerName: "Gross Salary", field: "grossSalary" },
      { headerName: "Net Salary", field: "netSalary" },
    ] : [
      { headerName: "Employee Code", field: "empCode",pinned: 'left' },

      { headerName: "Employee Name", field: "employeeName",pinned: 'left' },
      { headerName: "Salary Month", field: "salaryMonth" },
      { headerName: "Salary Year", field: "salaryYear" },
      { headerName: "Working Days", field: "workingdays" },
      // { headerName: "Loss of Pay", field: "lossOfPay" },
      // { headerName: "Employee PF Contribution", field: "employeeEPFContribution" },
      // { headerName: "Employer PF Contribution", field: "employerEPFContribution" },
      // { headerName: "Employee Additional PF Contribution", field: "empAdditionalEPFContribution" },
      // { headerName: "Admin Charges", field: "adminCharges" },
      // { headerName: "Total Deduction", field: "totalDeduction" },
      // { headerName: "Total Contribution", field: "employerTotalContribution" },
      // { headerName: "Total Reimbursement", field: "totalReimbursment" },
      // { headerName: "Employee PF Contribution", field: "empAdditionalEPFContribution" },
      // { headerName: "Employee PF Contribution", field: "empAdditionalEPFContribution" },
      { headerName: "Gross Salary", field: "grossSalary" },
      { headerName: "Net Salary", field: "netSalary" },
      // { headerName: "CTC", field: "ctc" }
    ]
  );


const paginationNumberFormatter = useCallback((params) => {
  return "[" + params.value.toLocaleString() + "]";
}, []);

const defaultColDef = useMemo(() => ({
  sortable: true,
  filter: "agTextColumnFilter",
  floatingFilter: true,
  cacheQuickFilter: true,
}));


const onallEmpSalaryBtnExport = useCallback(() => {
  gridRef.current.api.exportDataAsCsv();
}, []);


  
    // Getting Current Year End

    useEffect(()=>{
      getPayrollMonthList(new Date(SelectedYear).getFullYear());
    },[])

      // Getting Payroll Month Slider List
  const getPayrollMonthList = async (YEAR) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollEstimate/GetPayrollMonthList?PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
         console.log("Month List Response",response);

      const MonthRes = response.data;
      setPayrollMonthList(response.data);
      let getCurrentMonth = MonthRes.filter((data)=>data.payrollStatus === "Current").map((mdata)=>{
        return mdata.payrollMonthNo
      })[0]

      GetPayrollSettingStatus()
      getEstimatedDetailsOfMonth(getCurrentMonth,YEAR);
      GetFinalizePayrollStatus(getCurrentMonth,YEAR);
      getEstimateEmpList(getCurrentMonth,YEAR);

    } catch (error) {
      console.error(error);
    }
  };


  const handleMonthChange = async (Clickedmonth,ClickedYear) => {
    // console.log("Clicked Month",Clickedmonth,"Clicked Year",ClickedYear);
    setPayrollMonth(Clickedmonth);
    setPayrollYear(ClickedYear);

    GetPayrollSettingStatus()
    getEstimatedDetailsOfMonth(Clickedmonth,ClickedYear);
    getEstimateEmpList(Clickedmonth,ClickedYear);
  }


  const GetPayrollSettingStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollConfiguration/GetPayrollConfiguration?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Payroll Configuration Setting",response);

      if(response.data === ''){
        setPayrollSettingStatus((prev) => { return {...prev,PayrollConfiguration : false}});
      }else{
        setPayrollSettingStatus((prev) => { return {...prev,PayrollConfiguration : true}});
      }
    } catch (error) {
      console.error(error);
    }

  }


  const getEstimatedDetailsOfMonth = async (MONTH,YEAR) => {
    console.log("Payroll Details MONTH",MONTH,"YEAR",YEAR);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollEstimate/GetPayrollMonthEstimateData?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
       console.log("Estimated of Month Response",response.data);
       const res = response.data;
      setEstimatedData(response.data);

      if(Number(res.totalEmployee) === Number(res.payrollProcessedEmployee)){
        setPayrollSettingStatus((prev) => { return {...prev,SalaryStatus : true}});
      }else{
        setPayrollSettingStatus((prev) => { return {...prev,SalaryStatus : false}});
      }

    } catch (error) {
      console.error(error);
    }
  };


  const getEstimateEmpList = async (MONTH,YEAR) => {
    console.log("Row Data MONTH",MONTH,"YEAR",YEAR);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollEstimate/GetEstimatePayrollList?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Estimated Emp Payroll List", response);
      setRowData(response.data)
    } catch (error) {
      console.error(error);
    }finally{
      if(!Object.values(PayrollSettingStatus).every((value) => value)){
        onOpen();
      }
    }

  }

  const GetFinalizePayrollStatus = async (MONTH,YEAR) => {
    try {
        const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PayrollFinalize/GetPayrollFinalStatus?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          );
          console.log("Payroll Month Finalized Status Month",MONTH,"Status",response)
          setIsPayrollMonthFinalized(response.data);
    } catch (error) {
        console.error(error);
    }
 }





    const CalculateEstimatedPayroll = async (MONTH,YEAR) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollEstimate/GetPayrollMonthEstimateData?PayrollMonth=${MONTH}&PayrollYear=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log(response.data); 
      setEstimatedData(response.data);
      if (!toast.isActive(id)) {
        toast({
          id,
          title:"Estimated Salary Processed",
          position: "top",
          status: "info",
          duration: 2000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error(error);
    } 

    getEstimateEmpList(MONTH,YEAR)
    }


  console.log("time ",new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', second: '2-digit' , hour12: false}));

  
  return (
    <div>

      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Estimated Payroll</h1>
        <div>
        <DatePicker
            className="bg-white border border-gray-400 z-60 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
            selected={SelectedYear}
            onChange={(date) => {
              setSelectedYear(date);
              getPayrollMonthList(new Date(date).getFullYear());
            }}
            dateFormat="yyyy"
            showYearPicker
            placeholderText="Select Year"
          />
        </div>
      </div>

      <div className="flex mb-4">
        <div className="bg-white rounded-l-3xl p-2 align-middle flex border-r-2 drop-shadow-md">
          <button className="prev-arrow" onClick={handlePrev}>
            <FontAwesomeIcon className="text-2xl" icon={faAngleLeft} />
          </button>
        </div>

        <Swiper
          initialSlide={new Date().getMonth()}
          ref={sliderRef}
          grabCursor={true}
          className="mySwiper bg-white !z-0"
          breakpoints={{
            640: { slidesPerView: 2 },
            768: { slidesPerView: 4 },
            1024: { slidesPerView: 4 },
            1280: { slidesPerView: 5 },
            1440: { slidesPerView: 7, spaceBetween:10 },
            1920: { slidesPerView: 8},
          }}
        >
          {PayrollMonthList.map((data, i) => {
            return (
              <SwiperSlide key={i}>
                <div
                  onClick={() => {
                    handleMonthChange(data.payrollMonthNo,data.payrollYear);
                  }}
                  className={` flex flex-col  cursor-pointer justify-between rounded-2xl ${
                    data.payrollStatus === "Pending"
                      ? "border-red-400"
                      : data.payrollStatus === "Current"
                      ? "border-purple-500"
                      : data.payrollStatus === "Upcoming"
                      ? "border-gray-400"
                      : data.payrollStatus === "Complete"
                      ? "border-green-500"
                      : " "
                  } ${
                    data.payrollMonthNo === PayrollMonth
                      ? "bg-blue-200 border-blue-400"
                      : ""
                  } border-2 m-2`}
                >
                  <div className="text-center p-4 space-y-2">
                    <h6 className="text-gray-800  texl-2xl font-bold">
                      {data.payrollMonthName.substring(0, 3).toUpperCase()}{" "}
                      {data.payrollYear}
                    </h6>
                    <p className="text-sm">
                      {data.payrollMonthName.substring(0, 3).toUpperCase()}{" "}
                      {data.startDate} -{" "}
                      {data.payrollMonthName.substring(0, 3).toUpperCase()}{" "}
                      {data.endDate}
                    </p>
                  </div>
                  <div
                    className={`${
                      data.payrollStatus === "Pending"
                        ? "bg-red-400"
                        : data.payrollStatus === "Current"
                        ? "bg-purple-500"
                        : data.payrollStatus === "Upcoming"
                        ? "bg-gray-400"
                        : data.payrollStatus === "Complete"
                        ? "bg-green-500"
                        : " "
                    } ${
                      data.payrollMonthNo === PayrollMonth ? "bg-blue-500 " : ""
                    }  text-white rounded-b-xl text-center`}
                  >
                    {data.payrollStatus}
                  </div>
                </div>
              </SwiperSlide>
            );
          })}
        </Swiper>

        <div className="bg-white rounded-r-3xl p-2 align-middle flex border-l-2 drop-shadow-md">
          <button className="next-arrow" onClick={handleNext}>
            <FontAwesomeIcon className="text-2xl" icon={faAngleRight} />
          </button>
        </div>
      </div>



      <div className="bg-white p-4 rounded-3xl mb-4 drop-shadow-md">
        <div className="border-b-2">
          <div className="flex justify-between">
            <h6 className="text-xl font-bold pb-3 text-gray-800">
              {EstimatedData.payrollMonthName} - {EstimatedData.payrollYear}
              &nbsp;
              <span className="text-gray-400 text-xl font-extralight">
                ({EstimatedData.payrollMonthName} {EstimatedData.startDate} -{" "}
                {EstimatedData.payrollMonthName} {EstimatedData.endDate},{" "}
                {EstimatedData.calendarDays} Days)
              </span>
            </h6>
            <Button
              isDisabled={IsPayrollMonthFinalized}
              onClick={() => {
                CalculateEstimatedPayroll(PayrollMonth,PayrollYear);
              }}
              colorScheme="purple"
              size={'sm'}
            >
              Estimate Salary Process
            </Button>
          </div>
        </div>

        <div className="flex">
          <div className="border-r-2 flex flex-col justify-between space-y-2 p-2 pr-6">
            <h6 className="text-sm font-medium text-gray-500">
              Total Employees
            </h6>
            <p className="text-2xl text-gray-800 font-bold">
              {EstimatedData.totalEmployee || 0}
            </p>
            <p className="text-sm text-gray-50">_</p>
          </div>

          <div className="border-r-2 flex flex-col justify-between space-y-2 p-2 pr-6">
            <h6 className="text-sm font-medium text-gray-500">
              Calendar Days
            </h6>
            <p className="text-2xl text-gray-800 font-bold">
              {EstimatedData.calendarDays || 0}
            </p>
            <p className="text-sm text-gray-50">_</p>
          </div>

          <div className="border-r-2 flex flex-col justify-between space-y-2 p-2 pr-6">
            <h6 className="text-sm font-medium text-gray-500">
              Current Payroll
            </h6>
            <p className="text-2xl text-gray-800 font-bold">
              {EstimatedData.payrollProcessedEmployee || 0}
            </p>
            <p className="text-sm text-gray-400">Employees</p>
          </div>

          <div className="flex  items-center">
            <div className="flex flex-col justify-between space-y-2 p-2 px-6">
              <h6 className="text-sm font-medium text-gray-500">
                Total Payroll Cost
              </h6>
              <p className="text-2xl text-gray-800 font-bold">
                {userData.currencyCode} {NumberFormat(EstimatedData.totalPayrollCost)}
              </p>
              <p className="text-sm text-gray-400">{numberToWords.toWords(EstimatedData.totalPayrollCost || 0).toString().toUpperCase()}</p>
            </div>
          </div>
        </div>
      </div>

      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-gray-700 text-lg font-bold ">Estimated Salary</h1>
          <Button leftIcon={<FontAwesomeIcon icon={faDownload} />} onClick={onallEmpSalaryBtnExport} colorScheme="purple">
             Export CSV
          </Button>

      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="multiple" // Options - allows click selection of rows
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          cacheQuickFilter={true}
          suppressExcelExport={true}
        />
      </div>



      <AlertDialog
        isOpen={isOpen}
        leastDestructiveRef={cancelRef}
        onClose={onClose}
        isCentered
        closeOnOverlayClick={false}
      >
        <AlertDialogOverlay >
        <AlertDialogContent rounded={'3xl'}>
            <AlertDialogHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>
              Payroll Status
            </AlertDialogHeader>

            <AlertDialogBody>
            <div className='space-y-4'>
            <div className='flex justify-between'>
             <h6>Payroll Configuration Setting</h6>
             {PayrollSettingStatus.PayrollConfiguration ?
             <h6>Completed <FontAwesomeIcon icon={faCircleCheck} className='text-green-600' /></h6> : 
             <h6>Incomplete <FontAwesomeIcon icon={faXmarkCircle} className='text-red-600' /></h6>}
            </div>

            <div className='flex justify-between'>
             <h6>All Employee Salary Status</h6>
             {PayrollSettingStatus.SalaryStatus ?
             <h6>Completed <FontAwesomeIcon icon={faCircleCheck} className='text-green-600' /></h6> : 
             <h6>Incomplete <FontAwesomeIcon icon={faXmarkCircle} className='text-red-600' /></h6>}
            </div>

          </div>
            </AlertDialogBody>
            <hr />
            <AlertDialogFooter>
              <Button  variant={'outline'} colorScheme="purple" onClick={onClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </div>
  );
}

export default EstimatedSalary