import { Button, Input, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon, Box, RadioGroup, Stack, Radio, IconButton, Tooltip, useDisclosure,ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Modal, ModalOverlay } from '@chakra-ui/react';
import axios from 'axios';
import React,{ useState, useEffect } from 'react'
import _ from 'lodash';
import { useNavigate, useParams } from 'react-router-dom';
import usePost from '../Utils/usePost';
import { CheckCircle, Eye, Paperclip, Unlock, Upload } from 'react-feather';
import { useRef } from 'react';
import DatePicker from "react-datepicker";
import moment from 'moment';
import useFetch from '../Utils/useFetch';


function AddInvestment() {
  
  const userData = JSON.parse(localStorage.getItem("user"));
  const { empGuId, gotFinancialYear, userRole } = useParams();

  const FileRef = useRef();
  const reset = () => {
    FileRef.current.value = "";
  };

  const { IsFetchLoading: IsFinYearListLoading, FetchedData: FinYearListData } = useFetch(`/api/Master/GetFinancialYearMasterList`,{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch,
  })
  const { IsPostLoading: IsPostInvestmentLoading, PostData: PostInvestmentData } = usePost(`/api/Investment/SavePayrollInvestment`);
  const { IsPostLoading: IsPostTaxAttachLoading, PostData: PostTaxAttachData } = usePost(`/api/Investment/SaveInvestmentAttachment`);
  const { IsPostLoading: IsPostRentSlipLoading, PostData: PostRentSlipData } = usePost(`/api/Investment/SaveRentInvestment`);

  const navigate = useNavigate();

  const { isOpen: isUploadRentSlipOpen, onOpen: onUploadRentSlipOpen, onClose: onUploadRentSlipClose } = useDisclosure();
  const { isOpen: isUploadTaxAttachmentOpen, onOpen: onUploadTaxAttachmentOpen, onClose: onUploadTaxAttachmentClose } = useDisclosure();
  const { isOpen: isViewTaxAttachmentOpen, onOpen: onViewTaxAttachmentOpen, onClose: onViewTaxAttachmentClose } = useDisclosure();

  const [StartMonth, setStartMonth] = useState(null);
  const [EndMonth, setEndMonth] = useState(null);
  const [RentAmount, setRentAmount] = useState();
  const handleMonthChange = ([newStartDate, newEndDate]) => {
    setStartMonth(newStartDate);
    setEndMonth(newEndDate);
  };

  const [FinancialYearData,setFinancialYearData] = useState([]);
  const [RentSlipList,setRentSlipList] = useState([]);
  const [RentSlipData,setRentSlipData] = useState([]);
  const [TaxSlabList,setTaxSlabList] = useState([]);
  const [AllTaxSectionList,setAllTaxSectionList] = useState([]);
  const [TaxSectionList,setTaxSectionList] = useState([]);
  const [TaxRegimes,setTaxRegimes] = useState('1');
  const [TaxSummary,setTaxSummary ] = useState({
    netTaxId: null,
    empGuid: empGuId,
    financialYear: gotFinancialYear,
    totalIncome: 0,
    taxRebate_Budget2019: 0,
    totalTaxPayable: 0,
    taxSurcharge_Budget2019: 0,
    ednHealthCess: 0,
    netTaxPayable: 0,
    advanceTaxPaid: 0,
    taxRemaining: 0,
    companyId: userData.companyId,
    branchid: userData.isActiveBranch
  });

  const [ TaxSecAttachment, setTaxSecAttachment ] = useState();

  const [SelectedSectionData,setSelectedSectionData] = useState([]);
  console.log("Selected Section Data",SelectedSectionData);


  const CalculateTax = (TotalIncome) => {

    let IsOld = TaxRegimes === "1" ? true : false;
    let IsNew = TaxRegimes === "2" ? true : false;

    const SlabList = TaxSlabList?.filter((tax) => (((IsOld && tax.isOldRegim) || (IsNew && tax.isNewRegin)) && tax.isSc === false && tax.isSSC === false ))

    const TaxableSlabList = SlabList.filter(slab => (slab.minTaxSlab <= TotalIncome ) || (slab.maxTaxSlab !== 0 || TotalIncome <= slab.maxTaxSlab))

    const IncomeTaxAmount = TaxableSlabList.map((data)=>{
      let TaxableAmount = data.minTaxSlab === 0 ? data.maxTaxSlab : (TotalIncome - data.minTaxSlab)
      // console.log("Min Slab Amount",TaxableAmount,(data.taxPercent/100));
      return TaxableAmount >= data.minTaxSlab ? data.minTaxSlab * (data.taxPercent/100) : TaxableAmount <= data.minTaxSlab ? TaxableAmount * (data.taxPercent/100) : 0 
     }).reduce((acc, curr) => acc + curr, 0)

      // console.log("Tax Slab List",TaxSlabList);
      // console.log("Taxable Slab List",TaxableSlabList);
      // console.log("Income Tax Amount",IncomeTaxAmount);
    return Number(IncomeTaxAmount || 0)
  }

  useEffect(()=>{
    if(FinYearListData){
      setFinancialYearData(FinYearListData.filter(data => data.financialYearName === gotFinancialYear)[0]);
    }
  },[FinYearListData])


  console.log("Financial Year Data",FinancialYearData);
  useEffect(()=>{

    const IncomeTotal = (TaxSectionList?.investmentList?.filter((data) => data.taxTypeGlbl === 4 && data.taxParentId !== 0)?.reduce((prev, values) => {
      return Number(prev || 0 ) + Number(values.taxFinalAmount || 0);
    }, 0) + Number(TaxSectionList.annualIncome || 0) - TaxSectionList?.investmentList?.filter(data => data.taxTypeGlbl !== 4 && data.taxParentId === 0 )?.reduce((prev,values)=>{
      return Number(prev || 0) + Number(values.taxFinalAmount || 0) 
    },0)) || 0;

    // console.log("Calculated Tax",IncomeTotal ,CalculateTax(IncomeTotal));

    const TaxRebate = IncomeTotal <= 500000 ? 12500 : 0;
    const TotalTaxPay = CalculateTax(IncomeTotal);
    const incomeThresholds = [5000000, 10000000, 20000000, 50000000];
    const surchargePercentages = [10, 15, 25, 37]; 

    let rate = 0;
    for (let i = 0; i < incomeThresholds.length; i++) {
      if (TotalTaxPay > incomeThresholds[i]) {
        rate = surchargePercentages[i];
      }
    }

    const Surchage = ((rate / 100) * TotalTaxPay) || 0;
    const HealthCess = ((TotalTaxPay + Surchage) * (4/100)) || 0;

    let TaxNet = IncomeTotal <= 500000 ? 0 : Number((TotalTaxPay + Surchage + HealthCess).toFixed(2))

    setTaxSummary((prev)=>{
      return {
        ...prev,
        totalIncome: Number(IncomeTotal.toFixed(2)),
        taxRebate_Budget2019: IncomeTotal <= 500000 ? Number(TaxRebate.toFixed(2)) : 0 ,
        totalTaxPayable: IncomeTotal <= 500000 ? 0 : Number(TotalTaxPay.toFixed(2)),
        taxSurcharge_Budget2019: IncomeTotal <= 500000 ? 0 : Number(Surchage.toFixed(2)),
        ednHealthCess: IncomeTotal <= 500000 ? 0 : Number(HealthCess.toFixed(2)),
        netTaxPayable: TaxNet,
        taxRemaining:  TaxNet - prev.advanceTaxPaid
      }
    })


  },[TaxSectionList])

  useEffect(()=>{
    if(TaxRegimes === "2"){
      setTaxSectionList((prev)=>{
        return {
          ...prev,
          investmentList: AllTaxSectionList?.investmentList?.filter((data)=> data.isNewRegim)
        }
      })
    }else if(TaxRegimes === "1"){
      setTaxSectionList((prev)=>{
        return {
          ...prev,
          investmentList: AllTaxSectionList?.investmentList?.filter((data)=> data.isOldRegim)
        }
      })
    }
  },[TaxRegimes])

  
  useEffect(() => {
    GetTaxSlabList();
  },[]);

  const GetTaxSlabList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/Investment/GetTaxRegimSlabList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&EmpGuid=${empGuId}&FinancialYear=${gotFinancialYear}`)
      console.log('Slab List',response)
      setTaxSlabList(response.data);
    } catch (err) {
      console.error(err);
    } finally {
      GetTaxSectionList();
    }
  }


    const GetTaxSectionList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Investment/GetInvestmentDetail?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&EmpGuid=${empGuId}&FinancialYear=${gotFinancialYear}`
        )
         console.log("Tax Section List Response",response);
        const res = response.data;
        const ModifiedInvestmentList = res?.investmentList?.map((data)=>{
          if(data.taxSectionName === "Rent Paid" && data.taxTypeGlbl === 1){
            return {
              ...data,
              taxSectionMaxValue: res.hraAmount 
            }
          }else{
            return data
          }
        })

        setAllTaxSectionList({
          ...res,
          investmentList : ModifiedInvestmentList,
        });
        setTaxSectionList({
          ...res,
          investmentList : ModifiedInvestmentList,
        });
        setTaxSummary({
          ...res.taxPayableDeclaration,
          empGuid: empGuId,
          totalIncome: res.annualIncome,
          companyId: userData.companyId,
          branchid: userData.isActiveBranch
        });
      } catch (error) {
        console.error(error)
      }
    }

    const GetRentAttachmentList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/Investment/GetRentInvestmentList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&EmpGuid=${empGuId}&FinancialYear=${gotFinancialYear}`)
        console.log('Rent Attachment List',response);
        setRentSlipList(response.data);
      } catch (err) {
        console.error(err);
      }
    }

    // console.log("Tax Summary", TaxSummary);
    // console.log("Tax Section", TaxSectionList);

    const HandleTaxCalculation = (Value,SectionData) => {

      const newState = TaxSectionList?.investmentList?.map((obj) => {
        if (obj.taxSectionId === SectionData.taxSectionId){
          return { ...obj, 
            investmentAmount: Value,
            taxFinalAmount: SectionData.taxSectionMaxValue === 0 ? Value : Value <= SectionData.taxSectionMaxValue ? Value : SectionData.taxSectionMaxValue
          };
        }
        return obj;
      });

      setTaxSectionList((prev)=>{
        return {
          ...prev,
          investmentList : newState.map((obj)=>{
            if(obj.taxParentId === 0){
    
              let SectionTotal = newState.filter((data) => data.taxParentId === obj.taxSectionId).reduce((prevValue, values) => {
                return Number(prevValue || 0) + Number(values.taxFinalAmount ||0)
              }, 0);
    
              return {
                ...obj,
                taxFinalAmount: obj.taxSectionMaxValue === 0 ? SectionTotal : SectionTotal > obj.taxSectionMaxValue  ?  obj.taxSectionMaxValue : SectionTotal
              }
             }
             return obj
          })
        }
      })


    }


    const HandleDeclarationSave = async (DeclarationLock) => {


      let body = {
        ...TaxSectionList,
        taxPayableDeclaration: {
          ...TaxSummary,
          financialYear:gotFinancialYear,
          isLock: DeclarationLock
        },
        investmentList: TaxSectionList?.investmentList?.map((data)=>{
          return {
            ...data,
            empGuid: empGuId,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            tax_Financial_Year: gotFinancialYear
          }
        })
      }

      console.log("Invest body",body);

      const response = await PostInvestmentData(body);

      if(response){
        GetTaxSectionList();
        GetTaxSlabList();
      }
      

    }


    const SaveSectionAttachment = async (e) => {
      e.preventDefault();

      const AttachmentBody = new FormData();

      AttachmentBody.append('taxSectionId',SelectedSectionData.taxSectionId);
      AttachmentBody.append('empGuid',TaxSectionList.empGuid);
      AttachmentBody.append('empCode',TaxSectionList.empCode);
      AttachmentBody.append('investmentAmount',SelectedSectionData.investmentAmount);
      AttachmentBody.append('taxFinalAmount',SelectedSectionData.taxFinalAmount);
      AttachmentBody.append('tax_Financial_Year',SelectedSectionData.tax_Financial_Year);
      AttachmentBody.append('companyId',userData.companyId);
      AttachmentBody.append('branchId',userData.isActiveBranch);
      AttachmentBody.append('attachment',TaxSecAttachment);
      
      const response = await PostTaxAttachData(AttachmentBody);

      if(response){
        GetTaxSectionList();
        onUploadTaxAttachmentClose();
        reset();
      }
    }

    const SaveRentSlip = async (e) => {
      e.preventDefault();

      const RentAttachmentData = new FormData();
      RentAttachmentData.append('empGuId',TaxSectionList.empGuid);
      RentAttachmentData.append('empCode',TaxSectionList.empCode);
      RentAttachmentData.append('rentStartDate',moment(StartMonth).format('YYYY-MM-DD'));
      RentAttachmentData.append('rentEndDate',moment(EndMonth).format('YYYY-MM-DD'));
      RentAttachmentData.append('rentMonth', new Date(StartMonth).getMonth()+1);
      RentAttachmentData.append('rentYear',new Date(StartMonth).getFullYear());
      RentAttachmentData.append('taxFinancialYear',gotFinancialYear);
      RentAttachmentData.append('rentAmount',RentAmount);
      RentAttachmentData.append('companyId',userData.companyId);
      RentAttachmentData.append('branchId',userData.isActiveBranch);
      RentAttachmentData.append('attachment',TaxSecAttachment);

      const response = await PostRentSlipData(RentAttachmentData);
      if(response){
        GetRentAttachmentList();
      }
    }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Add Investment</h1>
      </div>

      <div className="bg-white rounded-2xl p-4 drop-shadow-md mb-4">
        <div className="flex flex-wrap pb-1 gap-8">
          <div>
            <p className="text-sm text-gray-500">Emp Name</p>
            <h6 className="font-medium">{TaxSectionList?.empName}</h6>
          </div>
          <div>
            <p className="text-sm text-gray-500">Emp Code</p>
            <h6 className="font-medium">{TaxSectionList?.empCode}</h6>
          </div>
          <div>
            <p className="text-sm text-gray-500">Designation</p>
            <h6 className="font-medium">{TaxSectionList?.designationName}</h6>
          </div>
          <div>
            <p className="text-sm text-gray-500">Department</p>
            <h6 className="font-medium">{TaxSectionList?.departmentName}</h6>
          </div>
          <div>
            <p className="text-sm text-gray-500">Email</p>
            <h6 className="font-medium">{TaxSectionList?.emailId}</h6>
          </div>
          <div>
            <p className="text-sm text-gray-500">Pan Number</p>
            <h6 className="font-medium">{TaxSectionList?.panNumber}</h6>
          </div>
          <div>
            <p className='text-sm text-gray-500'>City Type</p>
            <h6 className='font-medium'>{TaxSectionList?.cityOfResidance}</h6>
          </div>
          <div>
            <p className="text-sm text-gray-500">Annual Income</p>
            <h6 className="font-medium">{TaxSectionList?.annualIncome}</h6>
          </div>
          <div>
            <p className='text-sm text-gray-500'>Basic Amount</p>
            <h6 className='font-medium'>{TaxSectionList?.basicAmount}</h6>
          </div>
          <div>
            <p className='text-sm text-gray-500'>HRA Amount</p>
            <h6 className='font-medium'>{TaxSectionList?.hraAmount}</h6>
          </div>
        </div>
      </div>


        <div className="bg-white rounded-2xl p-4 drop-shadow-md mb-4 ">
         <div className='flex justify-between items-center text-center text-2xl font-bold mb-3'>
           <h1>Income Tax Calculator for FY {gotFinancialYear}</h1>
           <div className='font-normal '>
             <RadioGroup onChange={setTaxRegimes} value={TaxRegimes}>
               <Stack direction='row' gap={2}>
                 {TaxSectionList?.investmentList?.some((data)=> data.isOldRegim) && <Radio value='1'>Old Regime</Radio>}
                 {TaxSectionList?.investmentList?.some((data)=> data.isNewRegim) && <Radio value='2'>New Regime</Radio>}
               </Stack>
             </RadioGroup>
           </div>
         </div>

      <div className="flex items-center gap-6 py-2 px-4 bg-orange-200 rounded-lg drop-shadow">
        <div className='w-6/12'>
         <label className='font-semibold text-lg'>Particulars</label>
        </div>
        
        <div className='w-2/12'>
         <label className='font-semibold text-lg'>Declared Amount</label>
        </div>
        
        <div className='w-2/12'>
         <label className='font-semibold text-lg'>Actual Calculation</label>
        </div>
        
        <div className='w-2/12'>
         <label className='font-semibold text-lg'>Standard Deduction </label>
        </div>
      </div>


      <div className=' relative rounded-2xl overflow-auto max-h-[45rem] no-scrollbar mb-4'>

      <Accordion defaultIndex={[0]} allowMultiple>

      {_.orderBy(TaxSectionList?.investmentList,['taxTypeGlbl'], ['asc']).filter(data => data.taxParentId === 0).map((parentdata)=>{
            return <AccordionItem key={parentdata.taxSectionId}>
    <div>
      <AccordionButton >
        <Box as="span" flex='1' textAlign='left'>
          <h2 className='font-bold text-lg'>{parentdata.taxSectionName}</h2>
          {parentdata.taxSectionMaxValue > 0 && <p className='text-red-600 text-sm'>Max Value: {userData.currencySymbol}{parentdata.taxSectionMaxValue}</p>}
        </Box>
        <AccordionIcon />
      </AccordionButton>
    </div>
    <AccordionPanel pb={4}>

    {TaxSectionList?.investmentList?.filter((data) => data.taxParentId === parentdata.taxSectionId).map(
                (data, i) => {
                  return (<div key={i} className="flex items-center gap-4 mb-2">
                     <div className='w-6/12'>
                      {data.isInput ? <label>{data.taxSectionName}</label> : <label className='font-semibold text-lg'>{data.taxSectionName}</label>}
                      {data.taxSectionMaxValue > 0 && <p className='text-red-600 text-sm'>Max Value: {userData.currencySymbol}{data.taxSectionMaxValue}</p>}
                     </div>

                    <div className='w-2/12'>
                    {data.isInput && <Input
                        value={data.investmentAmount || ''}
                        onChange={({target})=>{
                          HandleTaxCalculation(target.value,data,parentdata)
                        }}
                        type={"number"}
                        backgroundColor={'blue.50'}
                        borderColor={"blue.400"}
                        placeholder="Amount"
                        min={"0"}
                        onWheel={({target}) => target.blur()}
                      /> }
                    </div>

                    <div className='w-2/12'>
                    {data.isInput && data.taxSectionMaxValue > 0 && <Input 
                        isReadOnly
                        value={data.taxFinalAmount || ''}
                        type={"number"}
                        backgroundColor={'gray.100'}
                        borderColor={"gray.100"}
                        placeholder="Max Amount"
                        min={"0"}
                      /> }
                    </div>

                    <div className='w-2/12 space-x-4'>
                      {data.isAttachmentRequired === true && data.attachmentPath === null && data.investmentAmount > 0 ? <Tooltip placement='top' rounded='5px' hasArrow label='Add Attachment' bg='gray.700' color='white'>
                       <IconButton onClick={()=>{
                         setSelectedSectionData(data);
                         onUploadTaxAttachmentOpen();
                         }} size={'xs'} icon={<Paperclip size={'14px'}/>} colorScheme='blue' />
                      </Tooltip> : data.isAttachmentRequired === true && data.attachmentPath !== null && data.investmentAmount > 0 ? 
                        <Tooltip placement='top' rounded='5px' hasArrow label='View Attachment' bg='gray.700' color='white'>
                         <Button onClick={async()=>{
                           await fetch(
                            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${data.attachmentPath}`,
                           ).then(response => {
                          console.log("file Response",response);
                          response.blob().then(blob => {
                              const fileURL = window.URL.createObjectURL(blob);
                              let alink = document.createElement('a');
                              alink.href = fileURL;
                              // alink.download = fileURL;
                              console.log("Alink",alink);
                              setTaxSecAttachment(alink);
                          })
                        }).catch((error)=>{
                          console.log("File Api Error",error);
                        }).finally(()=>{
                          setSelectedSectionData(data);
                          onViewTaxAttachmentOpen();
                        })
                         }} colorScheme='blue' size={'xs'} leftIcon={<Eye size={'14px'} />} >View</Button>
                        </Tooltip> : ''}
                        {(data.taxSectionName === "Rent Paid" && data.investmentAmount > 0) && 
                        <Button 
                         onClick={()=>{
                          setSelectedSectionData(data);
                          GetRentAttachmentList();
                          onUploadRentSlipOpen();
                         }} 
                         colorScheme='blue' 
                         size={'xs'} 
                         leftIcon={<Upload size={'14px'} />} 
                        >Upload Rent Slips</Button>}
                    </div>
                  </div>
                  );
                }
              )}

                 <div className="flex items-center gap-6 mb-4 ">
                    <div className='w-6/12 pl-4'>
                      
                    </div>
    
                    <div className='w-2/12'>

                    </div>
    
                    <div className='w-2/12 text-right'>
                      <label className='text-lg font-bold text-gray-600'>Total:</label>
                    </div>
    
                    <div className='w-2/12'>
                      <Input
                        value={parentdata.taxFinalAmount || ''}
                        isReadOnly
                        type={"number"}
                        backgroundColor={'gray.50'}
                        borderColor={"gray.400"}
                        placeholder="Total Amount"
                        min={"0"}
                      /> 
                    </div>
                    
                  </div>

                <div>
                 <label>{parentdata.sectionNote}</label>
                </div>

    </AccordionPanel>
  </AccordionItem>

      })}
            </Accordion>

      </div>
        </div>

        <div className="bg-white rounded-2xl p-4 drop-shadow-md mb-6">
          <div className='space-y-2'>
            <div className='flex justify-between items-center'>
              <label>Total Income</label>
              <div>
               <Input 
                 isReadOnly
                 value={TaxSummary?.totalIncome}
                 type={"number"}
                 backgroundColor={'gray.50'}
                 borderColor={"gray.400"}
                 placeholder="Amount"
                 min={"0"}
               />
              </div>
            </div>

            <div className='flex justify-between items-center'>
              <label>Tax Rebate of Rs. 12,500 (For Income of less than 5 lakhs) (Budget 2019)</label>
              <div>
               <Input 
                 isReadOnly
                 value={TaxSummary?.taxRebate_Budget2019}
                 type={"number"}
                 backgroundColor={'gray.50'}
                 borderColor={"gray.400"}
                 placeholder="Amount"
                 min={"0"}
               />
              </div>
            </div>

            <div className='flex justify-between items-center'>
              <label>Total Tax Payable</label>
              <div>
               <Input 
                 isReadOnly
                 value={TaxSummary?.totalTaxPayable}
                 type={"number"}
                 backgroundColor={'gray.50'}
                 borderColor={"gray.400"}
                 placeholder="Amount"
                 min={"0"}
               />
              </div>
            </div>

            <div className='flex justify-between items-center '>
              <label className='w-9/12'>Tax Surcharge @ 10%/15%/25%/37% (Income more than 50 Lakhs/1 cr/2 cr/5 cr respectively) (Budget 2019)</label>
              <div>
               <Input 
                 isReadOnly
                 value={TaxSummary?.taxSurcharge_Budget2019}
                 type={"number"}
                 backgroundColor={'gray.50'}
                 borderColor={"gray.400"}
                 placeholder="Amount"
                 min={"0"}
               />
              </div>
            </div>

            <div className='flex justify-between items-center'>
              <label>Add; Edn Cess + Health Cess @ 4%</label>
              <div>
               <Input 
                 isReadOnly
                 type={"number"}
                 value={TaxSummary?.ednHealthCess}
                 backgroundColor={'gray.50'}
                 borderColor={"gray.400"}
                 placeholder="Amount"
                 min={"0"}
               />
              </div>
            </div>

            <div className='flex justify-between items-center'>
              <label>Net Tax Payable</label>
              <div>
               <Input 
                 isReadOnly
                 value={TaxSummary?.netTaxPayable}
                 type={"number"}
                 backgroundColor={'gray.50'}
                 borderColor={"gray.400"}
                 placeholder="Amount"
                 min={"0"}
               />
              </div>
            </div>

            <hr />

            <div className='flex justify-between items-center'>
              <label>Advance Tax Paid</label>
              <div>
               <Input                  
                 value={TaxSummary?.advanceTaxPaid}
                 onChange={({target})=>{
                  setTaxSummary((prev)=>{
                    return {
                      ...prev,
                      advanceTaxPaid: target.value,
                      taxRemaining: prev.netTaxPayable - target.value
                    }
                  })
                 }}
                 type={"number"}
                 backgroundColor={'blue.50'}
                 borderColor={"blue.400"}
                 placeholder="Amount"
                 min={"0"}
                 onWheel={({target}) => target.blur()}
               />
              </div>
            </div>

            <div className='flex justify-between items-center'>
              <label>Tax Remaining to be Paid</label>
              <div>
               <Input 
                 isReadOnly
                 value={TaxSummary?.taxRemaining }
                 type={"number"}
                 backgroundColor={'gray.50'}
                 borderColor={"gray.400"}
                 placeholder="Amount"
                 min={"0"}
               />
              </div>
            </div>


          </div>

          <div className='text-right space-x-4 mt-4'>
           { TaxSummary.isLock === true || <Button isLoading={IsPostInvestmentLoading} loadingText='Saving...' onClick={()=>{HandleDeclarationSave(false);}} colorScheme={'purple'} variant={'solid'}>Save</Button>}
           { userRole === "admin" && TaxSummary.isLock === false ? <Button isLoading={IsPostInvestmentLoading} loadingText='Locking...' onClick={()=>{HandleDeclarationSave(true);}} leftIcon={<CheckCircle size={'20px'}/>} variant={'outline'} colorScheme='purple'>Confirm and Lock</Button> : userRole === "admin" && TaxSummary.isLock === true ? <Button isLoading={IsPostInvestmentLoading} loadingText='Unlocking...' onClick={()=>{HandleDeclarationSave(false);}} leftIcon={<Unlock size={'20px'}/>} variant={'outline'} colorScheme='purple'>Unlock</Button> : ''}
           <Button colorScheme='purple' variant={'ghost'} onClick={()=>{
             if(userRole === "admin"){
               navigate('/allinvestments')
             }else if(userRole === "employee"){
               navigate('/empinvestments')
             }
            }}>Cancel</Button>
          </div>
        </div>

       <div className='absolute right-5 bottom-0 bg-rose-200 shadow-md rounded-t-xl px-2 flex flex-col py-1'>
        <label className='font-semibold text-gray-700'>Total Income: {TaxSummary?.totalIncome}</label>
        <label className='font-semibold text-gray-700'>Net Tax Payable: {TaxSummary?.netTaxPayable}</label>
       </div>

       <Modal isOpen={isUploadTaxAttachmentOpen} onClose={onUploadTaxAttachmentClose} >
         <ModalOverlay />
         <ModalContent rounded={"3xl"}>
           <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Upload Attachment</ModalHeader>
           <ModalCloseButton mt={1} />
           <form onSubmit={SaveSectionAttachment}>
             <ModalBody>
               <div className="space-y-2">
                 <label>Attachment</label>
                 <Input ref={FileRef} isRequired type='file' accept='application/pdf' borderColor={'gray'} onChange={({target})=>{setTaxSecAttachment(target.files[0])}} />
               </div>
             </ModalBody>
             <hr />
             <ModalFooter>
               <div className="space-x-4">
                 <Button isLoading={IsPostTaxAttachLoading} loadingText='Saving...' type="submit" colorScheme="purple" variant="solid">Save</Button>
                 <Button variant="outline" colorScheme="purple" onClick={onUploadTaxAttachmentClose}>Cancel</Button>
               </div>
             </ModalFooter>
           </form>
         </ModalContent>
       </Modal>

       <Modal size='full' scrollBehavior="inside" isOpen={isViewTaxAttachmentOpen} onClose={()=>{
                onViewTaxAttachmentClose();
                if(RentSlipData.length !== 0){
                  setRentSlipData([]);
                }else{
                  setSelectedSectionData([]);
                }
              }}>
          <ModalOverlay />
          <ModalContent rounded={"3xl"}>
            <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Tax Details</ModalHeader>
            <ModalCloseButton mt={1} />
            <ModalBody className='space-y-2'>
            { RentSlipData.length !== 0 ? <div className="flex flex-wrap pb-1 gap-8">
              <div>
                <p className="text-sm text-gray-500">Rent Start Month</p>
                <h6 className="font-medium">{moment(RentSlipData.rentStartDate).format('MMM-YYYY')}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Rent End Month</p>
                <h6 className="font-medium">{moment(RentSlipData.rentEndDate).format('MMM-YYYY')}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Max Amount</p>
                <h6 className="font-medium">{RentSlipData.rentAmount}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Financial Year</p>
                <h6 className="font-medium">{RentSlipData.taxFinancialYear}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">HRA Investment</p>
                <h6 className="font-medium">{SelectedSectionData.investmentAmount}</h6>
              </div>
            </div> : <div className="flex flex-wrap pb-1 gap-8">
              <div>
                <p className="text-sm text-gray-500">Tax Section</p>
                <h6 className="font-medium">{SelectedSectionData.taxSectionName}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Investment Amount</p>
                <h6 className="font-medium">{SelectedSectionData.investmentAmount}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Max Amount</p>
                <h6 className="font-medium">{SelectedSectionData.taxSectionMaxValue}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Financial Year</p>
                <h6 className="font-medium">{SelectedSectionData.tax_Financial_Year}</h6>
              </div>
            </div>}
            <hr />
              <div id="pdf-div">
              <embed
                src={TaxSecAttachment + "#toolbar=0"}
                type="application/pdf"
                className="h-screen w-full !select-none"
                height={800}
                width={500}
              />
              </div>
            </ModalBody>
            <hr />
  
            <ModalFooter className="space-x-4">
              {/* <Button leftIcon={<CheckCircle size={'18px'} />} colorScheme='green'>Confirm</Button> */}
              <Button colorScheme='purple' variant={'outline'} onClick={()=>{
                onViewTaxAttachmentClose();
                if(RentSlipData.length !== 0){
                  setRentSlipData([]);
                }else{
                  setSelectedSectionData([]);
                }
              }}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>


        <Modal size={'full'} isOpen={isUploadRentSlipOpen} onClose={onUploadRentSlipClose} >
         <ModalOverlay />
         <ModalContent rounded={"3xl"}>
           <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Upload Rent Slips</ModalHeader>
           <ModalCloseButton mt={1} />
             <ModalBody>
             <div className="flex flex-wrap pb-1 gap-8">
              <div>
                <p className="text-sm text-gray-500">Tax Section</p>
                <h6 className="font-medium">{SelectedSectionData.taxSectionName}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Investment Amount</p>
                <h6 className="font-medium">{SelectedSectionData.investmentAmount}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Max Amount</p>
                <h6 className="font-medium">{SelectedSectionData.taxSectionMaxValue}</h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Financial Year</p>
                <h6 className="font-medium">{SelectedSectionData.tax_Financial_Year}</h6>
              </div>
            </div>
            <hr />

               <form className='flex gap-4 items-end my-4' onSubmit={SaveRentSlip}>
                <div className='space-y-2'>
                  <label>Select Months</label>
                  <DatePicker 
                    required
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5 outline-none"
                    onChange={handleMonthChange}
                    placeholderText="Select Months"
                    startDate={StartMonth}
                    endDate={EndMonth}
                    selectsRange
                    minDate={new Date(gotFinancialYear.split('-')[0],2,1)}
                    maxDate={new Date(gotFinancialYear.split('-')[1],3,1)}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                  />
                </div>
                <div className="space-y-2">
                  <label>Attachment</label>
                  <Input ref={FileRef} isRequired type='file' accept='application/pdf' borderColor={'gray'} onChange={({target})=>{setTaxSecAttachment(target.files[0])}} />
                </div>
                <div className='space-y-2'>
                  <label>Amount</label>
                  <Input value={RentAmount || ''} onChange={({target})=>{setRentAmount(target.value)}} borderColor={'gray'} type='number' placeholder='Rent Amount' />
                </div>
                <div>
                  <Button type='submit' isLoading={IsPostRentSlipLoading} loadingText='Saving...' colorScheme='purple'>Submit</Button>
                </div>
               </form>

               <div className="my-4  overflow-x-auto relative shadow-md sm:rounded-lg">
                  <table className="w-full text-sm text-left text-gray-500 ">
                    <thead className="text-xs text-white uppercase bg-slate-700 ">
                      <tr>
                        <th scope="col" className="py-3 px-6">
                          Rent Start Month
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Rent End Month
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Rent Amount
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Financial Year
                        </th>
                        <th scope="col" className="py-3 px-6">
                          Action
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {RentSlipList.map((data)=>{
                        return <tr key={data.rentId} className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300" >
                        <th scope="row" className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap " >
                          {moment(data.rentStartDate).format('MMM-YYYY')}
                        </th>
                        <td className="py-4 px-6">
                          {moment(data.rentEndDate).format('MMM-YYYY')}
                        </td>
                        <td className="py-4 px-6">
                          {data.rentAmount}
                        </td>
                        <td className="py-4 px-6">
                          {data.taxFinancialYear}
                        </td>
                        <td className="py-4 px-6">
                          <Button onClick={async()=>{
                               await fetch(
                                `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${data.attachmentPath}`,
                               ).then(response => {
                              console.log("file Response",response);
                              response.blob().then(blob => {
                                  const fileURL = window.URL.createObjectURL(blob);
                                  let alink = document.createElement('a');
                                  alink.href = fileURL;
                                  // alink.download = fileURL;
                                  console.log("Alink",alink);
                                  setTaxSecAttachment(alink);
                              })
                            }).catch((error)=>{
                              console.log("File Api Error",error);
                            }).finally(()=>{
                              setRentSlipData(data);
                              onViewTaxAttachmentOpen();
                            })
                          }} leftIcon={<Eye size={'14px'} />} size={'xs'} colorScheme='blue' >View</Button>
                        </td>
                      </tr>
                      })}
                    </tbody>
                  </table>
                </div>
             </ModalBody>
             <hr />
             <ModalFooter>
               <div className="space-x-4">
                 {/* <Button  type="submit" colorScheme="purple" variant="solid">Save</Button> */}
                 <Button variant="outline" colorScheme="purple" onClick={onUploadRentSlipClose}>Cancel</Button>
               </div>
             </ModalFooter>
         </ModalContent>
       </Modal>


    </div>
  );
}

export default AddInvestment