import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Input,
  Button,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";
import axios from "axios";

function DocumentMaster() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [DocumentID, setDocumentID] = useState(0);
  const [DocumentName, setDocumentName] = useState();

  const gridRef = useRef();
  const [rowData, setRowData] = useState([
    {
      id: 1,
      documentTypeName: "Driving License",
      isActive: true,
    },
  ]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Document Name",
      field: "documentTypeName",
    },

    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleDocumentSwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      field: "guId",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              setDocumentID(params.data.documentId);
              setDocumentName(params.data.documentTypeName);
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
          >
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  useEffect(() => {
    getDocumentMasterList();
  }, []);

  const getDocumentMasterList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetDocumentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Document Master List", response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleDocumentSwitch = async (checked, docData) => {
    setRowData((prev) => {
      const newState = prev.map((obj) => {
        if (obj.documentId === docData.documentId) {
          return { ...obj, isActive: checked };
        }
        return obj;
      });

      return newState;
    });

    let body = {
      documentId: docData.documentId,
      srNo: docData.srNo,
      documentTypeName: docData.documentTypeName,
      countryId: docData.countryId,
      countryName: docData.countryName,
      isActive: checked,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log(body);
    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveDocumentMaster`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getDocumentMasterList();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Document not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const SaveDocumentMaster = async (e) => {
    e.preventDefault();
    let body = {
      documentId: DocumentID,
      documentTypeName: DocumentName,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    console.log(body);
    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveDocumentMaster`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getDocumentMasterList();
        setDocumentName("");
        setDocumentID(0);
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Document not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Document Master</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Document
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveDocumentMaster}>
                <div className="space-y-2 w-1/3">
                  <label>Add Document Name</label>
                  <Input
                    isRequired
                    value={DocumentName || ""}
                    onChange={({ target }) => {
                      setDocumentName(target.value);
                    }}
                    borderColor="gray"
                    placeholder="Add Document Name"
                  />
                </div>
                <div className="space-x-3 my-2 text-right">
                  <Button
                    onClick={() => {
                      setDocumentName("");
                      setDocumentID(0);
                    }}
                    type="reset"
                    variant="outline"
                    colorScheme="purple"
                  >
                    Reset
                  </Button>
                  <Button type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default DocumentMaster;
