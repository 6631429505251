import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { Box, Button, useDisclosure, useToast,Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton, 
    Input,
    InputGroup,
    Select,
    InputRightAddon,Tooltip} from "@chakra-ui/react";
import DatePicker from "react-datepicker";
import { Plus } from "react-feather";
import ReactSelect from 'react-select';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileImport, faPlus } from "@fortawesome/free-solid-svg-icons";
import * as XLSX from 'xlsx';
import { CSVLink, CSVDownload } from "react-csv";
import _ from "lodash";
import useValidation from "../Utils/useValidation";

function FinalAdhocPayment() {


    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = "toast";
    const Validation = useValidation();

    const monthNames = ["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER",];

    const [AdhocPaymentMasterList, setAdhocPaymentMasterList] = useState([]);
    const [ImportedPaymentData, setImportedPaymentData] = useState([]);

     const [AddPaymentRowData, setAddPaymentRowData] = useState([]);

    const [AdhocPaymentName, setAdhocPaymentName] = useState();

    const [PaymentMasterID, setPaymentMasterID] = useState();

    const [PaymentMasterName, setPaymentMasterName] = useState();
    const [PaymentMasterType, setPaymentMasterType] = useState();

    const [EmpList, setEmpList] = useState([]);

    const [ImportDataMonth, setImportDataMonth] = useState(new Date());
    const [ImportPaymentExcelHeads, setImportPaymentExcelHeads] = useState([
        { label: "empGuId", key: `empGuId`},
        { label: "employeeName", key: `employeeName`},
        { label: "empCode", key: `empCode`},
        { label: "adhocPaymentAmount", key: `adhocPaymentAmount`},
        { label: "month", key: `month`},
        { label: "year", key: `year`},
      ])

    const {
        isOpen: isAddAdhocPaymentOpen,
        onOpen: onAddAdhocPaymentOpen,
        onClose: onAddAdhocPaymentClose,
      } = useDisclosure();

      const {
        isOpen: isImportAdhocPaymentOpen,
        onOpen: onImportAdhocPaymentOpen,
        onClose: onImportAdhocPaymentClose,
      } = useDisclosure();

    const {
        isOpen: isAdhocPaymentMasterAddOpen,
        onOpen: onAdhocPaymentMasterAddOpen,
        onClose: onAdhocPaymentMasterAddClose,
      } = useDisclosure();

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Employee Code",
        field: "empCode",
      },
      {
            headerName: "Employee Name",
            field: "employeeName",
          },
  

            {
              headerName: "Payment Name",
              field: "adhocPaymentName",
            },
            {
              headerName: "Amount",
              field: "adhocPaymentAmount",
              editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'

            },
  
            {
              headerName: "Month",
              field: "month",
            },
            {
              headerName: "Pay Action",
              field: "payAction",
              editable:true, singleClickEdit: true,
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                 values: ['Pay', 'Hold'],
              } ,cellClass:'editable-grid-cell'
            },
  
            {
              headerName: "Remarks",
              field: "remark",
              editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'
            },

            {
                headerName: "Action",
                cellRenderer : (params) => <Button 
                onClick={async()=>{
                    let AdhocData = params.data;
    
                    let body={
                        payrollAdhocPaymentId : AdhocData.payrollAdhocPaymentId,
                        employeeName : AdhocData.employeeName,
                        empCode : AdhocData.empCode,
                        empGuId : AdhocData.empGuId,
                        adhocPaymentId : AdhocData.adhocPaymentId,
                        adhocPaymentAmount : AdhocData.adhocPaymentAmount,
                        remark : AdhocData.remark,
                        payAction : AdhocData.payAction,
                        month : AdhocData.month,
                        year : AdhocData.year,
                        companyId : userData.companyId,
                        branchId : userData.isActiveBranch,
                        isLock : true,
                      }
    
    
                    console.log("Row Save Body",body)
    
    
                    await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Adhocpayment/SaveEmployeeAdhocPayment`,body
                    )
                    .then((response) => {
                      const res = response.data;
                      console.log("res",res);
                  
                      if (!toast.isActive(id)) {
                        toast({
                          id,
                          title:res,
                          position: 'top',
                          status: 'info',
                          duration: 2000,
                          isClosable: true,
                        })
                      }
                  
                    GetAdhocPaymentList(AdhocData.month,AdhocData.year);
    
                  
                    })
                    .catch((error) => {
                      console.log(error.response);
                      console.log(error.response.data);
                  
                      if (!toast.isActive(id)) {
                        toast({
                          id,
                          title: 'ERROR',
                          description: "Payment not Updates.",
                          position: 'top',
                          status: 'error',
                          duration: 2000,
                          isClosable: true,
                        })
                      }
                    });
    
    
                }}
                 size={'xs'} colorScheme='blue'>Save</Button>
              },

      ]);


      const [AdhocPaymentColumnDefs, setAdhocPaymentColumnDefs] = useState([
        {
            headerName: "Employee Name",
            field: "employeeName",
          },
  
          {
              headerName: "Employee Code",
              field: "empCode",
            },
            {
              headerName: "Amount",
              field: "adhocPaymentAmount",
              singleClickEdit: true ,editable:true, cellClass:'editable-grid-cell'
            },
  
            {
              headerName: "Month",
              field: "month",
            },

            {
                headerName: "Month",
                field: "year",
              }

      ]);


            // Table Pagination
            const paginationNumberFormatter = useCallback((params) => {
              return "[" + params.value.toLocaleString() + "]";
            }, []);
          
            //Table columns properties applies to all columns
            const defaultColDef = useMemo(() => ({
              flex: 1,
              sortable: true,
              filter: "agTextColumnFilter",
              floatingFilter: true,
              cacheQuickFilter: true,
              cellClass: 'no-border',
              enableCellTextSelection:true
  
            }));



            useEffect(()=>{
                getEmployeeList();
                GetAdhocPaymentList(new Date(ImportDataMonth).getMonth()+1,new Date(ImportDataMonth).getFullYear());
                GetAdhocPaymentMasterList();
              },[])


              const getEmployeeList = async() =>{
                try {
            
                  const response = await axios.get(
                    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                  )
                  console.log("Employee List",response.data);
            
                  const res = response.data;

                  let ActiveEmpList = res.filter((data)=>data.isActive === true);
            
                  setEmpList(ActiveEmpList);
                  
                } catch (error) {
                  console.error(error)
                }
            
              }

              const GetAdhocPaymentList = async(MONTH,YEAR) => {
                try {
                    const response = await axios.get(
                    `${process.env.REACT_APP_ACQ_URL}/api/Adhocpayment/GetAdhocpaymentlist?month=${MONTH}&year=${YEAR}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                    )
                    console.log("Adhoc Payment List Response",response);
                    setRowData(response.data);
                } catch (error) {
                    console.error(error)
                }
              }


              const GetAdhocPaymentMasterList = async() => {
                try {
                    const response = await axios.get(
                        `${process.env.REACT_APP_ACQ_URL}/api/Adhocpayment/GetAdhocPaymentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                        )

                        console.log("Adhoc Payment Master List Response",response);
                        const res = response.data;

                        setAdhocPaymentMasterList(res);

                } catch (error) {
                    console.error(error)
                }
              }





              
              const SavePaymentMaster = async (e) => {

                e.preventDefault();

                let body = {
                    adhocPaymentName : PaymentMasterName,
                    adhocPaymentType : PaymentMasterType,
                    companyId : userData.companyId,
                    branchId : userData.isActiveBranch
                  }

                  console.log("Save Payment Master Body",body);



                  await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Adhocpayment/SaveAdhocpaymentMaster`,body
                  )
                  .then((response) => {
                    const res = response.data;
                    console.log("res",res);
                
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title:res,
                        position: 'top',
                        status: 'info',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
                
                  GetAdhocPaymentMasterList();
                  onAdhocPaymentMasterAddClose();
                  setPaymentMasterName('');
                  setPaymentMasterType('');
                
                  })
                  .catch((error) => {
                    console.error(error);
                    console.log(error.response.data);
                
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: 'ERROR',
                        description: "Payment Master not Added.",
                        position: 'top',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
                  });
              }


   console.log("Payment Name", _.compact(AdhocPaymentMasterList.map((data)=>{
    if(data.PaymentId === 3){
       return  data.PaymentName
    }
   }))[0]);





   const SaveTableAdhocPayments = async () => {
         let body = AddPaymentRowData;

         console.log("Table Save Adhoc Payment Body",body);


         
         await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Adhocpayment/ImportEmployeeAdhocPayment`,body
         )
         .then((response) => {

            console.log("Save Response",response);

           const res = response.data;

       
           if (!toast.isActive(id)) {
             toast({
               id,
               title:res,
               position: 'top',
               status: 'info',
               duration: 2000,
               isClosable: true,
             })
           }
       
         GetAdhocPaymentList(new Date(ImportDataMonth).getMonth()+1,new Date(ImportDataMonth).getFullYear());
         onAddAdhocPaymentClose();
         setAdhocPaymentName('')
       
         })
         .catch((error) => {
           console.log(error.response);
           console.log(error.response.data);
       
           if (!toast.isActive(id)) {
             toast({
               id,
               title: 'ERROR',
               description: "Payment not Added.",
               position: 'top',
               status: 'error',
               duration: 2000,
               isClosable: true,
             })
           }
         });


   }


   const ImportedPaymentReadExcel = (file)=>{

    const promise = new Promise((resolve,reject) => {
      
     const fileReader = new FileReader();
     fileReader.readAsArrayBuffer(file);
 
     fileReader.onload=(e)=>{
       const bufferArray = e.target.result;
 
       const wb = XLSX.read(bufferArray,{type:'buffer'});
       const wsname = wb.SheetNames[0];
 
       const ws = wb.Sheets[wsname];
 
       const data = XLSX.utils.sheet_to_json(ws, {raw: false});
 
       resolve(data)
     } 
 
     fileReader.onerror = (error) => {
       reject(error)
     }
 
    });
 
    promise.then((data)=>{
      console.log("Payment Imported Data",data);

      let FinalData = data.map((obj)=>{
        return {...obj,
          companyId:userData.companyId,
          branchId:userData.isActiveBranch
        }
       })

       console.log("Payment Final Data",FinalData);
       setImportedPaymentData(FinalData);

       HandleImportedPaymentData(FinalData);


      });
 }



 const HandleImportedPaymentData = async (FinalData) => {


    let body = FinalData.map((data)=>{
        return { ...data,
            adhocPaymentId:AdhocPaymentName
        }
    })

    console.log("Import Adhoc Payment Body",body)
         
    await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Adhocpayment/ImportEmployeeAdhocPayment`,body
    )
    .then((response) => {

       console.log("Save Response",response);

      const res = response.data;

  
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: 'top',
          status: 'info',
          duration: 2000,
          isClosable: true,
        })
      }
  
    GetAdhocPaymentList(new Date(ImportDataMonth).getMonth()+1,new Date(ImportDataMonth).getFullYear());
    onImportAdhocPaymentClose();
    setAdhocPaymentName('')
  
    })
    .catch((error) => {
      console.log(error.response);
      console.log(error.response.data);
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title: 'ERROR',
          description: "Payment not Added.",
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    });



 }


 console.log("Add Payemnt Row Data",AddPaymentRowData);
  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Adhoc Payments</h1>
        <div className="flex items-center gap-4">
            <label>Data Month :</label>
            <div>
            <DatePicker
              className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
              selected={ImportDataMonth}
              onChange={(date) => {
                setImportDataMonth(date);
                GetAdhocPaymentList(new Date(date).getMonth()+1,new Date(date).getFullYear());
              }}
              dateFormat="MM/yyyy"
              minDate={new Date(Validation?.payrollStartFrom)}
              maxDate={new Date()}
              showMonthYearPicker
              placeholderText="Select Month"
            />
            </div>

          </div>
      </div>



      <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end mb-4 '>

      <div className="space-y-2">
    <label>Payment Name</label>
    <InputGroup>
            <Select
              isRequired
              value={AdhocPaymentName}
              onChange={(e) => {
                setAdhocPaymentName(e.target.value);
              }}
              borderColor="gray.400"
              placeholder="Select Payment Name"
            >
              {AdhocPaymentMasterList.map((data, i) => {
                return (
                  <option key={i} value={data.adhocPaymentId}>
                    {data.adhocPaymentName}
                  </option>
                );
              })}
            </Select>
            <Tooltip
              rounded="5px"
              hasArrow
              label="Add Payment Name"
              bg={'black'}
              color="white"
            >
              <InputRightAddon
                onClick={onAdhocPaymentMasterAddOpen}
                className="cursor-pointer"
                bg="purple.400"
                children={
                  <FontAwesomeIcon
                    className="text-white"
                    icon={faPlus}
                  />
                }
              />
            </Tooltip>
          </InputGroup>
</div>


<div className="space-y-2">
    <label>Import Data for Month</label>
    <DatePicker
      required
        className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2   outline-none"
        selected={ImportDataMonth}
        onChange={(date) => {
          setImportDataMonth(date);
          GetAdhocPaymentList(new Date(date).getMonth()+1,new Date(date).getFullYear());
        }}
        dateFormat="MM/yyyy"
        minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
        maxDate={new Date()}
        showMonthYearPicker
        placeholderText="Select Month"
      />
  </div>

        <Button onClick={()=>{
            let PaymentRowData = EmpList.map((data)=>{
                return {
                    employeeName : data.fullName,
                    empCode : data.empCode,
                    empGuId : data.guId,
                    adhocPaymentId : AdhocPaymentName,
                    adhocPaymentAmount : 0,
                    month : new Date(ImportDataMonth).getMonth()+1,
                    year : new Date(ImportDataMonth).getFullYear(),
                    companyId : userData.companyId,
                    branchId : userData.isActiveBranch,

                  }
            })
            setAddPaymentRowData(PaymentRowData);
            onAddAdhocPaymentOpen();
        }} isDisabled={AdhocPaymentName === undefined ? true : false} colorScheme='purple' ><FontAwesomeIcon icon={faPlus} className='mr-2'/>Add Payment</Button>
        <Button  onClick={()=>{
            let PaymentRowData = EmpList.map((data)=>{
                return {
                    employeeName : data.fullName,
                    empCode : data.empCode,
                    empGuId : data.guId,
                    adhocPaymentId : AdhocPaymentName,
                    adhocPaymentAmount : 0,
                    month : new Date(ImportDataMonth).getMonth()+1,
                    year : new Date(ImportDataMonth).getFullYear(),
                    companyId : userData.companyId,
                    branchId : userData.isActiveBranch
                  }
            })
            setAddPaymentRowData(PaymentRowData);
            onImportAdhocPaymentOpen();
        }} isDisabled={AdhocPaymentName === undefined ? true : false} colorScheme='purple' ><FontAwesomeIcon icon={faFileImport} className='mr-2'/>Import Payments</Button>
      </Box>

      
      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>


      <Modal isOpen={isAdhocPaymentMasterAddOpen} onClose={onAdhocPaymentMasterAddClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Payment</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={SavePaymentMaster}>
          <ModalBody className="space-y-4">

          <div className="space-y-2">
                <label>Payment Name</label>
                <Input value={PaymentMasterName} onChange={({target})=>{setPaymentMasterName(target.value)}} borderColor='gray.500' placeholder="Payment Name" />
            </div>

            <div className="space-y-2">
                <label>Payment Type</label>
                <Select
                  isRequired
                  value={PaymentMasterType}
                  onChange={(e) => {
                    setPaymentMasterType(e.target.value);
                  }}
                  borderColor="gray.500"
                  placeholder="Select Payment Type"
                >
                      <option  value='1'>
                      Re-Accuring
                      </option>
                      <option  value='2'>
                      Non Re-Accuring
                      </option>
                      
                </Select>
                        
                </div>

          </ModalBody>
          <hr />
          <ModalFooter>
            <Button type="submit" variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={onAdhocPaymentMasterAddClose} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>





      <Modal scrollBehavior="inside" size='full' isOpen={isAddAdhocPaymentOpen} onClose={onAddAdhocPaymentClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Adhoc Payment</ModalHeader>
          <ModalCloseButton />
          <hr />

          <ModalBody>
            <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Payment Name</p>
                <h6 className="font-medium">{ _.compact(AdhocPaymentMasterList.map((data)=>{
    if(data.adhocPaymentId === Number(AdhocPaymentName)){
       return  data.adhocPaymentName
    }
   }))[0]}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Month</p>
                <h6 className="font-medium">{monthNames[new Date(ImportDataMonth).getMonth()]}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Year</p>
                <h6 className="font-medium">{new Date(ImportDataMonth).getFullYear()}</h6>
              </div>
            </div>

            <hr />

            <div className="ag-theme-alpine mt-2">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={AddPaymentRowData} // Row Data for Rows
                columnDefs={AdhocPaymentColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
            </div>



          </ModalBody>
          <hr />
          <ModalFooter>
            <Button onClick={()=>{SaveTableAdhocPayments()}} variant='solid' colorScheme='purple' mr={4}>
              Save
            </Button>
            <Button variant='outline' onClick={onAddAdhocPaymentClose} colorScheme='purple' >
              Cancel
            </Button>

          </ModalFooter>

        </ModalContent>
      </Modal>



      <Modal size='full' isOpen={isImportAdhocPaymentOpen} onClose={onImportAdhocPaymentClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Adhoc Payment Data</ModalHeader>
          <ModalCloseButton onClick={()=>{setImportedPaymentData([])}} />
          <hr />
            <ModalBody>
              <div className='mt-4 flex items-end gap-5'>

              <div className="space-y-1">
                <p className="text-sm text-gray-500">Payment Name</p>
                <h6 className="font-medium">{ _.compact(AdhocPaymentMasterList.map((data)=>{
    if(data.adhocPaymentId === Number(AdhocPaymentName)){
       return  data.adhocPaymentName
    }
   }))[0]}</h6>
              </div>

               <CSVLink data={AddPaymentRowData} headers={ImportPaymentExcelHeads} filename={"Import Payment.csv"}>
                 <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Payment Import</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                ImportedPaymentReadExcel(file);
              }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(ImportedPaymentData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setImportedPaymentData([]);
                    onImportAdhocPaymentClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default FinalAdhocPayment