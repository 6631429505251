import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { Button,Switch,useToast, Select, Box, Checkbox } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import _ from 'lodash';
import ReactSelect from 'react-select'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

function EmailConfig() {

    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = "toast";

    const [ConfigId, setConfigId] = useState(0);
    const [EmailApplicable,setEmailApplicable] = useState(false);
    const [AllEmpList, setAllEmpList] = useState([]);
    const [CCSelectedEmp, setCCSelectedEmp] = useState([])
    const [DefaultSelectedEmp, setDefaultSelectedEmp] = useState([]);
    const [EmailTypeList, setEmailTypeList] = useState([]);

    const [EmailType,setEmailType] = useState('');
    const gridRef = useRef();
    const [rowData, setRowData] = useState([ ]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [ columnDefs ] = useState([
        {
          headerName: "Email Type",
          field: "emailType"
        },
        {
          headerName: "CC Email",
          field: "ccEmail"
        },
        {
          headerName: "Default Email",
          field: "defaultEmail"
        },
        {
          headerName: "IsActive",
          field: "isActive",
          cellRenderer: (params) => <Switch isChecked={params.data.isActive} onChange={({target})=>{HandleEmailSwitch(target.checked,params.data)}} colorScheme="green" />
        },
        {
          headerName: "Action",
          cellRenderer:(params) => <Button 
           onClick={()=>{
            HandleEdit(params.data);
           }}
           leftIcon={<FontAwesomeIcon icon={faEdit} />} colorScheme="blue" size={'xs'}>Edit</Button>
        }
      ]);


      const HandleEmailSwitch = async (checked,EmailData) => {

        setRowData((prev) => {
          const newState = prev.map((obj) => {
            if (obj.id === EmailData.id) {
              return { ...obj, isActive: checked };
            }
            return obj;
          });
          return newState;
        });


        let body ={
          id: EmailData.id,
          emailType : EmailData.emailType,
          ccEmail : EmailData.ccEmail,
          defaultEmail : EmailData.defaultEmail,
          isActive: checked,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch
        }

        console.log("Email switch Body",body);

        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Email/SaveEmailConfig`,body
        ).then((response)=>{
          console.log(response);

          if(!toast.isActive(id)){
            toast({
              id,
              title: response.data,
              position: 'top',
              status:'info',
              duration: 2000,
              isClosable: true
            })
          }

          GetEmailConfigList();

        }).catch((err)=>{
          console.error(err);
          if(!toast.isActive(id)){
            toast({
              id,
              title:"Server Error",
              position: 'top',
              status:'error',
              duration: 2000,
              isClosable: true
            })
          }
        });


        

      }

      const HandleEdit = async (EConfigData) => {

        console.log("Email Config Data",EConfigData);

        setConfigId(EConfigData.id)
        setEmailType(EConfigData.emailType);

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Emp List",response);
          const res = response.data;

          console.log("Edit CC EMp",res[res.findIndex(data => data.officeEmail === EConfigData.ccEmail)],res.findIndex(data => data.officeEmail === EConfigData.ccEmail));


          if(res[res.findIndex(data => data.officeEmail === EConfigData.ccEmail)] === undefined){
            alert('Employee Not Found in CC Email List.');
            setCCSelectedEmp([])
          }else{
            setCCSelectedEmp(res[res.findIndex(data => data.officeEmail === EConfigData.ccEmail)]);
          }


          if(res[res.findIndex(data => data.officeEmail === EConfigData.defaultEmail)] === undefined){
            alert('Employee Not Found in Default Email List.');
            setDefaultSelectedEmp([])
          }else{
            setDefaultSelectedEmp(res[res.findIndex(data => data.officeEmail === EConfigData.defaultEmail)]);
          }

        } catch (error) {
          console.error(error);
        }
      }
  
        // Table Pagination
        const paginationNumberFormatter = useCallback((params) => {
          return "[" + params.value.toLocaleString() + "]";
        }, []);
      
        //Table columns properties applies to all columns
        const defaultColDef = useMemo(() => ({
          flex: 1,
          sortable: true,
          filter: "agTextColumnFilter",
          floatingFilter: true,
          cacheQuickFilter: true,
          cellClass: 'no-border'
        }))

        useEffect(() => {
          GetEmailTypeList();
          GetEmailApplicable();
          GetEmailConfigList();
          GetAllEmpList();
        }, [])

        const GetEmailTypeList = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Email/GetEmailTypeList`
            )
            console.log("Email Type List",response);
            setEmailTypeList(response.data);
          } catch (err) {
            console.error(err);
          }
        }

        const GetAllEmpList = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Emp List",response);
            const res = _.sortBy(response.data, [(o) => -o.id]);
            setAllEmpList(res.filter(data => data.isActive === true))
          } catch (error) {
            console.error(error);
          }
        }

        const GetEmailApplicable = async () => {
          try { 
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Email/GetEmailStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log('Email Applicable Status',response);
            setEmailApplicable(response.data)
          }catch (err) {
            console.error(err);
          }
        }


        const GetEmailConfigList = async () => {
          try{
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Email/GetEmailConfigList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Email Config List",response);
            setRowData(response.data);
          }catch (err) {
            console.error(err);
          }
        }
      

        
        const SaveEmailApplicable = async (target) => {
          setEmailApplicable(target.checked)
          try{
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Email/ChangeEmailStatus?IsActive=${target.checked}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
            console.log("Change Status Response",response);
            GetEmailApplicable();
          }catch (err){
            console.error(err);
          }
        }


        const SaveEmailConfig = async (e) => {
          e.preventDefault();
          let body ={
            id:ConfigId,
            emailType : EmailType,
            ccEmail : CCSelectedEmp.officeEmail,
            defaultEmail : DefaultSelectedEmp.officeEmail,
            isActive: true,
            companyId : userData.companyId,
            branchId : userData.isActiveBranch
          }

          console.log("Email Config Body",body);
          await axios.post(
            `${process.env.REACT_APP_ACQ_URL}/api/Email/SaveEmailConfig`,body
          ).then((response)=>{
            console.log(response);

            if(!toast.isActive(id)){
              toast({
                id,
                title: response.data,
                position: 'top',
                status:'info',
                duration: 2000,
                isClosable: true
              })
            }

            setCCSelectedEmp([]);
            setDefaultSelectedEmp([]);
            setEmailType('');
            setConfigId(0);
            GetEmailConfigList();

          }).catch((err)=>{
            console.error(err);
            if(!toast.isActive(id)){
              toast({
                id,
                title:"Server Error",
                position: 'top',
                status:'error',
                duration: 2000,
                isClosable: true
              })
            }
          });

        }

  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Email Configuration</h1>
        <div className="bg-white px-2.5 py-2 rounded-xl">
          <Checkbox isChecked={EmailApplicable} onChange={({target})=>{SaveEmailApplicable(target)}} borderColor={'gray.400'}>E-mail Applicable?</Checkbox>
        </div>
      </div>

      {EmailApplicable ? <>

      <Box className="bg-white p-4 rounded-2xl drop-shadow-md mb-6">
        <div className="flex gap-6">
            <div className="space-y-2 w-1/4">
              <label>Email Type</label>
              <Select value={EmailType} onChange={({target})=>{setEmailType(target.value)}}  borderColor={"gray.400"} placeholder="Select Email Type">
                {EmailTypeList.map((data,i)=>{
                  return <option key={i} value={data.emailType}>{data.emailType}</option>
                })}
                {/* <option value={"Leave Apply"}>Leave Apply</option>
                <option value={"Leave Action"}>Leave Action</option>
                <option value={"Work From Home"}>Work From Home</option>
                <option value={"Absent"}>Absent</option>
                <option value={"Late Attendance"}>Late Attendance</option> */}
              </Select>
            </div>
            <div className="space-y-2 w-1/4">
              <label>CC To</label>
              <ReactSelect 
                menuPortalTarget={document.body} 
                styles={{
                  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                options={AllEmpList}
                value={CCSelectedEmp}
                onChange={(value) => {
                  setCCSelectedEmp(value);
                }}
                getOptionLabel={(option) =>
                  `${option.fullName} ${option.empCode}`
                }
                getOptionValue={(option) => option.guId}
                key= {(option)=> option.userId}
                placeholder="Select Employee for CC"
              />
            </div>
            <div className="space-y-2 w-1/4">
              <label>Default To</label>
              <ReactSelect
                menuPortalTarget={document.body} 
                styles={{
                  menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                  menu: provided => ({ ...provided, zIndex: 9999 })
                }}
                options={AllEmpList}
                value={DefaultSelectedEmp}
                onChange={(value) => {
                  setDefaultSelectedEmp(value);
                }}
                getOptionLabel={(option) =>
                  `${option.fullName} ${option.empCode}`
                }
                getOptionValue={(option) => option.guId}
                placeholder="Select Employee for Default"
                key={(option)=> option.userId}
              />
            </div>
            </div>


          <div className="text-right space-x-4">
            <Button onClick={()=>{
              setCCSelectedEmp([]);
              setDefaultSelectedEmp([]);
              setEmailType('');
              setConfigId(0);
            }} colorScheme="purple" variant={"outline"}>
              Reset
            </Button>
            <Button isDisabled={EmailType === '' ? true : false } onClick={SaveEmailConfig} colorScheme="purple">
              Submit
            </Button>
          </div>
      </Box>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div> 

      </> : ''}
    </div>
  );
}

export default EmailConfig