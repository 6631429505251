import React, { useState, useRef, useMemo, useCallback } from "react";
import { Button, Switch, useToast } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";

function PolicyList() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const gridRef = useRef();
  const [rowData, setRowData] = useState([ ]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Sr. No.",
      field: "id",
    },
    {
      headerName: "Policy Description",
      field: "policydescription",
    },
    {
      headerName: "Policy Attachment",
      field: "policyattachment",
    },
    {
      headerName: "Remarks",
      field: "remarks",
    },

    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      field: "guId",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button variant="solid" size="sm" colorScheme="blue">
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
          {/* <Button variant='solid' size='sm' colorScheme='red'>Reject</Button> */}
        </div>
      ),
    },
  ]);
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">
          Company Policy Doucment
        </h1>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default PolicyList;
