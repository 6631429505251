import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {
  Button,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Center, Spinner,
  Input
} from "@chakra-ui/react";
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import StatCard from "../UI Components/StatCard";
import { LuCalendarX,LuUserX,LuUser,LuUsers,LuDownload,LuEdit } from 'react-icons/lu';
import useValidation from "../Utils/useValidation";

function ViewAttendance() {

  const Validation = useValidation();
  const userData = JSON.parse(localStorage.getItem("user"));

  const monthNames = ["JANUARY","FEBRUARY","MARCH","APRIL","MAY","JUNE","JULY","AUGUST","SEPTEMBER","OCTOBER","NOVEMBER","DECEMBER",];
  
  const [IsLoading, setIsLoading] = useState(false);

  const CurrentDate = new Date().toLocaleDateString("en-CA");
  console.log("Current Date", CurrentDate);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const id = "toast";
  const [AttAction, setAttAction] = useState("");
  const [SelectedEmp, setSelectedEmp] = useState([]);
  const [AttInTime, setAttInTime] = useState();
  const [AttOutTime, setAttOutTime] = useState();
  const [IsPresent, setIsPresent] = useState(false);

  const [EditAttRemarks, setEditAttRemarks] = useState();

  const [AttendanceDate, setAttendanceDate] = useState(new Date());

  const [AttendanceStats, setAttendanceStats] = useState([]);

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
    },
    {
      headerName: "Employee Name",
      field: "empName",
    },
    {
      headerName: "Designation",
      field: "designationName",
    },
    {
      headerName: "Status",
      field: "attendanceStatus",
      cellRenderer: (params) =>
        params.data.attendanceStatus === "P" ? (
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            P
          </span>
        ) : params.data.attendanceStatus === "P*" ? (
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            P*
          </span>
        ) : params.data.attendanceStatus === "L" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            L
          </span>
        ) : params.data.attendanceStatus === "LWP" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            LWP
          </span>
        ) : params.data.attendanceStatus === "LOP" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            LOP
          </span>
        ) : params.data.attendanceStatus === "A" ? (
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            A
          </span>
        ) : params.data.attendanceStatus === "WO" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            WO
          </span>
        ) : params.data.attendanceStatus === "H" ? (
          <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            H
          </span>
        ) : params.data.attendanceStatus == "Not Join" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            NJ
          </span>
        ) : params.data.attendanceStatus == "Resign" ? (
          <span className="bg-pink-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            R
          </span>
        ) : (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.attendanceStatus}
          </span>
        ),
    },
    {
      headerName: "Attendance",
      field: "att_statusType",
      cellRenderer: (params) =>
        params.data.attendanceStatus === "P" ? (
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) 
        // : params.data.attendanceStatus === "" ? (
        //   <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        //     {params.data.att_statusType}
        //   </span>
        // ) 
        : params.data.attendanceStatus === "A" ? (
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.att_statusType === "Late" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "L" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) :params.data.attendanceStatus === "LOP" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) :params.data.attendanceStatus === "LWP" ? (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.att_statusType === "HD" ? (
          <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "WO" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "H" ? (
          <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "Not Join" ? (
          <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : params.data.attendanceStatus === "Resign" ? (
          <span className="bg-pink-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ) : (
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            {params.data.att_statusType}
          </span>
        ),
    },
    {
      headerName: "In-Time",
      field: "inTime",
    },
    {
      headerName: "Out-Time",
      field: "outTime",
    },
    {
      headerName: "Date",
      field: "attendanceDate",
      valueGetter: (params) =>
        new Date(params.data.attendanceDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "Device Name",
      field: "linkDevice",
    },
    {
      headerName: "Location",
      field: "workingLocation",
    },
    {
      headerName: "Action",
      cellRenderer: (params) => <Button
        leftIcon={<LuEdit />}
        onClick={() => {
          setSelectedEmp(params.data);
          onOpen();
        }}
        size={"xs"}
        colorScheme={"blue"}
      >
        Edit
      </Button>
    //     params.data.attendanceStatus === "A" ? (
    //         <Button
    //         leftIcon={<LuEdit />}
    //           onClick={() => {
    //             setSelectedEmp(params.data);
    //             onOpen();
    //           }}
    //           size={"xs"}
    //           colorScheme={"blue"}
    //         >
    //           Edit
    //         </Button>

    //     ) :  params.data.attendanceStatus === "L" ? (
    //       <Button
    //       onClick={async () => {
    //         await axios
    //           .post(
    //             `${process.env.REACT_APP_ACQ_URL}/api/Leave_Attendance/SaveLeaveAction?Id=${params.data.leaveApplyId}&LeaveAction=Cancelled`
    //           )
    //           .then((response) => {
    //             console.log("Leave Approve Cancel", response);
    //             let res = response.data;
    //             if (!toast.isActive(id)) {
    //               toast({
    //                 id,
    //                 title: res,
    //                 position: "top",
    //                 status: "info",
    //                 duration: 2000,
    //                 isClosable: true,
    //               });
    //             }
    //           }).catch((err)=>{
    //             console.error(err);
    //             if (!toast.isActive(id)) {
    //               toast({
    //                 id,
    //                 title: "Server Error",
    //                 position: "top",
    //                 status: "error",
    //                 duration: 2000,
    //                 isClosable: true,
    //               });
    //             }
    //           });
    //       }}
    //         size={"xs"}
    //         colorScheme={"blue"}
    //       >
    //        Mark LWP
    //       </Button>
    //   )  :   params.data.attendanceStatus === "P" ? (
    //     <Button
    //       onClick={() => {
    //         setSelectedEmp(params.data);
    //         onOpen();
    //       }}
    //       size={"xs"}
    //       colorScheme={"blue"}
    //     >
    //       Mark Half Day
    //     </Button>
    // ) : (
    //       ""
    //     ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true,
  }));

  useEffect(() => {
    ProcessAttendanceData(AttendanceDate);
  }, []);

  const ProcessAttendanceData = async (DATE) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Attendance/ProcessDailyAttendance?CompanyId=${
          userData.companyId
        }&BranchId=${
          userData.isActiveBranch
        }&Attendancedate=${moment(DATE).format('YYYY-MM-DD')}`
      );
      console.log("Process Attendance Response", response);
      GetDailyAttendanceData(DATE);
      GetDailyAttendanceStats(DATE);
    } catch (error) {
      console.error(error);
    }
  };

  const GetDailyAttendanceData = async (DATE) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Attendance/GetFinalAttendanceView?Attendancedate=${moment(DATE).format('YYYY-MM-DD')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Get Daily Attendance Response", response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetDailyAttendanceStats = async (DATE) => {
    try {
      const response = await axios.get(
        `${
          process.env.REACT_APP_ACQ_URL
        }/api/Attendance/GetDashBoardData?Attendancedate=${DATE.toLocaleDateString(
          "en-CA"
        )}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log(" Daily Attendance Stats Response", response);
      setAttendanceStats(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const ProcessAttendance = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/CloneAttendance?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Process Attendance List", response.data);
      toast({
        id,
        title: response.data,
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
      GetDailyAttendanceData(AttendanceDate);
      GetDailyAttendanceStats(AttendanceDate);
      ProcessAttendanceData(AttendanceDate);
    } catch (error) {
      console.error(error);
    }
  };

  function convertHMS(value) {
    const sec = parseInt(value, 10); // convert value to number if it's string
    let hours = Math.floor(sec / 3600); // get hours
    let minutes = Math.floor((sec - hours * 3600) / 60); // get minutes
    let seconds = sec - hours * 3600 - minutes * 60; //  get seconds
    // add 0 if value < 10
    if (hours < 10) {
      hours = "0" + hours;
    }
    if (minutes < 10) {
      minutes = "0" + minutes;
    }
    if (seconds < 10) {
      seconds = "0" + seconds;
    }
    return hours + ":" + minutes + ":" + seconds; // Return is HH : MM : SS
  }

  function convertToSeconds(hours, minutes) {
    return Number(hours) * 60 * 60 + Number(minutes) * 60;
  }

  const convertToTimeSpan = (time) => {
    let getTime = time;
    //  console.log("Get time",getTime);
    const [hours, minutes] = getTime.split(":");
    //  console.log("Hours",hours);
    // console.log("Minutes",minutes);
    let seconds = convertToSeconds(hours, minutes);
    //  console.log("Secconds",seconds);
    let ConvertedTime = convertHMS(seconds);
    //  console.log("Converted Time",ConvertedTime);
    return ConvertedTime;
  };

  const SaveEditAttendance = async (e) => {
    e.preventDefault();

    let body = {
      empGuId: SelectedEmp.empGuId,
      empName: SelectedEmp.empName,
      empCode: SelectedEmp.empCode,
      attendanceDate: SelectedEmp.attendanceDate,
      inTime: convertToTimeSpan(AttInTime),
      outTime: convertToTimeSpan(AttOutTime),
      attendanceStatus: AttAction === "Mark Present" ? "P" : AttAction === "Mark LWP" ? "LWP" : AttAction === "Mark LOP" ? "LOP" : AttAction === "Mark Half Day" ? "H" : '',
      linkDevice: SelectedEmp.linkDevice,
      remark: EditAttRemarks + '*',
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      att_statusType: AttAction === "Mark Present" ? "Present" : AttAction === "Mark LWP" ? "LWP" : AttAction === "Mark LOP" ? "LOP" : AttAction === "Mark Half Day" ? "Half Day" : 'Absent',
      lock: true,
      designation: SelectedEmp.designation,
      department: SelectedEmp.department
    };

    console.log("Edit Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/UpdateFinalAttandance`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetDailyAttendanceData(AttendanceDate);
        GetDailyAttendanceStats(AttendanceDate);
        // ProcessAttendanceData(AttendanceDate);

        setAttInTime("");
        setAttOutTime("");
        setIsPresent(false);
        onClose();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Employee Attendance Not Updated",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const exportData = () => {
    // let SelMonthName = MonthNames[new Date(SelectedDateMonth).getMonth()]
    const params = {
      fileName: `Attendance Report of ${userData.activeBranchName} ${moment(
        AttendanceDate
      ).format("YYYY-MM-DD")}.csv`,
    };

    gridRef.current.api.exportDataAsCsv(params);
  };

  const AttendanceHelper = async () => {
    setIsLoading(true)
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/CloneAttendance?AttendanceDate=${new Date(AttendanceDate).getFullYear()}-${new Date(AttendanceDate).getMonth()+1}-01&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Run Process Attendance Reponse",response);

      if(response.data === 'True') {
        toast({
          id,
          title: response.data,
          description: `Attendance Processed for Month ${monthNames[new Date(AttendanceDate).getMonth()]}`,
          position: "top",
          status: "success",
          duration: 5000,
          isClosable: true
        });
      }else{
        toast({
          id,
          title: response.data,
          position: "top",
          status: "info",
          duration: 5000,
          isClosable: true
        });
      }
    } catch (err) {
      console.error(err);
    }finally{
      setIsLoading(false);
      // alert(`Attendance Processed for Month ${monthNames[new Date(AttendanceDate).getMonth()]}`);
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/ProcessDailyAttendance?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Attendancedate=${moment(AttendanceDate).format('YYYY-MM-DD')}`
      );
      console.log("Process Attendance Response", response);
    } catch (error) {
      console.error(error);
    }
  }

  return IsLoading ? ( <Center>
  <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
</Center> ) : (
    <div>
      <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">View Attendance</h1>
        <div className="flex items-center gap-4">
          <label className="font-semibold text-lg mr-3">
            Attendance Date :
          </label>
          <div>
            <DatePicker 
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={AttendanceDate}
              onChange={(date) => {
                setAttendanceDate(date);
                ProcessAttendanceData(date);
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Attendance Date"
              minDate={Validation?.payrollMonth !== 0 ? new Date(Validation?.payrollYear,Validation?.payrollMonth,1) : new Date(Validation?.payrollStartFrom)}
              maxDate={new Date()}
            />
          </div>
          <div>
            <Button
              leftIcon={<LuDownload/>}
              onClick={() => {
                exportData();
              }}
              colorScheme="purple"
            >
              Export
            </Button>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-6 gap-6">
       <StatCard StatName = "Total Employees" StatNumber = {AttendanceStats.totalEmpCount} Description = {'-'} IconBg = {"green.100"} StatIcon ={<LuUsers className="text-green-600 text-2xl"/>} />
       <StatCard StatName = "Total Present" StatNumber = {AttendanceStats.totalPresent} Description = {'-'} IconBg = {"orange.100"} StatIcon ={<LuUser className="text-orange-600 text-2xl"/>} />
       <StatCard StatName = "Total Leaves" StatNumber = {AttendanceStats.totalLeave} Description = {'-'} IconBg = {"purple.100"} StatIcon ={<LuCalendarX className="text-purple-600 text-2xl"/>} />
       <StatCard StatName = "Total Absent" StatNumber = {AttendanceStats.totalAbsent} Description = {'-'} IconBg = {"red.100"} StatIcon ={<LuUserX className="text-red-600 text-2xl"/>} />
      </div>
{/*   <div className="text-right mb-4">
        <Button onClick={AttendanceHelper} leftIcon={<LuRefreshCw className="text-lg" />} colorScheme="purple" >Sync Attendance</Button>
      </div> */}

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          suppressExcelExport={true}
        />
      </div>

      <Modal size={'lg'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >Edit Attendance</ModalHeader>
          <ModalCloseButton className="mt-1.5" />
          <form onSubmit={SaveEditAttendance}>
            <ModalBody className="space-y-2 my-2">
              <div className="flex pb-1 gap-8">
                <div>
                  <p className="text-sm text-gray-500">Emp Code</p>
                  <h6 className="font-medium">{SelectedEmp.empCode}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Emp Name</p>
                  <h6 className="font-medium">{SelectedEmp.empName}</h6>
                </div>
                <div>
                  <p className="text-sm text-gray-500">Attendance Date</p>
                  <h6 className="font-medium">{moment(SelectedEmp.attendanceDate).format('DD/MM/YYYY')}</h6>
                </div>
              </div>
              <hr />

              <div className="grid grid-cols-2 gap-4">

        <div onClick={()=>{setAttAction("Mark Present")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark Present" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark Present</div>
            </div>
        </div>

        <div onClick={()=>{setAttAction("Mark LWP")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark LWP" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark LWP</div>
            </div>
        </div>

        <div onClick={()=>{setAttAction("Mark LOP")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark LOP" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark LOP</div>
            </div>
        </div>

        {/* <div onClick={()=>{setAttAction("Mark Half Day")}} 
        className={`inline-flex items-center justify-between w-full px-5 py-2 text-gray-500 bg-white border border-gray-400 rounded-lg cursor-pointer ${AttAction === "Mark Half Day" ? 'border-blue-600 border-2 text-blue-600 bg-blue-100' : ''}  hover:text-blue-600 hover:bg-blue-100 `}>                           
            <div className="block">
                <div className="w-full font-medium">Mark Half Day</div>
            </div>
        </div> */}
              </div>

              {AttAction === "Mark Present" ? 
          <div className="grid grid-cols-2 gap-4">
            <div className="space-y-2">
              <label>In-Time</label>
              <Input
                value={AttInTime || ""}
                onChange={({ target }) => {
                  setAttInTime(target.value);
                }}
                borderColor={"gray.400"}
                type={"time"}
              />
            </div>
            <div className="space-y-2">
              <label>Out-Time</label>
              <Input
                value={AttOutTime || ""}
                onChange={({ target }) => {
                  setAttOutTime(target.value);
                }}
                borderColor={"gray.400"}
                type={"time"}
              />
            </div>
          </div> 
        : '' }

        <div className="space-y-2">
          <label>Reason</label>
          <Input value={EditAttRemarks} onChange={({target})=>{setEditAttRemarks(target.value)}} borderColor={'gray.400'} placeholder="Enter Reason" />
        </div>

          {/* <Checkbox
                isChecked={IsPresent}
                onChange={({ target }) => {
                  setIsPresent(target.checked);
                }}
                borderColor={"gray.400"}
              >
                Is Present
              </Checkbox> */}

              {/* <div className="space-y-2">
                <label>Attendance Status</label>
                <Select borderColor={'gray.400'} placeholder='Select Attendance Status'>
                  <option>Absent</option>
                  <option>Present</option>
                  <option>On Leave</option>
                </Select>
              </div> */}
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                variant={"outline"}
                colorScheme="purple"
                mr={3}
                onClick={onClose}
              >
                Close
              </Button>
              <Button type="submit" colorScheme={"purple"} variant="solid">
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default ViewAttendance;
