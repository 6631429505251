import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, useToast,  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton,  useDisclosure, Select } from "@chakra-ui/react";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faPlus } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import DatePicker from "react-datepicker";

function InductionInfo()  {
  
    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = "toast";
    const [EmployeeList, setEmployeeList] = useState([]);

    const [SelectedEmp, setSelectedEmp] = useState([]);

    const [InductionCompleteDate, setInductionCompleteDate] = useState();
    const [InductionGivenBy, setInductionGivenBy] = useState();


    const { isOpen: isAddInductionOpen, onOpen: onAddInductionOpen, onClose: onAddInductionClose } = useDisclosure()

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [ columnDefs ] = useState([
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Employee Name",
          field: "fullName",
        },

        {
          headerName: "Designation",
          field: "designationName",
        },
        {
          headerName: "Department",
          field: "departmentName",
        },
        {
          headerName: "Date of Joining",
          field: "dateofjoin",
          cellRenderer:(params) =><span>
            {moment(params.data.dateofjoin).format('DD/MM/YYYY')}
          </span>
        },
        {
          headerName: "Office Email",
          field: "officeEmail",
        },
        {
          headerName: "Induction Status",
          field:"empInductionStatus",
          cellRenderer:(params) => (
            params.data.empInductionStatus === true ? 
            <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
              Completed
            </span> :
            <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
              Pending
            </span>
          )
        },
        {
          headerName: "Action",
          cellRenderer: (params) =>(
            params.data.empInductionStatus === false ?
            <Button
           onClick={()=>{
            setSelectedEmp(params.data);
            onAddInductionOpen();
          }} size={'xs'} colorScheme={'purple'}><FontAwesomeIcon icon={faPlus} className='mr-2' />Confirm</Button>
          :
          <Button
           onClick={()=>{
            setSelectedEmp(params.data);
            HandleEdit(params.data.guId,params.data.dateofjoin);
          }} size={'xs'} colorScheme={'blue'}><FontAwesomeIcon icon={faEdit} className='mr-2' />Edit</Button>
          )
        }
      ]);


      const HandleEdit = async (EmployeeGuid,DateOfJoin) => {

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeInductionDetailByGuid?EmpGuid=${EmployeeGuid}`
          )
          console.log("Emp Induction Details",response);
          const res = response.data;
          setInductionCompleteDate(res.inductionDate !== "0001-01-01T00:00:00" ? new Date(res.inductionDate) : new Date(DateOfJoin));
          setInductionGivenBy(res.inductionGivenBy);
          onAddInductionOpen();
        } catch (error) {
          console.error(error)
        }

      }
  
            // Table Pagination
            const paginationNumberFormatter = useCallback((params) => {
              return "[" + params.value.toLocaleString() + "]";
            }, []);
          
            //Table columns properties applies to all columns
            const defaultColDef = useMemo(() => ({
              flex: 1,
              sortable: true,
              filter: "agTextColumnFilter",
              floatingFilter: true,
              cacheQuickFilter: true,
              cellClass: 'no-border',
              resizable: true
            }));

            useEffect(() => {
                getEmpList();
            }, [])
            
  
  
            const getEmpList = async () => {
                try {
                  const response = await axios.get(
                    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                  )
                  console.log(response);

                  const res = response.data;

                  const ActiveEMP = res.filter((data) => data.isActive === true)
                  setRowData(ActiveEMP);
                  setEmployeeList(response.data);
                } catch (error) {
                  console.error(error);
                }
              }
  

              const SaveEmpInductionDetails = async(e)=>{
                e.preventDefault();
        
                let body ={
                  GuId: SelectedEmp.guId,
                  InductionDate: moment(InductionCompleteDate).format('YYYY-MM-DD'),
                  InductionGivenBy: InductionGivenBy,
                  CompanyId: userData.companyId,
                  BranchId: userData.isActiveBranch
                }
                
                console.log("Emp Induction Save Body",body);
        
        
                await axios.post(
                  `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeInductionDetail`,body
                )
                .then((response) => {
                  console.log(response);
                  const res = response.data;
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title:res,
                      position: "top",
                      status: "success",
                      duration: 2000,
                      isClosable: true,
                    });
                  }
        
                  getEmpList();
                  onAddInductionClose();
            
                })
                .catch((error) => {
                  console.error(error);
        
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: "ERROR",
                      description: `Details not Added`,
                      position: "top",
                      status: "error",
                      duration: 2000,
                      isClosable: true,
                    });
                  }
                });
        
        
              }


    return (
      <div>

        <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
          <h1 className="text-xl font-bold text-gray-800">Employee Induction</h1>
          {/* <Button onClick={onAddInductionOpen} size={'sm'} colorScheme={'purple'}><FontAwesomeIcon icon={faPlus} className='mr-2' /> Add Induction</Button> */}
        </div>
  
        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>


        <Modal size={'2xl'} isOpen={isAddInductionOpen} onClose={()=>{
          setInductionGivenBy('');
          setInductionCompleteDate('');
          onAddInductionClose();
        }}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Employee Induction</ModalHeader>
          <ModalCloseButton mt={1} />
          <form onSubmit={SaveEmpInductionDetails}>
          <ModalBody>
            <div className="flex gap-6 pb-2">
                <div className="space-y-1">
                    <label className="text-sm text-gray-500">Employee Name</label>
                    <h6 className="font-medium">{SelectedEmp.fullName}</h6>
                </div>

                <div className="space-y-2">
                    <label className="text-sm text-gray-500">Employee Code</label>
                    <h6 className="font-medium">{SelectedEmp.empCode}</h6>
                </div>

                <div className="space-y-2">
                    <label className="text-sm text-gray-500">Designation</label>
                    <h6 className="font-medium">{SelectedEmp.designationName}</h6>
                </div>

                <div className="space-y-2">
                    <label className="text-sm text-gray-500">Department</label>
                    <h6 className="font-medium">{SelectedEmp.departmentName}</h6>
                </div>

                
                <div className="space-y-2">
                    <label className="text-sm text-gray-500">Date of Join</label>
                    <h6 className="font-medium">{moment(SelectedEmp.dateofjoin).format('DD/MM/YYYY')}</h6>
                </div>

            </div>
            <hr />
            <div className="grid grid-cols-2 gap-6 my-4">
              <div className="space-y-2">
                <label>Induction Date</label>
                {/* <Input value={InductionCompleteDate || new Date()} onChange={({target})=>{setInductionCompleteDate(target.value)}}  borderColor={'gray.500'} type={'date'} /> */}
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                  selected={InductionCompleteDate}
                  onChange={(date) =>{
                    setInductionCompleteDate(date); 
                  }}
                  placeholderText="Date of Joining"
                  dateFormat={"dd-MM-yyyy"}
                />
              </div>
              
              <div className="space-y-2">
                <label>Induction Given By</label>
                <Select value={InductionGivenBy} onChange={({target})=>{setInductionGivenBy(target.value)}} borderColor={'gray.500'} placeholder='Select Induction Given By'>
                 {EmployeeList.filter(data => data.departmentName === 'HR' || data.departmentName === 'Human Resources').map((data,i)=>{
                  return <option key={i} value={data.id}>{data.firstName} {data.lastName}</option>
                 })}
                </Select>
              </div>
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme={'purple'} variant={'outline'} mr={3} onClick={()=>{
          setInductionGivenBy('');
          setInductionCompleteDate('');
          onAddInductionClose();
        }}>
              Close
            </Button>
            <Button type="submit" colorScheme={'purple'} variant={'solid'}>Save</Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
      </div>
    )
  }

export default InductionInfo