import { Button, Spinner } from "@chakra-ui/react";
import { Page, Text, View, Document, StyleSheet, BlobProvider, Image } from "@react-pdf/renderer";
import axios from "axios";
import numeral from "numeral";
import numberToWords from 'number-to-words';

const monthNames = ["January","February","March","April","May","June","July","August","September","October","November","December"];
const userData = JSON.parse(localStorage.getItem("user"));

const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        paddingTop: 35,
        paddingLeft:25,
        paddingRight:25,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    imgLogo:{
        height:70,
        width:110,
    }
  });
  const NumberFormat = (value) => {
    return numeral(value).format('0,0.00')
  }


const SavePdf = async (EmpData, pdfBlob) => {
    // console.log("Save PDf data", pdfBlob);
   const SalarySlipData = new FormData();
   SalarySlipData.append("file", pdfBlob, `${EmpData.employeeName}.pdf`);
   SalarySlipData.append("empGuId", EmpData.empGuid);
   SalarySlipData.append("empName", EmpData.employeeName);
   SalarySlipData.append("month", EmpData.salaryMonth);
   SalarySlipData.append("year", EmpData.salaryYear);
   SalarySlipData.append("isActive", true);
   SalarySlipData.append("companyId", EmpData.companyId);
   SalarySlipData.append("branchId", EmpData.branchId);
 
   await axios
     .post(
       `${process.env.REACT_APP_ACQ_URL}/api/EmpMonthlySalarySlip/SaveEmpMonthlySalarySlip`,
       SalarySlipData
     )
     .then((response) => {
       console.log("PDF Save Response", response);
     })
     .catch((err) => {
       console.error(err);
     });
 };

 const SalarySlip = ({ data, BranchLogo }) => {
    // console.log("Dynamic Slip Data",data)
    const EmpSalarySlipData = data;

    return userData.countryName === "India" ? <Document>
     <Page size="A4"  style={styles.page}>

         {userData.activeBranchName === "HR Muster" || userData.companyName === "HR Muster"?   
         <View style={{flexDirection:"row",justifyContent:"space-between",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
          <View style={{width:'50%'}}>
            {BranchLogo !== undefined ? 
           <Image style={{width:220}} src={BranchLogo} /> 
           : <Text>Logo</Text>}
          </View>
         </View> 
         :  
         <View style={{flexDirection:"row",justifyContent:"space-between",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
          <View style={{width:'50%'}}>
            {BranchLogo !== undefined ? 
           <Image style={{width:110}} src={BranchLogo} /> 
           : <Text>Logo</Text>}
          </View>
          <View style={{width:'40%'}}>
               <Text style={{marginBottom:2,fontSize:13,fontWeight:'extrabold',fontFamily:'Helvetica-Bold'}}>{userData.companyName}</Text>
               <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.address1} {userData.address2}</Text>
               <Text style={{fontSize:9,fontWeight:'thin'}}>{userData.stateName}</Text>
           </View>
         </View>}
    
       {/* Employee Details */}
    
       <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:8,borderBottom:1, borderBottomColor:'#333333'}}>
         <View style={{width:'60%',flexWrap:'wrap'}}>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Code : {EmpSalarySlipData.empCode}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Designation : {EmpSalarySlipData.designationName}</Text>
         {/* <Text style={{marginBottom:'5px',fontSize: 10,}}>Division : {EmpSalarySlipData.division}</Text> */}
         <Text style={{fontSize:10}}>Present Days : {EmpSalarySlipData.totalPresent}</Text>
    
         </View>
    
         <View style={{width:'40%',flexWrap:'wrap'}}>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Department : {EmpSalarySlipData.departmentName}</Text>
         <Text style={{marginBottom:'5px',fontSize: 10,}}>Currency : {userData.currencyCode}</Text>
         <Text style={{marginBottom:'5px',fontSize:10,}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} - {EmpSalarySlipData.salaryYear}</Text>
         {/* <Text style={{fontSize:10}}>Grade : {EmpSalarySlipData.grade}</Text> */}
         </View>
       </View>
    
    
       {/* Salary Component Details Table */}
    
    
       <View style={{flexDirection:'row',border:1,marginTop:'8px',borderRadius:'5px'}}>
    
       <View style={{width:'50%',borderRight:1,flexDirection:'column',justifyContent:'flex-start'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,width:'40%'}}>Earnings</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Actual</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Earned</Text>
    
        </View>
    
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
          {EmpSalarySlipData?.salarySlipComponents?.filter((data) => data.componentType === 1).map((data,i)=>{
            return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
              <Text style={{fontSize:9,width:'30%'}}>{data.salarySlipLabel}</Text>
              <Text style={{fontSize:9}}>{NumberFormat(data.actualSalaryValue)}</Text>
    
              <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
              </View>
          })}
    
         </View>
    
         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Earnings</Text>
        <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.grossSalary)}</Text>
    
        </View>
    
    
         </View>
    
         <View style={{width:'50%',flexDirection:'column',justifyContent:'flex-start'}}>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Deductions</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
        </View>
    
         <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
         {EmpSalarySlipData?.salarySlipComponents?.filter((data) => data.componentType === 2).map((data,i)=>{
     return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>{data.salarySlipLabel}</Text>
       <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
       </View>
    })}


<View style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>PF</Text>
       <Text style={{fontSize:9}}>{NumberFormat(EmpSalarySlipData.pF_Employee)}</Text>
       </View>

       <View style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>ESI</Text>
       <Text style={{fontSize:9}}>{NumberFormat(EmpSalarySlipData.esI_Employee)}</Text>
       </View>

       <View style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
       <Text style={{fontSize:9}}>TDS</Text>
       <Text style={{fontSize:9}}>{NumberFormat(EmpSalarySlipData.empTDSAmount)}</Text>
       </View>
    
         </View>
    
         <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Deduction</Text>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalDeduction)}</Text>
    </View>
    
    
    
         </View>
    
       </View>
    
    
       <View style={{flexDirection:'row',marginTop:'10px'}}>
    
    
    <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Other Payment</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
    
        </View>
        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocPaymentRemark}</Text>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocPayment)}</Text>
    
        </View>
    
    
  
    </View>
    
    
    </View>
    
    <View style={{width:'49%',border:1,borderRadius:'5px'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Other Deduction</Text>
        <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>
        </View>

        <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocDeductionRemark}</Text>
        <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocDeduction)}</Text>
    
        </View>
    
    
    
    
    </View>
    
    
    </View>
    
    
    
    
    </View>
    
       
    
    
        {/* Total Calculation Rows  */}
    
    
        <View style={{flexDirection:'row',marginTop:'10px'}}>
    
    
    <View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>
    
    <View style={{flexDirection:'column',justifyContent:'space-between'}}>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,fontFamily:'Helvetica-Bold'}}>Payment Info</Text>
    </View>
    
    <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Payment Type</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.paymentType}</Text>
    </View>
    
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Account Number</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.accountNumber}</Text>
    </View>
    
    <View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
    <Text style={{fontSize:9}}>Bank Name</Text>
    <Text style={{fontSize:9}}>{EmpSalarySlipData.bankName}</Text>
    </View>
    
    
    
    </View>
    
    
    
    
    </View>
    
    
    </View>
    
    <View style={{width:'49%'}}>
    <View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>
    
    {/* <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10}}>Working Days</Text>
    <Text style={{fontSize: 10}}>{EmpSalarySlipData.workingdays}</Text>
    </View> */}
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Earnings</Text>
    <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.netSalary)}</Text>
    
    </View>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Deductions</Text>
    <Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.finalDeduction)}</Text>
    
    </View>
    
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize:10,fontFamily:'Helvetica-Bold'}}>Net Pay ({userData.currencyCode})</Text>
    <Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.netPayableSalary)}</Text>
    </View>
    
    <View style={{flexDirection:'row',alignItems:'center', flexWrap:'wrap',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
    <Text style={{fontSize:10,fontFamily:'Helvetica-Bold',marginRight:'4px'}}>In Words:</Text>
    <Text style={{fontSize:8 }}>{numberToWords.toWords(EmpSalarySlipData.netPayableSalary || 0).toString().toUpperCase() + " ONLY"}</Text>
    </View>
    
    </View>
    </View>
    
    </View>
        
     </Page>
     </Document> : userData.activeBranchName === "G42" || userData.activeBranchName ==="G42 XFUND"? <Document>
 <Page size="A4"  style={styles.page}>
       
       {/* Company Details Header */}
  <View style={{flexDirection:"row",justifyContent:"center",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
    <View>
      {BranchLogo !== undefined ? 
     <Image style={{width:110}} src={BranchLogo} /> 
     : <Text>Logo</Text>}
    </View>
   </View>

   {/* Employee Details */}

   <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:8,borderBottom:1, borderBottomColor:'#333333'}}>
     <View style={{width:'60%',flexWrap:'wrap'}}>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Employee ID : {EmpSalarySlipData.empCode}</Text>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Designation : {EmpSalarySlipData.designationName}</Text>
     <Text style={{marginBottom:'5px',fontSize: 10,}}>Division : {EmpSalarySlipData.division}</Text>
     <Text style={{fontSize:10}}>Present Days : {EmpSalarySlipData.totalPresent}</Text>

     </View>

     <View style={{width:'40%',flexWrap:'wrap'}}>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Department : {EmpSalarySlipData.departmentName}</Text>
     <Text style={{marginBottom:'5px',fontSize: 10,}}>Currency : {userData.currencyCode}</Text>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} - {EmpSalarySlipData.salaryYear}</Text>
     <Text style={{fontSize:10}}>Grade : {EmpSalarySlipData.grade}</Text>
     </View>
   </View>


   {/* Salary Component Details Table */}


   <View style={{flexDirection:'row',border:1,marginTop:'8px',borderRadius:'5px'}}>

   <View style={{width:'50%',borderRight:1,flexDirection:'column',justifyContent:'flex-start'}}>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,width:'40%'}}>Earnings</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Actual</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Earned</Text>

    </View>

     <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
      {EmpSalarySlipData?.salarySlipComponents?.filter((data) => data.componentType === 1).map((data,i)=>{
        return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
          <Text style={{fontSize:9,width:'30%'}}>{data.salarySlipLabel}</Text>
          <Text style={{fontSize:9}}>{NumberFormat(data.actualSalaryValue)}</Text>

          <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
          </View>
      })}

     </View>

     <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Earnings</Text>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.grossSalary)}</Text>

    </View>


     </View>

     <View style={{width:'50%',flexDirection:'column',justifyContent:'flex-start'}}>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Additions</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

    </View>

     <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
     {EmpSalarySlipData?.salarySlipComponents?.filter((data) => data.componentType === 4).map((data,i)=>{
 return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
   <Text style={{fontSize:9}}>{data.salarySlipLabel}</Text>
   <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
   </View>
})}

     </View>

     <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
<Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Additions</Text>
<Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalAddition)}</Text>
</View>



     </View>

   </View>


   <View style={{flexDirection:'row',marginTop:'10px'}}>


<View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Payment</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

    </View>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
    <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocPaymentRemark}</Text>
    <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocPayment)}</Text>

    </View>




</View>


</View>

<View style={{width:'49%',border:1,borderRadius:'5px'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Deduction</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

    </View>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
    <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocDeductionRemark}</Text>
    <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocDeduction)}</Text>

    </View>




</View>


</View>




</View>

   


    {/* Total Calculation Rows  */}


    <View style={{flexDirection:'row',marginTop:'10px'}}>


<View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>
<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,fontFamily:'Helvetica-Bold'}}>Payment Info</Text>
</View>

<View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Payment Type</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.paymentType}</Text>
</View>

<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Account Number</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.accountNumber}</Text>
</View>

<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Bank Name</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.bankName}</Text>
</View>



</View>




</View>


</View>

<View style={{width:'49%'}}>
<View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>

{/* <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10}}>Working Days</Text>
<Text style={{fontSize: 10}}>{EmpSalarySlipData.workingdays}</Text>
</View> */}

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Earnings</Text>
<Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.netSalary)}</Text>

</View>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Deductions</Text>
<Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.finalDeduction)}</Text>

</View>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize:10,fontFamily:'Helvetica-Bold'}}>Net Pay ({userData.currencyCode})</Text>
<Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.netPayableSalary)}</Text>
</View>

<View style={{flexDirection:'row',alignItems:'center', flexWrap:'wrap',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
<Text style={{fontSize:10,fontFamily:'Helvetica-Bold',marginRight:'4px'}}>In Words:</Text>
<Text style={{fontSize:8 }}>{numberToWords.toWords(EmpSalarySlipData.netPayableSalary || 0).toString().toUpperCase() + " ONLY"}</Text>
</View>

</View>
</View>




</View>




 </Page>
 </Document> : <Document>
 <Page size="A4"  style={styles.page}>
       
       {/* Company Details Header */}
  <View style={{flexDirection:"row",justifyContent:"center",paddingBottom:10,borderBottom:1, borderBottomColor:'#333333',alignItems:'center'}} >
    <View>
      {BranchLogo !== undefined ? 
     <Image style={{width:110}} src={BranchLogo} /> 
     : <Text>Logo</Text>}
    </View>
   </View>

   {/* Employee Details */}

   <View style={{flexDirection:"row",justifyContent:"space-between" ,paddingTop:10,paddingBottom:8,borderBottom:1, borderBottomColor:'#333333'}}>
     <View style={{width:'60%',flexWrap:'wrap'}}>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Employee Name : {EmpSalarySlipData.employeeName}</Text>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Employee ID : {EmpSalarySlipData.empCode}</Text>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Designation : {EmpSalarySlipData.designationName}</Text>
     <Text style={{marginBottom:'5px',fontSize: 10,}}>Division : {EmpSalarySlipData.division}</Text>
     <Text style={{fontSize:10}}>Present Days : {EmpSalarySlipData.totalPresent}</Text>

     </View>

     <View style={{width:'40%',flexWrap:'wrap'}}>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Department : {EmpSalarySlipData.departmentName}</Text>
     <Text style={{marginBottom:'5px',fontSize: 10,}}>Currency : {userData.currencyCode}</Text>
     <Text style={{marginBottom:'5px',fontSize:10,}}>Pay Slip Month : {monthNames[EmpSalarySlipData.salaryMonth-1]} - {EmpSalarySlipData.salaryYear}</Text>
     <Text style={{fontSize:10}}>Grade : {EmpSalarySlipData.grade}</Text>
     </View>
   </View>


   {/* Salary Component Details Table */}


   <View style={{flexDirection:'row',border:1,marginTop:'8px',borderRadius:'5px'}}>

   <View style={{width:'50%',borderRight:1,flexDirection:'column',justifyContent:'flex-start'}}>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,width:'40%'}}>Earnings</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Actual</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Earned</Text>

    </View>

     <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
      {EmpSalarySlipData?.salarySlipComponents?.filter((data) => data.componentType === 1).map((data,i)=>{
        return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
          <Text style={{fontSize:9,width:'30%'}}>{data.salarySlipLabel}</Text>
          <Text style={{fontSize:9}}>{NumberFormat(data.actualSalaryValue)}</Text>

          <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
          </View>
      })}

     </View>

     <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Earnings</Text>
    <Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.grossSalary)}</Text>

    </View>


     </View>

     <View style={{width:'50%',flexDirection:'column',justifyContent:'flex-start'}}>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Additions</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

    </View>

     <View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
     {EmpSalarySlipData?.salarySlipComponents?.filter((data) => data.componentType === 4).map((data,i)=>{
 return <View key={i} style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
   <Text style={{fontSize:9}}>{data.salarySlipLabel}</Text>
   <Text style={{fontSize:9}}>{NumberFormat(data.salaryValue)}</Text>
   </View>
})}

     </View>

     <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',borderTop:1,paddingTop:'5px',marginTop:'auto'}}>
<Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>Total Additions</Text>
<Text style={{marginBottom:'3px',fontSize: 10,paddingRight:7,paddingLeft:7}}>{NumberFormat(EmpSalarySlipData.totalAddition)}</Text>
</View>



     </View>

   </View>


   <View style={{flexDirection:'row',marginTop:'10px'}}>


<View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Payment</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

    </View>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
    <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocPaymentRemark}</Text>
    <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocPayment)}</Text>

    </View>




</View>


</View>

<View style={{width:'49%',border:1,borderRadius:'5px'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Deduction</Text>
    <Text style={{fontSize: 10,paddingRight:7,paddingLeft:7}}>Amount</Text>

    </View>
    <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px'}}>
    <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,width:'70%'}}>{EmpSalarySlipData.adhocDeductionRemark}</Text>
    <Text style={{fontSize: 9,paddingRight:7,paddingLeft:7,marginTop:'auto'}}>{NumberFormat(EmpSalarySlipData.adhocDeduction)}</Text>

    </View>




</View>


</View>




</View>

   


    {/* Total Calculation Rows  */}


    <View style={{flexDirection:'row',marginTop:'10px'}}>


<View style={{width:'49%',border:1,borderRadius:'5px',marginRight:'2%'}}>

<View style={{flexDirection:'column',justifyContent:'space-between'}}>
<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,paddingRight:7,paddingLeft:7,fontFamily:'Helvetica-Bold'}}>Payment Info</Text>
</View>

<View style={{paddingRight:7,paddingLeft:7,paddingTop:'5px'}}>
<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Payment Type</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.paymentType}</Text>
</View>

<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Account Number</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.accountNumber}</Text>
</View>

<View  style={{marginBottom:'5px',flexDirection:'row',justifyContent:'space-between'}}>
<Text style={{fontSize:9}}>Bank Name</Text>
<Text style={{fontSize:9}}>{EmpSalarySlipData.bankName}</Text>
</View>



</View>




</View>


</View>

<View style={{width:'49%'}}>
<View style={{flexDirection:'column',border:1,borderRadius:'5px'}}>

{/* <View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10}}>Working Days</Text>
<Text style={{fontSize: 10}}>{EmpSalarySlipData.workingdays}</Text>
</View> */}

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Earnings</Text>
<Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.netSalary)}</Text>

</View>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize: 10,fontFamily:'Helvetica-Bold'}}>Total Deductions</Text>
<Text style={{fontSize: 10}}>{NumberFormat(EmpSalarySlipData.finalDeduction)}</Text>

</View>

<View style={{flexDirection:'row',alignContent:'center',justifyContent:'space-between',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px',borderBottom:1}}>
<Text style={{fontSize:10,fontFamily:'Helvetica-Bold'}}>Net Pay ({userData.currencyCode})</Text>
<Text style={{fontSize:10}}>{NumberFormat(EmpSalarySlipData.netPayableSalary)}</Text>
</View>

<View style={{flexDirection:'row',alignItems:'center', flexWrap:'wrap',paddingTop:'4px',paddingRight:7,paddingLeft:7,paddingBottom:'2px'}}>
<Text style={{fontSize:10,fontFamily:'Helvetica-Bold',marginRight:'4px'}}>In Words:</Text>
<Text style={{fontSize:8 }}>{numberToWords.toWords(EmpSalarySlipData.netPayableSalary || 0).toString().toUpperCase() + " ONLY"}</Text>
</View>

</View>
</View>




</View>




 </Page>
 </Document>
  }
 
 const DynamicSalarySlip = ({data , BranchLogo}) => (
   
    <BlobProvider document={<SalarySlip data={data} BranchLogo={BranchLogo} />}>
      {({ blob, url, loading, error }) => {
        // You can use the blob and url here

        // console.log("Instance", data.employeeName, blob, url, loading, error);

        if(blob !== null ){
            SavePdf(data, blob);
        }

        return error ? (
            <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
              <th
                scope="row"
                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
              >
                {data.employeeName}
              </th>
              <td className="py-4 px-6">
                {data.empCode}
              </td>
              <td className="py-4 px-6">
                <span className="text-red-600 px-2 py-1 rounded-lg">
                 Error generatin Pdf
                </span>
              </td>
            </tr>
          ) : blob ? (
            <tr className="bg-white border-b text-gray-700 odd:bg-gray-200 even:bg-gray-300">
              <th
                scope="row"
                className="py-4 px-6 font-medium text-gray-900 whitespace-nowrap "
              >
                {data.employeeName}
              </th>
              <td className="py-4 px-6">
                {data.empCode}
              </td>
              <td className="py-4 px-6">
                <a href={url} target="_blank" rel="noopener noreferrer">
                  <Button isLoading={loading} loadingText="Generating..." size={"sm"} colorScheme="blue">
                    Open PDF
                  </Button>
                </a>
              </td>
            </tr>
          ) : (
            ""
          );
  
  
      }}
    </BlobProvider>
  );
 
 export default DynamicSalarySlip