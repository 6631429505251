import { useToast, Button } from '@chakra-ui/react';
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { LuCheckCircle, LuXCircle } from 'react-icons/lu';
import moment from "moment";


const AllCompensationOff = () => {

    const userData = JSON.parse(localStorage.getItem("user"));
    const toast = useToast();
    const id = "toast";

    const [rowData, setRowData] = useState([]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [columnDefs, setColumnDefs] = useState([
          {
            headerName: "Emp Code",
            field: "empCode",
          },    
          {
            headerName: "Emp Name",
            field: "empName",
          },
          {
            headerName: "Comp Off for Date",
            field: "compoffDate",
            cellRenderer:(params) => <span>{moment(params.data.compoffDate).format('DD/MM/YYYY')}</span>
          },
          {
            headerName: "Remarks",
            field: "remark",
          },
          {
            headerName: "Status",
            field: "compoffStatus",
            cellRenderer:(params) => params.data.compoffStatus === "Pending" ?
            <span className="bg-yellow-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            Pending
            </span> : params.data.compoffStatus === "Approved" ?
            <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
              Approved
            </span> : params.data.compoffStatus === "Rejected" ?
            <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
              Rejected
            </span> : params.data.compoffStatus === "Cancelled" ?
            <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
              Cancelled
            </span> : ''
          },
          {
            headerName: "Action By",
            field: "approvedByName",
          },
          {
            headerName: "Action",
            cellRenderer: (params) => params.data.compoffStatus === "Pending" ?
            <div className="space-x-2">
              <Button onClick={async()=>{
                let RowData = params.data;
                let body = {
                  compoffId: RowData.compoffId,
                  empGuid: RowData.empGuid,
                  empCode: RowData.empCode,
                  leaveQuotaId: RowData.leaveQuotaId,
                  compoffDate: RowData.compoffDate,
                  remark: RowData.remark,
                  compoffStatus: "Approved",
                  approvedBy: userData.userId,
                  companyId: RowData.companyId,
                  branchId: RowData.branchId
                }
                console.log("Approve Action",body);
                await axios.post(
                  `${process.env.REACT_APP_ACQ_URL}/api/Compoff/SAVECompoffApply`,body
                ).then((response) => {
                  console.log("Approve Response", response);
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: response?.data,
                      position: "top",
                      status: "info",
                      duration: 2000,
                      isClosable: true,
                    });
                  }
                  GetCompOffList();
                })
                .catch((err) => {
                  console.error("Approve Error", err);
                  if (!toast.isActive(id)) {
                    toast({
                      id,
                      title: "Server Error",
                      position: "top",
                      status: "error",
                      duration: 2000,
                      isClosable: true,
                    });
                  }
                });
              }} leftIcon={<LuCheckCircle/>} colorScheme="green" size={'xs'} >Approve</Button>
              <Button 
                onClick={async()=>{
                  let RowData = params.data;
        
                  let body = {
                    compoffId: RowData.compoffId,
                    empGuid: RowData.empGuid,
                    empCode: RowData.empCode,
                    leaveQuotaId: RowData.leaveQuotaId,
                    compoffDate: RowData.compoffDate,
                    remark: RowData.remark,
                    compoffStatus: "Rejected",
                    approvedBy: userData.userId,
                    companyId: RowData.companyId,
                    branchId: RowData.branchId
                  }
        
                  console.log("Reject Action",body);
        
                  await axios.post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Compoff/SAVECompoffApply`,body
                  ).then((response) => {
                    console.log("Reject Response", response);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: response?.data,
                        position: "top",
                        status: "info",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                    GetCompOffList();
                  })
                  .catch((err) => {
                    console.error("Reject Error", err);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "Server Error",
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  });
                }}
               leftIcon={<LuXCircle/>} colorScheme="red" size={'xs'} >Reject</Button>
            </div> : params.data.compoffStatus === "Approved" ?
            <Button  onClick={async()=>{
              let RowData = params.data;
      
              let body = {
                compoffId: RowData.compoffId,
                empGuid: RowData.empGuid,
                empCode: RowData.empCode,
                leaveQuotaId: RowData.leaveQuotaId,
                compoffDate: RowData.compoffDate,
                remark: RowData.remark,
                compoffStatus: "Cancelled",
                approvedBy: userData.userId,
                companyId: RowData.companyId,
                branchId: RowData.branchId
              }
              console.log("Cancel Action",body);
              await axios.post(
                `${process.env.REACT_APP_ACQ_URL}/api/Compoff/SAVECompoffApply`,body
              ).then((response) => {
                console.log("Cancel Response", response);
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: response?.data,
                    position: "top",
                    status: "info",
                    duration: 2000,
                    isClosable: true,
                  });
                }
                GetCompOffList();
              })
              .catch((err) => {
                console.error("Cancel Error", err);
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: "Server Error",
                    position: "top",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              });
            }} leftIcon={<LuXCircle/>} colorScheme="red" size={'xs'} >Cancel</Button> : ''
          },
          ]);

            // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

    useEffect(() => {
        GetCompOffList();
      }, []);
    
      const GetCompOffList = async () => {
        try {
          const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/Compoff/GetCompoffList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`);
          console.log("Comp Off List", response);
          const res = response.data;
          setRowData(res);
        } catch (err) {
          console.error(err);
        }
      };

  return (
    <div>
      <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">All Compensation Offs</h1>
      </div>

      <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={useMemo(() => ({
                  flex:1,
                  sortable: true,
                  filter: "agTextColumnFilter",
                  floatingFilter: true,
                  cacheQuickFilter: true,
                  cellClass: "no-border",
                  resizable: true
                }))} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>

    </div>
  )
}

export default AllCompensationOff