import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Button,
  useDisclosure,
  useToast,
  Select,
  ModalFooter,
  Checkbox,
  Input,
  ModalBody,
  ModalCloseButton,
  ModalHeader,
  ModalContent,
  ModalOverlay,
  Modal,
  InputGroup,
  InputRightAddon,
  Switch,
} from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AlertCircle, Check, Edit, Plus, Settings } from "react-feather";
import ReactSelect from "react-select";

function ComponentMaster()  {
    const userData = JSON.parse(localStorage.getItem("user"));
    // console.log(userData);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const [CompDetails, setCompDetails] = useState();

    const {
      isOpen: isAddComponentOpen,
      onOpen: onAddComponentOpen,
      onClose: onAddComponentClose,
    } = useDisclosure();

    const {
      isOpen: isConfigureComponentOpen,
      onOpen: onConfigureComponentOpen,
      onClose: onConfigureComponentClose,
    } = useDisclosure();

    const {
      isOpen: isHRAConfigurationOpen,
      onOpen: onHRAConfigurationOpen,
      onClose: onHRAConfigurationClose,
    } = useDisclosure();

    const {
      isOpen: isEditComponentOpen,
      onOpen: onEditComponentOpen,
      onClose: onEditComponentClose,
    } = useDisclosure();

    const toast = useToast();
    const id = "toast";
    const [MasterData, setMasterData] = useState();
    const [ComponentType, setComponentType] = useState();
    const [SalarySlipLabel, setSalarySlipLabel] = useState();
    const [DisplayLabel, setDisplayLabel] = useState();
    const [active, setActive] = useState(true);

    const [ConfigureCompDetails, setConfigureCompDetails] = useState([]);

    const [ComponentTypeList, setComponentTypeList] = useState([]);
    const [FixedComponentList, setFixedComponentList] = useState([]);


    const [AdditionalCalculationType, setAdditionalCalculationType] = useState();
    const [AdditionViewInSalarySlip, setAdditionViewInSalarySlip] = useState(true);

    const [FixedComponents, setFixedComponents] = useState([]);



    const [IsHraRuleApply, setIsHraRuleApply] = useState(false);
    const [MetroCityPercent, setMetroCityPercent] = useState();
    const [NonMetroPercent, setNonMetroPercent] = useState();
  
    console.log("fixed Components",FixedComponents);

    useEffect(() => {
      getMasterStructure();
      GetComponentTypeList();
      GetFixedComponentList();
    }, []);
  
    const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        minWidth: 150,
        sortable: true,
        filter: true,
        resizable: true,
      };
    }, []);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    const getMasterStructure = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        .then((response) => {
           console.log("Data got Successfully", response.data);
          setMasterData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
  

    const GetFixedComponentList = async () => {

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/MasterComponent/GetMasterComponentList?BranchId=${userData.isActiveBranch}`
        )

        console.log("Fixed Component List",response.data);
        setFixedComponentList(response.data)
        
      } catch (error) {
        console.error(error)
      }
    };

    const [McolumnDefs, setMColumnDefs] = useState([
      { headerName: "Salary Label", field: "displayLabel" },
      { headerName: "Salary Slip Label", field: "salarySlipLabel" },
      { headerName: "Salary Type", field: "componentTypeName" },
      {
        headerName: "Status",
        field: "isActive",
        cellRenderer: (params) =>
        <Switch isChecked={params.data.isActive} onChange={({target})=>{handleBranchStructureSwitch(target.checked,params.data)}} colorScheme='green' />
      },

      {
        headerName: "Action",
        cellRenderer: (params) => (
          <div className="space-x-4">
            <Button
              onClick={() => {

                let CompData = params.data;

                setCompDetails(CompData);
                setDisplayLabel(CompData.displayLabel);
                setSalarySlipLabel(CompData.salarySlipLabel);
                setComponentType(CompData.componentTypeId)

                onEditComponentOpen();
              }}
           size={'xs'}  colorScheme={'blue'}><Edit size={'12px'} className='mr-1' />Edit</Button>

          {params.data.componentTypeId === 4 ? 
<Button
              onClick={() => {

                let CompData = params.data;
                

                setConfigureCompDetails(CompData);
                setAdditionalCalculationType(CompData.calculationTypeId);
                setAdditionViewInSalarySlip(CompData.isViewInSalarySlip)
                onConfigureComponentOpen();
              }}
           size={'xs'} variant={'outline'}  colorScheme={'blue'}><Settings size={'12px'} className='mr-1' />Configure</Button> : ''}


            {params.data.componentId === 2 && <Button 
            onClick={async()=>{
              try {
                const response = await axios.get(
                  `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetHRAConfiguration?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                )
                console.log("Hra Configuration",response);
                const res = response.data;
                setIsHraRuleApply(res.hraApplied || false);
                setMetroCityPercent(res.hraForMetroCity || '');
                setNonMetroPercent(res.hraForNonMetroCity || '');
                onHRAConfigurationOpen();
              } catch (err) {
                console.error(err);
              }
            }} size={'xs'} variant={'outline'}  colorScheme={'blue'}><Settings size={'12px'} className='mr-1' />Configure HRA</Button>}
            </div>
        ),
      },
    ]);
  

    const handleBranchStructureSwitch = async (CheckedStatus,ComponentData) => {

      console.log("Checked Status",CheckedStatus);
      console.log("Component Data",ComponentData);

      setMasterData(prev => {
        const newState = prev.map((obj)=>{
          if (obj.branchSalaryStructureMasterId === ComponentData.branchSalaryStructureMasterId) {
            return {...obj, isActive: CheckedStatus};
          }
          return obj;
          
        })
        return newState
  
      })







      let body ={
        branchSalaryStructureMasterId : ComponentData.branchSalaryStructureMasterId,
        componentType : ComponentData.componentType,
        componentTypeId : ComponentData.componentTypeId,
        componentTypeName : ComponentData.componentTypeName,
        componentId : ComponentData.componentId,
        displayLabel : ComponentData.displayLabel,
        salarySlipLabel : ComponentData.salarySlipLabel,
        isActive : CheckedStatus,
        isEmpComponent : ComponentData.isEmpComponent,
        companyId : ComponentData.companyId,
        branchId : ComponentData.branchId
    }


    console.log("Switch Save Body",body);

    await axios
    .post(
      `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/SaveBranchSalaryStructureMaster`,
      body
    )
    .then((response) => {
      console.log(response);
      const res = response.data;
      //console.log("res", res);

      if (!toast.isActive(id)) {
        toast({
          id,
          title: res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      // onAddComponentClose();

    })
    .catch((error) => {
      console.error(error)
      console.log(error.message);

      const err = error.message;

      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: err,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }

    });

    }


    const GetComponentTypeList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/MasterComponent/GetComponentType`
        )
        console.log("Component Type List response",response)
        setComponentTypeList(response.data)
      } catch (error) {
        console.error(error)
      }
    }
  
    // Update Particular Branch Component
    const handleUpdateComponent = async (e, Compid) => {
      e.preventDefault();
    //  console.log(Compid);
      let body = {
        branchSalaryStructureMasterId: Compid,
        componentType: String(ComponentType),
        displayLabel: DisplayLabel,
        salarySlipLabel: SalarySlipLabel,
        isActive: active,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      };
  
    //  console.log(body);
  
      await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/SaveBranchSalaryStructureMaster`,
          body
        )
        .then((response) => {
          const res = response.data;
          //console.log("res", res);
          if (res === "Success") {
           // console.log("Data added Successfully", response);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "Branch salary component created.",
                description: "We've salary component.",
                position: "top",
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            }
            onEditComponentClose();
            getMasterStructure();
            setComponentType();
            setSalarySlipLabel();
            setDisplayLabel();
            setActive();
          } else {
          //  console.log("Data Not added", response);
  
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: "Branch salary structure not created.",
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          }
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);
        });
    };
  
    // Save Branch structure component
    const handleBranchSalaryComponent = async (e) => {
      e.preventDefault();
      let body = {
        componentType: String(ComponentType),
        displayLabel: DisplayLabel,
        salarySlipLabel: SalarySlipLabel,
        isActive: active,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      };
  
      console.log("Component Save Body",body);
  
      await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/SaveBranchSalaryStructureMaster`,
          body
        )
        .then((response) => {
          console.log(response);
          const res = response.data;
          //console.log("res", res);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
          // onAddComponentClose();
  
        })
        .catch((error) => {
          console.error(error)
          console.log(error.message);

          const err = error.message;

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: err,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }

        });


        let body2 = FixedComponents.map((obj)=>{
          return {
            componentType: obj.componentType,
            displayLabel: obj.name,
            salarySlipLabel: obj.name,
            componentId:obj.id,
            isActive: active,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
          }
        })

        console.log("Fixed Save Body" , body2);

        await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/SaveBranchSalaryStructureMasterList`,
          body2
        )
        .then((response) => {
          console.log(response);
          const res = response.data;
          //console.log("res", res);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
          // onAddComponentClose();
          getMasterStructure();
          setComponentType();
          setSalarySlipLabel();
          setDisplayLabel();
          setFixedComponents([])
          setActive();
  
        })
        .catch((error) => {
          console.error(error);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: "Component not created.",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

    };
     

    console.log("Component Details",ConfigureCompDetails);

    const SaveComponentConfiguration = async (e) => {
      e.preventDefault();
      let body = {
        componentTypeId : ConfigureCompDetails.componentTypeId,
        componentId : ConfigureCompDetails.branchSalaryStructureMasterId,
        componentName : ConfigureCompDetails.displayLabel,
        calculationTypeId : AdditionalCalculationType,
        // calculationName : AdditionalCalculationType,
        isViewInSalarySlip : AdditionViewInSalarySlip,
        companyId : userData.companyId,
        branchId : userData.isActiveBranch,
      }

      console.log("Additional Config Save Body",body);

      await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/SalaryComponentConfig/SaveSalaryComponentConfiguration`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        //console.log("res", res);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        
        getMasterStructure();
        setAdditionViewInSalarySlip(true);
        setAdditionalCalculationType('');
        onConfigureComponentClose();

      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Component not created.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }

      });
    }


    const SaveHraConfiguration = async (e) => {
      e.preventDefault();
      let body = {
        hraApplied : IsHraRuleApply,
        hraForMetroCity : MetroCityPercent,
        hraForNonMetroCity : NonMetroPercent,
        companyId : userData.companyId,
        branchId : userData.isActiveBranch
      }

      console.log("HRA Configure Body",body);

      await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/SaveHRAConfiguration`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        //console.log("res", res);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        onHRAConfigurationClose();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Component not created.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }

      });

    }
    

    return (
      <div>
        <div className="flex justify-between items-center border-b border-gray-400 mb-3 pb-2 text-left">
          <h1 className="text-gray-700 text-lg font-bold ">
            Branch Salary Structure
          </h1>
          <div>
            <Button onClick={onAddComponentOpen} size={'sm'} colorScheme="purple">
              <FontAwesomeIcon icon={faPlus} className="mr-2" />
              Add Salary Component
            </Button>
          </div>
        </div>
  
        <div className="ag-theme-alpine mb-6">
          <AgGridReact
            style={gridStyle}
            //ref={gridRef} // Ref for accessing Grid's API
            rowData={MasterData} // Row Data for Rows
            columnDefs={McolumnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional- set to 'true'
            pagination={true}
            domLayout={"autoHeight"}
            paginationPageSize={15}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>
  
  
        <Modal size={'4xl'} isOpen={isAddComponentOpen} onClose={onAddComponentClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Salary Component</ModalHeader>
            <ModalCloseButton />
            <hr />
            <form onSubmit={handleBranchSalaryComponent}>
              <ModalBody>
                <div className="grid grid-cols-2 gap-5 mb-5">
                  <div className="space-y-2">
                    <label>Select Default Components</label>
                  <ReactSelect
                    isMulti
                    options={FixedComponentList}
                    value={FixedComponents}
                    onChange={(value)=>{setFixedComponents(value);}}
                    getOptionLabel={option => option.name}
                    getOptionValue = {option => option.id}
                  />
                  </div>
                  
                  <div className="space-y-2">
                    <label>Component Name</label>
                    <Input
                      isRequired
                      borderColor="gray"
                      value={DisplayLabel || ""}
                      onChange={(e) => {
                        setDisplayLabel(e.target.value);
                      }}
                      placeholder="Display Name"
                    />
                  </div>
                  <div className="space-y-2">
                    <label>Salary Slip Name</label>
                    <Input
                      isRequired
                      borderColor="gray"
                      value={SalarySlipLabel || ""}
                      onChange={(e) => {
                        setSalarySlipLabel(e.target.value);
                      }}
                      placeholder="Salary Slip Name"
                    />
                  </div>
                  <div className="space-y-2">
                    <label>Component Type</label>
                    <Select
                      borderColor="gray"
                      value={ComponentType || ""}
                      onChange={(e) => {
                        setComponentType(e.target.value);
                      }}
                      placeholder="Select Component Type"
                    >
                      {ComponentTypeList.filter(filterdata => filterdata.isActive===  true).map((data,i)=>{
                        return <option key={i} value={data.id}>{data.componentTypeName}</option>
                      })}

                    </Select>
                  </div>



                </div>



              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button type="submit" colorScheme="purple" variant="solid">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={onAddComponentClose}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
  
        <Modal size={'4xl'}  isOpen={isEditComponentOpen} onClose={onEditComponentClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Edit Salary Component</ModalHeader>
            <ModalCloseButton />
            <hr />
            <form
              onSubmit={(e) => {
                handleUpdateComponent(
                  e,
                  CompDetails.branchSalaryStructureMasterId
                );
              }}
            >
              <ModalBody>
                <div className="grid grid-cols-2 gap-5 mb-5">
                  <div className="space-y-2">
                    <label>Component Name</label>
                    <Input
                      isRequired
                      borderColor="gray"
                      value={DisplayLabel || ""}
                      onChange={(e) => {
                        setDisplayLabel(e.target.value);
                      }}
                      placeholder="Display Name"
                    />
                  </div>
                  <div className="space-y-2">
                    <label>Salary Slip Name</label>
                    <Input
                      isRequired 
                      borderColor="gray"
                      value={SalarySlipLabel || ""}
                      onChange={(e) => {
                        setSalarySlipLabel(e.target.value);
                      }}
                      placeholder="Salary Slip Name"
                    />
                  </div>
                  <div className="space-y-2">
                    <label>Component Type</label>
                    <Select
                      borderColor="gray"
                      value={ComponentType || ""}
                      onChange={(e) => {
                        setComponentType(e.target.value);
                      }}
                      placeholder="Select option"
                    >
                      {ComponentTypeList.filter(filterdata => filterdata.isActive===  true).map((data,i)=>{
                        return <option key={i} value={data.id}>{data.componentTypeName}</option>
                      })}
                    </Select>
                  </div>

                </div>

              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button type="submit" colorScheme="purple" variant="solid">
                    Update
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={onEditComponentClose}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>


        <Modal  isOpen={isConfigureComponentOpen} onClose={onConfigureComponentClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Configure Component</ModalHeader>
            <ModalCloseButton />
            <hr />
            <form onSubmit={SaveComponentConfiguration}>
              <ModalBody>
                <div className="space-y-4">

                <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Component Name</p>
                <h6 className="font-medium">{ConfigureCompDetails.displayLabel}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Component Type</p>
                <h6 className="font-medium">{ConfigureCompDetails.componentTypeName}</h6>
              </div>
            </div>

                <div className="space-y-2">
  <div className="flex justify-between items-center">
   <label>Calculation Type</label>
   <Checkbox
    isChecked={AdditionViewInSalarySlip}
    onChange={(e) => {
      setAdditionViewInSalarySlip(e.target.checked);
    }}
    borderColor="gray.400"
  >
    View in Salary Slip?
  </Checkbox>
  </div>
  <Select
    borderColor="gray"
    value={AdditionalCalculationType || ""}
    onChange={({target}) => {
      setAdditionalCalculationType(target.value);
    }}
    placeholder="Select Calculation Type"
  >
    <option value='1'>Fix & Re-Accuring</option>
    <option value='2'>Not-Fix & Re-Accuring</option>
    <option value='3'>Fix & Non Re-Accuring</option>
    <option value='4'>Not-Fix & Non Re-Accuring</option>
  </Select>
</div>



  {AdditionalCalculationType === '1' || 1 ?

  <div className="my-auto text-base flex"><div><AlertCircle size={'18px'} color="red" className="mr-1"/></div>Amount is fix as per Salary Structure & Same amount will given Every Month!</div>

  : AdditionalCalculationType === '2' || 2 ?
  <div className="my-auto text-base flex"><div><AlertCircle size={'18px'} color="red" className="mr-1"/></div>Amount is variable or can change every month but maximum of salary structure & amount given every month</div>


  : AdditionalCalculationType === '3' || 3 ?
  <div className="my-auto text-base flex"><div><AlertCircle size={'18px'} color="red" className="mr-1"/></div>Amount is fix as per Salary Structure & Same amount will given once in a year</div>
  : AdditionalCalculationType === '4' || 4 ?
  <div className="my-auto text-base flex"><div><AlertCircle size={'18px'} color="red" className="mr-1"/></div>Amount is variable or can change every month but maximum of salary structure & same amount will given once in a year</div> : ''}

</div>
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button type="submit" colorScheme="purple" variant="solid">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={onConfigureComponentClose}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>


        <Modal  isOpen={isHRAConfigurationOpen} onClose={onHRAConfigurationClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>HRA Configuration</ModalHeader>
            <ModalCloseButton />
            <hr />
            <form onSubmit={SaveHraConfiguration}>
              <ModalBody>
                <div className="space-y-4">
                  <Checkbox isChecked={IsHraRuleApply} onChange={({target})=>{setIsHraRuleApply(target.checked)}} borderColor={'gray.400'}>Is HRA Rule Applicable?</Checkbox>
                  <div className="space-y-2">
                    <label>For Metro Cities</label>
                    <InputGroup>
                      <Input isDisabled={!IsHraRuleApply} isRequired={IsHraRuleApply} value={MetroCityPercent || ''} onChange={({target})=>{setMetroCityPercent(target.value)}} type="number" borderColor={'gray.400'} placeholder='Metro Cities' />
                      <InputRightAddon borderColor={'gray.400'} children='%' />
                    </InputGroup>
                  </div>


                  <div className="space-y-2">
                    <label>For Non Metro Cities</label>
                    <InputGroup>
                      <Input isDisabled={!IsHraRuleApply} isRequired={IsHraRuleApply} value={NonMetroPercent || ''} onChange={({target})=>{setNonMetroPercent(target.value)}} type="number" borderColor={'gray.400'} placeholder='Non Metro Cities' />
                      <InputRightAddon borderColor={'gray.400'} children='%' />
                    </InputGroup>
                  </div>

                </div>

              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button type="submit" colorScheme="purple" variant="solid">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={onHRAConfigurationClose}
                  >
                    Close
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

      </div>
    );
  }

export default ComponentMaster