import React, { useState, useRef, useEffect, useMemo, useCallback,} from "react";
import {  Drawer,  DrawerBody,  DrawerHeader,  DrawerOverlay,  DrawerContent,  DrawerCloseButton,  Button,  Stack,  Radio,  RadioGroup,  useDisclosure,  Checkbox,  useToast,  Tabs,  TabList,  TabPanels,  Tab,  TabPanel,  Textarea,} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { CheckCircle, Edit, Plus, XCircle } from "react-feather";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ReactSelect from "react-select";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";
import _ from "lodash";

function EmpWFH() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [WFHMode, setWFHMode] = useState("0");

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const [WfhReason, setWfhReason] = useState();
  const [HybridData, setHybridData] = useState([]);

  const [ApprovedBy, setApprovedBy] = useState();
  const [MenuList, setMenuList] = useState([]);

  const [Monday, setMonday] = useState(false);
  const [Tuesday, setTuesday] = useState(false);
  const [Wednesday, setWednesday] = useState(false);
  const [Thursday, setThursday] = useState(false);
  const [Friday, setFriday] = useState(false);
  const [Saturday, setSaturday] = useState(false);
  const [Sunday, setSunday] = useState(false);

  const [TeamRowData, setTeamRowData] = useState([]);
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Emp Name",
      field: "empName",
      floatingFilter: false,
    },
    {
      headerName: "Reporting Person",
      field: "reportingPerson",
      floatingFilter: false,
    },
    {
      headerName: "From Date",
      field: "fromDate",
      valueGetter: (params) =>
        new Date(params.data.fromDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "To Date",
      field: "toDate",
      valueGetter: (params) =>
        new Date(params.data.toDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "Approve By",
      field: "approveByName",
    },
    {
      headerName: "Reason",
      field: "reason",
    },
    {
      headerName: "Status",
      field: "isStatus",
      cellRenderer: (params) =>
        params.data.isStatus === 1 ? (
          <span className="bg-green-500  px-1 py-0.5 rounded-md font-medium text-white">
            Approved
          </span>
        ) : params.data.isStatus === 0 ? (
          <span className="bg-yellow-500  px-1 py-0.5 rounded-md font-medium text-white">
            Pending
          </span>
        ) : params.data.isStatus === 2 ? (
          <span className="bg-red-500  px-1 py-0.5 rounded-md font-medium text-white">
            Rejected
          </span>
        ) : params.data.isStatus === 3 ? (
          <span className="bg-red-500  px-1 py-0.5 rounded-md font-medium text-white">
            Cancelled
          </span>
        ) : (
          ""
        ),
    },
  ]);

  const [TeamColumnDefs, setTeamColumnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
    },
    {
      headerName: "Emp Name",
      field: "empName",
    },
    {
      headerName: "Department",
      field: "departmentName",
    },
    {
      headerName: "Reporting Person",
      field: "reportingPerson",
    },
    {
      headerName: "From Date",
      field: "fromDate",
      valueGetter: (params) =>
        new Date(params.data.fromDate).toLocaleDateString("en-GB"),
    },
    {
      headerName: "To Date",
      field: "toDate",
      valueGetter: (params) =>
        new Date(params.data.toDate).toLocaleDateString("en-GB"),
    },

    {
      headerName: "Reason",
      field: "reason",
    },
    {
      headerName: "Status",
      field: "isStatus",
      cellRenderer: (params) =>
        params.data.isStatus === 1 ? (
          <span className="bg-green-500  px-1 py-0.5 rounded-md font-medium text-white">
            Approved
          </span>
        ) : params.data.isStatus === 0 ? (
          <span className="bg-yellow-500  px-1 py-0.5 rounded-md font-medium text-white">
            Pending
          </span>
        ) : params.data.isStatus === 2 ? (
          <span className="bg-red-500  px-1 py-0.5 rounded-md font-medium text-white">
            Rejected
          </span>
        ) : params.data.isStatus === 3 ? (
          <span className="bg-red-500  px-1 py-0.5 rounded-md font-medium text-white">
            Cancelled
          </span>
        ) : (
          ""
        ),
    },
    {
      headerName: "Action",
      floatingFilter: false,
      cellRenderer: (params) =>
        params.data.isStatus === 0 ? (
          <div className="space-x-4">
            <Button
              onClick={async () => {
                let WFHData = params.data;

                HandleApproveHybridList(
                  WFHData.empGuid,
                  WFHData.id,
                  WFHData.isActive,
                  true
                );

                let body = {
                  id: WFHData.id,
                  reviewBy: String(userData.userId),
                  guId: WFHData.empGuid,
                  empName: WFHData.empName,
                  empCode: WFHData.empCode,
                  departmentName: String(WFHData.departmentId),
                  fromDate: WFHData.fromDate,
                  toDate: WFHData.toDate,
                  reason: WFHData.reason,
                  isActive: true,
                  isStatus: 1,
                  approveBy: String(userData.userId),
                  reportingPerson: WFHData.reportingPerson,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch,
                };

                console.log("Approved  Body", body);

                await axios
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFH`,
                    body
                  )
                  .then((response) => {
                    const res = response.data;
                    console.log("res", res);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }

                    GetTeamWFHList();
                  })
                  .catch((error) => {
                    console.log(error.response);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "ERROR",
                        description: "Employee WFH Not Updated",
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  });
              }}
              variant="solid"
              size="xs"
              colorScheme="green"
            >
              <CheckCircle size="14px" className="mr-2" />
              Approve
            </Button>
            <Button
              onClick={async () => {
                let WFHData = params.data;
                HandleApproveHybridList(
                  WFHData.empGuid,
                  WFHData.id,
                  WFHData.isActive,
                  false
                );
                let body = {
                  id: WFHData.id,
                  reviewBy: String(userData.userId),
                  guId: WFHData.empGuid,
                  empName: WFHData.empName,
                  empCode: WFHData.empCode,
                  departmentName: String(WFHData.departmentId),
                  fromDate: WFHData.fromDate,
                  toDate: WFHData.toDate,
                  reason: WFHData.reason,
                  isActive: false,
                  isStatus: 2,
                  approveBy: String(userData.userId),
                  reportingPerson: WFHData.reportingPerson,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch,
                };

                console.log("Not Approved  Body", body);

                await axios
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFH`,
                    body
                  )
                  .then((response) => {
                    const res = response.data;
                    console.log("res", res);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }

                    GetTeamWFHList();
                  })
                  .catch((error) => {
                    console.log(error.response);
                    console.log(error.response.data);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "ERROR",
                        description: "Employee WFH Not Updated",
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  });
              }}
              variant="solid"
              size="xs"
              colorScheme="red"
            >
              <XCircle size="14px" className="mr-2" />
              Reject
            </Button>
          </div>
        ) : params.data.isStatus === 1 ? (
          <div className="space-x-4">
            <Button
              onClick={async () => {
                let WFHData = params.data;
                HandleApproveHybridList(
                  WFHData.empGuid,
                  WFHData.id,
                  WFHData.isActive,
                  false
                );

                let body = {
                  id: WFHData.id,

                  reviewBy: String(userData.userId),
                  guId: WFHData.empGuid,
                  empName: WFHData.empName,
                  empCode: WFHData.empCode,
                  departmentName: String(WFHData.departmentId),
                  fromDate: WFHData.fromDate,
                  toDate: WFHData.toDate,
                  reason: WFHData.reason,
                  isActive: false,
                  isStatus: 3,
                  approveBy: String(userData.userId),
                  reportingPerson: WFHData.reportingPerson,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch,
                };

                console.log("Not Approved  Body", body);

                await axios
                  .post(
                    `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFH`,
                    body
                  )
                  .then((response) => {
                    const res = response.data;
                    console.log("res", res);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: res,
                        position: "top",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }

                    GetTeamWFHList();
                  })
                  .catch((error) => {
                    console.log(error.response);
                    console.log(error.response.data);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "ERROR",
                        description: "Employee WFH Not Updated",
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  });
              }}
              size={"xs"}
              colorScheme={"red"}
            >
              <XCircle size="14px" className="mr-2" />
              Cancel
            </Button>
          </div>
        ) : (
          ""
        ),
    },
  ]);

  const HandleApproveHybridList = async (
    EmpGuid,
    ID,
    ActiveStatus,
    BoolStatus
  ) => {
    console.log("EMpGuid", EmpGuid);
    console.log("WFH id", ID);
    console.log("Active Status", ActiveStatus);
    console.log("BoolStatus", BoolStatus);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetWFHHybridListDetails?EmpGuid=${EmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Id=${ID}&IsActive=${ActiveStatus}`
      );
      console.log("Approve Hybrid List", response.data);
      const res = response.data;

      let HybridData = res.map((obj) => {
        return { ...obj, isActive: BoolStatus, guId: obj.empGuid };
      });

      console.log("Changed Hybrid Data", HybridData);

      await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFHHybridlist`,
          HybridData
        )
        .then((response) => {
          console.log(response);
          const res = response.data;
          console.log("res", res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }

          GetTeamWFHList();
        })
        .catch((error) => {
          console.error(error);
          console.log(error.response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: "Employee WFH Not Created",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });
    } catch (error) {
      console.error(error);
    }
  };

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  const {
    isOpen: isdraweropen,
    onOpen: ondraweropen,
    onClose: ondrawerclose,
  } = useDisclosure();


  const [SelectedEmp, setSelectedEmp] = useState([]);

  useEffect(() => {
    HandleSelectedRole();

    getEmployeeDetails();
    GetWFHList();
    GetTeamWFHList();
  }, []);

  const HandleSelectedRole = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${userData.roleId}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      console.log("Menu List ", response);
      setMenuList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmployeeDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeGeneralInfoByGuid?EmpGuid=${userData.guId}`
    )
      console.log("Employee Details", response.data);
      setSelectedEmp(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetWFHList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetcheckWFHMasterEmpGuidData?CompanyId=${userData.companyId}&BranchId=${userData.branchId}&EmpGuid=${userData.guId}`
      );
      console.log("Get WFH List", response.data);
      const res = response.data;

      // setRowData(EmpWFH);
      setRowData(_.orderBy(res, ["id"], ["desc"]));
    } catch (error) {
      console.error(error);
    }
  };

  const GetTeamWFHList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetWFHListReportingData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&EmpGuid=${userData.guId}`
      );
      console.log("Team WFH List", response);

      const TeamRes = response.data;
      setTeamRowData(_.orderBy(TeamRes, ["id"], ["desc"]));
    } catch (error) {
      console.error(error);
    }
  };

  console.log(SelectedEmp);

  const SaveEmpWFH = async (e) => {
    e.preventDefault();

    let body = {
      // reviewBy: ReviewBy,
      guId: userData.guId,
      empName: userData.name,
      empCode: userData.empCode,
      departmentName: userData.departmentName,
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      reason: WfhReason,
      isActive: false,
      // approveBy: ApprovedBy,
      reportingPerson: SelectedEmp.reportingOfficerName,
      companyId: userData.companyId,
      branchId: userData.branchId,
    };

    console.log("Save WFH", body);

    await axios
      .post(`${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFH`, body)
      .then((response) => {
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        const GetWFHUpdatedList = async () => {
          try {
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetWFHListCB?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            );
            console.log("Get WFH Updated List", response);
            const res = response.data;

            const SelectedEmpData = res.filter(
              (data) => data.empGuid === userData.guId
            );

            console.log("Selected Emp Data", SelectedEmpData[0]);

            HandleHybridListSave(SelectedEmpData[0]);
          } catch (error) {
            console.error(error);
          }
        };

        GetWFHUpdatedList();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Employee WFH Not Created",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const HandleHybridListSave = async (RowEmpData) => {
    // setPermanentAll(checkedStatus)

    console.log("Emp Data", RowEmpData);

    let DaysList = HybridData.map((obj) => {
      return {
        ...obj,
        id: RowEmpData.id,
        isActive: false,
        reason: WfhReason,
      };
    });

    console.log("Days List", DaysList);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveWFHHybridlist`,
        DaysList
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetWFHList();
        setDateRange([null, null]);
        setWfhReason("");
        setApprovedBy("");
        setHybridData([]);
        setWFHMode("0");
        ondrawerclose();

        setMonday(false);
        setTuesday(false);
        setWednesday(false);
        setThursday(false);
        setFriday(false);
        setSaturday(false);
        setSunday(false);
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Employee WFH Not Created",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const HandleCheckboxes = async (target) => {
    console.log("Target ID", target.id);
    console.log("Target Name", target.name);
    console.log("Target Checked", target.checked);

    if (target.name === "Monday") {
      setMonday(target.checked);
    }
    if (target.name === "Tuesday") {
      setTuesday(target.checked);
    }
    if (target.name === "Wednesday") {
      setWednesday(target.checked);
    }
    if (target.name === "Thursday") {
      setThursday(target.checked);
    }
    if (target.name === "Friday") {
      setFriday(target.checked);
    }
    if (target.name === "Saturday") {
      setSaturday(target.checked);
    }
    if (target.name === "Sunday") {
      setSunday(target.checked);
    }

    let body = {
      guId: userData.guId,
      empName: userData.name,
      empCode: userData.empCode,
      departmentName: userData.departmentName,
      fromDate: moment(startDate).format("YYYY-MM-DD"),
      toDate: moment(endDate).format("YYYY-MM-DD"),
      reason: WfhReason,
      isActive: false,
      approveBy: "0",
      isStatus: 0,
      reportingPerson: "0",
      id: 0,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      weeklyofDay: target.name,
      selectWeek: target.id,
      dayName: target.name,
      isweekselect: target.checked,
    };

    console.log("Handle Checkbox Body", body);

    if (target.checked === false) {
      let FilteredData = _.remove(HybridData, (data) => {
        return data.weeklyofDay !== target.name;
      });

      console.log("Filtered Data", FilteredData);

      setHybridData(FilteredData);
    } else {
      setHybridData((prev) => {
        return [...prev, body];
      });
    }
  };

  const HandleDaySelectonRange = (dates) => {
    setMonday(false);
    setTuesday(false);
    setWednesday(false);
    setThursday(false);
    setFriday(false);
    setSaturday(false);
    setSunday(false);

    const [start, end] = dates;

    for (let date = start; date <= end; date.setDate(date.getDate() + 1)) {
      // Loop through each date between start and end date
      const weekday = date.getDay(); // Get the weekday number (0-6)
      console.log("Week Day", weekday);

      if (weekday === 1) {
        setMonday(true);
      }
      if (weekday === 2) {
        setTuesday(true);
      }
      if (weekday === 3) {
        setWednesday(true);
      }
      if (weekday === 4) {
        setThursday(true);
      }
      if (weekday === 5) {
        setFriday(true);
      }
      if (weekday === 6) {
        setSaturday(true);
      }
      if (weekday === 0) {
        setSunday(true);
      }
    }
  };

  console.log("Start Date", startDate, "End Date", endDate);

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Work From Home</h1>
        <Button size="sm" colorScheme="purple" onClick={ondraweropen}>
          <FontAwesomeIcon icon={faPlus} className="mr-2" /> Apply Your WFH
        </Button>
      </div>

      <Tabs size={"sm"} variant={"solid-rounded"} colorScheme={"purple"}>
        {userData.role === "Admin" ? (
          <TabList>
            <Tab>Your Application</Tab>
            <Tab>Team Application</Tab>
          </TabList>
        ) : (
          <TabList>
            {MenuList.filter(
              (data) => data.subMenuName === "Work From Home"
            ).map((data) => {
              return data.isCreate || data.isModify || data.isView === true ? (
                <Tab>Your Application</Tab>
              ) : (
                ""
              );
            })}

            {MenuList.filter((data) => data.subMenuName === "Team WFH").map(
              (data) => {
                return data.isCreate ||
                  data.isModify ||
                  data.isView === true ? (
                  <Tab>Team Application</Tab>
                ) : (
                  ""
                );
              }
            )}
          </TabList>
        )}
        <TabPanels>
          <TabPanel>
            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={TeamRowData} // Row Data for Rows
                columnDefs={TeamColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Drawer
        isOpen={isdraweropen}
        placement="right"
        size="md"
        onClose={ondrawerclose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Work From Home </DrawerHeader>
          <hr />

          <DrawerBody>
            <form onSubmit={SaveEmpWFH} className="space-y-4">
              <div className="my-2">
                <div className="space-y-2 my-4">
                  <label>Select Date Range</label>
                  <DatePicker
                    required
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                      setDateRange(update);
                      // HandleDaySelectonRange(update);
                    }}
                    minDate={new Date()}
                    dateFormat="dd/MM/yyyy"
                    isClearable={true}
                    placeholderText="Select Date Range"
                  />
                </div>

                <div className="space-y-2 ">
                  <label>WFH Period</label>
                  <RadioGroup onChange={setWFHMode} value={WFHMode}>
                    <Stack direction="row">
                      <Radio borderColor={"gray.400"} mr={6} value="0">
                        Permanent
                      </Radio>
                      <Radio borderColor={"gray.400"} value="1">
                        Temporary
                      </Radio>
                    </Stack>
                  </RadioGroup>
                </div>

                <div className="space-y-2 my-4">
                  <h6>Select Days</h6>
                  <hr />
                  <div className="grid grid-cols-2 gap-2">
                    <Checkbox
                      id="1"
                      name="Monday"
                      isChecked={Monday}
                      onChange={({ target }) => {
                        HandleCheckboxes(target);
                      }}
                      borderColor={"gray.400"}
                    >
                      Monday
                    </Checkbox>
                    <Checkbox
                      id="2"
                      name="Tuesday"
                      isChecked={Tuesday}
                      onChange={({ target }) => {
                        HandleCheckboxes(target);
                      }}
                      borderColor={"gray.400"}
                    >
                      Tuesday
                    </Checkbox>

                    <Checkbox
                      id="3"
                      name="Wednesday"
                      isChecked={Wednesday}
                      onChange={({ target }) => {
                        HandleCheckboxes(target);
                      }}
                      borderColor={"gray.400"}
                    >
                      Wednesday
                    </Checkbox>

                    <Checkbox
                      id="4"
                      name="Thursday"
                      isChecked={Thursday}
                      onChange={({ target }) => {
                        HandleCheckboxes(target);
                      }}
                      borderColor={"gray.400"}
                    >
                      Thursday
                    </Checkbox>

                    <Checkbox
                      id="5"
                      name="Friday"
                      isChecked={Friday}
                      onChange={({ target }) => {
                        HandleCheckboxes(target);
                      }}
                      borderColor={"gray.400"}
                    >
                      Friday
                    </Checkbox>

                    <Checkbox
                      id="6"
                      name="Saturday"
                      isChecked={Saturday}
                      onChange={({ target }) => {
                        HandleCheckboxes(target);
                      }}
                      borderColor={"gray.400"}
                    >
                      Saturday
                    </Checkbox>

                    <Checkbox
                      id="0"
                      name="Sunday"
                      isChecked={Sunday}
                      onChange={({ target }) => {
                        HandleCheckboxes(target);
                      }}
                      borderColor={"gray.400"}
                    >
                      Sunday
                    </Checkbox>
                  </div>
                </div>

                <div className="space-y-2 my-4">
                  <label>Reason</label>
                  <Textarea
                    isRequired
                    value={WfhReason}
                    onChange={({ target }) => {
                      setWfhReason(target.value);
                    }}
                    borderColor="gray"
                    placeholder="Enter Reason"
                  />
                </div>
              </div>

              <div className="space-x-3 my-2 text-right">
                <Button type="submit" colorScheme="purple">
                  Submit
                </Button>
                <Button
                  onClick={ondrawerclose}
                  variant="outline"
                  colorScheme="purple"
                >
                  Cancel
                </Button>
              </div>
            </form>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </div>
  );
}

export default EmpWFH;
