import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, useToast, Tabs, TabList, TabPanels, Tab, TabPanel, Select } from "@chakra-ui/react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";


function ExpensePayment() {

    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = "toast";
 
    const [ExpensePaymentPayAction, setExpensePaymentPayAction] = useState();
    const [ExpensePaymentPayMode, setExpensePaymentPayMode] = useState();

    const [AdvancePaymentPayAction, setAdvancePaymentPayAction] = useState();
    const [AdvancePaymentPayMode, setAdvancePaymentPayMode] = useState();
    const [SelectedEmps, setSelectedEmps] = useState([]);


    const [PaymentMonthSelect, setPaymentMonthSelect] = useState(new Date());
   const [PettyCashPaymentRowData, setPettyCashPaymentRowData] = useState([]);
      const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
      headerCheckboxSelection: true,
      checkboxSelection: (params) => {
        return params.data.empCode;
      }, 
    },
      {
        headerName: "Emp Name",
        field: "employeeName",
      },
      {
        headerName: "Claimed Amount",
        field: "claimedAmount",
      },
      {
        headerName: "Approved Amount",
        field: "approvedAmount",
      },
      {
        headerName: "Claim Date",
        field: "claimDate",
        valueGetter : (params) => new Date(params.data.claimDate).toLocaleDateString('en-GB')
      },
      {
        headerName: "Month",
        field: "paymentMonth",
        valueGetter : (params) => new Date(params.data.claimDate).getMonth()+1
      },
      {
        headerName: "Year",
        field: "paymentYear",
        valueGetter : (params) => new Date(params.data.claimDate).getFullYear()
      },
      {
        headerName: "Pay Action",
        field: "paymentStatus",
        editable:true, singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
           values: ['Pay', 'Hold', 'Pay Later'],
        } ,cellClass:'editable-grid-cell'
      },
      {
        headerName: "Payment Mode",
        field: "modeOfpayment",
        editable:true, singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
           values: ['Online', 'Check', 'Cash'],
        } ,cellClass:'editable-grid-cell'
      },
      {
        headerName: "Action",
        cellRenderer : (params) =>
        <Button onClick={async()=>{

          let ExpenseData = params.data;

           console.log("Expense Data",ExpenseData);

          let body ={
            empGuId : ExpenseData.empGuId,
            empName : ExpenseData.employeeName,
            empCode : ExpenseData.empCode,
            paymentStatus : ExpenseData.paymentStatus,
            paidAmount : ExpenseData.approvedAmount,
            modeOfpayment : ExpenseData.modeOfpayment,
            paymentMonth : new Date(ExpenseData.claimDate).getMonth()+1,
            paymentYear : new Date(ExpenseData.claimDate).getFullYear(),
            companyId : userData.companyId,
            branchId : userData.isActiveBranch,
          }

          console.log("Payment Body",body);

          await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveExpensePaymentInfo`,body
          )
          .then((response) => {
            const res = response.data;
            //console.log("res",res);
            if (!toast.isActive(id)) {
              toast({
                id,
                title:res,
                position: 'top',
                status: 'info',
                duration: 2000,
                isClosable: true,
              })
            }

          })
          .catch((error) => {
            console.log(error.response);
            // console.log(error.response.data);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: 'ERROR',
                description: "Expense Payment not Updated.",
                position: 'top',
                status: 'error',
                duration: 2000,
                isClosable: true,
              })
            }
          });

        }} colorScheme={'blue'} size={'xs'} >Save</Button>
      },
    ]);



    const [NewColumnDefs, setNewColumnDefs] = useState([
      {
        headerName: "Emp Code",
        field: "empCode",
        headerCheckboxSelection: true,
        checkboxSelection: (params) => {
          return params.data.empCode;
        }, 
      },
      {
        headerName: "Emp Name",
        field: "empName",

      },

      {
        headerName: "Opening Balance",
        field: "openingBalance",
      },
      {
        headerName: "Current Balance",
        field: "currentBalance",
      },
      {
        headerName: "Closing Balance",
        field: "closingBalance",
      },

      {
        headerName: "Month",
        field: "aMonth",
      },
      {
        headerName: "Year",
        field: "aYear",
      },
      {
        headerName: "Pay Action",
        field: "paymentStatus",
        editable:true, singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
           values: ['Pay'],
        } ,cellClass:'editable-grid-cell'
      },
      {
        headerName: "Payment Mode",
        field: "modeOfpayment",
        editable:true, singleClickEdit: true,
        cellEditor: 'agSelectCellEditor',
        cellEditorParams: {
           values: ['Online', 'Check', 'Cash'],
        } ,cellClass:'editable-grid-cell'
      },
      {
        headerName: "Action",
        cellRenderer : (params) =>
        <Button onClick={async()=>{

          let ExpenseData = params.data;

           console.log("Expense Data",ExpenseData);

          let body ={
            pettycashAdvanceBalanceId:ExpenseData.pettyCashAdvanceBalanceId,
            empGuId : ExpenseData.empGuId,
            empName : ExpenseData.empName,
            empCode : ExpenseData.empCode,
            paymentStatus : ExpenseData.paymentStatus,
            paidAmount : ExpenseData.closingBalance,
            modeOfpayment : ExpenseData.modeOfpayment,
            paymentMonth : ExpenseData.aMonth,
            paymentYear : ExpenseData.aYear,
            companyId : userData.companyId,
            branchId : userData.isActiveBranch,
          }

          console.log("Payment Body",body);

          await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveExpensePaymentInfo`,body
          )
          .then((response) => {
            const res = response.data;
            //console.log("res",res);
            if (!toast.isActive(id)) {
              toast({
                id,
                title:res,
                position: 'top',
                status: 'info',
                duration: 2000,
                isClosable: true,
              })
            }

          })
          .catch((error) => {
            console.log(error.response);
            // console.log(error.response.data);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: 'ERROR',
                description: "Expense Payment not Updated.",
                position: 'top',
                status: 'error',
                duration: 2000,
                isClosable: true,
              })
            }
          });


          let body1 =     {
            pettycashAdvanceBalanceId:ExpenseData.pettyCashAdvanceBalanceId,
            empGuId : ExpenseData.empGuId,
            empName : ExpenseData.empName,
            empCode : ExpenseData.empCode,
            currentBalance : ExpenseData.currentBalance,
            openingBalance : ExpenseData.openingBalance,
            closingBalance : 0,
            aMonth : ExpenseData.aMonth,
            aYear : ExpenseData.aYear,
            companyId : userData.companyId,
            branchId : userData.isActiveBranch,
        }


        console.log("Payment Row Update Body",body1)


        await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/UpdatePettycashAdvanceBalance`,body
        )
        .then((response) => {

          console.log("Row Update response",response);
          const res = response.data;
          //console.log("res",res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }


          GetPettyCashBalancePayment(PaymentMonthSelect.getMonth()+1,PaymentMonthSelect.getFullYear());

        })
        .catch((error) => {
          console.log(error.response);
          // console.log(error.response.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Expense Payment not Updated.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });

        }} colorScheme={'blue'} size={'xs'} >Save</Button>
      },
    ]);


//     const [PettyCashPaymentColumnDefs, setPettyCashPaymentColumnDefs] = useState([
//       {
//         headerName: "Employee Name",
//         field: "empName",
//         headerCheckboxSelection: true,
//         checkboxSelection: (params) => {
//           return params.data.empName;
//         }, 
//       },
//       {
//         headerName: "Employee Code",
//         field: "empCode",
//       },
//       {
//         headerName: "Claim Date",
//         field: "pT_ClaimDate",
//         valueGetter : (params) => new Date(params.data.pT_ClaimDate).toLocaleDateString('en-GB')
//       },
//       {
//         headerName: "Purpose",
//         field: "pT_Purpose",
//       },
//       {
//         headerName: "Claimed Amount",
//         field: "pT_ClaimAmount",
//       },

//       {
//         headerName: "Claim Status",
//         field: "pT_ClaimStatus",
//         cellRenderer: (params) => (
//           params.data.pT_ClaimStatus === 'Pending' ||  params.data.pT_ClaimStatus === 'pending'?
//           <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
//           Pending
//         </span>
// : params.data.pT_ClaimStatus === 'Approved'? <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
// Approved
// </span> : params.data.pT_ClaimStatus === 'Rejected'? <span className="bg-red-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
// Rejected
// </span>: ''
//         ),
//       },

//       {
//         headerName: "Pay Action",
//         field: "paymentStatus",
//         editable:true, singleClickEdit: true,
//         cellEditor: 'agSelectCellEditor',
//         cellEditorParams: {
//            values: ['Pay', 'Hold', 'Pay Later'],
//         } ,cellClass:'editable-grid-cell'
//       },
//       {
//         headerName: "Payment Mode",
//         field: "modeOfpayment",
//         editable:true, singleClickEdit: true,
//         cellEditor: 'agSelectCellEditor',
//         cellEditorParams: {
//            values: ['Online', 'Check', 'Cash'],
//         } ,cellClass:'editable-grid-cell'
//       },
//       {
//         headerName: "Action",
//         cellRenderer : (params) =>
//         <Button onClick={async()=>{

//           let ExpenseData = params.data;

//            console.log("Expense Data",ExpenseData);

//           let body ={
//             empGuId : ExpenseData.empGuId,
//             empName : ExpenseData.empName,
//             empCode : ExpenseData.empCode,
//             paymentStatus : ExpenseData.paymentStatus,
//             paidAmount : ExpenseData.pT_ClaimAmount,
//             modeOfpayment : ExpenseData.modeOfpayment,
//             paymentMonth : new Date(ExpenseData.pT_ClaimDate).getMonth()+1,
//             paymentYear : new Date(ExpenseData.pT_ClaimDate).getFullYear(),
//             paymentDate: new Date().toLocaleDateString('en-CA'),
//             companyId : userData.companyId,
//             branchId : userData.isActiveBranch,
//           }

//           console.log("Payment Body",body);

//           await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SaveAdvancePaymentInfo`,body
//           )
//           .then((response) => {
//             const res = response.data;
//             //console.log("res",res);
//             if (!toast.isActive(id)) {
//               toast({
//                 id,
//                 title:res,
//                 position: 'top',
//                 status: 'info',
//                 duration: 2000,
//                 isClosable: true,
//               })
//             }

//           })
//           .catch((error) => {
//             console.log(error.response);
//             // console.log(error.response.data);
//             if (!toast.isActive(id)) {
//               toast({
//                 id,
//                 title: 'ERROR',
//                 description: "Petty Cash Payment not Updated.",
//                 position: 'top',
//                 status: 'error',
//                 duration: 2000,
//                 isClosable: true,
//               })
//             }
//           });

//           let body2 = {
//             ptAdvMId:ExpenseData.ptAdvMId,
//             empGuId: ExpenseData.empGuId ,
//             empName: ExpenseData.empName ,
//             empCode: ExpenseData.empCode,
//             pT_ClaimAmount: ExpenseData.pT_ClaimAmount ,
//             pT_Type: ExpenseData.pT_Type ,
//             pT_ClaimDate: ExpenseData.pT_ClaimDate ,
//             pT_Purpose: ExpenseData.pT_Purpose ,
//             paymentBasedOn: ExpenseData.paymentBasedOn ,
//             pT_ClaimStatus: ExpenseData.pT_ClaimStatus,
//             claimMonth: ExpenseData.claimMonth ,
//             claimYear: ExpenseData.claimYear ,
//             isLock: true,
//             companyId:userData.companyId,
//             branchId:userData.isActiveBranch
//           }

//           await axios.post(
//             `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashMaster`,body2
//         )
//         .then((response) => {
//            //  const res = response.data;
//             console.log( "Save Petty Cash Response",response);
//             // console.log("Add Reimbursement List Response ",response.data);
        
//             const res = response.data;
//             // console.log("res",res);
//             if (!toast.isActive(id)) {
//               toast({
//                 id,
//                 title:res,
//                 position: 'top',
//                 status: 'info',
//                 duration: 2000,
//                 isClosable: true,
//               })
//             }
        
//             GetPettyCashPaymentList(PaymentMonthSelect.getMonth()+1,PaymentMonthSelect.getFullYear());

        
//           })
//           .catch((error) => {
//             console.log(error.response);
//             if (!toast.isActive(id)) {
//               toast({
//                 id,
//                 title: 'ERROR',
//                 description: "Petty Cash Not Updated.",
//                 position: 'top',
//                 status: 'error',
//                 duration: 2000,
//                 isClosable: true,
//               })
//             }
//           });


//         }} colorScheme={'blue'} size={'xs'} >Save</Button>
//       },



//     ]);


const onSelectionChanged = useCallback(() => {
  var SelectedEmployees = gridRef.current.api.getSelectedRows();
 console.log("Selected Employees",SelectedEmployees);  

 setSelectedEmps(SelectedEmployees);

}, []);




    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
      //Table columns properties applies to all columns
      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: 'no-border'
      }));


      useEffect(() => {
        // GetExpensePaymentList(PaymentMonthSelect.getMonth()+1,PaymentMonthSelect.getFullYear());
        // GetPettyCashPaymentList(PaymentMonthSelect.getMonth()+1,PaymentMonthSelect.getFullYear());
        GetPettyCashBalancePayment(PaymentMonthSelect.getMonth()+1,PaymentMonthSelect.getFullYear());

      }, [])
      
      // const GetExpensePaymentList = async (MONTH,YEAR) => {
      //   try {
      //     const response= await axios.get(
      //       `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetReimbursementApprovalList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}`
      //     )
      //     console.log("Get Expense Payment List Response",response);
      //     setRowData(response.data);
      //   } catch (error) {
      //       console.error(error);
      //   }
      // }


      // const GetPettyCashPaymentList = async (MONTH,YEAR) => {
      //   try {
      //     const response= await axios.get(
      //       `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}`
      //     )
      //     console.log("Petty Cash Payment List Response",response);
      //     const res =  response.data;
      //     let ApprovedList = res.filter(data => data.pT_ClaimStatus === 'Approved')
      //     setPettyCashPaymentRowData(ApprovedList);
      //   } catch (error) {
      //       console.error(error);
      //   }
      // }



      const GetPettyCashBalancePayment = async (MONTH,YEAR) => {
        try {
          const response= await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetBalanceList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}`
          )
          console.log("Petty Cash Payment List Response",response);
          const res =  response.data;
          setRowData(res)
        } catch (error) {
            console.error(error);
        }
      }




      // const SaveBulkExpensePayment = async (e) => {
      //   e.preventDefault();
      //   let body = rowData.map((data)=>{
      //     return {
      //       empGuId : data.empGuId,
      //       empName : data.employeeName,
      //       empCode : data.empCode,
      //       paymentStatus : ExpensePaymentPayAction,
      //       paidAmount : data.approvedAmount,
      //       modeOfpayment : ExpensePaymentPayMode,
      //       paymentMonth : new Date(data.claimDate).getMonth()+1,
      //       paymentYear : new Date(data.claimDate).getFullYear(),
      //       companyId : userData.companyId,
      //       branchId : userData.isActiveBranch,
      //     }
      //   });


      //   console.log("Expense Pyment Bulk Body",body);

      //   await axios
      //   .post(
      //     `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveExpensePaymentInfoINList`,
      //     body
      //   )
      //   .then((response) => {
      //     const res = response.data;
      //     console.log("res", res);
  
      //     toast({
      //       id,
      //       title: res,
      //       position: "top",
      //       status: "info",
      //       duration: 2000,
      //       isClosable: true,
      //     });

      //     GetExpensePaymentList(PaymentMonthSelect.getMonth()+1,PaymentMonthSelect.getFullYear());
      //     setExpensePaymentPayAction('');
      //     setExpensePaymentPayMode('');
      //   })
      //   .catch((error) => {
      //     console.log(error.response);
      //     console.log(error.response.data);
      //     if (!toast.isActive(id)) {
      //       toast({
      //         id,
      //         title: "ERROR",
      //         description: "Expense Payment Not Updated",
      //         position: "top",
      //         status: "error",
      //         duration: 2000,
      //         isClosable: true,
      //       });
      //     }
      //   });

      // }


      // const SaveBulkAdvancePayment = async (e) => {
      //   e.preventDefault();
      //   let body = rowData.map((data)=>{
      //     return {
      //       empGuId : data.empGuId,
      //       empName : data.employeeName,
      //       empCode : data.empCode,
      //       paymentStatus : ExpensePaymentPayAction,
      //       paidAmount : data.approvedAmount,
      //       modeOfpayment : ExpensePaymentPayMode,
      //       paymentMonth : new Date(data.claimDate).getMonth()+1,
      //       paymentYear : new Date(data.claimDate).getFullYear(),
      //       companyId : userData.companyId,
      //       branchId : userData.isActiveBranch,
      //     }
      //   });


      //   console.log("Advance Pyment Bulk Body",body);

      //   await axios
      //   .post(
      //     `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SaveAdvancePaymentInfoInList`,
      //     body
      //   )
      //   .then((response) => {
      //     const res = response.data;
      //     console.log("res", res);
  
      //     toast({
      //       id,
      //       title: res,
      //       position: "top",
      //       status: "info",
      //       duration: 2000,
      //       isClosable: true,
      //     });
      //   })
      //   .catch((error) => {
      //     console.log(error.response);
      //     console.log(error.response.data);
      //     if (!toast.isActive(id)) {
      //       toast({
      //         id,
      //         title: "ERROR",
      //         description: "Advance Payment Not Updated",
      //         position: "top",
      //         status: "error",
      //         duration: 2000,
      //         isClosable: true,
      //       });
      //     }
      //   });


      // }



      const SaveBulkBalancePayment = async (e) => {
        e.preventDefault();
        let body = SelectedEmps.map((data)=>{
          return {
            pettycashAdvanceBalanceId:data.pettyCashAdvanceBalanceId,
            empGuId : data.empGuId,
            empName : data.empName,
            empCode : data.empCode,
            paymentStatus : ExpensePaymentPayAction,
            paidAmount : data.closingBalance,
            modeOfpayment : ExpensePaymentPayMode,
            paymentMonth : data.aMonth,
            paymentYear : data.aYear,
            companyId : userData.companyId,
            branchId : userData.isActiveBranch,
          }
        });


        console.log("Balance Pyment Bulk Body",body);

        await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveExpensePaymentInfoINList`,
          body
        )
        .then((response) => {
          const res = response.data;
          console.log("res", res);
  
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });

          GetPettyCashBalancePayment(PaymentMonthSelect.getMonth()+1,PaymentMonthSelect.getFullYear());

        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: "Advance Payment Not Updated",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });


      }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Expense Payment</h1>
        <div>
        <DatePicker
          className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2   outline-none"
          selected={PaymentMonthSelect}
          onChange={(date) => {
            setPaymentMonthSelect(date);
            let MonthSelected = date.getMonth() + 1;
            let YearSelected = date.getFullYear();
            GetPettyCashBalancePayment(MonthSelected,YearSelected);
          }}
          dateFormat="MM/yyyy"
          showMonthYearPicker
          placeholderText="Select Month"
        />
        </div>
      </div>

      {/* <Tabs variant="solid-rounded" size="sm" colorScheme="purple">
  <TabList>
    <Tab>Expense Payment</Tab>
    <Tab>Advance Payment</Tab>
  </TabList>

  <TabPanels>
    <TabPanel>

    <form onSubmit={SaveBulkExpensePayment}>
        <div className="px-5 flex py-3 space-x-5 ">
          <div className="my-auto">
           <FontAwesomeIcon className="rotate-180 text-xl" icon={faArrowTurnUp} />
          </div>
          <div>
          <Select isRequired value={ExpensePaymentPayAction} onChange={({target})=>{setExpensePaymentPayAction(target.value)}} bg borderColor={'gray.400'} placeholder='Select Pay Action'>
            <option value={'Pay'}>Pay</option>
            <option value={'Hold'}>Hold</option>
            <option value={'Pay Later'}>Pay Later</option>
          </Select>
          </div>
          <div>
          <Select isRequired value={ExpensePaymentPayMode} onChange={({target})=>{setExpensePaymentPayMode(target.value)}} bg borderColor={'gray.400'} placeholder='Select Payment Mode'>
            <option value={'Online'}>Online</option>
            <option value={'Check'}>Check</option>
            <option value={'Cash'}>Cash</option>
          </Select>  
          </div>
          <Button type="submit"  colorScheme='purple'>Save</Button>

        </div>
      </form>

    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}

        />
      </div>
    </TabPanel>
    <TabPanel>


    <form onSubmit={SaveBulkAdvancePayment}>
        <div className="px-5 flex py-3 space-x-5 ">
          <div className="my-auto">
           <FontAwesomeIcon className="rotate-180 text-xl" icon={faArrowTurnUp} />
          </div>
          <div>
          <Select isRequired value={AdvancePaymentPayAction} onChange={({target})=>{setAdvancePaymentPayAction(target.value)}} bg borderColor={'gray.400'} placeholder='Select Pay Action'>
            <option value={'Pay'}>Pay</option>
            <option value={'Hold'}>Hold</option>
            <option value={'Pay Later'}>Pay Later</option>
          </Select>
          </div>
          <div>
          <Select isRequired value={AdvancePaymentPayMode} onChange={({target})=>{setAdvancePaymentPayMode(target.value)}} bg borderColor={'gray.400'} placeholder='Select Payment Mode'>
            <option value={'Online'}>Online</option>
            <option value={'Check'}>Check</option>
            <option value={'Cash'}>Cash</option>
          </Select>  
          </div>
          <Button type="submit"  colorScheme='purple'>Save</Button>

        </div>
      </form>

    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={PettyCashPaymentRowData} // Row Data for Rows
          columnDefs={PettyCashPaymentColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>

  </TabPanels>
</Tabs> */}

<form onSubmit={SaveBulkBalancePayment}>
        <div className="px-5 flex py-3 space-x-5 ">
          <div className="my-auto">
           <FontAwesomeIcon className="rotate-180 text-xl" icon={faArrowTurnUp} />
          </div>
          <div>
          <Select isRequired value={ExpensePaymentPayAction} onChange={({target})=>{setExpensePaymentPayAction(target.value)}} bg borderColor={'gray.400'} placeholder='Select Pay Action'>
            <option value={'Pay'}>Pay</option>
          </Select>
          </div>
          <div>
          <Select isRequired value={ExpensePaymentPayMode} onChange={({target})=>{setExpensePaymentPayMode(target.value)}} bg borderColor={'gray.400'} placeholder='Select Payment Mode'>
            <option value={'Online'}>Online</option>
            <option value={'Check'}>Check</option>
            <option value={'Cash'}>Cash</option>
          </Select>  
          </div>
          <Button type="submit"  colorScheme='purple'>Save</Button>

        </div>
      </form>

<div className="ag-theme-alpine">

<AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={NewColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
         rowSelection={'multiple'}
         onSelectionChanged={onSelectionChanged}
        />
      </div>


    </div>
  )
}

export default ExpensePayment