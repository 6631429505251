import React, { useState } from 'react'

const HelpGuideMenu = ({
    children,
    activecondition,
  }) => {

    const [open, setOpen] = useState(activecondition);
    const handleClick = () => {
      setOpen(!open);
    }

  return (
    <li className={`py-2 rounded-lg mb-0.5 last:mb-0`}>
      {children(handleClick, open)}
    </li>
  )
}

export default HelpGuideMenu