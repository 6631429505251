import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPlus,
  faArrowTurnUp
} from "@fortawesome/free-solid-svg-icons";
import { Avatar, Button, useDisclosure, useToast,  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, 
  Switch} from '@chakra-ui/react';
import axios from "axios";
import DatePicker from "react-datepicker";
import { CheckCircle } from "react-feather";

function GeoFencingAttendance()  {

    const userData = JSON.parse(localStorage.getItem('user'));
    const CurrentDate = new Date().toLocaleDateString('en-CA');
 //   console.log("Current Date",CurrentDate);
 const [SelectedEmpList, setSelectedEmpList] = useState([]);

 const {
  isOpen: isViewPhotOopen,
  onOpen: onViewPhotOopen,
  onClose: onViewPhotoClose,
} = useDisclosure();



const {
  isOpen: isEmpGeoLocationDataOpen,
  onOpen: onEmpGeoLocationDataOpen,
  onClose: onEmpGeoLocationDataClose,
} = useDisclosure();


const [EmpGeoLocationRowData, setEmpGeoLocationRowData] = useState([]);


const [OriginalPhoto, setOriginalPhoto] = useState();
const [CapturingPhoto, setCapturingPhoto] = useState();

    const toast = useToast();
    const id = "toast";

    const [AttendanceDate, setAttendanceDate] = useState(new Date());

    const [EmpGeoLocColumnDefs, setEmpGeoLocColumnDefs] = useState([
      {
        headerName: "Geo Distance",
        field: "geoDistance",
      },
        {
          headerName: "Latitute",
          field: "latitude",
        },
        {
          headerName: "Longitude",
          field: "longitude",
        },
        // {
        //   headerName: "Location",
        //   field: "linkDevice",
        // },
        {
          headerName: "Action",
          field: "isActive",
          floatingFilter: false,
          cellRenderer:(params) => <Switch colorScheme='green' isChecked={params.data.isActive} onChange={({target})=>{
            HandleGeoLocationSwitch(target.checked,params.data)
          }} size={'md'} />
        },

    ]);


    const HandleGeoLocationSwitch = async (checked,LocationData) => {

      setEmpGeoLocationRowData(prev => {
        const newState = prev.map((obj)=>{
          if(obj.gId ===LocationData.gId ){
            return {...obj, isActive: checked};
          }
          return obj;
        })
        return newState
      })

    }

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
          {
            headerName: "Employee Name",
            field: "empName",
            headerCheckboxSelection: true,
            checkboxSelection: (params) => {
              return params.data.empName;
            }, 
          },
          {
            headerName: "Emp Code",
            field: "empcode",
          },
          {
            headerName: "Attendance Date",
            field: "attendanceDate",
            valueGetter:(params) => new Date(params.data.attendanceDate).toLocaleDateString('en-GB')
          },
          {
            headerName: "In Time",
            field: "inTime",
          },
          {
            headerName: "Out Time",
            field: "outTime",
          },
          {
            headerName: "Original Image",
            field: "imagePath",
            cellRenderer:(params) => <Avatar size={'sm'}  className='cursor-pointer'
            onClick={()=>{
              setOriginalPhoto(params.data.imagePath);
              setCapturingPhoto(params.data.capturingImage);
              onViewPhotOopen();
            }} src={params.data.capturingImage}/>
          },
          {
            headerName: "Capturing Image",
            field: "capturingImage",
            cellRenderer:(params) => <Avatar size={'sm'}  className='cursor-pointer'
            onClick={()=>{
              setOriginalPhoto(params.data.imagePath);
              setCapturingPhoto(params.data.capturingImage);
              onViewPhotOopen();
            }} src={params.data.capturingImage}/>
          },
          {
            headerName: "Location Status",
            field: "geoLocationStatus",
            cellRenderer:(params)=>(
              params.data.geoLocationStatus === 'OUT Of Range' ?
              <span className="bg-red-500 p-1  rounded-lg text-white">
                {params.data.geoLocationStatus}
              </span> : params.data.geoLocationStatus === 'IN' ?
               <span className="bg-green-500 p-1 rounded-lg text-white">
              {params.data.geoLocationStatus}
             </span> : '')
          },
          {
            headerName: "Distance",
            field: "geoDistance",
            cellRenderer:(params)=>(
              params.data.geoLocationStatus === 'OUT Of Range' ?
              <span onClick={()=>{GetEmpGeoLocationData(params.data.empGuId)}} className="bg-red-500 p-1 cursor-pointer rounded-lg text-white">
                <FontAwesomeIcon icon={faPlus} className='mr-1' /> {params.data.geoDistance}
              </span> : params.data.geoLocationStatus === 'IN' ?
               <span onClick={()=>{
                onEmpGeoLocationDataOpen();
               }} className="bg-green-500 p-1 cursor-pointer rounded-lg text-white">
               <FontAwesomeIcon icon={faPlus} className='mr-1' /> {params.data.geoDistance}
             </span> : ''
            )
          },
          // {
          //   headerName: "Latitute",
          //   field: "latitude",
          // },
          // {
          //   headerName: "Longitude",
          //   field: "longitude",
          // },
          {
            headerName: "Device",
            field: "linkDevice",
          },

          {
            headerName: "Status",
            field: "attendanceStatus",
            cellRenderer: (params) => 
            params.data.attendanceStatus === "P"?
            <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
            P
          </span>:
           params.data.attendanceStatus === "L"?
          <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          L
        </span>
          :
           params.data.attendanceStatus === "A"?
          <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          A
        </span> :
        params.data.attendanceStatus === "WO"?
       <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
       WO
     </span> 
        :""

          },
          {
            headerName: "Action",
            floatingFilter: false,
            cellRenderer:(params) =>params.data.attendanceStatus === "A"? <Button size={'xs'} 
            onClick={async()=>{

              let EmpData = params.data
              let body = {
                empCode: EmpData.empcode,
                empGuid: EmpData.empGuId,
                aDate: EmpData.attendanceDate,
                leaveTypeId:0,
                isLop: false,
                isPresent:true,
                companyId: userData.companyId,
                branchId: userData.isActiveBranch
              }

              console.log("Approve attendance Body",body);

              await axios
              .post(
                `${process.env.REACT_APP_ACQ_URL}/api/LOPAdjustment/SaveAttendanceLop`,body)
              .then((response) => {
                console.log(response);
                const res = response.data;
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: res,
                    position: "top",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                }
        
                GetGeoAttendanceData(AttendanceDate);
        
              })
              .catch((error) => {
                console.error(error);
        
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: "ERROR",
                    description: `Attendance not Updated`,
                    position: "top",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              });
        
            }}
            colorScheme={'green'}><CheckCircle size={'16px'} className="mr-1" />Approve</Button> : ''
        },
      ]);
  
            // Table Pagination
            const paginationNumberFormatter = useCallback((params) => {
              return "[" + params.value.toLocaleString() + "]";
            }, []);
          
            //Table columns properties applies to all columns
            const defaultColDef = useMemo(() => ({
              //  flex: 1,
              sortable: true,
              filter: "agTextColumnFilter",
              floatingFilter: true,
              cacheQuickFilter: true,
              cellClass: 'no-border'
            }));

            const onSelectionChanged = useCallback(() => {
              var EmpList = gridRef.current.api.getSelectedRows();
             setSelectedEmpList(EmpList);
            }, []);

            useEffect(() => {
                GetGeoAttendanceData(AttendanceDate);
              }, [])


              

            const GetGeoAttendanceData = async(DATE) =>{
                console.log(DATE.toLocaleDateString('en-CA'));
                try {
                    const response= await axios.get(
                        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/FetchGeodataList?Attendancedate=${DATE.toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                      )
                      console.log("Get Geo Attendance Response",response);
                      setRowData(response.data)
                    
                } catch (error) {
                    console.error(error);
                }
            }


               const GetEmpGeoLocationData = async (EmpGuid) => {
                try {
                  const response= await axios.get(
                      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetGeofencingListByGUId?EmpGuid=${EmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                    )
                    console.log("Get Emp Geo Attendance",response);
                    setEmpGeoLocationRowData(response.data)
                    onEmpGeoLocationDataOpen();
              } catch (error) {
                  console.error(error);
              }
               }


               const HandleTreatAsPresent = async () => {

                let body =SelectedEmpList.map((data)=>{
                   return {
                     empCode: data.empcode,
                     empGuid: data.empGuId,
                     aDate: data.attendanceDate,
                     leaveTypeId:0,
                     isLop: false,
                     isPresent:true,
                     companyId: userData.companyId,
                     branchId: userData.isActiveBranch
                   }
                 })

                 console.log("Geo Att Body",body);
               
               
                 await axios.post(
                   `${process.env.REACT_APP_ACQ_URL}/api/LOPAdjustment/SaveAttendanceLopList`,body
                 )
                 .then((response) => {
                   const res = response.data;
                   console.log("res",res);
                   if (!toast.isActive(id)) {
                     toast({
                       id,
                       title: res,
                       position: 'top',
                       status: 'success',
                       duration: 2000,
                       isClosable: true,
                     })
                   }
               
                   GetGeoAttendanceData(AttendanceDate);
                 })
                 .catch((error) => {
                   console.log(error.response);
                   console.log(error.response.data);
                   if (!toast.isActive(id)) {
                     toast({
                       id,
                       title: 'ERROR',
                       description: "Attendance Not Edited",
                       position: 'top',
                       status: 'error',
                       duration: 2000,
                       isClosable: true,
                     })
                   }
                 });
               
               }

            

  return (
    <div>

<div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Geo-Fencing Attendance</h1>
        <div className="flex items-center">
            <label className="font-semibold text-lg mr-3">Attendance Date :</label>
            <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={AttendanceDate}
              onChange={(date) => {setAttendanceDate(date); GetGeoAttendanceData(date); }}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Attendance Date'
            />
            </div>
        </div>
      </div>

      <div className="px-5 flex items-center pb-4 space-x-5 ">
        <FontAwesomeIcon className="rotate-180 text-xl" icon={faArrowTurnUp} />
          <Button onClick={()=>{HandleTreatAsPresent();}} size={'sm'} colorScheme='green'><CheckCircle size={'16px'} className="mr-2" />Approve</Button>
        </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          onSelectionChanged={onSelectionChanged}
          rowSelection={'multiple'}
        />
      </div>


      <Modal size={'xl'} isOpen={isViewPhotOopen} onClose={()=>{
        setOriginalPhoto('')
        setCapturingPhoto('')
        onViewPhotoClose();
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attendance Photo</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>

            <div className="grid grid-cols-2 gap-4">
              <div className="text-center"> 
                <h6 className="mb-2 font-bold ">Original Photo</h6>
                <div>
                <img className="h-60 rounded-xl" src={OriginalPhoto}/>
                </div>
              </div>


              <div className="text-center"> 
                <h6 className="mb-2 font-bold ">Captured Photo</h6>
                <div>
                <img className="h-60 rounded-xl" src={CapturingPhoto}/>

                  </div>
              </div>

            </div>
          </ModalBody>
<hr />
          <ModalFooter>
            <Button  colorScheme='purple' mr={3} onClick={onViewPhotoClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal size={'xl'} isOpen={isEmpGeoLocationDataOpen} onClose={onEmpGeoLocationDataClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Employee Geo Locations</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>

          <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={EmpGeoLocationRowData} // Row Data for Rows
          columnDefs={EmpGeoLocColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          // pagination={true}
          // paginationPageSize={10}
          // paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
          </ModalBody>
<hr />
          <ModalFooter>
            <Button  colorScheme='purple' mr={3} onClick={onEmpGeoLocationDataClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default GeoFencingAttendance