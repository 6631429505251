import React,{useState,useEffect} from "react";
import axios from "axios";
import { XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, AreaChart, Area } from "recharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {  faUsers, faUserXmark, faClock } from "@fortawesome/free-solid-svg-icons";
import { Avatar, Button } from '@chakra-ui/react';
import { Clock } from "react-feather";
import DatePicker from "react-datepicker";

export default function AtttendanceDashboard () {

    const userData = JSON.parse(localStorage.getItem("user"));
    const [AttendanceDate, setAttendanceDate] = useState(new Date());
    const [AttendanceStats, setAttendanceStats] = useState([])


    const [AttendanceChartData, setAttendanceChartData] = useState([]);

    const [ActiveChartButton, setActiveChartButton] = useState();

    useEffect(()=>{
      GetDailyAttendanceStats(AttendanceDate);
      HandleDurationChart('5D')
      },[])


      const formatXAxis = (tickItem) => {
        if(ActiveChartButton === '5D' || ActiveChartButton === '1M' ){
          return new Date(tickItem).toLocaleDateString('en-GB');
        }else{
          return tickItem
        }
      }      


      const GetDailyAttendanceStats = async(DATE) =>{
        try {
            const response= await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetDashBoardData?Attendancedate=${DATE.toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
              )
              console.log(" Daily Attendance Stats Response",response);
              setAttendanceStats(response.data)
            
        } catch (error) {
            console.error(error);
        }
    }
       
  


    const HandleDurationChart=async(Duration) =>{

      console.log("Chart Duration",Duration);
      setActiveChartButton(Duration)

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetDashBoardGrapfhData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&Duration=${Duration}&Attendancedate=${new Date().toLocaleDateString('en-CA')}`
        )
        console.log("Area Chart Data",response);
        setAttendanceChartData(response.data);
      } catch (error) {
        console.error(error)
  
      }

    }
  


  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Attendance Dashboard</h1>
        <div className="flex items-center">
            <label className="font-semibold text-lg mr-3">Date :</label>
            <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={AttendanceDate}
              onChange={(date) => {setAttendanceDate(date); GetDailyAttendanceStats(date); }}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Attendance Date'
            />
            </div>

        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mb-6 gap-6">
      <div  className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
                  <div className="flex flex-col justify-between">
                    <p className="text-sm font-medium text-gray-500">Total Employees</p>
        
                    <h6 className="text-3xl font-bold">{AttendanceStats.totalEmpCount||0}</h6>
        
                    <p className="text-sm text-gray-50">-</p>
                  </div>
                  <div className="align-middle my-auto">
                    <div className="bg-red-100 rounded-full ">
                      <Avatar
                        bg="green.100"
                        size="md"
                        icon={
                          <FontAwesomeIcon
                            className=" text-green-600 text-xl"
                            icon={faUsers}
                          />
                        }
                      />
                    </div>
                  </div>
                </div>

<div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
          <div className="flex flex-col justify-between">
            <p className="text-sm font-medium text-gray-500">Early / On Time</p>
            <h6 className="text-3xl font-bold">{AttendanceStats.totalOnTime || 0}</h6>
            <p className="text-sm text-gray-50">-</p>
          </div>
          <div className="align-middle my-auto">
            <div className="bg-orange-100 rounded-full">
              <Avatar
                bg="blue.100"
                size="md"
                icon={
                  <FontAwesomeIcon
                    className=" text-blue-600 text-2xl"
                    icon={faClock}
                  />
                }
              />
            </div>
          </div>
        </div>


        <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
          <div className="flex flex-col justify-between">
            <p className="text-sm font-medium text-gray-500">
              Late
            </p>
            <h6 className="text-3xl font-bold">{AttendanceStats.totalLate || 0}</h6>
            <p className="text-sm text-gray-50">-</p>
          </div>
          <div className="align-middle my-auto">
            <div className="bg-purple-100 rounded-full ">
              <Avatar
                bg="red.100"
                size="md"
                icon={
                  <Clock color={'red'} size={'28px'} />
                  // <FontAwesomeIcon
                  //   className=" text-red-600 text-3xl"
                  //   icon={faClock}
                  // />
                }
              />
            </div>
          </div>
        </div>

        <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
          <div className="flex flex-col justify-between">
            <p className="text-sm font-medium text-gray-500">Total Absent</p>
            <h6 className="text-3xl font-bold">{AttendanceStats.totalAbsent}</h6>
            <p className="text-sm text-gray-50">-</p>
          </div>
          <div className="align-middle my-auto">
            <div className="bg-red-100 rounded-full">
              <Avatar
                bg="red.100"
                size="md"
                icon={
                  <FontAwesomeIcon
                    className=" text-red-600 text-xl"
                    icon={faUserXmark}
                  />
                }
              />
            </div>
          </div>
        </div>


      </div> 


      <div className="bg-white p-4 rounded-2xl">

        <div className="space-x-4 mt-4 mb-6 text-center">
          <Button onClick={()=>{HandleDurationChart('5D')}} size={'sm'} variant={ActiveChartButton === '5D'? 'solid' : 'outline'} colorScheme='purple'>5D</Button>
          <Button onClick={()=>{HandleDurationChart('1M')}} size={'sm'} variant={ActiveChartButton === '1M'? 'solid' : 'outline'} colorScheme='purple'>1M</Button>
          <Button onClick={()=>{HandleDurationChart('6M')}} size={'sm'} variant={ActiveChartButton === '6M'? 'solid' : 'outline'} colorScheme='purple'>6M</Button>
          <Button onClick={()=>{HandleDurationChart('YTD')}} size={'sm'} variant={ActiveChartButton === 'YTD'? 'solid' : 'outline'} colorScheme='purple'>YTD</Button>
          <Button onClick={()=>{HandleDurationChart('1Y')}} size={'sm'} variant={ActiveChartButton === '1Y'? 'solid' : 'outline'} colorScheme='purple'>1Y</Button>
          <Button onClick={()=>{HandleDurationChart('2Y')}} size={'sm'} variant={ActiveChartButton === '2Y'? 'solid' : 'outline'} colorScheme='purple'>2Y</Button>
          <Button onClick={()=>{HandleDurationChart('3Y')}} size={'sm'} variant={ActiveChartButton === '3Y'? 'solid' : 'outline'} colorScheme='purple'>3Y</Button>
          <Button onClick={()=>{HandleDurationChart('5Y')}} size={'sm'} variant={ActiveChartButton === '5Y'? 'solid' : 'outline'} colorScheme='purple'>5Y</Button>

        </div>

      <ResponsiveContainer height={400}>
      <AreaChart width={730} height={300} data={AttendanceChartData}
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}>
        <defs>
          <linearGradient id="presentColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="8%" stopColor="#82ca9d" stopOpacity={0.4}/>
            <stop offset="92%" stopColor="#82ca9d" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="absentColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="8%" stopColor="#f44336" stopOpacity={0.4}/>
            <stop offset="92%" stopColor="#f44336" stopOpacity={0}/>
          </linearGradient>
          <linearGradient id="leaveColor" x1="0" y1="0" x2="0" y2="1">
            <stop offset="8%" stopColor="#60A5FA" stopOpacity={0.4}/>
            <stop offset="92%" stopColor="#60A5FA" stopOpacity={0}/>
          </linearGradient>
        </defs>
        <XAxis dataKey="attendanceDate" tickFormatter={formatXAxis}/>
        <YAxis />
        <CartesianGrid strokeDasharray="3 3" />
        <Tooltip />
        <Legend verticalAlign="top" height={36}/>
        <Area type="monotone" dataKey="totalPresent" strokeWidth={2} name="Present Employees" stroke="#82ca9d" fillOpacity={1} fill="url(#presentColor)" />
        <Area type="monotone" dataKey="totalAbsent" strokeWidth={2} name="Absent Employees" stroke="#f44336" fillOpacity={1} fill="url(#absentColor)" />
        <Area type="monotone" dataKey="totalLeave" strokeWidth={2} name="On Leave Employees" stroke="#60A5FA" fillOpacity={1} fill="url(#leaveColor)" />
      </AreaChart>

          </ResponsiveContainer>


      </div>

      
      
    </div>
  );
}
