import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import ReactSelect from 'react-select';
import { Box, Button,  Tabs, TabList, TabPanels, Tab, TabPanel, Spinner, Center, useSafeLayoutEffect } from "@chakra-ui/react";
import { Search } from "react-feather";
import _ from "lodash";


function OvertimeReport()  {

    const userData = JSON.parse(localStorage.getItem("user"));
    const [SelectedEmpOvertimeData, setSelectedEmpOvertimeData] = useState([]);
    const [AllEmployeeDateRange, setAllEmployeeDateRange] = useState([null,null]);
    const [AllEmployeeStartDate, AllEmployeeEndDate] = AllEmployeeDateRange;

const [OvertimePaymentRowData, setOvertimePaymentRowData] = useState([]);
    
    const [EmpList, setEmpList] = useState([]);

    const [IsLoading, setIsLoading] = useState(false);

    const [ViewEmpRowData, setViewEmpRowData] = useState([]);

    const [OvertimePaymentMonth, setOvertimePaymentMonth] = useState(new Date());


    const [SelectedEmp, setSelectedEmp] = useState([]);
    const [DateRange, setDateRange] = useState([null,null]);
    const [StartDate, EndDate] = DateRange;

    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const gridRef = useRef();
    const [rowData, setRowData] = useState([ ]); // Table Data Variable
  const [AllEmployeeRowData, setAllEmployeeRowData] = useState([]);

    const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
      const defaultColDef = useMemo(() => ({
       flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: "no-border",
      }));

      const ConvertToHoursMinutes = (time) => {
        const date = new Date("1970-01-01T" + time + "Z");
  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();
    return hours + " hours " + minutes + " minutes"
      }


      const [columnDefs, setColumnDefs] = useState([
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Emp Name",
          field: "empName",
        },


          {
              headerName: "Overtime Date",
              field: "attendanceDate",
              valueGetter:(params)=> new Date(params.data.attendanceDate).toLocaleDateString('en-GB')
            },
            {
              headerName: "In-Time",
              field: "inTime",
            },
            {
              headerName: "Out-Time",
              field: "outTime",
            },
            {
              headerName: "Total Time",
              field: "totalTime",
              valueGetter:(params) => ConvertToHoursMinutes(params.data.totalTime)
            },
            {
                headerName: "Final Overtime",
                field: "finalOverTime",
                valueGetter:(params) => ConvertToHoursMinutes(params.data.finalOverTime)

              },

              {
                headerName: "Status",
                field: "isStatus",
                cellRenderer:(params) => (
                  params.data.isStatus === 0 ?
                  <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                    Pending
                  </span> : params.data.isStatus === 1 ? 
                  <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                    Approved
                  </span> :  params.data.isStatus === 2 ? 
                  <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                  Rejected
                  </span> : params.data.isStatus === 3 ? 
                  <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
                  Cancelled
                  </span> : ''
                )
              },

      ]);


      const [AllEmployeeColumnDefs, setAllEmployeeColumnDefs] = useState([
        {
          headerName: "Emp Name",
          field: "empName",
        },

        {
          headerName: "Emp Code",
          field: "empCode",
        },
          {
              headerName: "Eligible Date",
              field: "overtimeEligibleDate",
              valueGetter:(params)=> new Date(params.data.overtimeEligibleDate).toLocaleDateString('en-GB')
            },
            {
              headerName: "Min Overtime",
              field: "overTimeMin",
              valueGetter:(params) => ConvertToHoursMinutes(params.data.overTimeMin)
            },
            {
              headerName: "Max Overtime",
              field: "overTimeMax",
              valueGetter:(params) => ConvertToHoursMinutes(params.data.overTimeMax)
            },
            {
              headerName: "Total Overtime",
              field: "totalCalcOvertime",
            },
            {
                headerName: "Final Approved Overtime",
                field: "finalApprovedOvertime",
              },
              {
                headerName: "Action",
                cellRenderer:(params) => <Button
                   onClick={()=>{
                    // let EmpOverData = params.data;
    
                    setSelectedEmpOvertimeData(params.data);
                    HandleViewOvertime(params.data.dataFromDate,params.data.dataToDate,params.data.empGuId)
                   }}
                 size={'xs'} colorScheme='blue'>View</Button>
              }

      ]);
    


      
      const [OverimePaymentColumnDefs, setOverimePaymentColumnDefs] = useState([
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Emp Name",
          field: "empName",
        },


        
        {
          headerName: "Payment Month",
          valueGetter:(params)=> `${params.data.month}/${params.data.year}`
        },

            {
              headerName: "Total Overtime",
              field: "totalOvertime",
              valueGetter:(params) => ConvertToHoursMinutes(params.data.totalOvertime)
            },
            {
              headerName: "Pay Overtime",
              field: "payOvertime",
              valueGetter:(params) => ConvertToHoursMinutes(params.data.payOvertime)
            },
            {
              headerName: "Calculated Amount",
              field: "calculatedAmount",
            },

            {
              headerName: "Payable Amount",
              field: "payableAmount",
            },


      ]);
    

    useEffect(()=>{
      GetOvertimeEmpEligibleList();
      },[])


      const GetOvertimeEmpEligibleList = async () =>{

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOvertimeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Eligible Overtime List",response.data);

          const res = response.data;

          let EligibleList = res.filter(data => data.isActive === true)
          setEmpList(EligibleList);
        } catch (error) {
          console.error(error)
        }

      }



      const HandleSearch = async () => {
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetAttendanceTotalOverTimeListData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&FromDate=${new Date(StartDate).toLocaleDateString('en-CA')}&ToDate=${new Date(EndDate).toLocaleDateString('en-CA')}&AttStatus=P&EmpGuid=${SelectedEmp.empGuid}`
            )
            console.log("Emp Overtime Data List",response.data);
            const res = response.data;
            let FinalOvertimeData = res.filter((data)=> data.finalOverTime !== "00:00:00")

            HandleFinalTableData(FinalOvertimeData)
          } catch (error) {
            console.error(error)
          }
      }


      console.log("Selected Emp",SelectedEmp);

      const HandleFinalTableData = async (FinalOvertimeData) => {

        console.log("Final Overtime List",FinalOvertimeData);

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOverTimeFinalListData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
           console.log("Final Data List",response.data);

           const res = response.data;


          //  console.log("Value Check", _.compact(res.map((Finaladata)=>{
          //   if(Finaladata.empGuid === "c0f35713-fd11-4b77-aca1-e278b0d32a0c" && Finaladata.overtimeDatefrom === "2023-01-03T00:00:00"){
          //      return  Finaladata.reportingOfficeId
          //   }
          //  }))[0])
           
          let FinalRowData = FinalOvertimeData.map((data)=>{
              return {...data,
                isStatus : _.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.isStatus 
                  }
                 }))[0] || 0,
                isLock :  _.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.isLock
                  }
                 }))[0] || false,
                 reportingOfficeId :_.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.reportingOfficeId 
                  }
                 }))[0] || 0
              }
           })

           console.log("Final Row Data",FinalRowData)
          setRowData(FinalRowData)

        } catch (error) {
          console.error(error)
        }
      }

      function filterAboveDate(specificDate, data) {
        const date = new Date(specificDate);
        return data.filter(item => {
          const itemDate = new Date(item.attendanceDate);
          if(itemDate >= date){
            return item
          }
        });
      }

      const CalculateTotalOvertime = (data)=>{
        let minutesArray = data.map(item => {
          const time = item.finalOverTime.split(':');
          const hours = parseInt(time[0]);
          const mins = parseInt(time[1]);
          return (hours * 60) + mins;
        });

        const totalMinutes = minutesArray.reduce((total, current) => total + current, 0);

        return Math.floor(totalMinutes / 60) + ' Hours ' + totalMinutes % 60 + ' Minutes';
      }


      const HandleAllEmployeeSearch = async () => {

        setIsLoading(true);

        try {

          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetAttendanceAllEmpOverTimeListData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&FromDate=${new Date(AllEmployeeStartDate).toLocaleDateString('en-CA')}&ToDate=${new Date(AllEmployeeEndDate).toLocaleDateString('en-CA')}&AttStatus=p`
          )
          console.log("Overtime Request List",response);

          const res = response.data;

          let FinalOvertimeData = res.filter((data)=> data.finalOverTime !== "00:00:00")
          // setAllEmployeeRowData(_.uniqBy(FinalOvertimeData,'empGuId').map((data)=>{
          //   return {...data,
          //       empGuId : data.empGuId,
          //       empName : data.empName,
          //       empCode : data.empCode,
          //       dataFromDate : AllEmployeeStartDate,
          //       dataToDate : AllEmployeeEndDate,
          //       totalCalcOvertime: CalculateTotalOvertime(filterAboveDate(data.overtimeEligibleDate, FinalOvertimeData.filter((AllData)=>AllData.empGuId === data.empGuId)))
          //   }
          // }));

          HandleAllEmpFinalTableData(FinalOvertimeData)

        } catch (error) {
          console.error(error);
        }

      }


      const HandleAllEmpFinalTableData = async (FinalOvertimeData) => {

        console.log("Final Overtime List",FinalOvertimeData);

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOverTimeFinalListData?CompanyId=1&BranchId=1&month=12&year=2022`
          )
           console.log("Final Data List",response.data);

           const res = response.data;


          //  console.log("Value Check", _.compact(res.map((Finaladata)=>{
          //   if(Finaladata.empGuid === "c0f35713-fd11-4b77-aca1-e278b0d32a0c" && Finaladata.overtimeDatefrom === "2023-01-03T00:00:00"){
          //      return  Finaladata.reportingOfficeId
          //   }
          //  }))[0])
           
          let FinalRowData = FinalOvertimeData.map((data)=>{
              return {...data,
                isStatus : _.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.isStatus 
                  }
                 }))[0] || 0,
                isLock :  _.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.isLock
                  }
                 }))[0] || false,
                 reportingOfficeId :_.compact(res.map((Finaladata)=>{
                  if(Finaladata.empGuid === data.empGuId && Finaladata.overtimeDatefrom === data.attendanceDate ){
                     return  Finaladata.reportingOfficeId 
                  }
                 }))[0] || 0
              }
           })

           console.log("Final Row Data",FinalRowData)
          // setAllEmployeeRowData(FinalRowData);


          let AllEmpUniqRowData = _.uniqBy(FinalRowData,'empGuId').map((data)=>{
            return {...data,
                empGuId : data.empGuId,
                empName : data.empName,
                empCode : data.empCode,
                dataFromDate : AllEmployeeStartDate,
                dataToDate : AllEmployeeEndDate,
                totalCalcOvertime: CalculateTotalOvertime(filterAboveDate(data.overtimeEligibleDate, FinalRowData.filter((AllData)=>AllData.empGuId === data.empGuId))),
                finalApprovedOvertime: CalculateTotalOvertime(FinalRowData.filter((AllData)=>AllData.empGuId === data.empGuId && AllData.isLock === true)),
            }
          })

          console.log("All Emp Uniq Row Data",AllEmpUniqRowData)

          setAllEmployeeRowData(AllEmpUniqRowData.filter(data => data.finalApprovedOvertime !== "0 Hours 0 Minutes"));
          setIsLoading(false)
        } catch (error) {
          console.error(error)
        }
      }
      

      console.log("All Employee Row Data",AllEmployeeRowData);


      const HandleViewOvertime = async (FromDate,ToDate,RowEmpGuid) => {

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetAttendanceAllEmpOverTimeListReportingpersonData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&FromDate=${new Date(FromDate).toLocaleDateString('en-CA')}&ToDate=${new Date(ToDate).toLocaleDateString('en-CA')}&AttStatus=p&EmpGuid=${userData.guId}`
          )
  
          console.log("View Emp Overtime Requests",response);
  
          const res = response.data;
  
          let FinalOvertimeData = res.filter((data)=> data.finalOverTime !== "00:00:00")

        } catch (error) {
          console.error(error)
        }

      }


      const HandlePaymentSearch = async () =>{
        setIsLoading(true);
        try{
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOvertimePaymentInfo?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${new Date(OvertimePaymentMonth).getMonth()+1}&year=${new Date(OvertimePaymentMonth).getFullYear()}`
          )
          console.log("Overtime Payment Data Response",response);

          setOvertimePaymentRowData(response.data);
          setIsLoading(false);
        }catch(error){
          console.error(error)
        }
      }
    

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Overtime Report</h1>
      </div>


      <Tabs colorScheme={'purple'} variant={'solid-rounded'} size={'sm'}>
  <TabList>
    <Tab>Payment</Tab>
    <Tab>Indivdual Overtime</Tab>
  </TabList>

  <TabPanels>
    <TabPanel px={0}>
    {/* <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end  '>

        <div className="space-y-2">
            <label>Select Date Range</label>
            <DatePicker
                className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
              selectsRange={true}
              startDate={AllEmployeeStartDate}
              endDate={AllEmployeeEndDate}
              onChange={(update) => {
                 setAllEmployeeDateRange(update)
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Date Range'
            />
        </div>

        <Button colorScheme={'purple'} onClick={()=>{HandleAllEmployeeSearch();}}><Search size={'16px'} className="mr-2" />Search</Button>

      </Box>

      {IsLoading === false ? 
      <div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={AllEmployeeRowData} // Row Data for Rows
          columnDefs={AllEmployeeColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
      : <Center>

      <Spinner   thickness='4px' color={'purple.500 '}  size='lg'  />
      </Center> } */}



<Box className='bg-white rounded-2xl p-4 flex gap-8 items-end  '>

<div className="space-y-2">
    <label>Select Month</label>
    <DatePicker
      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
      selected={OvertimePaymentMonth}
      onChange={(date) => {
        setOvertimePaymentMonth(date);
      }}
      showMonthYearPicker
      dateFormat="MM/yyyy"
      placeholderText="Select Month/Year"
    />
</div>

<Button leftIcon={<Search size={'16px'} />} colorScheme={'purple'} onClick={()=>{HandlePaymentSearch();}}>Search</Button>

</Box>

{IsLoading === false ? 
<div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={OvertimePaymentRowData} // Row Data for Rows
          columnDefs={OverimePaymentColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
      : <Center>

      <Spinner   thickness='4px' color={'purple.500 '}  size='lg'  />
      </Center> } 
    </TabPanel>





    {/* Individual Panel */}


    <TabPanel px={0}>
    <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end  mb-4'>
        <div className='space-y-2 w-1/4'>
            <label>Select Employee</label>
            <ReactSelect
               options={EmpList}
               value={SelectedEmp}
               onChange={(value) => {setSelectedEmp(value); }}
               getOptionLabel={(option) => option.empName}
               getOptionValue={(option) => option.empGuid}
               placeholder="Select Employee"
              />
        </div>

        <div className="space-y-2">
            <label>Select Date Range</label>
            <DatePicker
                className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
              selectsRange={true}
              startDate={StartDate}
              minDate={new Date(SelectedEmp.overtimeEligibleDate)}
              endDate={EndDate}
              onChange={(update) => {
                 setDateRange(update)
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Date Range'
            />
        </div>

        <Button isDisabled={SelectedEmp.length === 0  ?true:false} colorScheme={'purple'} onClick={()=>{HandleSearch();}}><Search size={'16px'} className="mr-2" />Search</Button>

      </Box>

      {SelectedEmp.length !== 0 ? 

      <div className='bg-white p-4 rounded-2xl drop-shadow-md mb-5'>
            <div className="flex  gap-8">
                  <div>
                    <p className="text-sm text-gray-500">Emp Name</p>
                    <h6 className="font-medium">{SelectedEmp.empName}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Overtime Min</p>
                    <h6 className="font-medium">{ConvertToHoursMinutes(SelectedEmp.overtimeMin)}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Overtime Max</p>
                    <h6 className="font-medium">{ConvertToHoursMinutes(SelectedEmp.overTimeMax)}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Overtime Eligible Date</p>
                    <h6 className="font-medium">{new Date(SelectedEmp.overtimeEligibleDate).toLocaleDateString('en-GB')}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Calculation Type</p>
                    <h6 className="font-medium">{SelectedEmp.attendanceTypeName}</h6>
                  </div>
                </div>

            </div> : ''}



      <div className="ag-theme-alpine mt-4">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>
  </TabPanels>
</Tabs>




    </div>
  )
}

export default OvertimeReport