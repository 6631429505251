import {
  Input,
  Checkbox,
  Button,
  useDisclosure,
  useToast,
  Modal,
  Select,
  Tooltip,
  InputGroup,
  InputRightAddon,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Textarea,
} from "@chakra-ui/react";
import {
  faArrowTurnUp,
  faDownload,
  faFileArrowUp,
  faPlus,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, {
  useRef,
  useMemo,
  useState,
  useCallback,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import * as XLSX from "xlsx";
import { CSVLink, CSVDownload } from "react-csv";

import ReactSelect from "react-select";

function Bonus() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";
  let CurrentYear = new Date().getFullYear();
  // console.log("Current Year", CurrentYear);

  let CurrentMonth = new Date().getMonth() + 1;

  const [ImportData, setImportData] = useState([]);

  const BonusGridRef = useRef();

  const [SelectedEmployeesList, setSelectedEmployeesList] = useState([]);
  const {
    isOpen: isAddBonusOpen,
    onOpen: onAddBonusOpen,
    onClose: onAddBonusClose,
  } = useDisclosure();

  const {
    isOpen: isAddBonusTypeOpen,
    onOpen: onAddBonusTypeOpen,
    onClose: onAddBonusTypeClose,
  } = useDisclosure();

  const {
    isOpen: isImportBonusDataOpen,
    onOpen: onImportBonusDataOpen,
    onClose: onImportBonusDataClose,
  } = useDisclosure();

  const [MinPayrollDate, setMinPayrollDate] = useState();

  const [BonusTypeName, setBonusTypeName] = useState();
  const [EmpList, setEmpList] = useState([]);
  const [active, setActive] = useState(true);
  const [IsEmpSelected, setIsEmpSelected] = useState(false);
  const [BonusTypeList, setBonusTypeList] = useState([]);
  const [EmpDrop, setEmpDrop] = useState();

  const [SelectedEmp, setSelectedEmp] = useState([]);

  const [SampleExcelData, setSampleExcelData] = useState([]);

  const [BonusType, setBonusType] = useState();
  const [BonusAmount, setBonusAmount] = useState();
  const [BonusMonth, setBonusMonth] = useState();
  const [BonusYear, setBonusYear] = useState();
  const [Remarks, setRemarks] = useState();
  const [PayAction, setPayAction] = useState();

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Employee Code",
      field: "empCode",
      headerCheckboxSelection: true,
      checkboxSelection: (params) => {
        return params.data.employeeName;
      },
    },

    { headerName: "Employee Name", field: "employeeName" },
    { headerName: "Bonus Name", field: "bonusName" },
    { headerName: "Bonus Amount", field: "bonusAmount" },
    {
      headerName: "Amount Payable",
      field: "amountPaid",
      singleClickEdit: true,
      editable: true,
      cellClass: "editable-grid-cell",
    },

    { headerName: "Month", field: "month" },
    { headerName: "Year", field: "year" },
    {
      headerName: "Pay Action",
      field: "payAction",
      editable: true,
      singleClickEdit: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: ["Pay", "Hold", "Pay Later"],
      },
      cellClass: "editable-grid-cell",
    },
    {
      headerName: "Remarks",
      field: "remark",
      singleClickEdit: true,
      editable: true,
      cellClass: "editable-grid-cell",
    },
    // { headerName: "Pay Action", field: "payAction" },
    {
      headerName: "Pay Action",
      field: "payAction",
      cellRenderer: (params) => (
        <Button
          onClick={() => {
            HandleBonusPay(params.data);
          }}
          size="xs"
          colorScheme="blue"
        >
          Save
        </Button>
      ),
    },
  ]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onSelectionChanged = useCallback(() => {
    var SelectedEmployees = BonusGridRef.current.api.getSelectedRows();
    console.log("Selected Employees", SelectedEmployees);

    setSelectedEmployeesList(SelectedEmployees);
  }, []);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
  }));

  useEffect(() => {
    getBonusDataList();
    getBonusTypeList();
    GetPayrollFinalizedList();
    getEmpList();
    getSampleExcel();
  }, []);

  const GetPayrollFinalizedList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PayrollFinalize/GetPayrollStatusList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );

      console.log("Payroll Finalized List", response);

      const res = response.data;

      const sortedData = res.sort((a, b) => {
        if (a.payrollYear !== b.payrollYear) {
          return b.payrollYear - a.payrollYear;
        } else {
          return b.payrollMonth - a.payrollMonth;
        }
      });

      const lastMonthYear = sortedData[0];
      console.log("Last Month Payroll", lastMonthYear);

      setMinPayrollDate(
        new Date(lastMonthYear.payrollYear, lastMonthYear.payrollMonth, 1)
      );
      console.log(
        "Last Payroll Date",
        new Date(lastMonthYear.payrollYear, lastMonthYear.payrollMonth, 1)
      );
    } catch (error) {
      console.error(error);
    }
  };

  const getEmpList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Emp List", response);
      const res = response.data;

      setEmpList(res.filter((data) => data.isActive === true));
    } catch (error) {
      console.error(error);
    }
  };

  const getBonusTypeList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Bonus/GetBonusTypeList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Bonus Type List", response);
      setBonusTypeList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getBonusDataList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Bonus/GetBonusDataList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Bonus Data List", response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const handleGetEmp = async (e) => {
    // console.log(e.target.value);
    setEmpDrop(e.target.value);
    setSelectedEmp(e.target.value);

    let SelectedValue = e.target.value;
    // try {
    //     const response = await axios.get(
    //      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeDetailByEmpGuid?EmpGuid=${SelectedValue}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    //     )
    //     //console.log(response);
    //     setSelectedEmp(response.data);
    //     setIsEmpSelected(true);
    // } catch (error) {
    //     console.error(error);
    // }
  };

  const handleAddBonusType = async (e) => {
    e.preventDefault();
    let body = {
      bonusName: BonusTypeName,
      isActive: active,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    await axios
      .post(`${process.env.REACT_APP_ACQ_URL}/api/Bonus/SaveBonusType`, body)
      .then((response) => {
        const res = response.data;
        //console.log("res",res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
        getBonusTypeList();
        onAddBonusTypeClose();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Bonus Type not created.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  console.log(SelectedEmp);

  const SaveBonus = async (e) => {
    e.preventDefault();

    if (!SelectedEmp.guId) {
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "Please Select Employee",
          position: "top",
          status: "info",
          duration: 2000,
          isClosable: true,
        });
      }
    } else {
      let body = {
        bonusName: BonusType,
        empGuId: SelectedEmp.guId,
        empCode: SelectedEmp.empCode,
        bonusAmount: BonusAmount,
        month: new Date(BonusMonth).getMonth() + 1,
        year: new Date(BonusMonth).getFullYear(),
        remark: Remarks,
        // bonusStatus:PayAction === 'Pay'? 'Completed': 'Pending',
        payAction: PayAction,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      };

      console.log(body);

      await axios
        .post(`${process.env.REACT_APP_ACQ_URL}/api/Bonus/SaveBonusData`, body)
        .then((response) => {
          console.log(response);
          const res = response.data;
          console.log("res", res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }

          getBonusDataList();
          onAddBonusClose();
          setSelectedEmp([]);
          setIsEmpSelected(false);
          setEmpDrop();
          setBonusType();
          setBonusAmount();
          setBonusMonth();
          setBonusYear();
          setRemarks();
          setPayAction();
        })

        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: "Bonus not Added.",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });
    }
  };

  const HandleBonusPay = async (data) => {
    console.log("Pay Data", data);

    let body = {
      bonusDataId: data.bonusDataId,
      bonusName: data.bonusName,
      empCode: data.empCode,
      empGuId: data.empGuId,
      bonusAmount: data.bonusAmount,
      // bonusStatus:"Completed",
      month: data.month,
      year: data.year,
      companyId: data.branchId,
      branchId: data.branchId,
      employeeName: data.employeeName,
      remark: data.remark,
      amountPaid: Number(data.amountPaid),
      payAction: data.payAction,
    };

    console.log(body);

    await axios
      .post(`${process.env.REACT_APP_ACQ_URL}/api/Bonus/SaveBonusData`, body)
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: ` Bonus not Updated`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
    let CurrentMonth = new Date().getMonth() + 1;
    getBonusDataList();
  };

  const [ImportDataColDefs, setImportDataColDefs] = useState([
    { headerName: "Employee Name", field: "employeeName" },
    { headerName: "Employee Code", field: "empCode" },
    { headerName: "Bonus Name", field: "bonusName" },
    { headerName: "Bonus Amount", field: "bonusAmount" },
    { headerName: "Month", field: "month" },
    { headerName: "Year", field: "year" },
    { headerName: "Remarks", field: "remark" },
  ]);

  const getSampleExcel = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Bonus/GetSampleExcelExportData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Sample Excel Response", response.data);
      setSampleExcelData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const readExcel = (file) => {
    const promise = new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);

      fileReader.onload = (e) => {
        const bufferArray = e.target.result;

        const wb = XLSX.read(bufferArray, { type: "buffer" });
        const wsname = wb.SheetNames[0];

        const ws = wb.Sheets[wsname];

        const data = XLSX.utils.sheet_to_json(ws);

        resolve(data);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });

    promise.then((data) => {
      // console.log(data);
      setImportData(data);
      handleImportBonus(data);
    });
  };

  // console.log(ImportData);

  const handleImportBonus = async (data) => {
    // console.log(data);

    let body = data.map((obj) => {
      return {
        ...obj,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      };
    });

    console.log(body);

    await axios
      .post(`${process.env.REACT_APP_ACQ_URL}/api/Bonus/ImportBonusData`, body)
      .then((response) => {
        let res = response.data;
        console.log("res", res);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Bonus List not Imported.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });

    getBonusDataList();
  };

  const HandlePay = async () => {
    let body = SelectedEmployeesList.map((obj) => {
      return { ...obj, payAction: "Pay" };
    });

    console.log("Handle Pay Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Bonus/SaveBonusDataINList`,
        body
      )
      .then((response) => {
        console.log(response);
        let res = response.data;
        console.log("res", res);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        getBonusDataList();
        setSelectedEmployeesList([]);
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Bonus List not Updated.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const HandleHoldBonus = async () => {
    let body = SelectedEmployeesList.map((obj) => {
      return { ...obj, payAction: "Hold" };
    });

    console.log("Handle Hold Bonus Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Bonus/SaveBonusDataINList`,
        body
      )
      .then((response) => {
        console.log(response);
        let res = response.data;
        console.log("res", res);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        getBonusDataList();
        setSelectedEmployeesList([]);
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Bonus List not Updated.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const HandlePayLaterBonus = async () => {
    let body = SelectedEmployeesList.map((obj) => {
      return { ...obj, payAction: "Pay Later" };
    });

    console.log("Handle Pay Later Bonus Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Bonus/SaveBonusDataINList`,
        body
      )
      .then((response) => {
        console.log(response);
        let res = response.data;
        console.log("res", res);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        getBonusDataList();
        setSelectedEmployeesList([]);
      })
      .catch((error) => {
        console.error(error);
        console.log(error.response);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Bonus List not Updated.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className="flex justify-between items-center border-b border-gray-400 mb-3 pb-3 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">Bonus</h1>
        <div className="space-x-6">
          <Button
            size={"sm"}
            onClick={onImportBonusDataOpen}
            colorScheme="purple"
          >
            Import Bonus List
          </Button>
          <Button size={"sm"} onClick={onAddBonusOpen} colorScheme="purple">
            <FontAwesomeIcon icon={faPlus} className="mr-2" />
            Add Bonus
          </Button>
        </div>
      </div>

      <div>
        <div className="px-5 flex py-3 space-x-5 ">
          <div className="my-auto">
            <FontAwesomeIcon
              className="rotate-180 text-lg"
              icon={faArrowTurnUp}
            />
          </div>
          <Button size={"sm"} onClick={HandlePay} colorScheme="purple">
            Pay
          </Button>
          <Button size={"sm"} onClick={HandleHoldBonus} colorScheme="purple">
            Hold
          </Button>
          <Button
            size={"sm"}
            onClick={HandlePayLaterBonus}
            colorScheme="purple"
          >
            Pay Later
          </Button>
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={BonusGridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          rowSelection="multiple" // Options - allows click selection of rows
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          cacheQuickFilter={true}
          suppressExcelExport={true}
          onSelectionChanged={onSelectionChanged}
        />
      </div>

      <Modal size="2xl" isOpen={isAddBonusOpen} onClose={onAddBonusClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Bonus</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              setSelectedEmp([]);
            }}
          />
          <hr />
          <form onSubmit={SaveBonus}>
            <ModalBody>
              <div className="flex justify-between align-middle pb-4">
                <h6 className="my-auto font-medium text-lg">
                  Search Employee Name
                </h6>
                <div className="w-1/2">
                  <ReactSelect
                    options={EmpList}
                    value={SelectedEmp}
                    onChange={setSelectedEmp}
                    getOptionLabel={(option) =>
                      `${option.fullName} ${option.empCode}`
                    }
                    getOptionValue={(option) => option.guId}
                    placeholder="Select Employee"
                  />
                </div>
              </div>
              <hr />
              {!SelectedEmp.guId || (
                <>
                  {" "}
                  <div className="flex py-2 gap-8">
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Employee Name</p>
                      <h6 className="font-medium">
                        {SelectedEmp.firstName || "Select Employee"}
                      </h6>
                    </div>
                    <div className="space-y-1">
                      <p className="text-sm text-gray-500">Employee Code</p>
                      <h6 className="font-medium">
                        {SelectedEmp.empCode || "Select Employee"}
                      </h6>
                    </div>
                  </div>
                  <div className="grid grid-cols-2 gap-4 py-4">
                    <div className="space-y-2">
                      <label>Bonus Type</label>
                      <InputGroup>
                        <Select
                          isRequired
                          value={BonusType}
                          onChange={(e) => {
                            setBonusType(e.target.value);
                          }}
                          borderColor="gray.400"
                          placeholder="Select Bonus Type"
                        >
                          {BonusTypeList.map((data, i) => (
                            <option key={i} value={data.bonusName}>
                              {data.bonusName}
                            </option>
                          ))}
                        </Select>
                        <Tooltip
                          rounded="5px"
                          hasArrow
                          label="Add Bonus Type"
                          bg="gray.300"
                          color="black"
                        >
                          <InputRightAddon
                            onClick={onAddBonusTypeOpen}
                            className="cursor-pointer"
                            bg="purple.400"
                            children={
                              <FontAwesomeIcon
                                className="text-white"
                                icon={faPlus}
                              />
                            }
                          />
                        </Tooltip>
                      </InputGroup>
                    </div>

                    <div className="space-y-2">
                      <label>Bonus Amount</label>
                      <Input
                        isRequired
                        value={BonusAmount || ""}
                        onChange={(e) => {
                          setBonusAmount(e.target.value);
                        }}
                        type="number"
                        borderColor="gray.400"
                        placeholder="Bonus Amount"
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Month</label>
                      <DatePicker
                        className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   outline-none"
                        selected={BonusMonth}
                        onChange={(date) => setBonusMonth(date)}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        placeholderText="Bonus Pay Month"
                        minDate={new Date(MinPayrollDate)}
                        required
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Pay Action</label>
                      <Select
                        isRequired
                        value={PayAction}
                        onChange={(e) => {
                          setPayAction(e.target.value);
                        }}
                        borderColor="gray.400"
                        placeholder="Select Pay Action"
                      >
                        <option value="Pay">Pay</option>
                        <option value="Hold">Hold</option>
                        <option value="Pay Later">Pay Later</option>
                      </Select>
                    </div>

                    <div className="space-y-2">
                      <label>Remarks</label>
                      <Textarea
                        value={Remarks}
                        onChange={(e) => {
                          setRemarks(e.target.value);
                        }}
                        borderColor="gray.400"
                        rows={1}
                        placeholder="Enter Reason"
                      />
                    </div>
                  </div>{" "}
                </>
              )}
            </ModalBody>
            <hr />
            <ModalFooter>
              {!SelectedEmp.guId || (
                <div className="space-x-4">
                  <Button type="submit" colorScheme="purple" variant="solid">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    mr={3}
                    onClick={() => {
                      onAddBonusClose();
                      setSelectedEmp([]);
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              )}
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isAddBonusTypeOpen} onClose={onAddBonusTypeClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Bonus Type</ModalHeader>
          <ModalCloseButton />
          <hr />
          <form onSubmit={handleAddBonusType}>
            <ModalBody>
              <div className=" space-y-4">
                <div className="space-y-2">
                  <label>Bonus Name</label>
                  <Input
                    isRequired
                    borderColor="gray"
                    value={BonusTypeName || ""}
                    onChange={(e) => {
                      setBonusTypeName(e.target.value);
                    }}
                    placeholder="Bonus Name"
                  />
                </div>

                <div className="mt-6">
                  <Checkbox
                    isChecked={active}
                    onChange={(e) => {
                      setActive(e.target.checked);
                    }}
                    borderColor="gray.400"
                  >
                    Make this as Active
                  </Checkbox>
                </div>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">
                <Button type="submit" colorScheme="purple" variant="solid">
                  Save
                </Button>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={onAddBonusTypeClose}
                >
                  Cancel
                </Button>
              </div>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal
        size="full"
        isOpen={isImportBonusDataOpen}
        onClose={onImportBonusDataClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Bonus List</ModalHeader>
          <ModalCloseButton
            onClick={() => {
              setImportData([]);
            }}
          />
          <hr />
          <ModalBody>
            <div className="mt-4">
              <CSVLink data={SampleExcelData} filename={"Employees.csv"}>
                <Button colorScheme="purple" leftIcon={<FontAwesomeIcon icon={faDownload} />}>
                  Download Sample Excel
                </Button>
              </CSVLink>
            </div>
            <div className="flex justify-center items-center w-full my-10">
              <label
                htmlFor="dropzone-file"
                className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600"
              >
                <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg
                    aria-hidden="true"
                    className="mb-3 w-10 h-10 text-gray-400"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"
                    ></path>
                  </svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400">
                    <span className="font-semibold">Click to upload</span> or
                    drag and drop
                  </p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">
                    XLSV, CSV
                  </p>
                </div>
                <input
                  onChange={(e) => {
                    const file = e.target.files[0];
                    readExcel(file);
                  }}
                  id="dropzone-file"
                  type="file"
                  className="hidden"
                />
              </label>
            </div>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={ImportData} // Row Data for Rows
                columnDefs={ImportDataColDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection="multiple" // Options - allows click selection of rows
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
                cacheQuickFilter={true}
                suppressExcelExport={true}
              />
            </div>
          </ModalBody>
          <hr />
          <ModalFooter>
            <div className="space-x-4">
              <Button
                variant="outline"
                colorScheme="purple"
                mr={3}
                onClick={() => {
                  setImportData([]);
                  onImportBonusDataClose();
                }}
              >
                Close
              </Button>
            </div>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default Bonus;
