import React, { useState, useRef, useEffect, useMemo, useCallback, } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionPanel, AccordionItem, Input, Button, Select, Switch, useToast, Checkbox, } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";
import axios from "axios";

function CodeMaster() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [CodeMasterID, setCodeMasterID] = useState(0);
  const [MappingInfo, setMappingInfo] = useState();
  const [CodePrefix, setCodePrefix] = useState();
  const [StartingNumber, setStartingNumber] = useState();
  const [CodeSuffix, setCodeSuffix] = useState();
  const [IsManual, setIsManual] = useState(false);

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Mapping With",
      field: "mappingInfo",
    },
    {
      headerName: "Code Prefix",
      field: "codePrefix",
    },
    {
      headerName: "Starting Number",
      field: "startingnumber",
    },
    {
      headerName: "Code Suffix",
      field: "codeSuffix",
    },
    {
      headerName: "Generated Code",
      field: "generatedCode",
    },
    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleCodeSwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              setMappingInfo(params.data.mappingInfo);
              setCodeMasterID(params.data.codemasterId);
              setCodePrefix(params.data.codePrefix);
              setStartingNumber(params.data.startingnumber);
              setCodeSuffix(params.data.codeSuffix);
              setIsManual(params.data.isManual);
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
          >
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  useEffect(() => {
    getCodeMasterList();
  }, []);

  const getCodeMasterList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCodeMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Code Master List", response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleCodeSwitch = async (checked, CodeData) => {
    setRowData((prev) => {
      const newState = prev.map((obj) => {
        if (obj.codemasterId === CodeData.codemasterId) {
          return { ...obj, isActive: checked };
        }
        return obj;
      });
      return newState;
    });

    let body = {
      codemasterId: CodeData.codemasterId,
      codePrefix: CodeData.codePrefix,
      startingnumber: CodeData.startingnumber,
      isActive: checked,
      codeDate: CodeData.codeDate,
      mappingInfo: CodeData.mappingInfo,
      codeSuffix: CodeData.codeSuffix,
      generatedCode: CodeData.generatedCode,
      isManual: IsManual,
      companyId: CodeData.companyId,
      branchId: CodeData.branchId,
      lastInsertedNo: CodeData.lastInsertedNo,
      isActiveCodeDate: CodeData.isActiveCodeDate,
      isManual: CodeData.isManual,
    };

    await axios
      .post(`${process.env.REACT_APP_ACQ_URL}/api/Master/SaveCodeMaster`, body)
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getCodeMasterList();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Code not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const SaveCode = async (e) => {
    e.preventDefault();
    let body = {
      codemasterId: CodeMasterID,
      mappingInfo: MappingInfo,
      codePrefix: CodePrefix,
      codeSuffix: CodeSuffix,
      startingnumber: Number(StartingNumber || 0),
      isManual: IsManual,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    console.log(body);
    await axios
      .post(`${process.env.REACT_APP_ACQ_URL}/api/Master/SaveCodeMaster`, body)
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getCodeMasterList();
        setMappingInfo("");
        setCodePrefix("");
        setStartingNumber("");
        setCodeSuffix("");
        setCodeMasterID(0);
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Code not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Code Master</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Code Details
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveCode}>
                <div className="flex gap-8 mb-4">
                  <div className="space-y-2 w-1/2">
                    <div className="flex justify-between">
                      <label>Mapping With</label>
                      <Checkbox
                        isChecked={IsManual}
                        onChange={({ target }) => {
                          setIsManual(target.checked);
                        }}
                        borderColor={"gray.400"}
                      >
                        Is Manual?
                      </Checkbox>
                    </div>
                    <Select
                      isRequired
                      value={MappingInfo || ""}
                      onChange={({ target }) => {
                        setMappingInfo(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Select Mapping With"
                    >
                      <option value="Employee">Employee</option>
                    </Select>
                  </div>
                  <div className="space-y-2 w-1/2">
                    <label>Prefix</label>
                    <Input
                      isDisabled={IsManual}
                      value={CodePrefix || ""}
                      onChange={({ target }) => {
                        setCodePrefix(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Prefix"
                    />
                  </div>
                </div>
                <div className="flex gap-8">
                  <div className="space-y-2 w-1/2">
                    <label>Starting Number/Month</label>
                    <Input
                      isDisabled={IsManual}
                      value={StartingNumber || ""}
                      onChange={({ target }) => {
                        setStartingNumber(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Starting Number"
                    />
                  </div>
                  <div className="space-y-2 w-1/2">
                    <label>Suffix</label>
                    <Input
                      isDisabled={IsManual}
                      value={CodeSuffix || ""}
                      onChange={({ target }) => {
                        setCodeSuffix(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Suffix"
                    />
                  </div>
                </div>
                <div className="space-x-3 my-2 text-right">
                  <Button type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default CodeMaster;
