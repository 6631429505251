import React from 'react'
import HelpSearch from './HelpSearch';

const HelpHeader = () => {
  return (
    <header className="sticky top-2 mx-2 rounded-2xl bg-slate-800 border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-14 -mb-px">
            <HelpSearch/>
        </div>
      </div>
    </header>
  )
}

export default HelpHeader