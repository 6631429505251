import axios from "axios";
import { useState, useEffect } from "react";
import { useAuth } from "./AuthContext";

const usePermissionCheck = (MainMenu, SubMenu) => {
  const {userData} = useAuth();

  const [hasPermission, setHasPermission] = useState(false);

  useEffect(() => {
    const MenuList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${userData.roleId || 0}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
        );

        //  console.log("User Permission",response);
        const userPermissions = response.data;

        const hasRequiredPermission = userPermissions
          .filter((data) => data.mainMenuName === MainMenu && data.subMenuName === SubMenu)
          .some(
            ({ isCreate, isModify, isView }) =>
              isCreate ||
              isModify ||
              isView === true ||
              userData.role === "Admin"
          );

        setHasPermission(hasRequiredPermission);
      } catch (err) {
        console.log("Error Fetching User Permission", err);
        setHasPermission(false);
      }
    };

    MenuList();
  }, [MainMenu, SubMenu]);

  return hasPermission;
};

export default usePermissionCheck;
