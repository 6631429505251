import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { Button, useDisclosure, Modal,
  ModalOverlay,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader, 
  useToast,
  Textarea,Tabs, TabList, TabPanels, Tab, TabPanel} from "@chakra-ui/react";
import moment from "moment";
import { CheckCircle, XCircle } from "react-feather";


function ViewAssets() {

    const userData = JSON.parse(localStorage.getItem('user'));
    const gridRef = useRef();

    const [SelectedAsset, setSelectedAsset] = useState([]);
    const toast = useToast();
    const id = 'toast';
     
    const [IssueDesc, setIssueDesc] = useState();
    const {
      isOpen: isRaiseIssueOpen,
      onOpen: onRaiseIssueOpen,
      onClose: onRaiseIssueClose,
    } = useDisclosure();

    const [Description, setDescription] = useState();
    const {
      isOpen: isDescriptionOpen,
      onOpen: onDescriptionOpen,
      onClose: onDescriptionClose,
    } = useDisclosure();



    const [RaisedIssueRowData, setRaisedIssueRowData] = useState([]);
    const [RaisedIssueColumnDefs, setRaisedIssueColumnDefs] = useState([
      { headerName: "Request Date", field: "createdOn",
       cellRenderer:(params) => <span>{moment(params.data.createdOn).format('DD/MM/YYYY')}</span>
     },
      { headerName: "Asset Name", field: "assetsName" },
      { headerName: "Model No.", field: "assetsModelNo" },
      { headerName: "Asset Type", field: "assetType" },
      { headerName: "Issue", field: "empDescription",
       cellClass:'cursor-pointer',
       cellRenderer:(params) =>  <div onClick={()=>{
        setDescription(params.data.empDescription);
        onDescriptionOpen();
       }}>{params.data.empDescription}</div>
     },
     { headerName: "Issue Status", field: "issueStatus",
     cellRenderer:(params) => (
       params.data.issueStatus === "Pending" ?
       <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
         Pending
       </span> : params.data.issueStatus === "Processing" ?
       <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
         Pending
       </span> : params.data.issueStatus === "Resolved" ?
       <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
         Pending
       </span> : params.data.issueStatus === "Rejected" ?
       <span className="bg-rejected-500 font-medium px-1.5 py-0.5 rounded-md text-white">
         Pending
       </span> : ''
     )
     },
     { headerName: "Approval Status", field: "approveStatus",
     cellRenderer:(params) => (
       params.data.approveStatus === "Pending" ?
       <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
         Pending
       </span> : params.data.approveStatus === "Approved" ?
       <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
         Approved
       </span> : params.data.approveStatus === "Rejected" ?
       <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
         Rejected
       </span> : ''
     )
     },

     { headerName: "Comment", field: "adminComment",
     cellClass:'cursor-pointer',
     cellRenderer:(params) =>  <div onClick={()=>{
      setDescription(params.data.adminComment);
      onDescriptionOpen();
     }}>{params.data.adminComment}</div>
   },


    ])

    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
        { headerName: "Asset Name", field: "assetsName" },
        { headerName: "Serial No.", field: "assetsSerialNo" },
        { headerName: "Model No.", field: "assetsModelNo" },
        { headerName: "Asset Type", field: "assetsTypeName" },
        { headerName: "Action", cellRenderer : (params) => 
        <Button 
         onClick={()=>{
          setSelectedAsset(params.data);
          onRaiseIssueOpen();
         }}
        size={'xs'} colorScheme={'blue'} >Raise Issue</Button>
      }
    ]);



    const [TeamIssueRowData, setTeamIssueRowData] = useState([]);
    const [TeamIssueColumnDefs, setTeamIssueColumnDefs] = useState([
      { headerName: "Emp Code", field: "empCode"},
      { headerName: "Emp Name", field: "fullName"},

      { headerName: "Request Date", field: "issueDate",
       cellRenderer:(params) => <span>{moment(params.data.issueDate).format('DD/MM/YYYY')}</span>
      },
      { headerName: "Asset Name", field: "assetsName" },
      { headerName: "Model No.", field: "assetsModelNo" },
      { headerName: "Asset Type", field: "assetType" },
      { headerName: "Issue", field: "empDescription",
       cellClass:'cursor-pointer',
       cellRenderer:(params) =>  <div onClick={()=>{
        setDescription(params.data.empDescription);
        onDescriptionOpen();
       }}>{params.data.empDescription}</div>
      },
      { headerName: "Issue Status", field: "issueStatus",
      cellRenderer:(params) => (
        params.data.issueStatus === "Pending" ?
        <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
          Pending
        </span> : params.data.issueStatus === "Processing" ?
        <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
          Processing
        </span> : params.data.issueStatus === "Resolved" ?
        <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
          Resolved
        </span> : params.data.issueStatus === "Rejected" ?
        <span className="bg-rejected-500 font-medium px-1.5 py-0.5 rounded-md text-white">
          Rejected
        </span> : ''
      )
      },
      { headerName: "Approval Status", field: "approveStatus",
      cellRenderer:(params) => (
        params.data.approveStatus === "Pending" ?
        <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
          Pending
        </span> : params.data.approveStatus === "Approved" ?
        <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
          Approved
        </span> : params.data.approveStatus === "Rejected" ?
        <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
          Rejected
        </span> : ""
      )
      },

      { headerName: "Action", cellRenderer:(params) => params.data.issueStatus === "Pending" ? <div className="space-x-4">
       <Button onClick={async()=>{
                 let IssueRowData = params.data;

                 let body = {
                  assetsRequestId : IssueRowData.assetsRequestId,
                  empGuid : IssueRowData.empGuid,
                  empCode : IssueRowData.empCode,
                  fullName : IssueRowData.fullName,
                  assetsMasterId : IssueRowData.assetsMasterId,
                  assetsName : IssueRowData.assetsName,
                  assetsSerialNo : IssueRowData.assetsSerialNo,
                  assetsModelNo : IssueRowData.assetsModelNo,
                  assetType : IssueRowData.assetType,
                  empDescription : IssueRowData.empDescription,
                  issueStatus : "Processing",
                  issueDate : IssueRowData.issueDate,
                  resolveDate : IssueRowData.resolveDate,
                  approveBy : userData.userId,
                  approveStatus : "Approved",
                  adminComment : IssueRowData.adminComment,
                  companyId : IssueRowData.companyId,
                  branchId : IssueRowData.branchId
                }
        
              console.log("Approve Body",body);
        
              await axios.post(
                `${process.env.REACT_APP_ACQ_URL}/api/Assets/SaveSaveAssetIssueRequest`,body
              )
              .then((response) => {
                console.log(response);
                const res = response.data;
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title:res,
                    position: "top",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                }
        
                
              })
              .catch((error) => {
                console.error(error);
          
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: "ERROR",
                    description: `Assets Issue Not Updated`,
                    position: "top",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              });
        
       }} leftIcon={<CheckCircle size='14px' />} colorScheme={'green'} size={'xs'} >Approve</Button>
       <Button onClick={async()=>{
         let IssueRowData = params.data;

         let body = {
          assetsRequestId : IssueRowData.assetsRequestId,
          empGuid : IssueRowData.empGuid,
          empCode : IssueRowData.empCode,
          fullName : IssueRowData.fullName,
          assetsMasterId : IssueRowData.assetsMasterId,
          assetsName : IssueRowData.assetsName,
          assetsSerialNo : IssueRowData.assetsSerialNo,
          assetsModelNo : IssueRowData.assetsModelNo,
          assetType : IssueRowData.assetType,
          empDescription : IssueRowData.empDescription,
          issueStatus : "Rejected",
          issueDate : IssueRowData.issueDate,
          resolveDate : IssueRowData.resolveDate,
          approveBy : userData.userId,
          approveStatus : "Rejected",
          adminComment : IssueRowData.adminComment,
          companyId : IssueRowData.companyId,
          branchId : IssueRowData.branchId
        }

      console.log("Reject Body",body);

      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Assets/SaveSaveAssetIssueRequest`,body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Assets Issue Not Updated`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });



       }} leftIcon={<XCircle size='14px' />} colorScheme={'red'} size={'xs'} >Reject</Button>

      </div> : '' },
    ]);
  
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable : true
    }));

    const TeamColDef = useMemo(() => ({
      // flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable : true
    }));

    useEffect(() => {
      GetEmpAssetsList();
      GetRaisedIssueList();
      GetTeamIssueList();
    }, []);


    const GetEmpAssetsList = async () => {
        try {
         const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Assets/ViewAssetsAllocatelist?EmpGuId=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
         )
         console.log("Employee Assets",response);
         const res = response.data;
         setRowData(res);
        } catch (error) {
          console.error(error);
        }
    }


    const GetRaisedIssueList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Assets/GetAssetIssueRequestlist?EmpGuId=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Raised Issue List Response",response);
        setRaisedIssueRowData(response.data);
      } catch (error) {
        console.error(error);
      }
    }


    const GetTeamIssueList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Assets/ViewAssetIssueRequestShowbyReportingData?EmpGuId=${userData.guId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Team Issue List Response",response);
        setTeamIssueRowData(response.data);
      } catch (error) {
        console.error(error);
      }
    }


    const SaveAssetIssues = async (e) => {
      e.preventDefault();

      let body ={
           empGuid : userData.guId,
           assetsMasterId : SelectedAsset.assetsMasterId,
           assetsName :  SelectedAsset.assetsName,
           assetsSerialNo : SelectedAsset.assetsSerialNo,
           assetsModelNo : SelectedAsset.assetsModelNo,
           assetType : SelectedAsset.assetsTypeName,
           empDescription : IssueDesc,
           issueDate:moment().format('YYYY-MM-DD'),
           issueStatus : "Pending",
           adminComment : "",
           approveBy:0,
           approveStatus: "Pending",
           companyId : userData.companyId,
           branchId : userData.isActiveBranch
        }

      console.log("Save Issue Body",body);

      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Assets/SaveSaveAssetIssueRequest`,body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        setIssueDesc('');
        onRaiseIssueClose();
        
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Assets Issue Not Registered`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  

    }

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">View Assets</h1>
      </div>


      <Tabs size={'sm'} variant={'solid-rounded'} colorScheme={'purple'}>
  <TabList>
    <Tab>Assets</Tab>
    <Tab>Raised Issues</Tab>
    <Tab>Team Issues</Tab>
  </TabList>

  <TabPanels>
    <TabPanel px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>
    <TabPanel px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={RaisedIssueRowData} // Row Data for Rows
          columnDefs={RaisedIssueColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>

    <TabPanel px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={TeamIssueRowData} // Row Data for Rows
          columnDefs={TeamIssueColumnDefs} // Column Defs for Columns
          defaultColDef={TeamColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>

  </TabPanels>
</Tabs>


      <Modal size="xl" isOpen={isRaiseIssueOpen} onClose={onRaiseIssueClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Raise Issue</ModalHeader>
            <ModalCloseButton />
            <hr />
            <form onSubmit={SaveAssetIssues}>
            <ModalBody>

           <div className="flex py-2 gap-8">
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Asset Name</p>
                <h6 className="font-medium">{SelectedAsset.assetsName}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Asset Type</p>
                <h6 className="font-medium">{SelectedAsset.assetsTypeName}</h6>
              </div>
              <div className="space-y-1">
                <p className="text-sm text-gray-500">Model No.</p>
                <h6 className="font-medium">{SelectedAsset.assetsModelNo}</h6>
              </div>
            </div>

            <hr />

            <div className="my-4">

            <div className="space-y-2">
              <label>Issue</label>
              <Textarea isRequired value={IssueDesc} onChange={({target})=>{setIssueDesc(target.value)}} borderColor={'gray.400'} placeholder="Describe issue you are facing with the asset?" />
            </div>

            </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button type="submit" colorScheme="purple" mr={3}>
                Save
              </Button>
              <Button
                onClick={onRaiseIssueClose}
                colorScheme="purple"
                variant="outline"
              >
                Cancel
              </Button>
            </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
        <Modal  isOpen={isDescriptionOpen} onClose={onDescriptionClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Description</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>
              <div>
                {Description}
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                onClick={onDescriptionClose}
                colorScheme="purple"
                variant="outline"
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

    </div>
  )
}

export default ViewAssets