import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import _ from "lodash";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { Eye } from "react-feather";
import moment from "moment";

const PendingSettlements = () => {

    const userData = JSON.parse(localStorage.getItem("user"));

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Emp Code",
        field: "empCode",
      },
      {
        headerName: "Emp Name",
        field: "empName",
      },
      {
        headerName: "Settlement Status",
        field: "settlementStatus",
        cellRenderer:(params) => params.data.settlementStatus === true ?
        <span className="bg-green-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Settled
        </span> : <span className="bg-yellow-500 text-white rounded-md px-1 py-0.5 text-sm font-medium">
          Pending
        </span>
      },
      {
        headerName: "Action",
        cellRenderer:(params)=><Link to={`/leavesettlement/${params.data.empGuid}`}>
         <Button leftIcon={<Eye size={'14px'}/>} colorScheme="blue" size={'xs'}>View</Button>
        </Link>
      }
    ]);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
    }));


    useEffect(()=>{
        GetPendingSettlements();
    },[])



    const GetPendingSettlements = async () => {
        try {
            let body = {
                empGuid : "1",
                isLopSettled : false,
                companyId : userData.companyId,
                branchId : userData.isActiveBranch,
                startDate : moment().startOf('year').format('YYYY-MM-DD'),
                endDate : moment().endOf('year').format('YYYY-MM-DD'),
              }
  
           console.log("Pending Body",body);

            const response = await axios.post(
                `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLOPAdjustmentList`,body
            )

            console.log("Pending Settlements",response);
            const res = response.data;
            

            setRowData(_.uniqBy(res,'empGuid').map((data)=>{
                return {
                    ...data,
                    settlementStatus : res.every((resData)=> resData.isLopSettled === true)
                }
            }))
        } catch (err) {
            console.error(err);
        }
    }
    
  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Pending Settlements</h1>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  )
}

export default PendingSettlements