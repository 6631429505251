import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Input,
  Button,
  Select,
  Switch,
  useToast,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";

import { Edit } from "react-feather";
import axios from "axios";
function CurrencyMaster() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [CurrencyName, setCurrencyName] = useState();
  const [CurrencySymbol, setCurrencySymbol] = useState();
  const [CountryName, setCountryName] = useState();

  const gridRef = useRef();
  const [rowData, setRowData] = useState([
    {
      id: 1,
      currencyName: "AED",
      countryName: "United State Arab",
      isActive: true,
    },
  ]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Currency Name",
      field: "currencyName",
    },
    {
      headerName: "Country Name",
      field: "countryName",
    },

    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleCurrencySwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      field: "guId",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            variant="solid"
            size="sm"
            colorScheme="blue"
            leftIcon={<Edit size="14px" />}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ]);
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  useEffect(() => {
    getCurrencyMasterList();
  }, []);

  const getCurrencyMasterList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCurrencyMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Currency Master List", response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleCurrencySwitch = async (checked, CurrencyData) => {
    setRowData((prev) => {
      const newState = prev.map((obj) => {
        if (obj.currencyId === CurrencyData.currencyId) {
          return { ...obj, isActive: checked };
        }
        return obj;
      });
      return newState;
    });

    let body = {
      currencyId: CurrencyData.currencyId,
      srNo: CurrencyData.srNo,
      currencyName: CurrencyData.currencyName,
      currencySymbol: CurrencyData.currencySymbol,
      countryId: CurrencyData.countryId,
      countryName: CurrencyData.countryName,
      isActive: checked,
      companyId: CurrencyData.companyId,
      branchId: CurrencyData.branchId,
    };

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveCurrencyMaster`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getCurrencyMasterList();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Currency not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const SaveCurrencyName = async (e) => {
    e.preventDefault();
    let body = {
      currencyName: CurrencyName,
      currencySymbol: CurrencySymbol,
      countryName: CountryName,

      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    console.log(body);
    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveCurrencyMaster`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getCurrencyMasterList();
        setCurrencyName("");
        setCurrencySymbol("");
        setCountryName("");
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Currency not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Currency Master</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Currency Name
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveCurrencyName}>
                <div className="flex gap-8">
                  <div className="space-y-2 w-1/3">
                    <label>Add Currency Name</label>
                    <Input
                      isRequired
                      value={CurrencyName || ""}
                      onChange={({ target }) => {
                        setCurrencyName(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Currency Name"
                    />
                  </div>
                  <div className="space-y-2 w-1/3">
                    <label>Add Currency Symbol</label>
                    <Input
                      value={CurrencySymbol || ""}
                      onChange={({ target }) => {
                        setCurrencySymbol(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Currency Symbol"
                    />
                  </div>
                  <div className="space-y-2 w-1/3">
                    <label>Country Name</label>
                    <Select
                      value={CountryName || ""}
                      onChange={({ target }) => {
                        setCountryName(target.value);
                      }}
                      borderColor="gray"
                    >
                      <option value="1">Please select a Country</option>
                      <option value="3">United Arab Emirates</option>
                      <option value="6">India</option>
                    </Select>
                  </div>
                </div>
                <div className="space-x-3 my-2 text-right">
                  <Button type="reset" variant="outline" colorScheme="purple">
                    Reset
                  </Button>
                  <Button type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default CurrencyMaster;
