import {
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    Switch,useToast, Select, Center, Spinner, useDisclosure
  } from "@chakra-ui/react";
  import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
  import { AgGridReact } from "ag-grid-react";
  import { Edit, Eye } from "react-feather";
  import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";
import DatePicker from "react-datepicker";
import moment from "moment";

function ConfirmedExpensePayments() {

    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = "toast";

    const [PaymentDataMonth, setPaymentDataMonth] = useState(new Date());
    const gridRef = useRef();
    const [ConfirmedPayRowData, setConfirmedPayRowData] = useState([]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

    const [ConfirmedPayColumnDefs, setConfirmedPayColumnDefs] = useState([
          {
            headerName: "Ticket No",
            field: "expenseTicketNo",
          },
          {
            headerName: "Emp Code",
            field: "empCode",
          },
            {
              headerName: "Emp Name",
              field: "fullName",
            },
    
    
            {
              headerName: "Payment Date",
              field: "paymentDate",
              cellRenderer:(params) => <span>{moment(params.data.paymentDate).format('DD/MM/YYYY')}</span>
            },
              {
                headerName: 'Paid Amount',
                field: "finalAmountPay",
              },
            {
              headerName: "Payment Mode",
              field: "paymentMethod",
              editable:true, singleClickEdit: true,
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                 values: ['Online', 'Cheque', 'Cash','FNF','Payroll'],
              } ,cellClass:'editable-grid-cell'
            },
            {
              headerName: "Remarks",
              field: "remark",
              editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'
            },
            {
              headerName: "Action",floatingFilter : false,
              cellRenderer:(params) => params.data.lock === false ? <Button onClick={async()=>{

                let RowData = params.data;

                  let NewBody = {
                       empGuId : RowData.empGuId,
                       finalAmountPay : RowData.finalAmountPay,
                       transactionMethod : RowData.paymentMethod,
                       transactionDate : RowData.paymentDate,
                       transactionStatus : "Done",
                       paymentMethodUpdationDate : moment().format('YYYY-MM-DD'),
                       paymentFinalDetailsId : RowData.paymentFinalDetailsId,
                       companyId : userData.companyId,
                       branchId : userData.isActiveBranch,
                       lock : true,
                       expenseTicketNo : RowData.expenseTicketNo,
                       remark : RowData.remark
                    }


                  console.log("New Payment Body",NewBody);



                  await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePaymentMethodDetails`,NewBody
                  )
                  .then((response) => {
                    console.log(response);
                    const res = response.data;
                    //console.log("res",res);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title:res,
                        position: 'top',
                        status: 'info',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
        
                  })
                  .catch((error) => {
                    console.log(error.response);

                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: 'ERROR',
                        description: "Payment not Updated.",
                        position: 'top',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
                  });


              }} colorScheme={'blue'} size={'xs'} >Save</Button> : ''

            },
        ]);
  
       // Table Pagination
       const paginationNumberFormatter = useCallback((params) => {
         return "[" + params.value.toLocaleString() + "]";
       }, []);
      
       //Table Columns Properties Applies to All Columns
       const defaultColDef = useMemo(() => ({
         flex: 1,
         sortable: true,
         filter: "agTextColumnFilter",
         floatingFilter: true,
         cacheQuickFilter: true,
         cellClass: 'no-border',
       }));



       useEffect(() => {
        GetConfirmedPaymentList(new Date(PaymentDataMonth).getMonth()+1,new Date(PaymentDataMonth).getFullYear());
       }, [])
       




       const GetConfirmedPaymentList = async (MONTH,YEAR) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashPaymentFinalDetailsData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}`
            )
            console.log("Confirmed Payment List",response);

            const res = response.data;
            setConfirmedPayRowData(res.filter(data => data.method === 'Payment'));
        } catch (error) {
            console.error(error);
        }
    }



  return (
    <div>

    <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
    <h1 className="text-xl font-bold text-gray-800">Confirmed Expense Payments</h1>
    <div>
        <DatePicker
        className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
        selected={PaymentDataMonth}
        onChange={(date) => {
          setPaymentDataMonth(date);
          GetConfirmedPaymentList(new Date(date).getMonth()+1,new Date(date).getFullYear());
        }}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        placeholderText="Select Month"
      />
        </div>
  </div>


  <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={ConfirmedPayRowData} // Row Data for Rows
          columnDefs={ConfirmedPayColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>

  </div>
  )
}

export default ConfirmedExpensePayments