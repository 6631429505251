import React, {useState,useRef, useMemo,useCallback, useEffect} from 'react'
import { Input, Button, Switch, Drawer, DrawerBody, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton,useDisclosure, useToast } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit, Plus } from "react-feather";
import axios from 'axios';

export default function HolidayMaster() {

  const userData = JSON.parse(localStorage.getItem('user'));
  const toast = useToast();
  const id = "toast";

    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const gridRef = useRef();

    const [rowData, setRowData] = useState([ ]); // Table Data Variable
    const { isOpen :isdraweropen, onOpen:ondraweropen, onClose:ondrawerclose } = useDisclosure()

    const [HolidayMasterID, setHolidayMasterID] = useState(0);
    
   const [HolidayDate, setHolidayDate] = useState();
   const [HolidayName, setHolidayName] = useState();
   const [HolidayShortName, setHolidayShortName] = useState();
   const [HolidayRemarks, setHolidayRemarks] = useState();
   const [BackgroundColor, setBackgroundColor] = useState();
   const [FontColor, setFontColor] = useState();

   

    const [columnDefs, setColumnDefs] = useState([
        {
          headerName: "Holiday Name",
          field: "holidayName",
        },
        {
          headerName: "Holiday Date (DD-MM-YYY)",
          field: "holidayDate",
          valueGetter:(params) => new Date(params.data.holidayDate).toLocaleDateString('en-GB')
        },
       
        { headerName: "IsActive", field: "isActive" ,
         cellRenderer:(params) => <Switch colorScheme='green' isChecked={ params.data.isActive} onChange={({target})=>{HandleHolidaySwitch(target.checked,params.data)}}  size='md' />
      },
        {
          headerName: "Action",
          floatingFilter: false,
          cellRenderer: (params) => <div className="space-x-4">
             <Button  variant='solid' size='xs' colorScheme='blue' 
             onClick={()=>{
              setHolidayMasterID(params.data.holidayMasterId);
              setHolidayName(params.data.holidayName);
              setHolidayShortName(params.data.shortName);
              setHolidayRemarks(params.data.remark);
              setHolidayDate(new Date(params.data.holidayDate).toLocaleDateString('en-CA'));
              setFontColor(params.data.fontColor);
              setBackgroundColor(params.data.backgroundColor);
              ondraweropen();
             }}><Edit size='13px' className="mr-2"/>Edit</Button>
          </div>
        },
      
      ]); 
      const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
      //Table columns properties applies to all columns
      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: 'no-border'
      })); 



useEffect(()=>{
  getHolidayMasterList();
},[])

    const getHolidayMasterList = async () => {
      try {
        const response= await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Home/GetHolidayMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        console.log("Get Holiday List Response",response);
        setRowData(response.data);
      } catch (error) {
        console.error(error);
      }
    }



    const HandleHolidaySwitch = async (checked,holidayData) => {


      setRowData(prev => {
        const newState =  prev.map((obj) => {
          if (obj.holidayMasterId === holidayData.holidayMasterId) {
            return {...obj, isActive: checked};
          }
          return obj;
        });
        return newState
      })

      let body ={
        srNo:  holidayData.srNo,
        holidayMasterId: holidayData.holidayMasterId,
        holidayDateText:  holidayData.holidayDateText,
        holidayDate:  holidayData.holidayDate,
        remark:  holidayData.remark,
        holidayName:  holidayData.holidayName,
        isActive:  checked,
        companyId:  holidayData.companyId,
        branchId:  holidayData.branchId,
        deleted:  holidayData.deleted,
        backgroundColor:  holidayData.backgroundColor,
        fontColor:  holidayData.fontColor,
        shortName:  holidayData.shortName,
    }

    console.log("Holiday Switch Body",body);


    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveHolidayMaster`,body
    )
    .then((response) => {
      console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      getHolidayMasterList();
    })
    .catch((error) => {
      console.error(error);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: ` Designation not Updated`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });

    }


    const SaveHolidayMaster = async (e) => {

      e.preventDefault();

      let body ={
        holidayMasterId:HolidayMasterID,
        holidayDate:  HolidayDate,
        remark:  HolidayRemarks,
        holidayName:  HolidayName,
        isActive: true,
        companyId:  userData.companyId,
        branchId:  userData.isActiveBranch,
        backgroundColor:  BackgroundColor,
        fontColor:  FontColor,
        shortName: HolidayShortName,
    }

    console.log("Holiday Save Body",body);


    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveHolidayMaster`,body
    )
    .then((response) => {
      console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      getHolidayMasterList();
 ondrawerclose();
      setHolidayDate('');
setHolidayName('');
setHolidayShortName('');
setHolidayRemarks('');
setBackgroundColor('');
setFontColor('');
setHolidayMasterID(0);
    })
    .catch((error) => {
      console.error(error);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: ` Designation not Updated`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });
    } 
      
  return (
    <div>
        <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Holiday Master</h1>

        <div className="space-x-3">
                      <Button colorScheme='purple' onClick={ondraweropen}><Plus size='16px' className='mr-1' />Add Holiday</Button>
                    </div>
        </div>


                    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}

        />
      </div>
       <Drawer
        isOpen={isdraweropen}
        placement='right'
        size='md'
        onClose={()=>{
          ondrawerclose();
          setHolidayDate('');
          setHolidayName('');
          setHolidayShortName('');
          setHolidayRemarks('');
          setBackgroundColor('');
          setFontColor('');
        }}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Holiday</DrawerHeader>

          <form onSubmit={SaveHolidayMaster}>


        
          <DrawerBody>

            <div className="space-y-2 w-1/1 mb-4">
            <label>Holiday Date</label>
          <Input value={HolidayDate} onChange={({target})=>{setHolidayDate(target.value)}} borderColor='gray' placeholder="DD-MM-YYYY" type='date' />
            </div>
            <div className="space-y-2 w-1/1 mb-4">
            <label>Holiday Name</label>
          <Input value={HolidayName} onChange={({target})=>{setHolidayName(target.value)}}  borderColor='gray' placeholder="Holiday Name"  />
            </div>

            <div className="space-y-2 w-1/1 mb-4">
            <label>Short Name</label>
          <Input  value={HolidayShortName} onChange={({target})=>{setHolidayShortName(target.value)}}  borderColor='gray' placeholder="Short Name"  />
            </div>

          
            <div className="space-y-2 w-1/1 mb-4">
            <label>Remarks</label>
          <Input  value={HolidayRemarks} onChange={({target})=>{setHolidayRemarks(target.value)}}  borderColor='gray' placeholder="Remarks"  />
            </div>

            <div className="flex gap-8 mb-4">
                    <div className="space-y-2 w-1/3">
                    <label>Background Color</label>
          <Input  value={BackgroundColor} onChange={({target})=>{setBackgroundColor(target.value)}}  borderColor='gray' placeholder="Remarks" type='color' />
                    </div>
                    <div className="space-y-2 w-1/3">
                    <label>Font Color</label>
          <Input value={FontColor} onChange={({target})=>{setFontColor(target.value)}}  borderColor='gray' placeholder="Remarks" type='color' />
                    </div>
                </div>
          
           
                <div className="space-x-3 my-2 text-right">
                      <Button type="submit" colorScheme='purple'>Submit</Button>
                    </div>
          
          </DrawerBody>
          </form>
        </DrawerContent>
      </Drawer>




    </div>
  )

 
}
