import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { BrowserRouter as Router, createBrowserRouter, RouterProvider } from "react-router-dom";
import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { StepsStyleConfig as Steps } from "chakra-ui-steps";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import "./custom.scss";
// import AppRoutes from "./AppRoutes";
import { AuthProvider } from "./Utils/AuthContext";

const theme = extendTheme({
  components: {
    Steps,
  },
});

// const appRouter = createBrowserRouter(AppRoutes);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <AuthProvider>
    <ChakraProvider theme={theme}>
      <Router>
        <App />
        {/* <RouterProvider router={appRouter} /> */}
      </Router>
    </ChakraProvider>
  </AuthProvider>
);
