import { Button, useDisclosure, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, ModalCloseButton, Input, Select, } from '@chakra-ui/react'
import React, { useState, useRef, useMemo, useCallback } from "react";
import { Plus } from 'react-feather'
import useFetch from '../Utils/useFetch'
import { useAuth } from '../Utils/AuthContext'
import { AgGridReact } from "ag-grid-react";
import moment from 'moment';
import DatePicker from "react-datepicker";
import { Editor } from '@tinymce/tinymce-react';

const EmailCampaigns = () => {
    const { userData } = useAuth();
    const editorRef = useRef(null);
    const {
        isOpen: isAddEmailCampaignOpen,
        onOpen: onAddEmailCampaignOpen,
        onClose: onAddEmailCampaignClose,
      } = useDisclosure();

      const [emails, setEmails] = useState([]);
      const [currentEmail, setCurrentEmail] = useState('');
    
      const handleEmailInputChange = (event) => {
        setCurrentEmail(event.target.value);
      };
    
      const handleEmailInputKeyDown = (event) => {
        if (event.key === 'Enter' || event.key === ',' || event.key === ' ') {
          event.preventDefault();
    
          const trimmedEmail = currentEmail.trim();
          if (trimmedEmail !== '') {
            setEmails([...emails, trimmedEmail]);
          }
    
          setCurrentEmail('');
        }
      };
    
      const handleRemoveEmail = (index) => {
        const newEmails = [...emails];
        newEmails.splice(index, 1);
        setEmails(newEmails);
      };


    const { FetchedData: CampaignList } = useFetch('/api/EmailScheduler/GetEmailSchedulerList', {
        BranchId: userData.isActiveBranch
    });
    const { FetchedData: FrequencyList } = useFetch('/api/EmailScheduler/GetEmailSchedulerFrequencyList')

    const gridRef = useRef();
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [ columnDefs ] = useState([
      {
        headerName: "Schedular Name",
        field: "emailSchedulerName"
      },
      {
        headerName: "From",
        field: "emailFrom"
      },
      {
        headerName: "To",
        field: "toEmail"
      },
      {
        headerName: "CC",
        field: "ccEmail"
      },
      {
        headerName: "Subject",
        field: "emailSubject"
      },
      {
        headerName: "Body",
        field: "emailBody"
      },
      {
        headerName: "Start Date",
        field: "startDate",
        cellRenderer:({data})=> moment(data.startDate).format('DD-MMM-YYYY')
      },
      {
        headerName: "Start Time",
        field: "startTime"
      },
      {
        headerName: "Frequency",
        field: "frequencyName"
      },
      {
        headerName: "Status",
        width: 120 ,
        cellRenderer:(params)=><span className='bg-green-500 font-medium rounded-md text-white px-2 py-1' >Active</span>,
        pinned: 'right'
      },
      {
        headerName: "Action",
        pinned: 'right',
        cellRenderer:(params) => <div>
            <Button size={'xs'} colorScheme='blue'>Stop</Button>
        </div>
      }
    ]);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable: true
    }));





    const SaveSchedulerContent = () => {
      // if(editorRef.current){
      //     console.log(editorRef.current.getContent());
      //   }
    }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Email Campaigns</h1>
        <div>
            <Button size={'sm'} onClick={()=>{onAddEmailCampaignOpen()}} leftIcon={<Plus size={'16px'} />}  colorScheme='purple' >Add Campaign</Button>
        </div>
      </div>


      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={CampaignList} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Modal
        size="4xl"
        isOpen={isAddEmailCampaignOpen}
        onClose={onAddEmailCampaignClose}
      >
        <ModalOverlay />
        <ModalContent  rounded={'3xl'}>
          <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>Add Campaign</ModalHeader>
          <ModalCloseButton mt={2} />
          <ModalBody className='space-y-2'>
            <div className='grid grid-cols-2 gap-4'>
             <div className='space-y-2'>
                 <label>Campaign Name</label>
                 <Input type='text' borderColor={'gray.400'} placeholder='Campaign Name' />
             </div>
             <div className='space-y-2'>
                 <label>From</label>
                 <Input type='email' borderColor={'gray.400'} placeholder='From' />
             </div>
            </div>

            <div className='space-y-2'>
                <label>To</label>
                <Input value={currentEmail} onChange={handleEmailInputChange} onKeyDown={handleEmailInputKeyDown} type='email' borderColor={'gray.400'} placeholder='To' />
            </div>

            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
        {emails.map((email, index) => (
          <div key={index} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#e0e0e0', padding: '5px 10px', margin: '5px', borderRadius: '20px' }}>
            <span>{email}</span>
            <button onClick={() => handleRemoveEmail(index)} style={{ background: 'none', border: 'none', cursor: 'pointer', marginLeft: '5px', fontSize: '12px', color: '#888' }}>&times;</button>
          </div>
        ))}
      </div>

            <div className='space-y-2'>
                <label>Cc</label>
                <Input type='text' borderColor={'gray.400'} placeholder='Cc' />
            </div>

            <div className='space-y-2'>
                <label>Subject</label>
                <Input type='text' borderColor={'gray.400'} placeholder='Subject' />
            </div>

            <div className='grid grid-cols-2 gap-4'>
                <div className='space-y-2'>
                    <label>Start Date & Time</label>
                    <div>
                      <DatePicker
                       className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-2.5 outline-none"
                    // selected={PaymentDataMonth}
                    // onChange={(date) => {
                    //   setPaymentDataMonth(date);
                    // }}
                       showTimeSelect
                       dateFormat="MMMM d, yyyy h:mm aa"
                       placeholderText="Start Date & Time"
                       popperPlacement='top'
                     />
                   </div>
                </div>

                <div className='space-y-2'>
                    <label>Frequency</label>
                    <Select borderColor={'gray.400'} placeholder='Email Frequency'>
                        {FrequencyList?.map((data)=>{
                            return <option key={data?.frequencyId} value={data?.frequencyId}>{data?.frequencyName}</option>
                        })}
                    </Select>
                </div>
            </div>

            <div className='space-y-2'>
                <label>Email Body</label>
                <Editor
                  apiKey={'9ed8l3htyuroctid8lmjm668hizcg7b4yp17xe25i5xwsq5j'}
                  onInit={(evt, editor) => editorRef.current = editor}
                 //  initialValue={GuideContent}
                  init={{
                    height: 300,
                     menubar: false,
                    plugins: [
                      'table advlist autolink lists link image charmap print preview anchor',
                      'searchreplace visualblocks code fullscreen',
                      'insertdatetime media table paste code help wordcount', 'code' , 'lists', 'table'
                    ],
                     toolbar: 'undo redo | blocks | bold italic | bullist numlist | | alignleft aligncenter alignright alignjustify | indent outdent | code | wordcount table tabledelete | tableprops tablerowprops tablecellprops | tableinsertrowbefore tableinsertrowafter tabledeleterow | tableinsertcolbefore tableinsertcolafter tabledeletecol',
                    content_style: 'body { font-family:Poppins,Arial,sans-serif; font-size:14px }'
                  }}
                />
            </div>

          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme="purple" mr={3}>
              Save
            </Button>
            <Button
              onClick={onAddEmailCampaignClose}
              colorScheme="purple"
              variant="outline"
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default EmailCampaigns