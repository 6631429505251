import { Switch, useToast } from "@chakra-ui/react";
import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";

function RoleList() {


    const userData = JSON.parse(localStorage.getItem("user"));

    const toast = useToast();
    const id = "toast";

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
        {
          headerName: "Role Name",
          field: "roleName",
        },
        {
           headerName: "IsActive", field: "isActive" ,
          cellRenderer:(params) => <Switch colorScheme='green' isChecked={params.data.isActive} onChange={({target})=>{HandleRoleSwitch(target.checked,params.data)}}  size='md' />
        },
        // {
        //   headerName: "Action",
        //   field:'isActive',
        //   cellRenderer: (params) => <div className="space-x-4">
        //      <Button onClick={()=>{
        //       setDesignationID(params.data.designationId);
        //       setDesignationName(params.data.designationName);
        //      }}  variant='solid' size='xs' colorScheme='blue'><Edit size='14px' className="mr-2"/>Edit</Button>
        //   </div>
        // },
      ]);

      const HandleRoleSwitch = async (checked,RoleData) => {

        setRowData(prev => {
            const newState =  prev.map((obj) => {
              if (obj.id === RoleData.id) {
                return {...obj, isActive: checked};
              }
              return obj;
            });
            return newState
          })


        let body = {
            id: RoleData.id,
            roleName: RoleData.roleName,
            isActive: checked,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
        }

        console.log("Switch Body",body);


        await axios.post(
            `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/SaveRoleMaster`,body
          )
          .then((response) => {
            console.log(response);
            const res = response.data;
            if (!toast.isActive(id)) {
              toast({
                id,
                title:res,
                position: "top",
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            }

          })
          .catch((error) => {
            console.error(error);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: `Role not Updated`,
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });

          GetRoleList();
      }
      
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);

    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: 'no-border'
    }));

              


    useEffect(() => {
        GetRoleList();
    }, [])
    

    const GetRoleList = async() => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetRoleMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
              )
              console.log("Role List ",response);
              setRowData(response.data);
        } catch (error) {
         console.error(error);   
        }
    }

  return (
    <div>
      <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Role List</h1>
      </div>


      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}

        />
      </div>


    </div>
  )
}

export default RoleList