import React, {useState,useRef,useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { useAuth } from '../Utils/AuthContext';
import useFetch from "../Utils/useFetch";
import moment from "moment";
import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const SeparationReport = () => {

    const { userData } = useAuth();
    const GridRef = useRef();

    const { FetchedData: SeparationDataList } = useFetch('/api/EmployeeDetail/GetEmployeelist',{
       CompanyId: userData.companyId,
       BranchId: userData.isActiveBranch
    });

    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [ColumnDefs] = useState(userData.countryName === "India" ? [
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Emp Name",
          field: "fullName",
        },
        {
          headerName: "Designation",
          field: "designationName",
        },
        {
          headerName: "Department",
          field: "departmentName",
        },
        {
          headerName: "Separation Date",
          field: "dateofresignation",
          cellRenderer:({data}) => <span>{moment(data.dateofresignation).format('DD-MMM-YYYY')}</span>
        },
      ] : [
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Emp Name",
          field: "fullName",
        },
        {
          headerName: "Designation",
          field: "designationName",
        },
        {
          headerName: "Department",
          field: "departmentName",
        },
        {
          headerName: "Separation Date",
          field: "dateofresignation",
          cellRenderer:({data}) => <span>{moment(data.dateofresignation).format('DD-MMM-YYYY')}</span>
        },
        {
          headerName: "Grade",
          field: "grade",
        },
        {
          headerName: "Division",
          field: "division",
        },
      ]);
     // Table Pagination
     const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
      //Table columns properties applies to all columns
      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: 'no-border',
        enableCellTextSelection: true,
        resizable: true
      }));
  return (
    <div>
        <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
          <h1 className="text-lg font-bold text-gray-800">
            Separation Report
          </h1>
          <div>
            <Button onClick={()=>{GridRef.current.api.exportDataAsCsv();}} leftIcon={<FontAwesomeIcon icon={faDownload} />} colorScheme="purple" >Export</Button>
          </div>
        </div>

        <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          ref={GridRef}
          domLayout={"autoHeight"}
          rowData={SeparationDataList?.filter(data => data.isResignee)} // Row Data for Rows
          columnDefs={ColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>
    </div>
  )
}

export default SeparationReport