import React, { useState, useRef, useMemo, useCallback } from "react";
import { Edit } from "react-feather";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
  Select,
  Checkbox,useToast, useDisclosure
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { useEffect } from "react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
export default function ForgotAttendance() {

  const userData = JSON.parse(localStorage.getItem('user'));
  const { isOpen, onOpen, onClose } = useDisclosure()
  const CurrentDate = new Date().toLocaleDateString('en-CA');
  const [AttendanceDate, setAttendanceDate] = useState(new Date());

  const [SelectedEmpList, setSelectedEmpList] = useState([]);

  const [SelectedEmpAttEdit, setSelectedEmpAttEdit] = useState([]);
console.log(SelectedEmpAttEdit);
  const toast = useToast();
  const id = "toast";
  const [LeaveQuotaList, setLeaveQuotaList] = useState([]);
  const [AppliedLeaveType, setAppliedLeaveType] = useState();

  const [IsLOP, setIsLOP] = useState(false);
  const [IsPresent, setIsPresent] = useState(false);

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Employee Name",
        field: "empName",
        headerCheckboxSelection: true,
        checkboxSelection: (params) => {
          return params.data.empName;
        }, },
      {
          headerName: "Emp Code",
          field: "empCode",
        },

        {
          headerName: "Designation",
          field: "designation",
        },
        {
          headerName: "Department",
          field: "department",
        },
        {
          headerName: "Status",
          field: "attendanceStatus",
          cellRenderer: (params) => 
          params.data.attendanceStatus === "P"?
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          P
        </span>:
         params.data.attendanceStatus === "L"?
        <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        L
      </span>
        :
         params.data.attendanceStatus === "A"?
        <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        A
      </span> :
        params.data.attendanceStatus === "WO"?
       <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
       WO
     </span> 
      :""
        },
        {
          headerName: "In-Time",
          field: "inTime",
        },
        {
          headerName: "Out-Time",
          field: "outTime",
        },
        {
          headerName: "Date",
          field: "attendanceDate",
          valueGetter:(params) => new Date(params.data.attendanceDate).toLocaleDateString('en-GB')
        },
        {
          headerName: "Device Name",
          field: "linkDevice",
        },


        {
          headerName: "Action",
          floatingFilter: false,
          cellRenderer:(params) =>
          params.data.attendanceStatus === "A"? <Button 
          onClick={()=>{
            setSelectedEmpAttEdit(params.data);
            onOpen();
          }} 
          size='xs' colorScheme='purple'><Edit size='14px'  className="mr-2" />Edit</Button> : ''
        },


  
    ]);

    const onSelectionChanged = useCallback(() => {
      var EmpList = gridRef.current.api.getSelectedRows();
     setSelectedEmpList(EmpList);
    }, []);
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: 'no-border'
    }));
    

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);


  console.log("Applied Leave ",AppliedLeaveType);


  useEffect(() => {
    GetDailyAttendanceData(AttendanceDate);
    getLeaveQuotaList()
  }, [])
  

const GetDailyAttendanceData = async(DATE) =>{
    try {
        const response= await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetFinalAttendanceView?Attendancedate=${DATE.toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Get Daily Attendance Response",response);

          const res =response.data;
          const AbsentEmp = res.filter((data)=>data.attendanceStatus === 'A')
          setRowData(AbsentEmp)
        
    } catch (error) {
        console.error(error);
    }
}



const getLeaveQuotaList = async() => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveQuotaList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    )
    console.log("Leave Quota List",response.data);
    setLeaveQuotaList(response.data);
  } catch (error) {
    console.error(error);
  }
}



const SaveEditAttendance = async () => {
  let body={
    empCode: SelectedEmpAttEdit.empCode,
    empGuid: SelectedEmpAttEdit.empGuId,
    aDate: SelectedEmpAttEdit.attendanceDate,
    leaveTypeId: Number(AppliedLeaveType ||0),
    isLop: IsLOP,
    isPresent:IsPresent,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch
  }
  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/LOPAdjustment/SaveAttendanceLop`,body
  )
  .then((response) => {
    const res = response.data;
    console.log("res",res);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }

    GetDailyAttendanceData(AttendanceDate);
    setAppliedLeaveType(undefined);
    setIsPresent(false)
    setIsLOP(false)
    onClose();


  })
  .catch((error) => {
    console.log(error.response);
    console.log(error.response.data);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: 'ERROR',
        description: "Attendance Not Edited",
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  });

}





const HandleTreatAsLop = async () => {

  let body =SelectedEmpList.map((data)=>{
    return {
      empCode: data.empCode,
      empGuid: data.empGuId,
      aDate: data.attendanceDate,
      leaveTypeId:0,
      isLop: true,
      isPresent:false,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    }
  })


  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/LOPAdjustment/SaveAttendanceLopList`,body
  )
  .then((response) => {
    const res = response.data;
    console.log("res",res);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }

    GetDailyAttendanceData(AttendanceDate);
  })
  .catch((error) => {
    console.log(error.response);
    console.log(error.response.data);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: 'ERROR',
        description: "Attendance Not Edited",
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  });

}


const HandleTreatAsPresent = async () => {

 let body =SelectedEmpList.map((data)=>{
    return {
      empCode: data.empCode,
      empGuid: data.empGuId,
      aDate: data.attendanceDate,
      leaveTypeId:0,
      isLop: false,
      isPresent:true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    }
  })


  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/LOPAdjustment/SaveAttendanceLopList`,body
  )
  .then((response) => {
    const res = response.data;
    console.log("res",res);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }
    GetDailyAttendanceData(AttendanceDate);

  })
  .catch((error) => {
    console.log(error.response);
    console.log(error.response.data);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: 'ERROR',
        description: "Attendance Not Edited",
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  });

}


const HandleTreatAsLeave = async () => {

  let body =SelectedEmpList.map((data)=>{
    return {
      empCode: data.empCode,
      empGuid: data.empGuId,
      aDate: data.attendanceDate,
      leaveTypeId: Number(AppliedLeaveType ||0),
      isLop: false,
      isPresent:false,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    }
  })


  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/LOPAdjustment/SaveAttendanceLopList`,body
  )
  .then((response) => {
    const res = response.data;
    console.log("res",res);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }
    GetDailyAttendanceData(AttendanceDate);

  })
  .catch((error) => {
    console.log(error.response);
    console.log(error.response.data);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: 'ERROR',
        description: "Attendance Not Edited",
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  });

}


  return (
    <div>
<div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Forgot Attendance</h1>
        <div className="flex items-center">
            <label className="font-semibold text-lg mr-3">Attendance Date :</label>
            <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={AttendanceDate}
              onChange={(date) => {setAttendanceDate(date); GetDailyAttendanceData(date);}}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Attendance Date'
            />
            </div>

        </div>
      </div>

      <div className="px-5 flex items-center pb-4 space-x-5 ">
        <FontAwesomeIcon className="rotate-180 text-xl" icon={faArrowTurnUp} />
          <Button onClick={()=>{HandleTreatAsLop();}} colorScheme='purple'>Treat as LOP</Button>
          <Button onClick={()=>{HandleTreatAsPresent();}} colorScheme='purple'>Treat as Present</Button>
          <span className="font-medium">OR</span>
          <div className='space-y-2 '>
                <Select
                value={AppliedLeaveType}
                onChange={({target})=>{setAppliedLeaveType(target.value)}}
                borderColor="gray"
                placeholder="Select a Leave Type"
                bg
              >
                {LeaveQuotaList.map((data,i)=>{
                  return <option key={i} value={data.leaveQuotaId}>{data.leaveDescription}</option>
                })}
              </Select>
            </div>
            <Button onClick={()=>{HandleTreatAsLeave();}} colorScheme='purple'>Treat as Leave</Button>
        </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          onSelectionChanged={onSelectionChanged}
          rowSelection={'multiple'}
        />
      </div>

      <Modal size='lg' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Attendance</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
          <div className="flex pb-1 gap-6">
                  <div>
                    <p className="text-sm text-gray-500">Emp Name</p>
                    <h6 className="font-medium">{SelectedEmpAttEdit.empName}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Emp Code</p>
                    <h6 className="font-medium">{SelectedEmpAttEdit.empCode}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Date</p>
                    <h6 className="font-medium">{new Date(SelectedEmpAttEdit.attendanceDate).toLocaleDateString('en-GB')}</h6>
                  </div>
                  <div>
                    <p className="text-sm text-gray-500">Status</p>
                    <h6 className="font-medium">{SelectedEmpAttEdit.att_statusType}</h6>
                  </div>

                </div>
                <hr />

                <div className='space-y-2 my-4'>
                <label>Treat as Leave</label>
                <Select
                isDisabled={IsLOP || IsPresent === true ? true : false}
                value={AppliedLeaveType}
                onChange={({target})=>{setAppliedLeaveType(target.value)}}
                borderColor="gray"
                placeholder="Please Select a Leave Type"
              >
                {LeaveQuotaList.map((data,i)=>{
                  return <option key={i} value={data.leaveQuotaId}>{data.leaveDescription}</option>
                })}
              </Select>
            </div>


            <div className="flex justify-between mb-4">
              <Checkbox isDisabled={IsPresent || (AppliedLeaveType != undefined)  === true ? true : false} isChecked={IsLOP} onChange={({target})=>{setIsLOP(target.checked)}} borderColor='gray.500' >Is LOP</Checkbox>
              <Checkbox isDisabled={IsLOP || (AppliedLeaveType != undefined)  === true ? true : false} isChecked={IsPresent} onChange={({target})=>{setIsPresent(target.checked)}} borderColor='gray.500' >Is Present</Checkbox>

            </div>
          </ModalBody>
          <hr />
          <ModalFooter>

            <Button onClick={SaveEditAttendance} variant='solid' colorScheme='purple' mr={3}>Save</Button>
            <Button variant='outline' colorScheme='purple' onClick={onClose}>
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}
