import React, { createContext, useContext, useState, useEffect } from "react";
const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
}

export const AuthProvider = ({children}) => {
    const [userData, setUserData] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(()=>{
        const storedUser = localStorage.getItem('user') || sessionStorage.getItem('user');
        if(storedUser){
            setUserData(JSON.parse(storedUser));
        }
        setIsLoading(false);
    },[]);

    const userLogin = (userData,isRemeber) => {
        console.log("user Login",userData,isRemeber);
        if(isRemeber){
          setUserData(userData);
          localStorage.setItem("user",JSON.stringify(userData));
        }else{
           setUserData(userData);
           sessionStorage.setItem('user', JSON.stringify(userData))
        }
    }


    const userLogout = () => {
        setUserData(null);
        localStorage.removeItem("user");
        sessionStorage.removeItem("user");
    }

    if(isLoading){
       return <div>Loading...</div>
    }

    return (
        <AuthContext.Provider value={{ userData, userLogin, userLogout}}>
            {children}
        </AuthContext.Provider>
    )
}