import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Input,
  Button,
  Switch,
  Checkbox,
  Select,
  useToast,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";
import axios from "axios";

function EmployeeCategory() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [EmpCategoryID, setEmpCategoryID] = useState(0);

  const [CategoryName, setCategoryName] = useState();
  const [EmpTypeName, setEmpTypeName] = useState();
  const [ProbationMonth, setProbationMonth] = useState();

  const [IsProbationApply, setIsProbationApply] = useState(false);
  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Category Name",
      field: "empTypeName",
    },
    {
      headerName: "Probation Apply",
      field: "isProbationApply",
    },
    {
      headerName: "Probation Month",
      field: "probationMonth",
    },

    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleEmpCategorySwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      field: "guId",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              setEmpCategoryID(params.data.empTypeId);
              setIsProbationApply(params.data.isProbationApply);
              setProbationMonth(params.data.probationMonth);
              setEmpTypeName(params.data.empTypeName);
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
          >
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
          {/* <Button variant='solid' size='sm' colorScheme='red'>Reject</Button> */}
        </div>
      ),
    },
  ]);
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  useEffect(() => {
    getEmployeeCategoryList();
  }, []);

  const getEmployeeCategoryList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetEmployeeTypeMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee Category List", response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleEmpCategorySwitch = async (checked, EmpCatData) => {
    setRowData((prev) => {
      const newState = prev.map((obj) => {
        if (obj.empTypeId === EmpCatData.empTypeId) {
          return { ...obj, isActive: checked };
        }
        return obj;
      });
      return newState;
    });

    let body = {
      empTypeId: EmpCatData.empTypeId,
      srNo: EmpCatData.srNo,
      empTypeName: EmpCatData.empTypeName,
      isActive: checked,
      isProbationApply: EmpCatData.isProbationApply,
      probationMonth: EmpCatData.probationMonth,
      probationApply: EmpCatData.probationApply,
      companyId: EmpCatData.companyId,
      branchId: EmpCatData.branchId,
    };

    console.log("Switch Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveEmployeeTypeMaster`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getEmployeeCategoryList();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Department not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const SaveEmpTypeName = async (e) => {
    e.preventDefault();
    let body = {
      empTypeId: EmpCategoryID,
      empTypeName: EmpTypeName,
      isProbationApply: IsProbationApply,
      probationMonth: ProbationMonth,

      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    console.log(body);
    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveEmployeeTypeMaster`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getEmployeeCategoryList();
        setEmpTypeName("");
        setProbationMonth("");
        setIsProbationApply(false);
        setEmpCategoryID(0);
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Department not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Employee Category</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Employee Category
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveEmpTypeName}>
                <div className="flex gap-8 ">
                  <div>
                    <label>Category Name</label>
                    <Input
                      value={EmpTypeName || ""}
                      onChange={({ target }) => {
                        setEmpTypeName(target.value);
                      }}
                      borderColor="gray.400"
                      placeholder="Category Name"
                    />
                  </div>

                  <Checkbox
                    isChecked={IsProbationApply}
                    onChange={({ target }) => {
                      setIsProbationApply(target.checked);
                      if (target.checked === false) {
                        setProbationMonth("");
                      }
                    }}
                    borderColor="gray"
                  >
                    is Probation Apply?
                  </Checkbox>

                  <div className="space-y-2 w-1/3 ">
                    <label>Probation Month</label>
                    <Select
                      isDisabled={IsProbationApply === false ? true : false}
                      value={ProbationMonth || ""}
                      onChange={({ target }) => {
                        setProbationMonth(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Select Month"
                    >
                      <option value="1">1 Month</option>
                      <option value="2">2 Month</option>
                      <option value="3">3 Month</option>
                    </Select>
                  </div>
                </div>
                <div className="space-x-4 my-4 text-right">
                  <Button
                    onClick={() => {
                      setEmpTypeName("");
                      setProbationMonth("");
                      setIsProbationApply(false);
                      setEmpCategoryID(0);
                    }}
                    type="reset"
                    variant="outline"
                    colorScheme="purple"
                  >
                    Reset
                  </Button>
                  <Button type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default EmployeeCategory;
