import { Avatar,Tabs, TabList, TabPanels, Tab, TabPanel } from "@chakra-ui/react";
import { faCalendarDays, faClock } from "@fortawesome/free-regular-svg-icons";
import React,{useState,useEffect} from "react";
import { LineChart,Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from "recharts";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import axios from "axios";
import { Link } from "react-router-dom";
import _ from "lodash";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import useFetch from "../Utils/useFetch";

  
const EmpDashboard = () => {
  const userData = JSON.parse(localStorage.getItem("user"));

  const { FetchedData: SeparationData } = useFetch('/api/EmployeeResignation/GetEmpResignationList',{
    CompanyId: userData?.companyId,
    BranchId: userData?.isActiveBranch
  })

  const WeekDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday"
  ];

  const MonthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const Today = new Date();
  // console.log(Today.getFullYear());


  const [StartDateOfMonth] = useState(
    moment().startOf("month").format("YYYY-MM-DD")
  );
  const [EndDateOfMonth, ] = useState(
    moment().endOf("month").format("YYYY-MM-DD")
  );

  console.log(
    "Start Date of Month",
    StartDateOfMonth,
    "End Date of Month",
    EndDateOfMonth
  );



const [HolidayList, setHolidayList] = useState([]);
const [BirthdayList, setBirthdayList] = useState([]);
const [AnnouncementList, setAnnouncementList] = useState([]);
const [CurrDayAttData, setCurrDayAttData] = useState([]);
const [AttendanceData, setAttendanceData] = useState([]);

useEffect(()=>{
  if(SeparationData){
    SeparationData?.filter((filData) => filData.resignationStatus === "Approved" || filData.resignationStatus === "Finalized")?.map(async(data)=>{
      if(new Date(data.lastWorkingDate) < new Date() ){
        try {
          const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ActiveEmployeeByGuid?EmpGuid=${data.guId}&IsActive=false`);
          console.log("Out Response",response);
        } catch (err) {
          console.error("Out Error",err);
        }
      } else {
        try {
          const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ActiveEmployeeByGuid?EmpGuid=${data.guId}&IsActive=true`);
          console.log("In Response",response);
        } catch (err) {
          console.error("In Error",err);
        }
      }
    })
  }
},[SeparationData])

useEffect(()=>{
  ProcessAttendanceData();
  getAttendanceData();
  getHolidayList();
  getBirthdayList();
  getAnnouncementList();
},[])

const ProcessAttendanceData = async () => {
  try {
    const response = await axios.get(
      `${
        process.env.REACT_APP_ACQ_URL
      }/api/Attendance/ProcessDailyAttendance?CompanyId=${
        userData.companyId
      }&BranchId=${
        userData.isActiveBranch
      }&Attendancedate=${moment().format('YYYY-MM-DD')}`
    );
    console.log("Auto Process Attendance Response", response);

  } catch (err) {
    console.error(err);
  }finally {
    getTodayAttendance();
  }
};


const BirthDateOptions = {
  // weekday: "short",
  // year: "numeric",
  month: "long",
  day: "numeric"
};


const formatXAxis = (tickItem) => {
  return tickItem.toString().substring(0,3).toUpperCase()

}

  const getHolidayList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Home/GetHolidayMasterList?CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      console.log("Holiday List Response",response);
      const res = response.data;
      let CurrentYearHoliday = res.filter(data => new Date(data.holidayDate).getFullYear() === new Date().getFullYear())
      setHolidayList(CurrentYearHoliday);
    } catch (error) {
      console.error(error);
    }
  }


  const getBirthdayList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Home/GetBirthdayList?CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      console.log("Birthday List Response",response);
      setBirthdayList(response.data);
    } catch (error) {
      console.error(error);
    }
  }


  const getAnnouncementList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Home/GetAnnouncmentListByDate?Startdate=${StartDateOfMonth}&EndDate=${EndDateOfMonth}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      console.log("Announcement List Response",response);
      setAnnouncementList(response.data);
    } catch (error) {
      console.error(error);
    }
  }


  const getAttendanceData = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDashBoard/GetEmpYearlyAttendanceList?Empguid=${userData.guId}&year=${Today.getFullYear()}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      console.log("Attendance List Response",response);
      setAttendanceData(response.data);
    } catch (error) {
      console.error(error);
    }
  }



  const getTodayAttendance = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDashBoard/GetTodayAttendanceOfEmployee?CompanyId=${userData.companyId}&BranchId=${userData.branchId}&EmpGuid=${userData.guId}&Todaydate=${Today.toLocaleDateString('en-CA')}`
      )
      console.log("Today Attendance  Data",response.data);
      setCurrDayAttData(response.data);
    } catch (error) {
      console.error(error);
    }
  }




  return (
    <div>
      <div className="relative bg-indigo-300 p-4 sm:p-6 rounded-2xl overflow-hidden mb-6">
        {/* Background illustration */}
        <div
          className="absolute right-0 top-0 -mt-4 mr-16 pointer-events-none hidden xl:block"
          aria-hidden="true"
        >
          <svg
            width="319"
            height="198"
            xmlnsXlink="http://www.w3.org/1999/xlink"
          >
            <defs>
              <path id="welcome-a" d="M64 0l64 128-64-20-64 20z" />
              <path id="welcome-e" d="M40 0l40 80-40-12.5L0 80z" />
              <path id="welcome-g" d="M40 0l40 80-40-12.5L0 80z" />
              <linearGradient
                x1="50%"
                y1="0%"
                x2="50%"
                y2="100%"
                id="welcome-b"
              >
                <stop stopColor="#A5B4FC" offset="0%" />
                <stop stopColor="#818CF8" offset="100%" />
              </linearGradient>
              <linearGradient
                x1="50%"
                y1="24.537%"
                x2="50%"
                y2="100%"
                id="welcome-c"
              >
                <stop stopColor="#4338CA" offset="0%" />
                <stop stopColor="#6366F1" stopOpacity="0" offset="100%" />
              </linearGradient>
            </defs>
            <g fill="none" fillRule="evenodd">
              <g transform="rotate(64 36.592 105.604)">
                <mask id="welcome-d" fill="#fff">
                  <use xlinkHref="#welcome-a" />
                </mask>
                <use fill="url(#welcome-b)" xlinkHref="#welcome-a" />
                <path
                  fill="url(#welcome-c)"
                  mask="url(#welcome-d)"
                  d="M64-24h80v152H64z"
                />
              </g>
              <g transform="rotate(-51 91.324 -105.372)">
                <mask id="welcome-f" fill="#fff">
                  <use xlinkHref="#welcome-e" />
                </mask>
                <use fill="url(#welcome-b)" xlinkHref="#welcome-e" />
                <path
                  fill="url(#welcome-c)"
                  mask="url(#welcome-f)"
                  d="M40.333-15.147h50v95h-50z"
                />
              </g>
              <g transform="rotate(44 61.546 392.623)">
                <mask id="welcome-h" fill="#fff">
                  <use xlinkHref="#welcome-g" />
                </mask>
                <use fill="url(#welcome-b)" xlinkHref="#welcome-g" />
                <path
                  fill="url(#welcome-c)"
                  mask="url(#welcome-h)"
                  d="M40.333-15.147h50v95h-50z"
                />
              </g>
            </g>
          </svg>
        </div>

        {/* Content */}
        <div className="relative">
          <h1 className="text-2xl md:text-3xl text-slate-800 font-bold mb-1">
            Hello, {userData.name}! 👋
          </h1>
          <p>Welcome Back</p>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-5 mb-6">
        <div className="grid grid-cols-2 gap-5">
          <div className="bg-white rounded-2xl drop-shadow-md p-4">
            <div className="grid grid-cols-1 lg:grid-cols-2 h-full">
              <div className="flex flex-col justify-between h-full">
                <p className="text-lg font-medium text-gray-500">Today</p>
                <div>
                  <h6 className="text-7xl font-bold text-gray-700">
                    {Today.getDate()}
                  </h6>
                  <p className="text-lg font-medium ml-2 text-gray-500">{`${
                    MonthNames[Today.getMonth()]
                  }, ${Today.getFullYear()}`}</p>
                </div>
                <p className="text-lg font-medium text-gray-500">
                  {WeekDays[Today.getDay() - 1]}
                </p>
              </div>

              <div className="text-right">
               <span className={`text-sm font-medium  ${CurrDayAttData.attendanceStatus === "P"? 'bg-green-600' : CurrDayAttData.attendanceStatus === "A"? 'bg-red-600' :'' }  p-1 text-gray-50 rounded-lg`}> {CurrDayAttData.attendanceStatus === "P"? 'Present' : CurrDayAttData.attendanceStatus === "A"? 'Absent' :'' }</span>
              </div>

              <div className="absolute right-0 bottom-0 mb-5 mr-6 pointer-events-none hidden xl:block">
                <FontAwesomeIcon
                  icon={faCalendarDays}
                  className="text-8xl text-gray-200 -rotate-[30deg]"
                />
              </div>

            </div>
          </div>

          <div className="flex flex-col gap-5 border-r pr-4 border-slate-400">
            <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-28">
              <div className="flex flex-col justify-between">
                <p className="text-lg font-medium text-gray-500">
                  Punch In Time
                </p>
                <h6 className="text-2xl font-semibold mb-3">{CurrDayAttData.inTime}</h6>
              </div>
              <div className="align-middle my-auto">
                <div className="bg-red-100 rounded-full ">
                  <Avatar
                    bg="green.100"
                    size="lg"
                    icon={
                      <FontAwesomeIcon
                        className=" text-green-600 text-3xl"
                        icon={faClock}
                      />
                    }
                  />
                </div>
              </div>
            </div>
            <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-28">
              <div className="flex flex-col justify-between">
                <p className="text-lg font-medium text-gray-500">
                  Punch Out Time
                </p>
                <h6 className="text-2xl font-semibold mb-3">{CurrDayAttData.outTime}</h6>
                
              </div>
              <div className="align-middle my-auto">
                <div className="bg-red-100 rounded-full ">
                  <Avatar
                    bg="green.100"
                    size="lg"
                    icon={
                      <FontAwesomeIcon
                        className=" text-green-600 text-3xl"
                        icon={faClock}
                      />
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="bg-white relative p-4 rounded-3xl shadow-lg h-full">
          <div className="border-b  border-slate-400 pb-2 mb-4">
            <h1 className="text-xl font-bold text-gray-800">
              Holidays
            </h1>
          </div>

          {/* <div className="absolute right-0 top-0 -mt-6  pointer-events-none hidden xl:block">
              <img className="w-24" src="/images/confetti.gif" />
            </div> */}

          <Swiper
            initialSlide={new Date().getMonth()+1}
            grabCursor={true}
            className="mySwiper bg-white flex"
            breakpoints={{
              640: { slidesPerView: 2, spaceBetween: 1 },
              768: { slidesPerView: 3, spaceBetween: 1 },
              1024: { slidesPerView: 3, spaceBetween: 1 },
              1280: { slidesPerView: 3, spaceBetween: 1 },
              1440: { slidesPerView: 4, spaceBetween: 1 }
            }}
          >
            {_.orderBy(HolidayList,['holidayDate'], ['asc']).map((data, i) => {
              return (
                <SwiperSlide key={i}>
                  <div
                    className={` w-40 h-32 flex flex-col  justify-between rounded-2xl border-indigo-400 border-2 `}
                  >
                    <div className="text-center p-2 space-y-2">
                      <h6 className="text-gray-600  text-5xl font-bold">
                        {new Date(data.holidayDate).getDate()}
                      </h6>
                      <p className="text-base">{`${
                        MonthNames[new Date(data.holidayDate).getMonth()]
                      }, ${new Date(data.holidayDate).getFullYear()}`}</p>
                    </div>
                    <div
                      className={`  text-white bg-indigo-400 rounded-b-xl text-center`}
                    >
                      {data.holidayName}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}

          </Swiper>

        </div>
      </div>

      <div className="grid grid-cols-2 gap-5">
        <div className="bg-white rounded-2xl drop-shadow-md p-3 h-96">
          <div className="border-b justify-between flex  border-slate-400 pb-2 mb-4">
            <h1 className="text-xl font-bold text-gray-800">
              Yearly Attendence
            </h1>
            <p className="text-lg font-medium ml-2 text-gray-500">{`${Today.getFullYear()}`}</p>
          </div>
          <ResponsiveContainer height={300}>
            <LineChart width={770} data={AttendanceData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="monthShortName" tickFormatter={formatXAxis} />
              <YAxis />
              <Tooltip />
              <Legend verticalAlign="top" height={36} />
              <Line
                type="monotone"
                dataKey="totalPresent"
                name="Present Days"
                stroke="#8884d8"
                activeDot={{ r: 8 }}
              />
              <Line
                type="monotone"
                dataKey="totalLeave"
                name="Leaves"
                stroke="red"
                activeDot={{ r: 8 }}
              />

            </LineChart>
          </ResponsiveContainer>
        </div>

        <div className="bg-white rounded-2xl drop-shadow-md p-3 h-96">
          <Tabs variant="solid-rounded" size="sm" colorScheme="purple">
            <TabList>
              <Tab>HR Announcement</Tab>
              {/* <Tab>HR Announcement</Tab>
              <Tab>Things to do</Tab> */}
              <Tab>Birthdays</Tab>
            </TabList>
            <hr className="mt-3" />

            <TabPanels p={0}>
              <TabPanel p={0}>
              <div>
                <ul className='max-h-80 h-auto overflow-auto'>

                  {AnnouncementList.map((data,i)=>{
                    return <li key={i} className="border-b border-slate-200 last:border-0 ">
                    <Link
                      className="block items-center py-2  hover:bg-slate-100 px-2"
                      to="#0"
                    >
                      <div className="flex items-center pb-2">
                      <span className="text-2xl mr-2">📣</span>
                      <span className="block ">{data.description}</span>
                      </div>
                      <div className="flex justify-between">
                      <span className="block text-xs font-medium text-slate-400">{new Date(data.startDate).toLocaleDateString('en-GB')}</span>
                      <span className="block text-xs font-medium text-slate-400">{new Date(data.endDate).toLocaleDateString('en-GB')}</span>

                      </div>
                    </Link>
                  </li>
                  })}
          </ul>
                </div>
              </TabPanel>
              {/* <TabPanel>
                <p>No HR Announcement Found</p>
              </TabPanel>
              <TabPanel>
                <p>No Things to do Found</p>
              </TabPanel> */}
              <TabPanel p={0}>
                <div>
                <ul className='max-h-80 h-auto overflow-auto'>
                {_.orderBy(BirthdayList,['dateOfBirth'], ['asc']).map((data,i)=>{
                    return <li key={i} className="border-b border-slate-200 last:border-0 ">
                    <Link
                      className="flex justify-between items-center py-2  hover:bg-slate-100 px-2"
                      to="#0"
                    >
                      <div className="flex items-center">
                      <span className="text-2xl mr-2">🥳</span>
                      <span className="block "> Wish <span className="font-semibold">{data.employeeFullName}</span> Happy Birthday!</span>
                      </div>
                      <span className="block text-xs font-medium text-slate-400">{new Date(data.dateOfBirth).toLocaleDateString('en',BirthDateOptions)}</span>
                    </Link>
                  </li>
                  }) }

                  
          </ul>
                </div>
              </TabPanel>
            </TabPanels>
          </Tabs>
        </div>
      </div>
    </div>
  );
}

export default EmpDashboard;
