import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Input,
  Button,
  Switch,
  useToast,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Box,
  Select,
} from "@chakra-ui/react";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Edit, Save } from "react-feather";
import axios from "axios";
import ReactSelect from "react-select";

function ExpenseTypeMaster() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [ApprovalBy, setApprovalBy] = useState([]);
  const [ApprovalLevel, setApprovalLevel] = useState();
  const [ApprovalLevelList, setApprovalLevelList] = useState([
    {
      id: 1,
      approvalLevel: 1,
      levelName: "First Level",
    },
    {
      id: 2,
      approvalLevel: 2,
      levelName: "Second Level",
    },
    {
      id: 3,
      approvalLevel: 3,
      levelName: "Third Level",
    },
  ]);

  const [ApprovalMatrixRowData, setApprovalMatrixRowData] = useState([]);

  const [DepartmentList, setDepartmentList] = useState([]);

  const [ExpenseTypeId, setExpenseTypeId] = useState();
  const [ExpenseTypeName, setExpenseTypeName] = useState();

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Expense Type Name",
      field: "expenseType",
    },
    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleExpenseTypeSwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      field: "isActive",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              setExpenseTypeId(params.data.expenseID);
              setExpenseTypeName(params.data.expenseType);
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
          >
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  const [ApprovalMatrixColumnDefs, setApprovalMatrixColumnDefs] = useState([
    {
      headerName: "Approval Level",
      field: "approvalLevel",
      cellRenderer: (params) => (
        <span>
          {params.data.approvalLevel === 1
            ? "First Level"
            : params.data.approvalLevel === 2
            ? "Second Level"
            : params.data.approvalLevel === 3
            ? "Third Level"
            : ""}
        </span>
      ),
    },
    {
      headerName: "Approval By",
      field: "approvalByDepartment",
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    enableCellTextSelection: true,
  }));

  const HandleExpenseTypeSwitch = async (checked, TypeData) => {
    setRowData((prev) => {
      const newState = prev.map((obj) => {
        if (obj.expenseID === TypeData.expenseID) {
          return { ...obj, isActive: checked };
        }
        return obj;
      });
      return newState;
    });

    let body = {
      expenseID: TypeData.expenseID,
      expenseType: TypeData.expenseType,
      isActive: checked,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log("Expense Type Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SaveExpenseType`,
        body
      )
      .then((response) => {
        console.log("Switch Response", response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetExpenseTypeMasterList();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Expense Type not Updated`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  useEffect(() => {
    GetExpenseTypeMasterList();
    getDepartmentList();
    GetApprovalMatrixList();
  }, []);

  const getDepartmentList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/DepartmentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Department List Response", response);
      const res = response.data;
      let ActiveDepartment = res.filter((data) => data.isActive === true);

      setDepartmentList([
        {
          departmentId: 0,
          srNo: 1,
          departmentName: "Reporting Officers",
          isActive: true,
          createdBy: 0,
          _createdOn: "2022-11-28T00:00:00",
          createdOn: "2022-11-28T00:00:00",
          modifiedBy: 0,
          _modifiedOn: "2022-11-28T00:00:00",
          modifiedOn: "2022-11-28T00:00:00",
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
          count: 0,
        },
        ...ActiveDepartment,
      ]);
    } catch (error) {
      console.error(error);
    }
  };

  const GetExpenseTypeMasterList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetExpenseList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Expense Type List", response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetApprovalMatrixList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetMasterApprovalMatrixList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Approval Matrix List", response);
      const res = response.data;

      console.log(
        "Filtered Approval Level List",
        ApprovalLevelList.filter(
          (obj1) =>
            !res.find((obj2) => obj1.approvalLevel === obj2.approvalLevel)
        )
      );
      setApprovalLevelList(
        ApprovalLevelList.filter(
          (obj1) =>
            !res.find((obj2) => obj1.approvalLevel === obj2.approvalLevel)
        )
      );
      setApprovalMatrixRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const SaveExpenseTypeMaster = async (e) => {
    e.preventDefault();
    let body = {
      expenseID: ExpenseTypeId,
      expenseType: ExpenseTypeName,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log("Expense Type Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SaveExpenseType`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetExpenseTypeMasterList();
        setExpenseTypeName("");
        setExpenseTypeId(0);
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Expense Type not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  console.log("Approval LEvel", ApprovalLevel);
  const SaveApprovalMatrix = async (e) => {
    e.preventDefault();

    let body = [
      {
        approvalByDepartment: ApprovalBy.departmentName,
        departmentId: ApprovalBy.departmentId,
        approvalLevel: Number(ApprovalLevel),
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      },
    ];

    console.log("Save Approval Details Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SaveMasterApprovalMatrix`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        GetApprovalMatrixList();
        setApprovalBy([]);
        setApprovalLevel("");
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Approval Details not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Expense Master</h1>
      </div>

      <Tabs colorScheme={"purple"} variant={"solid-rounded"} size={"sm"}>
        <TabList>
          <Tab>Expense Types</Tab>
          <Tab>Approval Matrix</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <div className="mb-6">
              <Accordion
                defaultIndex={[0]}
                shadow="lg"
                border="transparent"
                rounded="xl"
                allowMultiple
                bg="white"
              >
                <AccordionItem>
                  <h2>
                    <AccordionButton>
                      <h6 className="text-xl text-left flex-1 font-bold text-gray-800">
                        Add Expense Type
                      </h6>
                      <AccordionIcon />
                    </AccordionButton>
                  </h2>
                  <AccordionPanel pb={4}>
                    <form onSubmit={SaveExpenseTypeMaster}>
                      <div className="space-y-2 w-1/3">
                        <label>Expense Type Name</label>
                        <Input
                          isRequired
                          value={ExpenseTypeName || ""}
                          onChange={({ target }) => {
                            setExpenseTypeName(target.value);
                          }}
                          borderColor="gray.400"
                          placeholder="Expense Type Name"
                        />
                      </div>

                      <div className="space-x-3 my-2 text-right">
                        <Button
                          onClick={() => {
                            setExpenseTypeName("");
                            setExpenseTypeId(0);
                          }}
                          type="reset"
                          variant="outline"
                          colorScheme="purple"
                        >
                          Reset
                        </Button>
                        <Button type="submit" colorScheme="purple">
                          Save
                        </Button>
                      </div>
                    </form>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </div>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
            </div>
          </TabPanel>
          <TabPanel px={0}>
            <form
              onSubmit={SaveApprovalMatrix}
              className="bg-white rounded-2xl p-4 flex gap-8 items-end mb-4 "
            >
              <div className="space-y-2 w-1/4">
                <label>Select Approval By</label>
                <ReactSelect
                  options={DepartmentList}
                  value={ApprovalBy}
                  onChange={(value) => {
                    setApprovalBy(value);
                  }}
                  getOptionLabel={(option) => option.departmentName}
                  getOptionValue={(option) => option.departmentId}
                  placeholder="Select Approval By"
                />
              </div>

              <div className="space-y-2">
                <label>Approval Level</label>
                <Select
                  value={ApprovalLevel}
                  onChange={({ target }) => {
                    setApprovalLevel(target.value);
                  }}
                  isRequired
                  borderColor={"gray.400"}
                  placeholder="Select Approval Level"
                >
                  {ApprovalLevelList.map((data, i) => {
                    return (
                      <option key={i} value={data.approvalLevel}>
                        {data.levelName}
                      </option>
                    );
                  })}
                </Select>
              </div>
              <Button
                isDisabled={ApprovalBy.length === 0 ? true : false}
                type="submit"
                colorScheme={"purple"}
              >
                Save
              </Button>
            </form>

            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                ref={gridRef} // Ref for accessing Grid's API
                rowData={ApprovalMatrixRowData} // Row Data for Rows
                columnDefs={ApprovalMatrixColumnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={15}
                paginationNumberFormatter={paginationNumberFormatter}
                enableCellTextSelection={true}
              />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

export default ExpenseTypeMaster;
