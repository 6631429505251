import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { Edit, FilePlus, Lock } from "react-feather";
import { Avatar, Tabs, TabList, TabPanels, Tab, TabPanel, Input,Select,InputGroup,ModalBody,ModalCloseButton,ModalContent,ModalFooter,ModalHeader,Modal,ModalOverlay,Button, InputRightAddon, useDisclosure, Tooltip, Checkbox, Textarea, useToast, Switch  } from "@chakra-ui/react";
import { Briefcase, File, User, Users,Plus, Book, Award } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { faCheck, faDownload, faLock, faLockOpen, faUpRightFromSquare, faXmark } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import usePost from '../Utils/usePost';

function EmployeeDetails() {

  const userData = JSON.parse(localStorage.getItem('user'));

  const toast = useToast();
  const id = "toast";
  const UrlParams = useParams();

  const navigate = useNavigate();

  const { IsPostLoading: GradeDivLoading, PostData: GradeDivPost } = usePost('/api/EmployeeDetail/SaveEmpGradAndOtherDetail');
  const { IsPostLoading: EmpLoginStatusLoading , PostData: EmpLoginStatusPost } = usePost('/api/Account/EnableDesableEmpLogin');



  const [tabIndex, setTabIndex] = useState(0)

  const handleTabsChange = (index) => {
    setTabIndex(index)
  }

  const EmployeeGuid = UrlParams.empguid;

  const [ActivePlanDetails,setActivePlanDetails] = useState([]);

  const [ProfilePicPath, setProfilePicPath] = useState();

  const [EmployeeList, setEmployeeList] = useState([]);

  const [AgeInYears, setAgeInYears] = useState();

  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);

  const [DesignationList, setDesignationList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([]);
  const [EmployeeCategoryList, setEmployeeCategoryList] = useState([]);
  const [IsValidityRequired, setIsValidityRequired] = useState(false);

  const [BankMasterList, setBankMasterList] = useState([]);

  const [ProbationStartDate, setProbationStartDate] = useState();

  const [EmpTabData, setEmpTabData] = useState([]);
  const [EmpTabLock, setEmpTabLock] = useState([]);

  const [EmpGrade, setEmpGrade] = useState();
  const [GradeList, setGradeList] = useState([]);

  const [DesignationName, setDesignationName] = useState();

    const {
        isOpen: isAddDesignationOpen,
        onOpen: onAddDesignationOpen,
        onClose: onAddDesignationClose,
      } = useDisclosure();

      const {
        isOpen: isAddGradeDivisionOpen,
        onOpen: onAddGradeDivisionOpen,
        onClose: onAddGradeDivisionClose,
      } = useDisclosure();
    

      const [DepartmentName, setDepartmentName] = useState();

      const {
        isOpen: isAddDepartmentOpen,
        onOpen: onAddDepartmentOpen,
        onClose: onAddDepartmentClose,
      } = useDisclosure();
    
      const [EmpTypeName, setEmpTypeName] = useState();
      const [ProbationMonth, setProbationMonth] = useState();
    
      const [IsProbationApply, setIsProbationApply] = useState(false);
      const [DocumentName, setDocumentName] = useState();

      
      const {
        isOpen: isAddEmpCategoryOpen,
        onOpen: onAddEmpCategoryOpen,
        onClose: onAddEmpCategoryClose,
      } = useDisclosure();

      const {
        isOpen: isAddDocumemntTypeOpen,
        onOpen: onAddDocumemntTypeOpen,
        onClose: onAddDocumemntTypeClose,
      } = useDisclosure();

      const [DocumentMasterList, setDocumentMasterList] = useState([]);

      const [SelfReporting, setSelfReporting] = useState(false)

      const [EmpTitle, setEmpTitle] = useState();

      //  Employee Official Details Variables
      const [FirstName, setFirstName] = useState();
      const [MiddleName, setMiddleName] = useState();
      const [LastName, setLastName] = useState();
      const [DateofJoining, setDateofJoining] = useState();
      const [Designation, setDesignation] = useState();
      const [DesignationOld, setDesignationOld] = useState();
      const [Department, setDepartment] = useState();
      const [ReportingOfficer, setReportingOfficer] = useState();
      const [OfficeMobileNo, setOfficeMobileNo] = useState();
      const [RegulationDate, setRegulationDate] = useState();
      const [OfficeEmail, setOfficeEmail] = useState();
      const [EmployeeCategory, setEmployeeCategory] = useState();
      const [ProbationEndDate, setProbationEndDate] = useState();
      const [PersonalEmail, setPersonalEmail] = useState();
      const [RoleName, setRoleName] = useState();
      const [RoleList, setRoleList] = useState([]);
      //Induction DetailsVariables
      const [IsInductionCompleted, setIsInductionCompleted] = useState(false);
      const [InductionCompleteDate, setInductionCompleteDate] = useState();
      const [InductionGivenBy, setInductionGivenBy] = useState();

      //mutti update--> 

      const [WorkingLocation, setWorkingLocation] = useState();
      const [RefId, setRefId] = useState();

      // Personal Details Variables


      const [Gender, setGender] = useState();
      const [DateOfBirth, setDateOfBirth] = useState();
      const [PlaceOfBirth, setPlaceOfBirth] = useState();
      const [Nationality, setNationality] = useState();
      const [MobileNo, setMobileNo] = useState();
      const [PersonalEmailId, setPersonalEmailId] = useState();
      const [MartialStatus, setMartialStatus] = useState();
      const [Citizenship, setCitizenship] = useState();
      const [BloodGroup, setBloodGroup] = useState();

      const [EmergencyContactName, setEmergencyContactName] = useState();
      const [EmergencyContactNumber, setEmergencyContactNumber] = useState();
      const [EmergencyRelation, setEmergencyRelation] = useState();
      const [EmergencyAddress, setEmergencyAddress] = useState();

      const [PresentHouseNumber, setPresentHouseNumber] = useState();
      const [PresentAddress1, setPresentAddress1] = useState();
      const [PresentAddress2, setPresentAddress2] = useState();
      const [PresentLandmark, setPresentLandmark] = useState();
      const [PresentCountry, setPresentCountry] = useState();
      const [PresentState, setPresentState] = useState();
      const [PresentCity, setPresentCity] = useState();
      const [PresentPincode, setPresentPincode] = useState();


      const [IsSameAsPresent, setIsSameAsPresent] = useState();

      const [PermanentHouseNumber, setPermanentHouseNumber] = useState();
      const [PermanentAddress1, setPermanentAddress1] = useState();
      const [PermanentAddress2, setPermanentAddress2] = useState();
      const [PermanentLandmark, setPermanentLandmark] = useState();
      const [PermanentCountry, setPermanentCountry] = useState();
      const [PermanentState, setPermanentState] = useState();
      const [PermanentCity, setPermanentCity] = useState();
      const [PermanentPincode, setPermanentPincode] = useState();



      // Visa Details
      const [VisaType, setVisaType] = useState();
      const [VisaNumber, setVisaNumber] = useState();
      const [VisaIssuePlace, setVisaIssuePlace] = useState();
      const [VisaStartDate, setVisaStartDate] = useState();
      const [VisaExpiryDate, setVisaExpiryDate] = useState();

      // Passport Details
      const [PassportNumber, setPassportNumber] = useState();
      const [PassportExpiryDate, setPassportExpiryDate] = useState();

      const [LabourCardNumber, setLabourCardNumber] = useState();
      const [LabourCardExpiryDate, setLabourCardExpiryDate] = useState();


      // Qualification Details Variables

      const [HighestQualification, setHighestQualification] = useState();
      const [University, setUniversity] = useState();
      const [Institute, setInstitute] = useState();
      const [YearOfPassing, setYearOfPassing] = useState();
      const [DurationStartDate, setDurationStartDate] = useState();
      const [DurationEndDate, setDurationEndDate] = useState();
      const [QualificationStatus, setQualificationStatus] = useState();
      const [QualificationCountry, setQualificationCountry] = useState();
      const [QualificationState, setQualificationState] = useState();
      const [QualificationCity, setQualificationCity] = useState();


      // Previous Job Details Variables
      const [PrevJobStartDate, setPrevJobStartDate] = useState();
      const [PrevJobEndDate, setPrevJobEndDate] = useState();
      const [AnnualCTC, setAnnualCTC] = useState();
      const [JobTitle, setJobTitle] = useState();
      const [PrevJobDescription, setPrevJobDescription] = useState();
      const [PrevJobCompany, setPrevJobCompany] = useState();


      //Document Details Variables
      const [EmpDocumentList, setEmpDocumentList] = useState([]);
      const [DocumentTypeName, setDocumentTypeName] = useState();
      const [DocumentNumber, setDocumentNumber] = useState();
      const [DocumentValidUpto, setDocumentValidUpto] = useState();
      const [AttachDocument, setAttachDocument] = useState();
      const [DocumentRemarks, setDocumentRemarks] = useState();

      // Bank Details Variables
      const [BankName, setBankName] = useState();
      const [BankBranchName, setBankBranchName] = useState();
      const [BeneficiaryName, setBeneficiaryName] = useState();
      const [IfscIban, setIfscIban] = useState();
      const [AccountNumber, setAccountNumber] = useState();
      const [RoutingNumber, setRoutingNumber] = useState();

      const [IBANNumber, setIBANNumber] = useState();
      const [RoutingSwiftCode, setRoutingSwiftCode] = useState();
      const [PersonalId, setPersonalId] = useState();

      // Family Detail Variables
      const [FamilyDetailsId, setFamilyDetailsId] = useState(0)
      const [MemberName, setMemberName] = useState();
      const [MemberRelation, setMemberRelation] = useState();
      const [MemberAge, setMemberAge] = useState();
      const [MemberGender, setMemberGender] = useState();
      const [MemberEmiratesId, setMemberEmiratesId] = useState();
      const [MemberVisaDetails, setMemberVisaDetails] = useState();
      const [MemberPassportDetails, setMemberPassportDetails] = useState();


      const [EmpGeneralInfo, setEmpGeneralInfo] = useState([]);


      // Compliance Details
      const [EpfNumber, setEpfNumber] = useState();
      const [UanNumber, setUanNumber] = useState();
      const [EsiNumber, setEsiNumber] = useState();
      const [UANError, setUANError] = useState(null);

      // Login Info
      const [UserName, setUserName] = useState()
      const [OldPassword, setOldPassword] = useState();
      const [NewPassword, setNewPassword] = useState();
      const [ConfirmPassword, setConfirmPassword] = useState();
      const [LoginActive,setLoginActive] = useState(false);

      const [GradeDivisionDetails, setGradeDivisionDetails] = useState({
        empGuid: EmployeeGuid,
        empCode: EmpGeneralInfo.empCode,
        division: '',
        grade: '',
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
        isActive: true,
      });



      const gridRef = useRef();

      const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
      const [DocumentColumnDefs, setDocumentColumnDefs] = useState([
        {
          headerName: "Document Type",
          field: "documentName",
        },
        {
          headerName: "Document Number",
          field: "documentNumber",
        },
        {
          headerName: "Remark",
          field: "remark",
        },
        {
          headerName: "Action",
          cellRenderer: (params) => <Button
          leftIcon={<FontAwesomeIcon icon={faDownload} />}
          colorScheme="purple"
          variant='link'
          onClick={async()=>{
           await fetch(
             `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${params.data.attachmentPath}`,
         ).then(response => {
       
           // console.log("file Response",response);
   
           const fileURL = response.url
   
           // console.log("File URL",fileURL);
   
              let alink = document.createElement('a');
               alink.href = fileURL;
               // setViewChecklistAttachment(alink);
               // onViewAttachmentOpen();
   
               // console.log("alink",alink);
               alink.download = fileURL;
               alink.click();
       
   
           }).catch((error)=>{
             // console.log("File Api Error",error);
           })
          }}
        >
          Download
        </Button>
        },
      ]);
    
        const [familyrowData, setfamilyRowData] = useState([]); // Table Data Variable

        const [familycolumnDefs] = useState([

          {
            headerName: "Member Name",
            field: "memberName",
          },
          {
            headerName: "Relation",
            field: "relation",
          },
          {
            headerName: "Age",
            field: "age",
          },
          {
            headerName: "Gender",
            field: "gender",
          },
          {
            headerName: "Emirates Id",
            field: "emiratesID",
          },
          {
            headerName: "Visa Detail",
            field: "visaDetail",
          },
          {
            headerName: "Passport Detail",
            field: "passportDetail",
          },
          {
            headerName: "Action",
            cellRenderer: (params) => <div className="space-x-4">

       <Button 
        onClick={()=>{
          let FamilyRowData = params.data;
          setFamilyDetailsId(FamilyRowData.empFamilyDetailId);
          setMemberName(FamilyRowData.memberName)
          setMemberRelation(FamilyRowData.relation)
          setMemberAge(FamilyRowData.age)
          setMemberGender(FamilyRowData.gender)
          setMemberEmiratesId(FamilyRowData.emiratesID)
          setMemberVisaDetails(FamilyRowData.visaDetail)
          setMemberPassportDetails(FamilyRowData.passportDetail)
        }}
        variant='solid' size='xs' colorScheme='blue'><Edit size='14px' className="mr-2"/>Edit</Button>
    </div>
          },
      
        ]);

      // Table Pagination
      const paginationNumberFormatter = useCallback((params) => {
        return "[" + params.value.toLocaleString() + "]";
      }, []);
    
      //Table columns properties applies to all columns
      const defaultColDef = useMemo(() => ({
        flex: 1,
        sortable: true,
        filter: "agTextColumnFilter",
        floatingFilter: true,
        cacheQuickFilter: true,
        cellClass: 'no-border'
      }));


      useEffect(() => {
        // console.log("Age ",moment().diff(moment(DateOfBirth), 'years'));
        setAgeInYears(moment().diff(moment(DateOfBirth), 'years'))
      }, [DateOfBirth])
      

      useEffect(() => {
        GetEmpTabData();
        GetEmpTabLockData();
        getCountryList();
        getEmpGeneralInfo();
        getEmpList();
        getDesignationList();
        getDepartmentList();
        getEmployeeCategoryList();
        getBankMasterList();
        getEmpInductionDetails();
        getEmpPersonalDetails();
        getEmpPassportDetails();
        getEmpQualificationDetails();
        getEmpPreviousJobDetails();
        getEmpDocumentDetails();
        getEmpBankDetails();
        getDocumentMasterList();
        getEmpFamilyDetails();
        GetComplianceDetails();
        GetRoleList();
        GetUserPlanDetails();
        GetGradeList();
        GetEmpGradeInfo();
      }, [])

      
      
      const GetUserPlanDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/SuperAdmin/GetUserSubscriptionByEmail?EmailId=${userData.userName}`
          )
          // console.log("Active Plan Details",response);
          setActivePlanDetails(response.data);
        } catch (err) {
          console.error(err);
        }
      }

      const GetGradeList = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpGradAndOtherDetail?BranchId=${userData.isActiveBranch}`
          )
          console.log("Grade List",response);
          setGradeList(response.data);
        } catch (err) {
          console.error(err);
        }
      }

      const GetRoleList = async() => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetRoleMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
              )
              // console.log("Role List ",response)
      
              setRoleList(response.data);
        } catch (error) {
         console.error(error);   
        }
      }

      const GetEmpTabData = async() => {
        try {
          const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpIsCompleteStatusById?Guid=${EmployeeGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
  
          console.log("Emp Tab Data Response",response)
          const res = response.data;
          setEmpTabData(res)
        } catch (error) {
            console.error(error);
        }

      }


      const GetEmpTabLockData = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpIsLockStatusById?Guid=${EmployeeGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Emp Tab Lock Data",response);
          setEmpTabLock(response.data);
        } catch (err) {
          console.error(err);
        }
      }

      const getEmpList = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          // console.log(response);
          setEmployeeList(response.data);
        } catch (error) {
          console.error(error);
        }
      }


          
      const getCountryList = async()=>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCountryMasterList`
          )
          // console.log("Country List",response.data);
          setCountryList(response.data);
        } catch (error) {
          console.error(error)
        }
      }


      const getStateList = async(CountryID)=>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/GetStateListByCountryId?CountryId=${CountryID}`
          )
          // console.log("State List",response.data);
          setStateList(response.data);
        } catch (error) {
          console.error(error)
        }
      }

      
      
    
      const getDesignationList = async()=>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/GetDesignationMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          // console.log("Designation List",response.data);

          const res = response.data;
          let ActiveMasterList = res.filter(data => data.isActive === true)

          setDesignationList(ActiveMasterList);
        } catch (error) {
          console.error(error)
        }
      }
      
      const getDepartmentList = async()=>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/DepartmentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          // console.log("Department List",response.data);

          const res = response.data;
          let ActiveMasterList = res.filter(data => data.isActive === true)

          setDepartmentList(ActiveMasterList);
        } catch (error) {
          console.error(error)
        }
      }

      const getDocumentMasterList = async()=>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/GetDocumentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          // console.log("Document Master List",response.data);

          const res = response.data;
          let ActiveMasterList = res.filter(data => data.isActive === true)
          setDocumentMasterList(ActiveMasterList);
        } catch (error) {
          console.error(error);
        }
      }
      
      const getEmployeeCategoryList = async()=>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/GetEmployeeTypeMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          // console.log("Employee Category List",response.data);

          const res = response.data;
          let ActiveMasterList = res.filter(data => data.isActive === true)
          setEmployeeCategoryList(ActiveMasterList);
        } catch (error) {
          console.error(error)
        }
      }

      const getBankMasterList = async()=>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/GetBankMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          // console.log("Bank Master List",response.data);
          const res = response.data;
          let ActiveMasterList = res.filter(data => data.isActive === true)
          setBankMasterList(ActiveMasterList);
        } catch (error) {
          console.error(error)
        }
      }

      const GetEmpGradeInfo = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpGradAndOtherDetailByGuid?EmpGuid=${EmployeeGuid}`
          )
          console.log("Emp Grade Info",response);
          setEmpGrade(response?.data)
        } catch (err) {
          console.error(err);
        }
      }
      
    
      const getEmpGeneralInfo = async() => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeGeneralInfoByGuid?EmpGuid=${EmployeeGuid}`
            )
    
             console.log("Emp General Info Response",response)
            const res = response.data;
            if(res.branchId !== userData.isActiveBranch){
              console.log("Navigation Status",userData.isActiveBranch,EmpGeneralInfo.branchId);
              navigate('/editemployees');
            }
            setEmpGeneralInfo(response.data)
            setFirstName(res.firstName);
            setLastName(res.lastName);
            setEmpTitle(res.title);

            setMiddleName(res.middleName);
            setDateofJoining(new Date(res.dateofjoin))
            setDesignation(res.designation);
            setDesignationOld(res.designation)
            setDepartment(res.department);
            if(res.reportingOfficersId === 0){
              setSelfReporting(true);
            }else{
              setReportingOfficer(res.reportingOfficersId);
            }
            setOfficeMobileNo(res.mobile);
            setOfficeEmail(res.officeEmail);
            setUserName(res.officeEmail);
            setRefId(res.refId);
            setWorkingLocation(res.workingLocation);
            setLoginActive(res.isLoginActive);
            setRegulationDate(new Date(res.regulationDate));
            setEmployeeCategory(res.empType);
            setPersonalEmail(res.officeEmail);
            setRoleName(res.roleId);
            setProbationEndDate(new Date(res.probationEndDate));
            setProbationStartDate(new Date(res.probationStartDate));
            FetchProfilePic(res.profilePicPath);

            // HandleProbationEndDate(res.dateofjoin,res.empType)


            // setProbationEndDate(new Date(res.probationEndDate).toLocaleDateString('en-CA'));
            // setProbationStartDate(new Date(res.probationStartDate).toLocaleDateString('en-CA'));
        } catch (error) {
            console.error(error);
        }
      }



      const FetchProfilePic = async(FilePath)=>{
        await fetch(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${FilePath}`,
      ).then(response => {
    
        // console.log("file Response",response);

        const fileURL = response.url

        // console.log("File URL",fileURL);


            let alink = document.createElement('a');
            alink.href = fileURL;
            // setViewChecklistAttachment(alink);
            // onViewAttachmentOpen();

            // console.log("alink",alink);
            // alink.download = fileURL;
            // alink.click();

            setProfilePicPath(fileURL)
    

        }).catch((error)=>{
          // console.log("File Api Error",error);
        })
       }

      const HandleProbationEndDate = async(regulationDate,empType) =>{

        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Master/GetEmployeeTypeMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          // console.log("Employee Category List",response.data);

          const res = response.data;
          let ActiveMasterList = res.filter(data => data.isActive === true);

          
          let ProbMonth = ActiveMasterList.filter(data => data.empTypeId === Number(empType));
          // console.log("Got Probation Month",ProbMonth[0].probationMonth,"Regulation Date",regulationDate, "Employee Category List",EmployeeCategoryList);
          
          setProbationEndDate(new Date(regulationDate).addMonths(Number(ProbMonth[0].probationMonth)).toLocaleDateString('en-CA'))


        } catch (error) {
          console.error(error)
        }


      }

      const getEmpInductionDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeInductionDetailByGuid?EmpGuid=${EmployeeGuid}`
        )
        // console.log("Emp Induction Details",response.data);
        const res = response.data;
        setInductionCompleteDate(moment(res.inductionDate).format('YYYY-MM-DD'));
          //  setInductionCompleteDate(new Date(res.inductionDate));

        setInductionGivenBy(res.inductionGivenBy);

        if(res.inductionDate){
          setIsInductionCompleted(true);
        }

        } catch (error) {
          console.error(error)
        }
      }


      const getEmpPersonalDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpPersonalInfoByGuid?EmpGuid=${EmployeeGuid}`
        )
        // console.log("Emp Personal Details",response.data);

        const res = response.data;
         setGender(res.gender);
         setDateOfBirth(new Date(res.dateOfBirth || new Date(moment().subtract(18, 'years').format('YYYY-MM-DD'))));
         setPlaceOfBirth(res.placeOfBirth);
         setNationality(res.nationality);
         setMobileNo(res.personalMobile);
         setPersonalEmailId(res.personalEmail);
         setMartialStatus(res.maritalStatus);
         setCitizenship(res.citizenShip);
         setBloodGroup(res.bloodGroup);

         setEmergencyContactName(res.contactName);
         setEmergencyContactNumber(res.emergencyContactNo);
         setEmergencyRelation(res.familyMemberRelation);
         setEmergencyAddress(res.relationMaillingAddress);

         setPresentHouseNumber(res.presentHouseNo);
         setPresentAddress1(res.presentAddress1);
         setPresentAddress2(res.presentAddress2);
         setPresentLandmark(res.presentLandmark);
         setPresentCountry(res.presentCountry);
         getStateList(res.presentCountry);
         setPresentState(res.presentState);
         setPresentCity(res.presentCity);
         setPresentPincode(res.presentPin);

         setPermanentHouseNumber(res.permanentHouseNo);
         setPermanentAddress1(res.permanentAddress1);
         setPermanentAddress2(res.permanentAddress2);
         setPermanentLandmark(res.permanentLandmark);
         setPermanentCountry(res.permanentCountry);
         setPermanentState(res.permanentState);
         setPermanentCity(res.permanentCity);
         setPermanentPincode(res.permanentPin);
 
          setIsSameAsPresent(res.addressStatus);

        } catch (error) {
          console.error(error)
        }
      }


      const getEmpPassportDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpPassportVisaByGuid?EmpGuid=${EmployeeGuid}`
        )
        // console.log("Emp Passport Details",response.data);
        const res = response.data;
        setVisaType(res.visaType);
        setVisaNumber(res.visaNo);
        setVisaIssuePlace(res.vissaIssuePlace);
        setVisaStartDate(moment(res.visaStartDate || new Date()).format('YYYY-MM-DD'));
        setVisaExpiryDate(moment(res.visaExpiryDate || new Date()).format('YYYY-MM-DD'));
        setPassportNumber(res.passportNo);
        setPassportExpiryDate(moment(res.passportExpiryDate || new Date()).format('YYYY-MM-DD'));
        setLabourCardNumber(res.labourCardNo);
        setLabourCardExpiryDate(moment(res.labourCardExpiry || new Date()).format('YYYY-MM-DD'));


        } catch (error) {
          console.error(error)
        }
      }

      const getEmpQualificationDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpQualificationByGuid?EmpGuid=${EmployeeGuid}`
        )
        // console.log("Emp Qualification Details",response.data);
        const res = response.data;

        setHighestQualification(res.empQualification);
        setUniversity(res.empUniversity);
        setInstitute(res.instituteName);
        setYearOfPassing(new Date(res.empYearOfPassing || new Date()));
        setQualificationStatus(res.status);
        setQualificationCountry(res.country);
        getStateList(res.country)
        setQualificationCity(res.city);
        setQualificationState(res.state);


        } catch (error) {
          console.error(error)
        }
      }


      const getEmpPreviousJobDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpPreviousJobDetailsByGuid?EmpGuid=${EmployeeGuid}`
        )
        // console.log("Emp Previous Job Details",response);

        const res = response.data;

        setPrevJobStartDate(moment(res.startDate).format('YYYY-MM-DD'));
        setPrevJobEndDate(moment(res.endDate).format('YYYY-MM-DD'));
        setAnnualCTC(res.salary);
        setJobTitle(res.position);
        setPrevJobDescription(res.jobDescription);
        setPrevJobCompany(res.company);

        } catch (error) {
          console.error(error)
        }
      }


      const getEmpDocumentDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpDocuumentDetailsByGuid?EmpGuid=${EmployeeGuid}`
        )
        // console.log("Emp Document Details",response);
        setEmpDocumentList(response.data)
        } catch (error) {
          console.error(error)
        }
      }


      const getEmpBankDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeBankDetail?EmpGuid=${EmployeeGuid}`
        )
        // console.log("Emp Bank Details",response);
        const res = response.data;
        setBankName(res.bankName);
        setBankBranchName(res.bankBranchName);
        setIfscIban(res.ifscCode);
        setAccountNumber(res.accountNumber);
        setIBANNumber(res.ibanNumber);
        setRoutingSwiftCode(res.routingAndSwiftCode);
        setPersonalId(res.personalId);

        } catch (error) {
          console.error(error)
        }
      }

      const getEmpFamilyDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeeFamilyDetail?EmpGuid=${EmployeeGuid}`
        )
        // console.log("Emp Family Details",response);
        setfamilyRowData(response.data);
        } catch (error) {
          console.error(error)
        }
      }


      const GetComplianceDetails = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmpPFESIDataByGuid?EmpGuid=${EmployeeGuid}`
          )
          // console.log("Emp Compliance Details",response);
          const res = response.data;

          setUanNumber(res.empEPFUANNo);
          setEpfNumber(res.empEPFNo);
          setEsiNumber(res.empESICNo)
        } catch (error) {
          console.error(error);
        }
      }
 
      const SaveEmployeeOfficialDetails = async (e) => {
        e.preventDefault();
        let body={
          title:EmpTitle,
          guId: EmployeeGuid,
          id: EmpGeneralInfo.id,
          empCode: EmpGeneralInfo.empCode,
          firstName: FirstName,
          middleName:MiddleName || '',
          lastName: LastName,
          dateofjoin: moment(DateofJoining).format('YYYY-MM-DD'),
          designation: String(Designation),
          department: String(Department),
          reportingOfficersId: SelfReporting === true ? 0 : ReportingOfficer,
          mobile: OfficeMobileNo,
          officeEmail: OfficeEmail,
          refId:RefId,
          workingLocation:WorkingLocation,
          regulationDate:moment(RegulationDate).format('YYYY-MM-DD') ,
          roleId:Number(RoleName),
          empType: String(EmployeeCategory || 0),
          probationStartDate: moment(ProbationStartDate).format('YYYY-MM-DD'),
          probationEndDate: moment(ProbationEndDate).format('YYYY-MM-DD'),
          isActive: true,
          isComplete: true,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
        }

         console.log("Employee Official Details Body",body)

        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeGeneralInfo`,body
        )
        .then((response) => {
          // console.log(response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }

          setTabIndex(1);

          getEmpGeneralInfo();
          GetEmpTabData();
    
        })
        .catch((error) => {
          console.error(error);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Details not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });

          let body2 = {
              empGuid : EmployeeGuid,
              empName :  FirstName + " " + MiddleName + " " + LastName,
              empcode : EmpGeneralInfo.empCode,
              designationOld : String(DesignationOld),
              designationNew : String(Designation),
              desigStartDate : moment().format('YYYY-MM-DD'),
              desigEndDate : moment().add(1,'year').format('YYYY-MM-DD'),
              designationStatus : 1,
              gRindex : 0,
              companyId : userData.companyId,
              branchId : userData.isActiveBranch,
            }

          // console.log("Designation Update body",body2);

          await axios.post(
            `${process.env.REACT_APP_ACQ_URL}/api/DesignationTracking/SaveDesignationTrackingData`,body2
          )
          .then((response) => {
            // console.log("Designation Update Response",response);
            const res = response.data;
            if (!toast.isActive(id)) {
              toast({
                id,
                title:res,
                position: "top",
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            }
      
          })
          .catch((error) => {
            console.error("Designation Update Error",error);
  
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: `Details not Added`,
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });

          let body3 = {
            id: 0,
            empGuid: EmployeeGuid,
            empCode: EmpGeneralInfo.empCode,
            // division: GradeList.filter((data) => data.grade === EmpGrade)[0]?.division || '',
            division: EmpGrade.division || '',
            
            grade: EmpGrade.grade || '',
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            isActive: true,
          }

          console.log("Grade Save Body", body3);

          await axios.post(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmpGradAndOtherDetail`,body3
          )
          .then((response) => {
             console.log("Grade Save Response",response);
            const res = response.data;
            if (!toast.isActive(id)) {
              toast({
                id,
                title:res,
                position: "top",
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            }

            GetEmpGradeInfo();
      
          })
          .catch((error) => {
            console.error("Grade Save Error",error);
  
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: `Details not Added`,
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });
      }


      const SaveEmpInductionDetails = async(e)=>{
        e.preventDefault();

        let body ={

          GuId:EmployeeGuid,
          InductionDate: InductionCompleteDate,
          InductionGivenBy: InductionGivenBy,
          CompanyId: userData.companyId,
          BranchId: userData.isActiveBranch
        }
        
        // console.log("Emp Induction Save Body",body);


        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeInductionDetail`,body
        )
        .then((response) => {
          // console.log(response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }

          getEmpInductionDetails();
    
        })
        .catch((error) => {
          console.error(error);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Details not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });


      }

      var newDate = new Date();




Date.isLeapYear = function (year) { 
  return (((year % 4 === 0) && (year % 100 !== 0)) || (year % 400 === 0)); 
};

Date.getDaysInMonth = function (year, month) {
  return [31, (Date.isLeapYear(year) ? 29 : 28), 31, 30, 31, 30, 31, 31, 30, 31, 30, 31][month];
};

Date.prototype.isLeapYear = function () { 
  return Date.isLeapYear(this.getFullYear()); 
};

Date.prototype.getDaysInMonth = function () { 
  return Date.getDaysInMonth(this.getFullYear(), this.getMonth());
};

Date.prototype.addMonths = function (value) {
  var n = this.getDate();
  this.setDate(1);
  this.setMonth(this.getMonth() + value);
  this.setDate(Math.min(n, this.getDaysInMonth()));
  return this;
};




      const SaveEmpPersonalInfo = async (e) => {
        e.preventDefault();

        let body ={
          EmpGuid: EmployeeGuid,
          DateOfBirth: DateOfBirth,
          Nationality: Nationality,
          Gender: Gender,
          PersonalMobile: MobileNo,
          PersonalEmail: PersonalEmailId,
          PlaceOfBirth: PlaceOfBirth,
          BloodGroup: BloodGroup,
          MaritalStatus: MartialStatus,
          CitizenShip: Citizenship,
          EmergencyContactNo: EmergencyContactNumber,
          ContactName: EmergencyContactName,
          RelationMaillingAddress: EmergencyAddress,
          FamilyMemberName: EmergencyContactName,
          FamilyMemberRelation: EmergencyRelation,
          FamilyMemberContact: EmergencyContactNumber,
          PresentHouseNo: PresentHouseNumber,
          PresentAddress1: PresentAddress1,
          PresentAddress2: PresentAddress2,
          PresentLandmark: PresentLandmark,
          PresentCountry: PresentCountry,
          PresentState: PresentState,
          PresentCity: PresentCity,
          PresentPin: PresentPincode,
          PermanentHouseNo: PermanentHouseNumber,
          PermanentAddress1: PermanentAddress1,
          PermanentAddress2: PermanentAddress2,
          PermanentLandmark: PermanentLandmark,
          PermanentCountry: PermanentCountry,
          PermanentState: PermanentState,
          PermanentCity: PermanentCity,
          PermanentPin: PermanentPincode,
          isComplete: true,
          // PermanentMobileNo: 0,
          // PermanentPhoneNo: 0,
          // PresentMobileNo: "string",
          // PresentPhoneNo: "string",
          // AddressStatus: true,
          // IsEPF: true,
          // EmpEPFNo: "string",
          // EmpEPFUANNo: "string",
          // IsESI: true,
          // EmpESICNo: "string",
          CompanyId: userData.companyId,
          BranchId:userData.isActiveBranch 

        }
        
        // console.log("Emp Personal Save Body",body);


        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeePersonalDetail`,body
        )
        .then((response) => {
          // console.log(response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }

          setTabIndex(2);

          getEmpPersonalDetails();
        })
        .catch((error) => {
          console.error(error);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Details not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });


      }



     const SaveEmpPassportDetails = async (e) => {
      e.preventDefault();


      let body ={
        // EmpPassportVisaInfoId: 0,
        // EncryptedId: "string",
        GuId: EmployeeGuid,
        VisaStatus: true,
        VisaType: VisaType,
        VisaNo: VisaNumber,
        VissaIssuePlace: VisaIssuePlace,
        VisaStartDate: VisaStartDate,
        VisaExpiryDate: VisaExpiryDate,
        PassportNo: PassportNumber,
        PassportExpiryDate: PassportExpiryDate,
        LabourCardNo: LabourCardNumber,
        LabourCardExpiry: LabourCardExpiryDate,
        isComplete: true,
        CompanyId: userData.companyId,
        BranchId: userData.isActiveBranch
      }

      // console.log("Emp Passport Save Body",body);



      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeePassportVisaDetail`,body
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        setTabIndex(3);

        getEmpPassportDetails();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Details not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });



     }


     const SaveEmpQualificationDetails = async (e) => {
      e.preventDefault();

      let body={

        GuId: EmployeeGuid,
        EmpQualification: HighestQualification,
        EmpUniversity: University,
        EmpYearOfPassing: YearOfPassing,
        InstituteName: Institute,
        // Duration: "string",
        Status: QualificationStatus,
        Country: QualificationCountry,
        City: QualificationCity,
        State: QualificationState,
        isComplete: true,
        CompanyId: userData.companyId,
        BranchId: userData.isActiveBranch,
      }

      // console.log("Emp Qualification Save Body",body);

      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeQualificationDetail`,body
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        setTabIndex(4);
        getEmpQualificationDetails();
  
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Details not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });



     }


     const SaveEmpPreviousJobDetails = async (e) => {
      e.preventDefault();

      let body={
        GuId: EmployeeGuid,
        StartDate:PrevJobStartDate,
        EndDate:PrevJobEndDate,
        Salary: AnnualCTC,
        JobDescription: PrevJobDescription,
        Position: JobTitle,
        Company: PrevJobCompany,
        isComplete: true,
        CompanyId: userData.companyId,
        BranchId: userData.isActiveBranch
      }

      // console.log("Emp Previous Job Save Body",body);

      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeePreviousJobDetail`,body
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        setTabIndex(5);
        getEmpPreviousJobDetails();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Details not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });



     }


     const SaveEmpDocumentsDetails = async (e) => {
      e.preventDefault();

      if(Number(ActivePlanDetails.currentDataUsage || 0) <= Number(ActivePlanDetails.dataUsageUpto || 0)){
        
      const UploadDocument = new FormData();
      UploadDocument.append("Attachment",AttachDocument);
      UploadDocument.append("GuId",EmployeeGuid);
      UploadDocument.append("empCode",EmpGeneralInfo.empCode);
      UploadDocument.append("DocumentType",DocumentTypeName);
      UploadDocument.append("DocumentNumber",DocumentNumber);
      UploadDocument.append("ValidUpto",moment(DocumentValidUpto).format('YYYY-MM-DD'));
      UploadDocument.append("Remark",DocumentRemarks);
      UploadDocument.append("CompanyId", userData.companyId);
      UploadDocument.append("BranchId",userData.isActiveBranch);

      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeDocuumentDetail`,UploadDocument
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getEmpDocumentDetails();
        setDocumentTypeName('');
        setDocumentNumber('');
        setDocumentRemarks('');
        setDocumentValidUpto('');
        setAttachDocument('');

      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Details not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
    }else{
      alert('Data Usage Limit Exceeded. Please Contact to HR');
    }


     }


     const SaveEmpBankDetails = async (e) => {
      e.preventDefault();

      let body={
          guId : EmployeeGuid,
          empCode : EmpGeneralInfo.empCode,
          bankName : BankName,
          bankBranchName : BankBranchName,
          accountNumber : AccountNumber,
          ifscCode : IfscIban,
          personalId : PersonalId,
          routingAndSwiftCode : RoutingSwiftCode,
          ibanNumber : IBANNumber,
          isActive : true,
          isComplete: true,
          companyId : userData.companyId,
          branchId : userData.isActiveBranch
        }

      // console.log("Emp Bank Save Body",body);

      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeBankDetail`,body
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        setTabIndex(7);
        getEmpBankDetails();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Details not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });



     }


     const SaveEmpFamilyDetails = async (e) => {
      e.preventDefault();

      let body={
        empFamilyDetailId:FamilyDetailsId,
        GuId: EmployeeGuid,
        EmpCode: userData.empCode,
        Gender: MemberGender,
        MemberName: MemberName,
        Relation: MemberRelation,
        Age: MemberAge,
        EmiratesID: MemberEmiratesId,
        VisaDetail: MemberVisaDetails,
        PassportDetail: MemberPassportDetails,
        CompanyId: userData.companyId,
        BranchId: userData.isActiveBranch
      }

      // console.log("Emp Family Save Body",body);

      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeFamilyDetail`,body
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
        getEmpFamilyDetails();
        setFamilyDetailsId(0)
        setMemberName('')
        setMemberRelation('')
        setMemberAge('')
        setMemberGender('')
        setMemberEmiratesId('')
        setMemberVisaDetails('')
        setMemberPassportDetails('')
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Details not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });



     }



     const SaveDesignationMaster = async (e) =>{
      e.preventDefault();
      let body = {
        designationName: DesignationName,
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      }

      // console.log(body);
      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveDesignationMaster`,body
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
  
        getDesignationList();
        onAddDesignationClose();
        setDesignationName('');
  
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Designation not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });

    }


    const SaveDepartmentMaster = async (e) =>{
      e.preventDefault();
      let body = {
        departmentName: DepartmentName,
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      }
      // console.log(body);
      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveDepartmentMaster`,body
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
  
         getDepartmentList();
         onAddDepartmentClose();
         setDepartmentName('');
  
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Department not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });


    }


    const SaveEmpTypeName = async (e) => {
      e.preventDefault();
      let body = {
        empTypeName: EmpTypeName,
        isProbationApply: IsProbationApply,
        probationMonth: ProbationMonth,
  
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      };
      // console.log(body);
      await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveEmployeeTypeMaster`,
          body
        )
        .then((response) => {
          // console.log(response);
          const res = response.data;
          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
  
          getEmployeeCategoryList();
          onAddEmpCategoryClose();
          setEmpTypeName("");
          setProbationMonth("");
          setIsProbationApply(false);
        })
        .catch((error) => {
          console.error(error);
  
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `Employee Category not Added`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });
    };


    const SaveDocumentMaster = async (e) =>{
      e.preventDefault();
      let body = {
        documentTypeName: DocumentName,
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch
      }
      // console.log(body);
      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveDocumentMaster`,body
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }
  
        getDocumentMasterList();
        onAddDocumemntTypeClose();
        setDocumentName('');
  
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Document not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });


    }



    const handleDownloadFile = async (DocPath) =>{
      // console.log("Document path",DocPath)
     await fetch(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
    ).then(response => {

      // console.log("file Response",response);

      response.blob().then(blob => {
          // Creating new object of PDF file
          const fileURL = window.URL.createObjectURL(blob);

          // Setting various property values
          let alink = document.createElement('a');
          alink.href = fileURL;
          alink.download = fileURL;
          alink.click();
      })

    }).catch((error)=>{
      // console.log("File Api Error",error);
    })
    }



    
  const HandleResetPassword = async (e) => {

    e.preventDefault();

    let body = {
        empGuId: EmployeeGuid,
        userName: UserName,
        // oldPassword: OldPassword,
        password: NewPassword,
        confirmPassword: ConfirmPassword,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
        isActive: true
      }

      // console.log("Reset Password Body",body);

      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/HardResetPassword`,body
      )
      .then((response) => {
        // console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:"Done",
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        setOldPassword('');
        setNewPassword('');
        setConfirmPassword('');
        
      })
      .catch((error) => {
        console.error(error);
  
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Password not Updated`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });



  }




  const SaveComplianceDetails = async (e) => {
    
    e.preventDefault();

    let body={
      empGuid : EmployeeGuid,
      empCode : EmpGeneralInfo.empCode,
      empEPFNo : EpfNumber,
      empESICNo : EsiNumber,
      empEPFUANNo : UanNumber,
      isComplete: true,
      companyId : userData.companyId,
      branchId : userData.branchId
    }

    // console.log("Compliance Save Body",body);


    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmpPFESIData`,body
    )
    .then((response) => {
      // console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
      
    })
    .catch((error) => {
      console.error(error);

      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Compiance Details not Updated`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });


  }



  const SaveProfilePic = async (ProfilePic) => {

    console.log("Profile Pic",ProfilePic);

    const ProfileData = new FormData();

    ProfileData.append('empGuid',EmployeeGuid)
    ProfileData.append('attachment',ProfilePic)
    ProfileData.append('companyId',userData.companyId)
    ProfileData.append('branchId',userData.isActiveBranch)
    ProfileData.append('empCode',EmpGeneralInfo.empCode)

    await axios
    .post(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmployeeProfilePic`,ProfileData)
    .then((response) => {
      // console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title: res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }
  
      getEmpGeneralInfo();
  
    })
    .catch((error) => {
      console.error(error);
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Branch not Updated`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });


  }

  // console.log("Data usage",Number(ActivePlanDetails.currentDataUsage),Number(ActivePlanDetails.dataUsageUpto));


  const HandleLockUnlock = async (TabDesc,TabAction) => {
    try {
      let body = {
        empGuid: EmployeeGuid,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
        generalInfoIsLock: TabDesc === "General" && TabAction === "Lock"? true : TabDesc === "General" && TabAction === "Unlock" ? false : EmpTabLock.generalInfoIsLock ,
        personalInfoIsLock: TabDesc === "Personal" && TabAction === "Lock"? true : TabDesc === "Personal" && TabAction === "Unlock" ? false : EmpTabLock.personalInfoIsLock ,
        indcutionInfoIsLock: TabDesc === "Induction" && TabAction === "Lock"? true : TabDesc === "Induction" && TabAction === "Unlock" ? false : EmpTabLock.indcutionInfoIsLock ,
        passportVisaInfoIsLock: TabDesc === "Passport" && TabAction === "Lock"? true : TabDesc === "Passport" && TabAction === "Unlock" ? false : EmpTabLock.passportVisaInfoIsLock ,
        qualificationInfoIsLock: TabDesc === "Qualification" && TabAction === "Lock"? true : TabDesc === "Qualification" && TabAction === "Unlock" ? false : EmpTabLock.qualificationInfoIsLock ,
        previousJobInfoIsLock: TabDesc === "PrevJob" && TabAction === "Lock"? true : TabDesc === "PrevJob" && TabAction === "Unlock" ? false : EmpTabLock.previousJobInfoIsLock ,
        employeeDocsInfoIsLock: TabDesc === "EmpDoc" && TabAction === "Lock"? true : TabDesc === "EmpDoc" && TabAction === "Unlock" ? false : EmpTabLock.employeeDocsInfoIsLock ,
        bankInfoIsLock: TabDesc === "BankInfo" && TabAction === "Lock"? true : TabDesc === "BankInfo" && TabAction === "Unlock" ? false : EmpTabLock.bankInfoIsLock ,
        familyInfoIsLock: TabDesc === "FamilyInfo" && TabAction === "Lock"? true : TabDesc === "FamilyInfo" && TabAction === "Unlock" ? false : EmpTabLock.familyInfoIsLock ,
        complianceInfoIsLock: TabDesc === "Compliance" && TabAction === "Lock"? true : TabDesc === "Compliance" && TabAction === "Unlock" ? false : EmpTabLock.complianceInfoIsLock ,
        loginInfoIsLock: TabDesc === "LoginInfo" && TabAction === "Lock"? true : TabDesc === "LoginInfo" && TabAction === "Unlock" ? false : EmpTabLock.loginInfoIsLock ,
      }

      console.log("Lock Body",body);

      const response = await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmpLockByGuid`,body
      )

      console.log("response",response);
      
      if (!toast.isActive(id)) {
        toast({
          id,
          title:response?.data,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }

      GetEmpTabLockData();

    } catch (err) {
      console.error(err)
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Tab not Locked`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    }
   }
  

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Employee Details</h1>
      </div>

      <div className="bg-white rounded-2xl drop-shadow-md mb-4 p-4">
        <div className="flex flex-wrap gap-10 align-middle items-center">
          <div >

            <label htmlFor="dropzone-file" className="cursor-pointer">
              <div>
               <Avatar size="2xl" name={EmpGeneralInfo.fullName} src={ProfilePicPath}/>
              </div>
             <Input id="dropzone-file" onChange={({target})=>{SaveProfilePic(target.files[0])}} type="file" accept="image/*" hidden />
            </label>
          </div>

          <div className="space-y-1 flex-auto">
            <h6 className="font-medium">
              Employee Name/Code : <span className="font-normal ml-2">{EmpGeneralInfo.fullName} / {EmpGeneralInfo.empCode}</span>
            </h6>
            <h6 className="font-medium">
              Designation : <span className="font-normal ml-2">{EmpGeneralInfo.designationName}</span>
            </h6>
            <h6 className="font-medium">
            Department : <span className="font-normal ml-2">{EmpGeneralInfo.departmentName}</span>
            </h6>
            <h6 className="font-medium">
              RefID : <span className="font-normal ml-2">{EmpGeneralInfo.refId}</span>
            </h6>
          </div>

      

          <div className="space-y-1 flex-auto">
            <h6 className="font-medium">
              Employee status : <span className="font-normal ml-2">{EmpGeneralInfo.isActive === true ? 'Active':'In Active'}</span>
            </h6>
            <h6 className="font-medium">
              Email : <span className="font-normal ml-2">{EmpGeneralInfo.officeEmail}</span>
            </h6>
            <h6 className="font-medium">
              Branch : <span className="font-normal ml-2">{userData.activeBranchName}</span>
            </h6>
           
            <h6 className="font-medium">
             City : <span className="font-normal ml-2">{EmpGeneralInfo.workingLocation}</span>
            </h6>
          </div>
         
        </div>
       
      </div>

      <div className="bg-white rounded-2xl drop-shadow-md p-4 mb-4">
      <Tabs variant="line" colorScheme="purple"  size="sm" index={tabIndex} onChange={handleTabsChange} >
          <TabList flexWrap={"wrap"}>
            <Tab>
              <User size="14px" className="mr-1.5" />
              <span className="hidden text-xs lg:block">General</span>
              {EmpTabLock.generalInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />  : <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs"/> }             
              {EmpTabData.generalInfoIsComplete === true ? <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" size="xs" /> : <FontAwesomeIcon icon={faXmark} className="ml-1 text-red-500" size="xs" /> }
            </Tab>
            <Tab>
              <User size="14px" className="mr-1.5" />
              <span className="hidden text-xs lg:block">Personal</span>
              {EmpTabLock.personalInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />: <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs" />}
              {EmpTabData.personalInfoIsComplete === true ? <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" size="xs" /> : <FontAwesomeIcon icon={faXmark} className="ml-1 text-red-500" size="xs" /> }
            </Tab>
            <Tab>
              <Book size="14px" className="mr-1.5" />
              <span className="hidden text-xs lg:block">{userData.countryName === "India"? 'Passport' : 'Passport/Visa'}</span>
              {EmpTabLock.passportVisaInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />: <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs" />}
              {EmpTabData.passportVisaInfoIsComplete === true ? <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" size="xs" /> : <FontAwesomeIcon icon={faXmark} className="ml-1 text-red-500" size="xs" /> }
            </Tab>
            <Tab>
              <Award size="14px" className="mr-1.5" />
              <span className="hidden text-xs lg:block">Qualification</span>
              {EmpTabLock.qualificationInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />: <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs" />}
              {EmpTabData.qualificationInfoIsComplete === true ? <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" size="xs" /> : <FontAwesomeIcon icon={faXmark} className="ml-1 text-red-500" size="xs" /> }
            </Tab>
            <Tab>
              <Briefcase size="14px" className="mr-1.5" />
              <span className="hidden text-xs lg:block">Previous Job</span>
              {EmpTabLock.previousJobInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />: <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs" />}
              {EmpTabData.previousJobInfoIsComplete === true ? <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" size="xs" /> : <FontAwesomeIcon icon={faXmark} className="ml-1 text-red-500" size="xs" /> }
            </Tab>
            <Tab>
              <File size="14px" className="mr-1.5" />
              <span className="hidden text-xs lg:block">Document</span>
              {EmpTabLock.employeeDocsInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />: <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs" />}
              {EmpTabData.previousJobInfoIsComplete === true ? <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" size="xs" /> : <FontAwesomeIcon icon={faXmark} className="ml-1 text-red-500" size="xs" /> }
            </Tab>
            <Tab>
              <FontAwesomeIcon icon={faBuilding} className="mr-1.5" />
              <span className="hidden text-xs lg:block">Bank</span>
              {EmpTabLock.bankInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />: <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs" />}
              {EmpTabData.previousJobInfoIsComplete === true ? <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" size="xs" /> : <FontAwesomeIcon icon={faXmark} className="ml-1 text-red-500" size="xs" /> }
            </Tab>
            <Tab>
              <Users size="14px" className="mr-1.5" />
              <span className="hidden text-xs lg:block">Family</span>
              {EmpTabLock.familyInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />: <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs" />}
              {EmpTabData.previousJobInfoIsComplete === true ?   <FontAwesomeIcon icon={faCheck} className="ml-1 text-green-500" size="xs" /> : <FontAwesomeIcon icon={faXmark} className="ml-1 text-red-500" size="xs" /> }
            </Tab>
            {userData.countryName === "India"?
            <Tab>
              <FilePlus size="14px" className="mr-1.5" />
              <span className="hidden text-xs lg:block">Compliance</span>
              {EmpTabLock.complianceInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />: <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs" />}
            </Tab> : ''}
            <Tab>
              <Lock size="14px" className="mr-1.5" />
              <span className="hidden text-xs lg:block">Login</span>
              {EmpTabLock.loginInfoIsLock === true ? <FontAwesomeIcon icon={faLock} className="ml-1 text-green-500" size="xs" />: <FontAwesomeIcon icon={faLockOpen} className="ml-1 text-red-500" size="xs" />}
            </Tab>
          </TabList>
          {/* <hr className="mt-2" /> */}

          <TabPanels>

            {/* General Details Tab */}
            <TabPanel>
              <form onSubmit={SaveEmployeeOfficialDetails}>
                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                  <User size="18px" className=" text-indigo-600 mr-2 " />
                  <h1 className="text-lg font-normal text-indigo-600">
                    Employee Official Details
                  </h1>
                </div>

                <div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>


                <div className='flex gap-6'>
                <div className='space-y-2'>
              <label>Title<span className='text-red-500 font-semibold'>*</span></label>
              <Select value={EmpTitle} onChange={({target})=>{setEmpTitle(target.value)}} isRequired borderColor='gray.400' placeholder='Select Title'>
              <option value={'Mr.'}>Mr.</option>
                <option value={'Ms.'}>Ms.</option>
              </Select>
            </div>

<div className='space-y-2'>
   <label>First Name<span className='text-red-500 font-semibold'>*</span></label>
   <Input isRequired value={FirstName || ''}              onChange={({target})=>{
                let InputTarget = target.value;
                setFirstName(InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1));
              }} borderColor='gray.400' placeholder='First Name' />
 </div>

 <div className='space-y-2'>
   <label>Middle Name</label>
   <Input value={MiddleName || ''}   onChange={({target})=>{
                let InputTarget = target.value;
                setMiddleName(InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1));
              }}  borderColor='gray.400' placeholder='Middle Name' />
 </div>

</div>

<div className='grid grid-cols-2 gap-6'>

<div className='space-y-2'>
    <label>Last Name<span className='text-red-500 font-semibold'>*</span></label>
    <Input value={LastName || ''} onChange={({target})=>{
                let InputTarget = target.value;
                setLastName(InputTarget.charAt(0).toUpperCase() + InputTarget.slice(1));
              }}  borderColor='gray.400' placeholder='Last Name' />
  </div>

  <div className='space-y-2'>
    <label>Assign Role<span className='text-red-500 font-semibold'>*</span></label>
    <Select isRequired value={RoleName} onChange={({target})=>{setRoleName(target.value)}} borderColor={'gray.400'} placeholder='Select Role'>
      {RoleList.filter((data)=> data.isActive ===  true).map((data,i)=>{
          return <option value={data.id}>{data.roleName}</option>
      })}
   </Select>
  </div>

</div>

</div>
<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>RefId<span className='text-red-500 font-semibold'>*</span></label>
  <Input
    isRequired
    isDisabled
    value={RefId || ''}
    onChange={({target}) => { setRefId(target.value); }}
    type='text'
    borderColor='gray.400'
    placeholder='RefId'
  />
</div>
<div className='space-y-2'>
  <label>Working Location<span className='text-red-500 font-semibold'>*</span></label>
  <Input isRequired value={WorkingLocation||''} onChange={({target})=>{setWorkingLocation(target.value)}} type='text' borderColor='gray.400' placeholder='Working Location' />
</div>

</div>
<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Date of Joining<span className='text-red-500 font-semibold'>*</span></label>
  <DatePicker
   required
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={DateofJoining}
    onChange={(date) =>{
      setDateofJoining(date); 
      setRegulationDate(date); 
      setProbationStartDate(date);

      let ProbMonth = EmployeeCategoryList.filter(data => data.empTypeId === Number(EmployeeCategory))[0];

      // console.log("Selected Probation Month",ProbMonth.probationMonth);

      setProbationEndDate(new Date(moment(date).add(Number(ProbMonth.probationMonth),'months').format('YYYY-MM-DD')))

    }}
    placeholderText="Date of Joining"
    dateFormat={"dd-MM-yyyy"}
  />
</div>

<div className='space-y-2'>
  <label>Designation<span className='text-red-500 font-semibold'>*</span></label>
  <InputGroup size='md'>
  <Select isRequired borderColor='gray.400' value={Designation} onChange={({target})=>{setDesignation(target.value)}}>
       {DesignationList.map((data,i)=>{
        return <option key={i} value={data.designationId}>{data.designationName}</option>
       })
       }
     </Select>
      <Tooltip placement='top' rounded='5px' hasArrow label='Add Designation' bg='gray.700' color='white'>
      <InputRightAddon onClick={onAddDesignationOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
      </Tooltip>
    </InputGroup>
</div>

</div>

<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Department<span className='text-red-500 font-semibold'>*</span></label>
  <InputGroup size='md'>

  <Select isRequired borderColor='gray.400' value={Department} onChange={({target})=>{setDepartment(target.value)}}>
       {DepartmentList.map((data,i)=>{
        return <option key={i} value={data.departmentId}>{data.departmentName}</option>
       })
       }
     </Select>

      <Tooltip placement='top' rounded='5px' hasArrow label='Add Department' bg='gray.700' color='white'>
      <InputRightAddon onClick={onAddDepartmentOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
      </Tooltip>
    </InputGroup>
</div>

<div className='space-y-2'>
  <div className='flex justify-between'>
   <label>Reporting Officer<span className='text-red-500 font-semibold'>*</span></label>
   <Checkbox borderColor={'gray.400'} isChecked={SelfReporting} onChange={({target})=>{setSelfReporting(target.checked)}}>Self Reporting</Checkbox>
  </div>
<Select isDisabled={SelfReporting === true ? true: false}
  value={ReportingOfficer} onChange={({target})=>{setReportingOfficer(target.value)}}  borderColor='gray.400' placeholder="Reporting Officer">
   {EmployeeList.filter((data)=>data.isActive === true).map((data,i)=>{
    return <option key={i} value={data.id}>{data.firstName} {data.lastName}</option>
   })}
 </Select>
</div>



</div>

<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Office Mobile No.<span className='text-red-500 font-semibold'>*</span></label>
  <Input isRequired value={OfficeMobileNo||''} onChange={({target})=>{setOfficeMobileNo(target.value)}} type='number' borderColor='gray.400' placeholder='Office Mobile No.' />
</div>

<div className='space-y-2'>
  <label>Employee Category<span className='text-red-500 font-semibold'>*</span></label>
  <InputGroup size='md'>
     <Select isRequired borderColor='gray.400' value={EmployeeCategory} 
     onChange={({target})=>{
      setEmployeeCategory(target.value); 
      // console.log("Targetted Id",target.id); 

      let ProbMonth = EmployeeCategoryList.filter(data => data.empTypeId === Number(target.value))[0];

      // console.log("Selected Probation Month",ProbMonth.probationMonth);

        setProbationEndDate(new Date(moment(RegulationDate).add(Number(ProbMonth.probationMonth),'months').format('YYYY-MM-DD'))) }}>
       {EmployeeCategoryList.map((data,i)=>{
        return <option  key={i} value={data.empTypeId}>{data.empTypeName}</option>
       })
       }
     </Select>


      <Tooltip placement='top' rounded='5px' hasArrow label='Add Employee Category' bg='gray.700' color='white'>
      <InputRightAddon onClick={onAddEmpCategoryOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
      </Tooltip>
    </InputGroup>
</div>

</div>



<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>
<div className='space-y-2'>
  <label>Regulation Date<span className='text-red-500 font-semibold'>*</span></label>
  <DatePicker
   disabled
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={RegulationDate}
    onChange={(date) => setRegulationDate(date)}
    placeholderText="Regulation Date"
    dateFormat={'dd-MM-yyyy'}
  />
</div>


<div className="grid grid-cols-2 gap-6"> 

<div className='space-y-2'>
  <label>Probation Start Date</label>
  <DatePicker
    disabled
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={ProbationStartDate}
    onChange={(date) => setProbationStartDate(date)}
    placeholderText="Probation Start Date"
    dateFormat={'dd-MM-yyyy'}
  />
</div>

<div className='space-y-2'>
  <label>Probation End Date</label>
  <DatePicker
    disabled
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={ProbationEndDate}
    onChange={(date) => setProbationEndDate(date)}
    placeholderText="Probation End Date"
    dateFormat={'dd-MM-yyyy'}
  />
</div>

</div>


</div>

<div className='grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8'>

<div className='space-y-2'>
  <label>Office Email<span className='text-red-500 font-semibold'>*</span></label>
  <Input isRequired value={OfficeEmail||''} onChange={({target})=>{setOfficeEmail(target.value)}} type='email' borderColor='gray.400' placeholder='Office Email' />
</div>

<div className="space-y-2">
  <div className="flex justify-between">
    <label>Grade</label>
    {/* {EmpGrade && <label>Division: {EmpGrade?.division || ''}
      <span className="font-semibold">{GradeList.filter((data) => data.grade === EmpGrade)[0]?.division || ''}</span>
      </label>} */}
  </div>
  <InputGroup size={'md'}>
    <Input isReadOnly value={EmpGrade?.grade + ' - ' + EmpGrade?.division || ''} borderColor='gray.400' placeholder='Grade' />
  {/* <Select value={EmpGrade} onChange={({target})=>{setEmpGrade(target.value)}} borderColor={'gray.400'} placeholder="Select Grade">
    {GradeList.map((data,i) => {
      return <option key={i} value={data.grade}>{data.grade} - {data.division}</option>
    })}
  </Select> */}
  <Tooltip placement='top' rounded='5px' hasArrow label='Add Grade & Division' bg='gray.700' color='white'>
      <InputRightAddon onClick={onAddGradeDivisionOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
      </Tooltip>
    </InputGroup>
</div>

</div>

                <div className="flex gap-6 justify-end mt-6">

                  {EmpTabLock.generalInfoIsLock ? 
                    <Button onClick={()=>{HandleLockUnlock('General','Unlock')}} variant="ghost" colorScheme="purple">Unlock</Button>
                  :
                  <>
                    <Button onClick={()=>{HandleLockUnlock('General','Lock')}} variant="ghost" colorScheme="purple">Lock</Button>
                    <Button variant="outline" colorScheme="purple">Reset</Button>
                    <Button type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </>}

                </div>
              </form>


            </TabPanel>


            {/* Personal Details Tab */}
            <TabPanel>

              <form onSubmit={SaveEmpPersonalInfo}>


              <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <User size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Personal Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Gender<span className='text-red-500 font-semibold'>*</span></label>
                    <Select isRequired value={Gender} onChange={({target})=>{setGender(target.value)}} borderColor="gray" placeholder="Select Gender" >
                        <option value='Male'>Male</option>
                        <option value='Female'>Female</option>
                    </Select>
                  </div>

                  <div className="space-y-2">
                  <div className="flex justify-between">
                     <label>Date of Birth (DD-MM-YYYY)<span className='text-red-500 font-semibold'>*</span></label>
                     <label className="font-medium">{`${AgeInYears} Years`}</label>
                    </div>

                    <DatePicker
                       required
                        className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                        selected={DateOfBirth}
                        onChange={(date) =>{
                          setDateOfBirth(date); 
                        }}
                        maxDate={new Date(moment().subtract(18, 'years').format('YYYY-MM-DD'))}
                        showYearDropdown
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                        placeholderText="Date of Joining"
                        dateFormat={"dd-MM-yyyy"}
                      />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Place of Birth</label>
                    <Input value={PlaceOfBirth ||''} onChange={({target})=>{setPlaceOfBirth(target.value)}} borderColor="gray" placeholder="Place of Birth" />
                  </div>

                  <div className="space-y-2">
                    <label>Nationality</label>
                    <Input value={Nationality ||''} onChange={({target})=>{setNationality(target.value)}} borderColor="gray" placeholder="Nationality" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Mobile No<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={MobileNo||''} onChange={({target})=>{setMobileNo(target.value)}} type='number' borderColor="gray" placeholder="Mobile No." />
                  </div>

                  <div className="space-y-2">
                    <label>Personal Email Id<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PersonalEmailId||''} onChange={({target})=>{setPersonalEmailId(target.value)}} borderColor="gray" placeholder="Personal Email Id" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Marital Status<span className='text-red-500 font-semibold'>*</span></label>
                    <Select isRequired value={MartialStatus} onChange={({target})=>{setMartialStatus(target.value)}} borderColor="gray" placeholder="Select Martial Status" >
                        <option value='Single'>Single</option>
                        <option value='Married'>Married</option>
                        <option value='Divorced'>Divorced</option>
                        <option value='Other'>Other</option>
                    </Select>
                  </div>

                  <div className="space-y-2">
                    <label>Citizenship</label>
                    <Input value={Citizenship||''} onChange={({target})=>{setCitizenship(target.value)}} borderColor="gray" placeholder="Citizenship" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Blood Group</label>
                    <Input value={BloodGroup||''} onChange={({target})=>{setBloodGroup(target.value)}} borderColor="gray" placeholder="Blood Group" />

                  </div>

                </div>

                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <User size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Emergency Contact Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Contact Name<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={EmergencyContactName||''} onChange={({target})=>{setEmergencyContactName(target.value)}} borderColor="gray" placeholder="Contact Name" />

                  </div>

                  <div className="space-y-2">
                    <label>Contact No.<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired type={'number'} value={EmergencyContactNumber||''} onChange={({target})=>{setEmergencyContactNumber(target.value)}} borderColor="gray" placeholder="Contact No." />
                  </div>
                </div>
              
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Relation<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={EmergencyRelation||''} onChange={({target})=>{setEmergencyRelation(target.value)}} borderColor="gray" placeholder="Relation" />

                  </div>

                  <div className="space-y-2">
                    <label>Address<span className='text-red-500 font-semibold'>*</span></label>
                    <Textarea isRequired value={EmergencyAddress||''} onChange={({target})=>{setEmergencyAddress(target.value)}} rows={1} borderColor="gray" placeholder="Address" />
                  </div>
                </div>

                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <User size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Present Address
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>House/Flat No.<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PresentHouseNumber||''} onChange={({target})=>{setPresentHouseNumber(target.value)}} borderColor="gray" placeholder="House/Flat No." />
                  </div>

                  <div className="space-y-2">
                    <label>Address Line 1<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PresentAddress1||''} onChange={({target})=>{setPresentAddress1(target.value)}} borderColor="gray" placeholder="Address Line 1" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Address Line 2</label>
                    <Input value={PresentAddress2||''} onChange={({target})=>{setPresentAddress2(target.value)}} borderColor="gray" placeholder="Address Line 2" />

                  </div>

                  <div className="space-y-2">
                    <label>Landmark</label>
                    <Input value={PresentLandmark||''} onChange={({target})=>{setPresentLandmark(target.value)}} borderColor="gray" placeholder="Landmark" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Country<span className='text-red-500 font-semibold'>*</span></label>
                    <Select isRequired value={PresentCountry} onChange={({target})=>{setPresentCountry(target.value); getStateList(target.value)}} borderColor="gray" placeholder="Select Country" >
                     {CountryList.map((data,i)=>{
                      return <option key={i} value={data.countryId} >{data.countryName}</option>
                     })}

                    </Select>

                  </div>

                  <div className="space-y-2">
                    <label>State<span className='text-red-500 font-semibold'>*</span></label>
                    <Select isRequired value={PresentState} onChange={({target})=>{setPresentState(target.value)}} borderColor="gray" placeholder="Select State" >
                      {StateList.map((data,i)=>{
                        return  <option value={data.stateId}>{data.stateName}</option>

                      })}
                    </Select>
                  </div>
                </div>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>City<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PresentCity||''} onChange={({target})=>{setPresentCity(target.value)}} borderColor="gray" placeholder="City" />

                  </div>

                  <div className="space-y-2">
                    <label>Pincode<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired type={'number'} value={PresentPincode||''} onChange={({target})=>{setPresentPincode(target.value)}} borderColor="gray" placeholder="Pincode" />
                  </div>
                </div>




                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <User size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Permanent Address
                </h1>
              </div>

              <div className="mb-4">
                <Checkbox isChecked={IsSameAsPresent} 
                onChange={({target}) => {
                  setIsSameAsPresent(target.checked);
                  if(target.checked === true){
                    setPermanentHouseNumber(PresentHouseNumber);
                    setPermanentAddress1(PresentAddress1);
                    setPermanentAddress2(PresentAddress2);
                    setPermanentLandmark(PresentLandmark);
                    setPermanentCountry(PresentCountry);
                    setPermanentState(PresentState);
                    setPermanentCity(PresentCity);
                    setPermanentPincode(PresentPincode);
                  }else{
                    setPermanentHouseNumber('');
                    setPermanentAddress1('');
                    setPermanentAddress2('');
                    setPermanentLandmark('');
                    setPermanentCountry('');
                    setPermanentState('');
                    setPermanentCity('');
                    setPermanentPincode('');
                  }
                }} borderColor='gray' >Same as Present Address</Checkbox>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>House/Flat No.</label>
                    <Input value={PermanentHouseNumber||''} onChange={({target})=>{setPermanentHouseNumber(target.value)}} borderColor="gray" placeholder="House/Flat No." />

                  </div>

                  <div className="space-y-2">
                    <label>Address Line 1</label>
                    <Input value={PermanentAddress1||''} onChange={({target})=>{setPermanentAddress1(target.value)}} borderColor="gray" placeholder="Address Line 1" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Address Line 2</label>
                    <Input value={PermanentAddress2||''} onChange={({target})=>{setPermanentAddress2(target.value)}} borderColor="gray" placeholder="Address Line 2" />

                  </div>

                  <div className="space-y-2">
                    <label>Landmark</label>
                    <Input value={PermanentLandmark||''} onChange={({target})=>{setPermanentLandmark(target.value)}} borderColor="gray" placeholder="Landmark" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Country</label>
                    <Select value={PermanentCountry} onChange={({target})=>{setPermanentCountry(target.value); getStateList(target.value)}} borderColor="gray" placeholder="Select Country" >
                    {CountryList.map((data,i)=>{
                      return <option key={i} value={data.countryId} >{data.countryName}</option>
                     })}
                    </Select>

                  </div>

                  <div className="space-y-2">
                    <label>State</label>
                    <Select value={PermanentState} onChange={({target})=>{setPermanentState(target.value)}} borderColor="gray" placeholder="Select State" >
                    {StateList.map((data,i)=>{
                        return  <option value={data.stateId}>{data.stateName}</option>

                      })}
                    </Select>
                  </div>
                </div>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>City</label>
                    <Input value={PermanentCity||''} onChange={({target})=>{setPermanentCity(target.value)}} borderColor="gray" placeholder="City" />

                  </div>

                  <div className="space-y-2">
                    <label>Pincode</label>
                    <Input type={'number'} value={PermanentPincode||''} onChange={({target})=>{setPermanentPincode(target.value)}} borderColor="gray" placeholder="Pincode" />
                  </div>
                </div>


                <div className="flex gap-6 justify-end mt-6">

                  {EmpTabLock.personalInfoIsLock ? 
                    <Button onClick={()=>{HandleLockUnlock('Personal','Unlock')}} variant="ghost" colorScheme="purple">Unlock</Button>
                  : <>
                    <Button onClick={()=>{HandleLockUnlock('Personal','Lock')}} variant="ghost" colorScheme="purple">Lock</Button>
                    <Button variant="outline" colorScheme="purple">Reset</Button>
                    <Button type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </>}

                </div>
                
                </form>


            </TabPanel>

            {/* Passport Details Tab */}
            <TabPanel>
              <form onSubmit={SaveEmpPassportDetails}>

              {userData.countryName === "India"? <>

              <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Book size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                 Passport Details
                </h1>
              </div>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Passport No.<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PassportNumber||''} onChange={({target})=>{setPassportNumber(target.value)}} borderColor="gray" placeholder="Passport No." />

                  </div>

                  <div className="space-y-2">
                    <label>Passport Expiry Date<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PassportExpiryDate} onChange={({target})=>{setPassportExpiryDate(target.value)}} type='date' borderColor="gray" placeholder="Visa Start Date" />
                  </div>
                </div>


                </> : <>

                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Book size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                 Passport Details
                </h1>
              </div>


                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Passport No.<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PassportNumber||''} onChange={({target})=>{setPassportNumber(target.value)}} borderColor="gray" placeholder="Passport No." />

                  </div>

                  <div className="space-y-2">
                    <label>Passport Expiry Date<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PassportExpiryDate} onChange={({target})=>{setPassportExpiryDate(target.value)}} type='date' borderColor="gray" placeholder="Visa Start Date" />
                  </div>
                </div>
                
            <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Book size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                 Visa Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Visa Type</label>
                    <Select  value={VisaType} onChange={({target})=>{setVisaType(target.value)}} borderColor="gray" placeholder="Select Visa Type" >
                      <option value='visit visa'>Visit Visa</option>
                      <option value='Employeement visa'>Employeement Visa</option>
                      <option value='Un-limited'>Un-limited</option>
                    </Select>

                  </div>

                  <div className="space-y-2">
                    <label>Visa No.</label>
                    <Input  value={VisaNumber||''} onChange={({target})=>{setVisaNumber(target.value)}} borderColor="gray" placeholder="Visa No." />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Visa Issue Place</label>
                    <Input  value={VisaIssuePlace||''} onChange={({target})=>{setVisaIssuePlace(target.value)}} borderColor="gray" placeholder="Visa Issue Place" />

                  </div>

                  <div className="space-y-2">
                    <label>Visa Start Date</label>
                    <Input  value={VisaStartDate} onChange={({target})=>{setVisaStartDate(target.value)}} type='date' borderColor="gray" placeholder="Visa Start Date" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Visa Expiry Date</label>
                    <Input  value={VisaExpiryDate} onChange={({target})=>{setVisaExpiryDate(target.value)}} type='date' borderColor="gray" placeholder="Visa Issue Place" />
                  </div>

                </div>



                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Book size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                 Labour Card Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Labour Card No.</label>
                    <Input  value={LabourCardNumber||''} onChange={({target})=>{setLabourCardNumber(target.value)}} borderColor="gray" placeholder="Labour Card No." />

                  </div>

                  <div className="space-y-2">
                    <label>Labour Card Expiry Date</label>
                    <Input  value={LabourCardExpiryDate} onChange={({target})=>{setLabourCardExpiryDate(target.value)}} type='date' borderColor="gray" placeholder="Visa Start Date" />
                  </div>
                </div>

                </>}

                <div className="flex gap-6 justify-end mt-6">

                  {EmpTabLock.passportVisaInfoIsLock ? 
                    <Button onClick={()=>{HandleLockUnlock('Passport','Unlock')}} variant="ghost" colorScheme="purple">Unlock</Button>
                  : <>
                    <Button onClick={()=>{HandleLockUnlock('Passport','Lock')}} variant="ghost" colorScheme="purple">Lock</Button>
                    <Button variant="outline" colorScheme="purple">Reset</Button>
                    <Button type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </>}

                </div>

                </form>
            </TabPanel>

            {/* Qualifications Details Tab */}
            <TabPanel>
              <form onSubmit={SaveEmpQualificationDetails}>


             <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Award size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Qualification Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Highest Qualification<span className='text-red-500 font-semibold'>*</span></label>
                    <Select isRequired value={HighestQualification} onChange={({target})=>{setHighestQualification(target.value)}} borderColor="gray" placeholder="Select Qualification" >
                      <option value='Post Graduation'>Post Graduation</option>
                      <option value='Graduation'>Graduation</option>
                    </Select>

                  </div>

                  <div className="space-y-2">
                    <label>University</label>
                    <Input  value={University||''} onChange={({target})=>{setUniversity(target.value)}} borderColor="gray" placeholder="University" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Institute<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={Institute||''} onChange={({target})=>{setInstitute(target.value)}} borderColor="gray" placeholder="Institute" />
                  </div>

                  <div className="space-y-2">
                    <label>Year of Passing<span className='text-red-500 font-semibold'>*</span></label>
                    <DatePicker
                      className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                      selected={YearOfPassing}
                      onChange={(date) => setYearOfPassing(date)}
                      placeholderText="Year of Passing"
                       dateFormat='yyyy'
                       showYearPicker
                       required
                    />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
              
                <div className="space-y-2">
                    <label>Status<span className='text-red-500 font-semibold'>*</span></label>
                    <Select isRequired value={QualificationStatus} onChange={({target})=>{setQualificationStatus(target.value)}} borderColor="gray" placeholder="Status" >
                      <option value='Complete'>Complete</option>
                      <option value='Pursuing'>Pursuing</option>
                    </Select>
                  </div>
              
                <div className="space-y-2">
                    <label>City</label>
                    <Input  value={QualificationCity||''} onChange={({target})=>{setQualificationCity(target.value)}} borderColor="gray" placeholder="City" />
                  </div>


                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Country</label>
                    <Select  value={QualificationCountry} onChange={({target})=>{setQualificationCountry(target.value); getStateList(target.value)}} borderColor="gray" placeholder="Select Country" >
                    {CountryList.map((data,i)=>{
                      return <option key={i} value={data.countryId} >{data.countryName}</option>
                     })}
                    </Select>

                  </div>

                  <div className="space-y-2">
                    <label>State</label>
                    <Select  value={QualificationState} onChange={({target})=>{setQualificationState(target.value)}} borderColor="gray" placeholder="Select State" >
                    {StateList.map((data,i)=>{
                        return  <option value={data.stateId}>{data.stateName}</option>

                      })}
                    </Select>
                  </div>
                </div>




                <div className="flex gap-6 justify-end mt-6">

                  {EmpTabLock.qualificationInfoIsLock ? 
                    <Button onClick={()=>{HandleLockUnlock('Qualification','Unlock')}} variant="ghost" colorScheme="purple">Unlock</Button>
                  : <>
                    <Button onClick={()=>{HandleLockUnlock('Qualification','Lock')}} variant="ghost" colorScheme="purple">Lock</Button>
                    <Button variant="outline" colorScheme="purple">Reset</Button>
                    <Button type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </>}

                </div>

                </form>
            </TabPanel>

            {/* Previous Job Details Tab */}
            <TabPanel>
              <form onSubmit={SaveEmpPreviousJobDetails}>



             <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Briefcase size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Previous Job Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Start Date<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PrevJobStartDate} onChange={({target})=>{setPrevJobStartDate(target.value)}} type='date' borderColor="gray" placeholder="Year of Passing" />
                  </div>

                  <div className="space-y-2">
                    <label>End Date<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PrevJobEndDate} onChange={({target})=>{setPrevJobEndDate(target.value)}} type='date' borderColor="gray" placeholder="Year of Passing" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Annual CTC<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired type="number" value={AnnualCTC||''} onChange={({target})=>{setAnnualCTC(target.value)}} borderColor="gray" placeholder="Annual CTC" />
                  </div>

                  <div className="space-y-2">
                    <label>Job Title<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={JobTitle||''} onChange={({target})=>{setJobTitle(target.value)}} borderColor="gray" placeholder="Job Title" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className="space-y-2">
                    <label>Job Description</label>
                    <Input value={PrevJobDescription||''} onChange={({target})=>{setPrevJobDescription(target.value)}} borderColor="gray" placeholder="Job Description" />
                  </div>

                  <div className="space-y-2">
                    <label>Company<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={PrevJobCompany||''} onChange={({target})=>{setPrevJobCompany(target.value)}} borderColor="gray" placeholder="Company" />
                  </div>
                </div>

                <div className="flex gap-6 justify-end mt-6">

                  {EmpTabLock.previousJobInfoIsLock ? 
                    <Button onClick={()=>{HandleLockUnlock('PrevJob','Unlock')}} variant="ghost" colorScheme="purple">Unlock</Button>
                  : <>
                    <Button onClick={()=>{HandleLockUnlock('PrevJob','Lock')}} variant="ghost" colorScheme="purple">Lock</Button>
                    <Button variant="outline" colorScheme="purple">Reset</Button>
                    <Button type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </>}

                </div>

                </form>

            </TabPanel>

            {/* Document Details Tab */}
            <TabPanel>
              <form onSubmit={SaveEmpDocumentsDetails}>

            <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <File size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Document Details
                </h1>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Document Type<span className='text-red-500 font-semibold'>*</span></label>
                    {/* <InputGroup size='md'> */}
                         <Select isRequired value={DocumentTypeName} onChange={({target})=>{setDocumentTypeName(target.value)}} borderColor='gray' placeholder='Please Select a Document'>
                           {DocumentMasterList.map((data,i)=>{
                             return <option key={i} value={data.documentId}>{data.documentTypeName}</option>
                           })}
                         </Select>
                         {/* <Tooltip placement='top' rounded='5px' hasArrow label='Add Document' bg='gray.700' color='white'>
                         <InputRightAddon onClick={onAddDocumemntTypeOpen} p={0} bgcolor='purple' children={ <Button colorScheme='purple' children={<Plus size='16px' /> } />} />
                         </Tooltip> 
                       </InputGroup> */}
                  </div>

                  <div className="space-y-2">
                    <label>Document Number<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={DocumentNumber||''} onChange={({target})=>{setDocumentNumber(target.value)}} borderColor="gray" placeholder="Document Number" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                <div className="space-y-2">
                    <div className="flex justify-between">
                      <label>Valid Upto (DD/MM/YYYY)</label>
                      <Checkbox isChecked={IsValidityRequired} onChange={({target})=>{
                         setIsValidityRequired(target.checked);
                         if(target.checked  === false || IsValidityRequired === false){
                           setDocumentValidUpto(moment().format('YYYY-MM-DD'));
                         }
                        }}>Is Validity Required</Checkbox>
                    </div>
                    <Input isDisabled={IsValidityRequired === true ? false : true} type='date' value={DocumentValidUpto||''} onChange={({target})=>{setDocumentValidUpto(target.value)}}  borderColor="gray" placeholder="Valid Upto" />
                  </div>

                  <div className="space-y-2">
                    <label>Attach Document<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired onChange={({target})=>{setAttachDocument(target.files[0])}} type='file' borderColor="gray" placeholder="Attach Document" />
                  </div>
                </div>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">

                  <div className="space-y-2">
                    <label>Remarks</label>
                    <Input  value={DocumentRemarks||''} onChange={({target})=>{setDocumentRemarks(target.value)}} borderColor="gray" placeholder="Remarks" />
                  </div>
                </div>

                {Number(ActivePlanDetails.currentDataUsage || 0) <= Number(ActivePlanDetails.dataUsageUpto || 0) ? 
                <>
                 <div className="flex gap-6 justify-end mt-6">
                  {EmpTabLock.employeeDocsInfoIsLock ? 
                    <Button onClick={()=>{HandleLockUnlock('EmpDoc','Unlock')}} variant="ghost" colorScheme="purple">Unlock</Button>
                  : <>
                    <Button onClick={()=>{HandleLockUnlock('EmpDoc','Lock')}} variant="ghost" colorScheme="purple">Lock</Button>
                    <Button variant="outline" colorScheme="purple">Reset</Button>
                    <Button type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </>}
                 </div>
                </>
               : 
               <div className="flex justify-end">
                 <h6 onClick={()=>{navigate('/plantransactions')}} className="text-white cursor-pointer bg-red-500  text-sm py-1 px-1.5 rounded-lg font-medium">Data Limit Exceeded. Please Upgrade your Plan!! <FontAwesomeIcon icon={faUpRightFromSquare} className=' ml-1' /></h6>
               </div>
              }

                </form>

                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <File size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Documents
                </h1>
              </div>

                <div className="ag-theme-alpine">
                  <AgGridReact
                    style={gridStyle}
                    domLayout={"autoHeight"}
                    ref={gridRef} // Ref for accessing Grid's API
                    rowData={EmpDocumentList} // Row Data for Rows
                    columnDefs={DocumentColumnDefs} // Column Defs for Columns
                    defaultColDef={defaultColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    pagination={true}
                    paginationPageSize={10}
                    paginationNumberFormatter={paginationNumberFormatter}
                  />
                </div>

            </TabPanel>

            {/* Bank Details Tab */}
            <TabPanel>

              <form onSubmit={SaveEmpBankDetails}>

            
            <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
             <FontAwesomeIcon icon={faBuilding} className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Bank Details
                </h1>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-6">
                  <div className='space-y-2'>
                    <label>Bank Name<span className='text-red-500 font-semibold'>*</span></label>
                    <Select isRequired value={BankName||''} onChange={({target})=>{setBankName(target.value)}} borderColor={'gray'} placeholder="Select Bank">
                      {BankMasterList.map((data,i)=>{
                        return <option key={i} value={data.bankId}>{data.bankName}</option>
                      })}
                    </Select>
                  </div>

                  {userData.countryName === "India"?<>


                  <div className="space-y-2">
                    <label>Branch Name<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired  value={BankBranchName||''} onChange={({target})=>{setBankBranchName(target.value)}} borderColor="gray" placeholder="Branch Name" />
                  </div>

                  <div className="space-y-2">
                    <label>IFSC Code<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired  value={IfscIban||''} onChange={({target})=>{setIfscIban(target.value)}} borderColor="gray" placeholder="IFSC Code" />
                  </div>

                  <div className='space-y-2'>
                    <label>Account Number<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired type={'number'} value={AccountNumber||''} onChange={({target})=>{setAccountNumber(target.value)}} borderColor="gray" placeholder="Account Number" />
                  </div>
                  </> : <>

                  <div className='space-y-2'>
                    <label>IBAN Number<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={IBANNumber||''} onChange={({target})=>{setIBANNumber(target.value)}} borderColor="gray" placeholder="IBAN Number" />
                  </div>


                  <div className='space-y-2'>
                    <label>Routing/Swift Code<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={RoutingSwiftCode||''} onChange={({target})=>{setRoutingSwiftCode(target.value)}} borderColor="gray" placeholder="Routing/Swift Number" />
                  </div>

                  <div className="space-y-2">
                    <label>Personal Id</label>
                    <Input  value={PersonalId||''} onChange={({target})=>{setPersonalId(target.value)}} borderColor="gray" placeholder="Personal Id" />
                  </div>
                  </>}
                </div>

                <div className="flex gap-6 justify-end mt-6">

                  {EmpTabLock.bankInfoIsLock ? 
                    <Button onClick={()=>{HandleLockUnlock('BankInfo','Unlock')}} variant="ghost" colorScheme="purple">Unlock</Button>
                  : <>
                    <Button onClick={()=>{HandleLockUnlock('BankInfo','Lock')}} variant="ghost" colorScheme="purple">Lock</Button>
                    <Button variant="outline" colorScheme="purple">Reset</Button>
                    <Button type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </>}

                </div>
                </form>

            </TabPanel>

             {/* Family Details Tab */}
            <TabPanel>

              <form onSubmit={SaveEmpFamilyDetails}>


            <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <Users size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                  Family Details
                </h1>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Member Name<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={MemberName||''} onChange={({target})=>{setMemberName(target.value)}} borderColor="gray" placeholder="Member Name" />
                  </div>

                  <div className="space-y-2">
                    <label>Relation<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired value={MemberRelation||''} onChange={({target})=>{setMemberRelation(target.value)}} borderColor="gray" placeholder="Relation" />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Age<span className='text-red-500 font-semibold'>*</span></label>
                    <Input isRequired type={'number'} value={MemberAge||''} onChange={({target})=>{setMemberAge(target.value)}} borderColor="gray" placeholder="Enter Age" />
                  </div>

                  <div className="space-y-2">
                    <label>Gender<span className='text-red-500 font-semibold'>*</span></label>
                    <Select isRequired value={MemberGender} onChange={({target})=>{setMemberGender(target.value)}} borderColor='gray' placeholder="Select Gender">
                    <option value="Male">Male</option>
                    <option value="Female">Female</option>
                    </Select>
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Emirates ID</label>
                    <Input  value={MemberEmiratesId||''} onChange={({target})=>{setMemberEmiratesId(target.value)}} borderColor="gray" placeholder="Emirates ID" />
                  </div>

                  <div className="space-y-2">
                    <label>Visa Details</label>
                    <Input  value={MemberVisaDetails||''} onChange={({target})=>{setMemberVisaDetails(target.value)}} borderColor="gray" placeholder="Visa Details" />
                  </div>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-8">
                  <div className='space-y-2'>
                    <label>Passport Details</label>
                    <Input  value={MemberPassportDetails||''} onChange={({target})=>{setMemberPassportDetails(target.value)}} borderColor="gray" placeholder="Passport Details" />
                  </div>

                </div>
                <div className="flex gap-6 justify-end mt-6">

                  {EmpTabLock.familyInfoIsLock ? 
                    <Button onClick={()=>{HandleLockUnlock('FamilyInfo','Unlock')}} variant="ghost" colorScheme="purple">Unlock</Button>
                  : <>
                    <Button onClick={()=>{HandleLockUnlock('FamilyInfo','Lock')}} variant="ghost" colorScheme="purple">Lock</Button>
                    <Button variant="outline" colorScheme="purple">Reset</Button>
                    <Button type="submit" variant="solid" colorScheme="purple">Submit</Button> 
                  </>}

                </div>
                
                </form>
                <div className="border-b border-indigo-400 pb-2 mb-4 items-center flex">
                <File size="18px" className=" text-indigo-600 mr-2 " />
                <h1 className="text-lg font-normal text-indigo-600">
                Family Member Details
                </h1>
              </div>

                <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={familyrowData} // Row Data for Rows
          columnDefs={familycolumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}

        />
      </div>

            </TabPanel>

           {userData.countryName === "India"?<>
            {/* Compliance Details Tab */}
            <TabPanel>

              <form onSubmit={SaveComplianceDetails}>



              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4 md:gap-6">

<div className="space-y-2">
    <label>EPF Number<span className='text-red-500 font-semibold'>*</span></label>
    <Input isRequired value={EpfNumber} onChange={({target})=>{setEpfNumber(target.value)}} borderColor={"gray.400"} placeholder="EPF Number" />
  </div>

  <div className="space-y-2">
<label>UAN Number<span className='text-red-500 font-semibold'>*</span></label>
<Input isRequired value={UanNumber} onChange={({target})=>{setUanNumber(target.value)}} borderColor={"gray.400"} placeholder="UAN Number" onKeyDown={(event)=>{
      if (event.target.value.length < 11) {
        setUANError('UAN number should not be less than 12 digits!');
      } else {
        setUANError(null);
      }
  }}/>
{UANError && <span className='text-red-500 text-sm'>{UANError}</span>}
</div>

  <div className="space-y-2">
    <label>ESI Number</label>
    <Input value={EsiNumber} onChange={({target})=>{setEsiNumber(target.value)}} borderColor={"gray.400"} placeholder="ESI Number" />
  </div>

</div>

<div className="flex gap-6 justify-end mt-6">

{EmpTabLock.complianceInfoIsLock ? 
  <Button onClick={()=>{HandleLockUnlock('Compliance','Unlock')}} variant="ghost" colorScheme="purple">Unlock</Button>
: <>
  <Button onClick={()=>{HandleLockUnlock('Compliance','Lock')}} variant="ghost" colorScheme="purple">Lock</Button>
  <Button variant="outline" colorScheme="purple">Reset</Button>
  <Button type="submit" variant="solid" colorScheme="purple">Submit</Button> 
</>}

</div>

</form>
                

            </TabPanel> </>: ""}

            {/* Login Details Tab */}
            <TabPanel>
              <div className="mx-aut">

              <form onSubmit={HandleResetPassword}>

<div className=" space-y-4 mx-auto w-1/3">

  <div className="text-right space-x-2 items-center">
    <span>Employee Login Enable</span>
    <Switch isChecked={LoginActive} 
    onChange={async({target})=>{
      setLoginActive(target.checked);
      let body = {
        empGuId: EmployeeGuid,
        userName: UserName,
        password: '',
        confirmPassword: '',
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
        isActive: target.checked
      }
      console.log("Login Active Body",body);

      const response = await EmpLoginStatusPost(body);
      if(response){
        getEmpGeneralInfo()
      }
    }} colorScheme={'green'} />
  </div>

  {LoginActive &&

  <>
  <div className="space-y-2">
    <label>Username</label>
    <Input value={UserName || ''} onChange={({target})=>{setUserName(target.value)}} isReadOnly borderColor={"gray.400"} placeholder="Username" />
  </div>

  <div className="space-y-2">
    <label>New Password</label>
    <Input type={'password'}  value={NewPassword || ''} onChange={({target})=>{setNewPassword(target.value)}}  isRequired borderColor={"gray.400"} placeholder="New Password" />
  </div>

  <div className="space-y-2">
    <label>Confirm Password</label>
    <Input type={'password'}  value={ConfirmPassword || ''} onChange={({target})=>{setConfirmPassword(target.value)}}  isRequired borderColor={"gray.400"} placeholder="Confirm Password" />
  </div>

  <div>
    <Button type="submit" className="w-full" colorScheme={"purple"}>
      Submit
    </Button>
  </div>
  </>}


</div>

</form>
                

              </div>
            </TabPanel>

          </TabPanels>
        </Tabs>
      </div>



      <Modal
                isOpen={isAddDesignationOpen}
                onClose={onAddDesignationClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Add Designation</ModalHeader>
                  <ModalCloseButton />
                  <hr />
                  <form onSubmit={SaveDesignationMaster}>
                    <ModalBody>
                      <div className="space-y-2">
                        <label>Designation Name</label>
                        <Input
                          isRequired
                          value={DesignationName}
                          onChange={({target})=>{setDesignationName(target.value)}}
                          borderColor="gray"
                          placeholder="Enter Designation Name"
                        />
                      </div>
                    </ModalBody>
                    <hr />
                    <ModalFooter>
                      <div className="space-x-4">
                        <Button
                          type="submit"
                          colorScheme="purple"
                          variant="solid"
                        >
                          Save
                        </Button>
                        <Button
                          variant="outline"
                          colorScheme="purple"
                          mr={3}
                          onClick={onAddDesignationClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </ModalFooter>
                  </form>
                </ModalContent>
              </Modal>

              <Modal
                isOpen={isAddDepartmentOpen}
                onClose={onAddDepartmentClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Add Department</ModalHeader>
                  <ModalCloseButton />
                  <hr />
                  <form onSubmit={SaveDepartmentMaster}>
                    <ModalBody>
                      <div className="space-y-2">
                        <label>Department Name</label>
                        <Input
                          isRequired
                          value={DepartmentName}
                          onChange={({target})=>{setDepartmentName(target.value)}}
                          borderColor="gray"
                          placeholder="Enter Department Name"
                        />
                      </div>
                    </ModalBody>
                    <hr />
                    <ModalFooter>
                      <div className="space-x-4">
                        <Button
                          type="submit"
                          colorScheme="purple"
                          variant="solid"
                        >
                          Save
                        </Button>
                        <Button
                          variant="outline"
                          colorScheme="purple"
                          mr={3}
                          onClick={onAddDepartmentClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </ModalFooter>
                  </form>
                </ModalContent>
              </Modal>

              <Modal
                size='lg'
                isOpen={isAddEmpCategoryOpen}
                onClose={onAddEmpCategoryClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Add Employee Category</ModalHeader>
                  <ModalCloseButton />
                  <hr />
                  <form onSubmit={SaveEmpTypeName}>
                    <ModalBody>
                      <div className="space-y-4">


                    <div className="space-y-2">
                    <label>Category Name</label>
                    <Input
                      isRequired
                      value={EmpTypeName || ""}
                      onChange={({ target }) => {
                        setEmpTypeName(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Category Name"
                    />
                  </div>



                  <div className="space-y-2  mb-4">
                    <div className="flex justify-between">
                    <label>Probation Month</label>
                    <Checkbox
                    isChecked={IsProbationApply}
                    onChange={({ target }) => {
                      setIsProbationApply(target.checked);
                      if (target.checked === false) {
                        setProbationMonth("");
                      }
                    }}
                    borderColor="gray"
                  >
                    is Probation Apply?
                  </Checkbox>
                    </div>
                    <Select
                      isDisabled={IsProbationApply === false ? true : false}
                      value={ProbationMonth || ""}
                      onChange={({ target }) => {
                        setProbationMonth(target.value);
                      }}
                      borderColor="gray"
                      placeholder="Select Month"
                    >
                      <option value="1">1 Month</option>
                      <option value="2">2 Month</option>
                      <option value="3">3 Month</option>
                    </Select>
                  </div>
                  </div>
                    </ModalBody>
                    <hr />
                    <ModalFooter>
                      <div className="space-x-4">
                        <Button
                          type="submit"
                          colorScheme="purple"
                          variant="solid"
                        >
                          Save
                        </Button>
                        <Button
                          variant="outline"
                          colorScheme="purple"
                          mr={3}
                          onClick={onAddEmpCategoryClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </ModalFooter>
                  </form>
                </ModalContent>
              </Modal>


              <Modal
                isOpen={isAddDocumemntTypeOpen}
                onClose={onAddDocumemntTypeClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Add Document Name</ModalHeader>
                  <ModalCloseButton />
                  <hr />
                  <form onSubmit={SaveDocumentMaster}>
                    <ModalBody>
                      <div className="space-y-2">
                        <label>Document Name</label>
                        <Input
                          isRequired
                          value={DocumentName}
                          onChange={({target})=>{setDocumentName(target.value)}}
                          borderColor="gray"
                          placeholder="Enter Document Type Name"
                        />
                      </div>
                    </ModalBody>
                    <hr />
                    <ModalFooter>
                      <div className="space-x-4">
                        <Button
                          type="submit"
                          colorScheme="purple"
                          variant="solid"
                        >
                          Save
                        </Button>
                        <Button
                          variant="outline"
                          colorScheme="purple"
                          mr={3}
                          onClick={onAddDocumemntTypeClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </ModalFooter>
                  </form>
                </ModalContent>
              </Modal>
              
              <Modal
                isOpen={isAddGradeDivisionOpen}
                onClose={onAddGradeDivisionClose}
              >
                <ModalOverlay />
                <ModalContent rounded={'3xl'}>
                  <ModalHeader textColor={'gray.800'} fontSize='lg' backgroundColor={'purple.200'} roundedTop={'3xl'} roundedBottom={'xl'} fontWeight='bold'>Add Grade & Division</ModalHeader>
                  <ModalCloseButton mt={1.5}/>
                  <form onSubmit={async(e)=>{
                    e.preventDefault();

                    console.log("Grade Div Body",GradeDivisionDetails);                    
                    const response = await GradeDivPost(GradeDivisionDetails);

                    if(response){
                      GetGradeList();
                      GetEmpGradeInfo();
                      onAddGradeDivisionClose();
                      setGradeDivisionDetails({
                        empGuid: EmployeeGuid,
                        empCode: EmpGeneralInfo.empCode,
                        division: '',
                        grade: '',
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch,
                        isActive: true,
                      });
                    }

                  }}>
                    <ModalBody className="space-y-2">
                      <div className="space-y-2">
                        <label>Grade</label>
                        <Input
                          value={GradeDivisionDetails.grade || ''}
                          onChange={({target})=>{
                            setGradeDivisionDetails((prev)=>{
                              return {
                                ...prev,
                                grade: target.value
                              }
                            })
                          }}
                          borderColor="gray"
                          placeholder="Enter Grade"
                        />
                      </div>
                      <div className="space-y-2">
                        <label>Division</label>
                        <Input
                          value={GradeDivisionDetails.division || ''}
                          onChange={({target})=>{
                            setGradeDivisionDetails((prev)=>{
                              return {
                                ...prev,
                                division: target.value
                              }
                            })
                          }}
                          borderColor="gray"
                          placeholder="Enter Division"
                        />
                      </div>
                    </ModalBody>
                    <hr />
                    <ModalFooter>
                      <div className="space-x-4">
                        <Button
                          isLoading={GradeDivLoading}
                          loadingText="Saving..."
                          type="submit"
                          colorScheme="purple"
                          variant="solid"
                        >
                          Save
                        </Button>
                        <Button
                          variant="outline"
                          colorScheme="purple"
                          mr={3}
                          onClick={onAddGradeDivisionClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </ModalFooter>
                  </form>
                </ModalContent>
              </Modal>
    </div>
  );
}

export default EmployeeDetails;
