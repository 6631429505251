import {Select } from "@chakra-ui/react";
import React, { useState ,useEffect} from "react";
import Notifications from "./Notifications";
import UserMenu from "./UserMenu";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import HeadSettings from "./HeadSettings";
import HelpDesk from "./HelpDesk";
import { useAuth } from "../Utils/AuthContext";

function Header({ sidebarOpen, setSidebarOpen }) {
  const [branch,setBranch] = useState([]);

  const {userData} = useAuth();

  // console.log("Company Id",userData.companyId);
  // console.log("Branch Id", userData.isActiveBranch);
  const [ActiveBranch, setActiveBranch] = useState();
  const [MenuList, setMenuList] = useState([]);

  useEffect(()=>{
    const getBranchList = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/Branch/GetBranchList?CompanyId=${userData.companyId}`)
        // console.log( "Branch Data",response.data);
        let res = response.data;
        setBranch(res.filter(data => data.isActive === true));
        setActiveBranch(userData.isActiveBranch);
      } catch (error) {
        console.error(error);
      }
    }
    getBranchList();
  },[userData])

  useEffect(() => {
    HandleSelectedRole();
  }, [])
  

  const HandleBranchChange = async (e)=>{
   setActiveBranch(e.target.value);
  //  console.log(e.target.value);

   let body = {
    userid:userData.userId,
    branchId:e.target.value,
    userName: userData.userName
   }

  //  console.log(body);

   await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Branch/UpdateActiveBranch`,body,{
    headers: {
      "Content-Type": "application/json",
    },
    params: {
      userid:userData.userId,
      branchId:e.target.value,
      userName: userData.userName

    },
  }
)
.then((response) => {
  // console.log("Branch Updated Successfully",response);

  //navigate('/settings/salarycomponents');
})
.catch((error) => {
  console.error(error.response);
});


let body1 = {
  userName : userData.userName,
  password : userData.password
}

await axios.post(
  `${process.env.REACT_APP_ACQ_URL}/api/Account/VerifyUserLogin`,body1
)
.then((response) => {
  // console.log(response);
  const json =  response.data;

  console.log("json",json);
  if (json === 'Failed') {
    // redirect
    alert("Login Again");
  }else{
    // console.log("Success");
    // console.log(json);
    localStorage.removeItem('user');
    localStorage.removeItem('token');

    localStorage.setItem('user', JSON.stringify(json));
    localStorage.setItem('token', json.token);

     window.location.reload(true);
    //navigate('/dashboard');
  }


})
.catch((error) => {
  console.error(error);

  // if (!toast.isActive(id)) {
  //   toast({
  //     id,
  //     title: "ERROR",
  //     description: `Network Error`,
  //     position: "top",
  //     status: "error",
  //     duration: 2000,
  //     isClosable: true,
  //   });
  // }
});



  }


  const HandleSelectedRole = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${userData.roleId || 0}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      // console.log("Menu List ", response);
      setMenuList(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const FoundSettingMenu = MenuList.filter(
    (data) => data.mainMenuName === "Setting"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );


  return (
    <header className="sticky top-2 mx-2 rounded-2xl bg-slate-800 border-b border-slate-200 z-30">
      <div className="px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between h-14 -mb-px">
          {/* Header: Left side */}
          <div className="flex">
            {/* Hamburger button */}
            <button
              className="text-white mr-3 hover:text-slate-600 lg:hidden"
              aria-controls="sidebar"
              aria-expanded={sidebarOpen}
              onClick={(e) => {
                e.stopPropagation();
                setSidebarOpen(!sidebarOpen);
              }}
            >
              <span className="sr-only">Open sidebar</span>
              <svg
                className="w-6 h-6 fill-current"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <rect x="4" y="5" width="16" height="2" />
                <rect x="4" y="11" width="16" height="2" />
                <rect x="4" y="17" width="16" height="2" />
              </svg>
            </button>

            {userData.role === "Admin" ?
                          <div className="mr-4">
                          <Select onChange={HandleBranchChange} value={ActiveBranch}  bg='white' size='sm' borderRadius={8}>
                   {branch.map((data,i)=>(
                      <option key={i} value={data.branchId}>{data.branchName}</option>
                   ))}
                  </Select>
                          </div> :
                          <>
             
            {MenuList.filter((data)=>data.mainMenuName === "Branch").map(({isCreate,isModify,isView})=>{

              if(isCreate || isModify === true ){
                return               <div className="mr-4">
                <Select onChange={HandleBranchChange} value={ActiveBranch}  bg='white' size='sm' borderRadius={8}>
         {branch.map((data,i)=>(
            <option key={i} value={data.branchId}>{data.branchName}</option>
         ))}
        </Select>
                </div>
              }

              if(isView === true){
                return <div className="my-auto mr-4">
                {branch.map((data,i)=>{
                  if(data.branchId === ActiveBranch) {
                  return  <h6 key={i} ><FontAwesomeIcon icon={faLocationDot} className="text-white mr-2" /><span className='font-semibold text-gray-50'>{data.branchName}</span> </h6>
                  }
                })}
  
              </div>

              }
            })

            }
            </>
          }

            <div className="my-auto">
             <h6 ><FontAwesomeIcon icon={faBuilding} className="text-white mr-2" /><span className='font-semibold text-gray-50'>{userData.companyName}</span> </h6>
            </div>
          </div>

          {/* Header: Right side */}
          <div className="flex items-center">
             {userData.role === "Admin" ? 
             <HelpDesk/> : ''}
             <Notifications />
             {FoundSettingMenu || userData.role === "Admin" ? 
             <HeadSettings /> : ''}
            <hr className="w-px h-6 bg-slate-200 mx-3" />
            <UserMenu />
          </div>
        </div>
      </div>
    </header>
  );
}

export default Header;
