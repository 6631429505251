import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionPanel,
    AccordionItem,
    Input,
    Button,
    Switch,useToast, Select
  } from "@chakra-ui/react";
import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";

function ProjectMaster() {

        // Getting Logged in user data from Local Storage
        const userData = JSON.parse(localStorage.getItem('user'));

        // Toastr Variables
        const toast = useToast();
        const id = "toast";

        const [ProjectId, setProjectId] = useState(0);
        const [ProjectName, setProjectName] = useState();
        const [ProjectLocation, setProjectLocation] = useState();
        const [PrjStartDate, setPrjStartDate] = useState();
        const [PrjEndDate, setPrjEndDate] = useState();
        const [TeamSize, setTeamSize] = useState();

        const gridRef = useRef(); // Table Reference Variable
        const [rowData, setRowData] = useState([]); // Table Data Variable
        const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
        const [columnDefs, setColumnDefs] = useState([
            {
              headerName: "Project Name",
              field: "shiftProjectName",
            },
            {
              headerName: "Location",
              field: "projectLocation",
            },
            {
              headerName: "Start Date",
              field: "projectStartDate",
              cellRenderer:(params)=><span>{moment(params.data.projectStartDate).format('DD/MM/YYYY')}</span>
            },
            {
              headerName: "End Date",
              field: "projectEndDate",
              cellRenderer:(params)=><span>{moment(params.data.projectEndDate).format('DD/MM/YYYY')}</span>
            },
            {
              headerName: "Team Size",
              field: "teamSize",
            },
            {
              headerName: "IsActive",
              field: "isActive",
              cellRenderer:(params) => <Switch colorScheme='green' isChecked={params.data.isActive} onChange={({target}) => {HandleProjectSwitch(target.checked, params.data)}} size='md' />
            },
            {
              headerName: "Action",
              cellRenderer:(params) => <Button 
               onClick={()=>{
                 let ProjectRowData = params.data;

                 setProjectId(ProjectRowData.shiftPojectID)
                 setProjectName(ProjectRowData.shiftProjectName)
                 setProjectLocation(ProjectRowData.projectLocation)
                 setPrjStartDate(new Date(ProjectRowData.projectStartDate))
                 setPrjEndDate(new Date(ProjectRowData.projectEndDate))
                 setTeamSize(ProjectRowData.teamSize)
               }}
               leftIcon={<FontAwesomeIcon icon={faEdit} />} size={'xs'} colorScheme={'blue'} >Edit</Button>
            }
          ]);
      

          const HandleProjectSwitch = async(Verdict,ProjectData)=>{

            setRowData(prev => {
              const newState = prev.map((obj)=>{
                if (obj.shiftPojectID === ProjectData.shiftPojectID) {
                  return {...obj, isActive: Verdict};
                }
                return obj;
                
              })
              return newState
    
            })


            let body={
                shiftPojectID : ProjectData.shiftPojectID,
                srNo : ProjectData.srNo,
                shiftProjectName : ProjectData.shiftProjectName,
                projectLocation : ProjectData.projectLocation,
                projectStartDate : ProjectData.projectStartDate,
                projectEndDate : ProjectData.projectEndDate,
                teamSize : ProjectData.teamSize,
                isActive : ProjectData.isActive,
                companyId : userData.companyId,
                branchId : userData.isActiveBranch
              }
          console.log("Project Switch Body",body);


          await axios.post(
            `${process.env.REACT_APP_ACQ_URL}/api/Shift/SaveShiftProjectMasterData`,body
          )
          .then((response) => {
            console.log(response);
            const res = response.data;
            if (!toast.isActive(id)) {
              toast({
                id,
                title:res,
                position: "top",
                status: "success",
                duration: 2000,
                isClosable: true,
              });
            }
      
            // Again Calling API to fetch Updated Data 
            GetProjectMasterList();
        
          })
          .catch((error) => {
            console.error(error);
        
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: `Project Not Updated`,
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });


          }

          // Table Pagination
          const paginationNumberFormatter = useCallback((params) => {
            return "[" + params.value.toLocaleString() + "]";
          }, []);
        
          //Table columns properties applies to all columns
          const defaultColDef = useMemo(() => ({
            flex: 1,
            sortable: true,
            filter: "agTextColumnFilter",
            floatingFilter: true,
            cacheQuickFilter: true,
            cellClass: 'no-border',
            enableCellTextSelection: true,
            resizable: true
          }));


         useEffect(()=>{
           GetProjectMasterList();
         },[])


          const GetProjectMasterList = async () => {
            try {
              const response =  await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/Shift/GetshiftProjectMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
              )
              console.log("Project List Response",response);
              setRowData(response.data);
            } catch (error) {
              console.error(error);
            }
          }



          const SaveProjectMaster = async(e) => {
            e.preventDefault();

            let body={
                shiftPojectID: ProjectId,
                shiftProjectName : ProjectName,
                projectLocation : ProjectLocation,
                projectStartDate : moment(PrjStartDate).format('YYYY-MM-DD'),
                projectEndDate : moment(PrjEndDate).format('YYYY-MM-DD'),
                teamSize : TeamSize,
                isActive : true,
                companyId : userData.companyId,
                branchId : userData.isActiveBranch
              }
            console.log("Save Project Body",body);


            await axios.post(
              `${process.env.REACT_APP_ACQ_URL}/api/Shift/SaveShiftProjectMasterData`,body
            )
            .then((response) => {
              console.log(response);
              const res = response.data;
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title:res,
                  position: "top",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              }
        
              // Again Calling API to fetch Updated Data 
              GetProjectMasterList();
              setProjectId(0);
              setProjectName('');
              setProjectLocation('');
              setPrjStartDate();
              setPrjEndDate();
              setTeamSize('');
          
            })
            .catch((error) => {
              console.error(error);
          
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: "ERROR",
                  description: `Project Not Added`,
                  position: "top",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                });
              }
            });
        

          }
    

  return (
    <div>
      <div className="w-full border-b border-gray-400 mb-3 pb-3 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">Project Master</h1>
      </div>

      <div className="mb-6">
        <Accordion defaultIndex={[0]} shadow='md' border="transparent" rounded="xl" allowMultiple bg="white">
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Project
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
                <form onSubmit={SaveProjectMaster}>
                 <div className="grid grid-cols-5 gap-4">
                      <div className="space-y-2">
                        <label>Project Name</label>
                        <Input  isRequired value={ProjectName || ''} onChange={({target})=>{setProjectName(target.value)}}  borderColor='gray' placeholder="Project Name" />
                      </div>
                      <div className="space-y-2">
                        <label>Location</label>
                        <Input  value={ProjectLocation || ''} onChange={({target})=>{setProjectLocation(target.value)}} borderColor='gray' placeholder="Location" />
                      </div>
                      <div className="space-y-2">
                      <label>Project Start Date</label>
                      <DatePicker
                        className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                        selected={PrjStartDate}
                        onChange={(date) => setPrjStartDate(date)}
                        placeholderText="Start Date"
                        dateFormat={'dd-MM-yyyy'}
                      />
                    </div>
                    <div className="space-y-2">
                      <label>Project End Date</label>
                      <DatePicker
                        className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                        selected={PrjEndDate}
                        onChange={(date) => setPrjEndDate(date)}
                        placeholderText="End Date"
                        dateFormat={'dd-MM-yyyy'}
                      />
                    </div>
                    <div className="space-y-2">
                      <label>Team Size</label>
                      <Input value={TeamSize || ''} onChange={({target})=>{setTeamSize(target.value)}} isRequired type={'number'} borderColor='gray' placeholder="Team Size" />
                    </div>
                  </div>
                  <div className="space-x-4 text-right mt-4">
                    <Button onClick={()=>{
                        setProjectId(0);
                        setProjectName('');
                        setProjectLocation('');
                        setPrjStartDate();
                        setPrjEndDate();
                        setTeamSize('');
                    }} variant={'outline'} colorScheme={'purple'}>Reset</Button>
                    <Button type="submit" colorScheme={'purple'}>Submit</Button>
                  </div>
                </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>


      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          // enableCellTextSelection={true}
        />
      </div>

    </div>
  )
}

export default ProjectMaster