import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import {
  Checkbox,
  Button,
  useToast,
} from "@chakra-ui/react";
import { Link, useNavigate, useParams } from "react-router-dom";

function AddEmpSalaryComponents() {

    const navigate = useNavigate();
    const toast = useToast();
    const id = "toast";
    const userId = useParams();
    // console.log("User Id",userId);
    const guID = userId.employee;
    // console.log("GUID", guID);
  
    const userData = JSON.parse(localStorage.getItem("user"));
  
    const gridRef = useRef();
  
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  
    const [MasterData, setMasterData] = useState();
    const [EmployeeStructure, setEmployeeStructure] = useState([]);
    const [EmpSelectedRows, setEmpSelectedRows] = useState([]);
    const [empdata, setEmpData] = useState([]);
  
    const [Employerpf, setEmployerpf] = useState(false);
    const [Employeresi, setEmployeresi] = useState(false);
    const [PfCap, setPfCap] = useState(false);
    const [AdditionalPF, setAdditionalPF] = useState(false);
    const [IsHRAapply, setIsHRAapply] = useState(false);
    const [PfAccountNumber, setPfAccountNumber] = useState();
    const [UANNumber, setUANNumber] = useState();
    const [pfeps, setpfeps] = useState();
    const [EsiNumber, setEsiNumber] = useState();
    const [HRAActive, setHRAActive] = useState(false);

  
    useEffect(() => {
      getEmp();
      getMasterStructure();
      getEmpStructure();
      getPfESISetting();
      GetEmpHraSettings();
    }, []);
  
    const getEmp = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeDetailByEmpGuid?EmpGuid=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
         console.log(response);
        const Emp = response.data;
       // console.log(Emp);
        setEmpData(Emp);
      } catch (error) {
        console.error(error);
      }
    };
  
    const getPfESISetting = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetEmployeePFESICSettingEmpguid?EmpGuid=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        // console.log(response);
        const Emp = response.data;
        console.log("Employee ESI PF Setting Details",Emp);
        setEmployerpf(response.data.employeePF);
        setEmployeresi(response.data.employyeESIC);
        setPfCap(response.data.employeeMaxCap);
        setAdditionalPF(response.data.employeeAdditionalPF);
      } catch (error) {
        console.error(error);
      }
    };

    
    const GetEmpHraSettings = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetEmployeeHRASettings?EmpGuId=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`)
        console.log("Emp HRA Applicable",response);
        setIsHRAapply(response.data.hraApplied)
      } catch (error) {
        console.error(error);
      }
    }
  
    const getMasterStructure = async () => {
      await axios
        .get(
          `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/ModifyBranchStructureByEmployee?EmpGuid=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )
        .then((response) => {
         console.log("Master Row data", response.data);
          setMasterData(response.data);
        })
        .catch((error) => {
          console.error(error);
        });
    };
  
    const getEmpStructure = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalary/GetEmpSalaryDataByEmpGuid?EmpGuid=${guID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
         console.log("Emp Structure Response", response.data);
        // setEmployeeStructure(response.data);
        // console.log("Employee component List",EmployeeStructure);
        // setEmployerpf(response.data.isEPFApply);
        // console.log("Pf APply", Employerpf, "api", response.data.isEPFApply);
        // setEmployeresi(response.data.isESIApply);
        // console.log("Esi APply", Employeresi);
        setPfAccountNumber(response.data.empEPFNo);
        setUANNumber(response.data.empEPFUANNo);
        setpfeps(response.data.isEmpEPSContribution);
        setEsiNumber(response.data.empESINo);
      } catch (error) {
        console.error(error);
      }
    };
  
    console.log("Employee Selected Rows", EmpSelectedRows);
  
    const onSelectionChanged = useCallback(() => {
      var BranchData = gridRef.current.api.getSelectedRows();
      setEmpSelectedRows(BranchData);
     console.log(BranchData);  
     const isFound = BranchData.some(element => {
      if (element.componentId === 2) {
        return true;
      }
      return false;
    });
  
    if (isFound) {
      setHRAActive(false);
    }else{
      setHRAActive(true);
      setIsHRAapply(false);
    }

    }, []);
  
    const defaultColDef = useMemo(() => {
      return {
        flex: 1,
        sortable: true,
        filter: true,
      };
    }, []);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    const [McolumnDefs, setMColumnDefs] = useState([
      // {
      //   headerName: "Structure Id",
      //   field: "branchSalaryStructureMasterId",
      //   headerCheckboxSelection: true,
      //   checkboxSelection: (params) => {
      //     return params.data.branchSalaryStructureMasterId;
      //   },
      // },
      { 
        headerName: "Salary Label", 
        field: "displayLabel",
        headerCheckboxSelection: true,
        checkboxSelection:true
      },
      { headerName: "Salary Type", field: "componentTypeName" },
      // { headerName: "Component Id", field: "componentId" },
      //   { headerName: "Salary Slip Label", field: "salarySlipLabel" },
    ]);
  
    const onFirstDataRendered = useCallback((params) => {
      gridRef.current.api.forEachNode((node) =>
        node.setSelected(node.data.isEmpComponent === true)
      );
    }, []);
  
  
    const handleSaveStructure = async () => {
      // let selectedRows = EmpSelectedRows.map((object) => {
      //     return {
      //         componentId: object.componentId,
      //         componentType: object.componentType
      //     }
      //   });
      //   console.log("SelectedRows", selectedRows);
  
      let body = {
        // employeeSalaryMasterId: 1,
        empGuid: guID,
        empCode: empdata.empCode,
        // isEPFApply: Employerpf,
        // empEPFNo: PfAccountNumber,
        // empEPFUANNo: UANNumber,
        // isEmpEPSContribution: pfeps,
        // isESIApply: Employeresi,
        // empESINo: EsiNumber,
        companyId: empdata.companyId,
        branchId: empdata.branchId,
        linkupDataList: EmpSelectedRows.map((object) => {
          return {
            empGuid: guID,
            empCode: empdata.empCode,
            branchSalaryStructureMasterId: object.branchSalaryStructureMasterId,
          };
        }),
      };
      console.log("EMployee Body", body);
      await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/SaveEmployeeSalaryStructure`,
          body,
          {
            headers:{
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const res = response.data;
          //  console.log("res",res);
          if (res === "Success") {
            console.log("E & D Structure added Successfully", response);
            // navigate("/salary/dashboard");
            // if (!toast.isActive(id)) {
            //   toast({
            //     id,
            //     title: "Employee Structure created.",
            //     description: `${empdata.firstName} ${empdata.lastName} Structure Created`,
            //     position: "top",
            //     status: "success",
            //     duration: 2000,
            //     isClosable: true,
            //   });
            // }
          } else {
            console.log("E & D Structure Not added",response);
  
            // if (!toast.isActive(id)) {
            //   toast({
            //     id,
            //     title: "ERROR",
            //     description: `${empdata.firstName} ${empdata.lastName} Structure not created`,
            //     position: "top",
            //     status: "error",
            //     duration: 2000,
            //     isClosable: true,
            //   });
            // }
          }
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);
        });


        let body3 = {
            empGuId : guID,
            empCode : empdata.empCode,
            employeeHRA : IsHRAapply,
            countryId : 0,
            companyId : userData.companyId,
            branchId : userData.isActiveBranch,
          }

          console.log("HRA Save Body",body3);


          await axios.post(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/SaveEmployeeHRASettings`,body3
          ).then((response)=>{
            console.log("HRA SAVE RESPONSE",response);

          }).catch((error)=>{
            console.log("HRA SAVE ERROR",error);
          })
       
  



        let body2 = {
            empGuid:guID,
            empCode:empdata.empCode,
            employeePF:Employerpf,
            employyeESIC:Employeresi,
            isLock : true,
            employeeMaxCap : PfCap,
            companyId : userData.companyId,
            branchId : userData.isActiveBranch,
            employeeAdditionalPF : AdditionalPF,
            countryId : Number(userData.countryId || 0)
          }
  
        console.log("Emp PF ESI Setting body", body2);
  
  
            await axios
        .post(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/SaveEmployeePFESICSetting`,
          body2,
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then((response) => {
          const res = response.data;
          //  console.log("res",res);

          navigate("/salarydashboard");
          if (!toast.isActive(id)) {
            toast({
              id,
              title: res,
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
  
         })
        .catch((error) => {
          console.log(error.response);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: `${empdata.firstName} ${empdata.lastName} Structure not created`,
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });
  
  
    };


  return (
    <div>
      <div className="flex justify-between w-full border-b border-gray-400 mb-3 pb-3 text-left">
        <h1 className="text-gray-700 text-xl font-bold ">
          Employee Salary Structure
        </h1>
      </div>

      <div className="bg-white p-4 my-4 rounded-3xl drop-shadow-lg">
        <div className="grid grid-cols-4">
          <div className="space-y-2">
            <h6 className="text-lg font-semibold">
              Employee Name:- &nbsp;
              <span className="font-normal">
                {empdata.firstName} {empdata.lastName}
              </span>
            </h6>

            <h6 className="text-lg font-semibold">
              Employee Type:- &nbsp;
              <span className="font-normal">{empdata.empTypeName}</span>
            </h6>
          </div>

          <div className="space-y-2">
            <h6 className="text-lg font-semibold">
              Employee Code:- &nbsp;
              <span className="font-normal">{empdata.empCode}</span>
            </h6>
            <h6 className="text-lg font-semibold">
              Company:- &nbsp;
              <span className="font-normal">{userData.companyName}</span>
            </h6>
          </div>

          <div className="space-y-2">
            <h6 className="text-lg font-semibold">
              Designation:- &nbsp;
              <span className="font-normal">{empdata.designationName}</span>
            </h6>
            <h6 className="text-lg font-semibold">
              Branch:- &nbsp;
              <span className="font-normal">{userData.activeBranchName}</span>
            </h6>
          </div>

          <div className="space-y-2">
            <h6 className="text-lg font-semibold">
              Department:- &nbsp;
              <span className="font-normal">{empdata.departmentName}</span>
            </h6>
          </div>
        </div>
      </div>

      <div className="grid grid-cols-2 gap-8 ">
        <div>
          <div className="flex justify-between w-full border-b border-gray-400 my-3 pb-3 text-left">
            <h1 className="text-gray-700 text-2xl font-bold ">
              Branch Salary Structure
            </h1>
          </div>

          <div className="ag-theme-alpine">
            <AgGridReact
              style={gridStyle}
              ref={gridRef}
              rowData={MasterData}
              columnDefs={McolumnDefs}
              defaultColDef={defaultColDef}
              animateRows={true}
              pagination={true}
              domLayout={"autoHeight"}
              onSelectionChanged={onSelectionChanged}
              onFirstDataRendered={onFirstDataRendered}
              rowSelection={"multiple"}
              paginationPageSize={20}
              paginationNumberFormatter={paginationNumberFormatter}
            />
          </div>
        </div>

        <div className="">
          <div className="flex justify-between w-full border-b border-gray-400 my-3 pb-3 text-left">
            <h1 className="text-gray-700 text-2xl font-bold ">
              Statutory Compliance
            </h1>
          </div>

          <p className="mb-2 text-sm">
            Enable the necessary benefits and rules for this employee
          </p>

          <div className="bg-white p-4 rounded-xl  drop-shadow-md">
          {userData.countryName === "India" ? <div> 
          <div className="space-y-4 mb-4">
              <Checkbox
                borderColor="gray.500"
                isChecked={Employerpf}
                onChange={(e) => {
                  setEmployerpf(e.target.checked);
                  // if (Employerpf === false) {
                  //   setPfAccountNumber("");
                  //   setUANNumber("");
                  //   setpfeps(false);
                  // }
                  if (Employerpf === false) {
                    setPfCap(false);
                    setAdditionalPF(false);
                  }
                }}
              >
                PF
              </Checkbox>

              {Employerpf &&(
                <div className="pl-8 flex flex-col space-y-3">
                  <Checkbox isChecked={PfCap} onChange={(e)=>{setPfCap(e.target.checked)}} borderColor="gray.500">PF Cap ₹ 15000</Checkbox>
                  <Checkbox isChecked={AdditionalPF} onChange={(e)=>{setAdditionalPF(e.target.checked)}} borderColor="gray.500">Employee Additional PF</Checkbox>
                </div>)}


            </div>

            <div className="space-y-4 mb-4">
              <Checkbox
                borderColor="gray.500"
                isChecked={Employeresi}
                onChange={(e) => {
                  setEmployeresi(e.target.checked);
                  // if (Employeresi === false) {
                  //   setEsiNumber("");
                  // }
                }}
              >
                ESI
              </Checkbox>

            </div>
          </div> : ""}

            <div>
             <Checkbox isChecked={IsHRAapply} 
             isDisabled={HRAActive}
             onChange={({target})=>{
              setIsHRAapply(target.checked)
              // if (IsHRAapply === true) {

              // }
             }} borderColor="gray.500">
                HRA
              </Checkbox>
            </div>
          </div>
        </div>
      </div>

      <div className="my-4 text-right space-x-4">
        <Button onClick={handleSaveStructure} colorScheme="purple">
          Save Structure
        </Button>

        <Link to="/salarydashboard">
          <Button variant="outline" colorScheme="purple">
            Cancel
          </Button>
        </Link>
      </div>

    </div>
  )
}

export default AddEmpSalaryComponents