import { Button,useDisclosure } from '@chakra-ui/react';
import {Modal,useToast,ModalBody,ModalCloseButton,ModalContent,ModalFooter,ModalHeader,ModalOverlay } from '@chakra-ui/react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import React,{useState,useEffect} from 'react'
import * as XLSX from 'xlsx';
import { CSVLink, CSVDownload } from "react-csv";
import axios from 'axios';
import moment from 'moment';
import DatePicker from "react-datepicker";

const ImportEmpNAttendance = () => {

    const userData = JSON.parse(localStorage.getItem("user"));
    const toast = useToast();
    const id = 'toast';



    const {
        isOpen: isImportEmployeesOpen,
        onOpen: onImportEmployeesOpen,
        onClose: onImportEmployeesClose,
      } = useDisclosure();

      const {
        isOpen: isImportAttendanceOpen,
        onOpen: onImportAttendanceOpen,
        onClose: onImportAttendanceClose,
      } = useDisclosure();

      const {
        isOpen: isImportEmpLinkupDataOpen,
        onOpen: onImportEmpLinkupDataOpen,
        onClose: onImportEmpLinkupDataClose,
      } = useDisclosure();

      const {
        isOpen: isImportLeavesDataOpen,
        onOpen: onImportLeavesDataOpen,
        onClose: onImportLeavesDataClose,
      } = useDisclosure();


      const {
        isOpen: isImportAttendanceCountOpen,
        onOpen: onImportAttendanceCountOpen,
        onClose: onImportAttendanceCountClose,
      } = useDisclosure();


      const [ImportedAttendanceCountData, setImportedAttendanceCountData] = useState([]);

      const [EmployeesImportData, setEmployeesImportData] = useState([]);
      const [AttendanceImportData, setAttendanceImportData] = useState([]);
      const [EmpLinkupImportData, setEmpLinkupImportData] = useState([]);
      const [EmpListForLinkup, setEmpListForLinkup] = useState([]);
      const [ImportedLeavesData, setImportedLeavesData] = useState([]);
      const [LeaveExcelHeads, setLeaveExcelHeads] = useState([])
      const [AttendanceMonthSelected, setAttendanceMonthSelected] = useState(new Date());
      const [ImportTotalAttendanceEmpData, setImportTotalAttendanceEmpData] = useState([]);

      useEffect(() => {
        getEmpListForLinkup();
        getEmpList();
      }, [])
      

      const getEmpListForLinkup = async () => {
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetEmployeeListForAttendanceLinkup?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
        //  console.log("EmpList for Link Up",response);
          setEmpListForLinkup(response.data);
          
        } catch (error) {
          console.error(error);
        }
      }


      const getEmpList = async () => {
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
           console.log("Employee List ",response.data);
    
          let res = response.data;

          setImportTotalAttendanceEmpData(res.map((data)=>{
            return {
              empGuId : data.guId,
              empName : data.fullName,
              empCode : data.empCode,
              totalPresent : 0,
              totalLeaveBalance : 0,
              payrollMonth : new Date(AttendanceMonthSelected).getMonth()+1,
              payrollYear : new Date(AttendanceMonthSelected).getFullYear(),
            }       
             }));
          const CompletedStructure = res.filter((data)=>data.isSalaryStructureCompleted === 2);
          // console.log("Pending Structures" , PendingStructure);
          setLeaveExcelHeads(CompletedStructure);
        } catch (error) {
            console.error(error);
        }
      }

      const [AttExcelHeads] = useState([
        { label: "device_EmpID", key: `device_EmpID`},
        { label: "attendanceDate", key: `attendanceDate`},
        { label: "attendanceTime", key: `attendanceTime`},
        { label: "att_datetime", key: `att_datetime`},
        { label: "device", key: `device`},
        { label: "deviceno", key: `deviceno`},
        { label: "cardno", key: `cardno`},
        { label: "attendanceStatus", key: `attendanceStatus`},
      ])

      const [EmpLinkupExcelHeads] = useState([
        { label: "empGuId", key: `empGuId`},
        { label: "empName", key: `empName`},
        { label: "device_EmpID", key: `device_EmpID`},
      ])

      const readExcel = (file)=>{

        const promise = new Promise((resolve,reject) => {
          
         const fileReader = new FileReader();
         fileReader.readAsArrayBuffer(file);
     
         fileReader.onload=(e)=>{
           const bufferArray = e.target.result;
     
           const wb = XLSX.read(bufferArray,{type:'buffer'});
           const wsname = wb.SheetNames[0];
     
           const ws = wb.Sheets[wsname];
     
           const data = XLSX.utils.sheet_to_json(ws, {raw: false});
     
           resolve(data)
         } 
     
         fileReader.onerror = (error) => {
           reject(error)
         }
     
        });
     
        promise.then((data)=>{
          console.log("Imported Data",data);
    
          let FinalData = data.map((obj)=>{
            return {...obj,
              companyId:userData.companyId,
              branchId:userData.isActiveBranch
            }
           })

           console.log("Final Data",FinalData);
         setEmployeesImportData(FinalData);
         handleImportEmployeesData(FinalData);
        });
     
     }


     const handleImportEmployeesData = async (FinalData) =>{
        
    console.log(FinalData);
      
    let body = FinalData.map(obj =>{
     return {
      ...obj,
      empCode : String(obj.empCode),
      firstName : String(obj.firstName),
      lastName : String(obj.lastName),
      dateofjoin : moment(obj.dateofjoin).format('YYYY-MM-DD'),
      designation : String(obj.designation),
      department : String(obj.department),
      reportingOfficerName : String(obj.reportingOfficerName),
      mobile : String(obj.mobile),
      officeEmail : String(obj.officeEmail),
      regulationDate : moment(obj.regulationDate).format('YYYY-MM-DD'),
      officeLocation : String(obj.officeLocation),
      probationType : String(obj.probationType),
      empTypeName : String(obj.empTypeName)
     }
    })
    

     console.log(body)

        await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ImportEmployeeGeneralInfo`,body
        )
        .then((response) => {
          const res = response.data;
          console.log("res",res);
      
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
      
      
      
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);
      
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Employees Data not Imported.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
          
        });
        
      }


     const AttReadExcel = (file)=>{

        const promise = new Promise((resolve,reject) => {
          
         const fileReader = new FileReader();
         fileReader.readAsArrayBuffer(file);
     
         fileReader.onload=(e)=>{
           const bufferArray = e.target.result;
     
           const wb = XLSX.read(bufferArray,{type:'buffer'});
           const wsname = wb.SheetNames[0];
     
           const ws = wb.Sheets[wsname];
     
           const data = XLSX.utils.sheet_to_json(ws, {raw: false});
     
           resolve(data)
         } 
     
         fileReader.onerror = (error) => {
           reject(error)
         }
     
        });
     
        promise.then((data)=>{
          console.log("Attendance Imported Data",data);
    
          let FinalData = data.map((obj)=>{
            return {...obj,
              companyId:userData.companyId,
              branchId:userData.isActiveBranch
            }
           })

           console.log("Attendance Final Data",FinalData);
         setAttendanceImportData(FinalData);
         handleImportAttendanceData(FinalData);
        });
     
     }


     const EmpLinkupReadExcel = (file)=>{

      const promise = new Promise((resolve,reject) => {
        
       const fileReader = new FileReader();
       fileReader.readAsArrayBuffer(file);
   
       fileReader.onload=(e)=>{
         const bufferArray = e.target.result;
   
         const wb = XLSX.read(bufferArray,{type:'buffer'});
         const wsname = wb.SheetNames[0];
   
         const ws = wb.Sheets[wsname];
   
         const data = XLSX.utils.sheet_to_json(ws, {raw: false});
   
         resolve(data)
       } 
   
       fileReader.onerror = (error) => {
         reject(error)
       }
   
      });
   
      promise.then((data)=>{
        console.log("Emp Link Up Imported Data",data);
  
        let FinalData = data.map((obj)=>{
          return {...obj,
            companyId:userData.companyId,
            branchId:userData.isActiveBranch
          }
         })

         console.log("Emp Link Up Final Data",FinalData);
         setEmpLinkupImportData(FinalData);
      //  handleImportAttendanceData(FinalData);
          handleEmpLinkupImportData(FinalData);
      });
   
      
   }


   const handleEmpLinkupImportData = async (FinalData) =>{

    console.log(FinalData);
  
   let body = FinalData.map(obj =>{
    return {...obj,
      device_EmpID:String(obj.device_EmpID)
    }
   })
   

   
    console.log(body)
    
    await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Attendance/ImportEmpAttendanceLinkup`,FinalData
    )
    .then((response) => {
      console.log(response);
      const res = response.data;
      console.log("res",res);
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: 'top',
          status: 'info',
          duration: 2000,
          isClosable: true,
        })
      }
  
  
  
    })
    .catch((error) => {
      console.log(error.response);
      console.log(error.response.data);
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title: 'ERROR',
          description: "Emp Link Up Data not Imported.",
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    });
    
  }


     const handleImportAttendanceData = async (FinalData) =>{

        console.log(FinalData);
      
       let body = FinalData.map(obj =>{
        return {...obj,
          empLinkupId:String(obj.empLinkupId),
          empName:String(obj.empName),
          empCode:String(obj.empCode),
          attendanceDate:String(obj.attendanceDate),
          attendanceTime:String(obj.attendanceTime),
          lattitude:String(obj.lattitude),
          longitude:String(obj.longitude),
          attendanceStatus:String(obj.attendanceStatus),
          imagePath:String(obj.imagePath),
          officeLocation:String(obj.officeLocation),
          remark:String(obj.remark)
        }
       })
       
    
       
        console.log(body)
        
        await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Attendance/ImportAttendance`,body
        )
        .then((response) => {
          console.log(response);
          const res = response.data;
          console.log("res",res);
      
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }
      
      
      
        })
        .catch((error) => {
          console.log(error.response);
          console.log(error.response.data);
      
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Attendance Data not Imported.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });
        
      }



      const [LeaveHeads, setLeaveHeads] = useState([
        { label: "guId", key: `guId`},
        { label: "fullName", key: `fullName`},
        { label: "empCode", key: `empCode`},
        { label: "leaveType", key: `leaveType`},
        { label: "leaveFromDate", key: `leaveFromDate`},
        { label: "leaveToDate", key: `leaveToDate`},
      ])

      
     const ImportedLeavesReadExcel = (file)=>{

      const promise = new Promise((resolve,reject) => {
        
       const fileReader = new FileReader();
       fileReader.readAsArrayBuffer(file);
   
       fileReader.onload=(e)=>{
         const bufferArray = e.target.result;
   
         const wb = XLSX.read(bufferArray,{type:'buffer'});
         const wsname = wb.SheetNames[0];
   
         const ws = wb.Sheets[wsname];
   
         const data = XLSX.utils.sheet_to_json(ws, {raw: false});
   
         resolve(data)
       } 
   
       fileReader.onerror = (error) => {
         reject(error)
       }
   
      });
   
      promise.then((data)=>{
        console.log("Leaves Imported Data",data);
  
        let FinalData = data.map((obj)=>{
          return {...obj,
            companyId:userData.companyId,
            branchId:userData.isActiveBranch
          }
         })

         console.log("Leaves Final Data",FinalData);
         setImportedLeavesData(FinalData);
         HandleImportLeavesData(FinalData);

        });
   }


     const HandleImportLeavesData = async (FinalData) =>{

      console.log("LEave Final Data",FinalData);
      
      let body = FinalData.map((obj)=>{
          return {...obj,
            empGuid:obj.guId,
           leaveFromDate:moment(obj.leaveFromDate).format('YYYY-MM-DD'),
           leaveToDate:moment(obj.leaveToDate).format('YYYY-MM-DD')
          }
         })
      
       console.log("Import LEave Body Data",body);
       
       await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Leave/ImportLeaveApply`,body
       )
       .then((response) => {
         console.log(response);
         const res = response.data;
         console.log("res",res);
     
         if (!toast.isActive(id)) {
           toast({
             id,
             title:res,
             position: 'top',
             status: 'info',
             duration: 2000,
             isClosable: true,
           })
         }
     
     
     
       })
       .catch((error) => {
         console.error(error);
         console.log(error.response.data);
     
         if (!toast.isActive(id)) {
           toast({
             id,
             title: 'ERROR',
             description: "Leave Data not Imported.",
             position: 'top',
             status: 'error',
             duration: 2000,
             isClosable: true,
           })
         }
       });

     } 


     const [AttendanceCountHeads, setAttendanceCountHeads] = useState([
      { label: "empGuId", key: `empGuId`},
      { label: "empName", key: `empName`},
      { label: "empCode", key: `empCode`},
      { label: "totalPresent", key: `totalPresent`},
      { label: "totalLeaveBalance", key: `totalLeaveBalance`},
      { label: "payrollMonth", key: `payrollMonth`},
      { label: "payrollYear", key: `payrollYear`},
     ])


     const ImportedAttendanceCountReadExcel = (file)=>{

      const promise = new Promise((resolve,reject) => {
        
       const fileReader = new FileReader();
       fileReader.readAsArrayBuffer(file);
   
       fileReader.onload=(e)=>{
         const bufferArray = e.target.result;
   
         const wb = XLSX.read(bufferArray,{type:'buffer'});
         const wsname = wb.SheetNames[0];
   
         const ws = wb.Sheets[wsname];
   
         const data = XLSX.utils.sheet_to_json(ws, {raw: false});
   
         resolve(data)
       } 
   
       fileReader.onerror = (error) => {
         reject(error)
       }
   
      });
   
      promise.then((data)=>{
        console.log("Attendance Count Imported Data",data);
  
        let FinalData = data.map((obj)=>{
          return {...obj,
            companyId:userData.companyId,
            branchId:userData.isActiveBranch
          }
         })

         console.log("Attendance Count Final Data",FinalData);
         setImportedAttendanceCountData(FinalData);
         HandleTotalAttendanceCount(FinalData)
        });
   }



   const HandleTotalAttendanceCount = async (FinalData) =>{

    console.log("Total Attendance Final Data",FinalData);

    let body = FinalData.map((data)=>{
      return {
        ...data,
        totalLeaveBalance : Number(data.totalLeaveBalance),
        totalPresent : Number(data.totalPresent),               
        payrollMonth : Number(data.payrollMonth),
        payrollYear :  Number(data.payrollYear),
      }
    })
    
    console.log("Total Attendance Final Body",body);

     
     await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Attendance/ImportClientPayrollAttendance`,body
     )
     .then((response) => {
       console.log("Total Attendance Save response",response);
       const res = response.data;
       console.log("res",res);
   
       if (!toast.isActive(id)) {
         toast({
           id,
           title:"Imported",
           position: 'top',
           status: 'info',
           duration: 2000,
           isClosable: true,
         })
       }
   
   
   
     })
     .catch((error) => {
       console.error(error);
       console.log(error.response.data);
   
       if (!toast.isActive(id)) {
         toast({
           id,
           title: 'ERROR',
           description: "Total Attendance not Imported.",
           position: 'top',
           status: 'error',
           duration: 2000,
           isClosable: true,
         })
       }
     });

   } 

  return (
    <div >
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Import Data</h1>
      </div>

      <div className='flex gap-8'>
        <Button onClick={onImportEmpLinkupDataOpen} colorScheme='purple'>Import Emp LinkUp</Button>
        <Button onClick={onImportAttendanceOpen} colorScheme='purple'>Import Attendance</Button>
        <Button onClick={onImportLeavesDataOpen} colorScheme='purple'>Import Leaves</Button>
        <Button onClick={onImportAttendanceCountOpen} colorScheme='purple'>Import Month Total Attendance</Button>
      </div>

      <Modal size='full' isOpen={isImportEmployeesOpen} onClose={()=>{
        setEmployeesImportData([]);
        onImportEmployeesClose();
      }}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Employees</ModalHeader>
          <ModalCloseButton />
          <hr />
            <ModalBody>
              <div className='mt-4'>
              <CSVLink data={LeaveExcelHeads} headers={LeaveExcelHeads} filename={"Import Employees.csv"}>
                <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Employees</Button>
              </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                readExcel(file);
              }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(EmployeesImportData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setEmployeesImportData([]);
                    onImportEmployeesClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>



      <Modal size='full' isOpen={isImportAttendanceOpen} onClose={onImportAttendanceClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Attendance</ModalHeader>
          <ModalCloseButton onClick={()=>{setAttendanceImportData([])}} />
          <hr />
            <ModalBody>
              <div className='mt-4'>
              <CSVLink data={AttExcelHeads} headers={AttExcelHeads} filename={"Employees Attendance.csv"}>
            <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Attendance</Button>
          </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
            onChange={(e)=>{
              const file = e.target.files[0];
              AttReadExcel(file);
            }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(AttendanceImportData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setAttendanceImportData([]);
                    onImportAttendanceClose()}}
                >
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal size='full' isOpen={isImportEmpLinkupDataOpen} onClose={onImportEmpLinkupDataClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Employee Link Up Data</ModalHeader>
          <ModalCloseButton onClick={()=>{setEmpLinkupImportData([])}} />
          <hr />
            <ModalBody>
              <div className='mt-4'>
               <CSVLink data={EmpListForLinkup} headers={EmpLinkupExcelHeads} filename={"Employees Link Up.csv"}>
                 <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Linkup</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                EmpLinkupReadExcel(file);
              }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(EmpLinkupImportData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setEmpLinkupImportData([]);
                    onImportEmpLinkupDataClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal size='full' isOpen={isImportLeavesDataOpen} onClose={onImportLeavesDataClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Employee Link Up Data</ModalHeader>
          <ModalCloseButton onClick={()=>{setImportedLeavesData([])}} />
          <hr />
            <ModalBody>
              <div className='mt-4'>
               <CSVLink data={LeaveExcelHeads} headers={LeaveHeads} filename={"Import Emp Leaves.csv"}>
                 <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Leave Import</Button>
               </CSVLink>
              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                ImportedLeavesReadExcel(file);
              }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(ImportedLeavesData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
                <Button
                  variant="outline"
                  colorScheme="purple"
                  onClick={() => {
                    setImportedLeavesData([]);
                    onImportLeavesDataClose();
                  }}>
                  Close
                </Button>
            </ModalFooter>
        </ModalContent>
      </Modal>


      <Modal size='full' isOpen={isImportAttendanceCountOpen} onClose={onImportAttendanceCountClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Import Total Attendance </ModalHeader>
          <ModalCloseButton onClick={()=>{setImportedAttendanceCountData([])}} />
          <hr />
            <ModalBody>
              <div className='flex items-end gap-5 mt-4'>
                <div>
                  <label>Select Month</label>
                  <div>
                  <DatePicker
                    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block  px-2 py-1.5   outline-none"
                    selected={AttendanceMonthSelected}
                    onChange={(date) => {
                      setAttendanceMonthSelected(date);
                      let ExcelData = ImportTotalAttendanceEmpData.map((data)=>{
                        return {...data,
                          payrollMonth : new Date(date).getMonth()+1,
                          payrollYear : new Date(date).getFullYear()
                            }
                      });
                      setImportTotalAttendanceEmpData(ExcelData);
                    }}
                    dateFormat="MM/yyyy"
                    showMonthYearPicker
                    placeholderText="Select Month"
                  />
                  </div>


                </div>
                <div>
                <CSVLink data={ImportTotalAttendanceEmpData} headers={AttendanceCountHeads} filename={"Import Total Month Attendance.csv"}>
                 <Button size='sm' colorScheme='purple'><FontAwesomeIcon icon={faDownload} className="mr-2"/>Download Sample Excel for Total Import</Button>
               </CSVLink>
                </div>

              </div>
            <div className="flex justify-center items-center w-full my-10">
          <label htmlFor="dropzone-file" className="flex flex-col justify-center items-center w-full h-64  rounded-3xl border-2  border-dashed cursor-pointer dark:hover:bg-bray-800 bg-gray-700  border-gray-600 hover:border-gray-500 hover:bg-gray-600">
              <div className="flex flex-col justify-center items-center pt-5 pb-6">
                  <svg aria-hidden="true" className="mb-3 w-10 h-10 text-gray-400" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 16a4 4 0 01-.88-7.903A5 5 0 1115.9 6L16 6a5 5 0 011 9.9M15 13l-3-3m0 0l-3 3m3-3v12"></path></svg>
                  <p className="mb-2 text-sm text-gray-500 dark:text-gray-400"><span className="font-semibold">Click to upload</span> or drag and drop</p>
                  <p className="text-xs text-gray-500 dark:text-gray-400">XLSV, CSV</p>
              </div>
              <input 
              onChange={(e) => {
                const file = e.target.files[0];
                ImportedAttendanceCountReadExcel(file);
              }} 
              id="dropzone-file" type="file" className="hidden" />
          </label>
      </div> 

      <div>
        {JSON.stringify(ImportedAttendanceCountData)}
      </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <div className="space-x-4">

                <Button
                  variant="outline"
                  colorScheme="purple"
                  mr={3}
                  onClick={()=>{
                    setImportedAttendanceCountData([]);
                    onImportAttendanceCountClose();
                  }
                 }>
                  Close
                </Button>
              </div>
            </ModalFooter>
        </ModalContent>
      </Modal>


    </div>
  )
}

export default ImportEmpNAttendance