import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { Button, useToast,Center,Spinner } from "@chakra-ui/react";
import moment from "moment";
import axios from "axios";
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";


function ShiftDuty() {
  const userData = JSON.parse(localStorage.getItem('user'));
  const toast = useToast();
  const id = "toast";

  const [AttendanceDate, setAttendanceDate] = useState(new Date());
  const [IsLoading, setIsLoading] = useState(false);

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling


  const [rowData, setRowData] = useState([]); // Table Data Variable
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode"
    },
    {
      headerName: "Emp Name",
      field: "empName"
    },
    {
      headerName: "Project Name",
      field: "projectName"
    },
    {
      headerName: "Shift Name",
      field: "shiftName"
    },
    {
      headerName: "Log In",
      children: [
        {
          headerName: "Date",
          field: "logInDate",
          cellRenderer: (params) => (
            <span>{moment(params.data.logInDate).format("DD/MM/YYYY")}</span>
          )
        },
        {
          headerName: "In Time",
          field: "logIn_Time"
        },
      ],
    },
    {
      headerName: "Log Out",
      children: [
        {
          headerName: "Date",
          field: "logOutDate",
          cellRenderer: (params) => (
            <span>{moment(params.data.logOutDate).format("DD/MM/YYYY")}</span>
          )
        },
        {
          headerName: "Out Time",
          field: "logOut_Time"
        },
      ],
    },
    {
      headerName: "Total Hours",
      field: "total_Hour",
      cellRenderer:(params)=><span>{moment(params.data.total_Hour,'HH:mm:ss').format('H')+" hrs "+moment(params.data.total_Hour,'HH:mm:ss').format('m')+" mins"}</span>
    },
    {
      headerName: "Status",
      field: "shiftDuty_Status",
      cellRenderer: (params) =>
        params.data.shiftDuty_Status === "Done" ? (
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
           Done
          </span>
        ) : (
          <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
           {params.data.shiftDuty_Status}
          </span>
        )
    },
    {
      headerName: "Action",
      field: "designationName",
    }
  ]);

  
  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  // Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    // flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: 'no-border',
    enableCellTextSelection:true,
    resizable: true
  }));

  useEffect(()=>{
    GetShiftAttendance(new Date())
  },[])



  const GetShiftAttendance = async (DATE) => {
    setIsLoading(true);
    try {
      let body = {
        logIn_Date : moment(DATE).format('YYYY-MM-DD'),
        logOut_Date : moment(DATE).format('YYYY-MM-DD'),
        companyId : userData.companyId,
        branchId : userData.isActiveBranch
      }

      console.log("Shift Att Body",body);
      const response = await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Shift/GetShiftAttendanceListbyDate`,body)
      console.log("Shift Att Response",response);
      setRowData(response.data.success)
    } catch (error) {
      console.error(error);
    }finally{
      setIsLoading(false);
    }
  }

  return (
    <div>
      <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-gray-700 text-lg font-bold ">Shift Duty</h1>
        <div className="flex items-center gap-4">
          <label className="font-semibold text-lg mr-3">
            Attendance Date :
          </label>
          <div>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2   outline-none"
              selected={AttendanceDate}
              onChange={(date) => {
                setAttendanceDate(date);
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText="Select Attendance Date"
              maxDate={new Date()}
            />
          </div>
          <div>
            <Button
              colorScheme="purple"
              leftIcon={<FontAwesomeIcon icon={faMagnifyingGlass} />}
              onClick={()=>{GetShiftAttendance(AttendanceDate);}}
            >
              Search
            </Button>
          </div>
        </div>
      </div>

      {IsLoading ? 
       <Center>
         <Spinner thickness='4px' color={'purple.500 '}  size='lg'  />
       </Center> :
       <div className="ag-theme-alpine">
         <AgGridReact
           style={gridStyle}
           domLayout={"autoHeight"}
           ref={gridRef} // Ref for accessing Grid's API
           rowData={rowData} // Row Data for Rows
           columnDefs={columnDefs} // Column Defs for Columns
           defaultColDef={defaultColDef} // Default Column Properties
           animateRows={true} // Optional - set to 'true' to have rows animate when sorted
           pagination={true}
           paginationPageSize={10}
           paginationNumberFormatter={paginationNumberFormatter}
         />
       </div> }

    </div>
  )
}

export default ShiftDuty