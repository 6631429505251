import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState, useEffect } from "react";
import { QRCodeSVG } from "qrcode.react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHandPointRight } from "@fortawesome/free-solid-svg-icons";

const QRScanner = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [QrValue, setQrValue] = useState("");

  const navigate = useNavigate();

  const [isLoggedIn, setisLoggedIn] = useState(false);

  useEffect(() => {
    let intervalId;

    if (isLoggedIn) {
      intervalId = setInterval(HandleQRLogin, 1000);
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [isLoggedIn]);

  const HandleQRScanner = async () => {
    const random = Math.floor(Math.random() * 9000) + 1000;
    setQrValue(random);
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/SaveLoginQRCode?QRCodeGenerated=${random}`
      );
      // console.log("Save QR Code",response);
      if (response?.data) {
        onOpen();
        // HandleQRLogin(random)
        setisLoggedIn(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const HandleQRLogin = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/VerifyQRLogin?QRCodeGenerated=${QrValue}`
      );
      // console.log("QR Login Response",response);

      const res = response.data;

      if (res !== "Failed") {
        onClose();
        // alert("Logged In Successfully");
        setisLoggedIn(true);

        localStorage.removeItem("user");
        localStorage.setItem("user", JSON.stringify(res));
        localStorage.setItem("loggedInEmail", res.userName);
        localStorage.setItem("token", res.token);

        if (res.companyId === 0) {
          navigate("/createcompany");
        } else {
          if (res.isActiveBranch === 0) {
            navigate("/createbranch");
          } else {
            if (!res.isPasswordReset) {
              navigate("/changepassword");
            } else {
              navigate("/dashboard");
            }
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div>
      <div>
        <Button
          color={"gray.50"}
          className=" hover:text-blue-600"
          py={0}
          onClick={HandleQRScanner}
          variant={"link"}
        >
          Login with QR Code
        </Button>
      </div>

      <Modal size={"xs"} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >
            Login with QR
          </ModalHeader>
          <ModalCloseButton className="mt-1.5" />
          <ModalBody>
            <div className="mb-2">
              <QRCodeSVG
                className="mx-auto"
                value={String(QrValue)}
                size={250}
                bgColor={"#ffffff"}
                fgColor={"#000000"}
                level={"L"}
                imageSettings={{
                  src: "./HRMusterLogo.png",
                  x: undefined,
                  y: undefined,
                  height: 40,
                  width: 40,
                  excavate: true,
                }}
              />
              {/* {QrValue && <span>{QrValue}</span>} */}
            </div>
            <div>
              <h6>
                <FontAwesomeIcon
                  icon={faHandPointRight}
                  className="text-blue-600 mr-1.5"
                />
                Open the <span className="font-bold">2FA TSPL MIS App</span>
              </h6>
              <h6>
                <FontAwesomeIcon
                  icon={faHandPointRight}
                  className="text-blue-600 mr-1.5"
                />
                Scan the QR
              </h6>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default QRScanner;
