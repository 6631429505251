import React, {
  useState,
  useRef,
  useMemo,
  useCallback,
  useEffect,
} from "react";
import { useAuth } from "../Utils/AuthContext";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { LuLock, LuUnlock } from "react-icons/lu";
import _ from "lodash";
import { Button } from "@chakra-ui/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

const getMonthYearList = () => {
  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Month is zero-based, so we add 1
  const currentYear = currentDate.getFullYear();

  const monthYearList = [];
  for (let year = 2023; year <= currentYear; year++) {
    const endMonth = year === currentYear ? currentMonth : 12;
    for (let month = 1; month <= endMonth; month++) {
      monthYearList.push({ month, year });
    }
  }

  return monthYearList;
};

const getPayRegisterEmployee = async (month, year, userData) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetEmployeePRList?PayrollMonth=${month}&PayrollYear=${year}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
    );
    // console.log("Pay Register response", response);
    return response.data
      ?.filter((data) => data.guId !== null)
      .map((data) => ({
        ...data,
        payrollMonth: month,
        payrollYear: year
      }));
  } catch (err) {
    console.error(err);
  }
};

const sendSecondAPICall = async (month, year, data, userData) => {
  try {
    const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/PayrollSalaryReview/GetEmpMonthSalaryReportByEmpGuid?PayrollMonth=${month}&PayrollYear=${year}&EmpGuid=${data?.guId}&CompanyId=${userData?.companyId}&BranchId=${userData?.isActiveBranch}`
    );
    // console.log("Emp Salary Response response", response);
    const res = response.data;

    const transformedSalaryData = res.empSalaryData.reduce((acc, data) => {
        acc[data.branchSalaryStructureMasterId + 'salaryValue'] = data.salaryValue;
        return acc;
      }, {});
  
  const finalEmployeeData = {
    ...res,
    ...transformedSalaryData
  };
  
//   console.log("Modified Json",finalEmployeeData);

    return finalEmployeeData;
  } catch (err) {
    console.error(err);
  }
};

const PayRegisterReports = () => {
  const { userData } = useAuth();
  const [allPayRegisterEmployees, setAllPayRegisterEmployees] = useState([]);
  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);

  const [columnDefs, setColumnDefs] = useState([]);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const defaultColDef = useMemo(() => ({
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true,
  }));

  useEffect(() => {
    const fetchAllData = async () => {
      const monthYearList = getMonthYearList();
      const responses = await Promise.all(
        monthYearList?.map(async ({ month, year }) => {
          const firstResponse = await getPayRegisterEmployee(month, year, userData);
          const secondResponses = await Promise.all(
            firstResponse?.map(async (firstResponseItem) =>
             await sendSecondAPICall(month, year, firstResponseItem, userData)
            )
          );
          return secondResponses;
        })
      );
      setAllPayRegisterEmployees(_.flatten(responses));
    };

    fetchAllData();
    getMasterStructure();
  }, [userData]);

  console.log("All Pay Register Emp", allPayRegisterEmployees);

  const getMasterStructure = async () => {
    await axios
      .get(
        `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      .then((response) => {
        // console.log("Branch Salary Structure Response", response);

        const res = response.data;
        HandleReviewAllEmployeeTableHeads(
          res.filter((data) => data.isActive === true)
        );
      })
      .catch((err) => {
        console.error(err);
      });
  };

  const HandleReviewAllEmployeeTableHeads = (branchComponents) => {
    let StartingHeaders =
      userData.countryName === "India"
        ? [
            {
              headerName: "Employee Name",
              field: "empName",
              pinned: "left",
              cellRenderer: (params) => (
                <span className="align-middle">
                  {params.data.isLock ? (
                    <LuLock className="inline-block text-red-600" />
                  ) : (
                    <LuUnlock className="inline-block text-blue-600" />
                  )}{" "}
                  {params.data.empName}
                </span>
              ),
            },
            { headerName: "Employee Code", field: "empCode", pinned: "left" },
            { headerName: "Designation", field: "designationName" },
            { headerName: "Department", field: "departmentName" },
            { headerName: "Salary Month", field: "payrollMonth" },
            { headerName: "Salary Year", field: "payrollYear" },
            { headerName: "Payable Days", field: "actualPayableDays" },
          ]
        : [
            {
              headerName: "Employee Name",
              field: "empName",
              pinned: "left",
              cellRenderer: (params) => (
                <span className="align-middle">
                  {params.data.isLock ? (
                    <LuLock className="inline-block text-red-600" />
                  ) : (
                    <LuUnlock className="inline-block text-blue-600" />
                  )}{" "}
                  {params.data.empName}
                </span>
              ),
            },
            { headerName: "Employee Code", field: "empCode", pinned: "left" },
            { headerName: "Designation", field: "designationName" },
            { headerName: "Department", field: "departmentName" },
            { headerName: "Grade", field: "grade" },
            { headerName: "Division", field: "division" },
            { headerName: "Salary Month", field: "payrollMonth" },
            { headerName: "Salary Year", field: "payrollYear" },
            { headerName: "Payable Days", field: "actualPayableDays" },
          ];

    let MiddleHeaders = branchComponents.map((obj) => {
      return {
        headerName: obj.salarySlipLabel,
        field: obj.branchSalaryStructureMasterId + "salaryValue",
      };
    });

    let OtherEndHeads = [
      { headerName: "Adhoc Payments", field: `adhocPayment` },
      { headerName: "Gross", field: `gross` },
      { headerName: "Adhoc Deductions", field: `adhocDeduction` },
      { headerName: "Net Payable Salary", field: `netPayableSalary` },
    ];

    let IndiaEndHeads = [
      { headerName: "EPF_EE", field: "pF_Employee" },
      { headerName: "EPF_ER", field: "pF_Employer" },
      // { headerName: "Additional EPF", field: "empAdditionalEPFContribution" },
      { headerName: "ESI_EE", field: "esI_Employee" },
      { headerName: "ESI_ER", field: "esI_Employer" },
      { headerName: "Adhoc Payments", field: `adhocPayment` },
      { headerName: "Gross", field: `gross` },
      { headerName: "Adhoc Deductions", field: `adhocDeduction` },
      { headerName: "TDS", field: `empTDSAmount` },
      { headerName: "Net Payable Salary", field: `netPayableSalary` },
    ];

    if (userData.countryName === "India") {
      setColumnDefs(_.union(StartingHeaders, MiddleHeaders, IndiaEndHeads));
    } else {
      setColumnDefs(_.union(StartingHeaders, MiddleHeaders, OtherEndHeads));
    }
  };

//   console.log("Column Defs",columnDefs);

  return (
    <div>
      <div className="text-right mb-4">
        <Button
          onClick={() => {
            gridRef.current.api.exportDataAsCsv();
          }}
          leftIcon={<FontAwesomeIcon icon={faDownload} />}
          colorScheme="purple"
        >
          Export
        </Button>
      </div>
      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef}
          rowData={_.orderBy(allPayRegisterEmployees,['payrollMonth'], ['desc'])}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          pagination={true}
          paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
};

export default PayRegisterReports;
