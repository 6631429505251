import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import moment from "moment";

function GratuityList() {
  const userData = JSON.parse(localStorage.getItem("user"));

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  const [rowData, setRowData] = useState([]); // Table Data Variable
  const [columnDefs, setColumnDefs] = useState([

    {
      headerName: "Employee Name",
      field: "empName",
    },
    {
      headerName: "Designation",
      field: "designationName",
    },
    {
      headerName: "Date of joining",
      field: "doj",
      cellRenderer:(params) => <span>{moment(params.data.doj).format('DD/MM/YYYY')}</span>
    },
    {
      headerName: "Years of Service",
      field: "yearOfService",
    },
    {
      headerName: "Basic",
      field: "basic",
    },
    {
      headerName: "Gratuity",
      field: "gratuity",
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true
  }));


  useEffect(()=>{
    GetGratuityList();
  },[])

  const GetGratuityList = async () => {
    try{
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Gratuity/GetGratuityCalculation?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Gratuity List",response);
      setRowData(response.data);
    } catch(err) {
      console.error(err);
    }
  }
  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Gratuity List</h1>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          rowSelection="multiple"
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  )
}

export default GratuityList