import { Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

const HelpSearch = () => {

  const navigate = useNavigate();

  const [searchTerm, setSearchTerm] = useState("");
  const [suggestions, setSuggestions] = useState([]);
  const [selectedSuggestionIndex, setSelectedSuggestionIndex] = useState(-1);
  const [HelpMenuList, setHelpMenuList] = useState([])


  useEffect(()=>{
    GetHelpMenuList();
  },[])

  useEffect(() => {
    setSelectedSuggestionIndex(-1);
  }, [searchTerm]);

  const GetHelpMenuList = async () => {
    try {
     const response = await axios.get(
      `${process.env.REACT_APP_ACQ_URL}/api/HelpGuide/GetHelpGuideSubMenuList`
     ) 
     console.log("Help Menu List",response);
     setHelpMenuList(response.data);
    } catch (err) {
      console.error(err);
    }
  }


  const handleInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    if(newSearchTerm.length === 0){
      setSuggestions([]);
    }else{
          // Filter suggestions based on the current search term
     const filteredSuggestions = HelpMenuList.filter((suggestion) =>
      suggestion?.subCategoryName?.toLowerCase().includes(newSearchTerm.toLowerCase())
      );
      setSuggestions(filteredSuggestions);
    }

  };


  const handleKeyDown = (event) => {
    if (event.keyCode === 38) {
      // Up arrow key
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1
      );


    } else if (event.keyCode === 40) {
      // Down arrow key
      event.preventDefault();
      setSelectedSuggestionIndex((prevIndex) =>
        prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0
      );

    } else if (event.keyCode === 13) {
      // Enter key
      event.preventDefault();
      if (selectedSuggestionIndex !== -1) {
        setSearchTerm(suggestions[selectedSuggestionIndex]?.subCategoryName);
        setSuggestions([]);

        let EnteredData = suggestions[selectedSuggestionIndex]

         navigate(`/help/${EnteredData?.categoryId}/${EnteredData?.subCategoryId || 0}`);
      }
    }
  };


  return (
    <div className="relative inline-block w-1/3">
      <InputGroup>
        <InputLeftElement pointerEvents="none">
          <FontAwesomeIcon icon={faMagnifyingGlass} className="text-gray-400" />
        </InputLeftElement>
        <Input
          bg
          type="text"
          value={searchTerm}
          onKeyDown={handleKeyDown}
          onChange={handleInputChange}
          placeholder="Type here to search..."
        />
      </InputGroup>
      {suggestions.length > 0 && (
        <ul className="absolute bg-white border rounded-xl  overflow-y-auto m-0 p-0 w-full mt-1 border-solid border-gray-300 drop-shadow-md left-0 top-full">
          {suggestions.map((suggestion, i) => (
              <li key={i} onClick={()=>{
                navigate(`/help/${suggestion.categoryId}/${suggestion.subCategoryId || 0}`);
                setSuggestions([]);
                setSearchTerm(suggestion.subCategoryName);
               }}
               className={i === selectedSuggestionIndex ? 'px-4 py-2 hover:bg-blue-200 cursor-pointer w-full bg-blue-200 border-b last:border-b-0 border-gray-200' : 'px-4 py-2 hover:bg-blue-200 cursor-pointer w-full border-b last:border-b-0 border-gray-200'}  
               >
               {suggestion.subCategoryName}
              </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default HelpSearch;
