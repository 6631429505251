import React, {useState,useRef, useMemo,useCallback} from 'react'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import {
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Input,
  Avatar,  
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";

export default function AttendanceDated() {
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const gridRef = useRef();
  const [visulaizationcolumnDefs, setvisulaizationColumnDefs] = useState([
    {
      headerName: "Sr. No",
      field: "Id",
    },
    {
      headerName: "Employee Name",
      field: "employeeName",
    },
    {
      headerName: "Employee Code",
      field: "employeeCode",
    },
   
    { headerName: "City", 
    field: "city" ,
    },
    {
      headerName: "Original Pic.",
      field:'originalPic',
      cellRenderer: (params) => <Avatar size="sm" src="/images/user.jpg" />,
    },
    {
      headerName: "Capturing Pic.",
      field:'capturingPic',
      cellRenderer: (params) => <Avatar size="sm" src="/images/user.jpg" />,
    },
    
    {
      headerName: "In-Time",
      field:'inTime',
    },
    {
      headerName: "Out-Time",
      field:'outTime',
    },
    {
      headerName: "Date",
      field:'date',
    },
    {
      headerName: "Geo Fencing",
      field:'geoFencing',
    },
    {
      headerName: "Device Name",
      field:'deviceName',
    },
    {
      headerName: "Status",
      field:'status',
      cellRenderer:(params) => <span className='bg-green-600 p-1 rounded-md'>Present</span>
    },
    {
      headerName: "Status",
      field:'status',
      cellRenderer:(params) => <span className='bg-green-600 p-1 rounded-md'>Accepted</span>
    },

  ]); 
  const [visulaizationrowData, setvisulaizationRowData] = useState([
    {
      Id:"1",
      employeeName :"Pooja Jain",
      employeeCode: "Emp-101",
      city: "Gurgaon",
      designation: "Devloper",
      inTime: "10 : 02 AM",
      outTime: "10 : 02 AM",
      date : "01-01-2023",
      deviceName : "Bio-Matric",
      
    }
    
]); // Table Data Variable

const [columnDefs, setColumnDefs] = useState([
  {
    headerName: "Sr. No",
    field: "Id",
  },
  {
    headerName: "Employee Name",
    field: "employeeName",
  },
  {
    headerName: "Employee Code",
    field: "employeeCode",
  },
 
  { headerName: "City", 
  field: "city" ,
  },
  {
    headerName: "Designation",
    field:'designation',
  },
  {
    headerName: "Status",
    field:'status',
    cellRenderer:(params) => <span className='bg-green-600 p-1 rounded-md'>Present</span>
  },
  {
    headerName: "In-Time",
    field:'inTime',
  },
  {
    headerName: "Out-Time",
    field:'outTime',
  },
  {
    headerName: "Date",
    field:'date',
  },
  {
    headerName: "Device Name",
    field:'deviceName',
  },

]); 
const [rowData, setRowData] = useState([
  {
    Id:"1",
    employeeName :"Pooja Jain",
    employeeCode: "Emp-101",
    city: "Gurgaon",
    designation: "Devloper",
    inTime: "10 : 02 AM",
    outTime: "10 : 02 AM",
    date : "01-01-2023",
    deviceName : "Bio-Matric",
    
  }
  
]); // Table Data Variable


  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: 'no-border'
  })); 
  
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const current = new Date();
  const date = `${current.getDate()}/${
    current.getMonth() + 1
  }/${current.getFullYear()}`;
  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Forgot Attendance</h1>
      </div>

      <div className="bg-white rounded-2xl drop-shadow-md p-4">
        <h1 className="text-2xl text-center font-bold text-gray-800 my-10">
          Technomedia Solutions Private Limited
        </h1>

        <div className="space-x-3 flex justify-end my-2 items-center">
          <h5 className="text-2xl">Attendance Data</h5>
          <Input
            placeholder={date}
            borderColor={"gray.500"}
            width="90"
            size="md"
            type="date"
          />
        </div>
      </div>

      <div className="h-full  my-4">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
            <div className="flex flex-col justify-between">
              <p className="text-sm font-medium text-gray-500">Total Present</p>

              <h6 className="text-3xl font-bold">24</h6>

              <p className="text-sm text-gray-500 text-end">More info!</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="green.100"
                  size="lg"
                  icon={
                    <FontAwesomeIcon
                      className=" text-green-600 text-3xl"
                      icon={faUsers}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
            <div className="flex flex-col justify-between">
              <p className="text-sm font-medium text-gray-500">
                Total on Leave
              </p>

              <h6 className="text-3xl font-bold">20</h6>

              <p className="text-sm text-gray-500 text-end">More info!</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="green.100"
                  size="lg"
                  icon={
                    <FontAwesomeIcon
                      className=" text-green-600 text-3xl"
                      icon={faUsers}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
            <div className="flex flex-col justify-between">
              <p className="text-sm font-medium text-gray-500">Today Absent</p>

              <h6 className="text-3xl font-bold">05</h6>

              <p className="text-sm text-gray-500 text-end">More info!</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="green.100"
                  size="lg"
                  icon={
                    <FontAwesomeIcon
                      className=" text-green-600 text-3xl"
                      icon={faUsers}
                    />
                  }
                />
              </div>
            </div>
          </div>
          <div className="bg-white flex justify-between p-4 rounded-3xl shadow-lg h-36">
            <div className="flex flex-col justify-between">
              <p className="text-sm font-medium text-gray-500">
                Total Employees
              </p>

              <h6 className="text-3xl font-bold">200</h6>

              <p className="text-sm text-gray-500 text-end">More info!</p>
            </div>
            <div className="align-middle my-auto">
              <div className="bg-red-100 rounded-full ">
                <Avatar
                  bg="green.100"
                  size="lg"
                  icon={
                    <FontAwesomeIcon
                      className=" text-green-600 text-3xl"
                      icon={faUsers}
                    />
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="bg-white rounded-2xl drop-shadow-md p-4 mt-8">
        <Tabs size="md" variant="enclosed">
          <TabList>
            <Tab _selected={{ color: "white", bg: "purple.500" }}>
              Attendance dated
            </Tab>
            <Tab _selected={{ color: "white", bg: "purple.400" }}>
              Attendance Visualization
            </Tab>
            <Tab _selected={{ color: "white", bg: "purple.400" }}>
              Attendance Final Report
            </Tab>
          </TabList>
          <TabPanels>
            <TabPanel p={0} pt={4}>
              <div className="ag-theme-alpine">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={rowData} // Row Data for Rows
                  columnDefs={columnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                />
              </div>
            </TabPanel>
            <TabPanel  p={0} pt={4}>
            <div className="ag-theme-alpine">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  ref={gridRef} // Ref for accessing Grid's API
                  rowData={visulaizationrowData} // Row Data for Rows
                  columnDefs={visulaizationcolumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                />
              </div>
            </TabPanel>
          </TabPanels>
        </Tabs>
      </div>
    </div>
  );
}
