import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
  import { AgGridReact } from "ag-grid-react";
  import { Link } from "react-router-dom";
  import axios from "axios";
  import {
    Button,
    Modal,
    useDisclosure,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalOverlay,
    ModalFooter,
    ModalHeader,
    Input,
    useToast,
    Select,
  } from "@chakra-ui/react";
  import DatePicker from "react-datepicker";
  import ReactSelect from 'react-select';

import { faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function OvertimePayment() {
    const userData = JSON.parse(localStorage.getItem("user"));
     const [EmpList, setEmpList] = useState([]);
    const toast = useToast();
    const id = "toast";
  
    let CurrentYear = new Date().getFullYear();
    // console.log("Current Year", CurrentYear);
  
    let CurrentMonth = new Date().getMonth() + 1;
  
  
    const gridRef = useRef();
    const [rowData, setRowData] = useState(); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [SelectedEmpData, setSelectedEmpData] = useState([]);
    const [SelEmpSalaryData, setSelEmpSalaryData] = useState([]);
    const [OvertimeMonth, setOvertimeMonth] = useState();
    const [selMonthDates, setSelMonthDates] = useState([]);


    const [SelectedEmps, setSelectedEmps] = useState([]);

    const [OvertimeBulkPayAction, setOvertimeBulkPayAction] = useState();
    
    const [OvertimeListRowData, setOvertimeListRowData] = useState([]);
  
    const [PerDaySalary, setPerDaySalary] = useState(0);
  
    const [TotalCalculatedAmount, setTotalCalculatedAmount] = useState(0);
  
    const [PerHourSalary, setPerHourSalary] = useState(0);
  
    const [OvertimeMonthSelect, setOvertimeMonthSelect] = useState(new Date());
  
    // useEffect(() => {
  
    //  const getDaysInMonth = (month,year) => {
  
    //    return new Date(year, month, 0).getDate();
  
    //   };
  
    //   getDaysInMonth( OvertimeMonth.getMonth(),OvertimeMonth.getFullYear())
  
    //   console.log("Days in month" , getDaysInMonth);
  
    //   let DayGross = SelEmpSalaryData.grossSalary/30;
  
    //   console.log("Day Gross",DayGross)
    //   setPerDaySalary(DayGross)
  
    // }, [SelEmpSalaryData,OvertimeMonth])
  
    const {
      isOpen: isAddOvertimeOpen,
      onOpen: onAddOvertimeOpen,
      onClose: onAddOvertimeClose,
    } = useDisclosure();
  
    useEffect(() => {
      getEmp();
      getOvertimeData();
    }, []);
  
    const getEmp = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        console.log(response.data);
        const EmpList = response.data;
        let SalaryCompletedData = EmpList.filter(
          (data) => data.isSalaryStructureCompleted === 2
        );
  
        setRowData(SalaryCompletedData);
  
        setEmpList(SalaryCompletedData);
      } catch (error) {
        console.error(error);
      }
    };
  
  
    const getOvertimeData = async () =>{
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetPayrollOvertimeDataTotalEmp?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${CurrentMonth}&year=${CurrentYear}&Islock=true&IsStatus=1`
        );
        console.log("Overtime Data Response",response);
        setOvertimeListRowData(response.data);
      } catch (error) {
        console.error(error)
      }
    }

    function convertHMS(timeString){

      const arr = timeString.split(":");
      const seconds = arr[0]*3600+arr[1]*60+(+arr[2]);
      return seconds;
    
    }

    function convertToSeconds(hours, minutes) {
      return Number(hours) * 60 * 60 + Number(minutes) * 60;
    }

    const ConverToHours = (time) =>{
      let getTime = time;
      //  console.log("Get time",getTime);
  
      const [hours, minutes] = getTime.split(":");


      let seconds = convertToSeconds(hours, minutes);


      return Math.floor(seconds/3600)
    }


    const onSelectionChanged = useCallback(() => {
      var SelectedEmployees = gridRef.current.api.getSelectedRows();
     console.log("Selected Employees",SelectedEmployees);  

     setSelectedEmps(SelectedEmployees);

    }, []);


    const [OvertimeDataColumnDefs, setOvertimeDataColumnDefs] = useState([
      { headerName: "Employee Name", 
        field: "empName",
        headerCheckboxSelection: true,
        checkboxSelection: (params) => {
          return params.data.empName;
        }, 
      },
      { headerName: "Emp Code", field: "empCode" },
      { headerName: "Month", field: "month" },
      { headerName: "Year", field: "year" },
      { headerName: "Total Overtime", field: "totalovertime" ,
      valueGetter:(params) => ConverToHours(params.data.totalovertime) + ' ' +'hrs'
      // cellRenderer:(params)=>{
      //   let RowData = params.data;
      //   let TotalOvertimeHours = ConverToHours(RowData.totalovertime);

      //   console.log("Total Time",ConverToHours(RowData.totalovertime))
      //   return <span>{`${TotalOvertimeHours} hrs`}</span>
      // }
    },
    //   { headerName: "Per Hour Salary", field: "grossSalary",
    //     cellRenderer:(params)=>{
    //     let RowData = params.data;
    //     return <span>{parseInt((RowData.grossSalary/30)/24 || 0)}</span>
    //   }
    // },
    { headerName: "Calculation Amount", field: "overtimeAmtPerHour" },

      { headerName: "Calculated Amount", field: "calculatedAmount",
      valueGetter:(params) => parseInt((params.data.overtimeAmtPerHour)*ConverToHours(params.data.totalovertime))

      //  valueGetter:(params) => parseInt((params.data.grossSalary/30)/24*ConverToHours(params.data.totalovertime))
      // cellRenderer:(params)=>{
      //   let RowData = params.data;
      //   let TotalOvertimeHours = ConverToHours(RowData.totalovertime);
      //   return <span>{parseInt((RowData.grossSalary/30)/24*TotalOvertimeHours || 0)}</span>
      // }
    },

      { headerName: "Amount Payable", field: "payableAmount",
        singleClickEdit: true ,
        editable:true, 
        cellClass:'editable-grid-cell',
        valueGetter:(params) => parseInt((params.data.overtimeAmtPerHour)*ConverToHours(params.data.totalovertime))

        // valueGetter:(params) => parseInt((params.data.grossSalary/30)/24*ConverToHours(params.data.totalovertime))
    },
      { headerName: "Pay Action", field: "payAction",
      editable:true, singleClickEdit: true,
      cellEditor: 'agSelectCellEditor',
     cellEditorParams: {
         values: ['Pay', 'Hold'],
     } ,cellClass:'editable-grid-cell'
 },
      { headerName: "Remarks", field: "remark",singleClickEdit: true ,editable:true, cellClass:'editable-grid-cell'},
      { headerName: "Action", 
      cellRenderer:(params)=>
      <Button 
      onClick={async()=>{
        let OvertimeData = params.data;

        let body = {
          // overtimePaymentId: 0,
          empName: OvertimeData.empName,
          empGuId: OvertimeData.empGuid,
          empCode: OvertimeData.empCode,
          overTimeFinalId: OvertimeData.overTimeFinalId,
          month: OvertimeData.month,
          year: OvertimeData.year,
          totalOvertime: OvertimeData.totalovertime,
          payOvertime: OvertimeData.totalovertime,
          calculatedAmount: parseInt((params.data.overtimeAmtPerHour)*ConverToHours(params.data.totalovertime)),
          adjustedAmount: Number(OvertimeData.payableAmount),
          payableAmount: Number(OvertimeData.payableAmount),
          payAction: OvertimeData.payAction,
          remark: OvertimeData.remark,
          isStatus: 1,
          isLock: true,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
          employeeName: OvertimeData.empName,
          overtimePaymentStatus: true
        }



        console.log("Pay body",body);



        await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOvertimePayment`,body
        )
        .then((response) => {
          console.log("Pay Response",body);
          const res = response.data;
          //console.log("res",res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }

        })
        .catch((error) => {
          console.error(error);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: error.message,
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });


      }} 
      size='xs' colorScheme='blue' >Save</Button>},
    ]);
  
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Emp. Name",
        field: "firstName",
        valueGetter(params) {
          return params.data.firstName + " " + params.data.lastName;
        },
      },
      { headerName: "Emp. Code", field: "empCode", width: 118 },
      { headerName: "Designation", field: "designation" },
      { headerName: "Department", field: "department" },
      { headerName: "City", field: "branchName" },
      {
        headerName: "Employee Status",
        field: "isActive",
        cellRenderer: (params) =>
          params.data.isActive === true ? (
            <span>Active</span>
          ) : (
            <span>InActive</span>
          ),
      },
      {
        headerName: "Action",
        filter: false,
        cellRenderer: (params) =>
          params.data.isInvestment === true ? (
            <div className="flex gap-4">
              <Button size="sm" variant="link" colorScheme="blue">
                Edit
              </Button>
              <Button size="sm" variant="link" colorScheme="blue">
                View
              </Button>
            </div>
          ) : (
            <div className="space-x-4">
              <Button
                onClick={() => {
                  HandleAddOvertime(params.data.guId);
                }}
                size="sm"
                variant="link"
                colorScheme="blue"
              >
                Add Overtime
              </Button>
            </div>
          ),
      },
    ]);
  
    // Table Pagination
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
    //   flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
    }));
  
    const HandleAddOvertime = async (EmpguID) => {
  
      console.log(EmpguID);
  
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalary/GetEmpSalaryDataByEmpGuid?EmpGuid=${EmpguID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        );
        let EmpStructure = response.data;
        // console.log("Emp Structure Response",response);
        console.log("Emp Structure Response Data", response.data);
        setSelEmpSalaryData(EmpStructure);
        // onAddOvertimeOpen();
      } catch (error) {
        console.error(error);
      }
    };
  
    const getAllDaysInMonth = (year, month) => {
      const date = new Date(year, month, 1);
  
      const dates = [];
  
      while (date.getMonth() === month) {
        dates.push({
          date: new Date(date).toLocaleDateString("en-GB"),
          hours: 0,
          DaySalary: 0,
        });
        date.setDate(date.getDate() + 1);
      }
  
      return dates;
    };
  
    const getDaysInMonth = (month, year) => {
      return new Date(year, month, 0).getDate();
    };
  
    const HandleOvertimePayMonth = (date) => {
      console.log("Selected Date", date);
      setOvertimeMonth(date);
  
      setSelMonthDates(getAllDaysInMonth(date.getFullYear(), date.getMonth()));
      console.log(
        "Days in month",
        getDaysInMonth(date.getMonth(), date.getFullYear())
      );
      console.log(selMonthDates);
  
      let DaysinMonth = getDaysInMonth(date.getMonth(), date.getFullYear());
  
      let DayGross = SelEmpSalaryData.grossSalary / DaysinMonth;
  
      console.log("Day Gross", DayGross);
      setPerDaySalary(parseInt(DayGross));
  
      let HourGross = DayGross / 24;
      console.log("Hour Gross", HourGross);
      setPerHourSalary(parseInt(HourGross));
    };
  
    const HandleOvertimeHoursInput = (e, InputDate) => {
      console.log("Input Date", InputDate);
      const { id, value } = e.target;
      console.log("Input ID", Number(id));
      console.log("Input Value", Number(value));
  
      setSelMonthDates((prev) => {
        const newState = prev.map((obj) => {
          if (obj.date === InputDate) {
            return { ...obj, hours: value, DaySalary: value * PerHourSalary };
          }
          return obj;
        });
        return newState;
      });
    };
  
  
    useEffect(() => {
  
      setTotalCalculatedAmount(()=>{
        return selMonthDates.reduce((prev, values) => {
          return Number(prev || 0) + Number(values.DaySalary ||0)
        }, 0)
      })
  
    }, [selMonthDates])
  
  
    const HandleMonthChange = async (date) =>{
      setOvertimeMonthSelect(date)
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetPayrollOvertimeDataTotalEmp?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${date.getMonth()+1}&year=${date.getFullYear()}&Islock=true&IsStatus=1`
        );
        console.log("Overtime Data Response",response.data);
        setOvertimeListRowData(response.data);
      } catch (error) {
        console.error(error)
      }
    }
  
    const SaveOvertimeData = async () =>{
      let body={
        empGuId: SelectedEmpData.guId,
        empCode: SelectedEmpData.empCode,
        month: OvertimeMonth.getMonth()+1,
        year: OvertimeMonth.getFullYear(),
        calculatedAmount: TotalCalculatedAmount,
        // adjustedAmount: 800,
        // payableAmount: 455.6,
        // payAction: "pay",
        // remark: "string",
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
        employeeName: `${SelectedEmpData.firstName} ${SelectedEmpData.lastName}`,
        // overtimePaymentStatus: true
      }
  
      console.log("Save Overtime Body",body);
  
      await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOvertimePayment`,body
      )
      .then((response) => {
        let res = response.data;
        console.log("res",res);
    
        if(!toast.isActive(id)){
          toast({
            id,
            title:res,
            position: 'top',
            status: 'info',
            duration: 2000,
            isClosable: true,
          })
        }
         
        getOvertimeData();
        onAddOvertimeClose();
    
        setSelectedEmpData([]);
        setSelEmpSalaryData([]);
        setOvertimeMonth();
        setSelMonthDates([]);
        setPerDaySalary(0);
        setPerHourSalary(0);
    
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
      console.error(error)
      console.log(error.message);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'ERROR',
            description: "Overtime Not Saved",
            position: 'top',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      });
  
  
    }
  
  

    const HandleBulkSaveAction = async(e) =>{
      e.preventDefault();

      let body = OvertimeListRowData.map((data)=>{
        return  {...data,
          totalOvertime: data.totalovertime,
          payOvertime: data.totalovertime,
          calculatedAmount: parseInt((data.grossSalary/30)/24*ConverToHours(data.totalovertime)),
          adjustedAmount: Number((data.grossSalary/30)/24*ConverToHours(data.totalovertime) || 0),
          payableAmount: Number((data.grossSalary/30)/24*ConverToHours(data.totalovertime) || 0),
          payAction: OvertimeBulkPayAction,
          isLock: true,
          companyId: userData.companyId,
          branchId: userData.isActiveBranch,
          overtimePaymentStatus: true
        }
      })


      console.log("Bulk Actiton body",body);

      await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/Overtime/SaveOvertimePaymentList`,body
      )
      .then((response) => {
        console.log("Pay Response",body);
        const res = response.data;
        //console.log("res",res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: 'top',
            status: 'info',
            duration: 2000,
            isClosable: true,
          })
        }

      })
      .catch((error) => {
        console.error(error);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'ERROR',
            description: error.message,
            position: 'top',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      });


    }
  
    
    return (
      <div >
  
  
        <div className="flex justify-between items-center w-full border-b border-gray-400 mb-4 pb-2 text-left">
          <h1 className="text-gray-700 text-lg font-bold ">
            Overtime List
          </h1>
          <div className="flex space-x-4 items-center">
          <DatePicker
            className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
            selected={OvertimeMonthSelect}
            onChange={(date) => {
              HandleMonthChange(date);
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            placeholderText="Select Month"
          />
          {/* <div>
           <Button size={'sm'} onClick={onAddOvertimeOpen} colorScheme='purple' ><Plus size='16px' className="mr-2"/>Add Overtime</Button>
          </div> */}
          </div>
        </div>


        <form onSubmit={HandleBulkSaveAction}>
        <div className="px-5 flex py-3 space-x-5 ">
          <div className="my-auto">
           <FontAwesomeIcon className="rotate-180 text-lg" icon={faArrowTurnUp} />
          </div>
          <div>
          <Select size={'sm'} borderRadius={6} isRequired value={OvertimeBulkPayAction} onChange={({target})=>{setOvertimeBulkPayAction(target.value)}} bg borderColor={'gray.400'} placeholder='Select Pay Action'>
            <option value={'Pay'}>Pay</option>
            <option value={'Hold'}>Hold</option>
          </Select>
          </div>
          <Button size={'sm'} type="submit"  colorScheme='purple'>Save</Button>
        </div>
      </form>

  
        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} 
            rowData={OvertimeListRowData} 
            columnDefs={OvertimeDataColumnDefs}
            defaultColDef={defaultColDef} 
            animateRows={true}
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
            cacheQuickFilter={true}
            suppressExcelExport={true}
            onSelectionChanged={onSelectionChanged}
          />
        </div>
  
        <Modal
          size="full"
          isOpen={isAddOvertimeOpen}
          onClose={onAddOvertimeClose}
          scrollBehavior="inside"
        >
          <ModalOverlay />
          <ModalContent bg="gray.400">
            <ModalHeader bg="gray.400">Add Overtime</ModalHeader>
            <ModalCloseButton
              onClick={() => {
                setSelectedEmpData([]);
                setSelEmpSalaryData([]);
                setOvertimeMonth();
                setSelMonthDates([]);
                setPerDaySalary(0);
                setPerHourSalary(0);
              }}
            />
            <hr />
            <ModalBody>
              <div className="flex gap-10 m mb-8">
                <div className=" w-1/2">
                  <div className="overflow-x-auto relative rounded-3xl">
                    <table className="w-full text-sm text-left text-gray-500 ">
                      <thead className="text-xs text-gray-50 uppercase bg-gray-500">
                        <tr>
                          <th scope="col" className="py-3 px-6">
                            Date
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Overtime Hours
                          </th>
                          <th scope="col" className="py-3 px-6">
                            Value
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {selMonthDates.map((data, i) => {
                          return (
                            <tr key={i} className="bg-gray-100 border-b">
                              <td className="py-1 text-gray-800 font-medium px-6">
                                {data.date}
                              </td>
                              <td className="py-1 px-6">
                                <Input
                                  borderColor="gray"
                                  type="number"
                                  size="sm"
                                  borderRadius="5"
                                  placeholder="Overtime Hours"
                                  id={i}
                                  value={data.hours || ""}
                                  onChange={(e) => {
                                    HandleOvertimeHoursInput(e, data.date);
                                  }}
                                />
                              </td>
                              <td className="py-1 px-6">
                                <Input
                                  borderColor="gray"
                                  type="number"
                                  size="sm"
                                  borderRadius="5"
                                  placeholder="Value"
                                  id={i}
                                  value={data.DaySalary || ""}
                                />
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  </div>
                </div>
  
                <div className="w-1/4">
                  <div className="bg-gray-50 drop-shadow-md rounded-3xl space-y-4 p-4 mb-6">
                    <div>
                     <ReactSelect
                       styles={{
                        menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                        menu: provided => ({ ...provided, zIndex: 9999 })
                      }}
                       options={EmpList}
                       value={SelectedEmpData}
                       onChange={(value)=>{
                        setSelectedEmpData(value);
                        HandleAddOvertime(value.guId);
                       }}
                       getOptionLabel={(option) => `${option.firstName} ${option.lastName}  ${option.empCode}`}
                       getOptionValue={(option) => option.guId}
                       placeholder="Select Employee"
                     />
                    </div>
                    { !SelectedEmpData.guId  ||   <div className="grid grid-cols-2 gap-6">
                      <div className="space-y-2">
                       <div className="space-y-2  ">
                         <p className="text-base text-gray-500">Employee Name</p>
                         <h6 className="font-medium">
                           {SelectedEmpData.firstName} {SelectedEmpData.lastName}
                         </h6>
                       </div>
  
                       <div className="space-y-2 ">
                  <p className="text-base text-gray-500">Designation</p>
                  <h6 className="font-medium">{SelectedEmpData.designation}</h6>
                </div>
  
                <div className="space-y-2 ">
                  <p className="text-base text-gray-500">Gross Salary</p>
                  <h6 className="font-medium">{SelEmpSalaryData.grossSalary}</h6>
                </div>
  
                      </div>
  
                      <div className="space-y-2">
                      <div className="space-y-2 ">
                         <p className="text-base text-gray-500">Employee Code</p>
                         <h6 className="font-medium">{SelectedEmpData.empCode}</h6>
                       </div>
  
                       <div className="space-y-2 ">
                  <p className="text-base text-gray-500">Department</p>
                  <h6 className="font-medium">{SelectedEmpData.department}</h6>
                </div>
  
                      </div>
  
                    </div> }
  
                    </div>
      
                    { !SelectedEmpData.guId  ||    <div className="bg-gray-50 drop-shadow-md rounded-3xl space-y-4 p-4">
                    <div className="space-y-2">
                      <p className="text-lg font-medium text-gray-900">
                        Overtime Pay Month
                      </p>
                      <DatePicker
                        className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5   outline-none"
                        selected={OvertimeMonth}
                        onChange={(date) => {
                          HandleOvertimePayMonth(date);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                        placeholderText="Overtime Pay Month"
                      />
                    </div>
  
                    <div className="grid grid-cols-2 gap-6">
                      <div className="space-y-2 ">
                        <p className="text-base  text-gray-900">Per Day Salary</p>
                        <h6 className="font-medium text-gray-900">
                          {PerDaySalary}
                        </h6>
                      </div>
  
                      
                      <div className="space-y-2 ">
                         <p className="text-base  text-gray-900">Per Hour Salary</p>
                         <h6 className="font-medium text-gray-900">
                           {PerHourSalary}
                         </h6>
                      </div>
  
                    </div>
  
                    <div className="grid grid-cols-2 gap-6">
                     <div className="space-y-2 ">
                         <p className="text-base  text-gray-900">Total Calculated Amount</p>
                         <h6 className="font-medium text-gray-900">
                           {TotalCalculatedAmount}
                         </h6>
                      </div>
                    </div>
  
  
  
                  </div> }
                  
  
                </div>
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                colorScheme="purple"
                mr={3}
                onClick={() => {
  
  
                  SaveOvertimeData();
  
  
                }}
              >
                Save
              </Button>
  
              <Button
                colorScheme="purple"
                variant="outline"
                onClick={() => {
                  onAddOvertimeClose();
  
                  setSelectedEmpData([]);
                  setSelEmpSalaryData([]);
                  setOvertimeMonth();
                  setSelMonthDates([]);
                  setPerDaySalary(0);
                  setPerHourSalary(0);
                }}
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    );
  }

export default OvertimePayment