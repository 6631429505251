import React, { useState, useRef, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import {
  Button,
  Avatar
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";

export default function ForgotAttendanceReport() {
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const gridRef = useRef();
  const [rowData, setRowData] = useState([
    {
      Id: "1",
      name: " Vishal Sharma",
      empCode: "IND-101",
      date: "01-01-2023",
      attendance: "Absent",
      status: "Pending",
      generatedCode: "IND-100",
      isActive: true,
    },
  ]); // Table Data Variable
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Sr. No",
      field: "Id",
    },
    {
      headerName: "Image",
      cellRenderer: (params) => <Avatar size="sm" src="/images/user.jpg" />,

      // <img  src='/images/user.jpg' width="50" height="50"   />,
    },
    {
      headerName: "Name",
      field: "name",
      // cellRenderer:(params) => {
      //   return <div>
      //   <h1>{params.data.name} <br /> {params.data.empCode}</h1>
      // </div>
      // }
    },
    {
      headerName: "Date (DD-MM-YYY)",
      field: "date",
    },
    {
      headerName: "Approved By",
      field: "approvedBy",
    },
    {
      headerName: "Review By",
      field: "reviewBy",
    },
    {
      headerName: "Attendance",
      field: "attendance",
    },
    {
      headerName: "Status",
      field: "status",
    },
    {
      headerName: "Action",
      field: "guId",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button variant="solid" size="sm" colorScheme="blue">
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
        </div>
      ),
    },
  ]);
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">
          Forgot Attendance Report
        </h1>
      </div>
      <div className="space-x-3 my-2 text-left">
        <Button type="reset" colorScheme="purple">
          <Link to="/forgotattendance">Forgot Attendance List</Link>
        </Button>
      </div>

      <div className="bg-white rounded-2xl drop-shadow-md p-4">
        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>
      </div>
    </div>
  );
}
