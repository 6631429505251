import { Button } from '@chakra-ui/react';
import { faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useNavigate } from 'react-router-dom'

function NotFound() {
 const navigate = useNavigate();
  return (
    <div>
     <div className='p-4 flex justify-center items-center'>
        <div className='text-center space-y-6'>
          <img src="./images/oops.png" className='mx-auto' alt="oops"/>
          <h2 className='text-3xl font-semibold text-gray-800'>Error 404 : Page not found!</h2>
          <Button leftIcon={<FontAwesomeIcon icon={faArrowLeft} />} onClick={()=>{navigate('/dashboard')}} variant={'solid'} colorScheme='purple'>Back to Dashboard!</Button>
        </div>
      </div>
    </div>
  )
}

export default NotFound