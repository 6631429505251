import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionPanel, AccordionItem, Input, Button, Select, InputGroup, Tooltip, InputRightAddon, Switch, useDisclosure, useToast, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Modal, ModalOverlay, RadioGroup, Radio, } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Plus } from "react-feather";
import axios from "axios";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit, faEye } from "@fortawesome/free-solid-svg-icons";

function PolicyUpload() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";


  const FileRef = useRef();

  const reset = () => {
    FileRef.current.value = "";
  };


  const [PolicyId, setPolicyId] = useState(0);
  const [IsEdit, setIsEdit] = useState(false);

  const [DepartmentList, setDepartmentList] = useState([]);

  const [Department, setDepartment] = useState();
  const [CompanyName, setCompanyName] = useState(userData.companyName);
  const [PolicyDescription, setPolicyDescription] = useState();
  const [AttachPolicy, setAttachPolicy] = useState();
  const [PolicyApply, setPolicyApply] = useState('0');
  const [Remarks, setRemarks] = useState();

  const { isOpen, onOpen, onClose } = useDisclosure()

  const [PdfPath, setPdfPath] = useState();

  const [DepartmentName, setDepartmentName] = useState();

  const {
    isOpen: isAddDepartmentOpen,
    onOpen: onAddDepartmentOpen,
    onClose: onAddDepartmentClose,
  } = useDisclosure();


  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Policy Description",
      field: "policyName",
    },
    {
      headerName: "Remarks",
      field: "remark",
      cellRenderer:(params)=>(
        params.data.remark === "undefined" ? <h6>No Remarks Given</h6> : params.data.remark
        )
    },
    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({target}) => {HandlePolicySwitch(target.checked,params.data)}}
          size="md"
        />
      ),
    },
    {
      headerName: "Policy Attachment",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
           leftIcon={<FontAwesomeIcon icon={faEye} />}
           onClick={()=>{handleDownloadFile(params.data.attachPolicyPath)}}
           colorScheme="blue"
           variant='solid'
           size={'xs'}
         >
           View
         </Button> 
         <Button
           leftIcon={<FontAwesomeIcon icon={faEdit} />}
           onClick={()=>{
            let PolicyRowData = params.data;
             setIsEdit(true);

             setPolicyId(PolicyRowData.policyId)
             setPolicyDescription(PolicyRowData.policyName);
             setPolicyApply(String(PolicyRowData.policyApply));
             setRemarks(PolicyRowData.remark);
             setDepartment(PolicyRowData.department)
             setAttachPolicy(PolicyRowData.attachPolicyPath)
           }}
           colorScheme="blue"
           variant='outline'
           size={'xs'}
         >
           Edit
         </Button>   
        </div>
      ),
    },
  ]);
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));





  useEffect(() => {
    getDepartmentList();
    getPolicyList();
  }, [])

  const getDepartmentList = async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/DepartmentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      // console.log("Department List",response.data);
      setDepartmentList(response.data);
    } catch (error) {
      console.error(error)
    }
  }



  const getPolicyList = async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/CompanyPolicy/GetAllPolicyMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Policy List",response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error)
    }
  }


  const HandlePolicySwitch = async (checked,PolicyData) => {

    setRowData(prev => {
      const newState = prev.map((obj)=>{
        if (obj.policyId === PolicyData.policyId) {
          return {...obj, isActive: checked};
        }
        return obj;
        
      })
      return newState

    })


  const UploadData = new FormData();
  UploadData.append('policyId',PolicyData.policyId)
  UploadData.append("attachPolicyPath",PolicyData.attachPolicyPath);
  UploadData.append("policyName",PolicyData.policyName);
  UploadData.append("policyApply",PolicyData.policyApply);
  UploadData.append("department",PolicyData.department);
  UploadData.append("companyName",PolicyData.companyName);
  UploadData.append("isActive",checked);
  UploadData.append("remark",PolicyData.remark);
  UploadData.append("companyId",PolicyData.companyId);
  UploadData.append("branchId",PolicyData.branchId);

  await axios
  .post(
    `${process.env.REACT_APP_ACQ_URL}/api/CompanyPolicy/SaveCompanyPolicy`,
    UploadData
  )
  .then((response) => {
    console.log(response);
    const res = response.data;
    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }

    getPolicyList();

  })
  .catch(({response}) => {
    console.log(response);

    if (!toast.isActive(id)) {
      toast({
        id,
        title: "ERROR",
        description: `Company Policy not Added`,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  });


  }

  const SaveCompanyPolicy = async (e) => {

    e.preventDefault();

    const UploadData = new FormData();

    if(IsEdit){
      UploadData.append("policyId",PolicyId);
      UploadData.append("attachPolicyPath",AttachPolicy);

    }else{
      UploadData.append("attachPolicy",AttachPolicy);

    }

    UploadData.append("policyName",PolicyDescription);
    UploadData.append("department",Department || 0);
    UploadData.append("companyName",CompanyName);
    UploadData.append("policyApply",Number(PolicyApply));
    UploadData.append("isActive",true);
    UploadData.append("remark",Remarks);
    UploadData.append("companyId",userData.companyId);
    UploadData.append("branchId",userData.isActiveBranch);
    //  UploadData.append("SrNo",2);
   console.log(" Upload Data",UploadData)

    await axios
    .post(
      `${process.env.REACT_APP_ACQ_URL}/api/CompanyPolicy/SaveCompanyPolicy`,
      UploadData
    )
    .then((response) => {
      console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title: res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }

      getPolicyList();
      setDepartment('');
      setPolicyDescription('');
      setAttachPolicy('');
      setPolicyApply('0');
      setRemarks('');
      setIsEdit(false);
      setPolicyId(0);
      reset();


    })
    .catch((error) => {
      console.error(error);

      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Company Policy not Added`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });

  }

  const SaveDepartmentMaster = async (e) =>{
    e.preventDefault();
    let body = {
      departmentName: DepartmentName,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    }
    console.log(body);
    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Master/SaveDepartmentMaster`,body
    )
    .then((response) => {
      console.log(response);
      const res = response.data;
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: "top",
          status: "success",
          duration: 2000,
          isClosable: true,
        });
      }

       getDepartmentList();
       onAddDepartmentClose();
       setDepartmentName('');

    })
    .catch((error) => {
      console.error(error);

      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Department not Added`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });


  }








  const handleDownloadFile = async (DocPath) =>{
    console.log(DocPath)
   await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
  ).then(response => {

    console.log("file Response",response);

    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        // alink.download = fileURL;
        setPdfPath(alink)
        onOpen();
        // alink.click();
    })

  }).catch((error)=>{
    console.log("File Api Error",error);
  })
  }


  




  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">
          Company Policy Doucment
        </h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Company Policy Doucment
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveCompanyPolicy}>

                <div className="grid grid-cols-2 gap-8 mb-4">
                <div className="space-y-2 ">
                    <label>Company Name</label>
                    <Input
                      value={CompanyName}
                      onChange={({target})=>{setCompanyName(target.value)}}
                      isDisabled
                      borderColor="gray"
                      placeholder="Company Name"
                    />
                  </div>

                  <div className="space-y-2 ">
                    <label>Policy Name</label>
                    <Input 
                      value={PolicyDescription ||''}
                      onChange={({target})=>{setPolicyDescription(target.value)}}
                      borderColor="gray"
                      placeholder="Enter Policy Name"
                    />
                  </div>
                </div>
                <div className="grid grid-cols-2 gap-8 mb-4">
                <div className="space-y-2">
                    <label>Attach Policy</label>
                    <Input
                      ref={FileRef}
                      isDisabled={IsEdit}
                      onChange={({target})=>{setAttachPolicy(target.files[0])}}
                      type="file"
                      borderColor="gray"

                    />
                  </div>
                  <div className="space-y-2">
                    <label>Policy Description</label>
                    <Input                  
                     value={Remarks || ''}
                     onChange={({target})=>{setRemarks(target.value)}}
                     borderColor="gray" placeholder="Enter Policy Description" />
                  </div>

                </div>

                <div className="grid grid-cols-2 gap-8 mb-4">
                  <div className="space-y-2 ">
                    <label className="block">Policy Apply</label>
                      <RadioGroup    
                      value={PolicyApply}
                      onChange={setPolicyApply}
>
                        <div className="space-x-4">
                          <Radio borderColor='gray.400'  value="0">
                            Admin
                          </Radio>
                          <Radio borderColor='gray.400'  value="1">
                            All Employee
                          </Radio>
                          <Radio borderColor='gray.400'  value="2">
                            Department
                          </Radio>
                        </div>
                      </RadioGroup>
                  </div>
                  <div className="space-y-2 ">
                    <label>Department</label>
                    <InputGroup size="md">
                      <Select
                        isDisabled={PolicyApply === '2' ?false : true}
                        borderColor="gray"
                        placeholder="Please Select Department"
                        value={Department}
                        onChange={({target})=>{setDepartment(target.value)}}
                      >
                     {DepartmentList.map((data,i)=>{
                       return <option key={i} value={data.departmentId}>{data.departmentName}</option>
                      })
                      }
                      </Select>
                      <Tooltip
                        placement="left"
                        rounded="5px"
                        hasArrow
                        label="Add Department"
                        bg="gray.700"
                        color="white"
                      >
                        <InputRightAddon
                          onClick={onAddDepartmentOpen}
                          p={0}
                          bgcolor="purple"
                          children={
                            <Button
                              colorScheme="purple"
                              children={<Plus size="16px" />}
                            />
                          }
                        />
                      </Tooltip>
                    </InputGroup>
                  </div>
                </div>
                <div className="space-x-3 my-2 text-right">
                  {/* <Button type="reset" variant="outline" colorScheme="purple">
                    Reset
                  </Button> */}
                  <Button type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Modal 
                isOpen={isAddDepartmentOpen}
                onClose={onAddDepartmentClose}
              >
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Add Department</ModalHeader>
                  <ModalCloseButton />
                  <hr />
                  <form onSubmit={SaveDepartmentMaster}>
                    <ModalBody>
                      <div className="space-y-2">
                        <label>Department Name</label>
                        <Input
                          isRequired
                          value={DepartmentName}
                          onChange={({target})=>{setDepartmentName(target.value)}}
                          borderColor="gray"
                          placeholder="Enter Department Name"
                        />
                      </div>
                    </ModalBody>
                    <hr />
                    <ModalFooter>
                      <div className="space-x-4">
                        <Button
                          type="submit"
                          colorScheme="purple"
                          variant="solid"
                        >
                          Save
                        </Button>
                        <Button
                          variant="outline"
                          colorScheme="purple"
                          mr={3}
                          onClick={onAddDepartmentClose}
                        >
                          Cancel
                        </Button>
                      </div>
                    </ModalFooter>
                  </form>
                </ModalContent>
              </Modal>


              <Modal size='full' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Policies</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div id="pdf-div"  >
            <embed

              src={PdfPath + "#toolbar=0"}
              type="application/pdf"
              className="h-screen w-full !select-none"
              height={800}
              width={500}
            />
            </div>
          </ModalBody>
          <hr />

          <ModalFooter>
            <Button colorScheme='purple' onClick={onClose}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
}

export default PolicyUpload;
