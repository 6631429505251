import React, { useState, useRef, useMemo, useCallback, useEffect } from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Input,
  Button,
  Tabs, TabList, TabPanels, Tab, TabPanel,
  useToast,
  Select,
  Textarea,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";
import axios from "axios";


function EmpSeparation() {

  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";
  const [SeperationReasonList, setSeperationReasonList] = useState([]);

  const [ResignationDate, setResignationDate] = useState();
  const [LastWorkingDate, setLastWorkingDate] = useState();
  const [ResigantionReason, setResigantionReason] = useState();
  const [Remarks, setRemarks] = useState();

  const [TeamRowData, setTeamRowData] = useState([]);

  const [TeamColumnDefs, setTeamColumnDefs] = useState([
    // {
    //   headerName: "Emp Code",
    //   field: "empCode",
    // },
    {
      headerName: "Emp Name",
      field: "empName",
    },
    {
      headerName: "Request Date",
      field: "resignationDate",
      valueGetter : (params) => new Date(params.data.resignationDate).toLocaleDateString('en-GB')
    },
    {
      headerName: "Last working Date",
      field: "lastWorkingDate",
      valueGetter : (params) => new Date(params.data.lastWorkingDate).toLocaleDateString('en-GB')
    },
    {
      headerName: "Reason",
      field: "resignationReason",
    },
    { headerName: "Separation Status", field: "resignationStatus",
    cellRenderer:(params) => (
    params.data.resignationStatus === 'Pending' ?
    <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
      Pending
    </span> : params.data.resignationStatus === 'Approved' ? 
    <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
      Approved
    </span> :  params.data.resignationStatus === 'Rejected' ? 
    <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
    Rejected
    </span> : params.data.resignationStatus === 'Cancelled' ? 
    <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
    Cancelled
    </span> : params.data.resignationStatus === 'Finalized' ? 
    <span className="bg-blue-500 font-medium px-1.5 py-0.5 rounded-md text-white">
    Finalized
    </span> : ''
    )
 },
  ]);


  const gridRef = useRef();
  const [rowData, setRowData] = useState([ ]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Request Date",
      field: "resignationDate",
      valueGetter : (params) => new Date(params.data.resignationDate).toLocaleDateString('en-GB')
    },
    {
      headerName: "Last working Date",
      field: "lastWorkingDate",
      valueGetter : (params) => new Date(params.data.lastWorkingDate).toLocaleDateString('en-GB')

    },
    {
      headerName: "Reason",
      field: "resignationReason",
    },
    { headerName: "Separation Status", field: "resignationStatus",
    cellRenderer:(params) => (
    params.data.resignationStatus === 'Pending' ?
    <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
      Pending
    </span> : params.data.resignationStatus === 'Approved' ? 
    <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
      Approved
    </span> :  params.data.resignationStatus === 'Rejected' ? 
    <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
    Rejected
    </span> : params.data.resignationStatus === 'Cancelled' ? 
    <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
    Cancelled
    </span> : params.data.resignationStatus === 'Finalized' ? 
    <span className="bg-blue-500 font-medium px-1.5 py-0.5 rounded-md text-white">
    Finalized
    </span> : ''
    )
 },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    // floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  const TeamDefaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      resizable:true
  }));

  useEffect(() => {
    getSeperationReasonList();
    getEmpResignationStatus();
    GetTeamSeparationList();
  }, [])
  


  const getSeperationReasonList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SeparationReasonMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log(" Reason List",response);
      setSeperationReasonList(response.data);
    } catch (error) {
      console.error(error)
    }
  }


  const GetTeamSeparationList = async () => {
    try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetSeparationListShowbyReporting?Empguid=${userData.guId}`
        )
        console.log("Team Separation List",response);
        const res = response.data;
        setTeamRowData(response.data);
    } catch (error) {
      console.error(error)
    }
  }


  const getEmpResignationStatus = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetEmpResignationList?CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      )
      console.log(" Resignations List",response.data);
      const res = response.data;
      let EmpResignation = res.filter(
        (data) =>{
          return (data.empGuId === (userData.guId).toUpperCase()) || (data.empGuId === (userData.guId).toLowerCase())
        }
      );
      console.log("Emp Resignation",EmpResignation);
      setRowData(EmpResignation)
    } catch (error) {
      console.error(error)
    }
  }



  const SaveResignation = async (e) =>{
    e.preventDefault();
    let body = {
      empGuId: userData.guId,
      empName: userData.name,
      resignationDate: ResignationDate,
      resignationReason: ResigantionReason,
      lastWorkingDate: LastWorkingDate,
      resignationStatus: "Pending",
      companyId: userData.companyId,
      branchId: userData.branchId,


    };

    console.log("Add Resignation Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveEmpResignation`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        console.log("res", res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        getEmpResignationStatus();

        setResignationDate('');
        setResigantionReason('');
        setLastWorkingDate('')

      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Resignation Not Approved.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  }


  return (
    <div>
      <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Separation</h1>
      </div>


      <Tabs size={'sm'} variant={'solid-rounded'} colorScheme={'purple'}>
  <TabList>
    <Tab>Separation</Tab>
    <Tab>Team Separation</Tab>
  </TabList>

  <TabPanels>
    <TabPanel px={0}>
    <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Request Separation
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveResignation}>
                <div className="grid grid-cols-4 gap-8 my-4">
                  <div className="space-y-2">
                    <label>Request Date</label>
                    <Input
                    value={ResignationDate}
                    onChange={({target})=>{setResignationDate(target.value)}}
                      isRequired
                      type="date"
                      borderColor="gray"
                      placeholder="Request Date"
                    />
                  </div>
                  <div className="space-y-2">
                    <label>Last Working Date</label>
                    <Input
                      value={LastWorkingDate}
                      onChange={({target})=>{setLastWorkingDate(target.value)}}
                      isRequired
                      type="date"
                      borderColor="gray"
                      placeholder="Request Date"
                    />
                  </div>
                  <div className="space-y-2">
                    <label>Separation Reason</label>
                    <Select
                    value={ResigantionReason}
                    onChange={(e) => {
                      setResigantionReason(e.target.value);
                    }}
                    borderColor="gray.400"
                    placeholder="Select Seperation Reason"
                  >
                    {SeperationReasonList.map((data,i)=>{
                      return <option key={i} value={data.separationReasonName}>{data.separationReasonName}</option>
                    })}
                  </Select>
                  </div>
                  <div className="space-y-2">
                    <label>Remarks</label>
                    <Textarea
                      value={Remarks}
                      onChange={({target})=>{setRemarks(target.value)}}
                      rows={1}
                      borderColor="gray"
                      placeholder="Enter Remarks"
                    />
                  </div>
                </div>
                <div className="space-x-3 my-2 text-right">
                  <Button type="submit" colorScheme="purple">
                    Request
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>


      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>
    <TabPanel px={0}>
    <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={TeamRowData} // Row Data for Rows
          columnDefs={TeamColumnDefs} // Column Defs for Columns
          defaultColDef={TeamDefaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </TabPanel>
  </TabPanels>
</Tabs>



    </div>
  )
}

export default EmpSeparation