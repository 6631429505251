import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
  import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import { useToast, Button, useDisclosure, Modal,
    ModalOverlay,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader } from "@chakra-ui/react";
import moment from "moment";

function AssetIssues() {

    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = 'toast';

    const [Description, setDescription] = useState();
    const {
      isOpen: isDescriptionOpen,
      onOpen: onDescriptionOpen,
      onClose: onDescriptionClose,
    } = useDisclosure();

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
        { headerName: "Emp Code", field: "empCode"},
       { headerName: "Emp Name", field: "fullName"},

       { headerName: "Request Date", field: "issueDate",
        cellRenderer:(params) => <span>{moment(params.data.issueDate).format('DD/MM/YYYY')}</span>
       },
       { headerName: "Asset Name", field: "assetsName" },
       { headerName: "Model No.", field: "assetsModelNo" },
       { headerName: "Asset Type", field: "assetType" },
       { headerName: "Issue", field: "empDescription",
        cellClass:'cursor-pointer',
        cellRenderer:(params) =>  <div onClick={()=>{
         setDescription(params.data.empDescription);
         onDescriptionOpen();
        }}>{params.data.empDescription}</div>
       },
       { headerName: "Issue Status", field: "issueStatus",
       cellRenderer:(params) => (
         params.data.issueStatus === "Pending" ?
         <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
           Pending
         </span> : params.data.issueStatus === "Processing" ?
         <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
           Processing
         </span> : params.data.issueStatus === "Resolved" ?
         <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
           Resolved
         </span> : params.data.issueStatus === "Rejected" ?
         <span className="bg-rejected-500 font-medium px-1.5 py-0.5 rounded-md text-white">
           Rejected
         </span> : ''
       )
       },
       { headerName: "Approval Status", field: "approveStatus",
       cellRenderer:(params) => (
         params.data.approveStatus === "Pending" ?
         <span className="bg-yellow-500 font-medium px-1.5 py-0.5 rounded-md text-white">
           Pending
         </span> : params.data.approveStatus === "Approved" ?
         <span className="bg-green-500 font-medium px-1.5 py-0.5 rounded-md text-white">
           Approved
         </span> : params.data.approveStatus === "Rejected" ?
         <span className="bg-red-500 font-medium px-1.5 py-0.5 rounded-md text-white">
           Rejected
         </span> : ""
       )
       },
       { headerName: "Action", cellRenderer:(params) => params.data.approveStatus === "Approved" ? <div className="space-x-4">
        <Button onClick={async()=>{
                 let IssueRowData = params.data;

                 let body = {
                  assetsRequestId : IssueRowData.assetsRequestId,
                  empGuid : IssueRowData.empGuid,
                  empCode : IssueRowData.empCode,
                  fullName : IssueRowData.fullName,
                  assetsMasterId : IssueRowData.assetsMasterId,
                  assetsName : IssueRowData.assetsName,
                  assetsSerialNo : IssueRowData.assetsSerialNo,
                  assetsModelNo : IssueRowData.assetsModelNo,
                  assetType : IssueRowData.assetType,
                  empDescription : IssueRowData.empDescription,
                  issueStatus : "Resolved",
                  issueDate : IssueRowData.issueDate,
                  resolveDate : IssueRowData.resolveDate,
                  approveBy : userData.userId,
                  approveStatus : IssueRowData.approveStatus,
                  adminComment : IssueRowData.adminComment,
                  companyId : IssueRowData.companyId,
                  branchId : IssueRowData.branchId
                }
        
              console.log("Approve Body",body);
        
              await axios.post(
                `${process.env.REACT_APP_ACQ_URL}/api/Assets/SaveSaveAssetIssueRequest`,body
              )
              .then((response) => {
                console.log(response);
                const res = response.data;
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title:res,
                    position: "top",
                    status: "success",
                    duration: 2000,
                    isClosable: true,
                  });
                }
        
                
              })
              .catch((error) => {
                console.error(error);
          
                if (!toast.isActive(id)) {
                  toast({
                    id,
                    title: "ERROR",
                    description: `Assets Issue Not Updated`,
                    position: "top",
                    status: "error",
                    duration: 2000,
                    isClosable: true,
                  });
                }
              });

        }} colorScheme={'blue'} size={'xs'} >Resolved</Button>
       </div> : '' },

    ]);
  
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      // flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable : true
    }));

    useEffect(()=>{
        GetAssetIssuesList();
    },[])

    const GetAssetIssuesList = async () =>{
      try {
            const response =  await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/Assets/GetAssetIssueRequestListData?CompanyId=1&BranchId=1`
            )
            console.log("Issue List Response ",response.data);
            setRowData(response.data);
        } catch (error) {
            console.error(error);
        }
    }


  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Asset Issues</h1>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>

      <Modal  isOpen={isDescriptionOpen} onClose={onDescriptionClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Description</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>
              <div>
                {Description}
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                onClick={onDescriptionClose}
                colorScheme="purple"
                variant="outline"
              >
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

    </div>
  )
}

export default AssetIssues