import React, {
  useState,
  useEffect,
} from "react";
import {
  Input,
  Button,
  useToast,
  Textarea,
  Avatar,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
} from "@chakra-ui/react";
import axios from "axios";
import {LuEdit3} from 'react-icons/lu';

function CompanyDetail() {
  const userData = JSON.parse(localStorage.getItem('user'));
  const toast = useToast();
  const id = "toast";

  const { isOpen, onOpen, onClose } = useDisclosure()

  //  Company Details Variables
  const [CompanyId, setCompanyId] = useState();
  const [UserId, setUserId] = useState();
  const [CompanyName, setCompanyName] = useState();
  const [CompanyCode, setCompanyCode] = useState();
  const [IsActive, setIsActive] = useState();
  const [CompanyType, setCompanyType] = useState();
  const [ReportingOfficer, setReportingOffice] = useState();
  const [CompanyLogo, setCompanyLogo] = useState();
  const [CompanyAddress, setCompanyAddress] = useState();
  const [CompanyPhone, setCompanyPhone] = useState();

  const [LogoPath, setLogoPath] = useState();

  useEffect(() => {
    getCompanyDetailDetails();
  }, []);

  const getCompanyDetailDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Company/GetCompanyById?CompanyId=${userData.companyId}`
      );
      console.log("Company Details", response.data);
      const res = response.data;
      setCompanyId(res.companyId);
      setUserId(res.userId);
      setCompanyName(res.companyName);
      setCompanyCode(res.companyCode);
      setIsActive(res.isActive);
      setCompanyType(res.companyType);
      setReportingOffice(res.reportingOfficer);
      setCompanyAddress(res.companyAddress);
      setCompanyPhone(res.companyPhone);
      handleDownloadFile(res.logoPath);
    } catch (error) {
      console.error(error);
    }
  };


  const handleDownloadFile = async (DocPath) =>{
    console.log("Document path",DocPath)
   await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
  ).then(response => {

    console.log("file Response",response);

    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values

        let alink = document.createElement('a');
        alink.href = fileURL;
        setLogoPath(alink)
        // alink.download = fileURL;
        // alink.click();
    })

  }).catch((error)=>{
    console.log("File Api Error",error);
  })
  }



  const SaveCompanyDetails = async (e) => {

    e.preventDefault();


    const CompanyData = new FormData();

    CompanyData.append('userId',userData.userId)
    CompanyData.append('companyName',CompanyName)
    CompanyData.append('companyCode',CompanyCode)
    CompanyData.append('isActive',true)
    CompanyData.append('companyType',CompanyType)
    CompanyData.append('companyAddress',CompanyAddress)
    CompanyData.append('companyPhone',CompanyPhone)
    CompanyData.append('logo',CompanyLogo)
    CompanyData.append('companyId',Number(userData.companyId))

  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/Company/SaveCompany`,CompanyData
  )
  .then((response) => {
    console.log(response);
    const res = response.data;
    if (!toast.isActive(id)) {
      toast({
        id,
        title:"Company Details Updated",
        position: "top",
        status: "success",
        duration: 2000,
        isClosable: true,
      });
    }
    onClose();
    getCompanyDetailDetails();
    
  })
  .catch((error) => {
    console.error(error);

    if (!toast.isActive(id)) {
      toast({
        id,
        title: "ERROR",
        description: `Company Details not Updated`,
        position: "top",
        status: "error",
        duration: 2000,
        isClosable: true,
      });
    }
  });
  }

  console.log(LogoPath);

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray.500-800">Company Detail</h1>
      </div>

      <div className="bg-white w-fit rounded-2xl drop-shadow-md mb-4 p-4">
        <div className="text-right">
          <Button onClick={onOpen} colorScheme="purple" leftIcon={<LuEdit3/>} variant={'link'} >Edit</Button>
        </div>
        <div className="flex flex-wrap gap-12 align-middle items-center">
          <div>
            <Avatar size="2xl" name={CompanyName} src={LogoPath} />
          </div>

          <div className="space-y-1 ">
            <h6 className="font-medium">
              Company Name :
              <span className="font-normal ml-2">{CompanyName}</span>
            </h6>
            <h6 className="font-medium">
              Company Code :
              <span className="font-normal ml-2">{CompanyCode}</span>
            </h6>
            <h6 className="font-medium">
            Company Type : <span className="font-normal ml-2">{CompanyType}</span>
            </h6>
          </div>

          <div className="space-y-1">
            <h6 className="font-medium">
              Contact No :
              <span className="font-normal ml-2">{CompanyPhone}</span>
            </h6>
            <h6 className="font-medium">
              Address :
              <span className="font-normal ml-2">{CompanyAddress}</span>
            </h6>
            <h6 className="font-medium text-gray-50">
              -
            </h6>
          </div>

        </div>
      </div>


      <Modal size={'xl'} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
           textColor={"gray.700"}
           fontSize="lg"
           backgroundColor={"purple.200"}
           roundedTop={"3xl"}
           roundedBottom={"xl"}
           fontWeight="bold"
          >Edit Company Details</ModalHeader>
          <ModalCloseButton className="mt-1.5" />

          <form onSubmit={SaveCompanyDetails}>
          <ModalBody>

          <div className="grid grid-cols-2 gap-6 mb-2">

<div className="space-y-2 ">
    <label>Company Name</label>
    <Input
      value={CompanyName || ""}
      onChange={({target})=>{setCompanyName(target.value)}}
      borderColor="gray.500"
      placeholder="Enter Company Name"
    />
  </div>
  <div className="space-y-2 ">
    <label>Company Code</label>
    <Input
      value={CompanyCode || ""}
      onChange={({target})=>{setCompanyCode(target.value)}}
      borderColor="gray.500"
      placeholder="Enter Company Code"
    />
  </div>


  <div className="space-y-2 ">
    <label>Company Type</label>
    <Input
      value={CompanyType || ""}
      onChange={({target})=>{setCompanyType(target.value)}}
      borderColor="gray.500"
      placeholder="Enter Company Type"
    />
  </div>

  <div className="space-y-2 ">
    <label>Company Logo</label>
    <Input 
      type={'file'}
      onChange={({target})=>{setCompanyLogo(target.files[0])}}
      borderColor="gray.500"
      placeholder="Enter Company Type"
    />
  </div>


  <div className="space-y-2 ">
    <label>Company Phone</label>
    <Input
      value={CompanyPhone || ""}
      onChange={({target})=>{setCompanyPhone(target.value)}}
      borderColor="gray.500"
      placeholder="Enter Company Phone Number"
    />
  </div>



          </div>
          <div className="space-y-2">
  <label>Company Address</label>
  <Textarea
    value={CompanyAddress || ""}
    onChange={({target})=>{setCompanyAddress(target.value)}}
    borderColor="gray.500"
    placeholder="Enter Company Address"
  />
</div>
            
          </ModalBody>
          <hr />
          <ModalFooter>
            <Button colorScheme='purple' variant={'outline'} mr={3} onClick={onClose}>
              Cancel
            </Button>
            <Button type="submit" colorScheme='purple'>Save</Button>
          </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default CompanyDetail;
