import { Button, useToast } from "@chakra-ui/react";
import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import DatePicker from "react-datepicker";
import moment from "moment";

function AdvanceConfirmedPayments() {
    
    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = "toast";

    const [PaymentDataMonth, setPaymentDataMonth] = useState(new Date());
    const gridRef = useRef();
    const [ConfirmedPayRowData, setConfirmedPayRowData] = useState([]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

    const [ConfirmedPayColumnDefs] = useState([
            {
              headerName: "Emp Code",
              field: "empCode",
            },
            {
              headerName: "Emp Name",
              field: "empName",
            },
            {
              headerName: "Purpose",
              field: "pT_Purpose",
            },
            {
              headerName: "Payment Date",
              field: "paymentDate",
              cellRenderer:(params) => <span>{moment(params.data.paymentDate).format('DD/MM/YYYY')}</span>
            },
            {
              headerName: 'Paid Amount',
              field: "finalAdvAmountPayment",
            },
            {
              headerName: "Payment Mode",
              field: "paymentMethod",
              editable:true, singleClickEdit: true,
              cellEditor: 'agSelectCellEditor',
              cellEditorParams: {
                values: ['Online', 'Cheque', 'Cash','FNF','Payroll'],
                } ,cellClass:'editable-grid-cell'
            },
            {
              headerName: "Remarks",
              field: "remark",
              editable:true, singleClickEdit: true,cellClass:'editable-grid-cell'
            },
            {
              headerName: "Action", floatingFilter : false,
              cellRenderer:(params) => params.data.isLock === false ?
              <Button onClick={async()=>{

                let RowData = params.data;


                    let CnfrmBody = {
                        ptAdvMId : RowData.ptAdvMId,
                        empGuId : RowData.empGuId,
                        finalAdvAmountPayment : RowData.finalAdvAmountPayment,
                        paymentStatus : "Done",
                        processByGuId : userData.guId,
                        advPayMonth : new Date(PaymentDataMonth).getMonth()+1,
                        advPayYear : new Date(PaymentDataMonth).getFullYear(),
                        companyId : userData.companyId,
                        branchId : userData.isActiveBranch,
                        paymentMethod : RowData.paymentMethod,
                        paymentDate : RowData.paymentDate,
                        advPaymentUpdationDate : moment().format('YYYY-MM-DD'),
                        remark : RowData.remark,
                        paymentNo : RowData.paymentNo,
                        empName : RowData.empName,
                        empCode : RowData.empCode,
                        pT_Purpose : RowData.pT_Purpose
                      }


                  console.log("New Payment Body",CnfrmBody);



                  await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/PettyCash/FinalizePaymentAdvanceSingle`,CnfrmBody
                  )
                  .then((response) => {
                    console.log(response);
                    const res = response.data;
                    //console.log("res",res);
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title:res,
                        position: 'top',
                        status: 'info',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
        
                  })
                  .catch((error) => {
                    console.log(error.response);

                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: 'ERROR',
                        description: "Payment not Updated.",
                        position: 'top',
                        status: 'error',
                        duration: 2000,
                        isClosable: true,
                      })
                    }
                  });


              }} colorScheme={'blue'} size={'xs'} >Save</Button>  : ''

            },
        ]);
  
       // Table Pagination
       const paginationNumberFormatter = useCallback((params) => {
         return "[" + params.value.toLocaleString() + "]";
       }, []);
      
       //Table Columns Properties Applies to All Columns
       const defaultColDef = useMemo(() => ({
         flex: 1,
         sortable: true,
         filter: "agTextColumnFilter",
         floatingFilter: true,
         cacheQuickFilter: true,
         cellClass: 'no-border',
         resizable:true
       }));

       useEffect(() => {
        GetConfirmedPaymentList(new Date(PaymentDataMonth).getMonth()+1,new Date(PaymentDataMonth).getFullYear());
       }, [])
       

       const GetConfirmedPaymentList = async (MONTH,YEAR) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GETAdvancePaymentReport?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}`
            )
            console.log("Confirmed Payment List",response);

            const res = response.data;
            setConfirmedPayRowData(res);
        } catch (error) {
            console.error(error);
        }
    }


  return (
    <div>
            <div className="border-b flex items-center justify-between border-slate-400 pb-2 mb-4">
    <h1 className="text-xl font-bold text-gray-800">Confirmed Advance Payments</h1>
    <div>
        <DatePicker
        className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
        selected={PaymentDataMonth}
        onChange={(date) => {
          setPaymentDataMonth(date);
          GetConfirmedPaymentList(new Date(date).getMonth()+1,new Date(date).getFullYear());
        }}
        dateFormat="MM/yyyy"
        showMonthYearPicker
        placeholderText="Select Month"
      />
        </div>
  </div>


  <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={ConfirmedPayRowData} // Row Data for Rows
          columnDefs={ConfirmedPayColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>
    </div>
  )
}

export default AdvanceConfirmedPayments