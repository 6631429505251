import React, { useState, useRef, useMemo, useCallback } from "react";
import { Accordion, AccordionButton, AccordionIcon, AccordionPanel, AccordionItem, Input, Button, Switch } from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";
import useFetch from "../Utils/useFetch";
import usePost from "../Utils/usePost";

function DepartmentMaster() {
  const userData = JSON.parse(localStorage.getItem("user"));

  const { FetchedData, Refetch } = useFetch(
    '/api/Master/DepartmentMasterList', {
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch
    }
  );
  
  const { IsPostLoading, PostData } = usePost('/api/Master/SaveDepartmentMaster');

  const [DepartmentDetails,setDepartmentDetails] = useState({
    departmentId: 0,
    departmentName: '',
    isActive: true,
    companyId: userData.companyId,
    branchId: userData.isActiveBranch,
  })

  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
    {
      headerName: "Department Name",
      field: "departmentName",
    },

    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          onChange={({ target }) => {
            HandleDepartmentSwitch(target.checked, params.data);
          }}
          size="md"
        />
      ),
    },
    {
      headerName: "Action",
      field: "guId",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              setDepartmentDetails((prev) => {
                return {
                  ...prev,
                  departmentId: params.data.departmentId,
                  departmentName: params.data.departmentName,
                  isActive: true,
                  companyId: userData.companyId,
                  branchId: userData.isActiveBranch,
                }
              })
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
            leftIcon={<Edit size="14px" />}
          >
            Edit
          </Button>
        </div>
      ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    resizable: true
  }));


  const HandleDepartmentSwitch = async (checked, departData) => {

    let body = {
      departmentId: departData.departmentId,
      srNo: departData.srNo,
      departmentName: departData.departmentName,
      isActive: checked,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    console.log(body);

    const response = await PostData(body);

    if(response){
      Refetch();
    }

  };

  const SaveDepartmentMaster = async (e) => {
    e.preventDefault();
    let body = DepartmentDetails
    console.log(body);

    const response = await PostData(body);

    if(response){
      Refetch();
      setDepartmentDetails({
        departmentId: 0,
        departmentName: '',
        isActive: true,
        companyId: userData.companyId,
        branchId: userData.isActiveBranch,
      })
    }

  };

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Department Master</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Department
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveDepartmentMaster}>
                <div className="space-y-2 w-1/3">
                  <label>Add Department Name</label>
                  <Input
                    isRequired
                    value={DepartmentDetails.departmentName || ""}
                    onChange={({ target }) => {
                      setDepartmentDetails((prev) => {
                        return {
                          ...prev,
                          departmentName :target?.value
                        }
                      })
                    }}
                    borderColor="gray"
                    placeholder="Add Department Name"
                  />
                </div>

                <div className="space-x-3 my-2 text-right">
                  <Button
                    onClick={() => {
                      setDepartmentDetails({
                        departmentId: 0,
                        departmentName: '',
                        isActive: true,
                        companyId: userData.companyId,
                        branchId: userData.isActiveBranch,
                      })
                    }}
                    type="reset"
                    variant="outline"
                    colorScheme="purple"
                  >
                    Reset
                  </Button>
                  <Button isLoading={IsPostLoading} loadingText="Saving..." type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={FetchedData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default DepartmentMaster;
