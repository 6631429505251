import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation } from "react-router-dom";
import Login from "./Login";
import MainRoutes from "./MainRoutes";
import Header from "./UI Components/Header";
import CreateCompany from "./CreateCompany";
import CreateBranch from "./CreateBranch";
import ForgotPassword from "./ForgotPassword";
import IsPasswordReset from "./IsPasswordReset";
import FinalSidebar from "./UI Components/FinalSidebar";
import Checkout from "./OtherComponents/Checkout";
import CheckoutOutlet from "./OtherComponents/CheckoutOutlet";
import HelpSidebar from "./UI Components/HelpSidebar";
import HelpHeader from "./UI Components/HelpHeader";
import HelpDeskOutlet from "./UI Components/HelpDeskOutlet";
import HelpDescription from "./UI Components/HelpDescription";
import RaiseIssue from "./Utils/RaiseIssue";

function App() {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const location = useLocation();
  

  useEffect(() => {
    document.querySelector("html").style.scrollBehavior = "auto";
    window.scroll({ top: 0 });
    document.querySelector("html").style.scrollBehavior = "";
  }, [location.pathname]);


  const [messages, setMessages] = useState([]);

  const handleUserInput = (userMessage) => {
    // Process user message and add to messages state
    setMessages([...messages, { text: userMessage, sender: 'user' }]);

    // Simulate bot response (in a real scenario, you would send the user's input to a server for processing)
    setTimeout(() => {
      const botResponse = 'Thanks for reporting the issue. Please provide more details.';
      setMessages([...messages, { text: botResponse, sender: 'bot' }]);
    }, 1000);
  };

  return (
    <div>
      {location.pathname === "/" ||
      location.pathname === "/forgotpassword" ||
      location.pathname === "/createcompany" ||
      location.pathname === "/createbranch" ||
      location.pathname === "/changepassword" ||
      location.pathname === "/register" ||
      location.pathname.includes("/checkout") ? (
        <Routes>
          <Route exact path="/" element={<Login />} />
          {/* <Route exact path="/register" element={<Register />} /> */}
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/createcompany" element={<CreateCompany />} />
          <Route exact path="/changepassword" element={<IsPasswordReset />} />
          <Route exact path="/createbranch" element={<CreateBranch />} />
          <Route exact path="/checkout" element={<CheckoutOutlet />}>
            <Route exact path=":planId" element={<Checkout />} />
          </Route>
        </Routes>
      ) : location.pathname.includes("/help/") ? (
        <div className="flex h-screen bg-slate-300 overflow-hidden">
          <HelpSidebar />
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <HelpHeader />
            <main className="no-scrollbar overflow-auto">
              <div className="px-3 pt-6  pb-10 w-full max-w-10xl mx-auto">
                <Routes>
                  <Route exact path="/help" element={<HelpDeskOutlet />}>
                    <Route
                      exact
                      path=":categoryId/:subCategoryId"
                      element={<HelpDescription />}
                    />
                  </Route>
                </Routes>
              </div>
            </main>
          </div>
        </div>
      ) : (
        <div className="flex h-screen bg-slate-300 overflow-hidden">
          <FinalSidebar
            sidebarOpen={sidebarOpen}
            setSidebarOpen={setSidebarOpen}
          />
          <div className="relative flex flex-col flex-1 overflow-y-auto overflow-x-hidden">
            <Header sidebarOpen={sidebarOpen} setSidebarOpen={setSidebarOpen} />
            <main className="no-scrollbar overflow-auto">
              <div className="px-3 pt-6  pb-10 w-full max-w-10xl mx-auto">
                <MainRoutes />
              </div>
            </main>
          </div>
          <RaiseIssue/>

        </div>
      )}
    </div>
  );
}

export default App;
