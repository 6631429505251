import axios from 'axios';
import React,{ useEffect,useState } from 'react'
import { useParams } from 'react-router-dom';
import ReactHtmlParser from 'react-html-parser';

const HelpDescription = () => {

    const UrlData = useParams();
    const [HelpGuideContent, setHelpGuideContent] = useState();

    useEffect(()=>{
        GetHelpGuideData();
    },[UrlData])

    const GetHelpGuideData = async () => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/HelpGuide/GetHelpGuideContent?HelpGuideCategoryId=${UrlData.categoryId}&HelpGuideSubCategoryId=${UrlData.subCategoryId}`
            )
            console.log("Help Guide Data",response);
            setHelpGuideContent(response?.data[0]?.content)
        } catch (err) {
            console.error(err);
            setHelpGuideContent('No Content Found')
        }
    }

   return (
    <div>
       <div className="px-10 py-5 bg-white rounded-2xl drop-shadow-md">
         <div className='prose max-w-none'>{ReactHtmlParser(HelpGuideContent)}</div>
       </div>
    </div>
  )
}

export default HelpDescription