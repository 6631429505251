import { Radio, RadioGroup, Stack, Button, useDisclosure, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, Modal, ModalOverlay, useToast } from "@chakra-ui/react";
import axios from "axios";
import React from "react";
import { useState, useRef, useMemo, useEffect, useCallback } from "react";
import DatePicker from "react-datepicker";
import { AgGridReact } from "ag-grid-react";
import { Download, Eye } from "react-feather";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowTurnUp } from "@fortawesome/free-solid-svg-icons";

const PayslipReports = () => {
    const userData = JSON.parse(localStorage.getItem("user"));
  
    const toast = useToast();
    const id = "toast";
  
    const {
      isOpen: isViewPaySlipOpen,
      onOpen: onViewPaySlipOpen,
      onClose: onViewPaySlipClose,
    } = useDisclosure();
  
    const [SelectedMonth, setSelectedMonth] = useState(new Date());
    const [PdfPath, setPdfPath] = useState();
     
    const [ShowEmployees, setShowEmployees] = useState("1");
  
    const [PayslipSelectedEmp, setPayslipSelectedEmp] = useState([]);
  
    const gridRef = useRef();
    const [AllEmpList, setAllEmpList] = useState([]);
  
    const [FilteredPaySlipRowData, setFilteredPaySlipRowData] = useState([]);
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [PayslipsColumnDefs, setPayslipsColumnDefs] = useState([
      {
        headerName: "Emp Code",
        field: "empCode",
        headerCheckboxSelection: true,
        checkboxSelection: true,
      },
      {
        headerName: "Emp Name",
        field: "empName",
      },
      {
        headerName: "Payslip Month",
        field: "month",
        cellRenderer: (params) => (
          <span>
            {params.data.month}/{params.data.year}
          </span>
        ),
      },
      {
        headerName: "Released for Employee",
        field: "isActive",
        cellRenderer: (params) =>
          params.data.isActive ? (
            <span className="px-1.5 py-0.5 rounded-md bg-green-600 font-medium text-white">
              Released
            </span>
          ) : (
            <span className="px-1.5 py-0.5 rounded-md bg-red-600 font-medium text-white">
              Not Released
            </span>
          ),
      },
      {
        headerName: "Shared via E-mail",
        field: "isShared",
        cellRenderer: (params) =>
          params.data.isShared ? (
            <span className="px-1.5 py-0.5 rounded-md bg-green-600 font-medium text-white">
              Shared
            </span>
          ) : (
            <span className="px-1.5 py-0.5 rounded-md bg-red-600 font-medium text-white">
              Not Shared
            </span>
          ),
      },
      {
        headerName: "Action",
        field: "departmentName",
        floatingFilter: false,
        cellRenderer: (params) => (
          <div className="space-x-4">
            <Button
              leftIcon={<Eye size={"14px"} />}
              colorScheme="blue"
              variant="solid"
              size={"xs"}
              onClick={() => {
                handlePayslipFile(params.data.filePath, "View");
              }}
            >
              View
            </Button>
            <Button
              leftIcon={<Download size={"14px"} />}
              colorScheme="blue"
              variant="outline"
              size={"xs"}
              onClick={() => {
                handlePayslipFile(params.data.filePath, "Download");
              }}
            >
              Download
            </Button>
          </div>
        ),
      },
    ]);
  
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable: true
    }));
  
    const onSelectionPayslipChanged = useCallback(() => {
      var PayslipEmpRow = gridRef.current.api.getSelectedRows();
      console.log("Payslip Selection",PayslipEmpRow);
      setPayslipSelectedEmp(PayslipEmpRow);
    }, []);
  
  
    useEffect(() => {
      if (ShowEmployees === "2") {
        setFilteredPaySlipRowData(
          AllEmpList.filter((emp) => emp.empIsActive === false)
        );
      } else {
        setFilteredPaySlipRowData(
          AllEmpList.filter((emp) => emp.empIsActive === true)
        );
      }
    }, [ShowEmployees]);
  
    useEffect(() => {
      GetAllEmpList(SelectedMonth);
    }, []);
  
    const GetAllEmpList = async (DataMonth) => {
      try {
        const response = await axios.get(
          `${
            process.env.REACT_APP_ACQ_URL
          }/api/EmpMonthlySalarySlip/GetMonthlySalarySlipList?Month=${
            new Date(DataMonth).getMonth() + 1
          }&Year=${new Date(DataMonth).getFullYear()}&CompanyId=${
            userData.companyId
          }&BranchId=${userData.isActiveBranch}`
        );
  
        console.log("Emp Slip Response", response);
  
        const res = response.data;
  
        setAllEmpList(res);
  
        if (ShowEmployees === "2") {
          setFilteredPaySlipRowData(res.filter((emp) => emp.empIsActive === false));
        } else {
          setFilteredPaySlipRowData(res.filter((emp) => emp.empIsActive === true));
        }
      } catch (err) {
        console.error(err);
      }
    };
  
    const handlePayslipFile = async (DocPath, Action) => {
      console.log(DocPath);
      await fetch(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`
      ).then((response) => {
          console.log("file Response", response);
  
          response.blob().then((blob) => {
            // Creating new object of PDF file
            const fileURL = window.URL.createObjectURL(blob);
  
            // Setting various property values
            let alink = document.createElement("a");
            alink.href = fileURL;
            if (Action === "Download") {
              alink.download = fileURL;
              alink.click();
            }
  
            if (Action === "View") {
              setPdfPath(alink);
              onViewPaySlipOpen();
            }
  
          });
        }).catch((err) => {
          console.error("File Api Error", err);
        })
    };
  
  
    const EmailGeneratedPayslips = async () => {
  
      let body = PayslipSelectedEmp.map((data)=>{
        return {
          empGuId: data.empGuid,
          empName: data.empName,
          filePath: "",
          month: data.payrollMonth,
          year: data.payrollYear,
          isActive: true,
          companyId: data.companyId,
          branchId: data.branchId
        }
      })
  
      console.log("Email Payslips Body",body);
  
      await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/EmpMonthlySalarySlip/ShareSalarySlip`,body
      ).then((response) => {
        const res = response.data;
        console.log("res",res);
    
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: 'top',
            status: 'info',
            duration: 2000,
            isClosable: true,
          })
        }
    
      })
      .catch((err) => {
        console.log(err.response);
    
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'ERROR',
            description: "Email not sent",
            position: 'top',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      });
  
    }
  
    return (
      <div>
        <div className="flex justify-between items-center mb-4">
          <div className="bg-white flex gap-2 px-2 rounded-lg py-1 drop-shadow-sm">
            <label>Employee List:</label>
            <RadioGroup onChange={setShowEmployees} value={ShowEmployees}>
              <Stack direction="row" gap={2}>
                <Radio borderColor={"gray.400"} value="1">
                  Active
                </Radio>
                <Radio borderColor={"gray.400"} value="2">
                  InActive
                </Radio>
              </Stack>
            </RadioGroup>
          </div>
  
          <div>
            <DatePicker
              className="bg-white border border-gray-400 z-60 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
              selected={SelectedMonth}
              onChange={(date) => {
                setSelectedMonth(date);
                GetAllEmpList(date);
              }}
              maxDate={new Date()}
              dateFormat="MM/yyyy"
              showMonthYearPicker
              placeholderText="Select Month"
            />
          </div>
        </div>
  
        <div className="px-5 flex py-3 space-x-5 ">
          <div className="my-auto">
            <FontAwesomeIcon
              className="rotate-180 text-xl"
              icon={faArrowTurnUp}
            />
          </div>
          <Button isDisabled={PayslipSelectedEmp.length === 0 ? true : false} onClick={()=>{EmailGeneratedPayslips()}} variant={"outline"} colorScheme="purple">
            Send Payslips by E-mail
          </Button>
        </div>
  
        <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={FilteredPaySlipRowData} // Row Data for Rows
            columnDefs={PayslipsColumnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            rowSelection="multiple"
            paginationPageSize={20}
            paginationNumberFormatter={paginationNumberFormatter}
            onSelectionChanged={onSelectionPayslipChanged}
          />
        </div>
  
        <Modal
          size="full"
          scrollBehavior="inside"
          isOpen={isViewPaySlipOpen}
          onClose={onViewPaySlipClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>View Payslip</ModalHeader>
            <ModalCloseButton />
            <hr />
            <ModalBody>
              <div id="pdf-div">
                <embed
                  src={PdfPath + "#toolbar=0"}
                  type="application/pdf"
                  className="h-screen w-full !select-none"
                  height={800}
                  width={500}
                />
              </div>
            </ModalBody>
            <hr />
  
            <ModalFooter>
              <Button colorScheme="purple" onClick={onViewPaySlipClose}>
                Close
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </div>
    );
  };

export default PayslipReports