import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import axios from "axios";
import moment from "moment";
import { Button } from "@chakra-ui/react";
import { Link } from "react-router-dom";
import { AgGridReact } from "ag-grid-react";
import { Download, Eye } from "react-feather";
import authApi from "../Utils/AuthApis";

function AllTransactions() {
  const userData = JSON.parse(localStorage.getItem("user"));

  const [ActiveSubscription, setActiveSubscription] = useState([]);
  const [AttachmentPath, setAttachmentPath] = useState();


  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [rowData, setRowData] = useState([
    // {
    //   transactionDate : '2022-04-28',
    //   planName : 'Startup',
    //   amount : 0,
    //   currency : 'USD'
    // },
    // {
    //   transactionDate : '2023-05-01',
    //   planName : 'Medium Business',
    //   amount : 100,
    //   currency : 'USD'
    // }
  ]); 

  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Transaction Date",
      field: "paymentDate",
      cellRenderer:(params) => moment(params.data.paymentDate).format('DD/MM/YYYY')
    },
    {
      headerName: "Plan",
      field: "planName"
    },
    {
      headerName: "Amount",
      field: "paidAmount",
      cellRenderer:(params) => <span>{params.data.currency === 'USD' ? '$' : '₹'} {params.data.paidAmount * 0.01}</span>
    },
    // {
    //   headerName: "Action",
    //   cellRenderer:(params) => <div className="space-x-4">
    //     <Button onClick={()=>{setAttachmentPath(params.data.currency)}} leftIcon={<Eye size={'14px'} />} size={'xs'} variant={'outline'} colorScheme="purple" >View</Button>
    //     <Button onClick={()=>{setAttachmentPath(params.data.currency)}} leftIcon={<Download size={'14px'} />} size={'xs'} variant={'solid'} colorScheme="purple" >Download</Button>
    //   </div>
    // },
  ]);

  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border"
  }));

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  useEffect(() => {
    UpdatePlanDetails();
  }, []);


  const UpdatePlanDetails = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Plan/UpdateSubscritpionData?CompanyId=${userData.companyId}`
      )
      console.log("Plan Update Res",response);
      GetUserSubscription();
      GetUserTransactions();
      GetPlanMasterAll();
    }catch (err){
      console.error(err);
    }
  }

  const GetUserSubscription = async () => {
    try{
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/SuperAdmin/GetUserSubscriptionByEmail?EmailId=${userData.userName}`
      );
      console.log(response);
      setActiveSubscription(response.data);
    }catch(error){
      console.error(error);
    }
  };



  const GetUserTransactions = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Razorpay/GetTransactionByEmail?UserName=${userData.userName}`
      )
      console.log("Transaction Response",response);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  }


  const GetPlanMasterAll = async () => {
    try {
      const response = await authApi.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Plan/GetPlanMasterAll`
      )
      console.log("Plan Master All",response);
    } catch (error) {
      console.error("Plan Master Error",error);
      // if (error.response && error.response.status === 401) {
      //   alert('Token Expired!! Please Login Again.')
      //   window.location.href ='/';
      //   localStorage.removeItem('user');
      //   localStorage.removeItem('token');
      // }
    }
  }

  return (
    <div>
      <div className="bg-white drop-shadow-md rounded-xl p-4 mb-4 max-w-2xl">
        <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-2">
          <h1 className="text-lg font-bold text-gray-800">My Plan</h1>
        </div>
        
        <div className="grid grid-cols-2">
            <div className="flex gap-1 items-center">
              <h2 className="font-medium text-gray-500">Active Plan :</h2>
              <h6 className="font-medium">{ActiveSubscription.planName}</h6>
            </div>
            <div className="flex gap-1 items-center">
              <h2 className="font-medium text-gray-500">Employees :</h2>
              <h6 className="font-medium">{ActiveSubscription.currentUsers}/{ActiveSubscription.uptoUsers}</h6>
            </div>
            <div className="flex gap-1 items-center">
              <h2 className="font-medium text-gray-500">Purchase Date :</h2>
              <h6 className="font-medium">{moment(ActiveSubscription.purchaseDate).format('DD/MM/YYYY')}</h6>
            </div>
            <div className="flex gap-1 items-center">
              <h2 className="font-medium text-gray-500">Due Date :</h2>
              <h6 className="font-medium">{moment(ActiveSubscription.dueDate).format('DD/MM/YYYY')}</h6>
            </div>
          </div>

          <div className="space-x-4 mt-4 text-right">
          <Link to={`/upgradeplan/1/${ActiveSubscription.planRefId}`}>
          <Button colorScheme="purple">Renew</Button>
          </Link>
          <Link to={`/viewsubscription`}>
          <Button variant={'outline'} colorScheme="purple">Upgrade Plan</Button>
          </Link>
          </div>
      </div>

      <div className="ag-theme-alpine">
          <AgGridReact
            style={gridStyle}
            domLayout={"autoHeight"}
            ref={gridRef} // Ref for accessing Grid's API
            rowData={rowData} // Row Data for Rows
            columnDefs={columnDefs} // Column Defs for Columns
            defaultColDef={defaultColDef} // Default Column Properties
            animateRows={true} // Optional - set to 'true' to have rows animate when sorted
            pagination={true}
            rowSelection="single"
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter}
          />
        </div>

    </div>
  )
}

export default AllTransactions