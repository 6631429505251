import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import {  Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Button, useDisclosure, useToast, Textarea } from "@chakra-ui/react";
import { CheckCircle, Edit } from "react-feather";
import axios from "axios";
import moment from "moment";
import DatePicker from "react-datepicker";

function ProbationEmployees() {

    const userData = JSON.parse(localStorage.getItem('user'));
    const toast = useToast();
    const id = "toast";
     
    const {
        isOpen: isExtendProbationOpen,
        onOpen: onExtendProbationOpen,
        onClose: onExtendProbationClose,
      } = useDisclosure();

    const [ProbationStartDate, setProbationStartDate] = useState();
    const [ProbationEndDate, setProbationEndDate] = useState();
    const [ExtendReason, setExtendReason] = useState();

    const [SelectedEmp, setSelectedEmp] = useState([]);
    const gridRef = useRef();
    const [rowData, setRowData] = useState([ ]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs] = useState([
        {
          headerName: "Emp Code",
          field: "empCode",
        },
        {
          headerName: "Emp Name",
          field: "fullName",
        },
        {
            headerName: "Designation",
            field: "designationName",
          },
        // {
        //   headerName: "Department",
        //   field: "departmentName",
        // },
        {
          headerName: "Date of Joining",
          field: "dateofjoin",
          cellRenderer:(params) =><span>
            {moment(params.data.dateofjoin).format('DD/MM/YYYY')}
          </span>
        },
          {
            headerName: "Probation Date",
            field: "probationDate",
            valueGetter:(params) => `${moment(params.data.probationStartDate).format('DD/MM/YYYY')} - ${moment(params.data.probationEndDate).format('DD/MM/YYYY')}`
          },
          {
            headerName: "Action",
            width: 210,
            cellRenderer:(params) =><div className="space-x-3">
                <Button 
                 onClick={async()=>{
                    let body = {
                        guId:params.data.guId,
                        probationStartDate:params.data.probationStartDate,
                        probationEndDate:params.data.probationEndDate,
                        probationRemark:'Confirmed',
                        isProbationEnd:true,
                        companyId:userData.companyId,
                        branchId:userData.isActiveBranch
                    }
    
                    console.log("Confirm Save Body",body);
    
                            
                    await axios.post(
                        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmpProbationInfo`,body
                      )
                      .then((response) => {
                        console.log(response);
                        const res = response.data;
                        if (!toast.isActive(id)) {
                          toast({
                            id,
                            title:res,
                            position: "top",
                            status: "success",
                            duration: 2000,
                            isClosable: true,
                          });
                        }

                        getEmpList();

                      })
                      .catch((error) => {
                        console.error(error);
              
                        if (!toast.isActive(id)) {
                          toast({
                            id,
                            title: "ERROR",
                            description: `Probation not Extended`,
                            position: "top",
                            status: "error",
                            duration: 2000,
                            isClosable: true,
                          });
                        }
                      });
                 }}
                 leftIcon={<CheckCircle size='14px' />} size={'xs'} colorScheme={'green'}>Confirm</Button>
                <Button onClick={()=>{
                    setProbationStartDate(new Date(params.data.probationStartDate))
                    setProbationEndDate(new Date(params.data.probationEndDate))
                    setSelectedEmp(params.data);
                    setExtendReason(params.data.probationRemark);
                    onExtendProbationOpen();
                }} variant={'outline'} leftIcon={<Edit size='13px' />} size={'xs'} colorScheme={'orange'}>Extend</Button>
            </div>
          },
      ]);
  
            // Table Pagination
            const paginationNumberFormatter = useCallback((params) => {
              return "[" + params.value.toLocaleString() + "]";
            }, []);
          
            //Table columns properties applies to all columns
            const defaultColDef = useMemo(() => ({
              flex: 1,
              sortable: true,
              filter: "agTextColumnFilter",
              floatingFilter: true,
              cacheQuickFilter: true,
              cellClass: 'no-border',
              resizable:true
            }));


            useEffect(() => {
                getEmpList();
            }, [])
            

            const getEmpList = async () => {
                try {
                  const response = await axios.get(
                    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
                  )
                  console.log(response);
                  const res = response.data;

                  console.log("Probation Employees",res);
                  setRowData(res.filter(data => data.isProbationEnd === false && data.isActive === true));
                } catch (error) {
                  console.error(error);
                }
              }


              const SaveExtendProbation = async (e) => {
                e.preventDefault();

                let body = {
                    guId:SelectedEmp.guId,
                    probationStartDate:ProbationStartDate,
                    probationEndDate:ProbationEndDate,
                    probationRemark:ExtendReason,
                    isProbationEnd:false,
                    companyId:userData.companyId,
                    branchId:userData.isActiveBranch
                }

                console.log("Extend Save Body",body);

                        
                await axios.post(
                    `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/SaveEmpProbationInfo`,body
                  )
                  .then((response) => {
                    console.log(response);
                    const res = response.data;
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title:res,
                        position: "top",
                        status: "success",
                        duration: 2000,
                        isClosable: true,
                      });
                    }

                    getEmpList();
                    onExtendProbationClose();
                    setExtendReason('');

              
                  })
                  .catch((error) => {
                    console.error(error);
          
                    if (!toast.isActive(id)) {
                      toast({
                        id,
                        title: "ERROR",
                        description: `Probation not Extended`,
                        position: "top",
                        status: "error",
                        duration: 2000,
                        isClosable: true,
                      });
                    }
                  });

              }

  return (
    <div>
       <div className="border-b flex justify-between border-slate-400 pb-2 mb-4">
          <h1 className="text-lg font-bold text-gray-800">
            Employees in Probation
          </h1>
        </div>

        <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>


      <Modal
          size="3xl"
          isOpen={isExtendProbationOpen}
          onClose={onExtendProbationClose}
        >
          <ModalOverlay />
          <ModalContent rounded={"3xl"}>
            <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Extend Probation</ModalHeader>
            <ModalCloseButton mt={1}/>
            <form onSubmit={SaveExtendProbation}>
              <ModalBody>
                <div className="flex py-2 gap-8">
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Employee Name</p>
                    <h6 className="font-medium">{SelectedEmp.fullName}</h6>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Employee Code</p>
                    <h6 className="font-medium">{SelectedEmp.empCode}</h6>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Designation</p>
                    <h6 className="font-medium">
                      {SelectedEmp.designationName}
                    </h6>
                  </div>
                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Department</p>
                    <h6 className="font-medium">
                      {SelectedEmp.departmentName}
                    </h6>
                  </div>

                  <div className="space-y-1">
                    <p className="text-sm text-gray-500">Date of Joining</p>
                    <h6 className="font-medium">
                      {moment(SelectedEmp.dateofjoin).format('DD/MM/yyyy')}
                    </h6>
                  </div>

                </div>
                <hr />

                <div className="grid grid-cols-2 gap-4 my-2">
                    <div className="space-y-2">
                        <label>Probation Start Date</label>
                          <DatePicker 
                             required
                             readOnly
                             className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                             selected={ProbationStartDate}
                             onChange={(date) => setProbationStartDate(date)}
                             placeholderText="Probation Start Date"
                             dateFormat={'dd-MM-yyyy'}
                           />
                    </div>
                    <div className="space-y-2">
                        <label>Probation End Date</label>
                        <DatePicker
                          required
                           className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                           selected={ProbationEndDate}
                           onChange={(date) => setProbationEndDate(date)}
                           placeholderText="Probation Start Date"
                           dateFormat={'dd-MM-yyyy'}
                         />
                    </div>

                </div>

                <div className="space-y-2">
                    <label>Reason</label>
                    <Textarea isRequired value={ExtendReason} onChange={({target})=>{setExtendReason(target.value)}} borderColor={'gray.400'} placeholder='Enter Reason for Extending Probation' />
                </div>

              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button variant="solid" colorScheme="purple" type="submit">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    onClick={() => {
                      onExtendProbationClose();
                    }}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>

    </div>
  )
}

export default ProbationEmployees