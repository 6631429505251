import { AgGridReact } from "ag-grid-react";
import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import axios from "axios";
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Select,
  Checkbox,
  Input,
  Tooltip,
  InputGroup,
  InputRightAddon,
  useToast,
  InputLeftAddon,
  Textarea,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,Box
} from "@chakra-ui/react";
import { faPlus, faDownload, faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { XCircle } from "react-feather";
import ReactSelect from 'react-select';
import _ from 'lodash';
import moment from "moment";

function AddExpenseAndClaim() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const ClaimRef = useRef();
  const date = new Date();

  const [ViewClaimAttachment, setViewClaimAttachment] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure()

  const FileRef = useRef();

  const reset = () => {
    FileRef.current.value = "";
  };

  const [EmpList, setEmpList] = useState([]);
  const [DepartmentList, setDepartmentList] = useState([])
  const [SelectedDepartment, setSelectedDepartment] = useState([]);

  const HandleDepartmentChange = async (DeptID) =>{

    try {

      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Employee List",response.data);

      const res = response.data;

      let departmenetViseEmp = res.filter(data =>  data.department === String(DeptID) && data.isSalaryStructureCompleted === 2)

      setEmpList(departmenetViseEmp);
      
    } catch (error) {
      console.error(error)
    }


  }

  const currentDate = date.toLocaleDateString("en-CA");
  console.log(currentDate);

  let CurrentYear = new Date().getFullYear();
  // console.log("Current Year", CurrentYear);

  let CurrentDateMonth = new Date().getMonth() + 1;
  // console.log("Current Month",CurrentMonth);


  const [ApprovedClaimList, setApprovedClaimList] = useState([]);
  const [ClaimAmountDetails, setClaimAmountDetails] = useState([]);
  const id = "toast";
  const gridRef = useRef();
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const {
    isOpen: isAddReimbursementOpen,
    onOpen: onAddReimbursementOpen,
    onClose: onAddReimbursementClose,
  } = useDisclosure();
  const {
    isOpen: isAddReimbursementTypeOpen,
    onOpen: onAddReimbursementTypeOpen,
    onClose: onAddReimbursementTypeClose,
  } = useDisclosure();

  const {
    isOpen: isReviewClaimOpen,
    onOpen: onReviewClaimOpen,
    onClose: onReviewClaimClose,
  } = useDisclosure();

  const [ReimbursementName, setReimbursementName] = useState();
  const [ReimbursementTypeList, setReimbursementTypeList] = useState([]);
  const [active, setActive] = useState(true);

  const [PettyCashTypeName, setPettyCashTypeName] = useState();
  const [PettyCashTypeMaxlimit, setPettyCashTypeMaxlimit] = useState();

  const [ClientList, setClientList] = useState([]);
  const [ClientName, setClientName] = useState();
  const [ReviewClaimRowData, setReviewClaimRowData] = useState([]);
  const [PayableAmount, setPayableAmount] = useState();
  const [ReasonforLessApproval, setReasonforLessApproval] = useState();
  const [SelectedEmpClaim, setSelectedEmpClaim] = useState([]);

  const [EmployeeReimburseRowData, setEmployeeReimburseRowData] = useState([]);
  const [TicketId, setTicketId] = useState();
  const [ClaimDate, setClaimDate] = useState(moment().format('YYYY-MM-DD'));
  const [ClaimNumber, setClaimNumber] = useState();
  const [BillDate, setBillDate] = useState(moment().format('YYYY-MM-DD'));
  const [ClaimAmount, setClaimAmount] = useState();
  const [BillNumber, setBillNumber] = useState();
  const [Reason, setReason] = useState();
  const [Attachment, setAttachment] = useState(null);
  const [ReimbursementType, setReimbursementType] = useState();

  const [SelectedEmp, setSelectedEmp] = useState([]);
  useEffect(() => {
    // getEmp();
    // getReimbursementTypeList();
    getDepartmentList();
    getClientList();
    getReimbursementListOfMonth(CurrentDateMonth);
    GetExpenseTypeMasterList();
  }, []);
  //Defining Columns Defiinitions Ex-fields data,headername,etc



  const GetExpenseTypeMasterList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashTypeList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Expense Master List Response",response.data);
      setEmpList(response.data);
    } catch (error) {
      console.error(error);
    }

  }




  const [ExpensesColumnDefs, setExpensesColumnDefs] = useState([
    { headerName: "Request Date", field: "claimDate", valueGetter: (params) => {
      return new Date(params.data.claimDate).toLocaleDateString('en-GB')
    }},
    { headerName: "Bill Date", field: "billDate",valueGetter: (params) => {
      return new Date(params.data.billDate).toLocaleDateString('en-GB')
    }
   },
    

    { headerName: "Claim Amount", field: "claimedAmount" },
    {
      headerName: "Description",
      field: "reason",
      
    },

    {
      headerName: "Action",
      cellRenderer: (params) => <Button
       leftIcon={<FontAwesomeIcon icon={faDownload} />}
       onClick={()=>{handleDownloadFile(params.data.attachmentPath)}}
       colorScheme="purple"
       variant='link'
     >
       Download
     </Button>
    },
  ]);

  const [ReimbursementRowData, setReimbursementRowData] = useState([]);

  const [ReimbursementColumnDefs, setReimbursementColumnDefs] = useState([
    { headerName: "Employee Code", field: "empCode" },

    { headerName: "Employee Name", field: "employeeName" },
    // { headerName: "Ticket ID", field: "reimbursementTicketGenerate" },
    {
      headerName: "Claim Date",
      field: "claimDate",
      valueGetter: (params) => {
        return new Date(params.data.claimDate).toLocaleDateString("en-GB");
      },
    },

    { headerName: "Claim Amount", field: "claimedAmount" },
    {
      headerName: "Pay Action",
      field: "payAction",
      cellRenderer: (params) => {
        return (
          <Button
            onClick={() => {
              HandleReviewClaim(params.data.empGuId);
            }}
            size="xs"
            colorScheme="purple"
          >
            Review Claim
          </Button>
        );
      },
    },
  ]);

  const [ReviewClaimColumnDefs, setReviewClaimColumnDefs] = useState([
    { headerName: "Emp Code", field: "empCode",      headerCheckboxSelection: true,
    checkboxSelection: (params) => {
      return params.data.employeeName;
    }, },

    {
      headerName: "Emp Name",
      field: "employeeName",

    },
    { headerName: "Expense Name", field: "reimbursementName" },
    { headerName: "Ticket ID", field: "reimbursementTicketGenerate" },
    { headerName: "Requested Amount", field: "claimedAmount" },
    { headerName: "Description", field: "reason" },
    {
      headerName: "Attachment",
      field: "attachment",
      cellRenderer:(params) => params.data.attachment != null ? 
    <Button colorScheme={'blue'} size={'xs'} onClick={()=>{handleViewFile(params.data.attachment)}}>
      <FontAwesomeIcon className="cursor-pointer mr-1"  icon={faPaperclip} /> View
   </Button> : ''
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  const ReviewClaimColDef = useMemo(() => ({
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    // editable: true,
  }));


  const onFirstDataRendered = useCallback((params) => {
    ClaimRef.current.api.forEachNode((node) =>
      node.setSelected(true)
    );
  }, []);

  const onSelectionChanged = useCallback(() => {
    var ClaimData = ClaimRef.current.api.getSelectedRows();
    // console.log(ClaimData);

    setApprovedClaimList(ClaimData);

    setPayableAmount(() => {
      return ClaimData.reduce((prev, values) => {
        return parseInt(prev) + parseInt(values.claimedAmount || 0);
      }, 0);
    });
  }, []);





  const [SelectedEmpDefs, setSelectedEmpDefs] = useState([
    { headerName: "Ticket Id", field: "reimbursementTicketGenerate" },
    { headerName: "Employee Name", field: "employeeName" },
    { headerName: "Emp Code", field: "empCode" },
    { headerName: "Request Number", field: "claimNumber" },
    { headerName: "Request Date", field: "claimDate" },
    {
      headerName: "Claim Status",
      field: "claimStatus",
    },
    { headerName: "Request Amount", field: "claimedAmount" },
    { headerName: "Bill Number", field: "billNumber" },
    { headerName: "Bill Date", field: "billDate" },
    // { headerName: "Approved Amount", field: "approvedAmount" },
    { headerName: "Reason", field: "reason" },
    { headerName: "Attachment", field: "attachment" },
    // { headerName: "Action", cellRenderer:(params) => <FontAwesomeIcon onClick={onRemoveSelected}  icon={faTrash} className="text-red-600" />},
  ]);




  const getClientList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Client/GetClientInfo?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      // console.log(response);
      setClientList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getEmp = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeSalaryStatus?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
       console.log("Salary Employees",response);
      const res = response.data;

      let salarycompletedEmp = res.filter(data => data.isSalaryStructureCompleted === 2)

      setEmpList(salarycompletedEmp);

    } catch (error) {
      console.error(error);
    }
  };

  const getDepartmentList = async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/DepartmentMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Department List",response.data);
      setDepartmentList(response.data);
    } catch (error) {
      console.error(error)
    }
  }

  // Getting Leave List of Month
  const getReimbursementListOfMonth = async (MONTH) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetReimbursmentDataDetails?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${CurrentYear}`
      );
      console.log("Reimbursement response", response);
      console.log("Reibursement List of Month", response.data);
      setReimbursementRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };


  const HandleAddExpense = async () => {


    let body = {
      empGuId: SelectedEmp.empGuId,
      empCode: SelectedEmp.empCode,
      // ticketValid:false,
      ticketGenerateDate: new Date(),
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    console.log(body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveReimbursementTicket`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const res = response.data;
        // console.log(response);
        // console.log("Ticket ",response.data);
        setTicketId(res);
        GetBalanceAmountDetails(SelectedEmp.empGuId);
        GetEmpPettyType(SelectedEmp.empGuId);
        getEmployeeReimbursementData(res);
        onAddReimbursementOpen();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
      });

  }

  const handleAddReimbursement = async (employeeGuId) => {
    console.log(employeeGuId);

    let EMPSelData = [];
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeSalaryStructure/GetEmployeeDetailByEmpGuid?EmpGuid=${employeeGuId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log(response);
      EMPSelData = response.data;
      setSelectedEmp(response.data);
      GetEmpPettyType(EMPSelData.guId);
    } catch (error) {
      console.error(error);
    }

    let body = {
      empGuId: EMPSelData.guId,
      empCode: EMPSelData.empCode,
      // ticketValid:false,
      ticketGenerateDate: new Date(),
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    console.log(body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveReimbursementTicket`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        const res = response.data;
        // console.log(response);
        // console.log("Ticket ",response.data);
        setTicketId(res);
        GetBalanceAmountDetails(employeeGuId);
        // getEmployeeReimbursementData(res);
        onAddReimbursementOpen();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
      });
  };





  const GetBalanceAmountDetails = async (EmpGuid) => {

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetExpenseBalanceByGuid?EmpGuid=${EmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee Claim Amount data", response);
      let ClaimAmount = response.data;
      // console.log("TicketID",TicketID);
      setClaimAmountDetails(ClaimAmount);
    } catch (error) {
      console.error(error);
    }

  }

  const GetEmpPettyType = async (EmpGuid) => {
    console.log(EmpGuid);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetExpenseList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Emp Petty Type List", response);
      setReimbursementTypeList(response.data);
    } catch (error) {
      console.log("Emp Petty Type Error", error);
    }
  };

  const getEmployeeReimbursementData = async (EmpTicketID) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetReimbursementByTicketId?Ticket=${EmpTicketID}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee Reimbursement data", response);
      let ReimbursementData = response.data;
      // console.log("TicketID",TicketID);
      setEmployeeReimburseRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  // Adding Reimbursement Type

  const handleReimbursementType = async (e) => {
    e.preventDefault();
    let body = {
      reimbursementName: ReimbursementName,
      isActive: active,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };

    // console.log(body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveReimbursementType`,
        body
      )
      .then((response) => {
        const res = response.data;
        // console.log("res", res);
        if (res === "Success") {
          // console.log("Data added Successfully", response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "Reimbursement Type created.",
              description: "We've created Reimbursement Type for you.",
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
          onAddReimbursementTypeClose();
          // getReimbursementTypeList();
          setReimbursementName("");
          // navigate('/settings/salarycomponents');
        } else {
          // console.log("Data Not added", response);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: "Reimbursement Type not created.",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
      });
  };

  const AddReimbursementList = async (e) => {
    e.preventDefault();

    try {
      const response = await axios.get(
       `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/CheckExpenseLimit?EmpGuid=${SelectedEmp.empGuId}&month=${new Date().getMonth()+1}&Year=${new Date().getFullYear()}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Check Expense Balance Response" , response);


      const res = response.data;

      let RemainingAmount = res.remainningLimit;


      console.log("Clainm Amount",ClaimAmount, "Remaining Limit",RemainingAmount);

      if(ClaimAmount <= RemainingAmount ){

        console.log("attachment", Attachment);

        const UploadData = new FormData();
    
        UploadData.append("attachmentPath", Attachment);
        UploadData.append("claimNumber", ClaimNumber);
        UploadData.append("claimDate", ClaimDate);
        UploadData.append("empCode", SelectedEmp.empCode);
        UploadData.append("empGuId", SelectedEmp.empGuId);
        UploadData.append("reimbursementName", ReimbursementType);
        UploadData.append("billDate", BillDate);
        UploadData.append("billNumber", BillNumber);
        UploadData.append("claimedAmount", ClaimAmount);
        UploadData.append("claimStatus", "Pending");
        UploadData.append("claimAction", "Pending");
        UploadData.append("reason", Reason);
        UploadData.append("clientId", ClientName.clientId);
        UploadData.append("clientName", ClientName.clientName);
        UploadData.append("reimbursementTicketGenerate", TicketId);
        UploadData.append("companyId", userData.companyId);
        UploadData.append("branchId", userData.isActiveBranch);
    
        await axios
          .post(
            `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveReimbursement`,
            UploadData
          )
          .then((response) => {
            console.log("Responce IUpload Data", response);
            const res = response.data;
            console.log("res", res);
    
            toast({
              id,
              title: res.status,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
    
            //getReimbursementListOfMonth(CurrentDateMonth);  comment by rohit
            getEmployeeReimbursementData(TicketId);
    
            reset();
            setClaimDate(moment().format('YYYY-MM-DD'));
            setClaimNumber("");
            setBillDate(moment().format('YYYY-MM-DD'));
            setClaimAmount("");
            setBillNumber("");
            setReason("");
            setClientName("");
            setReimbursementType("");

            getReimbursementListOfMonth(CurrentDateMonth)
    
          })
          .catch((error) => {
            console.log(error.response);
            console.log(error.response.data);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: "Reimbursement Type not Approved.",
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });


      }else{
        toast({
          id,
          title: "Expense Limit Exceeded",
          description: "Please Enter the Amount Below Your Expense Limit.",
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }


    } catch (error) {
      console.error(error);
    }





  };

  const onRemoveSelected = useCallback(() => {
    const selectedData = gridRef.current.api.getSelectedRows();
    const res = gridRef.current.api.applyTransaction({ remove: selectedData });
    res.remove.forEach(function (rowNode) {
      console.log("Removed Row Node", rowNode);
    });
  }, []);

  const SaveReimbursementList = async () => {
    let body = EmployeeReimburseRowData;
    //  console.log("Save Reimbursement List Body",body);
    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveReimbursement`,
        body
      )
      .then((response) => {
        //  const res = response.data;
        // console.log(response);
        // console.log("Add Reimbursement List Response ",response.data);

        const res = response.data;
        // console.log("res",res);
        if (res === "Success") {
          //  console.log("Data added Successfully",response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "Reimbursement Added.",
              description: "We've created Reimbursement for you.",
              position: "top",
              status: "success",
              duration: 2000,
              isClosable: true,
            });
          }
          onAddReimbursementClose();
          setEmployeeReimburseRowData([]);
          getReimbursementListOfMonth();
        } else {
          // console.log("Data Not added",response);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: "ERROR",
              description: "Reimbursement Not Added.",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        }
      })
      .catch((error) => {
        console.log(error.response);
      });
  };

  const HandleReviewClaim = async (empGuId) => {
    // console.log(empGuId);

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetReimbursementTotalByEmpGuid?EmpGuid=${empGuId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee Claim Response data", response);
      let ClaimEmployee = response.data;
      setSelectedEmpClaim(ClaimEmployee);
      let SelectedEmpGuid = ClaimEmployee.empGuId;
      // console.log("TicketID",TicketID);
      getClaimDatabyTicket(SelectedEmpGuid);
    } catch (error) {
      console.error(error);
    }

    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetExpenseBalanceByGuid?EmpGuid=${empGuId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Employee Claim Amount data", response);
      let ClaimAmount = response.data;
      // console.log("TicketID",TicketID);
      setClaimAmountDetails(ClaimAmount);
    } catch (error) {
      console.error(error);
    }
  };

  const getClaimDatabyTicket = async (SelectedEmpGuid) => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/GetReimbursementListByEmp?EmpGuId=${SelectedEmpGuid}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      // console.log(response);
      console.log("Review Claim Response data", response);
      setReviewClaimRowData(response.data);
      onReviewClaimOpen();
      setPayableAmount("");
      setReasonforLessApproval("");
    } catch (error) {
      console.error(error);
    }
  };

  const SaveReimbursementApproval = async (e) => {
    e.preventDefault();
    let body = {
      empGuId: SelectedEmpClaim.empGuId,
      approvedAmount: PayableAmount,
      claimStatus: "Approved",
      claimAction: "Approved",
      empCode: SelectedEmpClaim.empCode,
      claimDate: SelectedEmpClaim.claimDate,
      claimNumber: SelectedEmpClaim.claimNumber,
      reasonForLessApproval: ReasonforLessApproval,
      reimbursementTicketGenerate: SelectedEmpClaim.reimbursementTicketGenerate,
      claimedAmount: SelectedEmpClaim.claimedAmount,
      companyId: SelectedEmpClaim.companyId,
      branchId: SelectedEmpClaim.branchId,
    };
    console.log("Save Approval Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/SaveReimbursementApproval`,
        body
      )
      .then((response) => {
        const res = response.data;
        console.log("res", res);

        let approvalId = response.data;

        let body2 = ReviewClaimRowData.map((obj)=>{
      
          if(ApprovedClaimList.some((data)=>{
            if(data.reimbursementId === obj.reimbursementId){
              return true
            }else{
              return false
            }
          })){
            return   {
              reimbursementId :  obj.reimbursementId,
              approvalId : approvalId,
              claimStatus : "Approved",
              claimAction : "Approved"
            }
          }else{
            return {
              reimbursementId :  obj.reimbursementId,
              approvalId : approvalId,
              claimAction : 'Rejected',
              claimStatus : 'Rejected',
            }
    
          }
        })
    
        console.log("Approved List", body2);
    
         axios
          .post(
            `${process.env.REACT_APP_ACQ_URL}/api/Reimbursement/UpdateReimbursementAction`,
            body2
          )
          .then((response) => {
            console.log("Approved List Response", response);
            const res = response.data;
            console.log("res", res);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: res,
                position: "top",
                status: "info",
                duration: 2000,
                isClosable: true,
              });
            }
            getReimbursementListOfMonth(CurrentDateMonth);
            onReviewClaimClose();
          })
          .catch((error) => {
            console.log(error.response);
            console.log(error.response.data);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "ERROR",
                description: "Reimbursement Type not Approved.",
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });
        getReimbursementListOfMonth(CurrentDateMonth);
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Reimbursement Type not Approved.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });


  };

  const SavePettyCashType = async (e) => {
    e.preventDefault();

    let body = {
      pettyCashTypeName: PettyCashTypeName,
      maxLimit: Number(PettyCashTypeMaxlimit),
      isActive: active,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      empCode: SelectedEmp.empCode,
      empGuId: SelectedEmp.guId,
      empName: `${SelectedEmp.firstName} ${SelectedEmp.lastName}`,
    };

    console.log(body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/SavePettyCashType`,
        body
      )
      .then((response) => {
        //  const res = response.data;
        console.log("Save Petty Cash Type Response", response);
        // console.log("Add Reimbursement List Response ",response.data);

        const res = response.data;
        // console.log("res",res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        GetEmpPettyType(SelectedEmp.guId);

        onAddReimbursementTypeClose();
        setPettyCashTypeName("");
        setPettyCashTypeMaxlimit("");
      })
      .catch((error) => {
        console.log(error.response);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: "Petty Cash Not Added.",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  const handleDownloadFile = async (DocPath) =>{
    console.log(DocPath)
   await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
  ).then(response => {

    console.log("file Response",response);

    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);

        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        alink.download = fileURL;
        alink.click();
    })

  }).catch((error)=>{
    console.log("File Api Error",error);
  })
  }



  const handleViewFile = async (DocPath) =>{
    console.log(DocPath)
   await fetch(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${DocPath}`,
  ).then(response => {

    console.log("file Response",response);

    response.blob().then(blob => {
        // Creating new object of PDF file
        const fileURL = window.URL.createObjectURL(blob);
        // Setting various property values
        let alink = document.createElement('a');
        alink.href = fileURL;
        setViewClaimAttachment(alink);
        onOpen();
        // alink.download = fileURL;
        // alink.click();
    })

    }).catch((error)=>{
      console.log("File Api Error",error);
    })
  }



  return (
    <div>
      <div>
        <div className=" w-full border-b border-gray-400 mb-3 pb-2 text-left">
          <h1 className="text-gray-700 text-lg font-bold ">
            Expense
          </h1>
        </div>


        <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end mb-4 '>
        {/* <div className='space-y-2 w-1/4'>
            <label>Select Department</label>
            <ReactSelect
               options={DepartmentList}
               value={SelectedDepartment}
               onChange={(value) => {
                setSelectedDepartment(value); 

                let selctedDepId = value.departmentId;
                console.log("Selected Department Id",selctedDepId);
                HandleDepartmentChange(selctedDepId);
                // getEmployeeList();
                // setEmpList(prev => prev.filter(data => data.department === String(selctedDepId)));
                setSelectedEmp([]);
              }}
               getOptionLabel={(option) => option.departmentName}
               getOptionValue={(option) => option.departmentId}
               placeholder="Select Department"
              />
        </div> */}


        <div className='space-y-2 w-1/4'>
            <label>Select Employee</label>
            <ReactSelect
               options={EmpList}
               value={SelectedEmp}
               onChange={(value) => {setSelectedEmp(value);}}
               getOptionLabel={(option) => `${option.empName} ${option.empCode}`}
               getOptionValue={(option) => option.empGuId}
               placeholder="Select Employee"
              />
        </div>


        <Button onClick={()=>{HandleAddExpense();}} colorScheme={'purple'}>Add Expense</Button>

      </Box>


      <div className=" w-full border-b border-gray-400 mb-3 pb-2 text-left">
          <h1 className="text-gray-700 text-lg font-bold ">
          All Expense Claims
          </h1>
        </div>


        <div className="ag-theme-alpine">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  rowData={ReimbursementRowData} // Row Data for Rows
                  columnDefs={ReimbursementColumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  rowSelection="multiple" // Options - allows click selection of rows
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                  cacheQuickFilter={true}
                  suppressExcelExport={true}
                />
              </div>




        <Modal
          size="full"
          isOpen={isAddReimbursementOpen}
          onClose={onAddReimbursementClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader bg="gray.400">Add Expense</ModalHeader>
            <ModalCloseButton />
            <hr />

            <ModalBody>
              <div className="flex py-2 gap-8">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Employee Name</p>
                  <h6 className="font-medium">{SelectedEmp.empName}</h6>
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Employee Code</p>
                  <h6 className="font-medium">{SelectedEmp.empCode}</h6>
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Ticket Id</p>
                  <h6 className="font-medium">{TicketId}</h6>
                </div>

                <div>
                <p className="text-sm text-gray-500">Opening Balance</p>
                <h6 className="font-semibold">
                  {ClaimAmountDetails.openingBalance || 0}
                </h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Current Balance</p>
                <h6 className="font-semibold">
                  {ClaimAmountDetails.currentBalance || 0}
                </h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Closing Balance</p>
                <h6 className="font-semibold">
                  {ClaimAmountDetails.closingBalance || 0}
                </h6>
              </div>

              </div>
              <hr />

              <form onSubmit={AddReimbursementList}>
                <div className="mt-4">
                  <div className="grid grid-cols-4 gap-6 mb-4">
                    <div className="space-y-2">
                      <label>Request Date</label>
                      <Input
                        readOnly
                        value={ClaimDate || ''}
                        onChange={(e) => {
                          setClaimDate(e.target.value);
                        }}
                        type="date"
                        borderColor="gray.400"
                        placeholder="Employee Code"
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Bill Date</label>
                      <Input
                        isRequired
                        value={BillDate || ''}
                        onChange={(e) => {
                          setBillDate(e.target.value);
                        }}
                        type="date"
                        borderColor="gray.400"
                        placeholder="Employee Code"
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Request Amount</label>
                      <Input
                        isRequired
                        value={ClaimAmount || ''}
                        onChange={(e) => {
                          setClaimAmount(e.target.value);
                        }}
                        type="number"
                        borderColor="gray.400"
                        placeholder="Claim Amount"
                      />
                    </div>

                    <div className="space-y-2">
                      <label>Description</label>
                      <Textarea
                        value={Reason || ''}
                        onChange={(e) => {
                          setReason(e.target.value);
                        }}
                        borderColor="gray.400"
                        rows={1}
                        placeholder="Enter Description"
                      />
                    </div>
                  </div>
                  <div className="grid grid-cols-4 gap-6 mb-4">
                    {/* <div className="space-y-2">
                      <label>Request Number</label>
                      <Input
                        isRequired
                        value={ClaimNumber}
                        onChange={(e) => {
                          setClaimNumber(e.target.value);
                        }}
                        borderColor="gray.400"
                        placeholder="Claim Number"
                      />
                    </div> */}

                    <div className="space-y-2">
                      <label>Expense Type</label>

                      <InputGroup>
                        <Select
                          isRequired
                          value={ReimbursementType}
                          onChange={(e) => {
                            setReimbursementType(e.target.value);
                          }}
                          borderColor="gray.400"
                          placeholder="Select Expense Type"
                        >
                          {ReimbursementTypeList.map((data, i) => {
                            return (
                              <option key={i} value={data.expenseID}>
                                {data.expenseType}
                              </option>
                            );
                          })}
                        </Select>
                        {/* <Tooltip
                          rounded="5px"
                          hasArrow
                          label="Add Expense Type"
                          bg="gray.300"
                          color="black"
                        >
                          <InputRightAddon
                            onClick={onAddReimbursementTypeOpen}
                            className="cursor-pointer"
                            bg="purple.400"
                            children={
                              <FontAwesomeIcon
                                className="text-white"
                                icon={faPlus}
                              />
                            }
                          />
                        </Tooltip> */}
                      </InputGroup>
                    </div>

                    <div className="space-y-2">
                      <label>Bill Number</label>
                      <Input
                        isRequired
                        value={BillNumber || ''}
                        onChange={(e) => {
                          setBillNumber(e.target.value);
                        }}
                        borderColor="gray.400"
                        placeholder="Bill Number"
                      />
                    </div>
                    <div className="space-y-2">
                      <label>Client Info</label>
                      {/* <Select
                        isRequired
                        value={ClientName}
                        onChange={(e) => {
                          setClientName(e.target.value);
                        }}
                        borderColor="gray.400"
                        placeholder="Select Client"
                      >
                        {ClientList.map((data, i) => {
                          return (
                            <option key={i} value={data.clientId}>
                              {data.clientName}
                            </option>
                          );
                        })}
                      </Select> */}

                      <ReactSelect
            options={ClientList}
            value={ClientName}
            onChange={(value) => {setClientName(value); }}
            getOptionLabel={(option) => option.clientName}
            getOptionValue={(option) => option.clientId}
            placeholder="Select Client"
          />
                    </div>

                    <div className="space-y-2">
                      <label>Attachment</label>
                      <Input
                      ref={FileRef}
                        isRequired
                        onChange={({target}) => {
                          setAttachment(target.files[0]);
                          console.log(target.files[0]);
                        }}
                        type="file"
                        borderColor="gray.400"
                        placeholder="Attachment"
                      />
                    </div>
                    
                  </div>

               

                  <div className="mb-6 text-right">
                    <Button type="submit" colorScheme="purple" mr={3}>
                      Save
                    </Button>
                    <Button
                      onClick={onAddReimbursementClose}
                      colorScheme="purple"
                      variant="outline"
                    >
                      Cancel
                    </Button>
                  </div>
                </div>
              </form>

              <div className="ag-theme-alpine-dark">
                <AgGridReact
                  style={gridStyle}
                  domLayout={"autoHeight"}
                  rowData={EmployeeReimburseRowData} // Row Data for Rows
                  columnDefs={ExpensesColumnDefs} // Column Defs for Columns
                  defaultColDef={defaultColDef} // Default Column Properties
                  animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                  rowSelection="multiple" // Options - allows click selection of rows
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                  cacheQuickFilter={true}
                  suppressExcelExport={true}
                />
              </div>

              {/* <div className="ag-theme-alpine-dark">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          rowData={EmployeeReimburseRowData} // Row Data for Rows
          columnDefs={SelectedEmpDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={7}
          paginationNumberFormatter={paginationNumberFormatter}

        />
      </div> */}

              {/* <div className="my-6 text-right">
                <Button onClick={SaveReimbursementList} colorScheme="purple" mr={3}>
                   Save
                 </Button>
                 <Button onClick={onAddReimbursementClose} colorScheme="purple" variant="outline">
                   Cancel
                 </Button>
                </div> */}
            </ModalBody>
          </ModalContent>
        </Modal>

        <Modal
          isOpen={isAddReimbursementTypeOpen}
          onClose={onAddReimbursementTypeClose}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>Add Expense Type</ModalHeader>
            <ModalCloseButton />
            <hr />
            <form onSubmit={SavePettyCashType}>
              <ModalBody>
                <div className="space-y-4">
                  <div className="space-y-2">
                    <label>Petty Cash Type Name</label>
                    <Input
                      isRequired
                      borderColor="gray"
                      value={PettyCashTypeName || ""}
                      onChange={(e) => {
                        setPettyCashTypeName(e.target.value);
                      }}
                      placeholder="Petty Cash Type Name"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Type Max Limit (optional)</label>
                    <Input
                      type="number"
                      borderColor="gray"
                      value={PettyCashTypeMaxlimit || ""}
                      onChange={(e) => {
                        setPettyCashTypeMaxlimit(e.target.value);
                      }}
                      placeholder=" Type Max Limit"
                    />
                  </div>

                  <div className="mt-6">
                    <Checkbox
                      isChecked={active}
                      onChange={(e) => {
                        setActive(e.target.checked);
                      }}
                      borderColor="gray.400"
                    >
                      Make this as Active
                    </Checkbox>
                  </div>
                </div>
              </ModalBody>
              <hr />
              <ModalFooter>
                <div className="space-x-4">
                  <Button type="submit" colorScheme="purple" variant="solid">
                    Save
                  </Button>
                  <Button
                    variant="outline"
                    colorScheme="purple"
                    mr={3}
                    onClick={onAddReimbursementTypeClose}
                  >
                    Cancel
                  </Button>
                </div>
              </ModalFooter>
            </form>
          </ModalContent>
        </Modal>
      </div>

      {/* Review Claim Modal Start */}
      <Modal
        size="full"
        isOpen={isReviewClaimOpen}
        onClose={onReviewClaimClose}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader bg="gray.400">Review Component Claim</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div className="flex pb-1 gap-8">
              <div>
                <p className="text-sm text-gray-500">Employee Name</p>
                <h6 className="font-semibold">
                  {SelectedEmpClaim.employeeName}
                </h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Employee Code</p>
                <h6 className="font-semibold">{SelectedEmpClaim.empCode}</h6>
              </div>
              
              <div>
                <p className="text-sm text-gray-500">Opening Balance</p>
                <h6 className="font-semibold">
                  {ClaimAmountDetails.openingBalance || 0}
                </h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Current Balance</p>
                <h6 className="font-semibold">
                  {ClaimAmountDetails.currentBalance || 0}
                </h6>
              </div>
              <div>
                <p className="text-sm text-gray-500">Closing Balance</p>
                <h6 className="font-semibold">
                  {ClaimAmountDetails.closingBalance || 0}
                </h6>
              </div>
            </div>
            <hr />

            <div className="flex gap-10 mt-4">
              <div className="w-2/3">
                <div className="ag-theme-alpine-dark">
                  <AgGridReact
                    style={gridStyle}
                    ref={ClaimRef}
                    domLayout={"autoHeight"}
                    rowData={ReviewClaimRowData} // Row Data for Rows
                    columnDefs={ReviewClaimColumnDefs} // Column Defs for Columns
                    defaultColDef={ReviewClaimColDef} // Default Column Properties
                    animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                    pagination={true}
                    rowSelection={"multiple"}
                    paginationPageSize={15}
                    onFirstDataRendered= {onFirstDataRendered}
                    paginationNumberFormatter={paginationNumberFormatter}
                    onSelectionChanged={onSelectionChanged}
                  />
                </div>
              </div>

              <form onSubmit={SaveReimbursementApproval}>
                <div className="space-y-4">
                  <h6 className="text-xl pt-2 font-medium">Claim Details</h6>
                  <div className="flex pb-1 gap-8">
                    <div>
                      <p className="text-sm text-gray-500">Claimed Amount</p>
                      <h6 className="font-medium">
                        {SelectedEmpClaim.claimedAmount}
                      </h6>
                    </div>
                    <div>
                      <p className="text-sm text-gray-500"> Date</p>
                      <h6 className="font-medium">
                        {new Date(SelectedEmpClaim.claimDate).toLocaleDateString("en-GB")}
                      </h6>
                    </div>
                  </div>

                  <div className="flex gap-8">
                    <div className="space-y-2">
                      <label>Approved Amount</label>
                      <InputGroup>
                        <InputLeftAddon children="INR" />
                        <Input
                          bgColor="white"
                          value={PayableAmount}
                          onChange={(e) => {
                            setPayableAmount(e.target.value);
                          }}
                          borderColor="gray.400"
                          placeholder="Payable Amount"
                        />
                      </InputGroup>
                    </div>
                  </div>

                  <div className="space-y-2">
                    <label>Comment</label>
                    <br />
                    <Textarea
                      value={ReasonforLessApproval}
                      onChange={(e) => {
                        setReasonforLessApproval(e.target.value);
                      }}
                      borderColor="gray.400"
                      placeholder="Comment"
                    />
                  </div>

                  <div className="space-x-4">
                    <Button type="submit" variant="solid" colorScheme="purple">
                      Submit
                    </Button>
                  </div>
                </div>
              </form>
            </div>
          </ModalBody>
        </ModalContent>
      </Modal>



      <Modal size='full' isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Attachment</ModalHeader>
          <ModalCloseButton />
          <hr />
          <ModalBody>
            <div id="pdf-div"  >
            <embed

              src={ViewClaimAttachment + "#toolbar=0"}
              type="application/pdf"
              className="h-screen w-full !select-none"
              height={800}
              width={500}
            />
            </div>
          </ModalBody>
          <hr />

          <ModalFooter>
            <Button colorScheme='purple' onClick={onClose}>
              Close
            </Button>

          </ModalFooter>
        </ModalContent>
      </Modal>

    </div>
  );
}

export default AddExpenseAndClaim