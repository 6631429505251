import {
  RadioGroup,
  Stack,
  Radio,
  Table,
  Checkbox,
  useToast,
  Center,
  Thead,
  Tbody,
  Spinner,
  Tr,
  Th,
  Td,
  TableContainer,
  Button
} from "@chakra-ui/react";
import axios from "axios";
import React, { useState, useEffect  } from "react";
import _ from "lodash";
import moment from "moment";
import DatePicker from "react-datepicker";


function WeeklyOffMaster() {

  const userData = JSON.parse(localStorage.getItem("user"));

  
  const toast = useToast();
  const id = "toast";


  const [isLoading, setisLoading] = useState(false);

  const [YearType, setYearType] = useState('1')
 
  const [YearStartMonth, setYearStartMonth] = useState(new Date());
  const [YearEndMonth, setYearEndMonth] = useState(new Date());

  const [WeeklyOffList, setWeeklyOffList] = useState([
    {
      weeklyOffMasterId : 0,
      selectMonthdata : null,
      selectMonth : null,
      selectWeek : "0",
      weeklyOffDay : "Sunday",
      weeklyStatus : null,
      weeklystatusduplicate : null,
      isActive : false,
      companyId : userData.companyId,
      branchId : userData.isActiveBranch,
      deleted : 0,
      isCurrentYear : YearType === "1" ? true : false,
      isCustomYear : YearType === "2" ? true : false,
      yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
      yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
      dayName : null,
      dayIsChecked : false,
      errorMessage : null
  },
  {
    weeklyOffMasterId : 0,
    selectMonthdata : null,
    selectMonth : null,
    selectWeek : "1",
    weeklyOffDay : "Sunday",
    weeklyStatus : null,
    weeklystatusduplicate : null,
    isActive : false,
    companyId : userData.companyId,
    branchId : userData.isActiveBranch,
    deleted : 0,
    isCurrentYear : YearType === "1" ? true : false,
    isCustomYear : YearType === "2" ? true : false,
    yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
    yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
    dayName : null,
    dayIsChecked : false,
    errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "2",
  weeklyOffDay : "Sunday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "3",
  weeklyOffDay : "Sunday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "4",
  weeklyOffDay : "Sunday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "0",
  weeklyOffDay : "Monday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "1",
  weeklyOffDay : "Monday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "2",
  weeklyOffDay : "Monday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "3",
  weeklyOffDay : "Monday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "4",
  weeklyOffDay : "Monday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "0",
  weeklyOffDay : "Tuesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "1",
  weeklyOffDay : "Tuesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "2",
  weeklyOffDay : "Tuesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "3",
  weeklyOffDay : "Tuesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "4",
  weeklyOffDay : "Tuesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "0",
  weeklyOffDay : "Wednesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "1",
  weeklyOffDay : "Wednesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "2",
  weeklyOffDay : "Wednesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "3",
  weeklyOffDay : "Wednesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "4",
  weeklyOffDay : "Wednesday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "0",
  weeklyOffDay : "Thursday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "1",
  weeklyOffDay : "Thursday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "2",
  weeklyOffDay : "Thursday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "3",
  weeklyOffDay : "Thursday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "4",
  weeklyOffDay : "Thursday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "0",
  weeklyOffDay : "Friday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "1",
  weeklyOffDay : "Friday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "2",
  weeklyOffDay : "Friday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "3",
  weeklyOffDay : "Friday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "4",
  weeklyOffDay : "Friday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "0",
  weeklyOffDay : "Saturday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "1",
  weeklyOffDay : "Saturday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "2",
  weeklyOffDay : "Saturday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "3",
  weeklyOffDay : "Saturday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},{
  weeklyOffMasterId : 0,
  selectMonthdata : null,
  selectMonth : null,
  selectWeek : "4",
  weeklyOffDay : "Saturday",
  weeklyStatus : null,
  weeklystatusduplicate : null,
  isActive : false,
  companyId : userData.companyId,
  branchId : userData.isActiveBranch,
  deleted : 0,
  isCurrentYear : YearType === "1" ? true : false,
  isCustomYear : YearType === "2" ? true : false,
  yearStartMonth : "2023-02-06T13:39:03.5675483+05:30",
  yearEndMonth : "2023-02-06T13:39:03.5676822+05:30",
  dayName : null,
  dayIsChecked : false,
  errorMessage : null
},
  ])

  useEffect(()=>{
    GetWeeklyOffList();
  },[])





  const GetWeeklyOffList = async () => {
    try{
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/WeeklyOff/GetWeeklyOffMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log('Weekly Off Data',response);
      const res = response.data;

      setWeeklyOffList(prev => {
        const newState =  prev.map((obj) => {
          return { ... obj,
            isActive : res.filter(filData => filData.selectWeek === obj.selectWeek && filData.weeklyOffDay === obj.weeklyOffDay).map(dayStatus => dayStatus.isActive)[0] || false
          }
        });
        return newState
       })
      
    }catch(error){
      console.error(error)
    }finally{
      setisLoading(false)
    }
  }

  const HandleAllCheckbox = async(WeekDayName,Verdict) =>{

    console.log("Checkbox Data",WeekDayName,Verdict);

     setWeeklyOffList(prev => {
      const newState =  prev.map((obj) => {
        if (obj.weeklyOffDay === WeekDayName) {
          return {...obj, isActive: Verdict};
        }
        return obj;
      });
      return newState
     })
  }


  const HandleSingleCheckbox = async(WeekNumber,WeekDayName,Verdict) =>{

    console.log("Checkbox Data",WeekNumber,WeekDayName,Verdict);

     setWeeklyOffList(prev => {
      const newState =  prev.map((obj) => {
        if (obj.weeklyOffDay === WeekDayName && obj.selectWeek === WeekNumber) {
          return {...obj, isActive: Verdict};
        }
        return obj;
      });
      return newState
     })

  }

  console.log("Week Off List",WeeklyOffList);

  const SaveWeeklyOffMaster = async () => {

    setisLoading(true);

    let body = WeeklyOffList.map((data)=>{
      return {
        ...data,
        isCurrentYear : YearType === "1" ? true : false,
        isCustomYear : YearType === "2" ? true : false,
        yearStartMonth : moment(YearStartMonth).format('YYYY-MM-DD'),
        yearEndMonth : moment(YearEndMonth).format('YYYY-MM-DD'),
      }
    })


    console.log("Save Weekly off Body",body);


    
    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/WeeklyOff/SaveWeeklyOffMaster`,body
    )
    .then((response) => {
      console.log("Save Weekly Off Response ",response);
      const res = response.data;


      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: "top",
          status: "info",
          duration: 2000,
          isClosable: true,
        });
      }


      GetWeeklyOffList();
    })
    .catch((error) => {
      console.log(error.response);

      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Weekly Off not Saved`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    })

  }



  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Weekly off master</h1>
      </div>

      <div className="bg-white rounded-2xl drop-shadow-md p-4">
        <div className="w-1/4">
          <RadioGroup onChange={setYearType} value={YearType}>
            <Stack direction="column">
              <Radio value="1">Calendar Year (January to December)</Radio>
              <Radio value="2">Custom Year</Radio>
            </Stack>
          </RadioGroup>
        </div>



        {YearType === "2" ?
        <div className="flex gap-8 mb-4 mt-4">
          <div className="space-y-2 w-1/2">
            <label>Year starts from</label>
              <DatePicker
   disabled={YearType === "1" ? true : false}
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={YearStartMonth}
    onChange={(date) => setYearStartMonth(date)}
    placeholderText="Year Start Date"
    dateFormat={'dd-MM-yyyy'}
  />
          </div>
          <div className="space-y-2 w-1/2">
            <label>Year ends on</label>
              <DatePicker
   disabled={YearType === "1" ? true : false}
    className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
    selected={YearEndMonth}
    onChange={(date) => setYearEndMonth(date)}
    placeholderText="Year End Date"
    dateFormat={'dd-MM-yyyy'}
  />
          </div>
        </div> : ''}

        {!isLoading ? 
        <TableContainer >
          <Table
            // borderRadius={15}
            variant="striped"
            colorScheme="gray"
            size="md"
            className="mt-4"

          >
            <Thead className="bg-gray-400">
              <Tr>
                <Th>Days</Th>
                <Th>All</Th>
                <Th>1st</Th>
                <Th>2nd</Th>
                <Th>3rd</Th>
                <Th>4th</Th>
                <Th>5th</Th>
              </Tr>
            </Thead>
            <Tbody>
              {_.uniqBy(WeeklyOffList,'weeklyOffDay').map((data,i)=>{
                return <Tr key={i}>
                <Td>{data.weeklyOffDay}</Td>
                <Td>
                  <Checkbox isChecked={WeeklyOffList.filter(weekday => weekday.weeklyOffDay === data.weeklyOffDay).every(everyData => everyData.isActive === true )} onChange={({target})=>{HandleAllCheckbox(data.weeklyOffDay,target.checked)}} borderColor={'gray.400'} colorScheme="green"></Checkbox>
                </Td>
                {WeeklyOffList.filter(weekday => weekday.weeklyOffDay === data.weeklyOffDay).map((DayData,i)=>{
                  return <Td key={i}>
                  <Checkbox isChecked={DayData.isActive} borderColor={'gray.400'} onChange={({target})=>{HandleSingleCheckbox(DayData.selectWeek,DayData.weeklyOffDay,target.checked)}} colorScheme="green"></Checkbox>
                </Td>
                })}
              </Tr>
              })}
            </Tbody>
          </Table>
        </TableContainer>
        : <Center>
          <Spinner   thickness='4px' color={'purple.500 '}  size='lg'  />
        </Center> }

        <div className="text-right mt-4">
          <Button onClick={SaveWeeklyOffMaster} colorScheme={'purple'} >Save</Button>
        </div>
      </div>
    </div>
  );
}

export default WeeklyOffMaster;
