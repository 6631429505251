import React, { useState, useRef, useEffect, useMemo, useCallback } from "react";
import DatePicker from "react-datepicker";
import axios from "axios";
import { AgGridReact } from "ag-grid-react";
import ReactSelect from 'react-select';
import { Search } from "react-feather";
import { Box, Button, RadioGroup, Stack, Radio} from "@chakra-ui/react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";

function AttendanceReport() {
    const userData = JSON.parse(localStorage.getItem("user"));

    const [ShowEmployees, setShowEmployees] = useState('1');
    const [AllEmpList, setAllEmpList] = useState([]);
    const [FilteredList, setFilteredList] = useState([]);

    const [SelectedEmp, setSelectedEmp] = useState([]);
    const [DateRange, setDateRange] = useState([null,null]);
    const [StartDate, EndDate] = DateRange;

    const gridRef = useRef();
    const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling

  const [ columnDefs ] = useState([
  
    {
      headerName: "Emp Name",
      field: "empName",
    },
    {
      headerName: "Date",
      field: "attendanceDate",
      valueGetter:(params) => new Date(params.data.attendanceDate).toLocaleDateString('en-GB')
    },

    {
        headerName: "Punch In Time",
        field: "inTime",
      },
      {
        headerName: "Punch Out Time",
        field: "outTime",
      },
      {
        headerName: "Device",
        field: "linkDevice",
      },
      {
        headerName: "Status",
        field: "attendanceStatus",
        cellRenderer: (params) => 
        params.data.attendanceStatus === "P"?
        <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        P
      </span>:
       params.data.attendanceStatus === "L"?
      <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      L
    </span>
      :
       params.data.attendanceStatus === "A"?
      <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      A
    </span> :
       params.data.attendanceStatus === "WO"?
      <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      WO
    </span> :
       params.data.attendanceStatus === "H"?
      <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      H
    </span> 
    :""

      },

      {
        headerName: "Attendance",
        field: "att_statusType",
        cellRenderer:(params)=>
        params.data.attendanceStatus === "P"?
        <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span> : params.data.attendanceStatus === 'A'?
        <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span> : params.data.att_statusType === 'Late'?
        <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>: params.data.attendanceStatus === 'L'?
        <span className="bg-blue-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>: params.data.att_statusType === 'HD'?
        <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>:params.data.attendanceStatus === "WO"?
        <span className="bg-orange-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>:params.data.attendanceStatus === "H"?
        <span className="bg-purple-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
          {params.data.att_statusType}
        </span>
       : ''
      },
      {
        headerName: "Remarks",
        field: "remark"
      }
  ]); 


  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);
  
  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: 'no-border',
    resizable:true
  }));


    useEffect(() => {
      if(ShowEmployees === '2'){
        setFilteredList(AllEmpList.filter((emp)=> emp.isActive === false));
      }else{
        setFilteredList(AllEmpList.filter((emp)=> emp.isActive === true));
      }
    }, [ShowEmployees]);
  
    useEffect(()=>{
        getEmployeeList();    
      },[]);
    
      const getEmployeeList = async() =>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Employee List",response.data);
          const res = response.data;
        
          setAllEmpList(res);
          setFilteredList(res);
          
        } catch (error) {
          console.error(error)
        }
    
      }


      const HandleSearch = async () => {
        try {
            const response = await axios.get(
              `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetEmpFinalAttendanceByDate?EmpGuid=${SelectedEmp.guId}&fromdate=${new Date(StartDate).toLocaleDateString('en-CA')}&Todate=${new Date(EndDate).toLocaleDateString('en-CA')}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )
      
            console.log("Emp Attendance Data List",response);

            const res = response.data;
            setRowData(_.orderBy(res,['attendanceDate'], ['asc']))
          } catch (error) {
            console.error(error)
      
          }
      }


      const exportData = () => {

        // let SelMonthName = MonthNames[new Date(SelectedDateMonth).getMonth()]
        const params = {
          fileName: `Attendance Report of ${SelectedEmp.fullName}.csv`,
        };
      
        gridRef.current.api.exportDataAsCsv(params);
      };


  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Attendance Report</h1>

        <Button  isDisabled={rowData.length === 0 ? true : false}
        onClick={()=>{exportData()}} leftIcon={<FontAwesomeIcon icon={faDownload} />} colorScheme={'purple'} >Export</Button>
      </div>


      <Box className='bg-white rounded-2xl p-4 flex gap-8 items-end  mb-4'>

        <div className="space-y-3">
          <label>Employee List</label>
        <RadioGroup  onChange={setShowEmployees} value={ShowEmployees}>
            <Stack mb={2} direction='row' gap={2}>
              <Radio borderColor={'gray.400'} value='1'>Active Employees</Radio>
              <Radio borderColor={'gray.400'} value='2'>Inactive Employees</Radio>
            </Stack>
          </RadioGroup>
        </div>

        <div className='space-y-2 w-1/4'>
            <label>Select Employee</label>
            <ReactSelect
               options={FilteredList}
               value={SelectedEmp}
               onChange={(value) => {setSelectedEmp(value); }}
               getOptionLabel={(option) => `${option.fullName} ${option.empCode}`}
               getOptionValue={(option) => option.guId}
               key={(option)=> option.id}
               placeholder="Select Employee"
              />
        </div>

        <div className="space-y-2">
            <label>Select Date Range</label>
            <DatePicker
              className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
              selectsRange={true}
              startDate={StartDate}
              endDate={EndDate}
              onChange={(update) => {
                 setDateRange(update)
              }}
              dateFormat="dd/MM/yyyy"
              placeholderText='Select Date Range'
            />
        </div>

        <Button isDisabled={SelectedEmp.length === 0 ? true : false} colorScheme={'purple'} onClick={()=>{HandleSearch();}}><Search size={'16px'} className="mr-2" />Search</Button>

      </Box>


      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
        //   paginationPageSize={20}
          paginationNumberFormatter={paginationNumberFormatter}

        />
      </div>

    </div>
  )
}

export default AttendanceReport