import { Button, Tabs, TabList, TabPanels, Tab, TabPanel, useToast, useDisclosure, Modal, ModalOverlay, ModalContent, ModalHeader, ModalFooter, ModalBody, ModalCloseButton, Input} from "@chakra-ui/react";
import React, { useState, useEffect, useMemo, useCallback } from "react";
import { AgGridReact } from "ag-grid-react";
import DatePicker from "react-datepicker";
import axios from "axios";
import moment from "moment";
import * as XLSX from 'xlsx';
import {LuCheckCircle,LuXCircle} from 'react-icons/lu';
const generateExcelFile = (SalaryData) => {

  // Create a new workbook
  const workbook = XLSX.utils.book_new();

  const CsvData = [];

  let startingHeads = ['Emp Code','Emp Name']

  let MiddleHeads = []
  SalaryData?.empSalaryData.forEach((data)=>{
    
  })

  CsvData.push(['Emp Code','Emp Name','Gross','Net Salary','Total']);

  SalaryData.forEach((item, index) => {
    const formula = `C${index + 2}+D${index + 2}`; 
    console.log("Formula",formula);
    CsvData.push([item.empCode, item.empName,item.gross,item.netPay, { f: formula, t: 'n' }]);
  });

  console.log("Excel Data",CsvData);

  const worksheet = XLSX.utils.aoa_to_sheet(CsvData);


  // Create a worksheet
  // const worksheet = XLSX.utils.aoa_to_sheet([
  //   ['Name', 'Age', 'Formula'],
  //   ['John', 25, { f: 'B2*2', t: 'n' }], // Example formula: double the value in cell B2
  //   ['Jane', 30, { f: 'B3*2', t: 'n' }], // Example formula: double the value in cell B3
  // ]);

  // Set the calculation mode to automatic
  worksheet['!calcstate'] = { calcMode: 'auto' };

  // Add the worksheet to the workbook
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');

  // Generate an Excel file
  const excelBuffer = XLSX.write(workbook, {
    type: 'buffer',
    bookType: 'xlsx',
  });

  // Save the Excel file
  const blob = new Blob([excelBuffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
  const url = URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', 'formulated_column.xlsx');
  // document.body.appendChild(link);
  // link.click();
  // document.body.removeChild(link);
};



const CompensationOff = () => {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [CompOffDate, setCompOffDate] = useState(new Date());
  const [Remarks, setRemarks] = useState();

  const {
    isOpen: isRequestCompOffOpen,
    onOpen: onRequestCompOffOpen,
    onClose: onRequestCompOffClose,
  } = useDisclosure();

  const [rowData, setRowData] = useState([]);
  const [TeamRowData, setTeamRowData] = useState([]);
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Comp Off for Date",
      field: "compoffDate",
      cellRenderer:(params) => <span>{moment(params.data.compoffDate).format('DD/MM/YYYY')}</span>
    },
    {
      headerName: "Remarks",
      field: "remark",
    },
    {
      headerName: "Status",
      field: "compoffStatus",
      cellRenderer:(params) => params.data.compoffStatus === "Pending" ?
      <span className="bg-yellow-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      Pending
      </span> : params.data.compoffStatus === "Approved" ?
      <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        Approved
      </span> : params.data.compoffStatus === "Rejected" ?
      <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        Rejected
      </span> : params.data.compoffStatus === "Cancelled" ?
      <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        Cancelled
      </span> : ''
    },
    {
      headerName: "Action By",
      field: "approvedByName",
    },
  ]);

  const [TeamColumnDefs, setTeamColumnDefs] = useState([
    {
      headerName: "Emp Code",
      field: "empCode",
    },    
    {
      headerName: "Emp Name",
      field: "empName",
    },
    {
      headerName: "Comp Off for Date",
      field: "compoffDate",
      cellRenderer:(params) => <span>{moment(params.data.compoffDate).format('DD/MM/YYYY')}</span>
    },
    {
      headerName: "Remarks",
      field: "remark",
    },
    {
      headerName: "Status",
      field: "compoffStatus",
      cellRenderer:(params) => params.data.compoffStatus === "Pending" ?
      <span className="bg-yellow-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
      Pending
      </span> : params.data.compoffStatus === "Approved" ?
      <span className="bg-green-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        Approved
      </span> : params.data.compoffStatus === "Rejected" ?
      <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        Rejected
      </span> : params.data.compoffStatus === "Cancelled" ?
      <span className="bg-red-500 text-white rounded-md px-2 py-0.5 text-sm font-medium">
        Cancelled
      </span> : ''
    },
    {
      headerName: "Action By",
      field: "approvedByName",
    },
    {
      headerName: "Action",
      cellRenderer: (params) => params.data.compoffStatus === "Pending" ?
      <div className="space-x-2">
        <Button onClick={async()=>{
          let RowData = params.data;

          let body = {
            compoffId: RowData.compoffId,
            empGuid: RowData.empGuid,
            empCode: RowData.empCode,
            leaveQuotaId: RowData.leaveQuotaId,
            compoffDate: RowData.compoffDate,
            remark: RowData.remark,
            compoffStatus: "Approved",
            approvedBy: userData.userId,
            companyId: RowData.companyId,
            branchId: RowData.branchId
          }

          console.log("Approve Action",body);

          await axios.post(
            `${process.env.REACT_APP_ACQ_URL}/api/Compoff/SAVECompoffApply`,body
          ).then((response) => {
            console.log("Approve Response", response);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: response?.data,
                position: "top",
                status: "info",
                duration: 2000,
                isClosable: true,
              });
            }
    
            GetCompOffList();
    
          })
          .catch((err) => {
            console.error("Approve Error", err);
            if (!toast.isActive(id)) {
              toast({
                id,
                title: "Server Error",
                position: "top",
                status: "error",
                duration: 2000,
                isClosable: true,
              });
            }
          });
        }} leftIcon={<LuCheckCircle/>} colorScheme="green" size={'xs'} >Approve</Button>
        <Button 
          onClick={async()=>{
            let RowData = params.data;
  
            let body = {
              compoffId: RowData.compoffId,
              empGuid: RowData.empGuid,
              empCode: RowData.empCode,
              leaveQuotaId: RowData.leaveQuotaId,
              compoffDate: RowData.compoffDate,
              remark: RowData.remark,
              compoffStatus: "Rejected",
              approvedBy: userData.userId,
              companyId: RowData.companyId,
              branchId: RowData.branchId
            }
  
            console.log("Reject Action",body);
  
            await axios.post(
              `${process.env.REACT_APP_ACQ_URL}/api/Compoff/SAVECompoffApply`,body
            ).then((response) => {
              console.log("Reject Response", response);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: response?.data,
                  position: "top",
                  status: "info",
                  duration: 2000,
                  isClosable: true,
                });
              }
      
              GetCompOffList();
      
            })
            .catch((err) => {
              console.error("Reject Error", err);
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: "Server Error",
                  position: "top",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                });
              }
            });
          }}
         leftIcon={<LuXCircle/>} colorScheme="red" size={'xs'} >Reject</Button>
      </div> : params.data.compoffStatus === "Approved" ?
      <Button  onClick={async()=>{
        let RowData = params.data;

        let body = {
          compoffId: RowData.compoffId,
          empGuid: RowData.empGuid,
          empCode: RowData.empCode,
          leaveQuotaId: RowData.leaveQuotaId,
          compoffDate: RowData.compoffDate,
          remark: RowData.remark,
          compoffStatus: "Cancelled",
          approvedBy: userData.userId,
          companyId: RowData.companyId,
          branchId: RowData.branchId
        }

        console.log("Cancel Action",body);

        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Compoff/SAVECompoffApply`,body
        ).then((response) => {
          console.log("Cancel Response", response);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: response?.data,
              position: "top",
              status: "info",
              duration: 2000,
              isClosable: true,
            });
          }
  
          GetCompOffList();
  
        })
        .catch((err) => {
          console.error("Cancel Error", err);
          if (!toast.isActive(id)) {
            toast({
              id,
              title: "Server Error",
              position: "top",
              status: "error",
              duration: 2000,
              isClosable: true,
            });
          }
        });
      }} leftIcon={<LuXCircle/>} colorScheme="red" size={'xs'} >Cancel</Button> : ''
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);


  useEffect(() => {
    GetCompOffList();
  }, []);

  const GetCompOffList = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/Compoff/GetCompoffList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`);
      console.log("Comp Off List", response);
      const res = response.data;
      setRowData(res.filter(item => item.empGuid === userData.guId));
      setTeamRowData(res.filter(item => item.reportingPersonGuid === userData.guId));
    } catch (err) {
      console.error(err);
    }
  };


  const SaveRequestCompOff = async (e) => {
    e.preventDefault();

    let body = {
      empGuid: userData.guId,
      empCode: userData.empCode,
      leaveQuotaId: 0,
      compoffDate: moment(CompOffDate).format('YYYY-MM-DD'),
      remark: Remarks,
      compoffStatus: "Pending",
      approvedBy: 0,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
    }

    console.log("Save Comp Off Body",body);

    await axios
      .post(`${process.env.REACT_APP_ACQ_URL}/api/Compoff/SAVECompoffApply`, body)
      .then((response) => {
        console.log("Save Response", response);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: response?.data,
            position: "top",
            status: "info",
            duration: 2000,
            isClosable: true,
          });
        }

        onRequestCompOffClose();
        setCompOffDate(new Date());
        setRemarks('');
        GetCompOffList();

      })
      .catch((err) => {
        console.error("Request Error", err);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "Server Error",
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  return (
    <div>
      <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-lg font-bold text-gray-800">Compensation Offs</h1>
        <Button onClick={onRequestCompOffOpen} colorScheme="purple">
          Request Comp. off
        </Button>

        {/* <Button variant={'outline'} colorScheme="purple" onClick={async()=>{
          try {
            const response = await axios.get(`http://122.176.36.172:8002/api/PayrollSalaryReview/GetEmpMonthSalaryReportByEmpGuid?PayrollMonth=6&PayrollYear=2023&EmpGuid=85b29eb2-de50-467b-b874-deb0ab367884&CompanyId=1&BranchId=19`)
            console.log("salary response",response);
             generateExcelFile([response.data])
          } catch (err) {
            console.error(err);
          }
        }}>Export</Button> */}
      </div>

      <Tabs variant={"solid-rounded"} size={"sm"} colorScheme="purple">
        <TabList>
          <Tab>Comp Offs</Tab>
          <Tab>Team Comp Offs</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                rowData={rowData} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={useMemo(() => ({
                  flex: 1,
                  sortable: true,
                  filter: "agTextColumnFilter",
                  floatingFilter: true,
                  cacheQuickFilter: true,
                  cellClass: "no-border",
                }))} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>
          </TabPanel>
          <TabPanel px={0}>
            <div className="ag-theme-alpine">
              <AgGridReact
                style={gridStyle}
                domLayout={"autoHeight"}
                rowData={TeamRowData} // Row Data for Rows
                columnDefs={TeamColumnDefs} // Column Defs for Columns
                defaultColDef={useMemo(() => ({
                  sortable: true,
                  filter: "agTextColumnFilter",
                  floatingFilter: true,
                  cacheQuickFilter: true,
                  cellClass: "no-border",
                }))} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
              />
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Modal
        isOpen={isRequestCompOffOpen}
        onClose={() => {
          onRequestCompOffClose();
        }}
      >
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader
            textColor={"gray.700"}
            fontSize="lg"
            backgroundColor={"purple.200"}
            roundedTop={"3xl"}
            roundedBottom={"xl"}
            fontWeight="bold"
          >
            Request Compensation Off
          </ModalHeader>
          <ModalCloseButton className="mt-2" />
          <form onSubmit={SaveRequestCompOff}>
            <ModalBody className="space-y-3">
              <div className="space-y-2">
                <label>Comp off Date</label>
                <DatePicker
                  required
                  className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2 outline-none"
                  selected={CompOffDate}
                  onChange={(date) => {
                    setCompOffDate(date);
                  }}
                  dateFormat="dd/MM/yyyy"
                  placeholderText="Select Comp off Date"
                />
              </div>

              <div className="space-y-2">
                <label>Remarks</label>
                <Input isRequired value={Remarks || ''} onChange={({target})=>{setRemarks(target.value)}} borderColor={"gray.400"} placeholder="Remarks" />
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
              
                variant={"outline"}
                colorScheme="purple"
                mr={3}
                onClick={() => {
                  onRequestCompOffClose();
                }}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                colorScheme="purple"
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default CompensationOff;
