import React, {useCallback, useState, useRef, useMemo, useEffect } from 'react'
import useFetch from "../Utils/useFetch";
import { AgGridReact } from "ag-grid-react"; 
import { Button, IconButton, Select, Drawer, Radio, RadioGroup, Stack, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, useDisclosure, Tooltip, Checkbox, Switch } from '@chakra-ui/react';
import { Eye, Lock, Sliders } from 'react-feather';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLock } from '@fortawesome/free-solid-svg-icons';
import DatePicker from "react-datepicker";
import usePost from '../Utils/usePost';
import ReactSelect from 'react-select';
import moment from 'moment';

const AllEmpInvestments = () => {
    const userData = JSON.parse(localStorage.getItem("user"));

    const { 
      isOpen: isInvestmentSettingOpen, 
      onOpen: onInvestmentSettingOpen, 
      onClose: onInvestmentSettingClose 
    } = useDisclosure();

    const navigate = useNavigate();

    const { IsPostLoading: InvestSettingLoading, PostData: PostInvestSettingData } = usePost('/api/Investment/SaveFYSettingForInvestment');
    const { FetchedData: FinancialYearList, Refetch: RefetchFinYearList } = useFetch(`/api/Master/GetFinancialYearMasterList`,{
      CompanyId: userData.companyId,
      BranchId: userData.isActiveBranch,
    });

    const [dateRange, setDateRange] = useState([null, null]);
    const [startDate, endDate] = dateRange;

    const [FinancialYear, setFinancialYear] = useState();

    const [FilteredRowData,setFilteredRowData] = useState([]);
    const [GridDataOf,setGridDataOf] = useState('1')
    const [PreferencesData,setPreferenceData] = useState({
      financialYearId: 0,
      financialYearName: "",
      isActive: false,
      isCurrent: false,
      isCurrentAY: false,
      isAttachmentRequired: false,
      isOpenForAll: false,
      isLock: false,
      fyOpenStartDate: "",
      fyOpenEndDate: "",
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    })

    const gridRef = useRef();
    const [ rowData, setRowData ] = useState([]);
    const [columnDefs] = useState([
      {
        headerName: "Emp Code",
        field: "empCode",
      },
      {
        headerName: "Emp Name",
        field: "empName",
      },
      {
        headerName: "Designation",
        field: "designationName",
      },
      {
        headerName: "Department",
        field: "departmentName",
      },
      {
        headerName: "Financial Year",
        field: "financialYear",
      },
      {
        headerName: "Pan Number",
        field: "panNumber",
      },
      {
        headerName: "Taxable Income",
        field: "totalIncome",
      },
      {
        headerName: "Tax Payable",
        field: "taxRemaining",
      },
      {
        headerName: "Investment Status",
        pinned:'right',
        valueGetter:(params)=> params.data.isComplete === true ? "Completed" : "Pending",
        field: "isComplete",
        cellRenderer:(params) => {
          return params.data.isComplete === true && params.data.isLock === true ? 
          <span className="bg-green-600 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap"><FontAwesomeIcon icon={faLock} size="sm" className="mr-1"/>Confirmed</span>: params.data.isComplete === true && params.data.isLock === false ? 
          <span className="bg-green-500 px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap">Completed</span>:
           <span className="bg-red-500  px-1.5 py-0.5 rounded-lg text-white font-semibold whitespace-nowrap">Pending</span>
         }
      },
      {
        headerName: "Action",
        pinned:'right',
        floatingFilter: false,
        cellRenderer: (params) => <Button onClick={()=>{
          navigate(`/addinvestment/${params.data.empGuid}/admin/${params.data.financialYear}`)
        }} leftIcon={<Eye size={'14px'}/>} colorScheme='blue' size={'xs'} >View</Button>
      }
    ]);

  
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
    const defaultColDef = useMemo(() => ({
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      enableCellTextSelection: true,
      resizable: true
    }));

    useEffect(()=>{
      if(GridDataOf === "2"){
        setFilteredRowData(rowData.filter(data => data.annualTaxStatus));
      }else if(GridDataOf === "3"){
        setFilteredRowData(rowData.filter(data => !data.annualTaxStatus))
      }else{
        setFilteredRowData(rowData);
      }
    },[GridDataOf])

    useEffect(()=>{
      if (FinancialYearList.length > 0) {
        let CurrFinYearData = FinancialYearList.filter(data => data.isCurrent === true )[0]
        console.log("Current Fin Year",CurrFinYearData);
        
        setFinancialYear(CurrFinYearData?.financialYearName);
        setPreferenceData(CurrFinYearData);

        if (CurrFinYearData?.fyOpenStartDate === "0001-01-01T00:00:00" || CurrFinYearData?.fyOpenEndDate === "0001-01-01T00:00:00") {
          setDateRange([null,null]);
        } else {
          setDateRange([new Date(CurrFinYearData?.fyOpenStartDate), new Date(CurrFinYearData?.fyOpenEndDate)]);
        }

        GetEmpTaxList(FinancialYearList.filter(data => data.isCurrent === true ).map(FilData => FilData.financialYearName)[0]);
      }
    },[FinancialYearList]);

    const GetEmpTaxList = async (FinYear) => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/Investment/GetEmpTaxStatusList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&FinancialYear=${FinYear}`)
        console.log("Emp Tax List",response);
        setRowData(response.data);
        setFilteredRowData(response.data);
      } catch (err) {
        console.error(err);
      }
    }


    //  console.log("Daterange",dateRange);

  return (
    <>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">All Investments</h1>
        <div className='flex gap-4 items-center'>
          <div>
            <Select 
              value={FinancialYear} 
              onChange={({target})=>{
                setFinancialYear(target.value);
                GetEmpTaxList(target.value);
              }} 
              bg 
              borderColor={'gray'} 
              placeholder='Select Year'
            >
              {FinancialYearList?.filter(data => data.isCurrent || data.isCurrentAY)?.map((FilData)=>{
                return <option key={FilData.financialYearId} value={FilData.financialYearName}>{FilData.financialYearName}</option>
              })}
            </Select>
          </div>
          <Tooltip placement='top' rounded='5px' hasArrow label='Preferences' bg='gray.700' color='white'>
           <IconButton onClick={onInvestmentSettingOpen} colorScheme='purple' icon={<Sliders size={'18px'} className='rotate-90' />} />
          </Tooltip>
        </div>
      </div>

      <div className='flex justify-end mb-4'>
        <div className="bg-white drop-shadow-sm flex gap-2 items-center rounded-lg py-1.5 px-3">    
          <label className="font-medium">Show Employees :</label>
          <RadioGroup onChange={setGridDataOf} value={GridDataOf}>
            <Stack direction='row' gap={2}>
              <Radio borderColor={'gray.400'} value='1'>All</Radio>
              <Radio borderColor={'gray.400'} value='2'>Taxable</Radio>
              <Radio borderColor={'gray.400'} value='3'>Non-Taxable</Radio>
            </Stack>
          </RadioGroup>
        </div>
      </div> 

      <div className="ag-theme-alpine">
        <AgGridReact
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={FilteredRowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={20}
          rowSelection='single'
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div>

      <Drawer
        isOpen={isInvestmentSettingOpen}
        placement='right'
        onClose={onInvestmentSettingClose}
        size={'sm'}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton mt={2} />
          <DrawerHeader>Preferences</DrawerHeader>
          <hr />
          <DrawerBody className='space-y-4'>
          <div className='flex gap-6 justify-between'>
            <ReactSelect
              required
              options={FinancialYearList}
              value={PreferencesData}
              className="w-full my-auto"
              onChange={(value)=>{
                setPreferenceData(value);
                GetEmpTaxList(value?.financialYearName);
                if (value?.fyOpenStartDate === "0001-01-01T00:00:00" || value?.fyOpenEndDate === "0001-01-01T00:00:00") {
                  setDateRange([null,null]);
                } else {
                  setDateRange([new Date(value?.fyOpenStartDate), new Date(value?.fyOpenEndDate)]);
                }
              }}
              getOptionLabel={(option) => option.financialYearName}
              getOptionValue={(option) => option.financialYearName}
              key={(option) => option.financialYearId}
              placeholder="Select Year"
            />
            <div>
             <Button isDisabled={!rowData.every((data) => data.isLock === true) } leftIcon={<Lock size={'16px'} />} colorScheme='purple' variant={'outline'}>Lock</Button>
            </div>
          </div>

          <div className='flex justify-between items-center'>
              <label>Make Declaration Submission Open for all</label>
              <Switch 
                isChecked={PreferencesData?.isOpenForAll} 
                onChange={({target})=>{
                  setPreferenceData((prev)=>{
                    return {
                      ...prev,
                      isOpenForAll: target.checked
                    }
                  })
                }} 
                colorScheme='green' 
                size={'md'}
              />
            </div>

            {PreferencesData?.isOpenForAll && <React.Fragment> 
            <div className='flex justify-between items-center'>
              <label>Make Proof Submission Mandatory</label>
              <Switch 
                isChecked={PreferencesData?.isAttachmentRequired} 
                onChange={({target})=>{
                  setPreferenceData((prev)=>{
                    return {
                      ...prev,
                      isAttachmentRequired: target.checked
                    }
                  })
                }} 
                colorScheme='green' 
                size={'md'}
              />
            </div>

            <div className='space-y-2'>
              <label>Submission Period</label>
              <DatePicker
                disabled={!PreferencesData?.isOpenForAll}
                className="bg-white border border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-indigo-600 focus:border-indigo-600 block w-full p-2.5   outline-none"
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                isClearable={true}
                placeholderText="Submission Period"
                dateFormat={'dd-MM-yyyy'}
                minDate={new Date()}
              />
            </div>
            </React.Fragment>}

         </DrawerBody>
          <hr />
          <DrawerFooter>
            <Button variant='outline' colorScheme='purple' mr={3} onClick={onInvestmentSettingClose}>
              Cancel
            </Button>
            <Button 
              isLoading={InvestSettingLoading}
              loadingText='Saving...'
              onClick={async()=>{

                 let body = {
                  ...PreferencesData,
                  fyOpenStartDate: moment(startDate).format('YYYY-MM-DD'),
                  fyOpenEndDate: moment(endDate).format('YYYY-MM-DD')
                 }

                 console.log("Setting Body",body);

                 const response = await PostInvestSettingData(body)
                 if(response){
                  RefetchFinYearList();
                 }
              }} 
              colorScheme='purple' 
            >Save</Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export default AllEmpInvestments