import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import { Link } from "react-router-dom";
import { Edit } from "react-feather";
import { Button, Radio,   Modal, ModalOverlay, ModalContent, useDisclosure, useToast,RadioGroup,Stack, Avatar, Image, Switch } from "@chakra-ui/react";
import axios from "axios";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import moment from "moment";
import { LuCopy } from 'react-icons/lu'


const EditEmployee = () => {
  
  const userData = JSON.parse(localStorage.getItem('user'));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [ProfilePicPath, setProfilePicPath] = useState({
    fullName: '',
    profilePath: ''
  });
  const toast = useToast();
  const id = "toast";
  const gridRef = useRef();
  const [SelectedEmpList, setSelectedEmpList] = useState([]);
  const [ShowEmployees, setShowEmployees] = useState('1');
  const [InActiveRowData, setInActiveRowData] = useState([]);
  const [rowData, setRowData] = useState([]);
  const [ActiveRowData, setActiveRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs] = useState([
      {
        headerName: "Emp Pic",
        field: "profilePicPath",
        // width:120,
        cellRenderer:(params) => <div onClick={()=>{
          if(params.data.profilePicPath){
          setProfilePicPath((prev)=>{
            return {
              ...prev,
              fullName: params.data.fullName,
              profilePath: params.data.profilePicPath
            }
          });
          onOpen();
        }
        }} className={params.data.profilePicPath ? "pt-1 cursor-pointer" : "pt-1"}>
          <Avatar name={params.data.firstName + ' ' + params.data.lastName} size={'sm'} src={`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${params.data.profilePicPath}`} />
        </div>
      },
      {
        headerName: "Employee Code",
        field: "empCode",
        comparator: (valueA, valueB) => {
          const numA = parseInt(valueA, 10);
          const numB = parseInt(valueB, 10);
          
          if (isNaN(numA) && isNaN(numB)) {
            return 0;
          } else if (isNaN(numA)) {
            return 1;
          } else if (isNaN(numB)) {
            return -1;
          } else {
            return numA - numB;
          }
        }
      },
      {
        headerName: "Employee Name",
        field: "fullName",
        valueGetter: (params) => (params.data.title || '') + ' ' + params.data.fullName
      },
      {
        headerName: "Designation",
        field: "designationName",
      },
      {
        headerName: "Department",
        field: "departmentName",
      },
      {
        headerName: "Date of Joining",
        field: "dateofjoin",
        cellRenderer:(params) =><span>
          {moment(params.data.dateofjoin).format('DD/MM/YYYY')}
        </span>
      },
      {
        headerName: "City",
        field: "workingLocation",
      },
      // { headerName: "IsActive", field: "isActive" , floatingFilter: false,
      //  cellRenderer: (params) => <Switch colorScheme='green' isChecked={params.data.isActive} onChange={({target})=>{handleIsActiveSwitch(target.checked,params.data)}}  />
      // },
      {
        headerName: "Office Email",
        field: "officeEmail",
        width:300,
        cellRenderer:(params) => <span 
           onClick={() => {
            navigator.clipboard.writeText(params.data.officeEmail);
            toast({
              id,
              title:"Copied to clipboard",
              description: `${params.data.firstName}'s E-mail copied to clipboard`,
              position: "bottom",
              status: "success",
              duration: 2000,
              isClosable: true
            });
          }} className="flex cursor-pointer"><LuCopy className="text-lg text-blue-600 mt-2 mr-2" />{params.data.officeEmail}</span>
      },
      {
        headerName: "RefID",
        field: "refId",
      },
      {
        headerName: "Action",
        floatingFilter: false,
        pinned: 'right',
        cellRenderer: (params) => <Link to={`/editempdetails/${params.data.guId}`}>
           <Button variant='solid' size='xs' colorScheme='blue'><Edit size='13px' className="mr-2"/>Edit</Button>
          </Link>
      }
    ]);

    const onSelectionChanged = useCallback(() => {
      var EmpList = gridRef.current.api.getSelectedRows();
     setSelectedEmpList(EmpList);
    }, []);


    const handleIsActiveSwitch = async (checked,EmpData)=>{

      console.log("Emp Value",checked);
      if (window.confirm(`${checked === true ? 'Are you sure you want to Active this Employee' : 'Are you sure you want to InActive this Employee'}`)) {


      setRowData(prev => {
        const newState =  prev.map((obj) => {
          if (obj.guId === EmpData.guId) {
            return {...obj, isActive: checked};
          }
          return obj;
        });
        return newState
      })
    
      // console.log("Create Emp Save Body",body);
    
      await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ActiveEmployeeByGuid?EmpGuid=${EmpData.guId}&IsActive=${checked}`).then((response) => {
        console.log("Switch Reponse",response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title:res,
            position: "top",
            status: res === 'Update Done'? 'success':'error',
            duration: 2000,
            isClosable: true,
          });
        }

    
      })
      .catch((error) => {
        console.log("Switch Error",error);
    
        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Employee not Updated`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });

      getEmpList();
    }

    }
    // console.log("New Row Data",rowData);
          // Table Pagination
          const paginationNumberFormatter = useCallback((params) => {
            return "[" + params.value.toLocaleString() + "]";
          }, []);
        
          //Table columns properties applies to all columns
          const defaultColDef = useMemo(() => ({
            // flex: 1,
            sortable: true,
            filter: "agTextColumnFilter",
            floatingFilter: true,
            cacheQuickFilter: true,
            cellClass: 'no-border',
            resizable: true,
            enableCellTextSelection: true
          }));

          useEffect(() => {
            getEmpList();
          }, [])

          const getEmpList = async () => {
            try {
              const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
              )
              console.log(response);
              const res = _.sortBy(response.data, [(o) => -o.id]);

              const FilteredData = res.filter(data => data.guId !== userData.guId)
              setRowData(FilteredData);
              setActiveRowData(FilteredData.filter(data => data.isActive === true));
              setInActiveRowData(FilteredData.filter(data => data.isActive === false));
            } catch (error) {
              console.error(error);
            }
          }


          const HandleEmpBatchSelection = async(ActiveStatus) => {

            if (window.confirm('Are you sure you want to perform this Action?')) {


            let body = SelectedEmpList.map((obj)=>{
              return {...obj,
                isActive: ActiveStatus,
                userName:'abc',
                password:"123"
              }
            })


            console.log("Selected Emp Save Body",body);
    
            await axios.post(
              `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/ActiveEmployeeInfoList`,body
            )
            .then((response) => {
              console.log(response);
              const res = response.data;
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title:res,
                  position: "top",
                  status: "success",
                  duration: 2000,
                  isClosable: true,
                });
              }
              getEmpList();
          
            })
            .catch((error) => {
              console.error(error);
          
              if (!toast.isActive(id)) {
                toast({
                  id,
                  title: "ERROR",
                  description: `Employee not Updated`,
                  position: "top",
                  status: "error",
                  duration: 2000,
                  isClosable: true,
                });
              }
            });
            
           }
  
        }

        const HandleEmpExport = () => {
          const params = {
            fileName : 'All Employee List.csv'
          }
          gridRef.current.api.exportDataAsCsv(params);
        }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Employee Information</h1>
        <div className="bg-white drop-shadow-sm flex gap-2 items-center rounded-lg py-1.5 px-3">    
          <label className="font-medium">Show Employees :</label>
          <RadioGroup onChange={setShowEmployees} value={ShowEmployees}>
            <Stack direction='row' gap={2}>
              <Radio borderColor={'gray.400'} value='1'>Active</Radio>
              <Radio borderColor={'gray.400'} value='2'>InActive</Radio>
              <Radio borderColor={'gray.400'} value='3'>All</Radio>
            </Stack>
          </RadioGroup>
        </div>
      </div>

      <div className="flex justify-end mb-4">
        <Button variant={'outline'} onClick={HandleEmpExport} leftIcon={<FontAwesomeIcon icon={faDownload}/>} colorScheme="purple" >Export</Button>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={ShowEmployees === '1'? ActiveRowData : ShowEmployees === '2'? InActiveRowData : ShowEmployees === '3'? rowData : []} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          onSelectionChanged={onSelectionChanged}
          rowSelection={'multiple'}
          enableCellTextSelection={true}
        />
      </div>

      <Modal size={'xs'} isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent bg={'transparent'} shadow={'none'}>
          <div>
           {/* <Avatar name={ProfilePicPath.fullName} size={'full'} objectFit={'cover'} src={`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${ProfilePicPath?.profilePath}`} /> */}
           <Image
             borderRadius='full'
             className="object-cover"
             boxSize='300px'
             src={`${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/DownloadDocumentFile?FileName=${ProfilePicPath?.profilePath}`}  alt={ProfilePicPath?.fullName}
           />
          </div>
        </ModalContent>
      </Modal>
    </div>
  )
}

export default EditEmployee