import { faCalendarMinus, faFileLines, faMoneyBill1, } from "@fortawesome/free-regular-svg-icons";
import { faBoxesStacked, faBullhorn, faEnvelope, faFileInvoice, faFileInvoiceDollar, faHandHoldingDollar, faMoneyBillTrendUp, faMoneyBills, faPersonWalkingArrowRight, faSackDollar, faWallet, } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { Calendar, Clock, Home, Users } from "react-feather";
import { NavLink, useLocation } from "react-router-dom";
import SidebarLinkGroup from "./SidebarLinkGroup";
import useFinancialYear from "../Utils/useFinancialYear";
import { useAuth } from "../Utils/AuthContext";

function FinalSidebar({ sidebarOpen, setSidebarOpen }) {
  const {userData} = useAuth();

  const FinancialYear = useFinancialYear();

  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);
  const [ELigibleforOvertime, setELigibleforOvertime] = useState(false);

  const [MenuList, setMenuList] = useState([]);
  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(
    storedSidebarExpanded === null ? false : storedSidebarExpanded === "true"
  );

  // close on click outside
  useEffect(() => {
    const clickHandler = ({ target }) => {
      if (!sidebar.current || !trigger.current) return;
      if (
        !sidebarOpen ||
        sidebar.current.contains(target) ||
        trigger.current.contains(target)
      )
        return;
      setSidebarOpen(false);
    };
    document.addEventListener("click", clickHandler);
    return () => document.removeEventListener("click", clickHandler);
  });

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  useEffect(() => {
    HandleSelectedRole();
    GetOvertimeEmpEligibleList();
  }, []);

  const HandleSelectedRole = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/RoleAndPermission/GetModuleMasterList?RoleId=${userData.roleId || 0}&CompanyId=${userData.companyId}&BranchId=${userData.branchId}`
      );
      // console.log("Menu List ", response);
      setMenuList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const GetOvertimeEmpEligibleList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Overtime/GetOvertimeMasterData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      // console.log("Eligible Overtime List", response.data);

      const res = response.data;

      let FoundUserEligibility = res
        .filter((data) => data.empGuid === userData.guId)
        .some((data) => {
          if (data.isActive) {
            return true;
          } else {
            return false;
          }
        });

      // console.log("Found User Eligibility", FoundUserEligibility);

      setELigibleforOvertime(FoundUserEligibility);
    } catch (error) {
      console.error(error);
    }
  };

  const FoundEmployeeDetailsMenu = MenuList.filter(
    (data) => data.mainMenuName === "Employee Details"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundSalaryRevisionMenu = MenuList.filter(
    (data) => data.mainMenuName === "Salary Revision"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundAssetMenu = MenuList.filter(
    (data) => data.mainMenuName === "Assets"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundAttendanceMenu = MenuList.filter(
    (data) => data.mainMenuName === "Attendance"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundOvertimeMenu = MenuList.filter(
    (data) => data.mainMenuName === "Overtime"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundLeaveMenu = MenuList.filter(
    (data) => data.mainMenuName === "Leave"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundPayrollMenu = MenuList.filter(
    (data) => data.mainMenuName === "Payroll"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundExpenseMenu = MenuList.filter(
    (data) => data.mainMenuName === "Expense"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundBonusMenu = MenuList.filter(
    (data) => data.mainMenuName === "Bonus"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundInvestmentsMenu = MenuList.filter(
    (data) => data.mainMenuName === "Investments"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundSeparationMenu = MenuList.filter(
    (data) => data.mainMenuName === "Separation"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundReportsMenu = MenuList.filter(
    (data) => data.mainMenuName === "Reports"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundPolicyMEnu = MenuList.filter(
    (data) => data.mainMenuName === "Policy"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  const FoundAnnouncementMenu = MenuList.filter(
    (data) => data.mainMenuName === "Announcements"
  ).some(
    ({ isCreate, isModify, isView }) => isCreate || isModify || isView === true
  );

  return (
    <div>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col m-2 mb-8  top-0 rounded-2xl absolute z-40 left-0  lg:static lg:left-auto lg:top-auto lg:translate-x-0 h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-60 md:sidebar-expanded:!w-60 lg:w-60 lg:sidebar-expanded:!w-60 2xl:!w-60 shrink-0 bg-slate-800 p-4 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-72"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-10 pr-3 sm:px-2 bg-slate-100 p-1 drop-shadow-md rounded-lg">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-500 hover:text-slate-400"
            onClick={() => setSidebarOpen(!sidebarOpen)}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}

          <NavLink end to="/dashboard" className="block">
            <div className="flex justify-center gap-2 items-center">
              <img className="h-7 ml-2" src="/HRMusterLogo.png" />
              <h1 className="text-gray-800 font-bold text-2xl">
                TSPL MIS
              </h1>
            </div>
          </NavLink>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>

            <ul className="mt-3 space-y-2">
              {userData.role === "Admin" ? (
                <>
                  {/* Dashboard */}
                  <li
                    className={`px-3 py-2 rounded-lg hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("/dashboard") &&
                      "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/dashboard"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/dashboard") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <Home size="16px" />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Dashboard
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {/* Employees */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/createemployee" ||
                      pathname === "/editemployees" ||
                      pathname.includes("/editempdetails/") ||
                      pathname === "/empinduction" ||
                      pathname === "/myprofile" ||
                      pathname === "/myteamempdetails" ||
                      pathname === "/salarydashboard" ||
                      pathname === "/emppayslips" ||
                      pathname === "/viewsalary" ||
                      pathname === "/probationemployees" ||
                      pathname === "/gratuitylist"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/createemployee" ||
                                pathname.includes("createemployee")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <Users size="16px" />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Employee Details
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/createemployee"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Create Employee
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/editemployees"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ||
                                    pathname.includes("/editempdetails/")
                                      ? "!text-indigo-500"
                                      : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All Employee Info{" "}
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empinduction"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ||
                                    pathname.includes("/empinduction")
                                      ? "!text-indigo-500"
                                      : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Employee Induction
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/probationemployees"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Employee Probation
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/myprofile"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Profile
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/emppayslips"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Payslips
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/salarydashboard"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Add Salary
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/viewsalary"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Salary
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/gratuitylist"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Employee Gratuity
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empdatareport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Employee Data Report
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Salary Revision */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/esirulereport" || 
                      pathname === "/viewsalaryincrement" || 
                      pathname === "/importsalaryrevision" || 
                      pathname === "/incrementrule"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faSackDollar} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Salary Revision
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/performance"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Performance
                                  </span>
                                </NavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/importsalaryrevision"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Revise Salary
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/viewsalaryincrement"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                   View Salary Increment
                                  </span>
                                </NavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/esirulereport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                   ESI Rule Report
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/incrementrule"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")}
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Revision Rules
                                  </span>
                                </NavLink>
                              </li>

                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Asset */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/addasset" ||
                      pathname === "/allocateasset" ||
                      pathname === "/viewempassets" ||
                      pathname === "/fnfassetrecovery" ||
                      pathname === "/assetissues"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faBoxesStacked} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Assets
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/addasset"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Add Asset
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/allocateasset"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Allocate Asset
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/viewempassets"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Assets
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/fnfassetrecovery"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    FNF Asset Recovery
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/assetissues"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                   Asset Issues
                                  </span>
                                </NavLink>
                              </li>


                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Attendance */}
                  <SidebarLinkGroup 
                    activecondition={
                      pathname === "/forgotattendance" ||
                      pathname === "/geoattendance" ||
                      pathname === "/viewattendance" ||
                      pathname === "/attendancesettings" ||
                      pathname === "/attendancedashboard" ||
                      pathname === "/empgeofencing" ||
                      pathname === "/activityreport" ||
                      pathname === "/myattendance" ||
                      pathname === "/attendancehelper"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/viewattendance" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <Calendar size="16px" />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Attendance
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="attendancedashboard"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Dashboard
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/viewattendance"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All Attendance
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empgeofencing"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Emp Geo-Fencing
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/activityreport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Activity Report
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/attendancesettings"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Attendance Settings
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/myattendance"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Attendance
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/attendancehelper"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Attendance Helper
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Shift */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/assignshift" ||
                      pathname === "/assignproject" ||
                      pathname === "/shiftmaster" ||
                      pathname === "/projectmaster" ||
                      pathname === "/shiftduty"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/assignshift" ||
                                pathname.includes("assignshift")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <Clock size="16px" />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Shift
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/projectmaster"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500 " : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Project Master
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/assignproject"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Assign Project
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/shiftmaster"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500 " : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Shift Master
                                  </span>
                                </NavLink>
                              </li>
                              
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/assignshift"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Assign Shift
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/shiftduty"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Shift Duty
                                  </span>
                                </NavLink>
                              </li>

                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Overtime */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/empviewovertime" ||
                      pathname === "/allovertime" ||
                      pathname === "/empovertime" ||
                      pathname === "/finalovertime" ||
                      pathname === "/applyovertime" ||
                      pathname === "/overtimereport" ||
                      pathname === "/overtimepayment"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/viewattendance" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <Clock size="16px" />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Overtime
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/applyovertime"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Overtime Eligibility
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/allovertime"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All Overtime
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/finalovertime"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Final Overtime
                                  </span>
                                </NavLink>
                              </li>


                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empovertime"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Overtime Status
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/overtimereport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Overtime Report
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Leave */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/leavequota" ||
                      pathname === "/leavesettings" ||
                      pathname === "/leaveapply" ||
                      pathname === "/leavebalance" ||
                      pathname === "/leavedashboard" ||
                      pathname === "/employeewfh" ||
                      pathname === "/myleave" ||
                      pathname === "/empwfh" ||
                      pathname === "/compensationoff" ||
                      pathname === "/allcompensationoff" ||
                      pathname === "/carryforwardsetting" || 
                      pathname.includes("leavesettlement")
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faCalendarMinus} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Leave
                                </span>
                              </div>
                              {/* Icon */}
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leavedashboard"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Dashboard
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leaveapply"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All Leave Application
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leavebalance"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Leave Balance
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leavesettings"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Leave Settings
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leavequota"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Leave Quota
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leavesettlement/pendingsettlements"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive || pathname.includes("leavesettlement") ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Leave Settlements
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/employeewfh"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All WFH Application
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/myleave"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Leaves
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/allcompensationoff"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  All Compensation off
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/compensationoff"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Compensation off
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empwfh"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Work From Home
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/carryforwardsetting"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Carry Forward Setting
                                  </span>
                                </NavLink>
                              </li>

                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Payroll */}
                  <SidebarLinkGroup
                    activecondition={
                      // pathname === "/estimatedpayroll" ||
                      pathname === "/finalrunpayroll" ||
                      pathname === "/adhocpayment" ||
                      pathname === "/compliance" ||
                      pathname === "/taxdeductionsource" ||
                      pathname === "/adhocdeduction"|| 
                      pathname === "/payrollreports" ||
                      pathname === "/payrollattendance"

                      
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faMoneyBill1} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Payroll
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              {/* <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/estimatedpayroll"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Estimated Payroll
                                  </span>
                                </NavLink>
                              </li> */}

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/finalrunpayroll"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Run Payroll
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/adhocpayment"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Adhoc Payment
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/adhocdeduction"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Adhoc Deduction
                                  </span>
                                </NavLink>
                              </li>

                              {userData.countryName === "India" ? <>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/compliance"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Compliance
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/taxdeductionsource"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    TDS
                                  </span>
                                </NavLink>
                              </li>

                              </> : ''}

                              
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/payrollattendance"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Payroll Attendance
                                  </span>
                                </NavLink>
                              </li>
                              
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/payrollreports"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Payroll Reports
                                  </span>
                                </NavLink>
                              </li>

                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  <li
                    className={`px-3 py-2 rounded-lg hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("/arrearlist") && "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/arrearlist"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/arrearlist") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faMoneyBills} />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Arrears
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {/* Expense */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/expenseadvancemaster" ||
                      pathname === "/pettyadvancerequest" ||
                      pathname === "/claimexpense" ||
                      pathname === "/expensepayment" ||
                       pathname === "/empsummarydetails" ||
                       pathname === "/expensepaymentprocess" ||
                       pathname === "/advancepaymentprocess" ||
                      pathname === "/empexpenseclaim" ||
                       pathname === "/expenseapprovals" || 
                       pathname === "/returnrequests"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/expenseadvancemaster" ||
                                pathname.includes("expenseadvancemaster")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faWallet} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Expense
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/expenseadvancemaster"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                   Advance Limit/Matrix
                                  </span>
                                </NavLink>
                              </li>

                               <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empsummarydetails"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Emp Summary
                                  </span>
                                </NavLink>
                              </li>

                           {/*   <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/claimexpense"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Claims
                                  </span>
                                </NavLink>
                              </li> */}

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empexpenseclaim"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Add Expense
                                  </span>
                                </NavLink>
                              </li>
                              
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/expenseapprovals"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Expense Approvals
                                  </span>
                                </NavLink>
                              </li>



                             <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/advancepaymentprocess"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Advance Payment Process
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/expensepaymentprocess"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Expense Payment Process
                                  </span>
                                </NavLink>
                              </li>


                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/advanceconfirmedpay"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Advance Confirmed Payments
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/expenseconfirmedpay"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Expense Confirmed Payments
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/returnrequests"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Return Requests
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/expensepaymentinfo"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Payment Report
                                  </span>
                                </NavLink>
                              </li>

                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  <li
                    className={`px-3 py-2 rounded-lg hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("/bonus") && "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/bonus"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/bonus") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Bonus
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {/* Investment */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname.includes("addinvestment") ||
                      pathname === "/addform16" ||
                      pathname === "/empform16" || 
                      pathname === "/allinvestments" || 
                      pathname === "/empinvestments" ||
                      pathname === "/tdsreport"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faFileInvoiceDollar} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Investments
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>

                            <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/allinvestments"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive || (pathname.includes("addinvestment") && pathname.includes("admin")) ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All Investments
                                  </span>
                                </NavLink>
                              </li>


                              <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to={`/addinvestment/${userData.guId}/employee/${FinancialYear?.financialYearName}`}
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Investment
                                          </span>
                                        </NavLink>
                                      </li>

                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to={`/empinvestments`}
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            My Investments
                                          </span>
                                        </NavLink>
                                      </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/addform16"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Add Form 16
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empform16"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Form 16
                                  </span>
                                </NavLink>
                              </li>

                              
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/tdsreport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    TDS Report
                                  </span>
                                </NavLink>
                              </li>

                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Seperation */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/addseparation" ||
                      pathname === "/empseperation" ||
                      pathname === "/fnfsettlement" ||
                      pathname === "/fnfreport"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon
                                  icon={faPersonWalkingArrowRight}
                                />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Separation
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/addseparation"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All Separation
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empseperation"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Separation Request
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/fnfsettlement"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    FNF Settlement
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/fnfreport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    FNF Report
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Reports */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/leavereports" ||
                      pathname === "/leavebalancereports" ||
                      pathname === "/allattendancereport" ||
                      pathname === "/attendancereport" ||
                      pathname === '/expensepaymentinfo' ||
                      pathname === '/expensereport' ||
                      pathname === '/employeesreport' ||
                      pathname === '/expensereportall' ||
                      pathname === '/separationreport' ||
                      pathname === '/empdesignations'
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faFileLines} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Reports
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                            {/* <SidebarLinkGroup
                    activecondition={
                      pathname === "/leavereports" ||
                      pathname === "/leavebalancereports" 
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span className="text-sm text-red-400  ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                 Leave Reports
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-1 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leavereports"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Leave Applied Report
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leavebalancereports"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Leave Balance Report
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/allattendancereport" ||
                      pathname === "/attendancereport"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <span className="text-sm  text-red-400 ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                 Attendance Reports
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-1 mt-1 ${!open && "hidden"}`}>
                            <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/attendancereport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Attendance Report
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/allattendancereport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All Attendance Report
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup> */}

<li className="text-red-400 my-2 text-xs">
                                Emp Details Reports
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/employeesreport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Employees Report
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/empdesignations"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Emp Designations
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/separationreport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Separation Report
                                  </span>
                                </NavLink>
                              </li>


                              <li className="text-red-400 my-2 text-xs">
                                Leave Reports
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leavereports"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Leave Applied Report
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/leavebalancereports"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Leave Balance Report
                                  </span>
                                </NavLink>
                              </li>
                              <li className="text-red-400 my-2 text-xs">
                                Attendance Reports
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/attendancereport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Attendance Report
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/allattendancereport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All Attendance Report
                                  </span>
                                </NavLink>
                              </li>



                              {/* <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/expensereport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Employee Ledger
                                  </span>
                                </NavLink>
                              </li>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/expensereportall"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    All Employee Ledger
                                  </span>
                                </NavLink>
                              </li> */}
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Announcements */}
                  <SidebarLinkGroup
                    activecondition={pathname === "/announcements"}
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faBullhorn} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Announcements
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/announcements"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Announcement & News
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>

                  {/* Policy */}
                  <SidebarLinkGroup
                    activecondition={
                      pathname === "/policyupload" ||
                      pathname === "/viewpolicies"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faFileInvoice} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Policy
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/policyupload"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Add Policy
                                  </span>
                                </NavLink>
                              </li>

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/viewpolicies"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    View Policies
                                  </span>
                                </NavLink>
                              </li>
                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>
                
                 {/* Subscription */}

                 <li
                    className={`px-3 py-2 rounded-lg hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("/plantransactions") &&
                      "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/plantransactions"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/plantransactions") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                      <FontAwesomeIcon icon={faHandHoldingDollar} />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          My Plan
                        </span>
                      </div>
                    </NavLink>
                  </li>

                 <li className={`px-3 py-2 rounded-lg hover:bg-slate-900 mb-2 last:mb-0 ${pathname.includes("/emailcampaigns") &&"bg-slate-900 rounded-xl"}`}>
                    <NavLink
                      end
                      to="/emailcampaigns"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/emailcampaigns") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                      <FontAwesomeIcon icon={faEnvelope} />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Email Scheduler
                        </span>
                      </div>
                    </NavLink>
                  </li>

                </>
              ) : (
                <>
                  {/* Dashboard */}
                  <li
                    className={`px-3 py-2 rounded-lg hover:bg-slate-900 mb-2 last:mb-0 ${
                      pathname.includes("/dashboard") &&
                      "bg-slate-900 rounded-xl"
                    }`}
                  >
                    <NavLink
                      end
                      to="/dashboard"
                      className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                        pathname.includes("/dashboard") && "hover:text-slate-50"
                      }`}
                    >
                      <div className="flex items-center">
                        <Home size="16px" />
                        <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                          Dashboard
                        </span>
                      </div>
                    </NavLink>
                  </li>

                  {FoundEmployeeDetailsMenu ? (
                    <>
                      {/* Employees */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/createemployee" ||
                          pathname === "/editemployees" ||
                          pathname.includes("/editempdetails/") ||
                          pathname === "/empinduction" ||
                          pathname === "/myprofile" ||
                          pathname === "/emppayslips" ||
                          pathname === "/myteamempdetails" ||
                          pathname === "/salarydashboard" ||
                          pathname === "/viewsalary" ||
                          pathname === "/probationemployees"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/createemployee" ||
                                    pathname.includes("createemployee")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <Users size="16px" />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Employee Details
                                    </span>
                                  </div>
                                  {/* Icon */}
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Create Employee"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/createemployee"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Create Employee
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "All Employee info"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/editemployees"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ||
                                            pathname.includes(
                                              "/editempdetails/"
                                            )
                                              ? "!text-indigo-500"
                                              : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Employee Info{" "}
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Employee Induction"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empinduction"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ||
                                            pathname.includes("/empinduction")
                                              ? "!text-indigo-500"
                                              : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Employee Induction
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}


{MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Employee Probation"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                        <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/probationemployees"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive )
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Employee Probation
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Profile"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/myprofile"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Profile
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Payslips"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/emppayslips"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Payslips
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Add Salary"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/salarydashboard"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Salary
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "View Salary"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/viewsalary"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Salary
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName ===
                                      "Employee Data Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empdatareport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Employee Data Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

              {FoundSalaryRevisionMenu ? (
                    <>
                      {/* Salary REvision */}
                      <SidebarLinkGroup
                    activecondition={
                      pathname === "/viewsalaryincrement" || 
                      pathname === "/esirulereport" || 
                      pathname === "/importsalaryrevision" || 
                      pathname === "/incrementrule"
                    }
                  >
                    {(handleClick, open) => {
                      return (
                        <React.Fragment>
                          <a
                            href="#0"
                            className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                              (pathname === "/sdf" ||
                                pathname.includes("dashboard")) &&
                              "hover:text-slate-200"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              sidebarExpanded
                                ? handleClick()
                                : setSidebarExpanded(true);
                            }}
                          >
                            <div className="flex items-center justify-between">
                              <div className="flex items-center">
                                <FontAwesomeIcon icon={faSackDollar} />
                                <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Salary Revision
                                </span>
                              </div>
                              <div className="flex shrink-0 ml-2">
                                <svg
                                  className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                    open && "rotate-180"
                                  }`}
                                  viewBox="0 0 12 12"
                                >
                                  <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                </svg>
                              </div>
                            </div>
                          </a>
                          <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                            <ul className={`pl-9 mt-1 ${!open && "hidden"}`}>


                            {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "View Salary Increment"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? ( <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/performance"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                   Performance
                                  </span>
                                </NavLink>
                              </li>
                                                                  ) : (
                                      ""
                                    );
                                  })}
                                                               {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Revise Salary"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? ( <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/importsalaryrevision"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Revise Salary
                                  </span>
                                </NavLink>
                              </li>
                                                                  ) : (
                                      ""
                                    );
                                  })}

                                   {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "View Salary Increment"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? ( <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/viewsalaryincrement"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                   View Salary Increment
                                  </span>
                                </NavLink>
                              </li>
                                                                  ) : (
                                      ""
                                    );
                                  })}

                              {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "ESI Rule Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? ( <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/esirulereport"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  ESI Rule Report
                                  </span>
                                </NavLink>
                              </li>
                               ) : (
                                      ""
                                    );
                                  })}

                                                               {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Revision Rules"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? ( <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/incrementrule"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")}
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    Revision Rules
                                  </span>
                                </NavLink>
                              </li>
                                                                  ) : (
                                      ""
                                    );
                                  })}

                            </ul>
                          </div>
                        </React.Fragment>
                      );
                    }}
                  </SidebarLinkGroup>


                    </>
                  ) : (
                    ""
                  )}



                  {FoundAssetMenu ? (
                    <>
                      {/* Asset */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/addasset" ||
                          pathname === "/allocateasset" ||
                          pathname === "/viewempassets"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faBoxesStacked} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Assets
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Add Asset"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/addasset"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Asset
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Allocate Asset"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/allocateasset"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Allocate Asset
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "View Assets"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/viewempassets"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Assets
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}


                           {MenuList.filter(
                                    (data) => data.subMenuName === "FNF Asset Recovery"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/fnfassetrecovery"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                    FNF Asset Recovery
                                  </span>
                                </NavLink>
                              </li>
                              ) : (
                                ""
                              );
                            })}

                            {MenuList.filter(
                              (data) => data.subMenuName === "Asset Issues"
                            ).map((data) => {
                              return data.isCreate ||
                                data.isModify ||
                                data.isView === true ? (
                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/assetissues"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                   Asset Issues
                                  </span>
                                </NavLink>
                              </li>
                             ) : (
                               ""
                             );
                           })}

                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundAttendanceMenu ? (
                    <>
                      {/* Attendance */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/forgotattendance" ||
                          pathname === "/geoattendance" ||
                          pathname === "/viewattendance" ||
                          pathname === "/attendancesettings" ||
                          pathname === "/attendancedashboard" ||
                          pathname === "/empgeofencing" ||
                          pathname === "/activityreport" ||
                          pathname === "/myattendance" ||
                          pathname === "/attendancehelper"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/viewattendance" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <Calendar size="16px" />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Attendance
                                    </span>
                                  </div>
                                  {/* Icon */}
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.mainMenuName === "Attendance" &&
                                      data.subMenuName === "Dashboard"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="attendancedashboard"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Dashboard
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "All Attendance"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/viewattendance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Attendance
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Emp Geo-Fencing"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empgeofencing"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Emp Geo-Fencing
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Activity Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/activityreport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Activity Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Attendance Setting"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/attendancesettings"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Attendance Settings
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "View Attendance"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/myattendance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Attendance
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundOvertimeMenu ? (
                    <>
                      {/* Overtime */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/empviewovertime" ||
                          pathname === "/allovertime" ||
                          pathname === "/empovertime" ||
                          pathname === "/finalovertime" ||
                          pathname === "/applyovertime" ||
                          pathname === "/overtimereport" ||
                          pathname === "/overtimepayment"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/viewattendance" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <Clock size="16px" />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Overtime
                                    </span>
                                  </div>
                                  {/* Icon */}
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName ===
                                      "Overtime Eligibility"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/applyovertime"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Overtime Eligibility
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "All Overtime"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/allovertime"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Overtime
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                 {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Final Overtime"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                        <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/finalovertime"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Final Overtime
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                  

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName ===
                                      "View Overtime Status"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empovertime"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Overtime Status
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Overtime Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/overtimereport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Overtime Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundLeaveMenu ? (
                    <>
                      {/* Leave */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/leavequota" ||
                          pathname === "/leavesettings" ||
                          pathname === "/leaveapply" ||
                          pathname === "/leavebalance" ||
                          pathname === "/leavedashboard" ||
                          pathname === "/employeewfh" ||
                          pathname === "/myleave" ||
                          pathname === "/empwfh" || 
                          pathname.includes('leavesettlement')
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faCalendarMinus} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Leave
                                    </span>
                                  </div>
                                  {/* Icon */}
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.mainMenuName === "Leave" &&
                                      data.subMenuName === "Dashboard"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavedashboard"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Dashboard
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName ===
                                      "All Leave Application"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leaveapply"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Leave Application
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Leave Balance"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavebalance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Balance
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Leave Setting"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavesettings"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Settings
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Leave Quota"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavequota"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Quota
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                 {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Leave Settlements"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavesettlement/pendingsettlements"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Settlements
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "All WFH Application"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/employeewfh"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All WFH Application
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "View Leave"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/myleave"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Leaves
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}


{MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "All Compensation off"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (

<li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/allcompensationoff"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  All Compensation off
                                  </span>
                                </NavLink>
                              </li>
                                                                  ) : (
                                                                    ""
                                                                  );
                                                                })}

                              
                              {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Compensation off"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (

                              <li className="mb-1 last:mb-0">
                                <NavLink
                                  end
                                  to="/compensationoff"
                                  className={({ isActive }) =>
                                    "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                    (isActive ? "!text-indigo-500" : "")
                                  }
                                >
                                  <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                  Compensation off
                                  </span>
                                </NavLink>
                              </li>
                                    ) : (
                                      ""
                                    );
                                  })}


                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Work From Home"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empwfh"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Work From Home
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundPayrollMenu ? (
                    <>
                      {/* Payroll */}
                      <SidebarLinkGroup
                        activecondition={
                          // pathname === "/estimatedpayroll" ||
                          pathname === "/finalrunpayroll" ||
                          pathname === "/adhocpayment" ||
                          pathname === "/adhocdeduction" ||
                          pathname === "/compliance" ||
                          pathname === "/taxdeductionsource" ||
                          pathname === "/payrollattendance"
                          
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faMoneyBill1} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Payroll
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {/* {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Estimated Payroll"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/estimatedpayroll"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Estimated Payroll
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })} */}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Run Payroll"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/finalrunpayroll"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Run Payroll
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Adhoc Payment"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/adhocpayment"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Adhoc Payment
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Adhoc Deduction"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/adhocdeduction"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Adhoc Deduction
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Compliance"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/compliance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Compliance
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "TDS"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/taxdeductionsource"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            TDS
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                    {MenuList.filter(
                                    (data) => data.subMenuName === "Payroll Attendance"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/payrollattendance"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Payroll Attendance
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}


                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundExpenseMenu ? (
                    <>
                      {/* Expense */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/expenseadvancemaster" ||
                          pathname === "/pettyadvancerequest" ||
                          pathname === "/claimexpense" ||
                          pathname === "/expensepayment" ||
                          pathname === "/expensereport" ||
                          pathname === "/expensereportall" ||
                          pathname === "/empexpenseclaim" ||
                          pathname === "/expensepaymentinfo" || 
                          pathname === "/expenseapprovals"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/expenseadvancemaster" ||
                                    pathname.includes(
                                      "expenseadvancemaster"
                                    )) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faWallet} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Expense
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName ===
                                      "Advance Limit Master"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/expenseadvancemaster"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Advance Limit/Matrix
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {/* {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Petty Cash Advance"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/pettyadvancerequest"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Petty Cash Advance
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })} */}

                                  {/* {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Claim Expense"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/claimexpense"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Claim Expense
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })} */}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Add Expense"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empexpenseclaim"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Expense
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}


{MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Expense Requests"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                        <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/expenseapprovals"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Expense Approvals
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {/* {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Expense Payment"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/expensepayment"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Expense Payment
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })} */}
{/* 
                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Payment Info"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/expensepaymentinfo"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Payment Info
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })} */}

                                  {/* {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Expense Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/expensereport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Expense Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "All Expense Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/expensereportall"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Expense Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })} */}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundBonusMenu ? (
                    <>
                      <li
                        className={`px-3 py-2 rounded-lg hover:bg-slate-900 mb-2 last:mb-0 ${
                          pathname.includes("/bonus") &&
                          "bg-slate-900 rounded-xl"
                        }`}
                      >
                        <NavLink
                          end
                          to="/bonus"
                          className={`block text-slate-50 hover:text-white truncate transition duration-150 ${
                            pathname.includes("/bonus") && "hover:text-slate-50"
                          }`}
                        >
                          <div className="flex items-center">
                            <FontAwesomeIcon icon={faMoneyBillTrendUp} />
                            <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                              Bonus
                            </span>
                          </div>
                        </NavLink>
                      </li>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundInvestmentsMenu ? (
                    <>
                      {/* Investment */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname.includes("addinvestment") ||
                          pathname === "/addform16" ||
                          pathname === "/empform16" || 
                          pathname === "/allinvestments" ||
                          pathname === "/empinvestments"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true)
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon
                                      icon={faFileInvoiceDollar}
                                    />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Investments
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "All Investments"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/allinvestments"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                           All Investments
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Add Investment"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to={`/addinvestment/${userData.guId}/${FinancialYear?.financialYearName}`}
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Investment
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                 {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "My Investments"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                        <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to={`/empinvestments`}
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive || (pathname.includes("addinvestment") && pathname.includes("employee")) ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            My Investments
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                 {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Add Form 16"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/addform16"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Form 16
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Form 16"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empform16"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                           Form 16
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundSeparationMenu ? (
                    <>
                      {/* Seperation */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/addseparation" ||
                          pathname === "/empseperation" ||
                          pathname === "/fnfsettlement"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon
                                      icon={faPersonWalkingArrowRight}
                                    />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Separation
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "All Separation"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/addseparation"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Separation
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "View Separation"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/empseperation"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Separation Request
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "FNF Settlement"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/fnfsettlement"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            FNF Settlement
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundReportsMenu ? (
                    <>
                      {/* Reports */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/leavereports" ||
                          pathname === "/leavebalancereports" ||
                          pathname === "/allattendancereport" ||
                          pathname === "/attendancereport"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faFileLines} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Reports
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {
                                    MenuList.filter(
                                      (data) =>
                                        data.subMenuName ===
                                          "Leave Balance Report" ||
                                        data.subMenuName ===
                                          "Leave Applied Report"
                                    ).map((data) => {
                                      return data.isCreate ||
                                        data.isModify ||
                                        data.isView === true ? (
                                        <li className="text-red-400 my-2 text-xs">
                                          Leave Reports
                                        </li>
                                      ) : (
                                        ""
                                      );
                                    })[0]
                                  }

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName ===
                                      "Leave Applied Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavereports"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Applied Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName ===
                                      "Leave Balance Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/leavebalancereports"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Leave Balance Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Attendance Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="text-red-400 my-2 text-xs">
                                        Attendance Reports
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Attendance Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/attendancereport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Attendance Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName ===
                                      "All Attendance Report"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/allattendancereport"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            All Attendance Report
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundAnnouncementMenu ? (
                    <>
                      {/* Announcements */}
                      <SidebarLinkGroup
                        activecondition={pathname === "/announcements"}
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faBullhorn} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Announcements
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) =>
                                      data.subMenuName === "Announcement & News"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/announcements"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Announcement & News
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {FoundPolicyMEnu ? (
                    <>
                      {/* Policy */}
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/policyupload" ||
                          pathname === "/viewpolicies"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <FontAwesomeIcon icon={faFileInvoice} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Policy
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Policy"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/policyupload"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Add Policy
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Policy"
                                  ).map((data) => {
                                    return data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/viewpolicies"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            View Policies
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )}

                  {/* {FoundSettingMenu ? (
                    <>
                      <SidebarLinkGroup
                        activecondition={
                          pathname === "/weeklyoff" ||
                          pathname === "/holidaymaster" ||
                          pathname === "/companydetail" ||
                          pathname === "/branchmaster" ||
                          pathname === "/designation" ||
                          pathname === "/department" ||
                          pathname === "/document" ||
                          pathname === "/employeecategory" ||
                          pathname === "/bank" ||
                          pathname === "/currency" ||
                          pathname === "/code" ||
                          pathname === "/componentmaster" ||
                          pathname === "/payrollconfiguration" ||
                          pathname === "/importdata" ||
                          pathname === "/compliancesettings" ||
                          pathname === "/clientmaster"||
                          pathname === "/emailconfig"
                        }
                      >
                        {(handleClick, open) => {
                          return (
                            <React.Fragment>
                              <a
                                href="#0"
                                className={`block text-slate-200 hover:text-white truncate transition duration-150 ${
                                  (pathname === "/sdf" ||
                                    pathname.includes("dashboard")) &&
                                  "hover:text-slate-200"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  sidebarExpanded
                                    ? handleClick()
                                    : setSidebarExpanded(true);
                                }}
                              >
                                <div className="flex items-center justify-between">
                                  <div className="flex items-center">
                                    <Settings size={"18px"} />
                                    <span className="text-sm font-medium ml-3 lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                      Settings
                                    </span>
                                  </div>
                                  <div className="flex shrink-0 ml-2">
                                    <svg
                                      className={`w-3 h-3 shrink-0 ml-1 fill-current text-slate-400 ${
                                        open && "rotate-180"
                                      }`}
                                      viewBox="0 0 12 12"
                                    >
                                      <path d="M5.9 11.4L.5 6l1.4-1.4 4 4 4-4L11.3 6z" />
                                    </svg>
                                  </div>
                                </div>
                              </a>
                              <div className="lg:block lg:sidebar-expanded:block 2xl:block">
                                <ul
                                  className={`pl-9 mt-1 ${!open && "hidden"}`}
                                >
                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Import Data"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/importdata"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                            Import Data
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}
                                  {MenuList.filter(
                                    (data) => data.subMenuName === "Email Config"
                                  ).map((data) => {
                                    return data.isCreate ||
                                      data.isModify ||
                                      data.isView === true ? (
                                      <li className="mb-1 last:mb-0">
                                        <NavLink
                                          end
                                          to="/emailconfig"
                                          className={({ isActive }) =>
                                            "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                            (isActive ? "!text-indigo-500" : "")
                                          }
                                        >
                                          <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                          Email Config
                                          </span>
                                        </NavLink>
                                      </li>
                                    ) : (
                                      ""
                                    );
                                  })}

                                  {FoundCompanySettingMenu ? (
                                    <>
                                      <li className="text-red-400 my-2 text-xs">
                                        Company Settings
                                      </li>

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName === "Company Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/companydetail"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500"
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Company Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) => data.subMenuName === "Branch"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/branchmaster"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500"
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Branch
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {FoundPayrollSettingMenu ? (
                                    <>
                                      <li className="text-red-400 my-2 text-xs">
                                        Payroll Salary Settings
                                      </li>

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName ===
                                          "Component Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/componentmaster"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500"
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Component Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName ===
                                          "Compliance Setting"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/compliancesettings"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500"
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Compliance Settings
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName ===
                                          "Payroll Configuration"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/payrollconfiguration"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500"
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Payroll Configuration
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName ===
                                          "Attendance Type Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/attendancetype"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500 "
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Attendance Type Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}
                                    </>
                                  ) : (
                                    ""
                                  )}

                                  {FoundMasterSettingMenu ? (
                                    <>
                                      <li className="text-red-400 my-2 text-xs">
                                        Master Settings
                                      </li>

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName ===
                                          "Designation Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/designation"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500 "
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Designation Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName ===
                                          "Department Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/department"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500 "
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Department Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName === "Document Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/document"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500 "
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Document Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName ===
                                          "Employee Category"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/employeecategory"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500 "
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Employee Category
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName === "Bank Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/bank"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500 "
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Bank Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName === "Code Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/code"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500 "
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Code Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName === "Holiday Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/holidaymaster"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500"
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Holiday Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName ===
                                          "Weekly Off Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/weeklyoff"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500"
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Weekly Off Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName === "Client Master"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/clientmaster"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500 "
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Client Master
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}

                                      {MenuList.filter(
                                        (data) =>
                                          data.subMenuName ===
                                          "Separation Settings"
                                      ).map((data) => {
                                        return data.isCreate ||
                                          data.isModify ||
                                          data.isView === true ? (
                                          <li className="mb-1 last:mb-0">
                                            <NavLink
                                              end
                                              to="/separationsettings"
                                              className={({ isActive }) =>
                                                "block text-slate-400 hover:text-slate-200 hover:translate-x-2 transition duration-150 truncate " +
                                                (isActive
                                                  ? "!text-indigo-500"
                                                  : "")
                                              }
                                            >
                                              <span className="text-sm font-medium lg:opacity-100 lg:sidebar-expanded:opacity-100 2xl:opacity-100 duration-200">
                                                Separation Settings
                                              </span>
                                            </NavLink>
                                          </li>
                                        ) : (
                                          ""
                                        );
                                      })}
                                    </>
                                  ) : ("")}
                                </ul>
                              </div>
                            </React.Fragment>
                          );
                        }}
                      </SidebarLinkGroup>
                    </>
                  ) : (
                    ""
                  )} */}
                </>
              )}
            </ul>
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:hidden 2xl:hidden justify-end mt-auto">
          <div className="px-3 py-2">
            <button onClick={() => setSidebarExpanded(!sidebarExpanded)}>
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-400"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
                <path className="text-slate-600" d="M3 23H1V1h2z" />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default FinalSidebar;
