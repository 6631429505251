import React, {useCallback, useState, useMemo } from 'react'
import { useAuth } from "../Utils/AuthContext";
import useFetch from "../Utils/useFetch";
import { Button, Select } from "@chakra-ui/react";
import ReactSelect from "react-select";
import { Search } from "react-feather";
import axios from "axios";
import { AgGridReact } from "ag-grid-react"; 


const TdsReport = () => {

  const { userData } = useAuth()

  const { FetchedData: FinYearListData } = useFetch(`/api/Master/GetFinancialYearMasterList`,{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  })

  const { FetchedData: EmpListData } = useFetch(`/api/EmployeeDetail/GetEmployeelist`,{
    CompanyId: userData.companyId,
    BranchId: userData.isActiveBranch
  })

  const [FinancialYear, setFinancialYear] = useState();
  const [SelectedEmp, setSelectedEmp] = useState();
  const [EmpTdsData, setEmpTdsData] = useState();
  const [columnDefs] = useState([
    {
      headerName: "Month",
      field: "payrollMonth"
    },
    {
      headerName: "Year",
      field: "payrollYear"
    },
    {
      headerName: "Tds Amount",
      field: "empTDSAmount"
    }
  ]);


  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const defaultColDef = useMemo(() => ({
    flex:1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
    enableCellTextSelection: true,
    resizable: true
  }));


  const GetEmpTDSData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_ACQ_URL}/api/PayrollTDSMonthData/GetTDSFYReport?FinancialYear=${FinancialYear}&EmpGuid=${SelectedEmp?.guId}&BranchId=${userData.isActiveBranch}&CompanyId=${userData.companyId}`)
      console.log("Emp Tds Data",response);
      setEmpTdsData(response.data);
    } catch (err) {
      console.error(err);
    }
  }

  return (
    <div>
      <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">TDS Report</h1>
      </div>
      <div className="bg-white p-4 flex gap-6 items-end rounded-2xl drop-shadow-md mb-4">
        <div className="space-y-2">
          <label>Select Financial Year</label>
          <Select
            value={FinancialYear} 
            onChange={({target})=>{
              setFinancialYear(target.value);
            }} 
            bg 
            borderColor={'gray'} 
            placeholder='Select Year'
          >
            {FinYearListData?.filter(data => data.isCurrent || data.isCurrentAY)?.map((FilData)=>{
              return <option key={FilData.financialYearId} value={FilData.financialYearName}>{FilData.financialYearName}</option>
            })}
          </Select>
        </div>

        <div className="space-y-2">
          <label>Select Employee</label>
          <ReactSelect
              required
              menuPortalTarget={document.body} 
              styles={{
                menuPortal: provided => ({ ...provided, zIndex: 9999 }),
                menu: provided => ({ ...provided, zIndex: 9999 })
              }}
              options={EmpListData}
              value={SelectedEmp}
              className="w-full my-auto white"
              onChange={(value)=>{
                setSelectedEmp(value);
              }}
              getOptionLabel={(option) => option.fullName}
              getOptionValue={(option) => option.guId}
              key={(option) => option.userId}
              placeholder="Select Employee"
            />
        </div>
        <div>
          <Button onClick={()=>{GetEmpTDSData();}} leftIcon={<Search size={'16px'} />} colorScheme="purple" >Search</Button>
        </div>
      </div>

    
     {EmpTdsData && <> 
      <div className="bg-white rounded-2xl p-4 drop-shadow-md mb-4">
        <div className="flex flex-wrap pb-1 gap-8">
          <div>
            <p className="text-sm text-gray-500">Emp Name</p>
            <h6 className="font-medium">{EmpTdsData?.empName}</h6>
          </div>
          <div>
            <p className="text-sm text-gray-500">Total TDS Amount</p>
            <h6 className="font-medium">{EmpTdsData?.totalTDSAmount}</h6>
          </div>
          <div>
            <p className="text-sm text-gray-500">Remaining TDS Amount</p>
            <h6 className="font-medium">{EmpTdsData?.remainingTDSAmount}</h6>
          </div>
        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          domLayout={"autoHeight"}
          rowData={EmpTdsData?.payrollTDSMonthDataList || []}
          columnDefs={columnDefs} 
          defaultColDef={defaultColDef} 
          animateRows={true}
          pagination={true}
          paginationPageSize={20}
          rowSelection='single'
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
        />
      </div> 
      </>}

    </div>
  );
};

export default TdsReport;