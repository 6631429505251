import React, {
  useState,
  useRef,
  useEffect,
  useMemo,
  useCallback,
} from "react";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionPanel,
  AccordionItem,
  Select,
  Input,
  Button,
  Switch,
  Checkbox,
  Radio,
  RadioGroup,
  Stack,
  useToast,
} from "@chakra-ui/react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from "react-feather";
import axios from "axios";
function LeaveSettings() {
  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const [LeaveQuotaList, setLeaveQuotaList] = useState([]);
  const [CompOffApplicable, setCompOffApplicable] = useState(false);


  const [LeaveSettingID, setLeaveSettingID] = useState(0);
  const [LeaveTypeName, setLeaveTypeName] = useState();
  const [PolicyName, setPolicyName] = useState();
  const [PerYearLeave, setPerYearLeave] = useState();
  const [PerMonthlyLeaves, setPerMonthlyLeaves] = useState();
  const [MonthyBasisEarningLeaves, setMonthyBasisEarningLeaves] =
    useState(false);
  const [ApplicableInNoticePeriod, setApplicableInNoticePeriod] =
    useState(false);
  const [ApplicableInProbationPeriod, setApplicableInProbationPeriod] =
    useState(false);
  const [EmpLeaveCancellaion, setEmpLeaveCancellaion] = useState(false);
  const [LeavesInStartingofYear, setLeavesInStartingofYear] = useState(false);
  const [LeaveAppliedBeforeDays, setLeaveAppliedBeforeDays] = useState();
  const [MedicalAfterNoOfDays, setMedicalAfterNoOfDays] = useState();
  const [PresentConsiderBy, setPresentConsiderBy] = useState("1");
  const [isMedicalApply, setisMedicalApply] = useState(false);

  const gridRef = useRef();
  const [rowData, setRowData] = useState([]); // Table Data Variable
  const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
  const [columnDefs, setColumnDefs] = useState([
    {
      headerName: "Leave Type Name",
      field: "leaveTypeName",
    },

    // {
    //   headerName: "Short Name",
    //   field: "shortName",
    //   cellRenderer:(params) => <span className='bg-green-600 py-1 px-2 text-white rounded-md'>{params.data.shortName}</span>
    // },

    {
      headerName: "IsActive",
      field: "isActive",
      cellRenderer: (params) => (
        <Switch
          colorScheme="green"
          isChecked={params.data.isActive}
          size="md"
          onChange={({ target }) => {
            HandleLeaveSettingsSwitch(target.checked, params.data);
          }}
        />
      ),
    },
    {
      headerName: "Action",
      field: "guId",
      cellRenderer: (params) => (
        <div className="space-x-4">
          <Button
            onClick={() => {
              let LeaveData = params.data;
              setLeaveSettingID(LeaveData.leaveSettingId);
              setLeaveTypeName(LeaveData.leaveQuotaId);
              setPolicyName(LeaveData.policyName);
              setPerYearLeave(LeaveData.perYearLeave);
              setPerMonthlyLeaves(LeaveData.perMonthlyLeaves);
              setMonthyBasisEarningLeaves(LeaveData.monthyBasisEarningLeaves);
              setApplicableInNoticePeriod(LeaveData.applicableInNoticePeriod);
              setEmpLeaveCancellaion(LeaveData.empLeaveCancellaion);
              setLeavesInStartingofYear(LeaveData.leavesInStartingofYear);
              setLeaveAppliedBeforeDays(LeaveData.leaveAppliedBeforeDays);
              setMedicalAfterNoOfDays(LeaveData.medicalAfterNoOfDays);
              setPresentConsiderBy(LeaveData.presentConsiderBy);
              setisMedicalApply(LeaveData.medicalRequired);
              setApplicableInProbationPeriod(
                LeaveData.applicableInProbationPeriod
              );
            }}
            variant="solid"
            size="xs"
            colorScheme="blue"
          >
            <Edit size="14px" className="mr-2" />
            Edit
          </Button>
          {/* <Button variant='solid' size='sm' colorScheme='red'>Reject</Button> */}
        </div>
      ),
    },
  ]);

  // Table Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  //Table columns properties applies to all columns
  const defaultColDef = useMemo(() => ({
    flex: 1,
    sortable: true,
    filter: "agTextColumnFilter",
    floatingFilter: true,
    cacheQuickFilter: true,
    cellClass: "no-border",
  }));

  useEffect(() => {
    getLeaveSettingList();
    getLeaveQuotaList();
  }, []);

  const getLeaveQuotaList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetLeaveQuotaList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Quota List", response.data);
      setLeaveQuotaList(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const getLeaveSettingList = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/GetActiveLeaveSettingList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      );
      console.log("Leave Setting List", response.data);
      setRowData(response.data);
    } catch (error) {
      console.error(error);
    }
  };

  const HandleLeaveSettingsSwitch = async (checked, LeaveData) => {
    setRowData((prev) => {
      const newState = prev.map((obj) => {
        if (obj.leaveSettingId === LeaveData.leaveSettingId) {
          return { ...obj, isActive: checked };
        }
        return obj;
      });
      return newState;
    });

    let body = {
      srNo: LeaveData.srNo,
      leaveSettingId: LeaveData.leaveSettingId,
      leaveTypeName: LeaveData.leaveTypeName,
      backgroundColor: LeaveData.backgroundColor,
      fontColor: LeaveData.fontColor,
      policyName: LeaveData.policyName,
      perYearLeave: LeaveData.perYearLeave,
      perMonthlyLeaves: LeaveData.perMonthlyLeaves,
      monthyBasisEarningLeaves: LeaveData.monthyBasisEarningLeaves,
      applicableInNoticePeriod: LeaveData.applicableInNoticePeriod,
      applicableInProbationPeriod: LeaveData.applicableInProbationPeriod,
      empLeaveCancellaion: LeaveData.empLeaveCancellaion,
      leavesInStartingofYear: LeaveData.leavesInStartingofYear,
      leaveAppliedBeforeDays: LeaveData.leaveAppliedBeforeDays,
      medicalRequired: LeaveData.medicalRequired,
      medicalAfterNoOfDays: LeaveData.medicalAfterNoOfDays,
      presentConsiderBy: LeaveData.presentConsiderBy,
      isActive: checked,
      companyId: LeaveData.companyId,
      branchId: LeaveData.branchId,
      createdBy: LeaveData.createdBy,
      createdOn: LeaveData.createdOn,
      modifiedBy: LeaveData.modifiedBy,
      modifiedOn: LeaveData.modifiedOn,
      shortName: LeaveData.shortName,
      remark: LeaveData.remark,
    };

    console.log("Switch Body", body);

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/SAVELeaveSettings`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getLeaveSettingList();
        getLeaveQuotaList();
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Leave Setting not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };

  console.log("Leave Type ID", LeaveTypeName);

  // geeta save leave setting
  const SaveLeaveSetting = async (e) => {
    e.preventDefault();
    let body = {
      leaveSettingId: LeaveSettingID,
      leaveQuotaId: LeaveTypeName,
      policyName: PolicyName,
      perYearLeave: PerYearLeave,
      perMonthlyLeaves: parseInt(PerMonthlyLeaves || 0),
      monthyBasisEarningLeaves: MonthyBasisEarningLeaves,
      applicableInNoticePeriod: ApplicableInNoticePeriod,
      applicableInProbationPeriod: ApplicableInProbationPeriod,
      empLeaveCancellaion: EmpLeaveCancellaion,
      leavesInStartingofYear: LeavesInStartingofYear,
      leaveAppliedBeforeDays: parseInt(LeaveAppliedBeforeDays || 0),
      medicalRequired: isMedicalApply,
      medicalAfterNoOfDays: parseInt(MedicalAfterNoOfDays || 0),
      presentConsiderBy: PresentConsiderBy,
      isActive: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    };
    console.log(body);

    
    let body1 = {
      leaveQuotaId: 1,
      isCompoff: true,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
    }


    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/Leave/SAVELeaveSettings`,
        body
      )
      .then((response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }

        getLeaveSettingList();
        getLeaveQuotaList();
        setLeaveSettingID(0);
        setLeaveTypeName("");
        setPolicyName("");
        setPerYearLeave("");
        setPerMonthlyLeaves("");
        setMonthyBasisEarningLeaves(false);
        setApplicableInNoticePeriod(false);
        setApplicableInProbationPeriod(false);
        setEmpLeaveCancellaion(false);
        setLeavesInStartingofYear(false);
        setLeaveAppliedBeforeDays("");
        setMedicalAfterNoOfDays("");
        setPresentConsiderBy("1");
        setisMedicalApply(false);
      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Leave Setting not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };
  // geeta save leave setting

  return (
    <div>
      <div className="border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Leave Settings</h1>
      </div>

      <div className="mb-6">
        <Accordion
          defaultIndex={[0]}
          shadow="lg"
          border="transparent"
          rounded="xl"
          allowMultiple
          bg="white"
        >
          <AccordionItem>
            <h2>
              <AccordionButton>
                <h6 className="text-xl text-left  flex-1 font-bold  text-gray-800">
                  Add Leave Settings
                </h6>
                <AccordionIcon />
              </AccordionButton>
            </h2>
            <AccordionPanel pb={4}>
              <form onSubmit={SaveLeaveSetting}>
                <div className="flex gap-8 my-4">
                  <div className="space-y-2 w-1/4">
                    <label>Leave Type</label>
                    <Select
                      value={LeaveTypeName}
                      onChange={(e) => {
                        setLeaveTypeName(e.target.value);
                      }}
                      borderColor="gray"
                      placeholder="Select Leave Type"
                    >
                      {LeaveQuotaList.map((data, i) => {
                        return (
                          <option key={i} value={data.leaveQuotaId}>
                            {data.leaveDescription}
                          </option>
                        );
                      })}
                    </Select>
                  </div>
                  <div className="space-y-2 w-1/4">
                    <label>Policy Name</label>
                    <Select
                      value={PolicyName}
                      onChange={(e) => {
                        setPolicyName(e.target.value);
                      }}
                      borderColor="gray"
                      placeholder="Select Policy Name"
                    >
                      <option value="1">Leave Policy</option>
                      <option value="2">Company Policy</option>
                      <option value="3">Asset Policy</option>
                    </Select>
                  </div>
                  <div className="space-y-2 w-1/4">
                    <label>Per Year Leaves</label>
                    <Input
                      value={PerYearLeave || ""}
                      onChange={({ target }) => {
                        setPerYearLeave(target.value);
                        setPerMonthlyLeaves(parseFloat(target.value / 12));
                      }}
                      borderColor="gray"
                      placeholder="0"
                    />
                  </div>
                  <div className="space-y-2 w-1/4">
                    <label>Per Month Leaves</label>
                    <Input
                      value={PerMonthlyLeaves || ""}
                      onChange={({ target }) => {
                        setPerMonthlyLeaves(target.value);
                      }}
                      borderColor="gray"
                      placeholder="0"
                    />
                  </div>
                </div>

                <div className="grid grid-cols-5 gap-8 my-6">
                  <div className="space-y-2 ">
                    <label className="block">
                      Leaves Should Be Credited On :
                    </label>
                    <Checkbox
                      isChecked={MonthyBasisEarningLeaves}
                      onChange={({ target }) => {
                        setMonthyBasisEarningLeaves(target.checked);
                      }}
                      borderColor="gray"
                    >
                      Monthly Earning Leave
                    </Checkbox>
                  </div>
                  <div className="space-y-2 ">
                    <label className="block">Applicable In Notice :</label>
                    <Checkbox
                      isChecked={ApplicableInNoticePeriod}
                      onChange={({ target }) => {
                        setApplicableInNoticePeriod(target.checked);
                      }}
                      borderColor="gray"
                    >
                      Yes
                    </Checkbox>
                  </div>
                  <div className="space-y-2">
                    <label className="block">
                      Applicable In Probation Period :
                    </label>
                    <Checkbox
                      isChecked={ApplicableInProbationPeriod}
                      onChange={({ target }) => {
                        setApplicableInProbationPeriod(target.checked);
                      }}
                      borderColor="gray"
                    >
                      Yes
                    </Checkbox>
                  </div>
                  <div className="space-y-2 ">
                    <label className="block">
                      Leave Cancellable By Employee :
                    </label>
                    <Checkbox
                      isChecked={EmpLeaveCancellaion}
                      onChange={({ target }) => {
                        setEmpLeaveCancellaion(target.checked);
                      }}
                      borderColor="gray"
                    >
                      Yes
                    </Checkbox>
                  </div>

                  <div className="space-y-2">
                  <label>Compensation Offs Adjustment</label>
                  <Checkbox isChecked={CompOffApplicable} onChange={({target})=>{setCompOffApplicable(target.checked)}} borderColor='gray.400'>Applicable?</Checkbox>
                </div>
                
                </div>

                <div className="grid grid-cols-4 gap-8 my-6">
                  <div className="space-y-2">
                    <label className="block">
                      Can Take in Starting of the year : :
                    </label>
                    <Checkbox
                      isChecked={LeavesInStartingofYear}
                      onChange={({ target }) => {
                        setLeavesInStartingofYear(target.checked);
                      }}
                      borderColor="gray"
                    >
                      Yes
                    </Checkbox>
                  </div>
                  <div className="space-y-2 ">
                    <label className="block">
                      Leave To Be Applied Before :
                    </label>
                    <div className="flex gap-2 items-center">
                      <div className="flex-1">
                        <Input
                          value={LeaveAppliedBeforeDays || ""}
                          onChange={({ target }) => {
                            setLeaveAppliedBeforeDays(target.value);
                          }}
                          borderColor="gray"
                          placeholder="0"
                        />
                      </div>
                      <p>Days</p>
                    </div>
                  </div>
                  <div className="space-y-2 ">
                    <label className="block">Documents Required After :</label>
                    <div className="flex gap-2 items-center">
                      <Checkbox
                        isChecked={isMedicalApply}
                        onChange={({ target }) => {
                          setisMedicalApply(target.checked);
                        }}
                        borderColor="gray"
                      >
                        Yes
                      </Checkbox>
                      <div className="flex-1">
                        <Input
                          value={MedicalAfterNoOfDays || ""}
                          onChange={({ target }) => {
                            setMedicalAfterNoOfDays(target.value);
                          }}
                          isDisabled={isMedicalApply === false ? true : false}
                          borderColor="gray"
                          placeholder="0"
                        />
                      </div>
                      <p>Days</p>
                    </div>
                  </div>
                  <div className="space-y-2 ">
                    <label className="block">Present Consider By :</label>
                    <div className="flex gap-2 items-center">
                      <RadioGroup
                        value={PresentConsiderBy}
                        onChange={setPresentConsiderBy}
                      >
                        <div className="space-x-10">
                          <Radio size="lg" value="1">
                            Yes
                          </Radio>
                          <Radio size="lg" value="2">
                            No
                          </Radio>
                        </div>
                      </RadioGroup>
                    </div>
                  </div>
                </div>

                <div className="space-x-3 my-2 text-right">
                  <Button
                    onClick={() => {
                      setLeaveSettingID(0);
                      setLeaveTypeName("");
                      setPolicyName("");
                      setPerYearLeave("");
                      setPerMonthlyLeaves("");
                      setMonthyBasisEarningLeaves(false);
                      setApplicableInNoticePeriod(false);
                      setApplicableInProbationPeriod(false);
                      setEmpLeaveCancellaion(false);
                      setLeavesInStartingofYear(false);
                      setLeaveAppliedBeforeDays("");
                      setMedicalAfterNoOfDays("");
                      setPresentConsiderBy("1");
                      setisMedicalApply(false);
                    }}
                    variant="outline"
                    colorScheme="purple"
                  >
                    Reset
                  </Button>
                  <Button type="submit" colorScheme="purple">
                    Save
                  </Button>
                </div>
              </form>
            </AccordionPanel>
          </AccordionItem>
        </Accordion>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
        />
      </div>
    </div>
  );
}

export default LeaveSettings;
