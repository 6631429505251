import { Button,  Drawer,
    DrawerBody,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    useToast,
    Input,
    Switch,
    Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton, } from '@chakra-ui/react'
import { faPlus } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import axios from 'axios';
import React, {
    useState,
    useRef,
    useEffect,
    useMemo,
    useCallback,
  } from "react";
  import { AgGridReact } from "ag-grid-react";
import ReactSelect from 'react-select';
import { CheckCircle, Edit } from 'react-feather';

function AddEmpGeoFencing() {

    const userData = JSON.parse(localStorage.getItem("user"));
    const toast = useToast();
    const id = "toast";


    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen:isEditModalOpen, onOpen :onEditModalOpen, onClose : onEditModelClose } = useDisclosure()

    const { isOpen:isAddEmpGeoModalOpen, onOpen :onAddEmpGeoModalOpen, onClose : onAddEmpGeoModelClose } = useDisclosure()


    const [EmpList, setEmpList] = useState([]);
    const [SelectedEmp, setSelectedEmp] = useState([]);

    const [SelectedEmpList, setSelectedEmpList] = useState([]);

    // const render = (status: Status) => {
    //   return <h1>{status}</h1>;
    // };
    

    const [GeoDistance, setGeoDistance] = useState('200');
    const [Latitude, setLatitude] = useState();
    const [Longitude, setLongitude] = useState();
    const [WorkingLocation, setWorkingLocation] = useState();
    
    const gridRef = useRef();
    const [rowData, setRowData] = useState([ ]); // Table Data Variable
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [columnDefs, setColumnDefs] = useState([
      {
        headerName: "Emp code",
        field: "empcode",
      },
      {
        headerName: "Emp Name",
        field: "empName",
      },
      {
        headerName: "Geo Distance",
        field: "geoDistance",
      },
      {
        headerName: "Latitude",
        field: "latitude",
      },
      {
        headerName: "Longitude",
        field: "longitude",
      },
      {
        headerName: "Geo Location",
        field: "geoLink",
      },
      {
        headerName: "Is Active",
        field: "isActive",
        cellRenderer:(params) => <Switch colorScheme={'green'} size={'md'} isChecked={params.data.isActive}           onChange={({ target }) => {
          HandleGeoSwitch(target.checked, params.data);
        }} />
      },
      {
        headerName: "Action",
        floatingFilter: false,
        cellRenderer: (params) => (
          params.data.isActive === false ?
          <div className="space-x-4">

            <Button onClick={async()=>{

              let GeoData = params.data

               let body1 = {
                empGuId: GeoData.empGuId,
                gId: GeoData.gId,
                approvedByHR: true,
                // approvedByReportingManager: true,
                finalStatus: "Approve",
                approvedByHRId: String(userData.guId),
                // approvedByReportingManagerId: "Emp-5",
                companyId: userData.companyId,
                branchId: userData.isActiveBranch
              }

                            
    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveAttendanceGeofencingApproval`,body1
    )
    .then((response) => {
      const res = response.data;
      console.log("res",res);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: res,
          position: 'top',
          status: 'success',
          duration: 2000,
          isClosable: true,
        })
      }

      getGeoFencingData();

    })
    .catch((error) => {
      console.log(error.response);
      console.log(error.response.data);
      if (!toast.isActive(id)) {
        toast({
          id,
          title: 'ERROR',
          description: "Emp Geo Fencing Not Created",
          position: 'top',
          status: 'error',
          duration: 2000,
          isClosable: true,
        })
      }
    });


              

            }} variant="solid" size="xs" colorScheme="green">
              <CheckCircle size={'14px'} className='mr-1'/>
              Approve              
            </Button>
            <Button onClick={()=>{
              setSelectedEmp(params.data);

              setGeoDistance(params.data.geoDistance);
              setLatitude(params.data.latitude);
              setLongitude(params.data.longitude);
              setWorkingLocation(params.data.geoLink);

              
              onEditModalOpen();
            }} variant="solid" size="xs" colorScheme="blue">
              <Edit size="14px" className="mr-1" />
              Edit
            </Button>
          </div> :           
          <div className="space-x-4">

<Button onClick={()=>{
  setSelectedEmp(params.data);

  setGeoDistance(params.data.geoDistance);
  setLatitude(params.data.latitude);
  setLongitude(params.data.longitude);
  setWorkingLocation(params.data.geoLink);

  onEditModalOpen();
}} variant="solid" size="xs" colorScheme="blue">
  <Edit size="14px" className="mr-1" />
  Edit
</Button>

{/* <Button 
 onClick={async()=>{
  await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/Attendance/DeletegeoById?GId=${params.data.gId}&CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`)
  .then((response) => {
    const res = response.data;
    console.log("res",res);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }

    getGeoFencingData();

  })
  .catch((error) => {
    console.log(error.response);
    console.log(error.response.data);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: 'ERROR',
        description: "Emp Geo Fencing Not Deleted",
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  });

 }}
colorScheme='red' size={'xs'} leftIcon={<FontAwesomeIcon icon={faTrash} />}>Delete</Button> */}
</div> )
      },
    ]);
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
    //Table columns properties applies to all columns
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
      cellClass: "no-border",
      resizable: true
    }));


    const HandleGeoSwitch = async (Checked,GeoData) => {

      setRowData((prev) => {
        const newState = prev.map((obj) => {
          if (obj.gId === GeoData.gId) {
            return { ...obj, isActive: Checked };
          }
          return obj;
        });
        return newState;
      });

      let body ={
        gId:GeoData.gId,
        empGuId: GeoData.empGuId,
        empName: GeoData.empName,
        empcode: GeoData.empcode,
        geoDistance: GeoData.geoDistance,
        latitude: GeoData.latitude,
        longitude: GeoData.longitude,
        companyId: GeoData.companyId,
        branchId: GeoData.branchId,
        isActive: Checked,
        geoLink: GeoData.geoLink
      }

      console.log("Switch Geo Fencing Body",body);

          
await axios.post(
    `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveAttendanceGeofencing`,body
  )
  .then((response) => {
    const res = response.data;
    console.log("res",response);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: res,
        position: 'top',
        status: 'success',
        duration: 2000,
        isClosable: true,
      })
    }

    getGeoFencingData();
  })
  .catch((error) => {
    console.log(error.response);
    console.log(error.response.data);
    if (!toast.isActive(id)) {
      toast({
        id,
        title: 'ERROR',
        description: "Emp Geo Fencing Not Created",
        position: 'top',
        status: 'error',
        duration: 2000,
        isClosable: true,
      })
    }
  });


    }

    useEffect(()=>{
        getEmployeeList();
        getGeoFencingData();
      },[])


    const getEmployeeList = async() =>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/EmployeeDetail/GetEmployeelist?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Employee List",response.data);
          const res = response.data;
          let ActiveEmpList = res.filter((data)=>data.isActive === true);
          setEmpList(ActiveEmpList);
        } catch (error) {
          console.error(error)
        }
      }


      const getGeoFencingData = async() =>{
        try {
          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/Attendance/GetAttendanceGeofencingList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          console.log("Geo Fencing Data",response);
          setRowData(response.data);

        } catch (error) {
          console.error(error)
        }
      }

console.log(SelectedEmp);

      const SaveEmpGeoFencing = async () => {


        let body ={
            empGuId: SelectedEmp.guId,
            empName: SelectedEmp.fullName,
            empcode: SelectedEmp.empCode,
            geoDistance: GeoDistance,
            latitude: Latitude,
            longitude: Longitude,
            companyId: userData.companyId,
            branchId: userData.isActiveBranch,
            isActive: false,
            geoLink: WorkingLocation
          }

          console.log("Save Geo Fencing Body",body);

              
    await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveAttendanceGeofencing`,body
      )
      .then((response) => {
        const res = response.data;
        console.log("res",res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: 'top',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })
        }

        getGeoFencingData();
        onClose();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'ERROR',
            description: "Emp Geo Fencing Not Created",
            position: 'top',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      });
      }

      const onSelectionChanged = useCallback(() => {
        var EmpList = gridRef.current.api.getSelectedRows();
       setSelectedEmpList(EmpList);
      }, []);
  
      const EditEmpGeoFencing = async (e) => {
        e.preventDefault();


        let body ={
          attendanceTime: SelectedEmp.attendanceTime,
          gId: SelectedEmp.gId,
          empGuId: SelectedEmp.empGuId,
          empName: SelectedEmp.empName,
          empcode: SelectedEmp.empcode,
          geoDistance: GeoDistance,
          latitude: Latitude,
          longitude:Longitude,
          companyId:userData.companyId,
          branchId: userData.isActiveBranch,
          imagePath: SelectedEmp.imagePath,
          attendanceDate: SelectedEmp.attendanceDate,
          isActive: false,
          location: SelectedEmp.location,
          geoLocationStatus: SelectedEmp.geoLocationStatus,
          capturingImage: SelectedEmp.capturingImage,
          linkDevice: SelectedEmp.linkDevice,
          inTime: SelectedEmp.inTime,
          outTime: SelectedEmp.outTime,
          attendanceStatus: SelectedEmp.attendanceStatus,
          geoLink: WorkingLocation
      }

          console.log("Edit Geo Fencing Body",body);

              
    await axios.post(
        `${process.env.REACT_APP_ACQ_URL}/api/Attendance/SaveAttendanceGeofencing`,body
      )
      .then((response) => {
        const res = response.data;
        console.log("res",res);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: 'top',
            status: 'success',
            duration: 2000,
            isClosable: true,
          })
        }

        getGeoFencingData();
        onClose();
      })
      .catch((error) => {
        console.log(error.response);
        console.log(error.response.data);
        if (!toast.isActive(id)) {
          toast({
            id,
            title: 'ERROR',
            description: "Emp Geo Fencing Not Updated",
            position: 'top',
            status: 'error',
            duration: 2000,
            isClosable: true,
          })
        }
      });
      }


  return (
    <div>
      <div className=" flex justify-between items-center border-b border-slate-400 pb-2 mb-4">
        <h1 className="text-xl font-bold text-gray-800">Employee Geo-Fencing Details</h1>
        <div className='space-x-4'>
        {/* <Button onClick={onAddEmpGeoModalOpen} size='sm' colorScheme="purple"><FontAwesomeIcon icon={faPlus} className="mr-2"/>Add Geo Fencing With Map</Button> */}
        <Button onClick={onOpen} size='sm' colorScheme="purple"><FontAwesomeIcon icon={faPlus} className="mr-2"/>Add Geo Fencing Details</Button>

        </div>
      </div>

      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={rowData} // Row Data for Rows
          columnDefs={columnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={15}
          paginationNumberFormatter={paginationNumberFormatter}
          onSelectionChanged={onSelectionChanged}
          rowSelection={'multiple'}
        />
      </div>


      <Drawer
        isOpen={isOpen}
        placement='right'
        size="md"
        onClose={onClose}
      >
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>Add Geo-Fencing Details</DrawerHeader>

          <hr />
          <DrawerBody className="space-y-4">

          <div className="my-2">
             <ReactSelect
               options={EmpList}
               value={SelectedEmp}
               onChange={(value) => {setSelectedEmp(value);}}
               getOptionLabel={(option) => `${option.fullName}  ${option.empCode}`}
               getOptionValue={(option) => option.guId}
               placeholder="Select Employee"
              />
            </div>

            {!SelectedEmp.guId || <>

            <div className="space-y-2">
              <h6 className='font-medium'>Employee Name: <span className='font-normal'>{SelectedEmp.firstName} {SelectedEmp.lastName}</span></h6>
              <h6 className='font-medium'>Employee Code: <span className='font-normal'>{SelectedEmp.empCode}</span></h6>
              <h6 className='font-medium'>Department: <span className='font-normal'>{SelectedEmp.department}</span></h6>
              <h6 className='font-medium'>Designation: <span className='font-normal'>{SelectedEmp.designation}</span></h6>
              {/* <h6 className='font-medium'>Reporting Person: <span className='font-normal'>{SelectedEmp.reportingOfficename}</span></h6> */}
            </div>
            <hr />

            <div className='space-y-2'>
              <label>Geo Distance</label>
              <Input value={GeoDistance || ''} onChange={({target})=>{setGeoDistance(target.value)}} borderColor='gray.500' placeholder="Geo Distance" />
            </div>

            <div className='space-y-2'>
              <label>Latitude</label>
              <Input  value={Latitude || ''} onChange={({target})=>{setLatitude(target.value)}}  borderColor='gray.500' placeholder="Latitude" />
            </div>

            <div className='space-y-2'>
              <label>Longitude</label>
              <Input  value={Longitude || ''} onChange={({target})=>{setLongitude(target.value)}}  borderColor='gray.500' placeholder="Longitude" />
            </div>

            <div className='space-y-2'>
              <label>Working Location</label>
              <Input  value={WorkingLocation || ''} onChange={({target})=>{setWorkingLocation(target.value)}}  borderColor='gray.500' placeholder="Working Location" />
            </div>

               </>}
            <div className='space-x-4 text-right my-4'>
                <Button onClick={()=>{SaveEmpGeoFencing()}} variant={'solid'} colorScheme={'purple'}>Save</Button>
                <Button onClick={onClose} variant={'outline'} colorScheme={'purple'}>Cancel</Button>
            </div>


          </DrawerBody>

        </DrawerContent>
      </Drawer>


      <Modal size={'2xl'} isOpen={isEditModalOpen} onClose={onEditModelClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Geo-Fencing Details</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={EditEmpGeoFencing}>
<hr />

          <ModalBody>

          <div className="flex py-2 gap-8">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Employee Name</p>
                  <h6 className="font-medium">{SelectedEmp.empName}</h6>
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Emp Code</p>
                  <h6 className="font-medium">{SelectedEmp.empcode}</h6>
                </div>
              </div>

              <hr />

            <div className='grid grid-cols-2 gap-6 mt-2'>

            <div className='space-y-2'>
              <label>Geo Distance</label>
              <Input value={GeoDistance || ''} onChange={({target})=>{setGeoDistance(target.value)}} borderColor='gray.500' placeholder="Geo Distance" />
            </div>

            <div className='space-y-2'>
              <label>Latitude</label>
              <Input  value={Latitude || ''} onChange={({target})=>{setLatitude(target.value)}}  borderColor='gray.500' placeholder="Latitude" />
            </div>

            <div className='space-y-2'>
              <label>Longitude</label>
              <Input  value={Longitude || ''} onChange={({target})=>{setLongitude(target.value)}}  borderColor='gray.500' placeholder="Longitude" />
            </div>

            <div className='space-y-2'>
              <label>Working Location</label>
              <Input  value={WorkingLocation || ''} onChange={({target})=>{setWorkingLocation(target.value)}}  borderColor='gray.500' placeholder="Working Location" />
            </div>

            </div>

          </ModalBody>
   <hr />
          <ModalFooter>
          <Button  variant='outline' colorScheme='purple' mr={4} onClick={()=>{
        onEditModelClose();
      }}>
              Close
            </Button>
            <Button type="submit" colorScheme='purple' variant='solid'>Save</Button>

          </ModalFooter>

          </form>
        </ModalContent>
      </Modal>

      <Modal size={'5xl'} isOpen={isAddEmpGeoModalOpen} onClose={onAddEmpGeoModelClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Geo-Fencing Details</ModalHeader>
          <ModalCloseButton />
          <form onSubmit={EditEmpGeoFencing}>
         <hr />
          <ModalBody>
          <div className="my-2 w-1/2 mx-auto">
             <ReactSelect
               options={EmpList}
               value={SelectedEmp}
               onChange={(value) => {setSelectedEmp(value);}}
               getOptionLabel={(option) => `${option.fullName}  ${option.empCode}`}
               getOptionValue={(option) => option.guId}
               placeholder="Select Employee"
              />
            </div>
            <hr />
          <div className="flex py-2 gap-8">
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Employee Name</p>
                  <h6 className="font-medium">{SelectedEmp.fullName}</h6>
                </div>
                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Emp Code</p>
                  <h6 className="font-medium">{SelectedEmp.empCode}</h6>
                </div>

                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Department</p>
                  <h6 className="font-medium">{SelectedEmp.department}</h6>
                </div>

                <div className="space-y-1">
                  <p className="text-sm text-gray-500">Designation</p>
                  <h6 className="font-medium">{SelectedEmp.designation}</h6>
                </div>
              </div>

              <hr />

            <div className='grid grid-cols-2 gap-6 mt-2'>



              <div className='space-y-4'>
              <div className='space-y-2'>
              <label>Geo Distance</label>
              <Input value={GeoDistance || ''} onChange={({target})=>{setGeoDistance(target.value)}} borderColor='gray.500' placeholder="Geo Distance" />
            </div>

            <div className='space-y-2'>
              <label>Latitude</label>
              <Input  value={Latitude || ''} onChange={({target})=>{setLatitude(target.value)}}  borderColor='gray.500' placeholder="Latitude" />
            </div>

            <div className='space-y-2'>
              <label>Longitude</label>
              <Input  value={Longitude || ''} onChange={({target})=>{setLongitude(target.value)}}  borderColor='gray.500' placeholder="Longitude" />
            </div>

            <div className='space-y-2'>
              <label>Working Location</label>
              <Input  value={WorkingLocation || ''} onChange={({target})=>{setWorkingLocation(target.value)}}  borderColor='gray.500' placeholder="Working Location" />
            </div>
              </div>



            </div>

          </ModalBody>
   <hr />
          <ModalFooter>
          <Button  variant='outline' colorScheme='purple' mr={4} onClick={()=>{
        onAddEmpGeoModelClose();
      }}>
              Close
            </Button>
            <Button type="submit" colorScheme='purple' variant='solid'>Save</Button>

          </ModalFooter>

          </form>
        </ModalContent>
      </Modal>

    </div>
  )
}

export default AddEmpGeoFencing