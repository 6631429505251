import React, { useState } from "react";
import {
  Input,
  InputGroup,
  InputRightElement,
  InputLeftElement,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  PinInput,
  PinInputField,
  HStack,
  useToast,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { Eye, EyeOff, Lock, User } from "react-feather";
import QRScanner from "./OtherComponents/QRScanner";
import usePost from "./Utils/usePost";
import { useAuth } from "./Utils/AuthContext";
import axios from "axios";
const Login = () => {
  const { userLogin } = useAuth();
  const toast = useToast();
  const [show, setShow] = useState(false);

  const { IsPostLoading: IsLoginLoading, PostData: PostLoginData } = usePost(
    `/api/Account/VerifyUserLogin`
  );
  const {
    IsPostLoading: IsResetPasswordLoading,
    PostData: PostResetPasswordData,
  } = usePost(`/api/Account/HardResetPassword`);

  const [ResetPasswordEmp, setResetPasswordEmp] = useState([]);

  const {
    isOpen: isGetEmailAddOpen,
    onOpen: onGetEmailAddOpen,
    onClose: onGetEmailAddClose,
  } = useDisclosure();

  const {
    isOpen: isVerifyOtpOpen,
    onOpen: onVerifyOtpOpen,
    onClose: onVerifyOtpClose,
  } = useDisclosure();

  const {
    isOpen: isResetPasswordOpen,
    onOpen: onResetPasswordOpen,
    onClose: onResetPasswordClose,
  } = useDisclosure();

  const [ResetNewPassword, setResetNewPassword] = useState();
  const [ConfirmResetPassword, setConfirmResetPassword] = useState();
  const [RememberUser, setRememberUser] = useState(false);
  const [ComparePassword, setComparePassword] = useState(true);
  const [OTPInput, setOTPInput] = useState();
  const [EmailVerifyMessage, setEmailVerifyMessage] = useState(null);
  const [VerifyEmailAdd, setVerifyEmailAdd] = useState();
  const [signemail, setSignEmail] = useState("");
  const [signpassword, setSignPassword] = useState("");
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    let body = {
      userName: signemail,
      password: signpassword,
    };

    const response = await PostLoginData(body);
    if (response) {
      if (response === "Failed") {
        alert("Invalid Credentials / Login Failed");
      } else if (response === "Invalid User.") {
        alert("Invalid User");
      } else {
        userLogin(response, true);

        if (response?.companyId === 0) {
          navigate("/createcompany");
        } else {
          if (response?.isActiveBranch === 0) {
            navigate("/createbranch");
          } else {
            if (!response?.isPasswordReset) {
              navigate("/changepassword");
            } else {
              navigate("/dashboard");
            }
          }
        }
      }
    }
  };

  const HandleVerifyEmailAddress = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/ForgotPassword?UserName=${VerifyEmailAdd}`
      );
      if (response.data) {
        onVerifyOtpOpen();
        onGetEmailAddClose();
      } else {
        setEmailVerifyMessage("Incorrect Email Entered!");
      }
    } catch (error) {
      console.error(error);
    }
  };

  const HandleVerifyOTP = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Account/VerifyOTP?UserName=${VerifyEmailAdd}&OTP=${OTPInput}`
      );

      const res = response.data;
      if (res.errorMessage === "true") {
        setResetPasswordEmp(res);
        onVerifyOtpClose();
        onResetPasswordOpen();
      } else {
        setEmailVerifyMessage("Incorrect OTP Entered");
      }
    } catch (err) {
      console.error(err);
    }
  };

  const HandleResetPassword = async (e) => {
    e.preventDefault();

    if (ComparePassword) {
      let body = {
        empGuId: ResetPasswordEmp.empGuId,
        userName: ResetPasswordEmp.userName,
        password: ResetNewPassword,
        confirmPassword: ConfirmResetPassword,
        companyId: ResetPasswordEmp.companyId,
        branchId: ResetPasswordEmp.isActiveBranch,
      };

      console.log("Reset Password Body", body);

      const response = await PostResetPasswordData(body);

      console.log("Reset Password Response", response);

      if (response) {
        setResetNewPassword("");
        setConfirmResetPassword("");
        onResetPasswordClose();
      } else {
        console.error("Failed to reset password.");
        toast({
          title: "Error",
          description: "Failed to reset password. Please try again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  return (
    <>
      <div className="bg-gradient-to-br from-pink-300 via-purple-300 to-indigo-400 h-screen flex">
        <div className="container px-5 py-14 md:py-24 mx-auto my-auto flex flex-wrap items-center ">
          <div className="lg:w-3/5 md:w-1/2 md:pr-16 lg:pr-0 pr-0 space-y-3">
            <div>
              <img src="./HRMusterLogo.png" className="h-28" alt="TSPL MIS Logo" />
            </div>
            <h6 className="font-bold text-2xl lg:text-5xl text-gray-700">Streamline</h6>
            <h6 className="font-bold text-2xl lg:text-5xl text-gray-700">Your Workforce,</h6>
            <h6 className="font-bold text-2xl lg:text-5xl text-gray-700">Simplify Your HR -</h6>
            <h6 className="font-bold text-2xl lg:text-5xl text-gray-700">with TSPL MIS Software!!</h6>
          </div>

          <div className="relative lg:w-[30%] md:w-1/2 w-full">
            <div className="absolute inset-0 bg-gradient-to-r from-blue-300 to-blue-600 shadow-lg transform -skew-y-12 sm:skew-y-0 sm:-rotate-[8deg] rounded-3xl drop-shadow-md"></div>

            <form
              onSubmit={handleSubmit}
              className="bg-gray-50 rounded-2xl drop-shadow-md p-8 flex flex-col md:ml-auto mt-10 md:mt-0 space-y-2"
            >
              <div className="flex items-center gap-4 border-b pb-3 border-gray-300">
                <img src="./HRMusterLogo.png" className="h-14" alt="TSPL MIS Logo" />
                <h2 className="text-gray-800 text-3xl font-bold ">TSPL MIS</h2>
              </div>
              <h2 className="text-gray-800 text-2xl font-medium ">Login To Your Account</h2>
              <div className="space-y-2">
                <label>Email Address</label>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <User size={"17px"} className="text-gray-500" />
                  </InputLeftElement>
                  <Input
                    isRequired
                    value={signemail || ""}
                    onChange={({ target }) => {
                      setSignEmail(target.value);
                    }}
                    type="email"
                    borderColor="gray"
                    placeholder="Enter Email Address"
                  />
                </InputGroup>
              </div>
              <div className="space-y-2">
                <label>Password</label>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <Lock size={"17px"} className="text-gray-500" />
                  </InputLeftElement>
                  <Input
                    isRequired
                    value={signpassword || ""}
                    onChange={(e) => {
                      setSignPassword(e.target.value);
                    }}
                    borderColor="gray"
                    type={show ? "text" : "password"}
                    placeholder="Enter Password"
                  />
                  <InputRightElement width="2.5rem">
                    <Button h="1.75rem" size="xs" onClick={() => { setShow(!show); }}>
                      {show ? <EyeOff size="14px" /> : <Eye size="14px" />}
                    </Button>
                  </InputRightElement>
                </InputGroup>
              </div>

              <div className="flex justify-between">
                <span></span>
                <Button
                  onClick={() => {
                    setEmailVerifyMessage(null);
                    onGetEmailAddOpen();
                  }}
                  size={"sm"}
                  colorScheme="blue"
                  variant="link"
                >
                  Forgot Password?
                </Button>
              </div>
              <Button
                type="submit"
                isLoading={IsLoginLoading}
                loadingText="Signing In"
                colorScheme="purple"
                w={"full"}
              >
                Submit
              </Button>
              <div className="text-center">
                <span className="text-xs text-gray-50 hover:text-blue-600">OR</span>
                <QRScanner />
              </div>
            </form>
          </div>
        </div>
      </div>

      <Modal isOpen={isGetEmailAddOpen} onClose={onGetEmailAddClose} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">Forgot Password?</ModalHeader>
          <ModalCloseButton mt={1} />
          <form onSubmit={HandleVerifyEmailAddress}>
            <ModalBody>
              <div className="space-y-2">
                <label>Enter Email Address</label>
                <Input
                  isRequired
                  value={VerifyEmailAdd || ""}
                  onChange={({ target }) => {
                    setVerifyEmailAdd(target.value);
                  }}
                  borderColor={"gray.400"}
                  placeholder={"Enter Email Address"}
                  type="email"
                />
                {EmailVerifyMessage && (
                  <label className="animate-pulse text-sm text-red-500">
                    Incorrect E-mail Address. Please Enter the Correct E-mail
                  </label>
                )}
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button type="submit" colorScheme={"purple"}>
                Get OTP
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isVerifyOtpOpen} onClose={onVerifyOtpClose} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">
            Verify OTP
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <form onSubmit={HandleVerifyOTP}>
            <ModalBody className="space-y-4">
              <div className="space-y-2 text-center">
                <HStack justifyContent={"center"}>
                  <PinInput otp value={OTPInput} onChange={setOTPInput}>
                    <PinInputField borderColor={"gray.400"} />
                    <PinInputField borderColor={"gray.400"} />
                    <PinInputField borderColor={"gray.400"} />
                    <PinInputField borderColor={"gray.400"} />
                    <PinInputField borderColor={"gray.400"} />
                    <PinInputField borderColor={"gray.400"} />
                  </PinInput>
                </HStack>

                {EmailVerifyMessage && (
                  <label className="animate-pulse text-sm text-red-600">
                    Incorrect OTP! Please Enter Correct OTP.
                  </label>
                )}
              </div>
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button type="submit" colorScheme={"purple"}>
                Verify
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>

      <Modal isOpen={isResetPasswordOpen} onClose={onResetPasswordClose} isCentered closeOnOverlayClick={false}>
        <ModalOverlay />
        <ModalContent rounded={"3xl"}>
          <ModalHeader textColor={"gray.800"} fontSize="lg" backgroundColor={"purple.200"} roundedTop={"3xl"} roundedBottom={"xl"} fontWeight="bold">
            Reset Password
          </ModalHeader>
          <ModalCloseButton mt={1} />
          <form onSubmit={HandleResetPassword}>
            <ModalBody className="space-y-4">
              <div className="space-y-2">
                <label>New Password</label>
                <Input
                  isRequired
                  value={ResetNewPassword || ""}
                  onChange={({ target }) => {
                    setResetNewPassword(target.value);
                  }}
                  borderColor={"gray.400"}
                  placeholder={"New Password"}
                  type="password"
                />
              </div>
              <div className="space-y-2">
                <label>Confirm Password</label>
                <Input
                  isRequired
                  value={ConfirmResetPassword || ""}
                  onChange={({ target }) => {
                    setConfirmResetPassword(target.value);
                    if (target.value !== ResetNewPassword) {
                      setComparePassword(false);
                    } else {
                      setComparePassword(true);
                    }
                  }}
                  borderColor={"gray.400"}
                  placeholder={"New Password"}
                  type="password"
                />
              </div>
              {!ComparePassword ? (
                <span className="text-red-600 text-sm animate-ping">
                  Password doesn't match!!
                </span>
              ) : (
                ""
              )}
            </ModalBody>
            <hr />
            <ModalFooter>
              <Button
                isLoading={IsResetPasswordLoading}
                loadingText="Saving"
                type="submit"
                colorScheme={"purple"}
              >
                Save
              </Button>
            </ModalFooter>
          </form>
        </ModalContent>
      </Modal>
    </>
  );
};

export default Login;
