import React, {useState,useRef,useEffect, useMemo,useCallback} from "react";
import { AgGridReact } from "ag-grid-react";
import axios from "axios";
import DatePicker from "react-datepicker";
import { Spinner ,Center,Radio, Switch, useToast,RadioGroup,Stack, Button} from "@chakra-ui/react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";


function ExpensePaymentInfo() {


    const userData = JSON.parse(localStorage.getItem('user'));
    const [IsLoading, setIsLoading] = useState(false);

    const [PaymentType, setPaymentType] = useState('1');

    const [FilteredEmps, setFilteredEmps] = useState([]);

  const [SelectedMonth, setSelectedMonth] = useState(new Date());
    const gridRef = useRef();
    const [PaymentRowData, setPaymentRowData] = useState([]); // Table Data Variable
    const [AdvanceRowData, setAdvanceRowData] = useState([])
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); //Ag Grid Styling
    const [PayColumnDefs, setPayColumnDefs] = useState([
      {
        headerName: "Ticket No",
        field: "expenseTicketNo",
      },
      {
        headerName: "Emp Code",
        field: "empCode",
      },
        {
          headerName: "Emp Name",
          field: "fullName",
        },


        {
          headerName: "Payment Date",
          field: "paymentDate",
          cellRenderer:(params) => <span>{moment(params.data.paymentDate).format('DD/MM/YYYY')}</span>
        },
          {
            headerName: 'Paid Amount',
            field: "finalAmountPay",
          },

          // {
          //   headerName: "Month",
          //   field: "paymentMonth",
          // },

          // {
          //   headerName: "Year",
          //   field: "paymentYear",
          // },

          // {
          //   headerName: "Payment Status",
          //   field: "paymentStatus",
          // },

          {
            headerName: "Method",
            field: "method",
            valueGetter:(params) => params.data.method === 'Advance' ?  'Advance Adjustment' : params.data.method
          },
      ]);


      const [AdvColumnDefs, setAdvColumnDefs] = useState([
        {
          headerName: "Ticket No",
          field: "expenseTicketNo",
        },
        {
          headerName: "Emp Code",
          field: "empCode",
        },
          {
            headerName: "Emp Name",
            field: "fullName",
          },
  
  
          {
            headerName: "Payment Date",
            field: "paymentDate",
            cellRenderer:(params) => <span>{moment(params.data.paymentDate).format('DD/MM/YYYY')}</span>
          },
            {
              headerName: 'Advance Adjusted',
              field: "finalAmountPay",
            },
  
            // {
            //   headerName: "Month",
            //   field: "paymentMonth",
            // },
  
            // {
            //   headerName: "Year",
            //   field: "paymentYear",
            // },
  
            // {
            //   headerName: "Payment Status",
            //   field: "paymentStatus",
            // },
  
            {
              headerName: "Method",
              field: "method",
              valueGetter:(params) => params.data.method === 'Advance' ?  'Advance Adjustment' : params.data.method
            },
  
  
        ]);
  
                // Table Pagination
                const paginationNumberFormatter = useCallback((params) => {
                    return "[" + params.value.toLocaleString() + "]";
                  }, []);
                
                  //Table columns properties applies to all columns
                  const defaultColDef = useMemo(() => ({
                    flex: 1,
                    sortable: true,
                    filter: "agTextColumnFilter",
                    floatingFilter: true,
                    cacheQuickFilter: true,
                    cellClass: 'no-border',
                    enableCellTextSelection:true
        
                  }));


                  const getDisplayedRow = useCallback(() => {
                    const Rows = gridRef.current.api.getDisplayedRows()
                    console.log('display rowa' + Rows);
                  }, []);
                

                  
    useEffect(() => {
        GetExpensePaymentInfoList(new Date(SelectedMonth).getMonth()+1,new Date(SelectedMonth).getFullYear());
    }, [])
    


    const GetExpensePaymentInfoList = async (MONTH,YEAR) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/PettyCash/GetPettyCashPaymentFinalDetailsData?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}&month=${MONTH}&year=${YEAR}`
            )

            console.log("Expense Payment List",response);

            const res = response.data;
            setPaymentRowData(res)
            // setAdvanceRowData(res.filter(data => data.method === 'Advance'))
            setIsLoading(false)
        } catch (error) {
            console.error(error);
        }
    }

  return (
    <div>
        <div className="border-b flex justify-between items-center border-slate-400 pb-2 mb-4">
          <h1 className="text-xl font-bold text-gray-800">Expense Payment Report</h1>
        <div>
        <DatePicker
            className="bg-white border flex-1 border-gray-400 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full px-2 py-1.5   outline-none"
            selected={SelectedMonth}
            onChange={(date) => {
              setSelectedMonth(date);
              setIsLoading(true)
              GetExpensePaymentInfoList(new Date(date).getMonth()+1,new Date(date).getFullYear());
            }}
            dateFormat="MM/yyyy"
            showMonthYearPicker
            placeholderText="Select Month"
          />
        </div>
      </div>

      <div className="flex justify-between items-center mb-4">

       <div className="bg-white drop-shadow-sm flex gap-2 items-center rounded-lg py-1.5 px-3">    
          <label className="font-medium">{PaymentType === '1'?'Total Paid Amount :' : 'Total Advance Adjusted :'}</label>
          <h6>{PaymentType === '1'? PaymentRowData.reduce((sum,data)=>{
            return sum + Number(data.finalAmountPay || 0)
          },0) : AdvanceRowData.reduce((sum,data)=>{
            return sum + Number(data.finalAmountPay || 0)
          },0)}</h6>
        </div>

        <Button isDisabled={PaymentRowData.length === 0 ? true : false} onClick={()=>{
        gridRef.current.api.exportDataAsCsv();
    }} leftIcon={<FontAwesomeIcon icon={faDownload} />} colorScheme={'purple'} size='sm'>Export</Button>

      </div>

      {!IsLoading ? <>


      <div className="ag-theme-alpine">
        <AgGridReact
          style={gridStyle}
          domLayout={"autoHeight"}
          ref={gridRef} // Ref for accessing Grid's API
          rowData={PaymentRowData} // Row Data for Rows
          columnDefs={PayColumnDefs} // Column Defs for Columns
          defaultColDef={defaultColDef} // Default Column Properties
          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
          pagination={true}
          paginationPageSize={10}
          paginationNumberFormatter={paginationNumberFormatter}
          enableCellTextSelection={true}
          onFilterChanged={getDisplayedRow}
          
        />
      </div>

      
      </> : <Center>
           <Spinner thickness='4px' color={'purple'}  size='lg'  />
          </Center> }
    </div>
  )
}

export default ExpensePaymentInfo