import React,{useState, useEffect, useRef} from 'react'
import { Input,Textarea, useToast,Select, Button } from '@chakra-ui/react'
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import ReactSelect from 'react-select';

function CreateBranch() {

  const userData = JSON.parse(localStorage.getItem("user"));
  const toast = useToast();
  const id = "toast";

  const navigate = useNavigate();


  
  const FileRef = useRef();

  const reset = () => {
    FileRef.current.value = "";
  };

  const [BranchLogo, setBranchLogo] = useState();

  const [BranchID, setBranchID] = useState(0);

  const [CountryList, setCountryList] = useState([]);
  const [StateList, setStateList] = useState([]);
  const [CurrencyList, setCurrencyList] = useState([]);

  const [BranchName, setBranchName] = useState();
  const [BranchCode, setBranchCode] = useState();
  const [CountryName, setCountryName] = useState();
  const [StateName, setStateName] = useState();
  const [Address1, setAddress1] = useState();
  const [Address2, setAddress2] = useState();
  const [PinCode, setPinCode] = useState();
  const [CurrencyName, setCurrencyName] = useState();
  const [TimeZoneName, setTimeZoneName] = useState();
  const [CompanyName, setCompanyName] = useState(userData.companyName);



  useEffect(() => {
    getCountryList();
    getCurrencyList();
  }, []);


  const getCurrencyList = async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCurrencyMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
      )
      console.log("Currency List",response);
      setCurrencyList(response.data);
    } catch (error) {
      console.error(error)
    }
  }


  const getCountryList = async()=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetCountryMasterList`
      )
      console.log("Country List",response);
       setCountryList(response.data);
    } catch (error) {
      console.error(error)
    }
  }


  const getStateList = async(CountryID)=>{
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_ACQ_URL}/api/Master/GetStateListByCountryId?CountryId=${CountryID}`
      )
      console.log("State List",response);
       setStateList(response.data);
    } catch (error) {
      console.error(error)
    }
  }



  const SaveBranchName = async (e) => {
    e.preventDefault();

    const BranchData = new FormData();

    BranchData.append('branchName',BranchName)
    BranchData.append('branchCode',BranchCode)
    BranchData.append('countryId',String(CountryName.countryId))
    BranchData.append('stateId',String(StateName.stateId))
    BranchData.append('address1',Address1)
    BranchData.append('address2',Address2)
    BranchData.append('pincode',PinCode,)
    BranchData.append('currencyId',CurrencyName)
    BranchData.append('timeZoneName',TimeZoneName)
    BranchData.append('companyName',CompanyName)
    BranchData.append('isActive',true)
    BranchData.append('companyId',userData.companyId)
    BranchData.append('_userId',String(userData.userId))
    BranchData.append('_userName',userData.userName)
    BranchData.append('branchId',BranchID)
    BranchData.append('logo',BranchLogo)

    await axios
      .post(
        `${process.env.REACT_APP_ACQ_URL}/api/BranchMaster/SaveBranchMaster`,BranchData)
      .then(async (response) => {
        console.log(response);
        const res = response.data;
        if (!toast.isActive(id)) {
          toast({
            id,
            title: res,
            position: "top",
            status: "success",
            duration: 2000,
            isClosable: true,
          });
        }



        let body1 = {
          userName : userData.userName,
          password : userData.password
        }
        
        await axios.post(
          `${process.env.REACT_APP_ACQ_URL}/api/Account/VerifyUserLogin`,body1
        )
        .then((response) => {
          console.log(response);
          const json =  response.data;
        
          console.log("json",json);
          if (json === 'Failed') {
            // redirect
            alert("Login Again");
          }else{
            //console.log("Success");
            // console.log(json);
            localStorage.removeItem('user');
            localStorage.setItem('user', JSON.stringify(json));
            //  window.location.reload(true);
            navigate('/dashboard');
  
          }
        
        
        })
        .catch((error) => {
          console.error(error);
        
          // if (!toast.isActive(id)) {
          //   toast({
          //     id,
          //     title: "ERROR",
          //     description: `Network Error`,
          //     position: "top",
          //     status: "error",
          //     duration: 2000,
          //     isClosable: true,
          //   });
          // }
        });
  

         setBranchName();
         setBranchCode();
         setCountryName();
         setStateName();
         setAddress1();
         setAddress2();
         setPinCode();
         setCurrencyName();
         setTimeZoneName();
         reset();


      })
      .catch((error) => {
        console.error(error);

        if (!toast.isActive(id)) {
          toast({
            id,
            title: "ERROR",
            description: `Branch not Added`,
            position: "top",
            status: "error",
            duration: 2000,
            isClosable: true,
          });
        }
      });
  };


  return (
    <div>
            <div className="grid md:grid-cols-2 sm:grid-cols-1">

<div className=" bg-slate-800 hidden  md:my-38 md:flex  items-center place-content-center ">
  <img className=" h-auto p-10 lg:p-28 mx-auto" src="/images/CreateBranch.png" alt=""  />

</div>
<div className="basis-1/2   h-auto md:h-screen flex items-center justify-start py-12 px-4 sm:px-6 lg:px-8 sm:basis-1">
  <div className="max-w-md w-full space-y-8 sm:max-w-md md:max-w-2xl">
    <div>
      <h2 className="mt-6 text-3xl font-bold text-gray-800">Branch (Location) Details</h2>
    </div>
    <form className="mt-6 space-y-4" onSubmit={SaveBranchName}>
    <div className="grid grid-cols-2 gap-6">

<div className="space-y-2">
  <label>Branch Name</label>
  <Input
   isRequired
    value={BranchName || ""}
    onChange={({target})=>{setBranchName(target.value)}}
    borderColor="gray"
    placeholder="Enter Branch Name"
  />
</div>

                  <div className="grid grid-cols-2 gap-4">

                  <div className="space-y-2">
                    <label>Branch Code</label>
                    <Input
                      value={BranchCode || ""}
                      onChange={({target})=>{setBranchCode(target.value)}}
                      borderColor="gray"
                      placeholder="Enter Branch Code"
                    />
                  </div>

                  <div className="space-y-2">
                    <label>Branch Logo</label>
                    <Input
                    isRequired
                      ref={FileRef}
                      onChange={({target})=>{
                        setBranchLogo(target.files[0]);
                        console.log("Logo",target.files[0]);
                      }}
                      borderColor="gray"
                      type={'file'}
                      placeholder="Enter Branch Code"
                    />
                  </div>
                    
                  </div>

  <div className="space-y-2">
    <label>Country Name</label>

    <ReactSelect
required
options={CountryList}
value={CountryName}
className="w-full my-auto"
onChange={(value) => {
setCountryName(value);
console.log("Country ID",value.countryId);
getStateList(value.countryId)
}}
getOptionLabel={(option) => option.countryName}
getOptionValue={(option) => option.countryId}
placeholder="Select Country"
/>

  </div>

  <div className="space-y-2">
    <label>State Name</label>
    <ReactSelect
required
options={StateList}
value={StateName}
className="w-full my-auto"
onChange={(value) => {
setStateName(value);
console.log("State ID",value.stateId);

}}
getOptionLabel={(option) => option.stateName}
getOptionValue={(option) => option.stateId}
placeholder="Select State"
/>


  </div>

  <div className="space-y-2">
    <label>Address 1</label>
    <Textarea 
    isRequired
    value={Address1 || ""}
    onChange={({target})=>{setAddress1(target.value)}}
    borderColor="gray" placeholder="Enter Address" />
  </div>

  <div className="space-y-2">
    <label>Address 2</label>
    <Textarea 
    isRequired
      value={Address2 || ""}
      onChange={({target})=>{setAddress2(target.value)}}
      borderColor="gray" placeholder="Enter Address" />
  </div>

  <div className="space-y-2">
    <label>Pincode</label>
    <Input  
    isRequired
    value={PinCode || ""}
    onChange={({target})=>{setPinCode(target.value)}}
    borderColor="gray" placeholder="Pincode" />
    
  </div>

  <div className="space-y-2">
    <label>Currency Name</label>
    <Select
    isRequired
      value={CurrencyName} 
      onChange={({target})=>{setCurrencyName(target.value)}}
      borderColor="gray"
      placeholder="Please Select Currency"
    >
      {CurrencyList.map((data,i)=>{
        return <option key={i} value={data.currencyId} >{data.currencyName}</option>

      })}
    </Select>
  </div>

  <div className="space-y-2">
    <label>Time Zone</label>
    <Select
     isRequired
    value={TimeZoneName} 
    onChange={({target})=>{setTimeZoneName(target.value)}}
      borderColor="gray"
      placeholder="Please Select Time Zone"
    >
      <option value="UTC +04:30">UTC +04:30</option>
      <option value="UTC +05:30">UTC +05:30</option>
      <option value="GMT +05:30">GMT +05:30</option>
    </Select>
  </div>

  <div className="space-y-2">
    <label>Company Name</label>
    <Input
    isDisabled
     value={CompanyName || ""}
     onChange={({target})=>{setCompanyName(target.value)}}
      borderColor="gray"
      placeholder="Enter Company Name"
    />
  </div>

</div>


      <div className='text-right'>
        <Button type='submit' mt={2} colorScheme={'purple'}>Create Branch</Button>
      </div>
    </form>
  </div>
</div>
</div> 
    </div>
  )
}

export default CreateBranch