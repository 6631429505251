import { Button, Checkbox, Input, Select, useToast } from '@chakra-ui/react'
import axios from 'axios';
import React, {
  useCallback,
  useRef,
  useState,
  useMemo,
  useEffect,
} from "react";
import { AgGridReact } from "ag-grid-react";
import { Edit } from 'react-feather';

function SeparationSettings() {

    const userData = JSON.parse(localStorage.getItem("user"));
  
    const toast = useToast();
    const id = "toast";
  
    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []); 
  
  
  const [IsLeaveEncashment, setIsLeaveEncashment] = useState(false);
  const [IsUnservedApply, setIsUnservedApply] = useState(false);

  const [LeaveCalcOn, setLeaveCalcOn] = useState();
  const [UnservedCalcOn, setUnservedCalcOn] = useState();

  const [LeaveCalcList, setLeaveCalcList] = useState([]);
  const [UnservedCalcList, setUnservedCalcList] = useState([]);

    const [CheckListID, setCheckListID] = useState(0);
    const [ChecklistName, setChecklistName] = useState();
    const [IsChecklistActive, setIsChecklistActive] = useState(true);
  
    const [CheckListRowData, setCheckListRowData] = useState([]);
  
    const [ChecklistColumnDefs, setChecklistColumnDefs] = useState([
      { headerName: "CheckList Name", field: "checkListName" },
      { headerName: "IsActive", field: "isActive" },
      { headerName: "Action",
      cellRenderer : (params) => <Button 
      onClick={()=>{
        setChecklistName(params.data.checkListName);
        setIsChecklistActive(params.data.isActive);
        setCheckListID(params.data.separationsCheckListId);
      }}
       size='xs' colorScheme='blue'><Edit size='14px' className='mr-2' />Edit</Button>
    },
  
    ]);
  
  
    const defaultColDef = useMemo(() => ({
      flex: 1,
      sortable: true,
      filter: "agTextColumnFilter",
      floatingFilter: true,
      cacheQuickFilter: true,
    }));
  
    const paginationNumberFormatter = useCallback((params) => {
      return "[" + params.value.toLocaleString() + "]";
    }, []);
  
  
  
  
    const [IsResignation, setIsResignation] = useState(false);
    const [ResignNoticeDays, setResignNoticeDays] = useState();
  
  
    useEffect(() => {
      getSeparationCheckList();
      getNoticePeriodMonths();
      GetUnservedValcList();
      GetLeaveEncashCalcList();
    }, [])
    
  
    const GetUnservedValcList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetSeparationUnservedDaysCalcuListAll?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )

        console.log("Unserved Calculation List", response);
        setUnservedCalcList(response.data);
      } catch (error) {
        console.error(error);
      }
    }


    const GetLeaveEncashCalcList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/GetSeparationLeaveCalcuListAll?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
        )

        console.log("Leave Encashment Calculation List", response);
        setLeaveCalcList(response.data);
      } catch (error) {
        console.error(error);
      }
    }
  
    const getSeparationCheckList = async () => {
      try {
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/SeparationsCheckList/SeparationsCheckList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`,
        )
        console.log("Checklist Response",response);
        setCheckListRowData(response.data);
  
      } catch (error) {
        console.error(error);
      }
    }
  
  
  
    const getNoticePeriodMonths = async () => {
      try {
  
        const response = await axios.get(
          `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SeparationNoticePeriodMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`,
        )
        console.log("Notice Period Month  Response",response);
  
        const res=response.data;
  
        setResignNoticeDays(res.noticePeriodMonth);
        setIsResignation(res.isActive);
        setIsLeaveEncashment(res.leaveCalcuIsActive);
        setIsUnservedApply(res.unservedDaysCalcuIsActive);
        setLeaveCalcOn(res.leaveCalcuBy);
        setUnservedCalcOn(res.unservedDaysCalcuBy);

      } catch (error) {
        console.error(error);
      }
    }
  
  
    const SaveSeperationChecklist = async(e) => {
      e.preventDefault();
      let body = {
      separationsCheckListId:CheckListID,
      checkListName: ChecklistName,
     // checkListStatus: "1",
      isActive: IsChecklistActive,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch
  
    }
  
    console.log("Checklist item Save Body",body);
  
    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/SeparationsCheckList/SaveSeparationsCheckListAdd`,body
    )
    .then((response) => {
      console.log("Save  Checklist Item Response ",response);
      const res = response.data;
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: "top",
          status: "info",
          duration: 2000,
          isClosable: true,
        });
      }
      getSeparationCheckList();
      setChecklistName('');
      setCheckListID(0);
  
    })
    .catch((error) => {
      console.log(error.response);
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Checklist Item not Created`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });
  
    }
  
  
  
    const SaveNoticePeriodMonth = async(e) => {
      e.preventDefault();
      let body = {
      isActive: IsResignation,
      noticePeriodMonth:ResignNoticeDays,
      companyId: userData.companyId,
      branchId: userData.isActiveBranch,
      leaveCalcuBy :  Number(LeaveCalcOn || 0),
      leaveCalcuIsActive : IsLeaveEncashment,
      unservedDaysCalcuBy : Number(UnservedCalcOn || 0),
      unservedDaysCalcuIsActive : IsUnservedApply
    }
  
    console.log("Notice Period Save Body",body);
  
    await axios.post(
      `${process.env.REACT_APP_ACQ_URL}/api/EmployeeResignation/SaveSeparationNoticePeriodMasteradd`,body
    )
    .then((response) => {
      console.log("Save  Checklist Item Response ",response);
      const res = response.data;
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title:res,
          position: "top",
          status: "info",
          duration: 2000,
          isClosable: true,
        });
      }
  
     
  
    })
    .catch((error) => {
      console.log(error.response);
  
      if (!toast.isActive(id)) {
        toast({
          id,
          title: "ERROR",
          description: `Notice Period Months not Saved`,
          position: "top",
          status: "error",
          duration: 2000,
          isClosable: true,
        });
      }
    });
  
    }
  
    return (
      <div className='h-screen'>
  
  
        <div className='grid grid-cols-2 gap-6'>
  
          <div>
  
            
  
  <div className='bg-white p-4  rounded-3xl drop-shadow-md mb-4'>
        <div className=" w-full border-b border-gray-400 mb-3 pb-3 text-left">
          <h1 className="text-gray-700 text-lg font-bold ">Create Separation Checklist</h1>
        </div>
  
        <form onSubmit={SaveSeperationChecklist}>
         <div className='flex justify-between items-center'>
          <div className='space-y-2'>
              <label>Checklist Name</label>
              <Input value={ChecklistName || ''} onChange={({target})=>{setChecklistName(target.value)}}  borderColor='gray.600' placeholder='Checklist Item Name' />
            </div>
  
            <div>
              <Checkbox isChecked={IsChecklistActive} onChange={({target})=>{setIsChecklistActive(target.checked)}}  borderColor='gray.500' >Is Active</Checkbox>
            </div>
  
  
          </div>
  
          <div className='text-right mt-4'>
            <Button type='submit' colorScheme='purple' >Save</Button>
          </div>
        </form>
  
        </div>
  
        <div>
          <div className=" w-full border-b border-gray-400 mb-3 pb-3 text-left">
          <h1 className="text-gray-700 text-lg font-bold ">Separation Checklist</h1>
        </div>
                <div className="ag-theme-alpine">
                        <AgGridReact
                          style={gridStyle}
                          domLayout={"autoHeight"}
                          rowData={CheckListRowData} // Row Data for Rows
                          columnDefs={ChecklistColumnDefs} // Column Defs for Columns
                          defaultColDef={defaultColDef} // Default Column Properties
                          animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                          pagination={true}
                          rowSelection={"multiple"}
                          paginationPageSize={10}
                          paginationNumberFormatter={paginationNumberFormatter}
                        />
                      </div>
          </div>
  
          </div>
  
  
  
          <div className='bg-white p-4 h-fit rounded-3xl drop-shadow-md mb-6'>
  
  
            <form className='space-y-4' onSubmit={SaveNoticePeriodMonth}>
  
  
  
  <div className='flex gap-6 justify-end mb-4'>
    <h6>Company : {userData.companyName}</h6>
    <h6>Branch : {userData.activeBranchName}</h6>
  </div>

  <div className='grid grid-cols-2 gap-6'>
  <div className='my-auto'>
     <Checkbox isChecked={IsResignation} onChange={({target})=>{setIsResignation(target.checked); if(IsResignation || target.checked === false){ setResignNoticeDays('')}}} borderColor='gray.500' >Notice Period Applicable?</Checkbox>
   </div>
  
   <div className='space-y-2'>
     <label>Notice Period Days</label>
     <Input isRequired type='number' isDisabled={!IsResignation} value={ResignNoticeDays || ''} onChange={({target})=>{setResignNoticeDays(target.value)}} borderColor='gray.600' placeholder='Enter Notice Period Days' />
   </div>

   <div  className='my-auto'>
     <Checkbox isChecked={IsLeaveEncashment} onChange={({target})=>{setIsLeaveEncashment(target.checked); if(IsLeaveEncashment || target.checked === false){ setLeaveCalcOn('')}}} borderColor='gray.500' >Leave Encashment Calculation?</Checkbox>
   </div>
  
   <div className='space-y-2'>
     <label>Calculation Based On</label>
     <Select  isRequired={!IsLeaveEncashment} isDisabled={!IsLeaveEncashment} value={LeaveCalcOn} onChange={({target})=>{setLeaveCalcOn(target.value)}} borderColor='gray.500' placeholder='Select Calculation Based On' >
       {LeaveCalcList.map((data,i)=>{
        return <option key={i} value={data.separationLeaveCalcuById}>{data.leaveCalcuByName}</option>
       })}
     </Select>
   </div>

   <div className='my-auto'>
     <Checkbox isChecked={IsUnservedApply} onChange={({target})=>{setIsUnservedApply(target.checked); if(IsUnservedApply || target.checked === false){ setUnservedCalcOn('')}}} borderColor='gray.500' >Unserved Calculation?</Checkbox>
   </div>
  
   <div className='space-y-2'>
   <label>Calculation Based On</label>
   <Select  isRequired={!IsUnservedApply} isDisabled={!IsUnservedApply} value={UnservedCalcOn} onChange={({target})=>{setUnservedCalcOn(target.value)}} borderColor='gray.500' placeholder='Select Calculation Based On' >
       {UnservedCalcList.map((data,i)=>{
        return <option key={i} value={data.separationUnservedDaysCalcuById}>{data.unservedDaysCalcuByName}</option>
       })}
     </Select> 
       </div>

  </div>

  
  <div className='text-right mt-4'>
   <Button type='submit' colorScheme='purple' >Save</Button>
  </div>
  </form>
  
  </div>
  
  
        </div>
  
  
  
  
      </div>
    )
  }

export default SeparationSettings