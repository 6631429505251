import React from "react";

const NoData = () => {
  return (
    <div>
      <div className="p-4 flex justify-center items-center">
        <div className="text-center space-y-6">
          <img src="./images/NoDataFound.png" className="mx-auto w-96" alt="oops" />
          <h2 className="text-3xl font-semibold text-gray-800">
            No Data Found
          </h2>
        </div>
      </div>
    </div>
  );
};

export default NoData;
