import axios from "axios";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { AgGridReact } from "ag-grid-react";
import {Tabs, TabList, TabPanels, Tab, TabPanel, Button, useToast, InputGroup, InputRightAddon, Input } from "@chakra-ui/react";

function ComplianceSettings() {

    const userData = JSON.parse(localStorage.getItem("user"));

    const gridStyle = useMemo(() => ({ height: "100%", width: "100%" }), []);
    const gridRef = useRef();
  
    const [IsConfigured, setIsConfigured] = useState(false)
    const toast = useToast();
    const id = "toast";

    const [EmployeePFCont, setEmployeePFCont] = useState();
    const [EmplyrPfCont, setEmplyrPfCont] = useState();
    const [PfMaxCapAmount, setPfMaxCapAmount] = useState();

    const [EmployeeESICont, setEmployeeESICont] = useState();
    const [EmplyrESICont, setEmplyrESICont] = useState();
    const [ESIMaxCap, setESIMaxCap] = useState();

    const [MasterData, setMasterData] = useState([]);

    const [McolumnDefs, setMColumnDefs] = useState([
        { headerName: "Salary Label", field: "displayLabel" , headerCheckboxSelection: true, checkboxSelection:true},
        { headerName: "Salary Type", field: "componentTypeName" }
      ]);


      const [SelectedColumnDefs, setSelectedColumnDefs] = useState([
        { headerName: "Salary Label", field: "displayLabel"},
        { headerName: "Salary Type", field: "componentTypeName" }
      ]);


      const [SelectedData, setSelectedData] = useState([]);

      const onSelectionChanged = useCallback(() => {
        var SelData = gridRef.current.api.getSelectedRows();
        setSelectedData(SelData);
        console.log(SelData);
      }, []);



    const defaultColDef = useMemo(() => {
        return {
          flex: 1,
          minWidth: 150,
          sortable: true,
          filter: true,
          resizable: true,
        };
      }, []);
    



      useEffect(() => {
        getMasterStructure();
        GetPFESIValue();

      }, []);


      const onFirstDataRendered = useCallback((params) => {
        gridRef.current.api.forEachNode((node) =>
          node.setSelected(node.data.isPfComponent === true)
        );
      }, []);

      const getMasterStructure = async () => {
        await axios
          .get(
            `${process.env.REACT_APP_ACQ_URL}/api/BranchSalaryStructureMaster/GetBranchSalaryStructureMasterList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
          )
          .then((response) => {
             console.log("Branch got Successfully", response.data);
             const res = response.data;

             let EarningStructure = res.filter(data => data.componentTypeId === 1)
            // setMasterData(EarningStructure);
            GetPFESISettings(EarningStructure);
          })
          .catch((error) => {
            console.error(error);
          });
      };


      const GetPFESISettings = async (EarningStructure) => {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/GetPFSettingList?CompanyId=${userData.companyId}&BranchId=${userData.isActiveBranch}`
            )

            console.log("Get PF ESI Setting Response",response);

            const res = response.data;
             
            setIsConfigured(res.length !== 0 ? false : true)

            console.log("Comp with PF ESI", EarningStructure.map((CompData)=>{
                return { 
                    ...CompData,
                    isPfComponent : res.some(pfdata => pfdata.branchSalaryStructureMasterId === CompData.branchSalaryStructureMasterId)
                }
            }))

            setMasterData(EarningStructure.map((CompData)=>{
                return { 
                    ...CompData,
                    isPfComponent : res.some(pfdata => pfdata.branchSalaryStructureMasterId === CompData.branchSalaryStructureMasterId)
                }
            }))
        } catch (error) {
            console.error(error);
        }
      }




      const GetPFESIValue = async () => {
        try {

          const response = await axios.get(
            `${process.env.REACT_APP_ACQ_URL}/api/ESI_PF/GetPFESICValue`
        )
          console.log("Get PF ESI Value",response);

          const res = response.data;

          setEmployeePFCont(res.epF_EmployeeContribution);
          setEmplyrPfCont(res.epF_EmployerContribution);
          setEmployeeESICont(res.esiC_EmployeeContribution);
          setEmplyrESICont(res.esiC_EmployerContribution);
          setESIMaxCap(res.esI_MaxCap);
          setPfMaxCapAmount(res.pF_MaxCap)


        } catch (error) {
          console.error(error);
        }
      }


      const SavePfEsiSetting = async () => {
        let body = SelectedData.map((data)=>{
            return {
                  branchSalaryStructureMasterId : data.branchSalaryStructureMasterId,
                  structureName : data.displayLabel,
                  companyId : userData.companyId,
                  branchId : userData.isActiveBranch,
                }
        })

        console.log("PF Esi Setting Data",body);

        await axios.post(`${process.env.REACT_APP_ACQ_URL}/api/EmployeePFESICSetting/SavePFSettings`,body
        )
        .then((response) => {
         console.log(" Saved Response",response);
          const res = response.data;
          //console.log("res",res);
          if (!toast.isActive(id)) {
            toast({
              id,
              title:res,
              position: 'top',
              status: 'info',
              duration: 2000,
              isClosable: true,
            })
          }

          getMasterStructure();

        })
        .catch((error) => {
          console.error(error);

          if (!toast.isActive(id)) {
            toast({
              id,
              title: 'ERROR',
              description: "Compliance not Saved.",
              position: 'top',
              status: 'error',
              duration: 2000,
              isClosable: true,
            })
          }
        });

      }
    



      const SaveESIPFContribution = async() =>{
        let body={
          esiC_EmployeeContribution : 0,
          esiC_EmployerContribution : 3.25,
          epF_EmployeeContribution : 12,
          epF_EmployerContribution : 12,
          pF_MaxCap : 15000,
          adminCharges : 21000,
          esI_MaxCap : 150
        }

        console.log("Configuration Save Body",body)
      }

  return (
    <div>
      <div className=" border-b border-gray-400 mb-4 pb-2 text-left">
        <h1 className="text-gray-700 text-lg font-bold ">
          Compliance Settings
        </h1>
      </div>

      <Tabs size={"sm"} variant="solid-rounded" colorScheme={"purple"}>
        <TabList>
          <Tab>PF Configuration</Tab>
          <Tab>ESI Configuration</Tab>
        </TabList>
        <TabPanels>
          <TabPanel px={0}>
            <div className="flex gap-6">
              <form className="bg-white w-1/3 flex flex-col justify-between rounded-2xl p-4 drop-shadow-md">
                <div className="space-y-4">
                  <div className=" border-b border-gray-400 mb-4 pb-2 text-left">
                    <h1 className="text-gray-700 text-lg font-bold ">
                      PF Configuration
                    </h1>
                  </div>

                  <div className="space-y-2">
                    <label>Employee PF Contribution</label>
                    <InputGroup>
                      <Input
                        isReadOnly
                        value={EmployeePFCont || ""}
                        onChange={({ target }) => {
                          setEmployeePFCont(target.value);
                        }}
                        isRequired
                        type={"number"}
                        borderColor={"gray.400"}
                        placeholder="Employee PF Contribution"
                      />
                      <InputRightAddon borderColor={"gray.400"} children="%" />
                    </InputGroup>
                  </div>

                  <div className="space-y-2">
                    <label>Employer PF Contribution</label>
                    <InputGroup>
                      <Input
                        isReadOnly
                        value={EmplyrPfCont || ""}
                        onChange={({ target }) => {
                          setEmplyrPfCont(target.value);
                        }}
                        isRequired
                        type={"number"}
                        borderColor={"gray.400"}
                        placeholder="Employer PF Contribution"
                      />
                      <InputRightAddon borderColor={"gray.400"} children="%" />
                    </InputGroup>
                  </div>

                  <div className="space-y-2">
                    <label>PF Max Cap Amount</label>
                    <Input
                      isReadOnly
                      value={PfMaxCapAmount || ""}
                      onChange={({ target }) => {
                        setPfMaxCapAmount(target.value);
                      }}
                      isRequired
                      type={"number"}
                      borderColor={"gray.400"}
                      placeholder="PF Max Cap Amount"
                    />
                  </div>
                </div>
                {/*  <div className="text-right mt-4">
                  <Button isDisabled type="submit" colorScheme={'purple'}>Save</Button>

                </div> */}
              </form>

              <div className="bg-slate-50 flex flex-col justify-between w-full  rounded-2xl p-4 drop-shadow-md">
                <div className="grid grid-cols-2 gap-6 ">
                  <div>
                    <div className=" border-b border-gray-400 mb-4 pb-2 text-left">
                      <h1 className="text-gray-700 text-lg font-bold ">
                        All Components
                      </h1>
                    </div>
                    <div className="ag-theme-alpine mb-6">
                      <AgGridReact
                        style={gridStyle}
                        ref={gridRef} // Ref for accessing Grid's API
                        rowData={MasterData} // Row Data for Rows
                        columnDefs={McolumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional- set to 'true'
                        rowDragManaged={true}
                        domLayout={"autoHeight"}
                        rowSelection={"multiple"}
                        onFirstDataRendered={onFirstDataRendered}
                        onSelectionChanged={onSelectionChanged}
                      />
                    </div>
                  </div>

                  <div>
                    <div className=" border-b border-gray-400 mb-4 pb-2 text-left">
                      <h1 className="text-gray-700 text-lg font-bold ">
                        Components for PF
                      </h1>
                    </div>
                    <div className="ag-theme-alpine mb-6">
                      <AgGridReact
                        style={gridStyle}
                        //ref={gridRef} // Ref for accessing Grid's API
                        rowData={SelectedData} // Row Data for Rows
                        columnDefs={SelectedColumnDefs} // Column Defs for Columns
                        defaultColDef={defaultColDef} // Default Column Properties
                        animateRows={true} // Optional- set to 'true'
                        domLayout={"autoHeight"}
                      />
                    </div>
                  </div>
                </div>

                {IsConfigured &&
                  <div className="text-right space-x-4">
                    <Button
                      onClick={() => {
                        gridRef.current.api.deselectAll();
                      }}
                      colorScheme={"purple"}
                      variant={"outline"}
                    >
                      Reset
                    </Button>

                    <Button onClick={SavePfEsiSetting} colorScheme={"purple"}>
                      Save
                    </Button>
                  </div> }

              </div>
            </div>
          </TabPanel>
          <TabPanel px={0}>
            <div className="bg-slate-50 flex flex-col space-y-4 justify-between w-1/3  rounded-2xl p-4 drop-shadow-md">
              <div className=" border-b border-gray-400 pb-2 text-left">
                <h1 className="text-gray-700 text-lg font-bold ">
                  ESI Configuration
                </h1>
              </div>

              <div className="space-y-2">
                <label>Employee ESI Contribution</label>
                <InputGroup>
                  <Input
                    isReadOnly
                    value={EmployeeESICont || ""}
                    onChange={({ target }) => {
                      setEmployeeESICont(target.value);
                    }}
                    isRequired
                    type={"number"}
                    borderColor={"gray.400"}
                    placeholder="Employee ESI Contribution"
                  />
                  <InputRightAddon borderColor={"gray.400"} children="%" />
                </InputGroup>
              </div>

              <div className="space-y-2">
                <label>Employer ESI Contribution</label>
                <InputGroup>
                  <Input
                    isReadOnly
                    value={EmplyrESICont || ""}
                    onChange={({ target }) => {
                      setEmplyrESICont(target.value);
                    }}
                    isRequired
                    type={"number"}
                    borderColor={"gray.400"}
                    placeholder="Employer ESI Contribution"
                  />
                  <InputRightAddon borderColor={"gray.400"} children="%" />
                </InputGroup>
              </div>

              <div className="space-y-2">
                <label>ESI Max Cap Amount</label>
                <Input
                  isReadOnly
                  value={ESIMaxCap || ""}
                  onChange={({ target }) => {
                    setESIMaxCap(target.value);
                  }}
                  isRequired
                  type={"number"}
                  borderColor={"gray.400"}
                  placeholder="ESI Max Cap Amount"
                />
              </div>

              {/* <div className="text-right">
                   <Button isDisabled type="submit" colorScheme={'purple'}>Save</Button>
                  </div> */}
            </div>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  );
}

export default ComplianceSettings